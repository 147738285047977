import { environment } from '#environments/environment';
import { MsalAngularConfiguration } from '@azure/msal-angular';
import { isIE } from './utils';

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: !isIE,
    consentScopes: environment.cal.consentScopes,
    unprotectedResources: ['https://www.microsoft.com/en-us/'],
    protectedResourceMap: environment.cal.protectedResourceMap as [
      string,
      string[]
    ][],
    extraQueryParameters: {},
  };
}
