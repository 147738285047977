import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';

import {
  BusinessUnitsComponent,
  TaskPlanComponent,
  TaskPlanCreateComponent,
  TaskPlanEditComponent,
  TaskPlanManageComponent,
  TaskPlanDeleteComponent,
} from './';

import { ProjectCreateComponent } from '#pages/admin/project-create/project-create.component';
import { ProjectEditComponent } from '#pages/admin/project-edit/project-edit.component';
import { EndDeviceFormComponent } from './end-device-form/end-device-form.component';
import { EndDeviceTypeNameComponent } from './end-device-type-name/end-device-type-name.component';
import { EndDeviceComponent } from './end-device/end-device.component';
import { EndDeviceListComponent } from './end-device-list/end-device-list.component';
import { EquipmentFormComponent } from './equipment-form/equipment-form.component';
import { EquipmentListComponent } from './equipment-list/equipment-list.component';
import { EquipmentComponent } from './equipment/equipment.component';
import { EquipmentTempGroupComponent } from './equipment-group/equipment-temp-group.component';

import { TaskPlanSectionEditComponent } from '#pages/admin/task-plan-section-edit/task-plan-section-edit.component';
import { TaskPlanSectionDeleteComponent } from '#pages/admin/task-plan-section-delete/task-plan-section-delete.component';

import { TaskPlanSubSectionEditComponent } from '#pages/admin/task-plan-sub-section-edit/task-plan-sub-section-edit.component';
import { TaskPlanSubSectionDeleteComponent } from '#pages/admin/task-plan-sub-section-delete/task-plan-sub-section-delete.component';

import { TaskPlanItemCreateComponent } from '#pages/admin/task-plan-item-create/task-plan-item-create.component';
import { TaskPlanItemEditComponent } from '#pages/admin/task-plan-item-edit/task-plan-item-edit.component';

import { ApiModule } from '#services/api/api.module';

import { SharedModule } from '#services/shared/shared.module';
import { ConfirmationService, MessageService } from 'primeng/api';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { OrganizationComponent } from './organization/organization.component';
import { OrganizationService } from '#services/api/organization.service';

import { UserComponent } from '#pages/admin/user/user.component';
import { UserCreateComponent } from '#pages/admin/user-create/user-create.component';

import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';

import { AreaComponent } from '#pages/admin/facility-hierarchy/area/area.component';
import { AreaCreateComponent } from '#pages/admin/facility-hierarchy/area-create/area-create.component';
import { AreaEditComponent } from '#pages/admin/facility-hierarchy/area-edit/area-edit.component';
import { AreaDeleteComponent } from '#pages/admin/facility-hierarchy/area-delete/area-delete.component';

import { SubAreaComponent } from '#pages/admin/facility-hierarchy/sub-area/sub-area.component';
import { SubAreaCreateComponent } from '#pages/admin/facility-hierarchy/sub-area-create/sub-area-create.component';
import { SubAreaEditComponent } from '#pages/admin/facility-hierarchy/sub-area-edit/sub-area-edit.component';
import { SubAreaDeleteComponent } from '#pages/admin/facility-hierarchy/sub-area-delete/sub-area-delete.component';

import { FacilityComponent } from '#pages/admin/facility-hierarchy/facility/facility.component';
import { FacilityCreateComponent } from '#pages/admin/facility-hierarchy/facility-create/facility-create.component';
import { FacilityEditComponent } from '#pages/admin/facility-hierarchy/facility-edit/facility-edit.component';
import { FacilityDeleteComponent } from '#pages/admin/facility-hierarchy/facility-delete/facility-delete.component';

import { CategoryComponent } from '#pages/admin/category/category.component';
import { CategoryCreateComponent } from '#pages/admin/category-create/category-create.component';
import { CategoryEditComponent } from '#pages/admin/category-edit/category-edit.component';
import { CategoryDeleteComponent } from '#pages/admin/category-delete/category-delete.component';
import { BusinessUnitUserComponent } from './business-unit-user/business-unit-user.component';
import { SystemAdminComponent } from './system-admin/system-admin.component';
import { SystemAdminService } from '#services/api/system-admin.service';
import { BusinessUnitCreateComponent } from './business-unit-create/business-unit-create.component';
import { BusinessUnitEditComponent } from './business-unit-edit/business-unit-edit.component';
import { EndDeviceTypeComponent } from './end-device-type/end-device-type.component';
import { EndDeviceTypeFormComponent } from './end-device-type-form/end-device-type-form.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { GroupsComponent } from './groups/groups.component';
import { GroupEditComponent } from './group-edit/group-edit.component';
import { GroupCreateComponent } from './group-create/group-create.component';
import { CheckboxModule } from 'primeng/checkbox';
import { PaginatorModule } from 'primeng/paginator';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FeedbackCreateComponent } from 'app/feedback/feedback-create/feedback-create.component';
import { FeedbackEditComponent } from 'app/feedback/feedback-edit/feedback-edit.component';
import { MobileAppVersionComponent } from './mobile-app-version/mobile-app-version.component';
import { TableModule } from 'primeng/table';
import { AppVersionStatusPipe } from './mobile-app-version/helpers/app-version-status.pipe';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MobileAppVersionEditFormComponent } from './mobile-app-version/mobile-app-version-edit-form/mobile-app-version-edit-form.component';
import { CalendarModule } from 'primeng/calendar';
import { OrganizationEditComponent } from '#pages/admin/organization-edit/organization-edit.component';
import { SubcategoryDialogComponent } from './subcategory-dialog/subcategory-dialog.component';
import { CategoryService } from '#services/api/category.service';
import { SubCategoryService } from '#services/api/subcategory.service';
import { RouterModule } from '@angular/router';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { LanguageLoader } from '#services/shared/language-loader';
import { LanguageParser } from '#services/shared/language-parser';
import { SharedPagesModule } from '#pages/shared/shared.pages.module';
import { SubcategoryDeleteComponent } from './subcategory-delete/subcategory-delete.component';
import { SubcategoryEditComponent } from './subcategory-edit/subcategory-edit.component';
import { SubcategoryCreateComponent } from './subcategory-create/subcategory-create.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { UserEditComponent } from './user-edit/user-edit.component';

@NgModule({
  declarations: [
    // Components
    BusinessUnitsComponent,
    ProjectCreateComponent,
    ProjectEditComponent,
    EndDeviceFormComponent,
    EquipmentComponent,
    EquipmentListComponent,
    EquipmentFormComponent,
    EquipmentTempGroupComponent,
    EndDeviceListComponent,
    EndDeviceComponent,
    EndDeviceTypeNameComponent,
    TaskPlanComponent,
    TaskPlanCreateComponent,
    TaskPlanEditComponent,
    TaskPlanManageComponent,
    TaskPlanDeleteComponent,
    TaskPlanSectionEditComponent,
    TaskPlanSectionDeleteComponent,
    TaskPlanSubSectionEditComponent,
    TaskPlanSubSectionDeleteComponent,
    TaskPlanItemCreateComponent,
    TaskPlanItemEditComponent,
    OrganizationComponent,
    UserComponent,
    UserCreateComponent,
    UserEditComponent,
    AreaComponent,
    AreaCreateComponent,
    AreaEditComponent,
    AreaDeleteComponent,
    SubAreaComponent,
    SubAreaCreateComponent,
    SubAreaEditComponent,
    SubAreaDeleteComponent,
    FacilityComponent,
    FacilityCreateComponent,
    FacilityEditComponent,
    FacilityDeleteComponent,
    CategoryComponent,
    CategoryCreateComponent,
    CategoryEditComponent,
    CategoryDeleteComponent,
    SubcategoryComponent,
    SubcategoryCreateComponent,
    SubcategoryEditComponent,
    SubcategoryDeleteComponent,
    BusinessUnitUserComponent,
    SystemAdminComponent,
    BusinessUnitCreateComponent,
    BusinessUnitEditComponent,
    EndDeviceTypeComponent,
    EndDeviceTypeFormComponent,
    GroupsComponent,
    GroupEditComponent,
    GroupCreateComponent,
    FeedbackCreateComponent,
    FeedbackEditComponent,
    MobileAppVersionComponent,
    OrganizationEditComponent,

    // Pipes
    AppVersionStatusPipe,

    MobileAppVersionEditFormComponent,

    SubcategoryDialogComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,
    RouterModule,

    // PrimeNG
    InputTextModule,
    ButtonModule,
    BrowserAnimationsModule,
    ConfirmDialogModule,
    DialogModule,
    AutoCompleteModule,
    MultiSelectModule,
    CheckboxModule,
    PaginatorModule,
    RadioButtonModule,
    TableModule,
    MessageModule,
    MessagesModule,
    CalendarModule,
    ProgressSpinnerModule,

    // Text Toolbar
    // RichTextEditorAllModule,

    // App
    ApiModule,
    SharedModule,
    SharedPagesModule,
    FormsModule,

    // Dropdown
    MatExpansionModule,
    MatInputModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: LanguageLoader },
      parser: {
        provide: TranslateParser,
        useClass: LanguageParser,
      },
      useDefaultLang: true,
    }),
  ],
  providers: [

    ConfirmationService,
    OrganizationService,
    SystemAdminService,
    MessageService,
    CategoryService,
    SubCategoryService,
  ],
})
export class AdminPagesModule {}
