import { Component, OnInit } from '@angular/core';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';

import { Router } from '@angular/router';

import { PssrTypeService } from '#services/api/pssrtype.service';
import { PSSRType } from '#models/pssr-type';


import { ToastrService } from '#services/shared/toastr.service';
import { SelectItem } from 'primeng';


@Component({
  selector: 'app-business-unit-create',
  templateUrl: './business-unit-create.component.html',
})
export class BusinessUnitCreateComponent implements OnInit {
  bu: BusinessUnit = new BusinessUnit();
  displayWarning: boolean;

  displayRichEditor: boolean;

  pssrTypes: PSSRType[] = [];

  months: SelectItem[] = [
    { label: '1 month', value: 1 },
    { label: '2 months', value: 2 },
    { label: '3 months', value: 3 },
    { label: '4 months', value: 4 },
    { label: '5 months', value: 5 },
    { label: '6 months', value: 6 },
    { label: '7 months', value: 7 },
    { label: '8 months', value: 8 },
    { label: '9 months', value: 9 },
    { label: '10 months', value: 10 },
    { label: '11 months', value: 11 },
    { label: '12 months', value: 12 }
  ];

  constructor(
    private buService: BusinessUnitsService,
    private router: Router,
    private pssrTypeService: PssrTypeService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.bu.HierarchyLabel1 = 'Area';
    this.bu.HierarchyLabel2 = 'Facility Operation Team';
    this.bu.HierarchyLabel3 = 'Facility';
    this.bu.ShowHierarchyLabel3 = true;
    this.bu.PSSRLabel = 'PSSR Title';
    this.bu.OrganizationLabel = 'Organization';
    this.bu.P3Label = 'P3';
    this.bu.P4Label = 'P4';
    this.bu.P5Label = 'P5';
    this.bu.ShowP5 = false;
    this.bu.ShowPssrType = false;
    this.bu.ShowProject = false;
    this.bu.ShowProjectInput = false;
    this.bu.AllowWetSignature = false;
    this.bu.ShowVerbalApproval = false;
    this.bu.ShowCCC = false;
    this.bu.ShowORR = false;
    this.bu.ShowQAQC = false;
    this.bu.ScheduledEmail = false;
    this.displayWarning = false;
    this.loadPssrTypes();
  }

  private loadPssrTypes() {
    this.pssrTypeService
      .loadPssrTypes()
      .toPromise()
      .then((types) => (this.pssrTypes = types));
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/business-units']);
  }

  public create(): void {
    this.displayWarning =
      this.bu.Name == undefined ||
      this.bu.Name == null ||
      this.bu.Name.length == 0;
    if (!this.displayWarning) {
      this.buService
        .addBusinessUnit({
          ...this.bu,
          RestrictApproverSignatureOnLeadsDevice: !this.bu.AllowLeadtoShareDeviceWithApprover
        })

        .subscribe(
          (_data: BusinessUnit) => {
            this.onRedirectButtonClick();
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);
          }
        );
    }
  }

  isPssrTypeChecked = ({ Id }) =>
    this.bu.PssrTypes.some(({ Id: pssrTypeId }) => Id === pssrTypeId)

  pssrTypeChecked({ target: { checked, value } }) {
    const pssrTypeId = +value;

    const hasPssrType = this.bu.PssrTypes.some(({ Id }) => Id === pssrTypeId);

    if (!hasPssrType && checked) {
      this.bu.PssrTypes.push(
        this.pssrTypes.find(({ Id }) => Id === pssrTypeId)
      );
    }

    if (hasPssrType && !checked) {
      this.bu.PssrTypes = this.bu.PssrTypes.filter(
        ({ Id }) => Id !== pssrTypeId
      );
    }
  }
}
