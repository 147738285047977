<div
  class="box-shadow module"
  style="width: 80%; margin: auto; margin-bottom: 20px"
  [appBUSwitcher]="selectedBU"
>

  <h2>{{'Edit'|translate}} {{ hierarchyLabel3 }} - {{ facilityName }}</h2>
  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{ hierarchyLabel3 }}</label>
      <div class="col-md-10">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="facility.Name"
        />
        <span
          class="text-danger field-validation-error"
          *ngIf="displayWarningName"
        >
          {{ "This field is required" | translate }}
        </span>
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <a
          class="btn btn-default"
          style="width: 100px"
          (click)="updateFacility()"
          >{{'Save'|translate}}</a
        >
      </div>
    </div>
  </div>
</div>

<div style="width: 80%; margin: auto">
  <a (click)="onRedirectButtonClick()" style="cursor: pointer"
    >{{'Back to'|translate}} {{ hierarchyLabel3 }} List</a
  >
</div>
