<!-- Project Info -->
<div class="box-shadow module" [appBUSwitcher]="selectedBU">
  <div class="row">
    <h2>
      {{ project?.Name }} {{ project?.Description ? "-" : "" }}
      {{ project?.Description }}
    </h2>
  </div>

  <!-- Project data -->
  <div class="row">
    <div class="col-xs-3">
      <div class="row">
        <label class="control-label" style="font-weight: bolder">{{
          "Project Name" | translate
        }}</label>
        <input
          id="projName"
          class="form-control text-box"
          [(ngModel)]="project.Name"
          name="projName"
          #projName="ngModel"
          [placeholder]="'Project Name' | translate"
          maxlength="255"
        />
      </div>
      <div class="row">
        <span
          class="col-sm-8 text-danger field-validation-error"
          *ngIf="!project.Name?.length"
        >
          {{ "This field is required" | translate }}
        </span>
        <span class="col-sm-4 charSpan">{{ project.Name?.length }} of 255</span>
      </div>
    </div>
    <ng-container *ngIf="selectedBU.ShowQAQC">
      <div class="col-xs-3">
        <div class="row">
          <div class="form-group">
            <label>{{ selectedBU.HierarchyLabel1 | translate }}</label>
            <select
              id="area"
              class="form-control"
              (change)="changeArea()"
              [(ngModel)]="areaID"
              name="area"
              #area="ngModel"
              required
            >
              <option *ngIf="lstArea.length > 0" value=""></option>
              <option *ngFor="let area of lstArea" [ngValue]="area.Id">
                {{ area.Name }}
              </option>
            </select>
            <div
              [hidden]="area.valid || area.pristine"
              class="alert alert-warning"
              role="alert"
            >
              {{ selectedBU?.HierarchyLabel1 | translate }}
              {{ "is required" | translate }}.
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-3">
        <div class="row">
          <div class="form-group">
            <label>{{ selectedBU.HierarchyLabel2 | translate }}</label>
            <select
              id="subArea"
              class="form-control"
              (change)="changeSubArea()"
              [(ngModel)]="subareaID"
              name="subArea"
              #subArea="ngModel"
              required
            >
              <option *ngIf="lstSubArea.length > 0" value=""></option>
              <option *ngFor="let subarea of lstSubArea" [ngValue]="subarea.Id">
                {{ subarea.Name }}
              </option>
            </select>
            <div
              [hidden]="subArea.valid || subArea.pristine"
              class="alert alert-warning"
              role="alert"
            >
              {{ selectedBU?.HierarchyLabel2 | translate }}
              {{ "is required" | translate }}.
            </div>
          </div>
        </div>
      </div>
      <div class="col-xs-3">
        <div class="row">
          <div *ngIf="selectedBU.ShowHierarchyLabel3 && lstFacility.length > 0">
            <div class="form-group">
              <label>{{ selectedBU.HierarchyLabel3 | translate }}</label>
              <select
                id="facility"
                class="form-control"
                [(ngModel)]="facilityID"
                name="facility"
                #facility="ngModel"
              >
                <option *ngIf="lstFacility.length > 0" value=""></option>
                <option
                  *ngFor="let facility of lstFacility"
                  [ngValue]="facility.Id"
                >
                  {{ facility.Name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ng-container *ngIf="selectedBU.ShowQAQC">
    <div class="row">
      <div class="col-xs-3" *ngIf="qaqcSelectItem.length > 0">
        <div class="form-group">
          <label>{{ qaqcLabel | translate }}</label>
          <p-multiSelect
            id="qaqcItems"
            [options]="qaqcSelectItem"
            [(ngModel)]="qaqcSelectedItem"
            name="qaqcItems"
            [style]="{ width: '100%', minHeight: '20px' }"
          ></p-multiSelect>
        </div>
        <div *ngIf="qaqcItem.length === 0" class="form-group">
          <label>{{ qaqcLabel | translate }}</label>
          <div
            class="row"
            *ngIf="selectedBU?.ShowQAQC"
            class="alert alert-warning"
            role="alert"
          >
            {{ "WorkOrdersNoavailable" | translate }}
          </div>
        </div>
      </div>
      <div class="col-xs-3">
        <div class="form-group">
          <label>{{ "Template" | translate }}</label>
          <select
            id="pssrTemplate"
            class="form-control"
            [(ngModel)]="taskplanID"
            name="pssrTemplate"
            required
            #template="ngModel"
          >
            <option
              *ngFor="let taskplan of lstTaskPlan"
              [ngValue]="taskplan.ID"
            >
              {{ taskplan.Description }}
            </option>
          </select>
          <div
            [hidden]="template.valid || template.pristine"
            class="alert alert-warning"
            role="alert"
          >
            {{ "Template is required" | translate }}.
          </div>
        </div>
      </div>

      <div class="col-xs-3">
        <div *ngIf="selectedBU?.ShowPssrType" class="form-group">
          <label>{{ "Type" | translate }} :</label>
          <select
            id="pssrTypeId"
            class="form-control"
            [(ngModel)]="pssrTypeID"
            name="pssrTypeId"
            #pssrTypeId="ngModel"
          >
            <option [ngValue]="null"></option>
            <option *ngFor="let pt of pssrTypes" [ngValue]="pt.value">
              {{ pt.label }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-xs-3" style="margin-top: 25px">
        <button
          id="loadnewqaqcBtn"
          type="button"
          class="btn btn-primary"
          *ngIf="project"
          (click)="createNewQAQCItems()"
          style="width: 90%; white-space: normal"
        >
          {{ "Load New QA QC" | translate }}
        </button>
      </div>
    </div>

    <div class="row" *ngIf="taskplanID > 0 && qaqcSelectedItem.length > 0">
      <app-pssr-edit-qaqc-members
        (addedPssrUsers)="addedPssrUsers($event)"
        (addedSectionLeads)="addedSectionLeads($event)"
        [orgId]="project.OrganizationId"
        [templateId]="taskplanID"
        [bu]="selectedBU"
      >
      </app-pssr-edit-qaqc-members>
    </div>
  </ng-container>

  <div *ngIf="project" class="row">
    <div class="col-xs-2" style="margin-top: 25px">
      <button
        *ngIf="!project.IsDeleted"
        id="savechangesBtn"
        type="button"
        class="btn btn-primary"
        (click)="updateProject()"
        style="width: 90%; white-space: normal"
        [disabled]="!project.Name?.length"
      >
        {{ "Save Changes" | translate }}
      </button>
      <button
        *ngIf="canDeleteProject && project.Id &gt; 0 && project.IsDeleted"
        id="restore-project-button"
        type="button"
        class="btn btn-default"
        (click)="restoreProject(project.Id)"
        style="width: 90%; white-space: normal"
      >
        {{ "Restore" | translate }}
      </button>
    </div>

    <div class="col-xs-2" style="margin-top: 25px">
      <button
        *ngIf="canDeleteProject && project.Id &gt; 0 && !project.IsDeleted && project.CurrentUser.IsBusinessUnitAdmin"
        id="delete-project-button"
        type="button"
        class="btn btn-danger"
        (click)="confirmDelete()"
        style="width: 90%; white-space: normal"
      >
        {{ "Delete" | translate }}
      </button>
    </div>

    <div class="col-xs-2" style="margin-top: 25px">
      <button
        *ngIf="project.Status == 1 && !project.IsDeleted && project.CurrentUser.IsBusinessUnitAdmin"
        id="markasclosedBtn"
        type="button"
        class="btn btn-default"
        (click)="confirmClose()"
        style="width: 90%; white-space: normal"
      >
        {{ "Mark as Closed" | translate }}
      </button>
    </div>
    <div class="col-xs-4" style="margin-top: 25px">
      <ng-container *ngIf="project">
        <div class="row" *ngIf="project.DeletedOn && project.IsDeleted">
          <strong class="col-xs-4 text-right padding-left-0"
            >{{ "Deleted On" | translate }}&nbsp;</strong
          >

          <span class="col-xs-8 text-left padding-left-0">
            {{ project.DeletedOn | date: "short" }}&nbsp;
          </span>
        </div>

        <div class="row" *ngIf="project.DeletedBy && project.IsDeleted">
          <strong class="col-xs-4 text-right padding-left-0">
            {{ "Deleted By" | translate }}&nbsp;</strong
          >

          <span
            class="col-xs-8 text-left padding-left-0"
            style="word-break: break-all"
          >
            {{ project.DeletedBy }}&nbsp;
          </span>
        </div>

        <div class="row" *ngIf="project.RestoredOn && !project.IsDeleted">
          <strong class="col-xs-4 text-right padding-left-0"
            >{{ "Restored On" | translate }}&nbsp;</strong
          >

          <span
            class="col-xs-8 text-left padding-left-0"
            style="word-break: break-all"
          >
            {{ project.RestoredOn | date: "short" }}&nbsp;
          </span>
        </div>

        <div class="row" *ngIf="project.RestoredBy && !project.IsDeleted">
          <strong class="col-xs-4 text-right padding-left-0"
            >{{ "Restored By" | translate }}&nbsp;</strong
          >

          <span
            class="col-xs-8 text-left padding-left-0"
            style="word-break: break-all"
          >
            {{ project.RestoredBy }}&nbsp;
          </span>
        </div>
      </ng-container>
    </div>

    <div *ngIf="project.StatusName" class="col-xs-2">
      <label>{{ "Status" | translate }} :</label><br />
      <div
        class="{{
          project.statusClass
        }} text-center padding-left-5 padding-right-5 padding-top-5 padding-bottom-5"
        style="border-radius: 1em; color: white; max-width: 150px"
      >
        {{ project.StatusName | translate }}
      </div>
    </div>
  </div>
  <!-- /End Project data -->
</div>
<!-- /End Project Info -->

<p-confirmDialog #cd>
  <p-footer>
    <div class="row">
      <ng-container *ngIf="isConfirmDelete">
        <button
          id="delProjectBtn"
          type="button"
          pButton
          *ngIf="hasPssrs"
          [label]="'Delete Project & PSSRs' | translate"
          (click)="cd.accept()"
          class="col-xs-4 ui-button-danger"
          style="width: 30%; white-space: normal"
        ></button>
        <button
          id="deleteProjectOnlyBtn"
          *ngIf="hasPssrs"
          type="button"
          pButton
          [label]="'Delete Project Only' | translate"
          (click)="partialDelete()"
          class="col-xs-4 ui-button-warning"
          style="width: 30%; white-space: normal"
        ></button>
        <button
          id="deleteBtn"
          type="button"
          pButton
          *ngIf="!hasPssrs"
          [label]="'Delete' | translate"
          (click)="cd.accept()"
          class="col-xs-4 ui-button-danger"
          style="width: 30%; white-space: normal"
        ></button>
      </ng-container>

      <button
        id="confirmBtn"
        type="button"
        pButton
        *ngIf="((!isConfirmDelete && !hasOpenPSSRs) || canInitiateAll)"
        [label]="'Confirm' | translate"
        (click)="cd.accept()"
        class="col-xs-4"
        style="width: 30%; white-space: normal"
      ></button>

      <button
        id="cancelBtn"
        type="button"
        pButton
        [label]="'Cancel' | translate"
        (click)="cd.reject()"
        class="col-xs-4 ui-button-secondary"
        style="width: 30%; white-space: normal"
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>

<!-- Team Members -->
<div class="box-shadow module" *ngIf="project?.Id">
  <app-project-members
    [project]="project"
    (projUserUpd)="onMembersChanged($event)"
  ></app-project-members>
</div>
<!-- /End Team Members -->

<!--PSSR List by Project Selected-->
<div *ngIf="project" class="box-shadow module">
  <div class="row">
    <h3 class="text-left" style="margin-bottom: 0px">
      {{ selectedBU?.PssrTitle }}
    </h3>
    <hr />
  </div>
  <div style="margin-bottom: 15px">
    <app-pssr-description
      [pssrNewList]="pssrListSelected"
      [project]="project"
      [orgId]="orgId"
      [selectedBU]="selectedBU"
      (initiateAllPssrsEvent)="initiateAllPssrsEvent()"
    ></app-pssr-description>
  </div>
</div>
<!--/ End PSSR List by Project Selected-->

<!-- Back Project List btn -->
<div class="box-shadow module">
  <div class="row" style="margin-top: 15px">
    <div class="col-xs-3">
      <button
        id="backtoprojectslistBtn"
        type="button"
        class="btn btn-default btn-block"
        style="width: 80%; white-space: normal"
        (click)="backProjectList()"
      >
        {{ "Back to Project List" | translate }}
      </button>
      &nbsp;
    </div>
  </div>
</div>
