import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import {
  EndDeviceTypeService,
  BusinessUnitsService,
} from '#services/api/index';

import { BusinessUnit, EndDeviceType } from '#models/index';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-end-device-type',
  templateUrl: './end-device-type.component.html',
  styleUrls: ['./end-device-type.component.scss'],
})
export class EndDeviceTypeComponent implements OnInit {
  //#region VARIABLES

  selectedBU: BusinessUnit;
  businessUnitList: BusinessUnit[];

  lstEndDeviceType: EndDeviceType[];

  //#endregion

  constructor(
    private toastrService: ToastrService,
    public svcEndDeviceType: EndDeviceTypeService,
    public router: Router,

    private businessUnitsSvc: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.loadBusinessUnits();
  }

  loadBusinessUnits(): void {
    const bus = this.businessUnitsSvc.getUserBUs();
    bus.subscribe(
      (data) => {
        if (data.length) {
          this.businessUnitList = data;
          this.selectedBU = this.businessUnitList[0];
          this.loadEndDeviceTypesByBU();
        }
      },
      (err) => {
        this.showMessage('Error retrieving Business Units: ' + err.message, 1);
      }
    );
  }

  onRedirectButtonClick(url: string, id: number): void {
    this.router.navigate([url, { itemId: id, parentId: this.selectedBU.Id }]);
  }

  async loadEndDeviceTypesByBU() {
    if (this.selectedBU.Id > 0) {
      const endType = this.svcEndDeviceType.getEndDeviceTypeByBU(
        this.selectedBU.Id
      );

      endType.subscribe(
        (endDevs) => {
          this.lstEndDeviceType = [];
          this.lstEndDeviceType = endDevs;
        },
        (err) => {
          this.showMessage('Error retrieving Device Types: ' + err.message, 1);
        }
      );
    }
  }

  showMessage(info: string, dv: number) {
    if (dv === 1) {
      this.toastrService.showError(info);
    } else {
      this.toastrService.showSuccess(info);
    }
  }
}
