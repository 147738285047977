import { TaskPlanSection } from '#models/task-plan-section';

export class TaskPlan {
  public Code: string;
  public Description: string;
  public ID: number;
  public TaskPlanSections: TaskPlanSection[];

  public Name: string;
  public BusinessUnitId: number;
  public HideCode: boolean;
  TaskPlanType: number;
  NextItemID: number;
  DefaultWalkthroughSignatureDisclaimer: string;
  DefaultSummarySignatureDisclaimer: string;
  IsDeleted: boolean;
}
