<nav
  class="navbar navbar-default navbar-default-subsite centered"
  role="navigation"
>
  <div class="visible-xs">
    <div class="navbar-header centered visible-xs">
      <div
        class="hamburger-mobile pull-left hidden-sm hidden-md hidden-lg dropdown dropdown-mobile"
      >
        <a
          href="#"
          class="dropdown-toggle pull-left"
          data-toggle="dropdown"
          data-target="#navbar-brand-centered-subsite"
          aria-expanded="false"
        >
          <span class="sr-only">
            {{ "Toggle navigation" | translate }}
          </span>
          <span
            class="glyphicon glyphicon-menu-hamburger black"
            aria-hidden="true"
          ></span>
        </a>
      </div>
    </div>
    <div class="navbar-brand navbar-brand-centered visible-xs">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"
          ><img
            alt="Chevron"
            src="/assets/images/hallmark.png"
            width="36"
            height="40"
        /></a>
      </div>
      <div class="sitename-container vertical-align">
        <a class="sitename light-blue font-gotham" href="/">inside chevron</a>
      </div>
    </div>
    <div
      class="search search-mobile pull-right hidden-sm hidden-md hidden-lg dropdown dropdown-mobile"
    >
      <a
        href="https://onesearch.sp.chevron.net/"
        class="dropdown-toggle pull-right"
      >
        <span
          class="glyphicon glyphicon-search black"
          aria-hidden="true"
        ></span>
        <span class="sr-only">{{ "toggle search" | translate }}</span>
      </a>
    </div>
  </div>

  <div

    class="collapse navbar-collapse js-navbar-collapse centered row hidden-xs font-gotham"
    id="navbar-brand-centered"
  >
    <ul
      *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin || (currentUser$ | async)?.IsOrgAdmin"
      class="nav navbar-nav navbar-left"
    >
      <li class="dropdown dropdown-large hamburger pull-left">
        <button
          type="button"
          class="navbar-toggle dropdown-toggle"
          data-toggle="dropdown"
          data-target="#navbar-hamburger-menu"
          aria-expanded="false"
        >
          <span class="sr-only">{{ "Toggle navigation" | translate }}</span>
          <span
            class="glyphicon glyphicon-menu-hamburger"
            aria-hidden="true"
          ></span>
          <span class="icon-text menu">{{ "Admin menu" | translate }}</span>
        </button>
      </li>
    </ul>
    <div class="navbar-brand navbar-brand-centered">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"
          ><img
            alt="Chevron"
            src="/assets/images/hallmark.png"
            width="42"
            height="46"
        /></a>
      </div>
      <div class="sitename-container vertical-align">
        <a class="sitename light-blue font-gotham" href="/">
          {{ "PreStartupSafetyReview" | translate }}
        </a>
      </div>
    </div>
    <ul class="nav navbar-nav navbar-right pull-right black">
      <li class="language-switcher">
        <p-dropdown
          id="language-switcher"
          [(ngModel)]="selectedLanguage"
          [options]="availableLanguages"
          (onChange)="selectLanguage($event)"
          [style]="{'width':'150px', 'text-align': 'left'}"
        >
          <ng-template let-item pTemplate="selectedItem">
            <span class="glyphicon glyphicon-globe">&nbsp;</span>
            <span [attr.lang]="item.value">{{ item.label | translate }}</span>
          </ng-template>
          <ng-template let-item pTemplate="item">
            <span [attr.lang]="item.value">{{ item.label | translateIn:item.value | async }}</span>
          </ng-template>

        </p-dropdown>
      </li>
      <li *ngIf="(currentUser$ | async)?.HasAccess" class="feedback" id="feedbackMenu">
        <div class="feedback-button" (click)="feedbackClick()">
          <img alt="Feedback" src="/assets/images/Feedback_blue_rgb.png" />
          <span class="icon-text">{{ "Feedback" | translate }}</span>
        </div>
      </li>
      <li *ngIf="(currentUser$ | async)?.HasAccess" class="guide">
        <div class="guide-button" (click)="userGuideClick()">
          <img alt="Guide" src="/assets/images/Guide.png" />
          <span class="icon-text">{{ "User Guide" | translate }}</span>
        </div>
      </li>
    </ul>
  </div>
</nav>

<nav class="navbar navbar-subsite centered" role="navigation">
  <div class="container-fluid">
    <div
      style="height: 100% !important"
      class="collapse navbar-collapse js-navbar-collapse centered row font-gotham navbar-mobile dropdown-menu *dropdown-menu-mobile"
      id="navbar-brand-centered-subsite"
    >
      <div class="mobile-nav-container vertical-align flex-center centered">

        <ul class="nav navbar-nav primary">
          <li
            *ngIf="(currentUser$ | async)?.HasAccess"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            (click)="redirect('/pssr-list')"
          >
            <ng-container *ngIf="currentBusinessUnit$ | async as bu">
              <a id="pssrListMainMenu">{{ "NavigateToMainPage" | translate: bu }}</a>
            </ng-container>
            <ng-container *ngIf="!(currentBusinessUnit$ | async) as bu">
              <a id="pssrListMainMenu">{{ "PSSRs" | translate }}</a>
            </ng-container>
          </li>
          <li
            *ngIf="(currentUser$ | async)?.HasAccess"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            (click)="redirect('/pssr-project')"
          >
            <a>{{ (selectedBU?.EventLabel | translate) || ("Projects" | translate) }}</a>
          </li>
          <li
            *ngIf="(currentUser$ | async)?.HasAccess"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            (click)="redirect('/pssr-action-item-list')"
          >
            <a>{{ "Action Items" | translate }}</a>
          </li>
          <li
            *ngIf="!(currentUser$ | async)?.IsSystemAdmin && !(currentUser$ | async)?.IsBUAdmin && !(currentUser$ | async)?.IsOrgAdmin"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
          ></li>

          <li
            *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin || (currentUser$ | async)?.IsOrgAdmin"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue"
            id="administrationMenu"
          >
            <a
              class="dropdown-toggle offcanvas-link vertical-align ie10-flex-start"
              data-toggle="offcanvas-subsite"
            >
              <span class="link-text">{{ "Administration" | translate }}</span>
            </a>
            <div class="dropdown-menu row row-offcanvas row-offcanvas-right">
              <div
                *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin || (currentUser$ | async)?.IsOrgAdmin"
                class="sidebar-offcanvas"
              >
                <div class="sub-menu clear-both">
                  <div class="nav-header nav-header-2 visible-xs">
                    <a
                      href="#"
                      class="offcanvas-link vertical-align ie10-flex-start"
                    >
                      <span class="link-text">nav-header-2</span>
                    </a>
                  </div>
                  <ul class="list-unstyled icon-list">
                    <li
                      id="users-menu"
                      class="level-2 menu-hover"
                      (click)="redirect('/admin/users')"
                    >
                      <a>{{ "Users" | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin" class="sidebar-offcanvas">
                <div class="sub-menu clear-both">
                  <div class="nav-header nav-header-2 visible-xs">
                    <a
                      href="#"
                      class="offcanvas-link vertical-align ie10-flex-start"
                    >
                      <span class="link-text">nav-header-2</span>
                    </a>
                  </div>
                  <ul class="list-unstyled icon-list">
                    <li
                      class="level-2 menu-hover"
                      (click)="redirect('/admin/groups')"
                    >
                      <a>{{ "UserGroups" | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin" class="sidebar-offcanvas">
                <div class="sub-menu clear-both">
                  <div class="nav-header nav-header-2 visible-xs">
                    <a
                      href="#"
                      class="offcanvas-link vertical-align ie10-flex-start"
                    >
                      <span class="link-text">nav-header-2</span>
                    </a>
                  </div>
                  <ul class="list-unstyled icon-list">
                    <li
                      class="level-2 menu-hover"
                      (click)="redirect('/admin/business-unit-user')"
                    >
                      <a>{{ "BUAdmins" | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin" class="sidebar-offcanvas">
                <div class="sub-menu clear-both">
                  <div class="nav-header nav-header-2 visible-xs">
                    <a
                      href="#"
                      class="offcanvas-link vertical-align ie10-flex-start"
                    >
                      <span class="link-text">nav-header-2</span>
                    </a>
                  </div>
                  <ul class="list-unstyled icon-list">
                    <li
                      class="level-2 menu-hover"
                      (click)="redirect('/admin/area')"
                    >
                      <a>{{ "Facility Hierarchy" | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin" class="sidebar-offcanvas">
                <div class="sub-menu clear-both">
                  <div class="nav-header nav-header-2 visible-xs">
                    <a
                      href="#"
                      class="offcanvas-link vertical-align ie10-flex-start"
                    >
                      <span class="link-text">nav-header-2</span>
                    </a>
                  </div>
                  <ul class="list-unstyled icon-list">
                    <li
                      class="level-2 menu-hover"
                      (click)="onRedirectButtonClick('admin/task-plan')"
                    >
                      <a>{{ "PSSR Templates" | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>

              <div *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin" class="sidebar-offcanvas">
                <div class="sub-menu clear-both">
                  <div class="nav-header nav-header-2 visible-xs">
                    <a
                      href="#"
                      class="offcanvas-link vertical-align ie10-flex-start"
                    >
                      <span class="link-text">nav-header-2</span>
                    </a>
                  </div>
                  <ul class="list-unstyled icon-list">
                    <li
                      class="level-2 menu-hover"
                      id="categoriesMenu"
                      (click)="redirect('/admin/category')"
                    >
                      <a>{{ "Categories" | translate }}</a>
                    </li>
                    <li  *ngIf="isSysAdmin"
                      class="level-2 menu-hover"
                      (click)="redirect('/admin/subcategory')"
                    >
                      <a>{{ "Sub Categories" | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <div
          role="dialog"
          tabindex="-1"
          aria-label="Utility Menu"
          id="navbar-hamburger-menu"
          class="utility-nav dropdown"
        >
          <div class="dropdown-menu show-xs">
            <div class="small-nav-container">
              <div *ngIf="(currentUser$ | async)?.IsSystemAdmin" class="small-nav small-nav-corp-links">
                <hr class="hr-mobile-nav visible-xs" />
                <ul class="list-unstyled icon-list">
                  <li class="menu-hover">
                    <a [routerLink]="['/admin/system-admin']">{{
                      "Sys Admin" | translate
                    }}</a>
                  </li>
                </ul>
              </div>

              <div *ngIf="(currentUser$ | async)?.IsSystemAdmin" class="small-nav small-nav-corp-links">
                <hr class="hr-mobile-nav visible-xs" />
                <ul class="list-unstyled icon-list">
                  <li class="menu-hover">
                    <a [routerLink]="['/admin/mobile-app-versions']">{{
                      "Mobile App Versions" | translate
                    }}</a>
                  </li>
                </ul>
              </div>

              <div
                *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin"
                class="small-nav small-nav-corp-links"
              >
                <hr class="hr-mobile-nav visible-xs" />
                <ul class="list-unstyled icon-list">
                  <li class="menu-hover">
                    <a [routerLink]="['/admin/business-units']">{{
                      "Business Units" | translate
                    }}</a>
                  </li>
                </ul>
              </div>
              <div
                *ngIf="(currentUser$ | async)?.IsSystemAdmin || (currentUser$ | async)?.IsBUAdmin || (currentUser$ | async)?.IsOrgAdmin"
                class="small-nav small-nav-corp-links"
              >
                <hr class="hr-mobile-nav visible-xs" />
                <ul class="list-unstyled icon-list">
                  <li class="menu-hover">
                    <a [routerLink]="['/admin/organizations']">{{
                      "Organizations" | translate
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mobile-nav-backdrop visible-xs"></div>
    </div>
  </div>
</nav>
