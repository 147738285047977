import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-task-plan-sub-section-edit',
  templateUrl: './task-plan-sub-section-edit.component.html',
})
export class TaskPlanSubSectionEditComponent implements OnInit {
  //#region VARIABLES

  taskPlanId: number;
  taskPlanSubSection: TaskPlanSubSection = new TaskPlanSubSection();
  displayWarningDesc: boolean;
  sectionId: number;
  taskPlanPage: string;

  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.taskPlanId = +v['taskPlanId'];
      this.taskPlanSubSection.ID = +v['id'];
      this.sectionId = +v['sectionId'];
      this.taskPlanPage = v['page'];
    });
    this.displayWarningDesc = false;
    this.getTaskPlanSubSectionById(this.taskPlanSubSection.ID);
  }

  getTaskPlanSubSectionById(id: number) {
    this.taskPlanSvc
      .getTaskPlanSubSectionById(id)
      .toPromise()
      .then((data) => (this.taskPlanSubSection = data));
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/task-plan-manage',
      {
        id: this.taskPlanId,
        sectionId: this.sectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public updateTaskPlanSubSection(): void {
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(
      this.taskPlanSubSection.Description
    );
    if (!this.displayWarningDesc) {

      this.taskPlanSvc
        .updateTaskPlanSubSection(this.taskPlanSubSection)

        .subscribe(() => {
          this.onRedirectButtonClick();
        });
    }
  }
}
