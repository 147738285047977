<div class="box-shadow module">

  <h2>{{ "Create Sub Category" | translate }}</h2>
  <hr />
  <div class="form-horizontal" [formGroup]="subCategoryForm">
    <div class="form-group">
      <label class="control-label col-md-2">{{ "Name" | translate }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          formControlName="Name"
        />
        <span class="text-danger field-validation-error" *ngIf="displayWarning">
          {{ "This field is required" | translate }}
        </span>
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <button
          class="btn btn-default"
          style="width: 100px"
          (click)="createSubCategory(subCategoryForm.value)"
        >
          {{'Create'|translate}}
        </button>
      </div>
    </div>
  </div>
  <div>
    <a [routerLink]="['/admin/subcategory']" style="cursor: pointer"
      >{{'Back to Sub Category List'|translate}}</a
    >
  </div>
</div>
