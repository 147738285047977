import { Component, OnInit, Input } from '@angular/core';
import { EndDevice } from '#models/end-device';
import { EndDeviceService } from '#services/api/end-device.service';
import { Router } from '@angular/router';
import { EndDeviceTypeService } from '#services/api/end-device-type.service';

import { EndDeviceTemplateService } from '#services/api/end-device-template.service';
import { finalize } from 'rxjs/operators';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-end-device-list',
  templateUrl: './end-device-list.component.html',
  styleUrls: ['./end-device-list.component.scss'],
})
export class EndDeviceListComponent implements OnInit {
  //#region VARIABLES

  @Input() parentID: number;
  @Input() buId: number;

  lstEndDevice: EndDevice[] = [];
  selectedDevice: EndDevice;

   firstEndDevId: number;
  lastEndDevId: number;

  endDevPosChange = false;

  //#endregion

  constructor(
    private toastrService: ToastrService,
    public svcEndDevice: EndDeviceService,
    public svcEndDeviceTemplate: EndDeviceTemplateService,
    public svcEndDeviceType: EndDeviceTypeService,

    private router: Router,
  ) {}

  ngOnInit() {
    this.loadEndDevices();
  }

  private loadEndDevices() {

    if (this.parentID > 0) {
      const endDev = this.svcEndDeviceTemplate
        .getAllByEquipTemplate(this.parentID);

      endDev.subscribe((endDevs) => {
        if (endDevs.length) {
          this.lstEndDevice = endDevs.sort((a, b) => a.SortID - b.SortID);
          this.lstEndDevice = this.lstEndDevice.filter((x) => x.SortID > 0);
          this.firstEndDevId = this.lstEndDevice[0].Id;
          this.lastEndDevId = this.lstEndDevice[
            this.lstEndDevice.length - 1
          ].Id;
        }
      }, (err) => {
        this.showMessage('Error retrieving End Devices: ' + err, 1);

      });
    }
  }

  onRedirectButtonClick(url: string, id: number, pId: number): void {
    this.router.navigate([
      url,
      { itemId: id, parentId: this.parentID, buId: this.buId },
    ]);
  }

  moveDeviceUp(endDevId: number) {
    this.endDevPosChange = true;
    const endDevs = this.svcEndDeviceTemplate.moveEndDevUp(endDevId).pipe(
      finalize(() => (this.endDevPosChange = false)),

    );
    endDevs.subscribe(async (status) => {
      if (status.Success) {
        this.loadEndDevices();
      }
    }, (err) => {
      this.showMessage('Error up Device sort order: ' + err, 1);

    });
  }

  moveDeviceDown(endDevId: number) {
    this.endDevPosChange = true;
    const endDevs = this.svcEndDeviceTemplate.moveEndDevDown(endDevId).pipe(
      finalize(() => (this.endDevPosChange = false)),

    );
    endDevs.subscribe(async (status) => {
      if (status.Success) {
        this.loadEndDevices();
      }
    }, (err) => {
      this.showMessage('Error down Device sort order: ' + err, 1);

    });
  }

  deviceToDelete(d: EndDevice) {
    this.selectedDevice = d;
  }

  deleteSelectedDevice() {
    const endDev = this.svcEndDeviceTemplate
      .delete(this.selectedDevice.Id)
      .pipe(
        finalize(() => {
          // TODO implement
        })
      );
    endDev.subscribe(() => {
      this.loadEndDevices();
    }, (err) => {
      this.showMessage('Error deleting End Device: ' + err, 1);

    });
  }

  showMessage(info: string, dv: number) {
    if (dv === 1) {
      this.toastrService.showError(info);
    } else {

      this.toastrService.showSuccess( info);
    }

  }
}
