<div class="box-shadow module">

    <h2>{{'Create'|translate}} {{selectedBU.HierarchyLabel1|translate}}</h2>
    <hr>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-md-2">{{'Name'|translate}}</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="area.Name">
                <span class="text-danger field-validation-error" *ngIf="displayWarningArea">
                    <span>{{'This field is required' | translate }}.</span>
                </span>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-default" style="width: 100px;" (click)="createArea()">{{'Create'|translate}}</a>
            </div>
        </div>
    </div>
    <div>
        <a (click)="onRedirectButtonClick(area.BusinessUnitID)" style="cursor: pointer;">{{'Back to'|translate}} {{selectedBU.HierarchyLabel1 |translate}} List</a>
    </div>
</div>
