<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">
    <h2>{{'Edit Task Plan Section'|translate}}</h2>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-md-2">{{'Description'|translate}}</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="taskPlanSection.Description">
                <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
                    <span>{{'The Task Plan field is required'|translate}}.</span>
                </span>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-md-2">{{'Section Type'|translate}}</label>
            <div class="col-md-10">

                <select class="form-control" [(ngModel)]="taskPlanSection.SectionType" value="taskPlanSection.SectionType">
                  <option value="1">{{'Mandatory'|translate}}</option>
                  <option value="2">{{'Default'|translate}}</option>
                  <option value="0">{{'Optional'|translate}}</option>
                  <option value="3">{{'Multiple'|translate}}</option>
                </select>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-default" style="width: 100px;" (click)="updateTaskPlanSection()">{{'Save'|translate}}</a>
            </div>
        </div>
    </div>
</div>
<div style="width:80%; margin:auto">
    <a (click)="onRedirectButtonClick()" style="cursor: pointer;">{{'Back to Task Plan Manage'|translate}}</a>
</div>

