<ng-container *ngIf="loadingCompleted">
  <div
    [appBUSwitcher]="selectedBU"
    class="box-shadow module"
    style="width: 80%; margin: auto; margin-bottom: 20px"
  >
    <h2>{{ "Edit Category" | translate }} - {{ category.Name }}</h2>
    <form class="form-horizontal">
      <div class="form-group row">
        <div class="col-md-6">
          <label class="control-label">{{ "Category" | translate }}</label>
          <br />
          <input
            pInputText
            [disabled]="!category.IsActive"
            type="text"
            name="category-name"
            style="width: 50%"
            [(ngModel)]="category.Name"
            required
          />
          &nbsp;
          <p-checkbox
            binary="true"
            name="isActive"
            [value]="category.IsActive"
            [label]="'Is Active' | translate"
            [(ngModel)]="category.IsActive"
          ></p-checkbox>
          <button
            pButton
            type="button"
            [label]="'Save' | translate"
            (click)="updateCategory()"
          ></button>
        </div>

        <div class="col-md-6" style="text-align: right">
          <label class="control-label">&nbsp;</label>
          <br />
          <button
            pButton
            name="add-remove-subcategories"
            [disabled]="!category.IsActive"
            type="button"
            [label]="'AddRemoveSubcategory' | translate"
            (click)="showDialog()"
          ></button>
        </div>
      </div>
    </form>

    <div class="row">
      <div class="col-md-offset-3 col-md-6">
        <h3>{{ "Subcategories" | translate }}</h3>
        <p-table [value]="category.SubCategories">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col">{{ "Name" | translate }}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td>{{ row.Name }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <div style="width: 80%; margin: auto">
    <a
      class="btn btn-link"
      [routerLink]="['/admin/category', { buId: businessUnitID }]"
      id="backToCategoryList"
    >
      {{ "Back to Category List" | translate }}
    </a>
  </div>

  <app-subcategory-dialog #dialog [category]="category">
  </app-subcategory-dialog>
</ng-container>
