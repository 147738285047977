import { Component, OnInit } from '@angular/core';
import { AuthService } from '#services/shared';
import { BusinessUnitsService, UserService } from '#services/api';
import { BusinessUnit } from '#models/index';
import { Router } from '@angular/router';
import { Utility } from '#services/shared/utility';
import { BusinessUnitStorageService } from '#services/shared/business-unit-storage.service';
import { Language } from '#services/translate/languages';
import { TranslateService } from '@ngx-translate/core';
import { LanguageLookupService } from '#services/api/language-lookup.service';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  //#region VARIABLES
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  isSysAdmin: boolean;
  availableLanguages: Array<{ label: string; value: Language }> = [];
  selectedLanguage: Language | string = 'en';

  //#endregion

  currentBusinessUnit$: Observable<BusinessUnit>;
  currentUser$: Observable<any>;




  constructor(
    private authSvc: AuthService,
    private businessUnitService: BusinessUnitsService,
    private businessUnitStorageService: BusinessUnitStorageService,
    private router: Router,
    private translateService: TranslateService,
    private languageLookupService: LanguageLookupService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.currentBusinessUnit$ = this.businessUnitStorageService.currentBusinessUnit$;

    this.currentUser$ = this.authSvc.loggedUser$;


    if ('initNavbarJS' in window) {
      (<any>window).initNavbarJS();
    }
    // this.loadBusinessUnits();
    const currentUser = this.authSvc.getCurrentUser();
    this.isSysAdmin = !!currentUser && currentUser.IsSystemAdmin;
    // Set languages for language switcher
    this.selectedLanguage =
      this.translateService.currentLang || localStorage.getItem('lang') || 'en';

    this.languageLookupService
      .getLanguagesPreferences()
      .toPromise()
      .then((languages) => {
        this.availableLanguages = languages;
      });
  }

  redirect(url: string) {
    this.router.navigate([url]);
  }
  feedbackClick() {
    this.router.navigate(['/feedback']);
  }
  userGuideClick() {
    window.open(
      'https://chevron.sharepoint.com/:f:/r/sites/Surface-FacilityandEquipmentOperationsProductLine/Shared%20Documents/Process%20Safety/Mobile%20PSSR/PSSR%20Tutorial%20and%20Guide?csf=1&web=1&e=XQCRPW', '_blank');
  }

  onRedirectButtonClick(url: string): void {
    this.router.navigate([url]);
  }

  loadBusinessUnits(): void {
    this.businessUnitService.getUserBUs().subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        this.selectedBU = this.lstBusinessUnit[0];
        this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
      }
    });
  }

  selectLanguage({ value: language }: { value: Language }): void {
    this.translateService.use(language);

    if (!this.authSvc.isAuthenticated()) { return; }

    this.userService
      .savePreferences({
        language,
      })
      .pipe(take(1))
      .subscribe(
        () => {
          this.authSvc.setCurrentUser({
            PreferredLanguage: language,
          });
        },
        console.error
      );
  }
}
