import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanItem, TaskPlanItemType } from '#models/task-plan-item';

import { Router, ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-task-plan-item-edit',
  templateUrl: './task-plan-item-edit.component.html',
})
export class TaskPlanItemEditComponent implements OnInit {
  //#region VARIABLES

  taskPlanId: number;
  sectionId: number;
  taskPlanItem: TaskPlanItem = new TaskPlanItem();
  displayWarningCode: boolean;
  displayWarningDesc: boolean;
  displayRichEditor: boolean;
  taskPlanPage: string;

  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.taskPlanId = +v['taskPlanId'];
      this.sectionId = +v['sectionId'];
      this.taskPlanPage = v['page'];
      this.getItemById(v['itemId']);
    });
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/task-plan-manage',
      {
        id: this.taskPlanId,
        sectionId: this.sectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public getItemById(id: number): void {

    this.taskPlanSvc
      .getTaskPlanItemById(id)

      .subscribe((data: any) => {
        this.taskPlanItem = data;
        this.displayRichEditor = this.taskPlanItem.Description && this.taskPlanItem.Description.startsWith('<');
      });
  }

  public updateTaskPlanItem(): void {
    this.displayWarningCode = this.taskPlanSvc.isInvalidString(
      this.taskPlanItem.Code
    );
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(
      this.taskPlanItem.Description
    );
    if (!this.displayWarningDesc && !this.displayWarningCode) {

      this.taskPlanSvc
        .updateTaskPlanItem(this.taskPlanItem)

        .subscribe((data: any) => {
          if (data.Success) {
            this.onRedirectButtonClick();
          }
        });
    }
  }

  onItemTypeChanged(itemType: TaskPlanItemType) {
    if (itemType !== TaskPlanItemType.Default) {
      this.taskPlanItem.ShouldAddCommentToQuestion = false;
    }
  }
}
