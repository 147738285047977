import { PSSRFile } from '#models/pssr-file';
import { FileService } from '#services/shared/file.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-download',
  template: ``,
})
export class DownloadComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fileService: FileService
  ) { }

  ngOnInit() {
    const pssrFile: PSSRFile = this.route.snapshot.data['file'];
    if (pssrFile !== null) {
      this.fileService.downloadByClick({
        file: pssrFile.File,
        contentType: pssrFile.ContentType,
        description: pssrFile.Description,
      });
    }
    this.router.navigate([{ outlets: { download: null } }]);
  }
}
