import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { EquipmentTemplateService } from '#services/api/equipment-template.service';

import { Project, BusinessUnit } from '#models/index';
import { EquipmentTemplate } from '../../../models/equipment-template';
import { tap } from 'rxjs/operators';

import { BusinessUnitsService } from '#services/api';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.scss'],
})
export class EquipmentFormComponent implements OnInit {
  //#region VARIABLES

  selectedBU: BusinessUnit = new BusinessUnit();
  businessUnitList: BusinessUnit[];
  businessUnitId: number;

  equipmentTemplate: EquipmentTemplate = new EquipmentTemplate();
  isTemplate: boolean;
  equipmentId: number;
  projectId: number;
  project: Project;

  newMode = true;

  //#endregion

  constructor(
    private toastrService: ToastrService,
    public router: Router,

    public svcEquipmentTemplate: EquipmentTemplateService,
    private route: ActivatedRoute,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.equipmentId = this.route.snapshot.params['itemId'];
    this.businessUnitId = this.route.snapshot.params['parentId'];

    if (this.equipmentId > 0) {
      this.newMode = false;
      this.loadEquipment();
    } else {
      this.newMode = true;
    }

    this.businessUnitService
      .getBusinessUnitById(this.businessUnitId)
      .toPromise()
      .then((bu) => (this.selectedBU = bu));
  }

  onSubmitEquipment() {
    this.equipmentTemplate.BusinessUnitId = this.businessUnitId;
    this.equipmentTemplate.Description = this.equipmentTemplate.Description
      ? this.equipmentTemplate.Description
      : '';
    if (this.equipmentTemplate.Id > 0) {
      // Update
      const updEquip = this.svcEquipmentTemplate
        .updateTemplate(this.equipmentTemplate)
        .pipe(
          tap(
            () => {
              // --
            },
            (err) => {
              this.handleError(err);
            }
          )
        );
      updEquip.subscribe((updEquipTemp) => {
        this.equipmentTemplate = updEquipTemp;
        this.showMessage('Equipment Template updated successfully', 0);
      });
    } else {
      // Create
      const createEquip = this.svcEquipmentTemplate
        .createTemplate(this.equipmentTemplate)
        .pipe(
          tap(
            () => {
              // --
            },
            (err) => {
              this.handleError(err);
            }
          )
        );
      createEquip.subscribe((newTemp) => {
        this.equipmentTemplate = newTemp;
        this.equipmentId = newTemp.Id;
        this.newMode = false;
        this.showMessage('Equipment Template saved successfully', 0);
      });
    }
  }

  private async loadEquipment() {
    if (this.equipmentId > 0) {
      const equipTemp = this.svcEquipmentTemplate
        .getEquipTemplateById(this.equipmentId)
        .pipe(
          tap(() => {
            // --
          },
          (err) => {
            this.handleError(err);

          })
        );
      equipTemp.subscribe((equipTemps) => {
        this.equipmentTemplate = equipTemps;
      });
    }
  }

  private handleError(err: any) {
    if (err.status === 500) {
      this.showMessage(
        'The Equipment ' +
          this.equipmentTemplate.Name +
          ' already exist in the database.  Please consider change the Name.',
        1
      );
    } else {
      this.showMessage(
        'Error saving the Equipment Template: ' + err.message,
        1
      );
    }
  }

  showMessage(info: string, dv: number) {

    if (dv === 1) {
      this.toastrService.showError( info);
    } else {
      this.toastrService.showSuccess(  info);

    }
  }
}
