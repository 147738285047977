import { environment } from '#environments/environment';
import { PSSRFile } from '#models/pssr-file';
import { ResponseItemLink } from '#models/response-item';
import { PSSRResponseService, PSSRService } from '#services/api';

import { Utility } from '#services/shared/utility';
import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from '@angular/forms';

import { FileUpload } from 'primeng/fileupload';
import { AttachedFile } from './attached-file.enum';
import { FilesFormService } from './files-form.service';
import { FileService } from '#services/shared/file.service';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-files-form',
  templateUrl: './files-form.component.html',
  styles: [`
   :host {
     display: block;
   }
   .btn-link {
     outline: 0;
   }
  `],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesFormComponent),
      multi: true,
    },
    FilesFormService,
    PSSRService,
    PSSRResponseService,
  ],
})
export class FilesFormComponent implements OnInit, ControlValueAccessor {
  private value = [];

  @Input() filesList: PSSRFile[] = [];
  @Input() canUploadFiles = true;

  filesForm: FormGroup;

  @ViewChild(FileUpload) fileUpload: FileUpload;
  parsedLinks: ResponseItemLink[] = [];

  constructor(
    private fb: FormBuilder,
    private pssrService: PSSRService,
    private fileService: FileService,
    private filesFormService: FilesFormService,

    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.filesForm = this.fb.group({
      attachFileType: AttachedFile.ActionLink,
      addedActionLink: null,
      linkItem: '',
    });
    this.filesForm.setValidators(
      Validators.compose([this.filesFormService.validateForm])
    );
  }

  onTouched = () => {
    // TODO implement G
  }
  onChange = (v) => v;

  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value: string[]): void {
    this.value = value;

    this.filesForm.get('addedActionLink').setValue(null);
    this.filesForm.get('linkItem').setValue(null);

    this.onChange(value);
    this.onTouched();

    this.parsedLinks = this.filesFormService.parseLinks(value);
  }

  attachLink() {
    /* tslint:disable */
    let links = [...this.value],
      {
        attachFileType,
        linkItem,
        addedActionLink = { GUID: '', Description: '' },
      } = this.filesForm.value;
    /* tslint:enable */

    let description = '';
    switch (attachFileType) {
      case AttachedFile.ActionLink:
        description = Utility.extractDescriptionFromFileName(addedActionLink.Description);
        links = [
          ...links,
          `[${description}](${environment.azureContainerEndpoint}/${addedActionLink.GUID})`,
        ];
        this.writeValue(links);
        break;
      case AttachedFile.ProvidedLink:
        links = [...links, `[${linkItem}](${linkItem})`];
        this.writeValue(links);
        break;
    }
  }

  get accept(): string {
    return PSSRFile.acceptableExtensions;
  }

  removeLink(index: number): void {
    const links = this.value.filter((_, i) => i !== index);
    this.writeValue(links);
  }
  hasFilesPendingToUpload() {
    return (
      !!this.fileUpload &&
      !!this.fileUpload.files &&
      this.fileUpload.files.length
    );
  }
  getFiles() {
    if (!!this.fileUpload) { return this.fileUpload.files; }
    return [];
  }
  clearFiles() {
    if (this.fileUpload) { this.fileUpload.clear(); }
  }
  async openLink(link: ResponseItemLink): Promise<void> {
    const pssrFile = this.filesList.find(f => link.url.includes(f.GUID) && f.Description.includes(link.title));
    if (pssrFile && link.url.includes(environment.azureContainerEndpoint)) {
      try {
        const [fileGuid] = link.url.match(/([a-z0-9\-])+$/g);



        const res = await this.pssrService
          .downloadPSSRFileByGuid(pssrFile.PSSRID, fileGuid)
          .toPromise();

        this.fileService.downloadByClick({
          file: res.File,
          contentType: res.ContentType,
          description: res.Description,
        });
      } catch (e) {
        this.toastrService.showError( e.error.ExceptionMessage);
      }
    } else {
      const w = window.open(link.url, '_blank');
      if (!w) {
        this.toastrService.showWarning( 'Cannot open link');
      }
    }
  }
}
