<div class="box-shadow module">
  <h2>Create Group</h2>
  <hr />
  <div class="form-horizontal">


    <div class="form-group">
      <label class="control-label col-md-2">{{'Business Unit'|translate}}</label>
      <div class="col-md-8">
        <select
          [appBUSwitcher]="selectedBU"
          class="form-control"
          [(ngModel)]="selectedBU"
        >
          <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
            {{ bu.Name }}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{'Group Name'|translate}}</label>
      <div class="col-md-8">
        <input class="form-control" [(ngModel)]="groupName" />
      </div>
      <div class="col-md-2"></div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <button
          [disabled]="!(groupName && selectedBU)"
          class="btn btn-primary"
          style="width: 100px"
          (click)="create()"
        >
          {{'Create'|translate}}
        </button>
      </div>
    </div>
  </div>
  <div>
    <a (click)="onRedirectButtonClick()" style="cursor: pointer"
      >{{'Back to List'|translate}}</a
    >
  </div>
</div>
