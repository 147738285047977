import { BusinessUnit, PSSR } from 'models';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { BusinessUnitUser } from '#models/business-unit-user';
import { PSSRStatus } from '#models/pssr-status';
import { UserRole } from '#models/enum/user-role';
import { ReImportSharedUsersResultModel } from '#models/reimport-sharedusers-result-model';
import { ReImportSharedUsersStatus } from '#models/enum/update-shared-users-status';

@Injectable()
export class BusinessUnitsService {
  async reImportSharedUsers(businessUnitId: number, orgId: number, pageSize = 10): Promise<ReImportSharedUsersResultModel> {
    let currentPage = 0;
    let res: ReImportSharedUsersResultModel = {
      Errors: [],
      ImportedSharedUsers: 0,
      NotFoundSharedUsers: 0,
      Status: ReImportSharedUsersStatus.Success,
      TotalSharedUsers: 0
    };
    const results: ReImportSharedUsersResultModel[] = [];

    do {
      try {

        res =
          await this.http.put<ReImportSharedUsersResultModel>(
            `${environment.apiEndpoint}/business-unit/${businessUnitId}/re-import-shared-users`, {
            OrganizationId: orgId,
            PageNumber: currentPage + 1,
            PageSize: pageSize
          }).toPromise();
        results.push(res);

      } catch (e) {
        res.Status = ReImportSharedUsersStatus.Error;
        results.push(res);

      } finally {
        currentPage += 1;

      }
    } while (
      currentPage * pageSize <= res.TotalSharedUsers
    );


    const _return = results.reduce((a, b) => {
      return {
        Errors: [...a.Errors, ...b.Errors],
        ImportedSharedUsers: a.ImportedSharedUsers + b.ImportedSharedUsers,
        NotFoundSharedUsers: a.NotFoundSharedUsers + b.NotFoundSharedUsers,
        TotalSharedUsers: b.TotalSharedUsers,
        Status: ReImportSharedUsersStatus.Success
      };
    }, {
      Errors: [],
      ImportedSharedUsers: 0,
      NotFoundSharedUsers: 0,
      TotalSharedUsers: 0,
      Status: ReImportSharedUsersStatus.Success
    });

    return {
      ..._return,
      Status: results.every(r => r.Status === ReImportSharedUsersStatus.Error) ?
        ReImportSharedUsersStatus.Error :
        results.some(r => r.Status === ReImportSharedUsersStatus.Warning || r.Status === ReImportSharedUsersStatus.Error) ?
          ReImportSharedUsersStatus.Warning :
          ReImportSharedUsersStatus.Success
    };
  }

  constructor(private http: HttpClient) { }

  public getAll(): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(
      `${environment.apiEndpoint}/business-unit/all`
    );
  }

  public getUserAdminBUs(checkForOrganizationAdminUser = false): Observable<BusinessUnit[]> {
    return this.http.get<BusinessUnit[]>(
      `${environment.apiEndpoint}/business-unit/currentUserAdminBUs`
      , {
        params: {
          checkForOrganizationAdminUser: `${checkForOrganizationAdminUser}`
        }
      });
  }

  public getUserBUs() {
    return this.http.get<BusinessUnit[]>(
      `${environment.apiEndpoint}/business-unit/currentUserBUs`
    );
  }

  public getBusinessUnitById(businessUnitId: number) {
    return this.http.get<BusinessUnit>(
      `${environment.apiEndpoint}/business-unit/${businessUnitId}`
    );
  }

  public addBusinessUnit(businessUnit: BusinessUnit): Observable<BusinessUnit> {
    return this.http.post<BusinessUnit>(
      `${environment.apiEndpoint}/business-unit`,
      businessUnit
    );
  }

  public updateBusinessUnit(
    businessUnit: BusinessUnit
  ): Observable<BusinessUnit> {
    return this.http.put<BusinessUnit>(
      `${environment.apiEndpoint}/business-unit`,
      businessUnit
    );
  }

  public getBusinessUnitUsers(
    businessUnitId: number
  ): Observable<BusinessUnitUser[]> {
    return this.http.get<BusinessUnitUser[]>(
      `${environment.apiEndpoint}/business-unit/businessUnitUsers/${businessUnitId}`
    );
  }

  public addBusinessUnitUser(
    buUser: BusinessUnitUser
  ): Observable<BusinessUnitUser> {
    return this.http.post<BusinessUnitUser>(
      `${environment.apiEndpoint}/business-unit/addBusinessUnitUser`,
      buUser
    );
  }

  public removeBusinessUnitUser(id: number): Observable<BusinessUnitUser> {
    return this.http.put<BusinessUnitUser>(
      `${environment.apiEndpoint}/business-unit/removeBusinessUnitUser/${id}`,
      null
    );
  }

  public allowMembersToCompletePSSR(
    bu: BusinessUnit,
    pssr: PSSR
  ): boolean | null {
    if (
      bu &&
      bu.AllowMembersToCompletePSSR &&
      (pssr.Status === PSSRStatus.InProgress ||
        pssr.Status === PSSRStatus.Initiated)
    ) {
      return (
        pssr.PSSRUsers.Users.some(
          (u) => u.UserID === pssr.CurrentUser.UserID
        ) || pssr.CurrentUser.Role !== UserRole.None
      );
    }
    return null;
  }
}
