<div class="row" [formGroup]="filesForm">
  <div class="col-md-12">
    <label>{{ "Attached Files" | translate }}/{{'Links'|translate}}:</label>
    <div>
      <ng-container
        *ngIf="
          !(
            filesForm.controls.attachFileType?.value == 3 &&
            fileUpload?.files?.length > 0
          )
        "
      >
        <div style="margin-right: 10px; display: inline">
          <input
            type="radio"
            id="PSSRFiles"
            [value]="1"
            formControlName="attachFileType"
          />
          <label for="PSSRFiles" style="margin-left: 5px">
            {{ "Files" | translate }}
          </label>
        </div>
        <div style="margin-right: 10px; display: inline">
          <input
            type="radio"
            id="PSSRLinks"
            [value]="2"
            formControlName="attachFileType"
          />
          <label for="PSSRLinks" style="margin-left: 5px">{{
            "Links" | translate
          }}</label>
        </div>
      </ng-container>
      <div style="margin-right: 10px; display: inline">
        <input
          type="radio"
          id="PSSRFiles"
          [value]="3"
          formControlName="attachFileType"
        />
        <label for="PSSRFiles" style="margin-left: 5px">{{
          "Upload" | translate
        }}</label>
      </div>
    </div>
    <div class="row">
      <div
        [class.col-md-10]="filesForm.controls.attachFileType?.value != 3"
        [class.col-md-12]="filesForm.controls.attachFileType?.value == 3"
      >
        <ng-container *ngIf="filesForm.controls.attachFileType?.value == 1">
          <select class="form-control" formControlName="addedActionLink">
            <option *ngFor="let file of filesList" [ngValue]="file">
              {{ file.Description }}
            </option>
          </select>
        </ng-container>
        <ng-container *ngIf="filesForm.controls.attachFileType?.value == 2">
          <div [class.has-error]="filesForm?.errors?.invalidUrl">
            <textarea
              type="text"
              class="form-control"
              formControlName="linkItem"
            ></textarea>
            <span
              class="help-block"
              [innerHTML]="filesForm?.errors?.invalidUrl"
            ></span>
          </div>
        </ng-container>
        <ng-container *ngIf="filesForm.controls.attachFileType?.value == 3">
          <p-fileUpload
            #fileUpload
            [disabled]="!canUploadFiles"
            multiple="multiple"
            name="files[]"
            mode="advanced"
            [accept]="accept"
            [showCancelButton]="false"
            [chooseLabel]="'Select' | translate"
            [showUploadButton]="false"
            customUpload="true"
          >
          </p-fileUpload>
        </ng-container>
      </div>
      <div class="col-md-2">
        <button
          pButton
          *ngIf="filesForm.controls.attachFileType?.value != 3"
          class="col-xs-5 whiteSpaceBtn"
          style="width: 80px; float: right"
          type="button"
          label="{{ 'Attach' | translate }}"
          (click)="attachLink()"
          [disabled]="filesForm.invalid"
        ></button>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>{{ "Links" | translate }}</label>
        <div style="width: 100%">
          <div class="row" *ngFor="let link of parsedLinks; let i = index">
            <div style="padding: 0" class="col-sm-11">
              <button
                class="btn btn-link"
                (click)="openLink(link)"
                [innerHTML]="link.title"
              ></button>
            </div>
            <div style="padding: 0" class="col-sm-1" *ngIf="canUploadFiles">
              <button (click)="removeLink(i)" class="btn btn-sm btn-danger">
                <strong>X</strong>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

