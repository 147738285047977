
<div class="box-shadow module">
  <div class="row">
    <h2>{{ "Users" | translate }}</h2>
    <hr />
  </div>

  <div class="row padding-bottom-5">
    <div class="col-xs-6">
      <label>{{ "Business Unit" | translate }} :</label>
      <select
        [appBUSwitcher]="selectedBU"
        id="business-unit"
        class="form-control"
        [(ngModel)]="selectedBU"
        (change)="loadOrganizations()"
      >
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
    <div class="col-xs-6">
      <label>{{ selectedBU?.OrganizationLabel | translate }} :</label>
      <select
        class="form-control"
        [(ngModel)]="selectedOrg"
        (change)="loadUsers()"
      >
        <option *ngFor="let org of lstOrganization" [ngValue]="org">
          {{ org.Name }}
        </option>
      </select>
    </div>
  </div>

  <div class="row padding-bottom-5">
    <!--search free text-->
    <div class="col-xs-6 padding-0">
      <div class="group-item">
        <label>{{ "Search" | translate }}:</label>
        <input
          type="text"
          class="form-control"
          [ngModel]='searchText' 
          (keypress) = "checkForValidSymbols($event)"
          (ngModelChange)='searchChanged($event)'
          name="search"
          maxlength="50">
      </div>
    </div>
  </div>
  <div class="row" *ngIf="currentUser?.IsSystemAdmin && selectedBU?.CanImportSharedUsers"> 
    <div class="col-xs-12 col-md-3 pull-right text-right">
      <button class="btn btn-primary" (click)="reImportAllSharedUsers(selectedBU.Id, selectedOrg.Id)">{{ 'Reimport All Shared Users' | translate }}</button>
    </div>
  </div>
  <table class="table table-hover" style="margin-top: 20px">
    <tbody>
      <tr>
        <td class="col-xs-3">
          <b>{{ "Full Name" | translate }} </b>
        </td>
        <td class="col-xs-3">
          <b>{{ "Email" | translate }} </b>
        </td>
        <td class="col-xs-1">
          <b>{{ "CAI" | translate }} </b>
        </td>
        <td class="col-xs-4">
          <b
            class="col-xs-6"
            style="padding-right: 0px; padding-left: 0px; text-align: center"
            >{{ "Type" | translate }}</b
          >
          <b
            class="col-xs-6"
            style="padding-right: 0px; padding-left: 0px; text-align: center"
            >{{ "Is Admin" | translate }}</b
          >
        </td>
        <td class="col-xs-1" style="text-align: end">
          <button
            *ngIf="selectedOrg"
            id="add-user"
            type="button"
            class="btn btn-success"
            (click)="btnCreate()"
          >
            {{ "New" | translate }}
          </button>
        </td>
      </tr>

      <tr *ngFor="let orgUsr of orgUsers">
        <td class="col-xs-3">{{ orgUsr | fullName }}</td>
        <td class="col-xs-3">{{ orgUsr.Email }}</td>
        <td class="col-xs-1">{{ orgUsr.CAI }}</td>
        <td class="col-xs-4">
          <span
            class="col-xs-6"
            style="padding-right: 0px; padding-left: 0px; text-align: center"
          >
            {{
              orgUsr.Type === UserTypeUI.Chevron
                ? "Chevron"
                : orgUsr.Type === UserTypeUI.External
                ? "3rd Party (external)"
                : "Shared"
            }}
          </span>
          <input
            class="col-xs-6"
            #orgUserIsAdmin
            style="padding-right: 0px; padding-left: 0px; text-align: center"
            type="checkbox"
            [checked]="orgUsr?.IsAdmin"
            [disabled]="orgUsr.Type === UserTypeUI.Shared"
            (click)="grantAdminAccess(orgUsr, orgUserIsAdmin.checked)"
          />
        </td>
        <td class="col-xs-1" style="text-align: end">
          <div style="display:inline-flex;">
          <button
          *ngIf="orgUsr.HasAccess && orgUsr.Type === UserTypeUI.Shared"
          type="button"
          class="btn btn-primary edit-Button"
          (click)="btnEdit(orgUsr.OrganizationID, orgUsr.UserID)"
          > {{"Edit" || translate}}
        </button>
          <button
            *ngIf="orgUsr.HasAccess"
            type="button"
            class="btn btn-danger"
            (click)="removeUser(orgUsr)"
          >
            {{ "Remove" | translate }}
          </button>
          <button
            *ngIf="!orgUsr.HasAccess"
            type="button"
            class="btn btn-primary approve-user"
            (click)="btnEdit(orgUsr.OrganizationID, orgUsr.UserID)"
          >
            {{ "Approve" | translate }}
          </button>
        </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<p-confirmDialog #cd>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa-check pi pi-check"
      [label]="'Yes' | translate"
      (click)="cd.accept()"
    ></button>
    <button
      type="button"
      pButton
      icon="fa-close pi pi-times"
      [label]="'No' | translate"
      (click)="cd.reject()"
    ></button>
  </p-footer>
</p-confirmDialog>
