
<div class="box-shadow module">
  <h2>{{ "Create User" | translate }}</h2>
  <hr />
  <div class="form-horizontal">


    <div class="form-group">
      <label class="control-label col-md-2">{{
        "Business Unit" | translate
      }}</label>
      <div class="col-md-8">
        <select
          class="form-control"
          [(ngModel)]="selectedBU"
          (change)="loadOrganizations()"
        >
          <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
            {{ bu.Name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        selectedBU?.OrganizationLabel | translate
      }}</label>
      <div class="col-md-8">
        <select class="form-control" [(ngModel)]="selectedOrg">
          <option *ngFor="let org of lstOrganization" [ngValue]="org">
            {{ org.Name }}
          </option>
        </select>
      </div>
      <div class="col-md-2"></div>
    </div>

    <div class="form-group user-type-control">
      <label class="user-type-control-title col-xs-12  control-label col-md-2">{{
        "User Type" | translate
      }}</label>
      <div class="col-xs-7 row">
        <label class="user-type-control-item-title form-check-inline col-xs-3">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="1"
            [(ngModel)]="orgUsr.Type"
            (change)="removeUsrData()"
          />
          &nbsp;{{'Chevron'|translate}}
        </label>
        <label class="user-type-control-item-title form-check-inline col-xs-4">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="2"
            [(ngModel)]="orgUsr.Type"
            (change)="removeUsrData()"
          />
          &nbsp;{{ "3rdPartyexternal" | translate }}
        </label>
        <label class="user-type-control-item-title form-check-inline col-xs-3">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="3"
            [(ngModel)]="orgUsr.Type"
            (change)="removeUsrData()"
          />
          &nbsp;{{ "Shared" | translate }}
        </label>
      </div>
      <div class="col-xs-3 padding-0">&nbsp;</div>
    </div>
    <!-- Shared users list component -->
    <fieldset class="import-shared-users-options" *ngIf="selectedBU?.CanImportSharedUsers && selectedBU?.Code === BusinessUnitCode.TCO && orgUsr.Type === UserType.Shared">
      <div class="form-group">
        <div class="col-md-offset-2 col-md-8">
          <div class="checkbox">
            <label for="importSharedUserByTcoWhitePages-1" class="import-shared-users-option-container">
              <input type="radio" id="importSharedUserByTcoWhitePages-1" name="importSharedUserByTcoWhitePages"
                [(ngModel)]="orgUsr.IsTcoWhitePagesImported" [value]="true" class="import-shared-users-option-input"
                (ngModelChange)="onImportSharedUsersOptionSelected($event)">
              <div class="import-shared-users-option-text-container" >
                <h4 class="import-shared-users-option-title">{{ 'ImportSharedUserOptionWhitePagesTitle' | translate }}</h4>
                <p class="import-shared-users-option-description">{{ 'ImportSharedUserOptionWhitePagesDescription' | translate }}</p>
              </div>
            </label>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-offset-2 col-md-10">
          <div class="checkbox">
            <label for="importSharedUserByTcoWhitePages-2" class="import-shared-users-option-container">
              <input type="radio" id="importSharedUserByTcoWhitePages-2" name="importSharedUserByTcoWhitePages"
                [(ngModel)]="orgUsr.IsTcoWhitePagesImported" [value]="false" class="import-shared-users-option-input"
                (ngModelChange)="onImportSharedUsersOptionSelected($event)">
                <div class="import-shared-users-option-text-container">
                  <h4 class="import-shared-users-option-title">{{'ImportSharedUserOptionCustomTitle'|translate}} </h4>
                  <p class="import-shared-users-option-description">{{'ImportSharedUserOptionCustomDescription'|translate}}</p>
                </div>
            </label>
          </div>
        </div>
      </div>
    </fieldset>
    <!-- chevron-->
    <div *ngIf="orgUsr.Type ===  UserType.Chevron" class="form-group">
      <label class="control-label col-md-2"
      >{{'User'|translate}}<b style="color: red">&nbsp;*</b></label
      >
      <div class="col-md-8">
        <p-autoComplete
          [(ngModel)]="selectedUser"
          [suggestions]="users"
          (completeMethod)="getUsers($event)"
          name="usr"
          #usr="ngModel"
          field="FullName"
          delay="1000"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [minLength]="1"
          [placeholder]="'typeusercainame' | translate"
        >
        </p-autoComplete>
      </div>
    </div>
    <!-- external or shared -->
    <div *ngIf="orgUsr.Type ===  UserType.External || orgUsr.Type ===  UserType.Shared" class="form-group">
      <label class="control-label col-md-2">
        <ng-container *ngIf="orgUsr.Type ===  UserType.External">{{'First Name'|translate}}</ng-container>
        <ng-container *ngIf="orgUsr.Type ===  UserType.Shared">{{'Shared User'|translate}}</ng-container>
        <b style="color: red">&nbsp;*</b></label
      >
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="orgUsr.FirstName"
          [attr.placeholder]="'typenewuser' | translate"
        />
      </div>
    </div>
    <!-- external -->
    <div *ngIf="orgUsr.Type ===  UserType.External" class="form-group">
      <label class="control-label col-md-2">{{
        "Last Name" | translate
      }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="orgUsr.LastName"
          [placeholder]="'typelastname' | translate"
        />
      </div>
    </div>
    <!-- external or shared -->
    <div *ngIf="orgUsr.Type ===  UserType.External || orgUsr.Type ===  UserType.Shared" class="form-group">
      <label class="control-label col-md-2"
        >
        <ng-container *ngIf="orgUsr.Type ===  UserType.External">{{'Email'|translate}}</ng-container>
        <ng-container *ngIf="orgUsr.Type ===  UserType.Shared">{{'Shared Email'|translate}}</ng-container>
        <b style="color: red">&nbsp;*</b></label
      >
      <div class="col-md-8">
        <ng-container
          *ngIf="
            orgUsr.Type ===  UserType.External ||
            (!selectedBU.CanImportSharedUsers && orgUsr.Type ===  UserType.Shared)
          "
        >
          <input
            class="form-control text-box single-line"
            [(ngModel)]="orgUsr.Email"
            [placeholder]="'typeemail' | translate"
          />
        </ng-container>
        
        <div
          class="has-feedback"
          *ngIf="selectedBU.CanImportSharedUsers && orgUsr.Type ===  UserType.Shared"
        >
          <div [class.input-group]="orgUsr.IsTcoWhitePagesImported">
            <input
              type="email"
              required
              #sharedUserSearch="ngModel"
              email
              class="form-control"
              (keydown.enter)="
                sharedUserSearch.valid &&
                importSharedUsers(selectedBU?.Id, orgUsr.Email)
              "
              [(ngModel)]="orgUsr.Email"
              name="sharedUserSearch"
              id="sharedUserSearch"
              [minLength]="1"
              [placeholder]="'InputSharedUserEmail' | translate"
            />
            <span class="input-group-btn" *ngIf="orgUsr.IsTcoWhitePagesImported">
              <button
                class="btn btn-default"
                id="import-shared-user-btn"
                [disabled]="sharedUserSearch.invalid"
                type="button"
                (click)="importSharedUsers(selectedBU?.Id, orgUsr.Email)"
              >
                {{ "Import Shared User" | translate }}
              </button>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- Users list component -->
    <app-shared-user-form *ngIf="orgUsr.Type === UserType.Shared"
      id="sharedUserComponent"
      [sharedUsers]="orgUsr.ChildUsers"
      [disabled]="orgUsr.IsTcoWhitePagesImported"
      (userListChanged)="onSharedUsersListChanged($event)">
    </app-shared-user-form>

    <div class="form-group" *ngIf="orgUsr.Type !==  UserType.Shared">
      <label class="control-label col-md-2">{{
        "Organization Admin" | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="orgUsr.IsAdmin"
          value="orgUsr.IsAdmin"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-2 col-xs-12">
        (<b style="color: red">*</b>) {{ "Required Fields" | translate }}
      </div>
      <div class="col-md-offset-2 col-xs-offset-4 col-xs-3 col-md-8 text-right">
        <button [disabled]="sharedUsersCtrlInvalid" class="btn btn-default" style="width: 100px" (click)="create()">
          {{'Create'|translate}}
        </button>
      </div>
    </div>
  </div>
  <div>
    <button
      class="btn btn-link"
      id="back-to-users-list"
      (click)="onRedirectButtonClick()"
      style="cursor: pointer"
    >
      {{ "Back to List" | translate }}
    </button>
  </div>
</div>
