export enum AppVersionStatus {
    Current = 1,
    NotCurrent = 2,
    NotSupported = 3
}

export class AppVersionStatusDesc {
    static map = new Map<number, string>([
        [AppVersionStatus.Current, 'Current'],
        [AppVersionStatus.NotCurrent, 'Not Current'],
        [AppVersionStatus.NotSupported, 'Not Supported']
    ]);
}
