import {
  Component,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AuthService } from '#services/shared';
import { Utility } from '#services/shared/utility';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import * as MsalUtils from 'app/msal/utils';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private _languageSubscriber = new Subscription();

  isIframe = MsalUtils.isIframe;

  constructor(
    private authSvc: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    private messageService: MessageService
  ) {

    const { PreferredLanguage = 'en' } = this.authSvc.getCurrentUser();

    this.document.documentElement.lang = PreferredLanguage;

    this._languageSubscriber.add(
      this.translateService.onLangChange.subscribe(({ lang }) => {
        localStorage.setItem('lang', lang);
        this.document.documentElement.lang = lang;
      })
    );
    this.translateService.use(
      PreferredLanguage || localStorage.getItem('lang')
    );

  }

  showDisclaimer: boolean;
  currentUserId: number;

  ngOnInit(): void {

    const currentUser = this.authSvc.getCurrentUser();
    this.currentUserId = Utility.isValidObj(currentUser) ? currentUser.ID : 0;
    this.showDisclaimer =
      Utility.isValidObj(currentUser) &&
      !currentUser.DisclaimerAccepted &&
      currentUser.HasAccess;

  }

  iAccept() {
    this.authSvc.acceptDisclaimer(this.currentUserId);
  }

  ngOnDestroy() {
    this._languageSubscriber.unsubscribe();
  }

  @HostListener('window:beforeunload')
  clearToastrs() {
    this.messageService.clear();
  }
}
