import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { Equipment } from '#models/equipment';
import { EquipmentResult } from '../../models/equipment-result';
import { EquipmentTempGroup } from '../../models/equipment-group';


@Injectable()
export class EquipmentService {
    constructor(
        private http: HttpClient
    ) { }

    public getAllByProject(projectId: Number): Observable<Equipment[]> {
        return this.http.get<Equipment[]>(`${environment.apiEndpoint}/equipment/getByProject/${projectId}`);
    }

    public getEquipmentById(id: number): Observable<Equipment> {
        return this.http.get<Equipment>(`${environment.apiEndpoint}/equipment/${id}`);
    }

    public create(equipment: Equipment): Observable<Equipment> {
        return this.http.post<Equipment>(`${environment.apiEndpoint}/equipment`, equipment);
    }

    public update(equipment: Equipment): Observable<Equipment> {
        return this.http.put<Equipment>(`${environment.apiEndpoint}/equipment`, equipment);
    }

    public delete(id: number): Observable<Equipment> {
        return this.http.put<Equipment>(`${environment.apiEndpoint}/equipment/delete/${id}`, null);
    }

    public getEquipEndDevQtyByProject(projectId: Number): Observable<EquipmentResult> {
        return this.http.get<EquipmentResult>(`${environment.apiEndpoint}/equipment/getEquipEndDevQtyByProject/${projectId}`);
    }

    public createByGroup(group: EquipmentTempGroup): Observable<Equipment[]> {
        return this.http.post<Equipment[]>(`${environment.apiEndpoint}/equipment/createEquipmentsByGroup`, group);
    }
}

