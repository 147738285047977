import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'unescape',
})
export class UnescapePipe implements PipeTransform {
  private escapeRegex = /[<>]/g;

  transform(value: string): any {
    if (value == null) {
      return '';
    }

    if (this.escapeRegex.test(value)) {
      return value;
    }
    const parser = new DOMParser();
    const doc = parser.parseFromString(value, 'text/html');
    return doc.documentElement.textContent;
  }
}
