import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { Group } from '#models/group';
import { GroupUser } from '#models/group-user';

@Injectable()
export class GroupService {
  constructor(private http: HttpClient) {}

  public getCurrentUserAdminGroups(buId: number) {
    return this.http.get<Group>(
      `${environment.apiEndpoint}/group/currentUserGrps/${buId}`
    );
  }

  public getGroupsByBu(buId: number): Observable<Group[]> {
    return this.http.get<Group[]>(
      `${environment.apiEndpoint}/group/currentUserAdminGrps/${buId}`
    );
  }

  public addNewGroup(group: Group): Observable<Group> {
    return this.http.post<any>(`${environment.apiEndpoint}/group`, group);
  }

  public getGroupById(groupId: number): Observable<Group> {
    return this.http.get<any>(`${environment.apiEndpoint}/group/${groupId}`);
  }

  public updateGroup(group: Group): Observable<Group> {
    return this.http.put<any>(`${environment.apiEndpoint}/group`, group);
  }

  public addGroupUser(gUser: GroupUser) {
    return this.http.post<GroupUser>(`${environment.apiEndpoint}/group/addGroupUser`, gUser);
  }

  public removeGroupUser(id: number): Observable<GroupUser> {
    return this.http.put<GroupUser>(
      `${environment.apiEndpoint}/group/removeGroupUser/${id}`,
      null
    );
  }
}
