<div class="box-shadow module">
  <div class="row">
    <h2>{{ selectedBU?.OrganizationLabel | translate }}</h2>
    <hr />
  </div>


  <div class="row padding-bottom-5">
    <div class="col-xs-6">
      <label>{{ "Business Unit" | translate }} :</label>
      <select
        [appBUSwitcher]="selectedBU"
        class="form-control"
        [(ngModel)]="selectedBU"
        (change)="onChangeBU()"
      >
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
    <div class="col-xs-6 padding-0">&nbsp;</div>
  </div>

  <div class="form-horizontal row padding-bottom-10">
    <div class="col-md-6">
      <label>{{ selectedBU?.OrganizationLabel | translate }} :</label>
      <input
        class="form-control"
        [(ngModel)]="newOrganzationName"
        [attr.placeholder]="'NewOrganization'|translate"
      />
    </div>
  </div>
  <div class="row">
    <div *ngIf="canCreateOrganizations" class="col-md-1 col-md-offset-md-5">
      <label>&nbsp;</label>
      <br />
      <button
        type="button"
        [disabled]="!newOrganzationName"
        class="btn btn-primary"
        (click)="createOrganization()"
      >
        {{ "Save" | translate }}
      </button>
    </div>
  </div>
  <br />
  <table class="table table-hover">
    <tr>
      <th scope="col" class="col-xs-4">{{ "Business Unit" | translate }}</th>
      <th scope="col" class="col-xs-6">
        {{ selectedBU?.OrganizationLabel | translate }}
      </th>
      <th scope="col" class="col-xs-1">&nbsp;</th>
      <th scope="col" class="col-xs-1">&nbsp;</th>
    </tr>
    <tr *ngFor="let o of lstOrganization">
      <td class="col-xs-4">{{ selectedBU.Name }}</td>
      <td class="col-xs-6">{{ o.Name }}</td>
      <td class="col-xs-2" style="text-align: end">
        <button type="button" class="btn btn-primary" (click)="edit(o.Id)">
          {{ "Edit" | translate }}
        </button>
      </td>
    </tr>
  </table>
</div>
