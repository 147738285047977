import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {
  PSSR,
  PSSRResponse,
  BusinessUnit,
  Group,
  LoggedUser,
} from '#models/index';
import {
  PSSRService,
  UserService,
  BusinessUnitsService,
  PSSRResponseService,
  GroupService,
  PSSRImportService,
} from '#services/api/index';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponseItem } from '#models/response-item';

import { ConfirmationService } from 'primeng/api';
import { UserRole } from '#models/enum/user-role';
import { PSSRFile } from '#models/pssr-file';
import { AuthService } from '#services/shared';
import { PSSRStatus } from '#models/pssr-status';
import { Utility } from '#services/shared/utility';
import { takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ResponseItemStatus } from '#models/response-item-status';
import { PssrFileStorageService } from '#services/shared/pssr-file-storage.service';
import { TranslatePipe } from '@ngx-translate/core';
import { PssrEditHeaderComponent, PssrEditItemsComponent } from '#pages/shared';
import { ToastrService } from '#services/shared/toastr.service';
import { ApplicationType } from '#models/enum/application-type';
import { ImportStatus } from '#models/enum/import-status';
@Component({
  selector: 'app-pssr-edit',
  templateUrl: './pssr-edit.component.html',
  styleUrls: ['./pssr-edit.component.scss'],
  providers: [PssrFileStorageService, TranslatePipe],
})
export class PssrEditComponent implements OnInit, OnDestroy {
  PSSRStatus = PSSRStatus;

  get isVerbalApprovalEnabled(): boolean {
    return (
      this.pssr.Status === PSSRStatus.PendingApproval &&
      this.businessUnit.ShowVerbalApproval &&
      !this.pssrResponseService.hasActionItems({
        responses: this.pssr.lstResponse,
        standAloneItems: this.pssr.standAloneItems,
        priority: 3,
        statuses: [ResponseItemStatus.Rejected],
      })
    );
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private pssrService: PSSRService,
    public userService: UserService,
    public svcBU: BusinessUnitsService,
    private toastrService: ToastrService,
    public pssrResponseService: PSSRResponseService,
    private confirmationService: ConfirmationService,
    private groupService: GroupService,
    private pssrFileService: PssrFileStorageService,
    private pssrImportService: PSSRImportService,
    private translatePipe: TranslatePipe
  ) { }

  get canDelete(): boolean {
    const currentUser = this.authService.getCurrentUser();
    const rule1 =
      this.pssr.Status < PSSRStatus.Completed &&
      (currentUser.Email == this.pssr.CreatedBy ||
        this.isOrgAdmin ||
        this.isLead);
    const rule2 = this.pssr.Status != PSSRStatus.Closed && this.isBUAdmin;
    return this.isSysAdmin || rule1 || rule2;
  }

  get canReOpen(): boolean {
    return (
      this.isBUAdmin &&
      (this.pssr.Status === PSSRStatus.Approved ||
        this.pssr.Status === PSSRStatus.Closed)
    );
  }

  @ViewChild(PssrEditItemsComponent) pssrEditItems: PssrEditItemsComponent;
  @ViewChild(PssrEditHeaderComponent) pssrEditHeader: PssrEditHeaderComponent;

  pssr: PSSR;

  standAloneItems: ResponseItem[] = [];
  lstResponse: PSSRResponse[] = [];
  businessUnit: BusinessUnit = new BusinessUnit();
  canChangeStatus: boolean;
  isSysAdmin: boolean;
  isBUAdmin: boolean;
  isOrgAdmin: boolean;
  isLead: boolean;
  isOpsRepresentative: boolean;
  finalSignatureShowPad: boolean;
  pssrID: number;
  groups: Group[];

  pssrDocuments: Partial<PSSRFile>[] = [];
  pssrImages: Partial<PSSRFile>[] = [];
  pssrFiles: Partial<PSSRFile>[] = [];

  destroyed$ = new Subject<boolean>();

  projectId: number;
  buId: number;
  orgId: number;

  canReject: boolean;
  displayRejectModal: boolean;
  displayEmailReminderModal = false;
  rejectReason: string;
  textForReminder = '';
  user: LoggedUser;
  sendFreeText = false;
  readonly validExtensionsImagesUpload = [
    'application/pdf',
    ...PSSRFile.validImageExtensions,
  ].join();
  readonly validExtensionsDocumentsUpload =
    PSSRFile.validBinaryExtensions.join();

  PSSRStatusNames = PSSRStatus;

  async ngOnInit() {
    this.isLead = false;
    this.isOpsRepresentative = false;
    this.route.params.subscribe((params) => {
      this.pssrID = params['id'];
      this.projectId = params['projectId'];
      this.buId = params['buId'];
      this.orgId = params['orgId'];
    });

    this.user = this.authService.getCurrentUser();
    this.isSysAdmin = !!this.user.IsSystemAdmin;

    this.pssrFileService.files$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((files) => {
        this.pssrFiles = [...files];

        this.pssrDocuments = this.pssrFileService.extractBinaryFiles(files);
        this.pssrImages = this.pssrFileService.extractImageFiles(files);
      });

    this.loadChangebleCollections();
  }

  async loadChangebleCollections() {
    await this.loadPSSR(this.pssrID);
    await this.validateAuthorization(this.pssr.BusinessUnitID);
    await this.getBU(this.pssr.BusinessUnitID);
    await this.getFinalApproverSignature();
    await this.loadPSSRFiles();
    await this.loadGroups();
  }

  async loadGroups() {
    this.groups = [];
    this.groups = await this.groupService
      .getGroupsByBu(this.pssr.BusinessUnitID)
      .toPromise();
  }

  async loadPSSR(pssrId: number) {
    await this.pssrService
      .getDetails(pssrId)
      .toPromise()
      .then((data: PSSR) => {
        this.pssr = data;
        const approvers = this.pssr.PSSRUsers.Users.filter(
          (f) => f.Role === UserRole.Approver
        );
        this.pssr.FinalApprover = approvers.length > 1 ? null : approvers[0];
        this.isLead =
          this.pssr.CurrentUser.Role === UserRole.Lead ||
          this.pssr.CurrentUser.Role === UserRole.Approver;
        this.isOpsRepresentative =
          this.pssr.CurrentUser.Role === UserRole.OpsRep;

        this.canChangeStatus =
          Utility.isValidString(this.pssr.NextStatus) && !this.pssr.IsDeleted;

        this.canReject =
          this.pssr.Status === PSSRStatus.PendingApproval &&
          (this.pssr.CurrentUser.Role === UserRole.Approver || this.isBUAdmin);
        this.pssr.statusClass = this.pssrService.labelClass(this.pssr.Status);

        this.isBUAdmin = this.pssr && this.pssr.CurrentUser.IsBusinessUnitAdmin;
        this.isOrgAdmin = this.pssr && this.pssr.CurrentUser.IsOrgAdmin;
      });
  }

  async validateAuthorization(buid: number) {
    const buMatch = this.user.BusinessUnits.find((x) => x.Id == buid);
    if (!Utility.isValidObj(buMatch)) {
      this.router.navigate(['/error/not-authorized']);
    }
  }

  async getBU(buid: number): Promise<void> {
    await this.svcBU
      .getBusinessUnitById(buid)
      .toPromise()
      .then((data: BusinessUnit) => {
        this.businessUnit = data;
        this.pssr.buObj = data;
        this.pssr.buObj.PssrTitle = Utility.getPssrTitle(this.pssr.buObj);
      });
  }

  private getSignatureDate() {
    const pending = this.pssr.Status === PSSRStatus.PendingApproval;

    const hasDigitalSignature = Utility.isValidString(this.pssr.FinalSignature);

    if (hasDigitalSignature) {
      return Utility.parseToStringOffsetDate(this.pssr.SignatureDate);
    }

    if (pending) {
      return '';
    }

    if (this.businessUnit.AllowWetSignature && this.pssr.SignatureApproval) {
      return 'Approved by Wet Signature';
    }

    if (this.pssr.FinalApprover) {
      return this.pssr.approvedDate;
    }

    return '';
  }
  private getSignaturePdfDate() {
    const hasDigitalSignature = Utility.isValidString(this.pssr.FinalSignature);
    const pending = this.pssr.Status === PSSRStatus.PendingApproval;

    if (hasDigitalSignature) {
      return Utility.parseToStringOffsetDate(this.pssr.SignatureDate);
    }

    if (
      this.businessUnit.AllowWetSignature &&
      (pending || this.pssr.SignatureApproval)
    ) {
      return 'Date:';
    }
    if (this.pssr.FinalApprover) {
      return this.pssr.approvedDate;
    }

    return '';
  }

  async getFinalApproverSignature() {
    if (!Utility.isValidString(this.pssr.SignatureGUID)) {
      return;
    }
    await this.pssrResponseService
      .getImageByGUID(0, this.pssr.SignatureGUID)
      .toPromise()
      .then((data: any) => {
        this.pssr.FinalSignature = data.Photo;
        this.pssr.showSignature =
          this.pssr.FinalApprover &&
          (this.pssr.Status === PSSRStatus.PendingApproval ||
            this.pssr.Status === PSSRStatus.Approved ||
            this.pssr.Status === PSSRStatus.Closed);
        if (this.pssr.showSignature) {
          // -- has Final Approver's digital signature from mobile side
          const hasDigitalSignature = Utility.isValidString(
            this.pssr.FinalSignature
          );
          this.pssr.pssrSignatureLabel = 'Final Approver'; // !hasDigitalSignature && !wasWetSigned ? 'Final Approver' : 'Final Approval Signature';

          this.pssr.pssrSignaturePdfDate = this.getSignaturePdfDate();
          this.pssr.pssrSignatureDate = this.getSignatureDate();

          // -- show wet signature pad if :BU allows wet signature, doesnt have digital signature, is lead
          this.finalSignatureShowPad =
            this.isOpsRepresentative &&
            !hasDigitalSignature &&
            this.businessUnit.AllowWetSignature; // && wasWetSigned;
        }
      });
  }

  async loadPSSRFiles() {
    await this.pssrService
      .getPSSRFiles(this.pssr.Id)
      .toPromise()
      .then(
        (res: PSSRFile[]) => {
          this.pssr.Files = res;
          this.pssrFileService.files = res;
        },
        (error) => {
          this.toastrService.showError(error.error.ExceptionMessage);
        }
      );
  }
  public showEmailReminderDialog() {
    this.textForReminder = '';
    this.displayEmailReminderModal = true;

  }

  public async sendMOCToStartApproval() {
    try {
      const apiResponse = await this.pssrService.uploadToMOC(this.pssr.Id).toPromise();
      // @ts-ignore
      if (apiResponse.Success) {
        this.toastrService.showSuccess('Uploaded to MOC successfully');
      } else {
        // @ts-ignore
        this.toastrService.showError(apiResponse.Message);
      }
    } catch (error) {
      this.toastrService.showError(error.error.ExceptionMessage);
    }
  }

  public async importMOCAIs() {
    await this.save();

    try {
      const apiResponse = await this.pssrImportService.importMOCActions(this.pssr.Id).toPromise();
      await this.loadChangebleCollections();

      switch (apiResponse.Status) {
        case ImportStatus.Success:
          this.toastrService.showSuccess(
            this.translatePipe.transform('ImportActionItemsSuccessfully')
          );
          break;

        case ImportStatus.None:
          this.toastrService.showSuccess(
              this.translatePipe.transform('ImportActionItemsNone')
            );
          break;

        case ImportStatus.Warning:
          this.toastrService.showWarning(apiResponse.Errors.join('\r\n'));
          break;

        case ImportStatus.Error:
          this.toastrService.showError(apiResponse.Errors.join('\r\n'));
          break;

        default:
          throw new Error(`Unsupported status ${apiResponse.Status}`);
      }
    } catch (error) {
      this.toastrService.showError(error.error.ExceptionMessage);
    }
  }

  public canImportMOCAIs(): boolean {
    const isManuallyCreatedPSSR = (this.pssr.ApplicationId == null && this.pssr.ExternalId == null);
    const hasMOCNumber = Utility.isValidString(this.pssr.MOC);

    return this.pssr.Status <= PSSRStatus.Initiated && hasMOCNumber && isManuallyCreatedPSSR && !this.pssr.IsDeleted;
  }

  public isImportedMOC(): boolean {
    return this.pssr.ExternalId?.length > 0 && this.pssr.ApplicationId == ApplicationType.MOC;
  }

  public cancelSendReminder() {
    this.displayEmailReminderModal = false;

  }

  public sendEmail() {
    if (!this.sendFreeText) {
      this.displayEmailReminderModal = false;
      this.pssrService
        .sendEmailReminder(this.pssrID)
        .toPromise()
        .then(
          () => {
            this.toastrService.showSuccess('Emails successfully sent');
            this.pssr.EmailSentDate = new Date();

          },
          () => {
            this.toastrService.showError('Failed to send emails');

          }
        );
    } else {
      this.displayEmailReminderModal = false;
      this.pssrService
        .sendEmailReminderWithFreeText(this.pssrID, this.textForReminder)
        .toPromise()
        .then(
          () => {
            this.toastrService.showSuccess(this.translatePipe.transform('Emails successfully sent'));

            this.pssr.EmailSentDate = new Date();

          },
          () => {
            this.toastrService.showError(this.translatePipe.transform('Failed to send emails'));

          }
        );
    }
    this.sendFreeText = false;
  }

  public downloadPdf() {
    const observable = this.pssrService.download(this.pssrID).pipe(
      tap(
        () => {
          // --
        },
        () => {
          this.toastrService.showError(this.translatePipe.transform('Failed to download the pdf file'));
        }
      )
    );

    observable.subscribe((blob) => {
      const element = document.createElement('a');
      element.href = URL.createObjectURL(blob);

      const today = new Date();
      const mm =
        today.getMonth() + 1 < 10
          ? '0' + (today.getMonth() + 1)
          : (today.getMonth() + 1).toString();
      const dd =
        today.getDate() < 10
          ? '0' + today.getDate()
          : today.getDate().toString();
      const yyyy = today.getFullYear();
      const date = mm + '_' + dd + '_' + yyyy;
      element.download = 'PSSR_' + date + '_#' + this.pssr.Id + '_.pdf';
      element.click();
    });
  }

  setVerbalApprover() {
    const isEmpty =
      !this.pssr.VerbalApprovalBy || this.pssr.VerbalApprovalBy === '';

    if (isEmpty) {
      this.toastrService.showWarning(this.translatePipe.transform('The verbal approver CAI is required'));
    } else {
      this.pssr.VerbalApprovalOn = new Date();
      this.pssrService
        .update(this.pssr)
        .toPromise()
        .then(() => {
          this.toastrService.showSuccess(this.translatePipe.transform('Verbal approver succesfully saved'));
        });
    }
  }

  async save(changeStatus: boolean = false) {
    try {
      await this.pssrService
        .update({
          ...this.pssr,
          Files: [],
          PSSRUsers: {
            Users: [],
            ResultModel: {
              Message: '',
              Success: true,
            },
          },
        })
        .toPromise().then(
          (data) => {
            this.pssr.pssrIsValid = true;
            if (this.pssr.NextStatusId != PSSRStatus.Initiated) {
              this.toastrService.showSuccess(this.translatePipe.transform('General Information Saved Successfully'));
            }
          },
          (error) => {
            this.toastrService.showWarning(error.error.ExceptionMessage);
          }
        );

      this.pssrEditHeader.setupTranslation();
    } finally {
      if (changeStatus) {
        this.changeStatus();
      }
    }
  }

  btnBack() {
    if (this.projectId > 0) {
      this.router.navigate([
        'pssr-project-detail',
        { itemId: this.projectId, parentId: this.buId, orgId: this.orgId },
      ]);
    } else {
      setTimeout(() => { this.router.navigate(['/pssr-list']); }, 400); // To overcome Popup destory before routing to list page added setTimeout
    }
  }

  private async changeStatus() {
    try {
      await this.pssrService
        .changeStatus(this.pssr.Id, this.pssr.NextStatusId)
        .toPromise();

      if (this.pssr.NextStatusId == PSSRStatus.Initiated) {
        this.toastrService.showSuccess(this.translatePipe.transform('PSSR Initialized Successfully'));
      }

      await this.loadChangebleCollections();
    } catch (error) {
      this.toastrService.showError(error.error.ExceptionMessage);
    }
  }

  private async reopenPSSR() {
    try {
      await this.pssrService.reOpenPSSR(this.pssr.Id).toPromise();

      await this.loadChangebleCollections();
    } catch (error) {
      this.toastrService.showError(error.error.ExceptionMessage);
    }
  }

  showReopenPSSRDialog() {
    const message = this.translatePipe.transform('ConfirmPSSRReOpenMessage');
    const header = this.translatePipe.transform('ConfirmPSSRReOpenHeader');

    this.confirmationService.confirm({
      message,
      header,
      accept: () => this.reopenPSSR(),
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  showChangeStatusDialog() {
    if (this.pssr.NextStatusId == PSSRStatus.Approved) {
      const message = this.translatePipe.transform(
        'ConfirmPSSRApprovalMessage'
      ),
        header = this.translatePipe.transform('ConfirmPSSRApprovalHeader');

      this.confirmationService.confirm({
        message,
        header,
        accept: () => this.save(true),
        reject: () => {
          // TODO reject implementation
        },
      });
    } else if (
      this.pssr.NextStatusId === PSSRStatus.Completed &&
      this.pssr.Status === PSSRStatus.InProgress
    ) {
      this.confirmationService.confirm({
        message: this.translatePipe.transform('ContinueConfirmation'),
        header: '',
        accept: () => this.save(true),
        reject: () => {
          // TODO reject implementation
        },
      });
    } else if (
      this.pssr.Status === PSSRStatus.Initiated &&
      this.pssr.NextStatusId === PSSRStatus.InProgress
    ) {
      this.confirmationService.confirm({
        message: this.translatePipe.transform('ContinueConfirmation'),
        header: '',
        accept: () => this.save(true),
      });
    } else if (this.pssr.Status === PSSRStatus.Approved &&
      this.pssr.NextStatusId === PSSRStatus.Closed) {
      this.confirmationService.confirm({
        message: this.translatePipe.transform('CloseConfirmation'),
        header: '',
        accept: () => this.save(true),
        reject: () => {
          // TODO reject implementation
        },
      });
    } else {
      this.save(true);
    }
  }



  confirmDelete() {
    const message = this.translatePipe.transform('ConfirmDeletePSSRMessage');
    this.confirmationService.confirm({
      message: message,
      header: this.translatePipe.transform('ConfirmDeletePSSRTitle'),
      accept: () => {
        this.deletePssr();
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  async deletePssr() {
    try {
      await this.pssrService.deletePssr(this.pssr.Id).toPromise();

      this.pssr.IsDeleted = true;

      this.btnBack();
    } catch (error) {
      this.toastrService.showError(error.error.ExceptionMessage);
    }
  }

  restorePssr() {
    this.pssrService
      .restorePssr(this.pssr.Id)

      .toPromise()
      .then(
        ({
          IsDeleted = false,
          RestoredOn = new Date(),
          RestoredBy = 'test@test.com',
        }) => {
          this.pssr = {
            ...this.pssr,
            IsDeleted,
            RestoredOn,
            RestoredBy,
            statusClass: this.pssrService.labelClass(
              IsDeleted ? PSSRStatus.Cancel : this.pssr.Status
            ),
          };
        },
        (error) => {
          this.toastrService.showError(error.error.ExceptionMessage);
        }
      );
  }
  ngOnDestroy() {
    this.pssrFileService.clear();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onreloadItems(): void {
    this.pssrEditItems.reload();
  }

  rejectPSSR() {
    this.displayRejectModal = true;
  }

  async confirmReject() {
    this.displayRejectModal = false;
    this.pssr.RejectedReason = this.rejectReason;
    this.pssr.RejectedOn = new Date();
    this.pssr.RejectedBy = this.user.Email;

    await this.pssrService
      .update({
        ...this.pssr,
        Files: [],
        PSSRUsers: {
          Users: [],
          ResultModel: {
            Message: '',
            Success: true,
          },
        },
      })
      .toPromise();
    this.pssr.NextStatusId = PSSRStatus.InProgress;

    await this.changeStatus();
  }

  cancelReject() {
    this.displayRejectModal = false;
    this.rejectReason = null;
  }
}
