import { ManufacturerModel } from '#models/manufacturer-model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class ManufacturerModelService {
    constructor(
        private http: HttpClient
    ) { }

    public getAll(): Observable<ManufacturerModel[]> {
        return this.http.get<ManufacturerModel[]>(`${environment.apiEndpoint}/manufacturer-model/all`);
    }

    public create(manufModel: ManufacturerModel): Observable<ManufacturerModel> {
        return this.http.post<ManufacturerModel>(`${environment.apiEndpoint}/manufacturer-model`, manufModel);
    }

    public getById(id: number): Observable<ManufacturerModel> {
        return this.http.get<ManufacturerModel>(`${environment.apiEndpoint}/manufacturer-model/${id}`);
    }

    public update(manufacturerModel: ManufacturerModel): Observable<ManufacturerModel> {
        return this.http.put<ManufacturerModel>(`${environment.apiEndpoint}/manufacturer-model`, manufacturerModel);
    }

    public getByEndDevTypeId(endDevTypeId: number): Observable<ManufacturerModel[]> {
        return this.http.get<ManufacturerModel[]>(`${environment.apiEndpoint}/manufacturer-model/byEndDevTypeId/${endDevTypeId}`);
    }

    public delete(id: number) {
        return this.http.put<ManufacturerModel>(`${environment.apiEndpoint}/manufacturer-model/delete/${id}`, null);
    }
}
