import { PSSRFile } from '#models/pssr-file';
import { PSSR } from '#models/pssr';
import { PssrFileStorageService } from '#services/shared/pssr-file-storage.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessUnit } from '#models/business-unit';
import { PSSRUser } from '#models/pssr-user';
import { UserRole } from '#models/enum/user-role';
import { PSSRResponse } from '#models/pssr-response';
import { PSSRResponseService } from '#services/api';

@Component({
  templateUrl: './pssr-action-item.component.html',
  selector: 'app-pssr-action-item',
  host: {
    class: 'panel panel-primary',
  },
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
  providers: [PssrFileStorageService],
})
export class PssrActionItemComponent implements OnInit, OnDestroy {
  @Input('p')
  set pssr(value: PSSR) {
    this._pssr = value;
    this.pssrFileService.files = value.Files;
  }

  @Input() selectedBU = new BusinessUnit();
  @Input() shouldLoadActionItemImages = true;

  private _pssr = new PSSR();

  get p() {
    return this._pssr;
  }

  private get hasUsers() {
    return this.p && this.p.PSSRUsers && this.p.PSSRUsers.Users;
  }

  pssrFiles: Partial<PSSRFile>[] = [];
  destroyed$ = new Subject<boolean>();
  leads = '';
  approvers = '';

  constructor(private pssrFileService: PssrFileStorageService,
    private responseService: PSSRResponseService) {
    this.pssrFileService.files$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((files = []) => {
        this.pssrFiles = [...files];
      });
  }

  ngOnInit() {
    if (!this.hasUsers) {
      return;
    }


    this.leads = this.getListOfUsersByRole(this.p.PSSRUsers.Users, UserRole.Lead);
    this.approvers = this.getListOfUsersByRole(this.p.PSSRUsers.Users, UserRole.Approver);
  }

  private getListOfUsersByRole(users: PSSRUser[], role: UserRole): string {
    let list = '';

    for (const user of users) {
      if (user.Role === role) {
        if (list !== '') {
          list += `, ${user.UserName}`;
        } else {
          list += user.UserName;
        }
      }
    }

    return list;
  }

  isSectionLead(response: PSSRResponse): boolean {
    if (!this._pssr) {
      return false;
    }

    const currentUser: PSSRUser = this._pssr.CurrentUser;

    return this._pssr &&
      this._pssr.PSSRSectionUsers &&
      this._pssr.PSSRSectionUsers.SectionUsers.some(
        (sectionUser) =>
          this.responseService.isSectionLead({
            sectionDescription: response.SectionDescription,
            sectionId: response.SectionID,
            currentUser,
            businessUnitCode: this._pssr.BusinessUnitCode,
            sectionUser
          })


      );
  }


  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
