import { Component, OnInit } from '@angular/core';
import { SubAreaService } from '#services/api/sub-area.service';
import { SubArea } from '#models/sub-area';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';

import { TranslatePipe } from '@ngx-translate/core';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-sub-area-delete',
  templateUrl: './sub-area-delete.component.html',
  providers: [TranslatePipe]
})
export class SubAreaDeleteComponent implements OnInit {
  subArea: SubArea = new SubArea();
  subAreaName: string;
  displayWarningName: boolean;
  buId: number;
  hierarchyLabel2: string;
  hierarchyLabel3: string;



  selectedBU: BusinessUnit;
  confirmDeleteSubAreaTitle = '';
  confirmDeleteSubAreaDescription = '';

  constructor(
    private toastrService: ToastrService,
    private subAreaSvc: SubAreaService,

    private router: Router,
    private route: ActivatedRoute,
    private businessUnitService: BusinessUnitsService,
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit() {
    this.buId = this.route.snapshot.params['buId'];
    this.subArea.Id = this.route.snapshot.params['subAreaId'];
    this.subArea.AreaId = this.route.snapshot.params['areaId'];
    this.hierarchyLabel2 = this.route.snapshot.params['hierarchyLabel2'];
    this.hierarchyLabel3 = this.route.snapshot.params['hierarchyLabel3'];

    this.getSubAreaById(this.subArea.Id);
    this.displayWarningName = false;

    if (this.buId > 0) {
      this.businessUnitService
        .getBusinessUnitById(this.buId)
        .toPromise()
        .then((bu) => (this.selectedBU = bu));
    }

    this.confirmDeleteSubAreaTitle = this.translatePipe.transform(
      'ConfirmDeleteSubAreaTitle',
      this
    );
    this.confirmDeleteSubAreaDescription = this.translatePipe.transform(
      'ConfirmDeleteSubAreaDescription',
      this
    );
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/sub-area',
      { buId: this.buId, areaId: this.subArea.AreaId },
    ]);
  }

  getSubAreaById(id: number): void {

    this.subAreaSvc
      .getSubAreaById(id)

      .subscribe(
        (data: SubArea) => {
          this.subArea = data;
          this.subAreaName = this.subArea.Name;
        },
        (error) => {

          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }

  deleteSubArea(): void {

    this.subAreaSvc
      .deleteSubArea(this.subArea.Id)

      .subscribe(
        () => {
          this.onRedirectButtonClick();
        },
        (error) => {
          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }
}
