
<div *ngIf="loadingCompleted" class="box-shadow module">
  <h3 class="title">{{ "Mobile App Versions" | translate }}</h3>

  <p-table [value]="appVersions">
    <ng-template pTemplate="caption">
      <div class="top-controls">
        <button class="btn btn-success" (click)="toggleDialog(true)">
          {{ "Add Version" | translate }}
        </button>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th scope="col">{{ "Number" | translate }}</th>
        <th scope="col">{{ "Description" | translate }}</th>
        <th scope="col">{{ "Release Date" | translate }}</th>
        <th scope="col">{{ "Status" | translate }}</th>
        <th scope="col">{{ "Available" | translate }}</th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-version>
      <tr>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="version.Number" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ version.Number }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="version.Description" />
            </ng-template>
            <ng-template pTemplate="output">
              {{ version.Description }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>{{ version.ReleaseDate | date: "short" }}</td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="listAppVersionStatus"
                [(ngModel)]="version.Status"
              ></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{ version.Status | appVersionStatus }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td pEditableColumn>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <p-dropdown
                [options]="availabilityOptions"
                [(ngModel)]="version.IsDisabled"
                [style]="{ width: '75px' }"
                appendTo="body"
              ></p-dropdown>
            </ng-template>
            <ng-template pTemplate="output">
              {{ version.IsDisabled ? "No" : "Yes" }}
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>

    <ng-template *ngIf="!isEmpty" pTemplate="summary">
      <div class="bottom-controls">
        <button class="btn btn-info" (click)="saveChanges()">
          {{ "Save" | translate }}
        </button>
        <button class="btn btn-default" (click)="discard()">
          {{ "Discard" | translate }}
        </button>
      </div>
    </ng-template>
  </p-table>

  <!-- Empty Space -->
  <div *ngIf="isEmpty" style="text-align: center; color: #888; padding: 15px">
    <span style="font-size: 5em">
      <i class="pi pi-mobile"></i>
    </span>
    <p style="font-size: 2em">{{ "No mobile app versions yet" | translate }}</p>
  </div>

  <app-mobile-app-version-edit-form
    [show]="showDialog"
    [listAppVersionStatus]="listAppVersionStatus"
    (onCreate)="addVersion($event)"
    (onCancel)="toggleDialog($event)"
  >
  </app-mobile-app-version-edit-form>
</div>
