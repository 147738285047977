<div class="box-shadow module">
  <div class="row">
    <h2>{{'CreatePSSR'|translate:selectedBU}}</h2>
  </div>

  <div class="row">
    <h3>
      <div class="text-left">
        {{'General Information' | translate}} <br />
        <small>{{'Please fill up the fields' | translate}}.</small>
      </div>
    </h3>
  </div>

  <form (ngSubmit)="onSubmitPSSR()" #pssrForm="ngForm">





    <div class="row">

      <div class="col-xs-6">
        <div class="form-group">
          <label>{{selectedBU?.PSSRLabel | translate}}</label>
          <input type="text" class="form-control" [(ngModel)]="pssr.Name" name="pssrName" required
            #pssrName="ngModel" />
          <div [hidden]="pssrName.valid || pssrName.pristine" class="alert alert-warning" role="alert">
            {{'Title is required' | translate}}.
          </div>
        </div>
      </div>

      <div class="col-xs-2">
        <div class="form-group">
          <label>{{'MOC' | translate}}</label>
          <input type="text" class="form-control" [(ngModel)]="pssr.MOC" name="moc" #moc="ngModel" />
        </div>
      </div>

      <div class="col-xs-2">
        <div class="form-group">
          <label>{{'WO' | translate}}</label>
          <input type="text" class="form-control" [(ngModel)]="pssr.WO" name="wo" #wo="ngModel" />
        </div>
      </div>

      <div class="col-xs-2">
        <div class="form-group">
          <label>{{'Business Unit' | translate}} :</label>
          <select class="form-control" (change)="changeBU()" [appBUSwitcher]="selectedBU" [(ngModel)]="pssr.BusinessUnitID" name="businessUnit"
            required #businessUnit="ngModel">
            <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu.Id">{{ bu.Name }}</option>
          </select>
          <div [hidden]="businessUnit.valid || businessUnit.pristine" class="alert alert-warning" role="alert">
            {{'Business Unit is required' | translate}}.
          </div>
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-xs-3" [hidden]="lstOrgs && lstOrgs.length == 1">
        <div class="form-group">
          <label>{{selectedBU?.OrganizationLabel | translate}} :</label>
          <select class="form-control" [(ngModel)]="pssr.OrganizationID" (change)="loadProject()" name="org" required
            #org="ngModel">
            <option *ngFor="let org of lstOrgs" [ngValue]="org.Id">{{ org.Name }}</option>
          </select>
          <div [hidden]="org.valid || org.pristine" class="alert alert-warning" role="alert">
            {{selectedBU?.OrganizationLabel | translate}} is required.
          </div>
        </div>
      </div>

      <div class="col-xs-3">
        <div class="form-group">
          <label>{{selectedBU?.HierarchyLabel1 | translate}} :</label>
          <select class="form-control" (change)="changeArea()" [(ngModel)]="pssr.AreaID" name="area" required
            #area="ngModel">
            <option *ngFor="let area of lstArea" [ngValue]="area.Id">{{ area.Name }}</option>
          </select>
          <div [hidden]="area.valid || area.pristine" class="alert alert-warning" role="alert">
            {{selectedBU?.HierarchyLabel1|translate}} is required.
          </div>
        </div>
      </div>

      <div class="col-xs-3">
        <div class="form-group">
          <div class="row">
            <div class="col-xs-6" style="text-align: left; padding: 0px">
              <label>{{selectedBU?.HierarchyLabel2 | translate}} :</label>
            </div>
            <div class="col-xs-6" style="text-align: right; padding: 0px">
              {{'Create New' |translate}}:&nbsp;
              <input type="checkbox" name="createNewSubArea" value="createNewSubArea" [(ngModel)]="createNewSubArea"
                (click)="activateSubArea()">
            </div>
          </div>
          <ng-container *ngIf="displayFacility && !createNewSubArea">
            <select class="form-control" [(ngModel)]="pssr.SubAreaID" name="subArea" #subArea="ngModel" required
              (change)="changeSubArea()">
              <option *ngFor="let subarea of lstSubArea" [ngValue]="subarea.Id">{{ subarea.Name }}</option>
            </select>
            <div [hidden]="subArea.valid || subArea.pristine" class="alert alert-warning" role="alert">
              {{selectedBU?.HierarchyLabel2 | translate}} is required.
            </div>
          </ng-container>
          <ng-container *ngIf="!displayFacility || createNewSubArea">
            <input type="text" class="form-control" [(ngModel)]="newSubAreaName" name="subArea" #subArea="ngModel"
              required />
            <button type="button" class="btn btn-primary" (click)="addSubArea()" style="white-space: pre-wrap;">{{'Add'
              | translate}}</button>
            <div [hidden]="newSubAreaValidation" class="alert alert-warning" role="alert">
              {{selectedBU?.HierarchyLabel2 | translate}} is required or not been added.
            </div>
          </ng-container>
        </div>
      </div>

      <div *ngIf="displayFacility && selectedBU?.ShowHierarchyLabel3" class="col-xs-3">
        <div class="form-group">
          <div class="row">
            <div class="col-xs-6" style="text-align: left; padding: 0px">
              <label>{{selectedBU?.HierarchyLabel3 | translate}} :</label>
            </div>
            <div class="col-xs-6" style="text-align: right; padding: 0px">
              {{'Create New'|translate}}:&nbsp;
              <input type="checkbox" name="createNewFacility" value="createNewFacility" [(ngModel)]="createNewFacility">
            </div>
          </div>
          <ng-container *ngIf="!createNewFacility">
            <select class="form-control" [(ngModel)]="pssr.FacilityID" name="facility" #facility="ngModel">
              <option [ngValue]="null"></option>
              <option *ngFor="let facility of lstFacility" [ngValue]="facility.Id">{{ facility.Name }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf="createNewFacility && selectedBU?.ShowHierarchyLabel3">
            <input type="text" class="form-control" [(ngModel)]="newFacilityName" name="facility" #facility="ngModel" />
            <button type="button" class="btn btn-primary" (click)="addFacility()" style="white-space: pre-wrap;">{{'Add'
              | translate}}</button>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="row col-xs-12">
        <label>{{'Description' | translate}} :</label>
        <textarea cols="5" class="form-control" [(ngModel)]="pssr.Description" name="description"
          #description="ngModel" maxlength="5000" [placeholder]="'desc5000'|translate">
        </textarea>
        <div id="description-length" style="text-align: right;">
          {{pssr.Description.length}}/5000
        </div>
      </div>
    </div>

    <div class="row">
      &nbsp;
    </div>

    <div class="row">
      <div class="col-xs-3">
        <div class="form-group">
          <label>{{'Template' | translate}} :</label>
          <select class="form-control" [(ngModel)]="pssr.TaskPlanID" name="pssrTemplate" required #template="ngModel">
            <option *ngFor="let taskplan of lstTaskPlan" [ngValue]="taskplan.ID">{{ taskplan.Description }}</option>
          </select>
          <div [hidden]="template.valid || template.pristine" class="alert alert-warning" role="alert">
            {{'Template is required' | translate}}.
          </div>
        </div>
      </div>

      <div *ngIf="selectedBU?.ShowPssrType" class="col-xs-3">
        <div class="form-group">
          <label>{{'Type' | translate}} :</label>
          <select class="form-control" [(ngModel)]="pssr.PssrTypeId" name="pssrTypeId" #pssrTypeId="ngModel">
            <option *ngFor="let pt of pssrTypes" [ngValue]="pt.value">{{pt.label|translate}}</option>
          </select>
        </div>
      </div>

      <div *ngIf="selectedBU?.ShowProject" class="col-xs-3">
        <div class="form-group">
          <div class="row">
            <div class="col-xs-6" style="text-align: left; padding: 0px">
              <label>{{ selectedBU?.EventLabel | translate }} :</label>
            </div>
            <div class="col-xs-6" style="text-align: right; padding: 0px">
              {{'Create New'|translate}}:&nbsp;
              <input type="checkbox" name="createNewProject" value="createNewProject" [(ngModel)]="createNewProject">
            </div>
          </div>
          <ng-container *ngIf="!createNewProject">
            <select *ngIf="!selectedBU?.ShowProjectInput" class="form-control" [(ngModel)]="pssr.ProjectID"
              name="pssrProject" #pssrproject="ngModel" (change)="changeProject()">
              <option [ngValue]="null"></option>
              <option *ngFor="let project of lstProject" [ngValue]="project.Id">{{ project.Name }}</option>
            </select>
            <input *ngIf="selectedBU?.ShowProjectInput" type="text" class="form-control"
              [(ngModel)]="pssr.ProjectNumber" name="projNum" #projNum="ngModel" (change)="changeProject()" />
          </ng-container>
          <ng-container *ngIf="createNewProject">
            <input *ngIf="!selectFromQAQC" type="text" class="form-control" [(ngModel)]="newProjectName" name="project"
              #project="ngModel" />
              <p [hidden]="!createNewProject" class="alert alert-warning" role="alert">
                {{'Project Name is required'| translate}}
              </p>
            <select *ngIf="selectFromQAQC" class="form-control" [(ngModel)]=newQAQCProject name="QAQCEvents"
              #qaqcEvents="ngModel" (change)=qaqcEventChange()>
              <option *ngFor="let event of lstqaqcEvents" [ngValue]="event">{{ event.Name }}</option>
            </select>
            <div class="row">
              <div class="col-xs-6" style="text-align: left; padding: 0px">
                <button type="button" class="btn btn-primary" (click)="addProject()"
                  style="white-space: pre-wrap;">{{'Add' | translate}}</button>
              </div>
              <div class="col-xs-6" style="text-align: right; padding: 0px">
                {{'Select from QAQC'|translate}}:&nbsp;
                <input type="checkbox" name="selectFromQAQC" value="selectFromQAQC" [(ngModel)]="selectFromQAQC">
              </div>
            </div>
          </ng-container>
          <ng-container  *ngIf="pssr.ProjectID &gt; 0">
            <div class="row">

              <div class="col-xs-12">
                <div class="form-group">
                  <div class="checkbox">
                    <label>
                      <input name="IncludeProjectTeam" [(ngModel)]="pssr.IncludeProjectTeam" type="checkbox">
                      {{'IncludeProjectTeam'|translate}}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

        </div>
      </div>

      <div class="col-xs-3" *ngIf="pssr.ProjectID">
        <div *ngIf="selectedBU?.ShowQAQC && (lstqaqcMilestone.length > 0 || lstqaqcWO.length > 0 )" class="form-group">
          <div class="row">
            <div class="col-xs-6" style="text-align: left; padding: 0px">
              <label>{{ 'Milestones' | translate }}:</label>&nbsp;
              <input type="checkbox" name="qaqcmilestone" value="qaqcmilestone" [(ngModel)]="isQAQCMilestone"
                (change)=qaqcTypeChange(true)>
            </div>
            <div class="col-xs-6" style="text-align: right; padding: 0px">
              <label>{{ 'WorkOrders' | translate }}:</label>&nbsp;
              <input type="checkbox" name="qaqcworkoder" value="qaqcworkoder" [(ngModel)]="isQAQCWorkOrder"
                (change)=qaqcTypeChange(false)>
            </div>
          </div>
          <ng-container *ngIf=isQAQCMilestone>
            <select class="form-control" [(ngModel)]="pssr.QAQCMilestoneId" name="QAQCMilestone"
              #qaqcMilestone="ngModel" (change)=qaqcMilestoneChange()>
              <option [ngValue]="null"></option>
              <option *ngFor="let miles of lstqaqcMilestone" [ngValue]="miles.Id">{{ miles.Name }}</option>
            </select>
          </ng-container>
          <ng-container *ngIf=!isQAQCMilestone>
            <select class="form-control" [(ngModel)]="pssr.QAQCWorkOrderId" name="QAQCWorkOrder"
              #QAQCWorkOrder="ngModel" (change)=qaqcWOChange()>
              <option [ngValue]="null"></option>
              <option *ngFor="let wo of lstqaqcWO" [ngValue]="wo.Id">{{ wo.Name }}</option>
            </select>
          </ng-container>

        </div>
        <div *ngIf="selectedBU?.ShowQAQC && (lstqaqcMilestone.length === 0 && lstqaqcWO.length === 0)"
          class="form-group">
          <label>{{'MilestoneAndWorkOrders' | translate}} :</label>
          <div class="row" *ngIf="selectedBU?.ShowQAQC" class="alert alert-warning" role="alert">
            {{'Nomilestones'|translate}}
          </div>
        </div>
      </div>

      <div class="col-xs-3" *ngIf="pssr.QAQCMilestoneId">
        <div *ngIf="selectedBU?.ShowQAQC && lstqaqcMilestoneWO.length > 0" class="form-group">
          <label>{{'MilestoneWorkOrders' | translate}} :</label>
          <select class="form-control" [(ngModel)]="pssr.QAQCWorkOrderId" name="QAQCMilestoneWO"
            #qaqcMilestoneWO="ngModel" (change)=qaqcMilestoneChangeWO()>
            <option [ngValue]="null"></option>
            <option *ngFor="let workOrder of lstqaqcMilestoneWO" [ngValue]="workOrder.Id">{{ workOrder.Name }}</option>
          </select>
        </div>
        <div *ngIf="selectedBU?.ShowQAQC && lstqaqcMilestoneWO.length === 0" class="form-group">
          <label>{{'MilestoneWorkOrders' | translate}} :</label>
          <div class="row" *ngIf="selectedBU?.ShowQAQC" class="alert alert-warning" role="alert">
            {{'WorkOrdersNoavailable'}}
          </div>
        </div>
      </div>

    </div>

    <div class="row" *ngIf="!!selectedBU.Instructions">
      <div class="col-xs-12">
        <label>{{'Instructions'|translate}}</label>
        <div [innerHTML]="selectedBU.Instructions"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <div class="checkbox">
            <label>
              <input name="isSectionOrderEnabled" [(ngModel)]="pssr.IsSectionOrderEnabled" type="checkbox">
              {{'IsSectionOrderEnabled'|translate}}
            </label>
          </div>
        </div>
      </div>
    </div>


    <div class="row" [hidden]="pssrForm.form.valid && newSubAreaValidation" class="alert alert-warning" role="alert">
      <strong>{{'Note' | translate}}
        :</strong>{{'Please fill up the fields that are marked required' | translate}}
    </div>

    <div class="row">
      <hr />
    </div>

    <div class="row text-right">
      <button type="button" class="btn btn-default" (click)="btnCancelPSSR()"
        style="white-space: pre-wrap !important;">{{'Cancel' | translate}}
      </button> &nbsp;&nbsp;
      <button *ngIf="selectedBU.ShowKMS" type="button" class="btn btn-primary"
        style="float: left; white-space: pre-wrap !important;" (click)="KMSNav()">{{'PullFromKMS' | translate}}
      </button>

      <button id="saveBtn" type="submit" class="btn btn-primary" [disabled]="isNotAllowedToSave(pssrForm.form.valid)"
        style="white-space: pre-wrap !important;">{{'Save' | translate}}
      </button>
    </div>

  </form>

</div>
