<div class="box-shadow module">
  <h2>{{ "CreateNewCategory" | translate }}</h2>
  <hr />
  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{ "Name" | translate }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="category.Name"
        />
        <span class="text-danger field-validation-error" *ngIf="displayWarning">
          <span>{{ "This field is required" | translate }}.</span>
        </span>
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <a
          class="btn btn-default"
          style="width: 100px"
          (click)="createCategory()"
          >{{ "Create" | translate }}</a
        >
      </div>
    </div>
  </div>
  <div>
    <a
      (click)="onRedirectButtonClick(category.BusinessUnitID)"
      style="cursor: pointer"
      id="backToCategoryList"
      >{{ "Back to Category List" | translate }}</a
    >
  </div>
</div>
