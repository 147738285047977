import { Component, OnInit } from '@angular/core';
import {
  BusinessUnit,
  Area,
  SubArea,
  Facility,
  TaskPlan,
  Project,
  LoggedUser,
  User,
  TaskPlanType,
  LocationFilter,
} from '#models/index';
import { ActionItemFilter } from '#models/action-item-filter';
import { Organization } from '#models/organization';
import { PSSR } from '#models/pssr';
import { ResponseItemImage } from '#models/response-item-image';

import {
  OrganizationService,
  BusinessUnitsService,
  UserService,
} from '#services/api';
import {
  AreaService,
  SubAreaService,
  FacilityService,
  PSSRService,
  PSSRResponseService,
  TaskPlanService,
  ProjectService,
} from '#services/api/index';
import { SelectItem } from 'primeng/api';
import { AuthService } from '#services/shared';
import { tap } from 'rxjs/operators';
import { ResponseItemStatus } from '#models/response-item-status';
import { PSSRStatus } from '#models/pssr-status';
import { ActivatedRoute } from '@angular/router';
import { Utility } from '#services/shared/utility';
import { PSSRType } from '#models/pssr-type';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';
import { PagedList, PaginationRequest, PaginationSettings } from '#models/pagination';
import { IdName } from '#models/id-name';

@Component({
  selector: 'app-pssr-action-item-list',
  templateUrl: './pssr-action-item-list.component.html',
  styleUrls: ['./pssr-action-item-list.component.scss'],
  providers: [ProjectService, TranslatePipe],
})
export class PssrActionItemListComponent implements OnInit {
  filter: ActionItemFilter;
  finalListPssr: PSSR[] = [];
  savedLocationFilter: LocationFilter;

  lstBUs: BusinessUnit[] = [];
  lstOrgs: Organization[] = [{ Id: 0, Name: 'All' } as Organization];
  lstArea: Area[] = [{ Id: 0, Name: 'All' } as Area];
  lstSubArea: SubArea[] = [{ Name: 'All', Id: 0 } as SubArea];
  lstFacility: Facility[] = [{ Id: 0, Name: 'All' } as Facility];
  lstTaskPlan: TaskPlan[] = [{ ID: 0, Name: 'All' } as TaskPlan];
  lstProject: Project[] = [{ Id: 0, Name: 'All' } as Project];
  lstPssrByBUId: IdName[] = [];
  lstPssrAutocomplete: string[] = [];

  allStatus: SelectItem[] = [];
  allPriorities: SelectItem[] = [];
  pssrStatus: SelectItem[] = [];
  pssrTypes: SelectItem[] = [];

  PSSRStatus = PSSRStatus;
  ResponseItemStatus = ResponseItemStatus;

  loadedCollection = {
    isBULoaded: false,
    isOrgLoaded: false,
    isAreaLoaded: false,
    isSubAreaLoaded: false,
    isProjectLoaded: false,
    isFacilityLoaded: false,
    isTaskPlanLoaded: false,
  };

  buId: number;
  orgId: number;
  pssrName: string;
  allLabel: string;
  actionItemsList: number[] = [];
  selectedBU: BusinessUnit = new BusinessUnit();
  orgName: string;
  showLoadingImagesIcon: boolean;
  currentUser: LoggedUser;
  isAdmin = false;

  displayImage = false;
  imagePhoto: string;
  users: User[];
  selectedUser: User;
  leads: User[];
  selectedLead: User;
  showInitialFilterIndicator: boolean;

  pagination = new PaginationSettings();

  constructor(
    private toastrService: ToastrService,
    public svcBU: BusinessUnitsService,
    public svcOrg: OrganizationService,
    private svcArea: AreaService,
    private svcSubArea: SubAreaService,
    private svcFacility: FacilityService,
    public svcActionItems: PSSRResponseService,
    private svcPSSR: PSSRService,
    private authSvc: AuthService,
    private svcTaskPlan: TaskPlanService,
    private svcProject: ProjectService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private translatePipe: TranslatePipe
  ) {
    this.allStatus = [
      { label: 'Open', value: ResponseItemStatus.Open },
      { label: 'Closed', value: ResponseItemStatus.Closed },
      { label: 'Cancel', value: ResponseItemStatus.Cancel },
      { label: 'Rejected', value: ResponseItemStatus.Rejected },
    ];
    this.pssrStatus = [
      { label: 'Draft', value: PSSRStatus.Draft },
      { label: 'Initiated', value: PSSRStatus.Initiated },
      { label: 'In Progress', value: PSSRStatus.InProgress },
      { label: 'Walkdown Complete', value: PSSRStatus.Completed },
      { label: 'Pending Approval', value: PSSRStatus.PendingApproval },
      { label: 'Approved', value: PSSRStatus.Approved },
      { label: 'Closed', value: PSSRStatus.Closed },
    ];

    this.filter = new ActionItemFilter();
    this.allLabel = this.translatePipe.transform('All');

  }

  setFilter() {
    this.savedLocationFilter = new LocationFilter();
    this.savedLocationFilter.buId = this.selectedBU?.Id;
    this.savedLocationFilter.buName = this.selectedBU?.Name;

    this.savedLocationFilter.orgId = this.filter?.Organization?.Id;
    this.savedLocationFilter.orgName = this.filter?.Organization?.Name;

    this.savedLocationFilter.areaId = this.filter?.Area?.Id;
    this.savedLocationFilter.areaName = this.filter?.Area?.Name;

    this.savedLocationFilter.subAreaId = this.filter?.SubArea?.Id;
    this.savedLocationFilter.subAreaName = this.filter?.SubArea?.Name;

    localStorage.setItem('locationFilter', JSON.stringify({ ...this.savedLocationFilter }));
  }

  ngOnInit() {
    this.currentUser = this.authSvc.getCurrentUser();
    this.isAdmin =
      this.currentUser.IsSystemAdmin ||
      this.currentUser.IsOrgAdmin ||
      this.currentUser.IsBUAdmin;

    this.resetFilter();

    this.activeRoute.params.subscribe((p) => {
      this.buId = +p['buId'];
      this.orgId = +p['orgId'];
      this.pssrName = p['pssr'];

      const paramActionItemsList: string = p.hasOwnProperty('actionItemsList')
        ? p['actionItemsList']
        : '';
      this.actionItemsList = paramActionItemsList
        .split(',')
        .filter((v: string) => /\d+/.test(v))
        .map<number>((v: string) => parseInt(v, 10));

        this.filter.BUId = this.buId;
        this.filter.Organization = {Id: this.orgId} as Organization;
        this.filter.PSSRName = this.pssrName;
    });

    if (this.pssrName && this.pssrName.length > 0) {
      this.filter.PSSRName = this.pssrName;
      this.filter.PSSRStatus.push(PSSRStatus.Closed);
    }

    this.selectedUser = this.currentUser;
    this.selectedUser.FullName = Utility.getFullName(this.selectedUser);
    this.filter.Status = [ResponseItemStatus.Open];
    this.filter.ShowImages = true;
    this.filterInfo();

    this.showInitialFilterIndicator = true;
  }

  async filterInfo() {
    this.pagination.reset();

    if (this.showInitialFilterIndicator === true) {
      this.showInitialFilterIndicator = false;
    }

    await this.loadItemsList();
  }

  onPageChange(pageNumber) {
    this.pagination.pageNumber = pageNumber;
    this.loadItemsList();
  }

  private buildFilter() {
    this.filter.OrganizationId = Utility.isValidObj(this.filter.Organization)
      ? this.filter.Organization.Id
      : null;
    this.filter.AreaId = Utility.isValidObj(this.filter.Area)
      ? this.filter.Area.Id
      : null;
    this.filter.SubAreaId = Utility.isValidObj(this.filter.SubArea)
      ? this.filter.SubArea.Id
      : null;
    this.filter.FacilityId = Utility.isValidObj(this.filter.Facility)
      ? this.filter.Facility.Id
      : null;
    this.filter.ProjectId = Utility.isValidObj(this.filter.Project)
      ? this.filter.Project.Id
      : null;
    this.filter.TaskPlanId = Utility.isValidObj(this.filter.TaskPlan)
      ? this.filter.TaskPlan.ID
      : null;
    this.filter.ActionItemsList = this.actionItemsList;
  }

  // Events

  async changeBU() {
    this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
    this.filter.BUId = this.selectedBU.Id;
    this.filter.OrganizationId = 0;
    this.filter.AreaId = 0;
    this.filter.SubAreaId = 0;
    this.filter.FacilityId = 0;
    this.filter.TaskPlanId = 0;
    this.filter.ProjectId = 0;
    this.filter.ProjectNumber = null;
    this.filter.Organization = null;
    this.filter.Area = null;
    this.filter.SubArea = null;
    this.filter.Facility = null;
    this.filter.TaskPlan = null;
    this.filter.Project = null;
    this.filter.AssignedUserId = null;

    this.filter.LeadId = null;
    this.filter.PssrTypeIds = [];
    this.filter.ActionItemsList = [];

    this.lstOrgs = [{ Id: 0, Name: this.allLabel } as Organization];
    this.lstArea = [{ Id: 0, Name: this.allLabel } as Area];
    this.lstSubArea = [{  Id: 0 , Name: this.allLabel } as SubArea];
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];
    this.lstTaskPlan = [{ ID: 0, Name: this.allLabel } as TaskPlan];
    this.lstProject = [{ Id: 0, Name: this.allLabel } as Project];

    this.loadedCollection.isOrgLoaded = false;
    this.loadedCollection.isAreaLoaded = false;
    this.loadedCollection.isSubAreaLoaded = false;
    this.loadedCollection.isFacilityLoaded = false;
    this.loadedCollection.isProjectLoaded = false;
    this.loadedCollection.isTaskPlanLoaded = false;
    this.pssrTypes = [];

    this.completeBU();
    this.buildFilter();
    this.setFilter();
  }

  async changeHierarchyOne() {
    this.filter.SubAreaId = 0;
    this.filter.FacilityId = 0;
    this.filter.SubArea = null;
    this.filter.Facility = null;
    this.lstSubArea = [{ Id: 0, Name: this.allLabel} as SubArea];
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];
    this.loadedCollection.isSubAreaLoaded = false;
    this.loadedCollection.isFacilityLoaded = false;

    this.buildFilter();
  }

  async changeHierarchyTwo() {
    this.filter.FacilityId = 0;
    this.filter.Facility = null;
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];
    this.loadedCollection.isFacilityLoaded = false;

    this.buildFilter();
  }

  clearFilter() {
    this.resetFilter(true);
    this.loadPssrTypes();
    this.filterInfo();
  }

  private resetFilter(isClear: boolean = false) {
    this.filter.Status = [
      ResponseItemStatus.Open,
      ResponseItemStatus.Closed,
      ResponseItemStatus.Cancel,
      ResponseItemStatus.Rejected,
    ];

    this.filter.Priority = [3, 4, 5];
    this.filter.PssrTypeIds = [1, 2, 3, 4, 5, 6, 7];
    this.filter.PSSRStatus = [
      PSSRStatus.Draft,
      PSSRStatus.Initiated,
      PSSRStatus.InProgress,
      PSSRStatus.Completed,
      PSSRStatus.PendingApproval,
      PSSRStatus.Approved,
    ];

    this.orgId = null;
    this.filter.OrganizationId = 0;
    this.filter.Organization = null;
    this.filter.AreaId = 0;
    this.filter.Area = null;
    this.lstArea = [{ Id: 0, Name: this.allLabel } as Area];
    this.filter.SubAreaId = 0;
    this.filter.SubArea = null;
    this.lstSubArea = [{ Id: 0, Name: this.allLabel } as SubArea];
    this.filter.FacilityId = 0;
    this.filter.Facility = null;
    this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];

    this.filter.ProjectId = 0;
    this.filter.Project = null;
    this.filter.TaskPlanId = 0;
    this.filter.TaskPlan = null;
    this.filter.Project = null;
    this.filter.PSSRName = null;

    this.filter.ShowImages = false;
    this.filter.ShowDeletedPssr = false;
    this.filter.ProjectNumber = null;
    this.filter.AssignedUserId = null;
    this.filter.ActionItemsList = [];
    this.filter.LeadId = null;
    this.selectedUser = null;
    this.selectedLead = null;

    if (this.showInitialFilterIndicator === true) {
      this.showInitialFilterIndicator = false;
    }

    if (!isClear) {
      const localFilter = localStorage.getItem('locationFilter');

      if (Utility.isValidString(localFilter)) {
        const { currenUser, ...currentFilter } = JSON.parse(localFilter);
        this.savedLocationFilter = currentFilter;
      }
      if (this.savedLocationFilter != null) {
        this.lstBUs = !!this.savedLocationFilter.buId ? [{ Id: this.savedLocationFilter.buId, Name: this.savedLocationFilter.buName } as BusinessUnit] : [];
        this.lstOrgs = !!this.savedLocationFilter.orgId ? [{ Id: this.savedLocationFilter.orgId, Name: this.savedLocationFilter.orgName } as Organization] : [];

        this.selectedBU = !!this.savedLocationFilter.buId ? { Id: this.savedLocationFilter.buId, Name: this.savedLocationFilter.buName } as BusinessUnit : new BusinessUnit;
        this.filter.BUId = this.savedLocationFilter?.buId;
        this.buId = this.savedLocationFilter.buId;

        this.orgId = this.savedLocationFilter.orgId;
        this.filter.OrganizationId = this.savedLocationFilter.orgId;
        this.filter.Organization = !!this.savedLocationFilter.orgId ? { Id: this.savedLocationFilter.orgId, Name: this.savedLocationFilter.orgName } as Organization : new Organization;

        this.filter.AreaId = this.savedLocationFilter.areaId;
        this.filter.Area = !!this.savedLocationFilter.areaId ? { Id: this.savedLocationFilter.areaId, Name: this.savedLocationFilter.areaName } as Area : new Area;

        if (this.savedLocationFilter?.buId > 0) {
          this.loadBUs();
        }
      }
    }
  }

  async loadItemsList() {
    this.buildFilter();
    this.showLoadingImagesIcon = this.filter.ShowImages;
    this.updateFilters();
    const filter = {
      ActionItemsList: this.filter.ActionItemsList,
      Status: this.filter.Status,
      Priority: this.filter.Priority,
      PSSRStatus: this.filter.PSSRStatus,
      OrganizationId: !this.filter.OrganizationId ? 0 :  this.filter.OrganizationId,
      AreaId: this.filter.AreaId == null ? 0 : this.filter.AreaId,
      SubAreaId: this.filter.SubAreaId == null ? 0 : this.filter.SubAreaId,
      FacilityId: this.filter.FacilityId == null ? 0 : this.filter.FacilityId,
      TaskPlanId: this.filter.TaskPlanId == null ? 0 : this.filter.TaskPlanId,
      ProjectId: this.filter.ProjectId == null ? 0 : this.filter.ProjectId,

      PSSRName: this.filter.PSSRName,
      ShowImages: this.filter.ShowImages,
      ShowDeletedPssr: this.filter.ShowDeletedPssr,
      ProjectNumber: this.filter.ProjectNumber == null ? '' : this.filter.ProjectNumber,
      AssignedUserId: this.filter.AssignedUserId,
      LeadId: this.filter.LeadId == null ? 0 : this.filter.LeadId,
      BUId: this.filter.BUId,
      PssrTypeIds: this.filter.PssrTypeIds,
      AssignedUserEmail: this.filter.AssignedUserEmail,
    };

    const paginationRequest = <PaginationRequest>{
      PageNumber: this.pagination.pageNumber,
      PageSize: this.pagination.pageSize,
    };

    await this.svcActionItems
      .getPagedActionItems(filter, paginationRequest)
      .toPromise()
      .then((response: PagedList<PSSR>) => {
        this.pagination.updateCounters(response.Items?.length, response.TotalCount);

        this.finalListPssr = response.Items?.map((p) => {
          p.translationRequestList = [];
          p.statusClass = this.svcPSSR.labelClass(p.IsDeleted ? 8 : p.Status);
          p.StatusName = p.Status === PSSRStatus.Completed
            ? 'Walkdown Complete'
            : p.StatusName;

          if (p.IsDeleted) {
            p.StatusName = 'Deleted';
          }

          p.RespActionItems.forEach((r) => {
            r.ActionItems.forEach((a) => {
              a.imageCount = this.getImageCount(a.Images);
              a.showLoading = this.filter.ShowImages;
              a.DueDate = Utility.parseOffsetDate(a.DueDate);
              a.CreateDate = Utility.parseOffsetDate(a.CreateDate);
              a.ClosedOn = Utility.parseOffsetDate(a.ClosedOn);
            });
          });
          return p;
        });

        if (this.filter.ShowImages) {
          this.completeItems();
        }

        this.actionItemsList = [];
      });
  }

  async loadBUs() {
    if (!this.loadedCollection.isBULoaded) {
      await this.svcBU
        .getUserBUs()
        .toPromise()
        .then(async (data: BusinessUnit[]) => {
          this.lstBUs = data;
          this.loadedCollection.isBULoaded = true;
          if (Utility.isValidObj(this.lstBUs[0]) && this.filter.BUId == null) {
            this.selectedBU =
              this.buId > 0
                ? this.lstBUs.find((x) => x.Id === this.buId)
                : this.lstBUs[0];
            this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);

            this.filter.BUId = this.selectedBU.Id;
            this.buId = this.selectedBU.Id;
            this.completeBU();
          } else if (this.filter.BUId !== null) {
            this.selectedBU = this.lstBUs.find((x) => x.Id === this.filter.BUId);
            this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
            this.completeBU();
          } else {

            this.lstOrgs = [{ Id: 0, Name: this.allLabel } as Organization];
            this.lstArea = [{ Id: 0, Name: this.allLabel } as Area];
            this.lstSubArea = [{ Id: 0, Name: this.allLabel } as SubArea];
            this.lstFacility = [{ Id: 0, Name: this.allLabel } as Facility];
            this.lstProject = [{ Id: 0, Name: this.allLabel } as Project];

          }

          this.setFilter();
        });
    }
  }

  completeBU() {
    this.allPriorities = [];
    if (this.selectedBU.ShowP5) {
      this.allPriorities = [
        { label: this.selectedBU.P3Label, value: 3 },
        { label: this.selectedBU.P4Label, value: 4 },
        { label: this.selectedBU.P5Label, value: 5 },
      ];
    } else {
      this.allPriorities = [
        { label: this.selectedBU.P3Label, value: 3 },
        { label: this.selectedBU.P4Label, value: 4 },
      ];
    }
    this.loadPssrTypes();
  }

  private loadPssrTypes() {
    this.pssrTypes = [];
    this.filter.PssrTypeIds = [];
    if (Utility.isValidListWithData(this.selectedBU.PssrTypes)) {
      this.selectedBU.PssrTypes.forEach((p: PSSRType) => {
        this.filter.PssrTypeIds.push(p.Id);
        this.pssrTypes.push({ label: p.Name, value: p.Id });
      });
    }
  }

  async loadTaskPlan() {
    if (this.selectedBU.Id > 0 && !this.loadedCollection.isTaskPlanLoaded) {
      await this.svcTaskPlan
        .getTaskPlans(this.selectedBU.Id)
        .toPromise()
        .then((data: TaskPlan[]) => {
          this.loadedCollection.isTaskPlanLoaded = true;
          data.push({
            ID: 0,
            Name: this.allLabel,
            TaskPlanType: TaskPlanType.PSSR,
          } as TaskPlan);
          this.lstTaskPlan = data.filter(
            (t) => t.TaskPlanType === TaskPlanType.PSSR && !t.IsDeleted
          );
        });
    }
  }

  async loadProject() {
    if (
      this.selectedBU.ShowProject &&
      !this.selectedBU.ShowProjectInput &&
      this.filter.BUId &&
      this.filter.OrganizationId &&
      !this.loadedCollection.isProjectLoaded
    ) {
      await this.svcProject
        .getByOrgId(this.filter.OrganizationId)
        .toPromise()
        .then((data: Project[]) => {
          this.loadedCollection.isProjectLoaded = true;
          data.push({ Id: 0, Name: this.allLabel } as Project);
          this.lstProject = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  async changeOrg() {
    this.filter.AreaId = 0;
    this.filter.SubAreaId = 0;
    this.filter.FacilityId = 0;
    this.filter.TaskPlanId = 0;
    this.filter.ProjectId = 0;
    this.filter.Area = null;
    this.filter.SubArea = null;
    this.filter.Facility = null;
    this.filter.TaskPlan = null;
    this.filter.Project = null;
    this.loadedCollection.isAreaLoaded = false;
    this.loadedCollection.isSubAreaLoaded = false;
    this.loadedCollection.isFacilityLoaded = false;
    this.loadedCollection.isProjectLoaded = false;
    this.loadedCollection.isTaskPlanLoaded = false;

    this.setFilter();
  }

  async loadOrganizations() {
    if (this.selectedBU.Id > 0 && !this.loadedCollection.isOrgLoaded) {
      await this.svcOrg
        .getListByBU(this.selectedBU.Id)
        .toPromise()
        .then(async (data: Organization[]) => {
          data.push({ Id: 0, Name: this.allLabel } as Organization);
          this.loadedCollection.isOrgLoaded = true;
          this.lstOrgs = data.sort((a, b) => a.Name.localeCompare(b.Name));

          this.filter.Organization = this.orgId > 0
            ? this.lstOrgs.find((x) => x.Id == this.orgId)
            : this.lstOrgs[0];

          if (!Utility.isValidObj(this.filter.Organization)) {
            this.filter.Organization = this.lstOrgs[0];
          }

          this.orgId = this.filter.Organization?.Id;
        });
    }
  }

  async loadHierarchyOne() {
    if (this.selectedBU.Id > 0 && !this.loadedCollection.isAreaLoaded) {
      await this.svcArea
        .getAllByBusinessUnit(this.selectedBU.Id)
        .toPromise()
        .then((data: Area[]) => {
          data.push({ Id: 0, Name: this.allLabel } as Area);
          this.loadedCollection.isAreaLoaded = true;
          this.lstArea = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  async loadHierarchyTwo() {
    if (this.filter.AreaId > 0 && !this.loadedCollection.isSubAreaLoaded) {
      await this.svcSubArea
        .getAllByAreaId(this.filter.AreaId)
        .toPromise()
        .then((data: SubArea[]) => {
          data.push({ Name: this.allLabel, Id: 0 } as SubArea);
          this.loadedCollection.isSubAreaLoaded = true;
          this.lstSubArea = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  async loadHierarchyThree() {
    if (this.filter.SubAreaId > 0 && this.selectedBU.ShowHierarchyLabel3 && !this.loadedCollection.isFacilityLoaded) {
      await this.svcFacility
        .getAllBySubArea(this.filter.SubAreaId)
        .toPromise()
        .then((data: Facility[]) => {
          data.push({ Id: 0, Name: this.allLabel } as Facility);
          this.loadedCollection.isFacilityLoaded = true;
          this.lstFacility = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  getPssrAutocomplete(event) {
    const name = event.query.trim().toUpperCase();
    this.svcPSSR
      .getPSSRsByName(this.selectedBU.Id, name)
      .subscribe((data) => {
        this.lstPssrByBUId = data.sort((a, b) => a.Name.localeCompare(b.Name));
        this.lstPssrAutocomplete = this.lstPssrByBUId
          .map((y) => y.Name);
      });
  }

  getAsigneeAutocomplete(event: any) {
    this.userService
      .autocompleteUsersByBU(this.selectedBU.Id, event.query)
      .subscribe((users) => {
        this.users = users;
        this.users.forEach((user) => {
          user.FullName = Utility.formatUser(user);
        });
      });
  }

  getLeadAutocomplete(event: any) {
    this.userService
      .autocompleteUsersByBU(this.selectedBU.Id, event.query)
      .subscribe((data) => {
        this.leads = data;
        this.leads.forEach((user) => {
          user.FullName = Utility.formatUser(user);
        });
      });
  }

  // -- images
  btnExpandImage(image: string) {
    this.displayImage = true;
    this.imagePhoto = 'data:image/png;base64,' + image;
  }

  btnCollapseImage() {
    this.displayImage = false;
    this.imagePhoto = null;
  }

  loadImages() {
    this.showLoadingImagesIcon = true;

    this.filter.ShowImages = true;
    this.completeItems();
  }

  async completeItems() {
    for (let p = 0; this.finalListPssr?.length > p; p++) {
      const resp = this.finalListPssr[p].RespActionItems;
      for (
        let r = 0;
        resp != null && resp != undefined && resp.length > r;
        r++
      ) {
        const itms = resp[r].ActionItems;
        for (
          let i = 0;
          itms != null && itms != undefined && itms.length > i;
          i++
        ) {
          await this.loadImagesByItem(
            this.finalListPssr[p].Id,
            resp[r].Id,
            itms[i].Id
          );
        }
      }
    }
  }

  async loadImagesByItem(pssrId: number, resId: number, itemId: number) {
    if (
      this.finalListPssr
        .find((p) => p.Id === pssrId)
        .RespActionItems.find((r) => r.Id === resId)
        .ActionItems.findIndex((i) => i.Id === itemId && i.imageCount > 0) > -1
    ) {
      this.finalListPssr
        .find((p) => p.Id === pssrId)
        .RespActionItems.find((r) => r.Id === resId)
        .ActionItems.find((i) => i.Id === itemId).showLoading = true;
      await this.svcActionItems
        .getImagesByActionItem(itemId)
        .toPromise()
        .then((data: ResponseItemImage[]) => {
          if (Utility.isValidListWithData(data)) {
            const pssrIx = this.finalListPssr.findIndex(
              (a) => a.Id === data[0].PSSRID
            );
            const resIx = this.finalListPssr[pssrIx].RespActionItems.findIndex(
              (r) => r.Id === data[0].ResponseID
            );
            if (resIx > -1) {
              this.finalListPssr[pssrIx].RespActionItems[
                resIx
              ].ActionItems.find(
                (b) => b.Id === data[0].ResponseItemID
              ).Images = data;
              this.finalListPssr[pssrIx].RespActionItems[
                resIx
              ].ActionItems.find(
                (b) => b.Id === data[0].ResponseItemID
              ).hasFoundImages =
                data.filter((y) => y.ImageType == 0).length > 0;
              this.finalListPssr[pssrIx].RespActionItems[
                resIx
              ].ActionItems.find(
                (b) => b.Id === data[0].ResponseItemID
              ).hasResolvedImages =
                data.filter((y) => y.ImageType == 1).length > 0;
              this.finalListPssr[pssrIx].RespActionItems[
                resIx
              ].ActionItems.find(
                (b) => b.Id === data[0].ResponseItemID
              ).imageCount = 0;
              this.finalListPssr[pssrIx].RespActionItems[
                resIx
              ].ActionItems.find(
                (b) => b.Id === data[0].ResponseItemID
              ).showLoading = false;
            }
          }
        });
    }
  }

  export() {
    if (this.finalListPssr.length > 0) {
      const source = this.svcActionItems.export(this.filter).pipe(
        tap(
          () => {
            //
          },
          () => {
            this.toastrService.showError(this.translatePipe.transform('DownloadActionItemsExcelReportFailureMessage'));
          }
        )
      );

      source.subscribe((data: Object) => {
        const fileName = 'ActionItems.xlsx';
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(data, fileName);
        } else {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(data);
          link.download = fileName;
          link.click();
        }
      });
    }
  }

  private updateFilters() {
    this.filter.AssignedUserId = null;
    this.filter.AssignedUserEmail = null;

    if (
      Utility.isValidObj(this.selectedUser) &&
      this.actionItemsList.length === 0
    ) {
      this.filter.AssignedUserId = this.selectedUser.ID;
      this.filter.AssignedUserEmail = this.selectedUser.Email;
    }

    this.filter.LeadId = Utility.isValidObj(this.selectedLead)
      ? this.selectedLead.ID
      : null;

    this.setFilter();
  }

  private getImageCount(images: any[]): number {
    return Utility.isValidListWithData(images) ? images.length : 0;
  }

  getPssrType(pssrTypeId: number): string {
    const found = this.pssrTypes.find((v) => v.value === pssrTypeId);
    return !found ? '' : found.label;
  }

  getPssrStatusLabel(): string {
    const pssrLabel = this.translatePipe.transform(this.selectedBU?.PSSRLabel ?? 'PSSR');
    const statusLabel = this.translatePipe.transform('Status');

    return `${pssrLabel} ${statusLabel}`;
  }
}
