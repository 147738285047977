
<div class="box-shadow module">

  <div class="row">
    <div class="col-sm-6">
      <h3 class="text-left">{{ "GENERAL INFORMATION" | translate }}</h3>
    </div>

    <div class="col-sm-6">
      <h3 class="text-right">{{ "PSSR ID: " | translate }} {{pssr.Id}}</h3>
    </div>
  </div>

  <form (ngSubmit)="setupTranslation()" #pssrForm="ngForm">


    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label>{{ selectedBU?.PSSRLabel | translate }} :</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.Name"
            name="pssrName"
            id="pssrName"
            #pssrName="ngModel"
            [required]="isEditable || canTranslate"
            [disabled]="!isEditable && !canTranslate"
          />
          <div
            [hidden]="pssrName.valid || pssrName.pristine"
            class="alert alert-warning"
            role="alert"
          >
            {{ "Title is required" | translate }}.
          </div>
        </div>
      </div>
      <div class="col-xs-2">
        <div class="form-group">
          <label>{{ "MOC" | translate }} :</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.MOC"
            name="moc"
            #moc="ngModel"
            title="{{
              !!pssr.ApplicationId
                ? 'This PSSR was imported. You cannot change its id'
                : null
            }}"
            [disabled]="!isEditable || !!pssr.ApplicationId"
          />
        </div>
      </div>
      <div class="col-xs-2">
        <div class="form-group">
          <label>{{ "WO" | translate }} :</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.WO"
            name="wo"
            #wo="ngModel"
            [disabled]="!isEditable"
          />
        </div>
      </div>
      <div class="col-xs-2">
        <div class="form-group">
          <label>{{ "Business Unit" | translate }} :</label>
          <input
            [appBUSwitcher]="pssr.buObj"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.BusinessUnit"
            name="businessUnit"
            id="businessUnit"
            #businessUnit="ngModel"
            readonly
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-3">
        <div class="form-group">
          <label>{{ selectedBU?.OrganizationLabel | translate }} :</label>
          <input
            *ngIf="!isEditable"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.Organization"
            name="organization"
            #organization="ngModel"
            [disabled]="true"
          />
          <div *ngIf="isEditable">
            <select
              class="form-control"
              [(ngModel)]="pssr.OrganizationID"
              name="org"
              (change)="changeOrgs()"
              (click)="loadOrganizations()"
              required
              #org="ngModel"
            >
              <option *ngFor="let org of lstOrgs" [ngValue]="org.Id">
                {{ org.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-xs-3">
        <div class="form-group">
          <label>{{ selectedBU?.HierarchyLabel1 | translate }} :</label>
          <input
            *ngIf="!isEditable"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.Area"
            name="area"
            id="area"
            #area="ngModel"
            readonly
          />
          <div *ngIf="isEditable">
            <select
              class="form-control"
              (change)="changeArea()"
              [(ngModel)]="pssr.AreaID"
              name="area"
              id="area"
              #area="ngModel"
              (click)="loadArea()"
              required
            >
              <option *ngFor="let area of lstArea" [ngValue]="area.Id">
                {{ area.Name }}
              </option>
            </select>
            <div
              [hidden]="area.valid || area.pristine"
              class="alert alert-warning"
              role="alert"
            >
              {{ selectedBU?.HierarchyLabel1 | translate }} is required.
            </div>
          </div>
        </div>
      </div>

      <div class="col-xs-3">
        <div class="form-group">
          <label>{{ selectedBU?.HierarchyLabel2 | translate }} :</label>
          <input
            *ngIf="!isEditable"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.SubArea"
            name="subArea"
            id="subArea"
            #subArea="ngModel"
            readonly
          />
          <div *ngIf="isEditable">
            <select
              class="form-control"
              (change)="changeSubArea()"
              [(ngModel)]="pssr.SubAreaID"
              name="subArea"
              id="subArea"
              #subArea="ngModel"
              required
              (click)="loadSubArea()"
            >
              <option *ngFor="let subarea of lstSubArea" [ngValue]="subarea.Id">
                {{ subarea.Name }}
              </option>
            </select>
            <div
              [hidden]="subArea.valid || subArea.pristine"
              class="alert alert-warning"
              role="alert"
            >
              {{ selectedBU?.HierarchyLabel2 | translate }} is required.
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="selectedBU?.ShowHierarchyLabel3" class="col-xs-3">
        <div class="form-group">
          <label>{{ selectedBU?.HierarchyLabel3 | translate }} :</label>
          <input
            *ngIf="!isEditable"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.Facility"
            name="facility"
            #facility="ngModel"
            readonly
          />
          <div *ngIf="isEditable">
            <select
              class="form-control"
              [(ngModel)]="pssr.FacilityID"
              name="facility"
              #facility="ngModel"
              (click)="loadFacility()"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let facility of lstFacility"
                [ngValue]="facility.Id"
              >
                {{ facility.Name }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <label>{{ "Description" | translate }} :</label>
        <textarea
          cols="5"
          rows="5"
          class="form-control"
          [(ngModel)]="pssr.Description"
          name="description"
          #description="ngModel"
          [required]="canTranslate"
          maxlength="5000"
          [placeholder]="'desc5000' | translate"
          [disabled]="!isEditable && !canTranslate"
        ></textarea>
        <div id="description-length" style="text-align: right">
          {{ pssr.Description.length }}/5000
        </div>
      </div>
    </div>

    <div class="row">&nbsp;</div>

    <div class="row">
      <div class="col-xs-3">
        <div class="form-group">
          <div class="row">
            <div class="col-xs-7" style="text-align: left; padding: 0px">
              <label>{{ "Template" | translate }} :</label>
            </div>
            <div
              *ngIf="isDraft"
              class="col-xs-5"
              style="text-align: right; padding: 0px"
            >
              <button
                type="button"
                class="btn btn-primary"
                (click)="displayTemplateModal = true"
                style="white-space: pre-wrap"
              >
                {{ "Change" | translate }}
              </button>
            </div>
          </div>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.TaskPlan"
            name="taskPlan"
            #taskPlan="ngModel"
            readonly
          />
        </div>
      </div>

      <div *ngIf="selectedBU?.ShowPssrType" class="col-xs-2 form-group">
        <label>{{ "Type" | translate }} :</label>
        <div *ngIf="isEditable">
          <select
            class="form-control"
            [(ngModel)]="pssr.PssrTypeId"
            name="pssrTypeId"
            #pssrTypeId="ngModel"
          >
            <option [ngValue]="null"></option>
            <option *ngFor="let pt of pssrTypes" [ngValue]="pt.value">
              {{ pt.label | translate }}
            </option>
          </select>
        </div>
        <div *ngIf="!isEditable">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.PssrTypeName"
            name="pssrType"
            #pssrtype="ngModel"
            readonly
          />
        </div>
      </div>

      <div *ngIf="selectedBU?.ShowProject" class="col-xs-2 form-group">
        <label>{{ selectedBU.EventLabel | translate }} :</label>
        <div *ngIf="isEditable">
          <input
            *ngIf="selectedBU?.ShowProjectInput"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.ProjectNumber"
            name="projNum"
            #projNum="ngModel"
          />
          <select
            *ngIf="!selectedBU?.ShowProjectInput"
            class="form-control"
            [(ngModel)]="pssr.ProjectID"
            name="pssrProject"
            #pssrproject="ngModel"
            (click)="loadProjects()"
          >
            <option [ngValue]="null"></option>
            <option *ngFor="let project of lstProject" [ngValue]="project.Id">
              {{ project.Name }}
            </option>
          </select>
        </div>
        <div *ngIf="!isEditable">
          <input
            *ngIf="selectedBU?.ShowProjectInput"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.ProjectNumber"
            name="projNum"
            #projNum="ngModel"
            readonly
          />
          <input
            *ngIf="!selectedBU?.ShowProjectInput"
            type="text"
            class="form-control"
            [(ngModel)]="pssr.Project"
            name="pssrProjectView"
            #pssrProjectView="ngModel"
            readonly
          />
        </div>
      </div>

      <div *ngIf="pssr.QAQCType > 0" class="col-xs-3 form-group">
        <div *ngIf="pssr.QAQCType === 1" class="form-group">
          <label>{{ "Milestone" | translate }} :</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.QAQCMilestone"
            name="QAQCMilestone"
            #qaqcMilestone="ngModel"
            readonly
          />
        </div>
        <div *ngIf="pssr.QAQCType === 2" class="form-group">
          <label>{{ "WorkOrder" | translate }} :</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.QAQCWorkOrder"
            name="QAQCMilestone"
            #qaqcMilestone="ngModel"
            readonly
          />
        </div>
        <div *ngIf="pssr.QAQCType === 3" class="form-group">
          <label
            >{{ "MilestoneWorkOrder" | translate }} :
            {{ pssr.QAQCMilestone }}</label
          >
          <input
            type="text"
            class="form-control"
            [(ngModel)]="pssr.QAQCWorkOrder"
            name="QAQCMilestone"
            #qaqcMilestone="ngModel"
            readonly
          />
        </div>
      </div>

      <div class="col-xs-2">
        <label>{{ "Status" | translate }} :</label><br />
        <div
          id="status"
          class="{{
            pssr.statusClass
          }} text-center padding-left-5 padding-right-5 padding-top-5 padding-bottom-5"
          style="border-radius: 1em; color: white; max-width: 150px"
        >
          {{ pssr.StatusName | translate }}
        </div>
      </div>
    </div>

    <div class="row" *ngIf="!!selectedBU.Instructions && pssr.Status &lt;= 2">
      <div class="col-xs-12">
        <label>{{ "Instructions" | translate }}</label>
        <div [innerHTML]="selectedBU.Instructions"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <div
            class="text-left alert alert-warning alert-dismissible"
            role="alert"
            *ngIf="remindSaveSort"
          >
            <button
              type="button"
              class="close"
              (click)="deleteRemindSaveSort()"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            {{ "SortChangesApplied" | translate }}
          </div>
          <div class="checkbox">
            <label>
              <input
                [value]="pssr.IsSectionOrderEnabled"
                name="isSectionOrderEnabled"
                [(ngModel)]="pssr.IsSectionOrderEnabled"
                type="checkbox"
                [disabled]="!isEditable || pssr.Status &gt; 3"
              />
              {{ "IsSectionOrderEnabled" | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-7">
        <table id="pssrstatus">
          <tr *ngIf="pssr.CreateDate">
            <td nowrap class="col-xs-4 text-right padding-left-0">
              <strong>{{ "Draft Created On" | translate }}&nbsp;</strong>
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.CreateDate | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.CreatedBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.InitiatedOn">
            <td nowrap class="col-xs-4 text-right padding-left-0">
              <strong>{{ "Initiated On" | translate }}&nbsp;</strong>
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.InitiatedOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.InitiatedBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.StartedOn">
            <td nowrap class="col-xs-3 text-right padding-left-0">
              <strong>{{ "Started" | translate }}&nbsp;</strong>
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.StartedOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.StartedBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.CompletedOn">
            <td nowrap class="col-xs-3 text-right padding-left-0">
              <strong
                >{{
                  "Walkdown Completed
                On" | translate
                }}&nbsp;</strong
              >
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.CompletedOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.CompletedBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.PendingApprovalOn">
            <td nowrap class="col-xs-4 text-right padding-left-0">
              <strong
                >{{
                  "Pending Approval Submitted
                On" | translate
                }}&nbsp;</strong
              >
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.PendingApprovalOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.PendingApprovalBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.VerbalApprovalOn">
            <td nowrap class="col-xs-4 text-right padding-left-0">
              <strong
                >{{
                  "Verbal Approval for Startup On" | translate
                }}&nbsp;</strong
              >
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.VerbalApprovalOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "Received from" | translate }}&nbsp;</strong>
              {{
                pssr.FinalApprover != null
                  ? pssr.FinalApprover.UserName
                  : ("Final Approver" | translate)
              }}
              <strong>{{ "by" | translate }}&nbsp;</strong>
              {{
                pssr.VerbalApprovalBy != null
                  ? pssr.VerbalApprovalBy
                  : ("Team Lead" | translate)
              }}
            </td>
          </tr>
          <tr *ngIf="pssr.ApprovedOn">
            <td nowrap class="col-xs-4 text-right padding-left-0">
              <strong>{{ "Approved On" | translate }}&nbsp;</strong>
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.ApprovedOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.ApprovedBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.ClosedOn && pssr.Status > 6">
            <td nowrap class="col-xs-4 text-right padding-left-0">
              <strong>{{ "Closed On" | translate }}&nbsp;</strong>
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.ClosedOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.ClosedBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.DeletedOn && pssr.IsDeleted">
            <td nowrap class="col-xs-3 text-right padding-left-0">
              <strong>{{ "DeletedOn" | translate }}&nbsp;</strong>
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.DeletedOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.DeletedBy }}
            </td>
          </tr>
          <tr *ngIf="pssr.RestoredOn && !pssr.IsDeleted">
            <td nowrap class="col-xs-3 text-right padding-left-0">
              <strong>{{ "Restored On" | translate }}&nbsp;</strong>
            </td>
            <td nowrap class="col-xs-2 text-left padding-left-0">
              {{ pssr.RestoredOn | date: "short" }}&nbsp;
            </td>
            <td nowrap class="col-xs-6 text-left padding-left-0">
              <strong>{{ "By" | translate }}&nbsp;&nbsp;</strong
              >{{ pssr.RestoredBy }}
            </td>
          </tr>
        </table>
      </div>
      <div class="col-xs-5">
        <ng-container *ngIf="pssr.RejectedOn && pssr.Status &lt; 6">
          <div class="row">
            <strong class="col-xs-4 text-right padding-left-0">
              {{ "Rejected On" | translate }}&nbsp;</strong
            >
            <span class="col-xs-8 text-left padding-left-0">
              {{ pssr.RejectedOn | date: "short" }}&nbsp;
            </span>
          </div>
          <div class="row">
            <strong class="col-xs-4 text-right padding-left-0">
              {{ "Rejected By" | translate }}&nbsp;</strong
            >

            <span class="col-xs-8 text-left padding-left-0">
              {{ pssr.RejectedBy }}&nbsp;
            </span>
          </div>
          <div class="row">
            <strong class="col-xs-4 text-right padding-left-0">
              {{ "Reason" | translate }}&nbsp;</strong
            >

            <span class="col-xs-8 text-left padding-left-0">
              {{ pssr.RejectedReason }}&nbsp;
            </span>
          </div>
        </ng-container>
      </div>
    </div>

    <div
      class="row"
      [hidden]="pssrForm.form.valid"
      class="alert alert-warning"
      role="alert"
    >
      <strong>{{ "Note" | translate }} :</strong
      >{{ "Please fill up the fields that are marked required" | translate }}.
      {{ "Please click Save" | translate }}.
    </div>

    <ng-container *ngIf="pssr?.translationRequestList?.length &gt; 0">
      <hr />
      <div class="row" id="translation-history">
        <div class="col-xs-12">
          <h3 class="text-left">{{ "Translation History" | translate }}</h3>
          <table>
            <tr>
              <th id="thRequestedOn" class="text-center">
                {{ "Requested On" | translate }}
              </th>
              <th id="thRequestedBy" class="text-center">
                {{ "Requested By" | translate }}
              </th>
              <th id="thTranslator" class="text-center">
                {{ "Translator" | translate }}
              </th>
              <th id="thLanguage" class="text-center">
                {{ "Language" | translate }}
              </th>
              <th id="thStatus" class="text-center">
                {{ "Status" | translate }}
              </th>
              <th id="thHolder">&nbsp;</th>
            </tr>
            <ng-container *ngFor="let request of pssr?.translationRequestList">
              <tr>
                <td class="text-center col-xs-2">
                  {{ request.UpdatedDate | date: "short" }}
                </td>
                <td class="text-center col-xs-2">
                  {{ request.CreatedBy }}
                </td>
                <td class="text-center col-xs-2">
                  <ng-container
                    *ngIf="
                      (pssr.CurrentUser.UserEmail == request.CreatedBy ||
                        pssr.CurrentUser.UserEmail ==
                          request.TranslatorEmail) &&
                        !request.IsCompleted;
                      else translationRequestor
                    "
                  >
                    <button
                      type="button"
                      (click)="editTranslator(request)"
                      class="btn btn-link"
                    >
                      {{ request.TranslatorEmail }}
                    </button>
                  </ng-container>
                  <ng-template #translationRequestor>
                    {{ request.TranslatorEmail }}
                  </ng-template>
                </td>
                <td class="text-center col-xs-2">
                  {{ languages[request.Language] | translate }}
                </td>
                <td class="text-center col-xs-2">
                  <span
                    *ngIf="
                      !request.IsCompleted &&
                      request.TranslationHistories.length == 0
                    "
                    class="translation-status label label-info"
                    >{{ "Translation Requested" | translate }}</span
                  >
                  <span
                    *ngIf="
                      !request.IsCompleted &&
                      request.TranslationHistories.length > 0
                    "
                    class="translation-status label label-warning"
                    >{{ "Translation In Progress" | translate }}</span
                  >
                  <span
                    *ngIf="request.IsCompleted"
                    class="translation-status label label-success"
                    >{{
                      "Translation
                    Completed" | translate
                    }}</span
                  >
                </td>
                <td class="col-xs-2 text-center">
                  <button
                    *ngIf="
                      pssr.CurrentUser.CanTranslate &&
                      (
                        pssr.CurrentUser.UserEmail === request.TranslatorEmail ||
                        pssr.CurrentUser.ParentUsers.includes(request.TranslatorEmail)
                      ) &&
                      !request.IsCompleted
                    "
                    type="button"
                    class="btn btn-primary complete-translation"
                    (click)="confirmCompleteTranslation(request)"
                  >
                    {{ "Complete
                    Translation" | translate }}
                  </button>
                </td>
              </tr>
            </ng-container>
          </table>
        </div>
      </div>
    </ng-container>

    <div class="row text-right">
      <hr />

      <button
        type="button"
        *ngIf="canRequestTranslation"
        style="margin-right: 20px"
        class="btn btn-default"
        (click)="requestTranslation()"
      >
        {{ "Request Translation" | translate }}
      </button>

      <button
        id="view-translation-history"
        *ngIf="translationHistoryList?.length &gt; 0"
        type="button"
        style="margin-right: 20px"
        class="btn btn-warning"
        (click)="showTranslations()"
      >
        {{ "Translation History" | translate }}
      </button>
    </div>
  </form>

  <input #isValid id="isValid" value="{{ pssrForm.form.valid }}" hidden />

  <p-dialog [(visible)]="displayTemplateModal" showEffect="fade" [modal]="true">
    <p-header>
      <div
        *ngIf="templateError"
        id="templateErrorId"
        class="row"
        class="alert alert-danger"
        role="alert"
        [innerHTML]="templateError"
      ></div>
      Change Template
    </p-header>
    <div class="row">
      You are about to change current Template: <b>{{ pssr.TaskPlan }}</b>
      <br />
      Do you want to proceed?
    </div>
    <div class="row">
      <select
        class="form-control"
        [(ngModel)]="newTaskPlanId"
        name="pssrTemplate"
        required
        #template="ngModel"
      >
        <option *ngFor="let taskplan of lstTaskPlan" [ngValue]="taskplan.ID">
          {{ taskplan.Description }}
        </option>
      </select>
    </div>
    <p-footer class="row">
      <button
        type="button"
        pButton
        [label]="'Yes' | translate"
        (click)="changeTemplate()"
        style="white-space: pre-wrap !important"
      ></button>
      <button
        type="button"
        pButton
        [label]="'No' | translate"
        (click)="displayTemplateModal = false"
        style="white-space: pre-wrap !important"
      ></button>
    </p-footer>
  </p-dialog>

  <p-dialog [(visible)]="displayHistoryModal" showEffect="fade" [modal]="true">
    <p-header>
      {{ "Translation History" | translate }}
    </p-header>
    <table class="table table-hover table-stripped table-condensed">
      <thead>
        <tr class="bg-info">
          <th id="thElement" class="col-sm-1">{{ "Element" | translate }}</th>
          <th id="thContent" class="col-sm-1">{{ "Content" | translate }}</th>
          <th id="thPrevious" class="col-sm-3">{{ "Previous" | translate }}</th>
          <!-- text-center -->
          <th id="thTranslation" class="col-sm-3">
            {{ "Translation" | translate }}
          </th>
          <th id="thLanguage" class="col-sm-1">{{ "Language" | translate }}</th>
          <th id="thDate" class="col-sm-1">{{ "Date" | translate }}</th>
          <th id="thTranslator" class="col-sm-2">
            {{ "Translator" | translate }}
          </th>
        </tr>
      </thead>
      <tbody *ngFor="let h of translationHistoryList">
        <tr>
          <td class="col-sm-1">
            {{ h.Element }}
          </td>
          <td class="col-sm-1 translated-content">
            <ng-container
              *ngIf="
                h.Content == 'Name' && h.Element == 'PSSR';
                else defaultTranslatedContent
              "
            >
              {{ "Title" | translate }}
            </ng-container>
            <ng-template #defaultTranslatedContent>{{ h.Content }}</ng-template>
          </td>
          <td class="col-sm-3">
            {{ h.PreviousContent }}
          </td>
          <td class="col-sm-3">
            {{ h.TranslatedContent }}
          </td>
          <td class="col-sm-1">
            {{ languages[h.Language] | translate }}
          </td>
          <td class="col-sm-1">
            {{ h.UpdatedDate | date: "M/d/y, h:mm:ss a" }}
          </td>
          <td class="col-sm-2" style="word-break: break-word">
            {{ h.UpdatedBy }}
          </td>
        </tr>
      </tbody>
    </table>
  </p-dialog>
</div>
