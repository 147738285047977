<p-dialog
  [(visible)]="prompt"
  [blockScroll]="true"
  [maximizable]="true"
  [modal]="true"
  [style]="{'min-width':'600px'}"
  showEffect="fade"
  appendTo="body"
>
  <p-header>
    <div
      *ngIf="errorMessage"
      class="row"
      class="alert alert-danger"
      role="alert"
      [innerHTML]="errorMessage"
    ></div>
    {{ "Comment" | translate }}
  </p-header>

  <div class="form-group" [formGroup]="form">
    <div class="row">
      <div class="col-md-12">
        <label>{{ "Comment" | translate }}:</label>
        <textarea type="text" formControlName="Comment" class="form-control">
        </textarea>
      </div>
      <div class="col-md-12">
        <label>{{ "Created By" | translate }}:</label>
        <span>&nbsp;&nbsp;{{ form.controls.CreatedBy?.value }} </span>
      </div>
      <div class="col-md-12">
        <label>{{ "Updated Date" | translate }}:</label>
        <span
          >&nbsp;&nbsp;{{
            form.controls.UpdatedDate?.value | date: "M/d/y, h:mm:ss a"
          }}
        </span>
      </div>
      <div class="col-md-12">
        <label>{{ "Updated By" | translate }}:</label>
        <span>&nbsp;&nbsp;{{ form.controls.UpdatedBy?.value }} </span>
      </div>
    </div>
  </div>

  <p-footer class="row">
    <button
      pButton
      type="button"
      class="ui-button-secondary"
      style="width: 80px; margin-right: 10px; float: right"
      [label]="'Cancel' | translate"
      (click)="close()"
    ></button>
    <button
      pButton
      type="button"
      class="col-xs-5 whiteSpaceBtn"
      style="width: 80px; float: right"
      [label]="'Save' | translate"
      (click)="saveComment()"
      [disabled]="disabled || form.invalid"
    ></button>
    <button
      pButton
      *ngIf="canDelete"
      type="button"
      class="ui-button-danger"
      style="width: 80px; float: right"
      [label]="'Delete' | translate"
      (click)="deleteComment()"
    ></button>
  </p-footer>
</p-dialog>
