import { PSSRUser } from '#models/pssr-user';
import { PSSRUserList } from '#models/pssr-user-list';
import { ResponseItem } from '#models/response-item';
import { PSSRResponse } from '#models/index';
import { BusinessUnit } from './business-unit';
import { PSSRFile } from '#models/pssr-file';
import { QAQCItem, QAQCType } from '#models/qaqc-milestone';
import { SectionUserList } from '#models/section/section';
import { PSSRStatus } from './pssr-status';
import { TranslationRequest } from './translation-request';
import { BusinessUnitCode } from './business-unit-code';

export class PSSR {
  Id: number;
  Name: string;

  /**
   * Management of Change
   */
  MOC: string;

  /**
   *  External PSSR Id
   * */
  ExternalId: string;

  /**
   * Chevron app Id
   */
  ApplicationId: string;

  /**
   * Work Order
   */
  WO: string;
  Description: string;

  TaskPlanID: number;
  TaskPlan: string;

  BusinessUnitID: number;
  BusinessUnit: string;
  BusinessUnitCode: BusinessUnitCode;

  OrganizationID: number;
  Organization: string;

  AreaID: number;
  Area: string;

  SubAreaID: number;
  SubArea: string;

  FacilityID: number;
  Facility: string;

  Status: number = PSSRStatus.Draft;
  StatusName: string;

  Dates: string[];
  Questions: string[];

  PSSRUsers: PSSRUserList;
  UpdatedDate: Date;

  CurrentUser: PSSRUser = new PSSRUser();
  NextStatus: string;
  NextStatusId: number;

  RespActionItems: PSSRResponse[] = [];
  HideCode: boolean;

  Files: PSSRFile[] = [];

  SignatureUrl: string;
  SignatureGUID: string;
  SignatureDate: Date;
  IsDeleted: boolean;
  PssrTypeId: number;
  PssrTypeName: string;
  SignatureApproval: boolean;

  EmailSentDate: Date;

  CreatedBy: string;
  CreateDate: Date = new Date();
  InitiatedBy: string;
  InitiatedOn: Date;
  StartedBy: string;
  StartedOn: Date;
  CompletedBy: string;
  CompletedOn: Date;
  PendingApprovalBy: string;
  PendingApprovalOn: Date;
  VerbalApprovalBy: string;
  VerbalApprovalOn: Date;
  ApprovedBy: string;
  ApprovedOn: Date;
  ClosedOn: string;
  ClosedBy: Date;
  RestoredOn: Date;
  RestoredBy: string;
  DeletedOn: Date;
  DeletedBy: string;
  RejectedBy: string;
  RejectedReason: string;
  RejectedOn: Date;

  // ----Internal Variables---------------
  statusClass: string;
  p3Closed: boolean;
  p4Closed: boolean;
  pssrIsValid: boolean;
  DatesFormated: any[];
  approvedDate: string;
  QuestionsFormated: any[];

  lstResponse: PSSRResponse[] = [];
  standAloneItems: ResponseItem[] = [];

  validationMsg: string;

  showViewItems: boolean;

  ProjectID: number;
  Project: string;

  ProjectNumber: string;

  FinalSignature: string;
  FinalApprover: PSSRUser;

  showSignature: boolean;
  pssrSignatureLabel: string;
  pssrSignatureDate: string;
  pssrSignaturePdfDate: string;

  buObj: BusinessUnit;
  QAQCMilestone: string;
  QAQCWorkOrder: string;
  QAQCMilestoneId: string;
  QAQCWorkOrderId: string;
  QAQCType: QAQCType;

  // Angular Only:
  QAQCItem: QAQCItem;

  // docFiles: PSSRFile[];
  // imgFiles: PSSRFile[];
  PSSRSectionUsers: SectionUserList;

  IsSectionOrderEnabled = false;
  IncludeProjectTeam = false;
  translationRequestList: TranslationRequest[] = [];
}
