
export class EndDeviceType {
    Id: number;
    BusinessUnitId: number;
    Description: string; // Used for EAMDescription
    EAMClass: string;
    EAMName: string;
    EAMDescription: string;
    Name: string;
    IsInEAM: boolean;
    IsSerialNumReq: boolean;
}
