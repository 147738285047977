<div>
  <div class="row">
    <div class="row">
      <h3 class="text-left col-sm-9" style="margin-bottom: 0px">
        {{ "TEAM MEMBERS" | translate }}
      </h3>
    </div>
    <hr />
    <form #membersForm="ngForm">

      <div class="row">
        <div *ngFor="let role of roles">
          <div class="col-xs-3">
            <p-card>
              <table>
                <tr>
                  <td>
                    <button 
                      type="button" 
                      *ngIf="project?.CurrentUser?.CanEdit" 
                      [disabled]="role.Disabled"
                      class="btn btn-sm btn-block btn-primary" 
                      style="white-space: pre-wrap !important"
                      (click)="openAddParticipant(role)"
                    >
                      {{ "Add" | translate }}
                    </button>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <label>{{ role.RoleLabel | translate }}</label>
                  </td>
                </tr>
              </table>
            </p-card>
            <br />
            <!-- participant label -->
            <div *ngFor="let u of project?.ProjectUsers?.Users">
              <div *ngIf="u.Role === role.RoleId">
                <div class="ui-card ui-widget ui-widget-content ui-corner-all">
                  <div class="ui-card-body">
                    <div class="ui-card-content">
                      <div class="row">
                        <div class="col-xs-8" style="padding: 0 0 0 0">
                          <p>{{ u.UserName }}</p>
                          <div style="font-size: 0.8em">
                            <em>{{ u.UserEmail }}</em><br />
                            <em>{{ u.UserCAI }}</em>
                          </div>
                        </div>
                        <div class="col-xs-3">
                          <button 
                            *ngIf="project?.CurrentUser?.CanEdit"
                            type="button"
                            class="btn btn-danger"
                            style="white-space: pre-wrap !important"
                            (click)="openEditParticipant(u)"
                          >
                            {{ "Remove" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <p-dialog 
          [(visible)]="displayAddModal"
          showEffect="fade"
          [modal]="true"
        >
          <p-header>
            {{ "Selectuserfor" | translate }} {{ selectedRole | translate }}
            {{ "Role" | translate }}
          </p-header>
          <input 
            *ngIf="!displayChev"
            type="radio"
            id="lookup"
            class="dialog-radio"
            name="ai-assign"
            [value]="ParticipantType.ChevronOrSharedUser"
            [(ngModel)]="participantType"
            [disabled]="displayChev"
          />
          <label *ngIf="!displayChev" for="lookup">{{ "Chevron Directory" | translate }}&nbsp; &nbsp;</label>
          <input
            *ngIf="!displayENonChev"
            type="radio"
            id="chevron"
            class="dialog-radio"
            name="ai-assign"
            [value]="ParticipantType.NonChevronUser"
            [(ngModel)]="participantType"
            [disabled]="displayENonChev"  
          />
          <label *ngIf="!displayENonChev" for="chevron">{{ "ExistingNonChevronShared" | translate }}&nbsp;
            &nbsp;</label>
          <input
            *ngIf="!displayNNonChev"
            type="radio"
            id="nonChevron"
            class="dialog-radio"
            name="ai-assing"
            [value]="ParticipantType.External"
            [(ngModel)]="participantType"
            [disabled]="displayNNonChev"
          />
          <label *ngIf="!displayNNonChev" for="nonChevron">{{
            "New Non-Chevron" | translate
            }}</label>
          <div *ngIf="participantType === ParticipantType.NonChevronUser">
            <select 
              class="form-control"
              [(ngModel)]="participantId"
              name="newParticipant"
              #newParticipant="ngModel"
              (change)="addNonChevronUserById(participantId)"
            >
              <option [ngValue]="null"></option>
              <option *ngFor="let au of nonChevronUsers" [ngValue]="au.ID">
                {{ au | fullName }}
                <ng-container *ngIf="au.Email">({{ au.Email }})</ng-container>
              </option>
            </select>
          </div>
          <div *ngIf="participantType === ParticipantType.ChevronOrSharedUser">
            <p-autoComplete
              [(ngModel)]="selectedUser"
              [suggestions]="participants"
              (completeMethod)="suggestParticipants($event)"
              name="usr"
              required
              #usr="ngModel"
              field="FullName"
              delay="1000"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              [placeholder]="'typeusercainame' | translate"
              [minLength]="1"
              (onSelect)="addChevronUser(selectedUser)"
            >
            </p-autoComplete>
          </div>

          <div *ngIf="participantType === ParticipantType.External">
            <div class="row">
              <div class="col-xs-2">
                <label>{{ "Email" | translate }}: </label>
              </div>
              <div class="col-xs-10">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="otherParticipant.Email"
                  name="otherP"
                  #otherP="ngModel" 
                />
              </div>
            </div>
            <div class="row" style="margin-top: 3px">
              <div class="col-xs-2">
                <label>{{ "Name" | translate }}: </label>
              </div>
              <div class="col-xs-10">
                <input
                  type="text"
                  class="form-control"
                  [(ngModel)]="otherParticipant.Name"
                  [disabled]="!otherParticipant.Email" 
                  (change)="addExternalUser()"
                  name="otherName"
                  #otherName="ngModel"
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <ng-container *ngIf="allowMulti && participantType === ParticipantType.ChevronOrSharedUser">
            <div *ngFor="let item of possibleSelectedUser">
              <p>
                {{ item | fullName }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="allowMulti && participantType === ParticipantType.NonChevronUser">
            <div *ngFor="let item of possibleParticipantId">
              <p>
                {{ item | fullName }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="allowMulti && participantType === ParticipantType.External">
            <div *ngFor="let item of possibleOtherParticipant">
              <p>
                {{ item?.Name }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <p-footer class="row ">
            <div *ngIf="maxMessage" class="col-xs-9 delete-project-member" style="text-align: left">
              {{ maxMessage }}
            </div>
            <button
              class="col-xs-3"
              type="button"
              [disabled]="disableAdd"
              pButton
              [label]="'Add' | translate"
              (click)="addParticipant()"
              style="width: 80px; white-space: pre-wrap !important"
            ></button>
          </p-footer>
        </p-dialog>

        <p-dialog [(visible)]="displayEditModal" showEffect="fade" [modal]="true">
          <p-header>
            {{ "Remove Participant" | translate }}
          </p-header>
          <div class="row text-center">
            <h4>
              {{
              "Do you agree to Remove selected participant" | translate
              }}&#63;
            </h4>
            <h3>{{ participantToEdit.UserName }}</h3>
          </div>

          <p-footer class="row">
            <button
              *ngIf="
                !participantToEdit?.SignatureGUID &&
                project?.CurrentUser?.CanEdit &&
                !disablePartic
              "
              type="button"
              pButton
              [label]="'Remove' | translate"
              (click)="removeParticipant(participantToEdit)"
              style="
                width: 80px;
                white-space: pre-wrap !important;
                background-color: #c9302c;
                border-color: #ac2925;
              "
            ></button>
            <button
              type="button"
              pButton
              [label]="'Cancel' | translate"
              (click)="removeCancel()"
              style="width: 80px; white-space: pre-wrap !important"
            ></button>
          </p-footer>
        </p-dialog>
      </div>
    </form>
  </div>
</div>