import { PSSRFile } from '#models/pssr-file';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class PssrFileStorageService {
  private _files = new BehaviorSubject<Partial<PSSRFile>[]>([]);

  private _getFiles() {
    return this._files.value;
  }

  addFile(file: Partial<PSSRFile>) {
    const files = this._getFiles();

    this._files.next([...files, file]);
  }
  addFiles(files: Partial<PSSRFile>[]) {
    const currentFiles = this._getFiles();

    this._files.next([...currentFiles, ...files]);
  }

  removeFile(fileId: number) {
    const files = this._getFiles();
    const newFiles = files.filter(({ Id }) => Id !== fileId);

    this._files.next(newFiles);
  }

  set files(value: Partial<PSSRFile>[]) {
    this._files.next(value);
  }

  get files$(): Observable<Partial<PSSRFile>[]> {
    return this._files.asObservable();
  }

  private extractFiles(validExtensions: string[], files: Partial<PSSRFile>[]) {
    return files.filter((f) => validExtensions.includes(f.ContentType));
  }
  extractImageFiles(files: Partial<PSSRFile>[]) {
    return this.extractFiles(PSSRFile.validImageExtensions, files);
  }
  extractBinaryFiles(files: Partial<PSSRFile>[]) {
    return this.extractFiles(PSSRFile.validBinaryExtensions, files);
  }

  clear() {
    this._files.next([]);
  }
}
