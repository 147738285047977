import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProjectService } from '../../../services/api/project.service';
import { Project, BusinessUnit } from '#models/index';
import { finalize } from 'rxjs/operators';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-pssr-project-detail-copy-equip',
  templateUrl: './pssr-project-detail-copy-equip.component.html',
  styleUrls: ['./pssr-project-detail-copy-equip.component.scss'],
})
export class PssrProjectDetailCopyEquipComponent implements OnInit {
  //#region VARIABLES

  projectId: number;
  project: Project = new Project();
  projectsList: Project[];
  selectedProjects: Project[] = [];
  selectedProject: Project = new Project();
  businessUnit: BusinessUnit;



  businessUnitId: number;
  organizationId: number;


  //#endregion

  constructor(
    private toastrService: ToastrService,
    private router: Router,
    private activeRoute: ActivatedRoute,

    private projectService: ProjectService
  ) {
    this.activeRoute.params.subscribe((p) => {
      (this.projectId = +p['itemId']),
        (this.businessUnitId = +p['parentId']),
        (this.organizationId = +p['orgId']);
    });
  }

  ngOnInit() {
    this.getProjectInfo();

  }

  getProjectInfo() {
    if (this.projectId > 0) {
      this.projectService
        .getProjectById(this.projectId)
        .pipe(
          finalize(() => {
            this.loadProjectsEquipment();
          })
        )
        .subscribe((project) => {
          this.project = project;
        });
    }
  }

  // Loading all Projects by User
  // Including Equipments by Project
  // EndDevices by Equipment
  private loadProjectsEquipment() {
    this.projectService
      .getByEquipmentExists(this.organizationId)

      .subscribe((data: Project[]) => {
        if (data.length) {
          this.projectsList = data;
        }
      });
  }

  backProjectDetail() {
    this.router.navigate([
      'pssr-project-detail',
      { itemId: this.projectId, parentId: this.businessUnitId },
    ]);
  }

  selectProject(proj: Project) {
    this.selectedProject = proj;
  }

  selectProjectOriginal(proj: Project) {
    if (this.selectedProjects.find((x) => x.Id === proj.Id)) {
      this.selectedProjects = this.selectedProjects.filter(
        (x) => x.Id !== proj.Id
      );
    } else {
      this.selectedProjects.push(proj);
    }
  }

  copyEquipmentsProject() {
    if (this.selectedProject) {
      const proj = this.projectService
        .copyFromProject(this.selectedProject.Id, this.projectId)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              this.backProjectDetail();
            }, 3000);
          })
        );
      proj.subscribe((result) => {
        if (result.Success) {
          this.showMessage(
            `Selected Project has been copied to ${this.project.Name}`,
            0
          );
        }
      }, (err) => {
        this.showMessage('Failed to copy Project: ' + err, 1);
      });
    }

    /*
        if (this.selectedProject.Equipments.length) {
            this.selectedProject.Equipments.forEach((equip) => {
                equip.ProjectId = this.project.Id;
                this.equipmentService.create(equip)

                                     .subscribe((newEquip) => {
                                        this.endDeviceService.createByEquipment(newEquip)
                                                             .pipe(finalize(() => {
                                                                 this.loadingIndicatorSvc.hide();
                                                                 this.showMessage();
                                                            })
                                                             .subscribe(() => {});
                                     });
            });
        }
        */
  }

  displayProject() {
    // TODO implement
  }

  showMessage(info: string, dv: number) {

    if (dv === 1) {
      this.toastrService.showError( info);
    } else {
      this.toastrService.showSuccess( info);

    }

  }
}
