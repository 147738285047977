<div class="panel-body no-padding-no-margin">
  <table
    class="table table-hover table-stripped table-condensed"
    style="table-layout: fixed; width: 100%; margin-bottom: 0px; padding-left:0px;"
  >
    <thead *ngIf="!isSignature" class="zero-padding-left">
      <tr class="bg-warning row" [ngClass] = "calculateHeaderClass()">
        <td class="col-xs-3 padding-left-0">
          <span class="col-xs-4 padding-left-5">
            <strong class="col-xs-6 padding-left-5">{{
              "ID" | translate
            }}</strong>
            <strong class="col-xs-6 padding-left-5">{{
              "Priority" | translate
            }}</strong>
          </span>
          <strong class="col-xs-8">{{ "Action" | translate }}</strong>
        </td>
        <td class="col-xs-3">
          <strong class="col-xs-4">{{ "Assignee" | translate }}</strong>
          <strong class="col-xs-4">{{ "Due Date" | translate }}</strong>
          <strong class="col-xs-4">{{ "Status" | translate }}</strong>
        </td>
        <td class="col-xs-4">
          <strong class="col-xs-6">{{ "Comment" | translate }}</strong>
          <strong class="col-xs-6">{{ "Links" | translate }}</strong>
        </td>
        <td class="col-xs-2">
          <strong class="col-xs-9">{{ "Category" | translate }}</strong>
          <strong class="col-xs-3 padding-right-0 text-right">
            <!--For post walkdown section to show up-->
            <button id="pwaddBtn"
              *ngIf="canAddPostWalkdownActionItem"
              type="button"
              class="btn btn-sm btn-success whiteSpaceBtn add-action-item"
              (click)="btnAddActionItem()"
            >
              {{ "Add" | translate }}
            </button>

            <!--For Master Punch List Section Add button to show up and other similar sections-->
            <button id="mpaddBtn"
              *ngIf="canAddMasterPunchActionItem"
              type="button"
              class="btn btn-sm btn-success whiteSpaceBtn"
              (click)="btnAddActionItem()"
            >
              {{ "Add" | translate }}
            </button>
          </strong>
        </td>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let ai of actionItems">
        <tr
          class="row"
          [class.normalItem]="!ai.inEdit"
          [class.modifiedItem]="ai.inEdit"
          [ngClass]="getElementColorByType(ai.ResponseStatus)"
        >
          <td
            *ngIf="
              ai.StatusName === 'None' && ai.Priority === 0 && ai.Comment;
              else elseBlock
            "
            colspan="4"
          >
            <span class="col-xs-8">
              <strong class="comment-title"
                >{{ "Comment" | translate }} :
              </strong>
              <span [innerHTML]="ai.Comment | unescape"></span>
            </span>
            <span class="col-xs-3">
              <span [innerHTML]="ai.CreatedBy | unescape"></span>
            </span>

            <span
              *ngIf="!pssr.IsDeleted && (ai.CanEditItem || allowMembersToCompletePSSR)  && pssr.Status &lt; 6"
              class="col-xs-1 padding-left-0 padding-right-0"
            >
              <button id="aieditBtn"
                type="button"
                class="btn btn-primary col-xs-12 whiteSpaceBtn"
                (click)="editComment(ai)"
              >
                {{ "Edit" | translate }}
              </button>
              <app-comment-dialog
                [canDelete]="pssr.Status == 3"
                #addCommentDialog
                (saved)="onSavedActionItem($event)"
                [(ngModel)]="newActionItem"
              >
              </app-comment-dialog>
            </span>
          </td>

          <ng-template #elseBlock>
            <td class="col-xs-3 padding-left-5">
              <span class="row">
                <span class="col-xs-4 padding-left-5">
                  <span class="col-xs-6 padding-left-0 padding-right-0">{{
                    ai.Id
                  }}</span>

                  <span
                    class="col-xs-6"
                    [ngClass]="{
                      p3Class: ai.Priority == 3,
                      p4Class: ai.Priority == 4,
                      p5Class: ai.Priority == 5
                    }"
                  >
                    {{
                      ai.Priority === 3
                        ? selectedBU?.P3Label
                        : ai.Priority === 4
                        ? selectedBU?.P4Label
                        : ai.Priority === 5
                        ? selectedBU?.P5Label
                        : ""
                    }}
                  </span>
                </span>
                <span class="col-xs-8" [innerHTML]="ai.Action"> </span>
              </span>
            </td>
            <td class="col-xs-3">
              <span class="row">
                <span class="col-xs-4 assignee">
                  {{ ai.AssignedTo }}
                  <i style="font-size: 0.8em" *ngIf="ai.AssignedText"
                    >({{ ai.AssignedText }})</i
                  >
                </span>
                <span class="col-xs-4">
                  <span
                    *ngIf="ai.DueDate"
                    [ngClass]="{'bg-red': actualDateMs &gt; ai.DueDate && ai.Status == 1 }"
                  >
                    {{ ai.DueDate.toLocaleDateString() }}
                  </span>
                </span>
                <span class="col-xs-4">
                  {{ getStatusTitle(ai) }}
                </span>
              </span>
            </td>
            <td class="col-xs-4">
              <span
                class="col-xs-6"
                *ngIf="ai.Status &lt; 4"
                [innerHTML]="ai.Comment | unescape"
              >
              </span>
              <span class="col-xs-6" *ngIf="ai.Status == 4">
                {{ ai.RejectReason }}
              </span>
              <span class="col-xs-6" style="word-break: break-all">
                <ul style="padding: 0" *ngIf="ai.Links.length">
                  <li *ngFor="let link of extractLinks(ai.Links)">
                    <button
                      class="btn btn-link"
                      (click)="openLink(link)"
                      [innerHTML]="link.title"
                    ></button>
                  </li>
                </ul>
              </span>
            </td>
            <td class="col-xs-2">
              <span class="col-xs-8">
                <span *ngIf="ai.CategoryName">
                  <div
                    [ngStyle]="{
                      'font-style': !isActiveCategory(ai.CategoryID)
                        ? 'italic'
                        : 'normal'
                    }"
                  >
                    {{ ai.CategoryName }}
                    <span *ngIf="!isActiveCategory(ai.CategoryID)"
                      >(Inactive)</span
                    >
                    <br />
                    {{ ai.SubCategoryName }}
                  </div>
                </span>
              </span>
              <span
                class="col-xs-4 padding-left-0 padding-right-0"
                *ngIf="!pssr.IsDeleted"
              >
                <button id="translateBtn"
                  *ngIf="canTranslate; else editBtn"
                  type="button"
                  class="btn btn-warning col-xs-12 whiteSpaceBtn"
                  style="overflow-wrap: break-word; padding: 5px 5px"
                  (click)="translateActionItem(ai)"
                >
                  {{ "Translate" | translate }}
                </button>
                <ng-template #editBtn>
                  <button id="editBtn"
                    *ngIf="canEditAI(ai)"
                    type="button"
                    class="btn btn-primary col-xs-12 whiteSpaceBtn"
                    (click)="editActionItem(ai)"
                  >
                    {{ "Edit" | translate }}
                  </button>
                  <button id="attachfileBtn"
                    *ngIf="canAttach(ai) && !ai.CanRejectItem"
                    type="button"
                    class="btn btn-primary col-xs-12 whiteSpaceBtn padding-left-0 padding-right-0"
                    (click)="editToAttach(ai)"
                  >
                    {{ "Attach File" | translate }}
                  </button>
                </ng-template>
                <button id="reopenBtn"
                  *ngIf="isLead && ai.CanReOpenItem && selectedBU.AllowTeamLeadToAttach"
                  type="button"
                  class="re-open-button btn btn-warning col-xs-12 whiteSpaceBtn"
                  (click)="reOpenActionItem(ai)"
                >
                  {{ "ReOpen" | translate }}
                </button>
                <button id="rejectBtn"
                  *ngIf="ai.CanRejectItem"
                  type="button"
                  class="btn btn-danger col-xs-12 whiteSpaceBtn"
                  (click)="openRejectActionItemDialog(ai)"
                >
                  {{ "Reject" | translate }}
                </button>
              </span>
            </td>
          </ng-template>
        </tr>
        <!--images START-->
        <ng-container *ngIf="ai.Images != null && ai.Images.length > 0">
          <tr>
            <td colspan="9">
              <ng-container *ngFor="let img of ai.Images">
                <div
                  class="col-xs-2 padding-left-0 padding-right-0 padding-bottom-5"
                >
                  <img
                    class="col-xs-12"
                    *ngIf="img.Photo"
                    src="data:image/png;base64,{{ img.Photo }}"
                    width="100%"
                    class="padding-left-5 padding-bottom-5"
                    (click)="btnExpandImage(img.Photo)"
                    style="cursor: pointer"
                    title="{{ img.Caption }}"
                  />
                  <div class="col-xs-12">
                    {{ img.Caption }}
                  </div>
                </div>
              </ng-container>
            </td>
          </tr>
        </ng-container>
        <!-- images END-->
      </ng-container>
    </tbody>
  </table>
</div>

<!-- action item dialog box  -->

<app-action-item-dialog
  #actionItemDialog
  [pssrFiles]="pssrFiles"
  [pssr]="pssr"
  [actionType] = "actionType"
  [(ngModel)]="newActionItem"
  [isStandAlone]="isStandAlone"
  [groups]="groups"
  [canAnswer]="false"
  (saved)="onSavedActionItem($event)"
  [isSectionLead]="isSectionLead"
  [onlyAttachments]="onlyAttachments"
>
</app-action-item-dialog>

<!-- expand image dialog box-->
<p-dialog
  [(visible)]="displayImage"
  showEffect="fade"
  [modal]="true"
  (onAfterHide)="btnCollapseImage()"
>
  <p-header></p-header>
  <div style="text-align: center">
    <img style="width: 90%" src="{{ imagePhoto }}" />
  </div>
  <p-footer></p-footer>
</p-dialog>

<p-dialog
  [(visible)]="showRejectActionItemDialog"
  [blockScroll]="true"
  [maximizable]="true"
  [modal]="true"
  [style]="{'min-width':'600px'}"
  showEffect="fade"
  appendTo="body"
>
  <p-header>

    {{ "Reject" | translate }}
  </p-header>

  <div class="row">
    <div class="col-md-12">
      <label>{{ "Rejectionreason" | translate }}:</label>
      <textarea
        type="text"
        class="form-control"
        [(ngModel)]="newActionItem.RejectReason"
      >
      </textarea>
      <div *ngIf="newActionItem.Id &gt; 0">
        <label>{{ "Time Captured" | translate }}:</label>
        <span
          >&nbsp;&nbsp;{{
            newActionItem.CreateDate != null
              ? newActionItem.CreateDate.toLocaleString()
              : ""
          }}
        </span>
      </div>
    </div>
  </div>

  <p-footer>
    <button id="cancelBtn"
      pButton
      type="button"
      class="ui-button-secondary"
      style="width: 80px; margin-right: 10px; float: right"
      [label]="'Cancel' | translate"
      (click)="showRejectActionItemDialog = false"
    ></button>
    <button id="saveBtn"
      pButton
      class="col-xs-5 whiteSpaceBtn"
      style="width: 80px; float: right"
      type="button"
      [label]="'Save'|translate"
      (click)="rejectActionItem()"
      [disabled]="isSaving"
    ></button>
  </p-footer>
</p-dialog>

<p-confirmDialog #cd>
  <p-footer>
    <div>
      <button id="yesBtn"
        type="button"
        pButton
        icon="pi pi-check"
        [label]="'Yes' | translate"
        (click)="cd.accept()"
      ></button>
      <button id="noBtn"
        type="button"
        pButton
        icon="pi pi-times"
        [label]="'No' | translate"
        (click)="cd.reject()"
      ></button>
    </div>
  </p-footer>
</p-confirmDialog>

