export enum ActionItemCategoryType {
  PrimaryCategory = 1,
  SecondaryCategory,
}

export class ActionItemCategory {
  Id: number;
  Name: string;
  BusinessUnitID: number;
  Type: ActionItemCategoryType;
  TypeName: string;
  IsActive = true;
}

export class CategoryList {
  PrimaryCategories: ActionItemCategory[];
  SecondaryCategories: ActionItemCategory[];
}
