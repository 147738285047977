import { TranslatePipe } from '@ngx-translate/core';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PSSRImportService } from '#services/api/pssr-import.service';
import { IdName } from '#models/id-name';

import { ImportStatusUI } from '#models/enum/import-status-ui';
import { ImportResponse } from '#models/Import-response';
import { ImportInfo } from '#models/pssr-import';
import { Organization } from '#models/organization';
import { BusinessUnit, Area, SubArea } from '#models/index';

import {
  OrganizationService,
  BusinessUnitsService,
  AreaService,
  SubAreaService,
} from '#services/api';

import { ImportStatus } from '#models/enum/import-status';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-pssr-import',
  templateUrl: './pssr-import.component.html',
  styleUrls: ['./pssr-import.component.scss'],
  providers: [TranslatePipe],
})
export class ImportComponent {
  @ViewChild('msgFocusId') msgFocusElem: ElementRef;

  ImportStatusEnum = ImportStatusUI;

  pageTitle = 'Import PSSRs';

  applicationList: IdName[] = [];
  selectedApplication: IdName;

  importList: ImportInfo[] = [];
  pssrId = '';
  isAllSelected = false;

  enableImportAll = false;
  importedCount = 0;
  errorCount = 0;


  importAllStarted = false;

  selectedBU: BusinessUnit = new BusinessUnit();
  selectedOrg: Organization = new Organization();
  selectedSubArea: SubArea = new SubArea();
  selectedArea: Area = new Area();

  lastCallOrgBUId = 0;
  lastCallAreaBUId = 0;
  lastCallAreaId = 0;

  lstBUs: BusinessUnit[] = [];

  disableAll = true;
  lstOrgs: Organization[];
  lstArea: Area[];
  lstSubArea: SubArea[];
  constructor(
    private toastrService: ToastrService,
    private pssrImportService: PSSRImportService,

    private translatePipe: TranslatePipe,
    private svcBU: BusinessUnitsService,
    private svcOrg: OrganizationService,
    private svcArea: AreaService,
    private svcSubArea: SubAreaService,
    private svcRouter: Router
  ) { }

  async selectAll() {
    this.importList.forEach(async (element) => {
      if (element.status !== ImportStatusUI.Imported) {
        element.selected = this.isAllSelected;
      }
    });
    this.enableImportAll = !!this.importList.filter((x) => x.selected).length;
  }

  async invertSelection() {
    this.isAllSelected =
      this.importList.filter((x) => x.status !== ImportStatusUI.Imported)
        .length ===
      this.importList.filter(
        (x) => x.selected && x.status !== ImportStatusUI.Imported
      ).length;
    this.enableImportAll = !!this.importList.filter((x) => x.selected).length;
  }

  async onChangeDisableAll() {
    this.disableAll =
      !!this.selectedBU?.Id &&
        this.selectedArea?.Id &&
        this.selectedSubArea?.Id &&
        this.selectedApplication?.Id
        ? false
        : true;
    this.pssrId = null;
  }

  addPSSR() {
    const id = this.pssrId?.trim();

    if (
      this.selectedApplication?.Id &&
      id !== '' &&
      !this.importList.find((x) => x.id === id)) {
      this.pssrImportService
        .getPSSRInfo(this.selectedApplication.Id, id)

        .subscribe(
          (data: ImportResponse) => {
            if (data != null) {
              this.importList.push({
                id: data.Id,
                name: data.Name,
                selected: false,
                status: ImportStatusUI.Pending,
                error: '',
              } as ImportInfo);
              this.pssrId = '';
            }
          },
          (error) => {
            this.setErrMessage(error.error.ExceptionMessage);
          }
        );
    } else {
      if (this.pssrId.trim() === '') {
        let showWarning = 'PSSR Id is required.';

        if (
          this.selectedApplication === null ||
          this.selectedApplication.Id > 0
        ) {
          showWarning += 'Import From is required.';
        }
        this.setErrMessage(showWarning);
      }
      if (this.importList.find((x) => x.id === this.pssrId.trim())) {
        this.setErrMessage('MOC ID is already present in the Import list.');
      } else {
        this.setErrMessage('Import From is required.');
      }

    }
  }

  importAll() {
    this.importAllStarted = true;

    try {
      this.importList.forEach((pssr) => {
        if (pssr.status !== ImportStatusUI.Imported && pssr.selected) {
          this.importItem(pssr);
        }
      });
    } catch (e) {
      this.setErrMessage('Error happened while importing PSSR');
    } finally {
      this.importAllStarted = false;
      this.isAllSelected = false;
      this.enableImportAll = false;
    }
  }

  async importItem(item: ImportInfo) {
    const importListItem = this.importList.find((x) => x.id === item.id);

    importListItem.status = ImportStatusUI.InProgress;

    try {
      await this.pssrImportService
        .importPSSR(
          item.id,
          this.selectedApplication.Id,
          this.selectedBU.Id,
          this.selectedOrg.Id,
          this.selectedArea.Id,
          this.selectedSubArea.Id
        )
        .toPromise()
        .then((data: ImportResponse) => {
          if (data) {
            importListItem.pssrId = data.PSSRId;

            switch (data.Status) {
              case ImportStatus.Success:
                importListItem.status = ImportStatusUI.Imported;
                break;
              case ImportStatus.None:
                importListItem.status = ImportStatusUI.Pending;
                break;
              case ImportStatus.Warning:
                importListItem.status = ImportStatusUI.Warning;
                importListItem.error = data.Errors.join('\r\n');
                break;
              case ImportStatus.Error:
                importListItem.status = ImportStatusUI.Error;
                importListItem.error = data.Errors.join('\r\n');
                break;
              default:
                throw new Error(`Unsupported status ${data.Status}`);
            }
          }

          this.importedCount = this.importList.filter((x) => x.status === ImportStatusUI.Imported).length;
          this.errorCount = this.importList.filter((x) => x.status === ImportStatusUI.Error).length;
          importListItem.selected = false;
        });
    } catch (e) {
      const pssr = this.importList.find((x) => x === item);

      pssr.status = ImportStatusUI.Error;
      this.setErrMessage(e.error.ExceptionMessage);

      pssr.error = e.error.ExceptionMessage;
      pssr.selected = false;

      this.importedCount = this.importList.filter((x) => x.status === ImportStatusUI.Imported).length;
      this.errorCount = this.importList.filter((x) => x.status === ImportStatusUI.Error).length;
    }
  }

  setErrMessage(message: string) {
    this.msgFocusElem.nativeElement.focus();
    this.toastrService.showWarning(  this.translatePipe.transform(message));
  }

  async loadApplications() {
    if (this.applicationList === null || this.applicationList.length === 0) {
      await this.pssrImportService
        .getApplications()
        .toPromise()
        .then((data: IdName[]) => {
          this.applicationList = data.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
        });
    }
  }

  getStatusText(pssrStatus: ImportStatusUI): string {
    let status = pssrStatus.toString();
    if (pssrStatus === ImportStatusUI.InProgress) {
      status = 'In Progress';
    } else if (pssrStatus === ImportStatusUI.Pending) {
      status = 'Pending';
    } else if (pssrStatus === ImportStatusUI.Imported) {
      status = 'Imported';
    }

    return status;
  }

  loadBUs() {
    if (this.lstBUs === null || this.lstBUs.length === 0) {
      this.svcBU
        .getUserBUs()
        .toPromise()
        .then((data: BusinessUnit[]) => {
          this.lstBUs = data;

          if (this.lstBUs.length > 0) {
            this.selectedBU = this.lstBUs[0];
            this.changeBU();
          }
        });
    }
  }

  async changeBU() {
    this.selectedOrg = null;
    this.selectedArea = null;
    this.selectedSubArea = null;
    this.lstOrgs = null;
    this.lstArea = null;
    this.lstSubArea = null;
    this.lastCallOrgBUId = null;
    this.lastCallAreaBUId = null;
    this.onChangeDisableAll();
  }

  loadOrganizations() {
    if (
      this.selectedBU?.Id > 0 &&
      this.selectedBU.Id !== this.lastCallOrgBUId
    ) {
      this.lastCallOrgBUId = this.selectedBU.Id;

      this.svcOrg
        .getListByBU(this.selectedBU.Id)
        .toPromise()
        .then((data: Organization[]) => {
          this.lstOrgs = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  changeOrganization() {
    this.selectedArea = null;
    this.selectedSubArea = null;
    this.lstSubArea = null;

    this.onChangeDisableAll();
  }

  loadArea() {
    if (
      this.selectedBU?.Id > 0 &&
      this.selectedBU.Id !== this.lastCallAreaBUId
    ) {
      this.lastCallAreaBUId = this.selectedBU.Id;

      this.svcArea
        .getAllByBusinessUnit(this.selectedBU.Id)
        .toPromise()
        .then((data: Area[]) => {
          this.lstArea = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  async changeArea() {
    this.selectedSubArea = null;
    this.lstSubArea = null;
    this.onChangeDisableAll();
  }

  loadSubArea() {
    if (
      this.selectedArea &&
      this.selectedArea.Id > 0 &&
      this.selectedArea.Id !== this.lastCallAreaId
    ) {
      this.lastCallAreaId = this.selectedArea.Id;
      this.svcSubArea
        .getAllByAreaId(this.selectedArea.Id)
        .toPromise()
        .then((data: SubArea[]) => {
          this.lstSubArea = data.sort((a, b) => a.Name.localeCompare(b.Name));
        });
    }
  }

  editPSSR(pssrId: number): void {
    const url = this.svcRouter.serializeUrl(
      this.svcRouter.createUrlTree(['/pssr-edit/', { id: pssrId }])
    );

    window.open(url, '_blank');
  }
}
