import { NgModule } from '@angular/core';

import { NotificationComponent } from './notification/notification.component';
import { NotificationService } from '#services/shared';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [
    NotificationComponent,

  ],
  imports: [
    CommonModule,
    FormsModule
  ],
  providers: [
    NotificationService
  ],
  exports: [
    NotificationComponent,

  ]
})

export class ComponentsModule { }
