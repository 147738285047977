import { BusinessUnit } from '#models/business-unit';
import { PSSRFile } from '#models/pssr-file';
import { UserType } from '#models/enum/user-type';
import { Injectable } from '@angular/core';
import { v4 as uuid } from 'uuid';
import * as moment from 'moment';
import { environment } from '#environments/environment';
import { ResponseStatus } from '#models/enum/response-status';
import { ValidationErrors } from '@angular/forms';


@Injectable()
export class Utility {
  static availableLanguages = {
    en: 'English',
    ru: 'Russian',
    kk: 'Kazakh',
    pt: 'Portuguese',
    nl: 'Dutch',
    es: 'Spanish',
  };
  static getFormattedDate() {
    const ts = new Date();
    return (
      ts.getFullYear() +
      '-' +
      this.pad(ts.getMonth() + 1, 2) +
      '-' +
      this.pad(ts.getDate(), 2) +
      'T' +
      this.pad(ts.getHours(), 2) +
      ':' +
      this.pad(ts.getMinutes(), 2) +
      ':' +
      this.pad(ts.getSeconds(), 2)
    );
  }

  static pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) {
      s = '0' + s;
    }
    return s;
  }

  static formatDate(date: string) {
    let dateString = null;
    if (this.isValidObj(date)) {
      const iDate = new Date(date);
      dateString =
        iDate.toLocaleDateString() + ', ' + iDate.toLocaleTimeString();
    }
    return dateString;
  }

  static isToday(date1: string, date2?: string): boolean {
    const d1 = new Date(date1);
    let d2 = null;
    d2 = date2 ? new Date(date2) : new Date();
    d1.setHours(0, 0, 0, 0);
    d2.setHours(0, 0, 0, 0);
    const d1time = d1.getTime();
    const d2time = d2.getTime();
    return d1time - d2time === 0;
  }

  static guid(): string {
    return uuid();
  }

  static isValidString(chainStr: string): boolean {
    return (
      chainStr !== null &&
      chainStr !== 'null' &&
      chainStr !== undefined &&
      chainStr !== 'undefined' &&
      chainStr.trim().length > 0
    );
  }

  static parseString(chainStr: string): string {
    return this.isValidString(chainStr) ? chainStr : null;
  }

  static isTrue(obj: any): boolean {
    return obj === true || obj === 'true' || obj === '1' || obj === 1;
  }

  static isValidObj(obj: any): boolean {
    return obj !== null && obj !== undefined;
  }

  static isValidListWithData(listObj: any[]): boolean {
    return listObj !== null && listObj !== undefined && listObj.length > 0;
  }

  static distinct(value, index, self) {
    return self.indexOf(value) === index;
  }

  static parseToStringOffsetDate(offsetdate: Date): string {
    return this.isValidObj(offsetdate)
      ? this.parseOffsetDate(offsetdate).toLocaleString()
      : null;
  }

  static parseOffsetDate(offsetdate: Date): Date {
    if (!this.isValidObj(offsetdate)) {
      return null;
    }
    return new Date(moment(offsetdate).format('MM/DD/YYYY, HH:mm:ss'));
  }

  static toHtmlEntities(str: string): string {
    const tagsToReplace = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
    };

    function replaceTag(tag) {
      return tagsToReplace[tag] || tag;
    }

    return str.replace(/[&<>]/g, replaceTag);
  }

  static getFileAsBinaryString(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener('load', (event) => {
        resolve(btoa((event.target as FileReader).result as string));
      });
      reader.addEventListener('error', () => {
        reject(reader.error);
        reader.abort();
      });
      reader.readAsBinaryString(file);
    });
  }

  static delay(fn, timeout = 100): Promise<void> {
    return new Promise<void>((resolve) => {
      const id = setTimeout(() => {
        try {
          fn();
          resolve();
        } finally {
          clearTimeout(id);
        }
      }, timeout);
    });
  }

  static extractDescriptionFromFileName(
    fileName: string,
    acceptableExtensions: string = PSSRFile.acceptableExtensions
  ): string {
    const acceptableExtensionsAsArray = acceptableExtensions
      .split(',')
      .map((v) => '.' + v.split('/')[1]);

    return fileName.replace(
      new RegExp(acceptableExtensionsAsArray.join('|')),
      ''
    );
  }

  static getPssrTitle(
    bu: BusinessUnit,
    pssrTypesProperties: string[] = ['ShowCCC', 'ShowORR']
  ): string {
    const pssrTypes = [
      'PSSR',
      ...pssrTypesProperties
        .filter((b) => bu[b])
        .map((b) => b.replace('Show', '')),
    ];

    const title = pssrTypes.join(' / ');
    return title;
  }

  static trimAll(s: string): string {
    return s.replace(/^[\s]+/g, '').replace(/[\s]+$/, '');
  }

  static create<T extends object>(obj: new () => T, props: any): T {
    const result = new obj();

    return Object.assign(result, props);
  }
  static createFromArray<T extends object>(
    obj: new () => T,
    props: any[]
  ): T[] {
    const result = [];

    for (let i = 0, len = props.length; i < len; i += 1) {
      const l = new obj();
      Object.assign(l, props[i]);
      result.push(l);
    }
    return Object.assign(result, props);
  }

  static getFullName({ FirstName, MiddleName, LastName }): string {
    let list = [FirstName, MiddleName, LastName];
    list = list.filter((i) => !!i);

    return list.reduce((a, b) => {
      if (a === '') {
        return b;
      }
      return `${a} ${b}`;
    }, '');
  }
  static formatUser({
    FirstName,
    MiddleName,
    LastName,
    Email,
    Type,
    ID,
    CAI,
  }): string {
    const fullName = Utility.getFullName({ FirstName, MiddleName, LastName });
    const isWhitePage = ID === 0;
    const isPrivateEmail =
      Email.includes('tengizchevroil') &&
      Type <= UserType.Chevron &&
      isWhitePage;
    const isSharedEmail = Type === UserType.Shared && isWhitePage;

    let modifier = '';
    if (isPrivateEmail) {
      modifier = '(Private)';
    }

    if (isSharedEmail) {
      modifier = '(Shared)';
    }

    if (CAI === null) {
      return `${fullName} ${modifier} <${Email}>`;
    } else {
      return `${fullName} ${modifier} <${Email}> ${CAI}`;
    }
  }

  static validateEnvironment = (validEnvironments = ['dev', 'local']) =>
    validEnvironments.includes(environment.env)

  static validateSectionItem(validationErrors: ValidationErrors | null, isOptional: boolean): ResponseStatus {
    const isValid = validationErrors === null;

    if (isValid && isOptional) {
      return ResponseStatus.Answered;
    }

    if (!isValid && isOptional) {
      return ResponseStatus.NeedUserAttention;
    }

    if (isValid && !isOptional) {
      return ResponseStatus.Answered;
    }

    if (!isValid && isOptional) {
      return ResponseStatus.NeedUserAttention;
    }

    if (!isValid && !isOptional) {
      return ResponseStatus.NeedUserAttention;
    }

    return ResponseStatus.Optional;

  }

  static getResponseStatusCSSClass(responseStatus: ResponseStatus) {
    switch (responseStatus) {
      case ResponseStatus.Answered: return 'greenResponse';
      case ResponseStatus.Optional: return 'orangeResponse';
      case ResponseStatus.NeedUserAttention: return 'redResponse';
      default: return 'greenResponse';
    }
  }
}
