import {
  Component,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { PSSR, LoggedUser } from '#models/index';
import { PSSRUser } from '#models/pssr-user';
import { UserRole } from '#models/enum/user-role';
import { UserUiRole } from '#models/user-ui-role';
import { User } from '#models/user';
import { PSSRService } from '#services/api/pssr.service';
import { UserService } from '#services/api/user.service';

import { PSSRResponseService } from '#services/api';
import { ResponseItemImage } from '#models/response-item-image';
import { Utility } from '#services/shared/utility';
import { UserType } from '#models/enum/user-type';
import { take } from 'rxjs/operators';

import { AuthService } from '#services/shared';
import html2canvas from 'html2canvas';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';
import { BusinessUnitCode } from '#models/business-unit-code';
import { PSSRStatus } from '#models/pssr-status';
import { ParticipantType } from '#models/enum/participant-type';


@Component({
  selector: 'app-pssr-edit-members',
  templateUrl: './pssr-edit-members.component.html',
  styleUrls: ['./pssr-edit-members.component.scss'],
  providers: [TranslatePipe],
})
export class PssrEditMembersComponent implements OnInit {
  constructor(
    private svcPSSR: PSSRService,

    public userService: UserService,
    public pssrResponseService: PSSRResponseService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private renderer2: Renderer2,
    private translatePipe: TranslatePipe
  ) { }

  PSSRStatus = PSSRStatus;

  @Input() pssr: PSSR = new PSSR(); // Will be populated by parent
  @Input() allowMultiApprover: boolean;

  ParticipantType = ParticipantType;

  @Output() memberChanged = new EventEmitter();
  roles: UserUiRole[];
  successMsg: string;

  displayAddModal = false;
  displayEditModal = false;
  displayChev = false;
  displayNNonChev = false;
  displayMain = false;
  allowMulti = false;
  disableAdd = true;
  displayNull = true;
  selectedRole: string;
  selectedRoleId: number;
  selectedUser: User;

  possibleSelectedUser: User[] = [];
  possibleParticipantId: User[] = [];
  possibleOtherParticipant: string[] = [];
  maxMessage = '';

  participants: User[];
  participantList: PSSRUser[] = [];

  participantType: ParticipantType;

  otherParticipant: string;

  disablePartic: boolean;

  // showEditButton: boolean = false;
  participantToEdit: PSSRUser;
  showFinalRole: boolean;
  currentUser: LoggedUser;

  today = new Date();
  showSignature: boolean;
  canCaptureSignature: boolean;
  signedbycurrentuser: boolean;

  @ViewChild('signatureTemplate') signatureTemplate: ElementRef;

  displayImage = false;
  imagePhoto: string;

  editParticipantRole = 0;

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.participantToEdit = new PSSRUser();

    this.participantType = ParticipantType.ChevronOrSharedUser; // chevron
    this.possibleSelectedUser = [];
    this.setRoles();

    await this.getSignatures();
  }

  setRoles() {
    this.disablePartic =
      !this.pssr.buObj.PssrStatusesCanManageParticipants.includes(
        this.pssr.Status
      ) || this.pssr.IsDeleted;

    const disableFinalApr = this.allowMultiApprover
      ? this.disablePartic
      : Utility.isValidObj(
        this.pssr.PSSRUsers.Users.find((x) => x.Role === UserRole.Approver)
      ) || this.disablePartic;

    this.roles = [
      {
        RoleId: UserRole.Lead,
        RoleName: 'PSSR Team Lead',
        RoleLabel: 'PSSR Team Lead',
        Disabled: this.disablePartic,
      },
      {
        RoleId: UserRole.Team,
        RoleName: 'PSSR Team',
        RoleLabel: 'PSSR Team',
        Disabled: this.disablePartic,
      },
      {
        RoleId: UserRole.OpsRep,
        RoleName: 'Operations Representative',
        RoleLabel: 'Operations Representative',
        Disabled: this.disablePartic,
      },
      {
        RoleId: UserRole.Approver,
        RoleName: 'Final Approver',
        RoleLabel: 'Final Approver',
        Disabled: disableFinalApr,
      },
    ];
  }

  validateRoles() {
    this.showFinalRole = !Utility.isValidObj(
      this.pssr.PSSRUsers.Users.find((x) => x.Role === UserRole.Approver)
    );
  }
  updateToday() {
    this.today = new Date();
  }
  async getSignatures() {
    this.pssr.PSSRUsers.Users.forEach(async (u) => {
      u.SignatureDate = Utility.parseOffsetDate(u.SignatureDate);
      if (
        u.SignatureGUID != null &&
        u.SignatureGUID != undefined &&
        u.SignatureGUID != ''
      ) {
        await this.pssrResponseService
          .getImageByGUID(0, u.SignatureGUID)
          .toPromise()
          .then((data: ResponseItemImage) => {
            u.SignatureImage = data.Photo;
          });
      }
    });
  }

  // -- images
  btnExpandImage(image: string) {
    this.displayImage = true;
    this.imagePhoto = 'data:image/png;base64,' + image;
  }

  btnCollapseImage() {
    this.displayImage = false;
    this.imagePhoto = null;
  }

  openAddParticipant(role: UserUiRole) {
    this.maxMessage = '';
    this.possibleSelectedUser = [];
    this.possibleOtherParticipant = [];
    this.possibleParticipantId = [];
    this.displayChev = false;

    this.allowMulti = false;
    this.displayNNonChev = false;
    if (role.RoleName === 'OpsRepresentative' || 'ChangeTeam') {
      this.allowMulti = true;
      this.displayMain = true;
      this.displayNull = true;
    }
    if (role.RoleName === 'Lead') {
      this.allowMulti = false;
      this.displayMain = false;
      this.displayNull = false;
    }
    if (role.RoleName === 'FinalApprover') {
      this.allowMulti = false;
      this.displayMain = false;
      this.displayNull = false;
      if (!this.allowMultiApprover) {
        const user = this.pssr.PSSRUsers.Users.find(
          (x) => x.Role == role.RoleId
        );
        if (user != null) {
          this.toastrService.showWarning(
            this.translatePipe.transform('RoleAlreadyAddedWarningMessage', role)
          );
        } else {
          this.displayAddModal = true;
          this.selectedRole = role.RoleLabel;
          this.selectedRoleId = role.RoleId;
          this.selectedUser = null;
        }
      } else {
        this.displayAddModal = true;
        this.selectedRole = role.RoleLabel;
        this.selectedRoleId = role.RoleId;
        this.selectedUser = null;
      }
    } else {
      this.displayAddModal = true;
      this.selectedRole = role.RoleLabel;
      this.selectedRoleId = role.RoleId;
      this.selectedUser = null;
    }
    this.validateRoles();
  }

  showEditButton(participant: PSSRUser) {
    const signedbycurrentuser = this.pssr.CurrentUser.UserID === participant.UserID;

    const show =
      signedbycurrentuser ||
      this.pssr.CurrentUser.Role === UserRole.Lead ||
      this.pssr.CurrentUser.Role === UserRole.Approver ||
      this.currentUserIsLinkedTo(participant) ||
      this.currentUser.IsBUAdmin;
    return show;
  }

  openEditParticipant(participant: PSSRUser) {
    this.showSignature = false;

    this.validateRoles();

    this.participantToEdit = participant;

    this.displayEditModal = true;

    this.signedbycurrentuser =
      this.pssr.CurrentUser.UserID === this.participantToEdit.UserID;

    this.canCaptureSignature =
      this.signedbycurrentuser ||
      this.pssr.CurrentUser.Role === UserRole.Lead ||
      this.pssr.CurrentUser.Role === UserRole.Approver ||
      this.currentUserIsLinkedTo(participant);
  }

  currentUserIsLinkedTo(participant: PSSRUser): boolean {
    return (
      participant.ChildUsers.includes(this.currentUser.Email) ||
      this.pssr.CurrentUser.ParentUsers.includes(participant.UserEmail)
    );
  }

  // method

  addParticipant() {
    this.displayAddModal = false;
    this.participantList = [];
    let user: PSSRUser;

    if (this.participantType === ParticipantType.ChevronOrSharedUser) {
      for (let i = 0; i < this.possibleSelectedUser.length; i++) {
        user = new PSSRUser();
        user.PSSRID = this.pssr.Id;
        user.Role = this.selectedRoleId;
        user.UserID = this.possibleSelectedUser[i].ID;
        user.UserEmail = this.possibleSelectedUser[i].Email;
        user.UserCAI = this.possibleSelectedUser[i].CAI;
        user.UserName = Utility.getFullName(this.possibleSelectedUser[i]);
        this.participantList.push(user);
      }
    } else {
      for (let i = 0; i < this.possibleOtherParticipant.length; i++) {
        user = new PSSRUser();
        user.PSSRID = this.pssr.Id;
        user.Role = this.selectedRoleId;
        if (this.participantType === ParticipantType.External) {
          user.UserCAI = '';
        } else {
          user.UserCAI = this.possibleSelectedUser[i].CAI;
        }

        user.UserEmail = this.possibleOtherParticipant[i];
        this.participantList.push(user);
      }
    }

    const observable = this.svcPSSR
      .addParticipant(this.participantList)
      .pipe(take(1));

    observable.subscribe(
      (response) => {
        this.participantList = [];
        // create a new array to trigger @ngular model binding
        this.pssr.PSSRUsers.Users = [
          ...this.pssr.PSSRUsers.Users,
          ...response.Users,
        ];

        this.toastrService.showSuccess(
          this.translatePipe.transform('PssrParticipantAddedSuccessMessage')
        );

        const isMe = response.Users.some(
          (u) =>
            u.UserID == this.currentUser.ID ||
            u.ChildUsers.includes(this.currentUser.Email) ||
            (u.ParentUsers.some(parentUser => this.pssr.CurrentUser.ParentUsers.includes(parentUser)) && this.pssr.BusinessUnitCode === BusinessUnitCode.TCO)
        );
        if (isMe) {
          this.memberChanged.emit();
        }
        this.pssrResponseService.validateItemPriority(
          this.pssr,
          this.pssr.lstResponse,
          this.pssr.standAloneItems
        );
      },
      (err) => {
        this.toastrService.showError(err.error.ExceptionMessage);
      }
    );
  }


  onSelectNewRole({ target: { value: newRole } }): void {
    this.editParticipantRole = +newRole;
  }
  editParticipant(newRole: number): void {
    this.displayEditModal = false;

    this.svcPSSR
      .updateParticipant(
        {
          ...this.participantToEdit,
          Role: newRole
        }
      )

      .toPromise()
      .then((data) => {
        if (data.Success) {
          this.participantToEdit.Role = newRole;

          const ix = this.pssr.PSSRUsers.Users.findIndex(
            (pu) => pu.Id === this.participantToEdit.Id
          );
          if (ix > -1) {
            const isMe =
              this.pssr.PSSRUsers.Users[ix].UserID === this.currentUser.ID ||
              this.pssr.PSSRUsers.Users[ix].ChildUsers.includes(this.currentUser.Email) ||
              this.pssr.BusinessUnitCode === BusinessUnitCode.TCO && this.pssr.CurrentUser.ParentUsers.some(parentUser => this.pssr.PSSRUsers.Users[ix].ParentUsers.includes(parentUser));

            if (isMe) {
              this.memberChanged.emit();
            } else {
              this.pssr.PSSRUsers.Users[ix].Role = this.participantToEdit.Role;
              this.pssr.PSSRUsers.Users[ix].RoleName =
                this.participantToEdit.RoleName;

              this.pssrResponseService.validateItemPriority(
                this.pssr,
                this.pssr.lstResponse,
                this.pssr.standAloneItems
              );
            }
          }
        } else {
          this.toastrService.showError(data.Message);
        }
      });
  }

  public removeParticipant(participant: PSSRUser) {
    this.displayEditModal = false;

    this.svcPSSR
      .removeParticipant(participant.Id)
      .subscribe(
        () => {
          const usrIx = this.pssr.PSSRUsers.Users.findIndex(
            (x) => x.UserID == participant.UserID
          );

          if (usrIx > -1) {
            const isMe =
              this.pssr.PSSRUsers.Users[usrIx].UserID == this.currentUser.ID ||
              this.pssr.PSSRUsers.Users[usrIx].ChildUsers.includes(this.currentUser.Email) ||
              this.pssr.PSSRUsers.Users[usrIx].ParentUsers.some(parentUser => this.pssr.CurrentUser.ParentUsers.includes(parentUser));

            this.pssr.PSSRUsers.Users = this.pssr.PSSRUsers.Users.filter(
              (_, i) => i !== usrIx
            );

            if (isMe) {
              this.memberChanged.emit();
            }

            this.pssrResponseService.validateItemPriority(
              this.pssr,
              this.pssr.lstResponse,
              this.pssr.standAloneItems
            );
            this.setRoles();
          }
        },
        (error) => {
          this.toastrService.showError(error.error.ExceptionMessage);
        }
      );
  }

  // ---------
  getParticipants(event) {
    const query = event.query;
    this.userService
      .searchParticipants(query, this.pssr.OrganizationID)
      .subscribe((data) => {
        this.participants = data;

        if (data && data.length > 0) {
          this.expandDialog();
        }

        this.participants = this.participants.map((p) => ({
          ...p,
          FullName: Utility.formatUser(p),
        }));
      });
  }

  private expandDialog() {
    const uiDialogWrapper = document.querySelector('.ui-dialog');
    this.renderer2.addClass(uiDialogWrapper, 'searching-cai-users');
  }

  addChevronUser(entry: User): void {
    this.collapseDialog();

    if (this.possibleSelectedUser.length >= 5) {
      this.maxMessage = this.translatePipe.transform(
        'MaxParticipantsPerBatchWarningMessage'
      );
      return;
    }

    if (
      this.possibleSelectedUser.some(
        (item) =>
          item.CAI === entry.CAI &&
          item.Email === entry.Email &&
          item.ID === entry.ID
      )
    ) {
      return;
    }

    if (
      this.pssr.PSSRUsers.Users.some((item) => item.UserEmail === entry.Email)
    ) {
      this.maxMessage = this.translatePipe.transform(
        'ParticipantAlreadyParticipatedWarningMessage'
      );
      return;
    }

    this.possibleSelectedUser.push(entry);
    if (this.displayMain) {
      this.displayNNonChev = true;
      this.maxMessage = this.translatePipe.transform(
        'MaxParticipantsPerBatchInstruction'
      );
    }

    if (this.displayNull) {
      this.selectedUser = null;
    }

    this.disableAdd = false;
  }

  addExternalUser(entry: string): void {
    if (this.possibleOtherParticipant.length >= 5) {
      this.maxMessage = this.translatePipe.transform(
        'MaxParticipantsPerBatchWarningMessage'
      );
      return;
    }

    if (this.possibleOtherParticipant.some((item) => item === entry)) {
      return;
    }

    if (this.pssr.PSSRUsers.Users.some((item) => item.UserName === entry)) {
      this.maxMessage = this.translatePipe.transform(
        'ParticipantAlreadyParticipatedWarningMessage'
      );
      return;
    }
    this.possibleOtherParticipant.push(entry);
    if (this.displayMain) {
      this.displayChev = true;

      this.maxMessage = this.translatePipe.transform(
        'MaxParticipantsPerBatchInstruction'
      );
    }
    if (this.displayNull) {
      this.otherParticipant = null;
    }
    this.disableAdd = false;
  }

  removePossibleParticipant(entry) {
    if (this.participantType === ParticipantType.ChevronOrSharedUser) {
      const index: number = this.possibleSelectedUser.indexOf(entry);
      if (index !== -1) {
        this.possibleSelectedUser.splice(index, 1);
      }
      if (this.possibleSelectedUser.length === 0) {
        this.displayNNonChev = false;
        this.displayChev = false;
        this.disableAdd = true;
      }
    } else {
      const index: number = this.possibleOtherParticipant.indexOf(entry);
      if (index !== -1) {
        this.possibleOtherParticipant.splice(index, 1);
      }
      if (this.possibleOtherParticipant.length === 0) {
        this.displayNNonChev = false;
        this.displayChev = false;
        this.disableAdd = true;
      }
    }
    this.maxMessage = this.translatePipe.transform(
      'MaxParticipantsPerBatchInstruction'
    );
  }

  collapseDialog() {
    const uiDialogWrapper = document.querySelector('.ui-dialog');
    this.renderer2.removeClass(uiDialogWrapper, 'searching-cai-users');
  }

  async sign() {
    const canvas = await html2canvas(this.signatureTemplate.nativeElement);
    const signature = canvas.toDataURL('image/png');
    const img = {
      GUID: Utility.guid(),
      Id: 0,
      Photo: signature.replace('data:image/png;base64,', ''),
      ParticipantGUID: this.participantToEdit.GUID,
      PSSRID: this.participantToEdit.PSSRID,
      Caption: this.participantToEdit.UserName,
      CreateDate: new Date(),
      ImageType: 1,
      ResponseItemGUID: null,
      ResponseItemID: null,
      ResponseID: null,
      ResponseGUID: null,
      IsDeleted: false,
    };
    await this.pssrResponseService
      .uploadImage(img)
      .toPromise()
      .then(() => {
        // TODO implement
      });

    this.participantToEdit.SignatureDate = img.CreateDate;
    this.participantToEdit.SignatureGUID = img.GUID;
    this.participantToEdit.SignatureUrl = img.GUID;
    await this.svcPSSR.updateParticipant(this.participantToEdit).toPromise();
    this.participantToEdit.SignatureImage = img.Photo;
    const ix = this.pssr.PSSRUsers.Users.findIndex(
      (pu) => pu.Id === this.participantToEdit.Id
    );
    this.pssr.PSSRUsers.Users[ix] = this.participantToEdit;
    this.displayEditModal = false;
    this.showSignature = false;
  }

  async removesign() {
    const img = {
      GUID: this.participantToEdit.SignatureGUID,
      Id: 0,
      Photo: null,
      ParticipantGUID: this.participantToEdit.GUID,
      PSSRID: this.participantToEdit.PSSRID,
      Caption: this.participantToEdit.UserName,
      CreateDate: new Date(),
      ImageType: 1,
      ResponseItemGUID: null,
      ResponseItemID: null,
      ResponseID: null,
      ResponseGUID: null,
      IsDeleted: true,
    };
    await this.pssrResponseService
      .uploadImage(img)
      .toPromise()
      .then((data: any) => {
        // TODO implement
      });
    const ix = this.pssr.PSSRUsers.Users.findIndex(
      (pu) => pu.Id === this.participantToEdit.Id
    );
    this.pssr.PSSRUsers.Users[ix].SignatureGUID = null;
    this.pssr.PSSRUsers.Users[ix].SignatureImage = null;
    this.pssr.PSSRUsers.Users[ix].SignatureUrl = null;
    this.pssr.PSSRUsers.Users[ix].SignatureDate = null;
    this.displayEditModal = false;
    this.showSignature = false;
  }

  isPrivateEmail(participant: User) {
    return (
      participant.Email.includes('tengizchevroil') &&
      participant.Type <= UserType.New
    );
  }
  isSharedEmail(participant: User) {
    return (
      participant.Email.includes('tengizchevroil') &&
      participant.Type === UserType.Shared
    );
  }

  captureSignature() {
    this.updateToday();
    this.showSignature = true;
  }

  get canManageParticipants(): boolean {
    return this.svcPSSR.canManageParticipants(this.pssr.CurrentUser, this.pssr, this.pssr.buObj);
  }
}
