export enum ResponseStatus {
  None = 0,
  /**
   * Green
   */
  Answered = 1,

  /**
   * Orange
   */
  Optional = 2,

  /**
   * Red
   */
  NeedUserAttention = 3
}
