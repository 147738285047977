import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslationHistory } from '#models/translation-history';

@Injectable({
  providedIn: 'root',
})
export class TranslationHistoryService {
  constructor(private http: HttpClient) {}

  saveTranslationHistory(payload: TranslationHistory) {
    return this.http.post<TranslationHistory>(
      `${environment.apiEndpoint}/translationHistory`,
      payload
    );
  }
}
