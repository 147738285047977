import { ResponseItem } from '#models/response-item';
import { ItemType } from './enum/item-type';
import { ResponseStatus as ResponseStatus } from './enum/response-status';

export class PSSRResponse {
    Id: number;
    PSSRID: number;
    PSSRDescription: string;
    TaskPlanDescription: string;
    SectionID: number;
    SectionDescription: string;
    SubSectionDescription: string;
    ItemCode: string;
    ItemDescription: string;
    ResponseText: string;
    ActionItems: ResponseItem[];
    SortID: number;
    SectionSortID: number;
    SubSectionSortID: number;
    GUID: string;
    CreateDate: Date;
    CreatedBy: string;
    UpdatedDate: Date;
    UpdatedBy: string;
    CanAnswer: boolean;
    ItemType: ItemType;
    ShouldAddCommentToQuestion: boolean;
    DefaultSignatureDisclaimer: string;
    ResponseStatus: ResponseStatus;
}

