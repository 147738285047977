import { Component, OnInit } from '@angular/core';
import { FacilityService } from '#services/api/facility.service';
import { Facility } from '#models/facility';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-facility-create',
  templateUrl: './facility-create.component.html',
})
export class FacilityCreateComponent implements OnInit {
  facility: Facility = new Facility();
  displayWarningName: boolean;
  subAreaId: number;
  areaId: number;
  buId: number;
  hierarchyLabel3: string;

  selectedBU = new BusinessUnit();

  constructor(
    private toastrService: ToastrService,
    private facilityService: FacilityService,
    private router: Router,
    private route: ActivatedRoute,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.buId = this.route.snapshot.params['buId'];
    this.areaId = this.route.snapshot.params['areaId'];
    this.subAreaId = this.route.snapshot.params['subAreaId'];
    this.hierarchyLabel3 = this.route.snapshot.params['hierarchyLabel3'];

    this.facility.SubAreaId = this.subAreaId;
    this.displayWarningName = false;

    this.businessUnitService
      .getBusinessUnitById(this.buId)
      .toPromise()
      .then((bu) => (this.selectedBU = bu));
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/facility',
      { buId: this.buId, areaId: this.areaId, subAreaId: this.subAreaId },
    ]);
  }

  public createFacility(): void {
    const invalidName =
      this.facility.Name == undefined ||
      this.facility.Name == null ||
      this.facility.Name.length == 0;
    this.displayWarningName = invalidName;
    if (!invalidName) {
      this.facilityService
        .createFacility(this.facility)
        .subscribe(
          (data: Facility) => {
            this.onRedirectButtonClick();
          },
          (error) => {

            this.toastrService.showError( error.error.ExceptionMessage);
          }
        );
    }
  }
}
