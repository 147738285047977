import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {
  private isLoading = new EventEmitter<boolean>();

  show() {
    this.isLoading.emit(true);
  }

  hide() {
    this.isLoading.emit(false);
  }

  isVisible(): EventEmitter<boolean> {
    return this.isLoading;
  }
}
