import { Component, OnInit, Input } from '@angular/core';
import { Equipment } from '#models/equipment';
import { EquipmentService } from '#services/api/equipment.service';
import { Router } from '@angular/router';
import { EquipmentTemplateService } from '#services/api/equipment-template.service';

@Component({
  selector: 'app-equipment-list',
  templateUrl: './equipment-list.component.html',
  styleUrls: ['./equipment-list.component.scss'],
  providers: [EquipmentService, EquipmentTemplateService]
})
export class EquipmentListComponent implements OnInit {

  @Input() isTemplate: boolean;
  @Input() editMode: boolean;
  @Input() parentID: number;

  lstEquipment: Equipment[];

  constructor(public svcEquipment: EquipmentService,
    public svcEquipmentTemplate: EquipmentTemplateService,
    private router: Router) { }

  ngOnInit() {
    this.loadEquipment();
  }

  private loadEquipment() {

    if (this.isTemplate) {
       this.svcEquipmentTemplate.getAllEquipments()

        .subscribe((data: Equipment[]) => {
          this.lstEquipment = data;
        });
    } else {
       this.svcEquipment.getAllByProject(this.parentID)

        .subscribe((data: Equipment[]) => {
          this.lstEquipment = data;
        });
    }

  }


  addEquipmentToProject() {
    alert('Add code to include equipment in project and refresh the project equipment list component');
  }

  onRedirectButtonClick(url: string, id: number, pId: number, templateMode: boolean): void {
    this.router.navigate([url, {itemId: id, parentId: pId, isTemplate: templateMode}]);
  }

}
