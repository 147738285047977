import { ReadSubCategoryDTO } from '#models/subcategory';
import { SubCategoryService } from '#services/api';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from '#services/shared/toastr.service';
import { Subscription } from 'rxjs-compat/Subscription';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-subcategory-delete',
  templateUrl: './subcategory-delete.component.html',
})
export class SubcategoryDeleteComponent implements OnInit, OnDestroy {

  subCategory: ReadSubCategoryDTO = {
    Id: 0,
    Categories: [],
    IsActive: true,
    Name: ''
  };

  sub = new Subscription();

  constructor(
    private toastrService: ToastrService,
    private route: ActivatedRoute,

    private subCategoryService: SubCategoryService,
    private router: Router
  ) {}

  ngOnInit() {
    this.sub.add(
      this.route.params.subscribe((params) => {
        this.subCategory.Id = +params.subCategoryId;
        this.loadSubCategory(this.subCategory.Id);
      })
    );
  }

  loadSubCategory(subCategoryId: number): void {

    this.sub.add(
      this.subCategoryService
        .getById(subCategoryId)
        .pipe(
          take(1),

        )
        .subscribe(
          (subCategory: ReadSubCategoryDTO) => {
            this.subCategory = subCategory;
          },
          (err) => {

            this.toastrService.showError(err.error.ExceptionMessage);

          }
        )
    );
  }

  deleteSubCategory(subCategoryId: number): void {

    this.sub.add(
      this.subCategoryService
        .delete(subCategoryId)
        .pipe(
          take(1),

        )
        .subscribe(
          () => {
            this.router.navigate(['/admin/subcategory']);
          },
          (err) => {
            this.toastrService.showError(err.error.ExceptionMessage);


          }
        )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
