import { Project, ProjectStatus } from '#models/project';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { ProjectUser } from '../../models/project-user';
import { ProjectUserList } from '../../models/project-user-list';
import { ResultModel } from '../../models/result-model';
import { ProjectFilter } from '#models/project-filter';

@Injectable({
  providedIn: 'root',
})
export class ProjectService {
  constructor(private http: HttpClient) { }

  public getProjectById(projectId: number) {
    return this.http.get<Project>(
      `${environment.apiEndpoint}/project/${projectId}`
    );
  }

  public addProject(project: Project): Observable<Project> {
    return this.http.post<Project>(
      `${environment.apiEndpoint}/project`,
      project
    );
  }

  public updateProject(project: Project): Observable<Project> {
    return this.http.put<Project>(
      `${environment.apiEndpoint}/project`,
      project
    );
  }

  public downloadLoadSheet(project: Project): Observable<Object> {
    const result = this.http.post<Object>(
      `${environment.apiEndpoint}/project/downloadEAMLoadSheet`,
      project,
      { responseType: 'blob' as 'json' }
    );
    return result;
  }

  public downloadEquipmentList(project: Project): Observable<Object> {
    const result = this.http.post<Object>(
      `${environment.apiEndpoint}/project/downloadEquipmentList`,
      project,
      { responseType: 'blob' as 'json' }
    );
    return result;
  }

  public getFullListByOrgId(orgId: number) {
    return this.http.get<Project[]>(
      `${environment.apiEndpoint}/project/fullList/${orgId}`
    );
  }

  public getByOrgId(orgId: number) {
    return this.http.get<Project[]>(
      `${environment.apiEndpoint}/project/getByOrgId/${orgId}`
    );
  }

  public getByEquipmentExists(orgId: number): Observable<Project[]> {
    return this.http.get<Project[]>(
      `${environment.apiEndpoint}/project/getByEquipExist/${orgId}`
    );
  }

  public addParticipant(
    projectUsers: ProjectUser[]
  ): Observable<ProjectUserList> {
    return this.http.post<ProjectUserList>(
      `${environment.apiEndpoint}/project/addParticipant`,
      projectUsers
    );
  }

  public removeParticipant(id: number): Observable<ProjectUser> {
    return this.http.put<ProjectUser>(
      `${environment.apiEndpoint}/project/removeParticipant/${id}`,
      null
    );
  }

  public copyFromProject(
    fromProj: number,
    toProj: number
  ): Observable<ResultModel> {
    return this.http.get<ResultModel>(
      `${environment.apiEndpoint}/project/copyFromProject/${fromProj}/${toProj}`
    );
  }

  public downloadABChecks(projId: number) {
    return this.http.get(
      `${environment.apiEndpoint}/project/downloadABChecks/${projId}`,
      { responseType: 'blob' }
    );
  }

  public delete(id: number, deletePSSRs: boolean) {
    return this.http.put<void>(
      `${environment.apiEndpoint}/project/delete/${id}/${deletePSSRs}`,
      null
    );
  }

  getLabelClass(project: Project): string {
    if (project.IsDeleted) {
      return 'label-deleted';
    }
    if (project.Status === ProjectStatus.Active) {
      return 'label-approved';
    }
    if (project.Status === ProjectStatus.Closed) {
      return 'label-closed';
    }
    return 'label-cancel';
  }

  searchProjectsList({
    organizationID = 0,
    isDeleted = false,
    statuses = [ProjectStatus.Active],
    maxRangeDate = null,
    minRangeDate = null,
  }: ProjectFilter) {
    let params = new HttpParams()
      .set('isDeleted', `${isDeleted}`)
      .set('organizationID', `${organizationID}`);

    if (minRangeDate !== null) {
      params = params.append('minRangeDate', minRangeDate.toISOString());
    }

    if (maxRangeDate !== null) {
      params = params.append('maxRangeDate', maxRangeDate.toISOString());
    }

    for (const status of statuses) {
      params = params.append('statuses', `${status}`);
    }

    return this.http.get<Project[]>(`${environment.apiEndpoint}/project?`, {
      params,
    });
  }
  restoreProject(projectId: number): Observable<any> {
    return this.http.put(
      `${environment.apiEndpoint}/project/${projectId}/restore`,
      {}
    );
  }
}
