<style>
.centerButtonContainer {
    display: flex;
    justify-content: center;
}
</style>
<div class="box-shadow module" style="width:80%; margin:auto; background-color: midnightblue; color: white">
    <h2>{{'Delete Task Plan Sub Section'|translate}}</h2>
    <hr>
    <h3 [innerHTML]="'ConfirmDeleteTaskPlanSubSectionMessage'|translate:taskPlanSubSectionsLabels"></h3>
    <h4>{{'Alltherelateditemswillberemoved'|translate}}.</h4>
    <div class="form-actions no-color">
        <div class="centerButtonContainer">
            <a class="btn btn-default" style="width: 100px;" (click)="deleteTaskPlanSubSection()" >{{'Delete'|translate}}</a>
            &nbsp;
            &nbsp;
            <a class="btn btn-default" style="width: 100px;" (click)="onRedirectButtonClick()">{{'Back'|translate}}</a>
        </div>
    </div>
</div>
