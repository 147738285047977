import { Injectable } from '@angular/core';
import { TranslateDefaultParser } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageParser extends TranslateDefaultParser {
  getValue(target: any, key: string): any {
    return super.getValue(target, key.replace(/\s/g, ''));
  }
}
