import { Group } from './group';
import { PSSRType } from './pssr-type';
import { Category } from './category';
import { PSSRStatus } from './pssr-status';
import { BusinessUnitSharedUser } from './business-unit-shared-user';
import { BusinessUnitCode } from './business-unit-code';

export class BusinessUnit {
  Id: number;
  Name = '';
  HierarchyLabel1: string;
  HierarchyLabel2: string;
  HierarchyLabel3: string;
  ShowHierarchyLabel3: boolean;
  ShowPssrType: boolean;
  ScheduledEmail: boolean;
  AllowWetSignature: boolean;
  PSSRLabel: string;
  OrganizationLabel: string;
  P3Label: string;
  P4Label: string;
  P5Label: string;
  ShowP5: boolean;
  ShowProject: boolean;
  ShowProjectInput: boolean;
  Groups: Group[] = [];
  ShowVerbalApproval: boolean;
  ShowCCC = false;
  ShowORR = false;
  PssrTypes: PSSRType[] = [];
  ShowQAQC = false;
  EventLabel: string;
  Categories: Category[] = [];
  AllowNAResponses = true;
  Instructions: string = null;
  AllowLeadsDeleteQuestions = false;
  ShowKMS: boolean;
  AllowMembersToCompletePSSR = false;
  MOCWOProjectNumberRestrictionEnabled = false;
  AllowMultiApprover: boolean;
  RestrictApproverSignatureOnLeadsDevice = false;
  AllowLeadtoShareDeviceWithApprover = true;
  RequireFinalApproverBeforeInitiation = false;
  RequireActionItemDueDate = false;
  SendAllNotificationsToApprovers = false;
  PssrTitle = 'Pssr';
  SharedUsers: BusinessUnitSharedUser[] = [];
  ShowSectionsCollapsed: boolean;
  AllowApproverToSubmitForApproval: boolean;
  RequiresApprovalForAccess: boolean;
  CanImportSharedUsers = false;
  CanTranslate = false;
  HasTraining = false;
  LimitAccessToAIOwner: boolean;
  AllowTeamLeadToAttach: boolean;
  AllowAIOwnerToAttach: boolean;
  PssrAbandonedInterval: number;
  PssrStatusesCanManageParticipants: PSSRStatus[] = [
    PSSRStatus.Draft,
    PSSRStatus.Initiated,
    PSSRStatus.InProgress,
    PSSRStatus.Completed,
    PSSRStatus.PendingApproval,
  ];
  Code: BusinessUnitCode;
}

