import { Component, OnInit } from '@angular/core';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Router } from '@angular/router';
import { AuthService } from '#services/shared';
import { Role } from '#models/enum/role';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-business-units',
  templateUrl: './business-units.component.html',
  styleUrls: ['./business-units.component.scss'],
  providers: [TranslatePipe],
})
export class BusinessUnitsComponent implements OnInit {
  public businessUnits: BusinessUnit[];

  constructor(
    private businessUnitsSvc: BusinessUnitsService,

    private svcRouter: Router,
    private authService: AuthService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.loadBusinessUnits();
  }

  get canCreateBusinessUnits(): boolean {
    return this.authService.hasRole(Role.SystemAdmin);
  }

  private loadBusinessUnits() {
    this.businessUnitsSvc
      .getUserAdminBUs()
      .toPromise()
      .then((data) => {
        this.businessUnits = data.map((b) => ({
          ...b,
          AllowLeadtoShareDeviceWithApprover: !b.RestrictApproverSignatureOnLeadsDevice
        }
        ));
      });
  }

  btnCreate() {
    this.svcRouter.navigate(['admin/business-unit-create']);
  }

  btnEdit(buId: number): void {
    this.svcRouter.navigate(['admin/business-unit-edit/', { id: buId }]);
  }

  getOtherOptions(bu: BusinessUnit): string {
    const keys = Object.getOwnPropertyNames(bu);

    const csv = keys
      .filter((k) => {
        if (['ShowHierarchyLabel3'].includes(k)) {
          return false;
        }
        if ('boolean' !== typeof bu[k]) {
          return false;
        }
        if ('ShowProject' === k) {
          return false;
        }
        if ('ShowProjectInput' === k) {
          return bu.ShowProject;
        }
        if ('RestrictApproverSignatureOnLeadsDevice' === k) {
          return false;
        }
        return bu[k];
      })
      .map((v) =>
        v !== 'ShowProjectInput'
          ? v
          : bu.ShowProjectInput
            ? 'ShowProjectInput'
            : 'ShowProjectInputAsDropdown'
      )
      .map((v) => this.translatePipe.transform(v, []))
      .join(',\n');

    return csv;
  }
}
