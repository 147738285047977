import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';



import {
  EndDeviceTypeService,
  EndDeviceTemplateService,
  EquipmentTemplateService,
  ManufacturerModelService,
  BusinessUnitsService,
} from '#services/api/index';

import {
  EndDeviceType,
  EquipmentTemplate,
  ManufacturerModel,
  EndDeviceTemplate,
  BusinessUnit,
} from '#models/index';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-end-device-form',
  templateUrl: './end-device-form.component.html',
  styleUrls: ['./end-device-form.component.scss'],
})
export class EndDeviceFormComponent implements OnInit {
  //#region VARIABLES

  endDevice: EndDeviceTemplate = new EndDeviceTemplate();
  lstEndDeviceTypes: EndDeviceType[] = [];

  equipment: EquipmentTemplate;
  equipmentTemplate: EquipmentTemplate;
  deviceId: number;
  equipmentId: number;

  canEdit = true;

  manufModelList: ManufacturerModel[] = [];
  manufModelSelected: ManufacturerModel;

  buId: number;
  selectedBU = new BusinessUnit();

  //#endregion

  constructor(
    private router: Router,
    private toastrService: ToastrService,
    public svcEndDeviceTemplate: EndDeviceTemplateService,
    public svcEquipmentTemplate: EquipmentTemplateService,
    public svcEndDeviceType: EndDeviceTypeService,
    private aRoute: ActivatedRoute,
    private manufModelService: ManufacturerModelService,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    // this.aRoute.params.subscribe((p) => {
    this.deviceId = this.aRoute.snapshot.params['itemId'];
    this.equipmentId = this.aRoute.snapshot.params['parentId'];
    this.buId = this.aRoute.snapshot.params['buId'];
    // });

    if (this.deviceId > 0) {
      this.loadDevice();
    }

    if (this.equipmentId > 0) {
      this.loadEquipment();
    }

    if (this.buId > 0) {
      this.loadDeviceTypes();

      //

      this.businessUnitService
        .getBusinessUnitById(this.buId)
        .toPromise()
        .then((bu) => (this.selectedBU = bu));
    }
  }

  private loadDevice() {
    if (this.deviceId > 0) {
      const devTemp = this.svcEndDeviceTemplate
        .getEndDeviceRealTemplateById(this.deviceId)
        ;
      devTemp.subscribe((endDev) => {
        this.endDevice = endDev;
        this.equipmentId = this.endDevice.EquipmentTemplateId;
      }, (err) => {
        this.handleError(
          err,
          'Error getting End Device Template information: '
        );
      });
    }
  }

  private loadDeviceTypes() {
    if (this.buId > 0) {
      const devType = this.svcEndDeviceType
        .getEndDeviceTypeByBU(this.buId);

      devType.subscribe((devTypes) => {
        this.lstEndDeviceTypes = devTypes;
      }, (err) => {
        this.handleError(err, 'Error loading Device Types: '); });
    }
  }

  loadManufModelByEndDevTypeId() {
    if (this.endDevice.EndDeviceTypeId) {
      const manufMod = this.manufModelService
        .getByEndDevTypeId(this.endDevice.EndDeviceTypeId)
        ;
      manufMod.subscribe((manufModels) => {
        this.manufModelList = manufModels;
      }, (err) => {
        this.handleError(err, 'Error loading Manufacturer/Model: '); });
    }
  }

  private loadEquipment() {
    const equipTemp = this.svcEquipmentTemplate
      .getEquipTemplateById(this.equipmentId)
     ;
    equipTemp.subscribe((equip) => {
      this.equipmentTemplate = equip;
    }, (err) => {
      this.handleError(err, 'Error loading Equipment Template: ');

    });
  }

  onSubmitEndDevice() {
    this.endDevice.EquipmentTemplateId = this.equipmentId;
    if (this.endDevice.Id > 0) {
      // Update
      const updDev = this.svcEndDeviceTemplate
        .updateTemplate(this.endDevice);

      updDev.subscribe((updTemp) => {
        this.endDevice = updTemp;
        this.showMessage('End Device Template updated successfully', 0);
        this.backToEquipment();
      }, (err) => {

        this.handleError(err, 'Error updating End Device Template: ');

      });
    } else {
      // Create
      const createDev = this.svcEndDeviceTemplate
        .createTemplate(this.endDevice);

      createDev.subscribe((newTemp) => {
        this.endDevice = newTemp;
        this.showMessage('End Device Template created successfully', 0);
        this.backToEquipment();
      }, (err) => {
        this.handleError(err, 'Error creating End Device Template: ');

      });
    }
  }

  backToEquipment() {
    setTimeout(() => {
      this.router.navigate([
        '/admin/equipment-form',
        { itemId: this.equipmentTemplate.Id, parentId: this.buId },
      ]);
    }, 1000);
  }

  onRedirectButtonClick(url: string): void {
    this.router.navigate([
      url,
      { itemId: this.equipmentTemplate.Id, parentId: this.buId },
    ]);
  }

  private handleError(err: any, msg: string) {
    if (err.status === 500) {
      this.showMessage(err.error.ExceptionMessage, 1);
    } else {
      this.showMessage(msg + err.message, 1);
    }
  }

  showMessage(info: string, dv: number) {
    if (dv === 1) {
      this.toastrService.showError( info);
    } else {
      this.toastrService.showSuccess( info);
    }
  }
}
