import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneOffsetInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    const timezone = new Date().getTimezoneOffset();

    const request = req.clone({
      headers: req.headers.set('X-Timezone-Offset', `${timezone}`)
    });

    return next.handle(request);
  }
}
