import { Component, OnInit } from '@angular/core';
import {
  Area,
  SubArea,
  QAQCResponse,
  QAQCRequest,
  QAQCItem,
  Facility,
  TaskPlan,
  BusinessUnit,
  PSSR,
  Project,
  QAQCType,
  TaskPlanType,
  ProjectStatus,
} from '#models/index';
import {
  AreaService,
  QAQCService,
  SubAreaService,
  FacilityService,
  BusinessUnitsService,
  OrganizationService,
  TaskPlanService,
  ProjectService,
} from '#services/api/index';
import { ActivatedRoute, Router } from '@angular/router';
import { PSSRService } from '#services/api/pssr.service';

import { Organization } from '#models/organization';
import { Location } from '@angular/common';
import { PSSRStatus } from '#models/pssr-status';
import { Utility } from '#services/shared/utility';
import { SelectItem } from 'primeng/api';
import { PSSRType } from '#models/pssr-type';
import { finalize } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-pssr-create-header',
  templateUrl: './pssr-create-header.component.html',
  styleUrls: ['./pssr-create-header.component.scss'],
  providers: [TranslatePipe],
})
export class PssrCreateHeaderComponent implements OnInit {
  pssr: PSSR = new PSSR();

  lstBusinessUnit: BusinessUnit[] = [];
  lstOrgs: Organization[] = [];
  lstArea: Area[] = [];
  lstSubArea: SubArea[] = [];
  lstFacility: Facility[] = [];
  lstTaskPlan: TaskPlan[] = [];
  lstProject: Project[] = [];

  buId: number;
  orgId: number;
  projId: number;
  areaId?: number = null;
  subAreaId?: number = null;
  facilityId?: number = null;
  organizationId?: number = null;
  taskPlanId?: number = null;


  selectedBU: BusinessUnit = new BusinessUnit();
  orgName: string;

  newSubArea: SubArea;
  newFacility: Facility;
  newProject: Project;
  newQAQCProject: QAQCItem;
  newSubAreaName: string;
  newFacilityName: string;
  newProjectName: string;

  displayFacility: boolean;
  allowSubAreaInput: boolean;
  newFacilty: Facility;
  displayWarningName: boolean;
  createNewSubArea: boolean;
  createNewFacility: boolean;
  createNewProject: boolean;
  selectFromQAQC: boolean;
  newSubAreaAdded: boolean;
  newSubAreaValidation: boolean;

  lstqaqcEvents: QAQCItem[] = [];
  lstqaqcMilestone: QAQCItem[] = [];
  qaqcMilestoneId: string;
  lstqaqcMilestoneWO: QAQCItem[] = [];
  qaqcMilestoneWOId: string;
  lstqaqcWO: QAQCItem[] = [];
  qaqcWOId: string;
  isQAQCMilestone: boolean;
  isQAQCWorkOrder: boolean;

  pssrTypes: SelectItem[];


  public get canImport(): boolean {
    if (!this.pssr) {
      return false;
    }

    const { MOC, BusinessUnitID, AreaID, SubAreaID, OrganizationID } =
      this.pssr;

    return (
      !!MOC && !!BusinessUnitID && !!AreaID && !!SubAreaID && !!OrganizationID
    );
  }

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,

    private svcTaskPlan: TaskPlanService,
    private svcBusinessUnit: BusinessUnitsService,
    private svcOrganization: OrganizationService,
    private svcArea: AreaService,
    private svcSubArea: SubAreaService,
    private svcFacility: FacilityService,
    private svcPSSR: PSSRService,
    private svcProject: ProjectService,
    private svcQAQC: QAQCService,
    private _location: Location,
    private translatePipe: TranslatePipe,
    private toastrService: ToastrService
  ) { }

  // Events

  ngOnInit() {
    this.activeRoute.params.subscribe((p) => {
      this.buId = +p['buId'];
      this.orgId = +p['orgId'];
      this.projId = +p['projId'];
    });
    const tmpLocalStorage = localStorage.getItem('localPssrFilter');
    if (tmpLocalStorage) {
      const localFilter = JSON.parse(tmpLocalStorage);
      this.areaId = localFilter.AreaId;
      this.subAreaId = localFilter.SubAreaId;
      this.facilityId = localFilter.FacilityId;
      this.organizationId = localFilter.OrganizationId;


      if (localFilter.TaskPlanId === 0) {
        this.taskPlanId = null;
      } else {
        this.taskPlanId = localFilter.TaskPlanId;
      }

      if (localFilter.SubAreaId === 0) {
        this.subAreaId = null;
      } else {
        this.subAreaId = localFilter.SubAreaId;
      }

      if (localFilter.FacilityId === 0) {
        this.facilityId = null;
      } else {
        this.facilityId = localFilter.FacilityId;
      }

    }

    this.displayFacility = true;
    this.newSubAreaName = null;
    this.allowSubAreaInput = false;
    this.pssr.ProjectID = this.projId > 0 ? this.projId : null;
    this.pssr.Description = Utility.isValidString(this.pssr.Description)
      ? this.pssr.Description
      : '';
    this.pssr.Status = PSSRStatus.Draft;
    this.pssr.TaskPlanID = null;
    this.displayWarningName = false;
    this.newSubAreaAdded = false;
    this.newSubAreaValidation = true;

    this.loadBusinessUnit();
  }

  KMSNav() {
    this.router.navigate([
      '/pssr-kms/',
      { buId: this.selectedBU.Id, orgId: this.pssr.OrganizationID },
    ]);
  }

  async loadQAQCEvent() {
    const qaqcRequestEvent = new QAQCRequest();
    qaqcRequestEvent.Site = this.selectedBU.Name;
    this.svcQAQC
      .getQAQCEventBySite(qaqcRequestEvent)
      .pipe(
        finalize(() => {
          // TODO implement
        })
      )
      .subscribe((data: QAQCResponse) => {
        this.lstqaqcEvents = data.Results.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        this.lstqaqcEvents = this.lstqaqcEvents.filter(
          (x) => x.Name.length > 0
        );
      });
  }

  qaqcEventChange() {
    // TODO implement
  }

  async loadQAQCMilestone() {
    const qaqcRequest = new QAQCRequest();
    qaqcRequest.Site = this.selectedBU.Name;
    if (this.pssr.ProjectID == null || this.pssr.ProjectID == undefined) {
      // TODO implement
    } else {
      const eventId = this.lstProject.find(
        (x) => x.Id === this.pssr.ProjectID
      ).QAQCEventId;
      qaqcRequest.EventId = eventId;
      this.svcQAQC
        .getQAQCMilestoneBySite(qaqcRequest)
        .subscribe((data: QAQCResponse) => {
          this.lstqaqcMilestone = data.Results.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
          this.lstqaqcMilestone = this.lstqaqcMilestone.filter(
            (x) => x.Name.length > 0
          );
        });
    }
  }

  async loadQAQCWO() {
    const qaqcRequestWO = new QAQCRequest();
    qaqcRequestWO.Site = this.selectedBU.Name;
    if (this.pssr.ProjectID == null || this.pssr.ProjectID == undefined) {
      // TODO implement
    } else {
      const eventId = this.lstProject.find(
        (x) => x.Id === this.pssr.ProjectID
      ).QAQCEventId;
      qaqcRequestWO.EventId = eventId;
      this.svcQAQC
        .getQAQCWorkOrderBySite(qaqcRequestWO)
        .subscribe((data: QAQCResponse) => {
          this.lstqaqcWO = data.Results.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
          this.lstqaqcWO = this.lstqaqcWO.filter((x) => x.Name.length > 0);
        });
    }
  }

  async loadQAQCMWO(Id: string) {
    const qaqcRequestMWO = new QAQCRequest();
    qaqcRequestMWO.Site = this.selectedBU.Name;
    qaqcRequestMWO.MilestoneId = Id;
    this.svcQAQC
      .getQAQCWorkOrderByMilestone(qaqcRequestMWO)
      .pipe(
        finalize(() => {
          // TODO implement
        })
      )
      .subscribe((data: QAQCResponse) => {
        this.lstqaqcMilestoneWO = data.Results.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        this.lstqaqcMilestoneWO = this.lstqaqcMilestoneWO.filter(
          (x) => x.Name.length > 0
        );
      });
  }

  qaqcMilestoneChange() {
    this.pssr.WO = null;
    if (this.pssr.QAQCMilestoneId) {
      this.loadQAQCMWO(this.pssr.QAQCMilestoneId);
      this.pssr.QAQCMilestone = this.lstqaqcMilestone.find(
        (x) => x.Id === this.pssr.QAQCMilestoneId
      ).Name;
    } else {
      this.pssr.QAQCMilestone = null;
      this.pssr.QAQCMilestoneId = null;
    }
  }

  qaqcMilestoneChangeWO() {
    if (this.pssr.QAQCWorkOrderId) {
      this.pssr.QAQCWorkOrder = this.lstqaqcMilestoneWO.find(
        (x) => x.Id === this.pssr.QAQCWorkOrderId
      ).Name;
      this.pssr.WO = this.lstqaqcMilestoneWO.find(
        (x) => x.Id === this.pssr.QAQCWorkOrderId
      ).Name;
    } else {
      this.pssr.QAQCWorkOrder = null;
      this.pssr.QAQCWorkOrderId = null;
      this.pssr.WO = null;
    }
  }

  qaqcWOChange() {
    if (this.pssr.QAQCWorkOrderId) {
      this.pssr.QAQCWorkOrder = this.lstqaqcWO.find(
        (x) => x.Id === this.pssr.QAQCWorkOrderId
      ).Name;
      this.pssr.WO = this.lstqaqcWO.find(
        (x) => x.Id === this.pssr.QAQCWorkOrderId
      ).Name;
    } else {
      this.pssr.QAQCWorkOrder = null;
      this.pssr.QAQCWorkOrderId = null;
      this.pssr.WO = null;
    }
  }

  qaqcTypeChange(type: boolean) {
    if (type) {
      this.isQAQCWorkOrder = false;
    } else {
      this.isQAQCMilestone = false;
      this.lstqaqcMilestoneWO = [];
    }
    this.pssr.QAQCWorkOrder = null;
    this.pssr.QAQCWorkOrderId = null;
    this.pssr.QAQCMilestone = null;
    this.pssr.QAQCMilestoneId = null;
    this.pssr.WO = null;
  }

  async changeProject() {
    this.createNewProject = false;
    this.selectFromQAQC = false;
    this.newProjectName = null;
    this.pssr.QAQCWorkOrder = null;
    this.pssr.QAQCWorkOrderId = null;
    this.pssr.QAQCMilestone = null;
    this.pssr.QAQCMilestoneId = null;
    this.lstqaqcMilestone = [];
    this.lstqaqcMilestoneWO = [];
    this.lstqaqcWO = [];
    this.isQAQCMilestone = true;
    this.isQAQCWorkOrder = false;
    if (this.selectedBU.ShowQAQC) {

      await this.loadQAQCMilestone();
      await this.loadQAQCWO();
      this.pssr.WO = null;
    }
  }

  async changeBU() {
    this.pssr.AreaID = null;
    this.pssr.OrganizationID = null;
    this.pssr.SubAreaID = null;
    this.pssr.FacilityID = null;
    this.pssr.TaskPlanID = null;
    this.pssr.ProjectID = null;
    this.pssr.PssrTypeId = null;
    this.pssr.PssrTypeName = null;

    this.selectedBU =
      this.pssr.BusinessUnitID > 0
        ? this.lstBusinessUnit.find((x) => x.Id === this.pssr.BusinessUnitID)
        : this.lstBusinessUnit[0];
    this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
    await this.loadTaskPlan(this.pssr.BusinessUnitID);
    await this.loadOrganizations(this.pssr.BusinessUnitID);
    await this.loadArea(this.pssr.BusinessUnitID);
    if (this.selectedBU.ShowProject && !this.selectedBU.ShowProjectInput) {
      await this.loadProject();
      await this.loadProjectQAQCFunctions();
    }
    if (this.selectedBU.ShowQAQC) {
      await this.loadQAQCEvent();
    }

  }

  async changeArea(entry: number = null) {
    this.pssr.SubAreaID = entry;
    this.pssr.FacilityID = null;
    this.newSubAreaName = null;
    this.createNewSubArea = false;
    this.newSubAreaValidation = true;
    if (this.pssr.AreaID > 0) {
      await this.loadSubArea(this.pssr.AreaID);
    }
  }

  async changeSubArea(entry: number = null) {
    this.pssr.FacilityID = entry;
    this.newFacilityName = null;
    this.createNewFacility = false;
    await this.loadFacility(this.pssr.SubAreaID);
  }

  activateSubArea() {
    if (!this.createNewSubArea) {
      this.newSubAreaValidation = false;
    }
    if (this.createNewSubArea && this.pssr.SubAreaID != null) {
      this.newSubAreaValidation = true;
    }
  }

  async addProject() {
    this.newProject = new Project();
    // validation
    if (this.lstProject[0] != undefined && this.lstProject[0] != null) {
      this.newProject.OrganizationId = this.pssr.OrganizationID;
      if (this.selectFromQAQC) {
        this.newProject.Name = this.newQAQCProject.Name;
        this.newProject.QAQCEventId = this.newQAQCProject.Id;
      } else {
        this.newProject.Name = this.newProjectName;
      }
      this.newProject.Description = '';
      const validProject = this.lstProject.find(
        (item) => item.Name === this.newProject.Name
      );
      if (validProject) {
        this.toastrService.showWarning(this.translatePipe.transform('The record entered already exists'));

      } else {
        await this.createProject();
      }
    } else {
      this.newProject.OrganizationId = this.pssr.OrganizationID;
      if (this.selectFromQAQC) {
        this.newProject.Name = this.newQAQCProject.Name;
        this.newProject.QAQCEventId = this.newQAQCProject.Id;
      } else {
        this.newProject.Name = this.newProjectName;
      }
      this.newProject.Description = '';
      this.newProject.Status = ProjectStatus.Active;
      await this.createProject();
    }
  }

  async createProject() {
    const invalidName =
      this.newProject.Name == undefined ||
      this.newProject.Name == null ||
      this.newProject.Name.length == 0;
    this.displayWarningName = invalidName;
    this.newProject.Status = ProjectStatus.Active;
    if (!invalidName) {

      this.svcProject
        .addProject(this.newProject)
        .toPromise()
        .then(
          async (data: Project) => {
            this.createNewProject = false;
            this.pssr.ProjectID = data.Id;
            this.lstProject.push(data);

            await this.changeProject();
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);

          }
        );
    }
  }

  async addFacility() {
    this.newFacility = new Facility();
    // validation
    if (this.lstFacility[0] != undefined && this.lstSubArea[0] != null) {
      this.newFacility.SubAreaId = this.pssr.SubAreaID;
      this.newFacility.Name = this.newFacilityName;
      const validFacility = this.lstFacility.find(
        (item) => item.Name === this.newFacility.Name
      );
      if (validFacility) {
        this.toastrService.showWarning(this.translatePipe.transform('The record entered already exists'));
      } else {
        await this.createFacility();
      }
    } else {
      this.newFacility.SubAreaId = this.pssr.SubAreaID;
      this.newFacility.Name = this.newFacilityName;
      await this.createFacility();
    }
  }

  async addSubArea() {
    this.newSubArea = new SubArea();
    this.newSubAreaAdded = false;
    // validation
    if (this.lstSubArea[0] != undefined && this.lstSubArea[0] != null) {
      this.newSubArea.AreaId = this.pssr.AreaID;
      this.newSubArea.Name = this.newSubAreaName;
      const validSubArea = this.lstSubArea.find(
        (item) => item.Name === this.newSubArea.Name
      );
      if (validSubArea) {
        this.toastrService.showError(this.translatePipe.transform('The record entered already exists'));
      } else {
        await this.createSubArea();
      }
    } else {
      this.newSubArea.AreaId = this.pssr.AreaID;
      this.newSubArea.Name = this.newSubAreaName;
      await this.createSubArea();
    }
  }

  async createFacility() {
    const invalidName =
      this.newFacility.Name == undefined ||
      this.newFacility.Name == null ||
      this.newFacility.Name.length == 0;
    this.displayWarningName = invalidName;
    if (!invalidName) {

      this.svcFacility
        .createFacility(this.newFacility)

        .toPromise()
        .then(
          async (data: Facility) => {
            await this.changeSubArea(data.Id);
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);

          }
        );
    }
  }

  async createSubArea() {
    const invalidName =
      this.newSubArea.Name == undefined ||
      this.newSubArea.Name == null ||
      this.newSubArea.Name.length == 0;
    this.displayWarningName = invalidName;
    if (!invalidName) {

      this.svcSubArea
        .createSubArea(this.newSubArea)

        .toPromise()
        .then(
          async (data: SubArea) => {
            await this.changeArea(data.Id);
            this.newSubAreaValidation = true;
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);
          }
        );
    }
  }

  btnCancelPSSR() {
    this._location.back();
  }

  btnCancelProject() {
    this.router.navigate(['/pssr-project']);
  }

  async onSubmitPSSR() {

    if (this.pssr.QAQCMilestoneId && this.pssr.QAQCWorkOrderId) {
      this.pssr.QAQCType = QAQCType.MilestoneWorkOrder;
    } else if (this.pssr.QAQCWorkOrderId) {
      this.pssr.QAQCType = QAQCType.WorkOrder;
    } else if (this.pssr.QAQCMilestoneId) {
      this.pssr.QAQCType = QAQCType.Milestone;
    } else {
      this.pssr.QAQCType = QAQCType.None;
    }

    if (this.pssr.SubAreaID == 0) {
      this.pssr.SubAreaID = null;
    }

    if (this.pssr.TaskPlanID  == 0) {
      this.pssr.TaskPlanID = null;
    }

    await this.svcPSSR
      .create(this.pssr)

      .toPromise()
      .then(
        (data) => {
          this.toastrService.showSuccess(this.translatePipe.transform('General Information Saved Successfully'));
          setTimeout(() => {
            if (this.projId > 0) {
              this.router.navigate([
                '/pssr-edit/',
                {
                  id: data.Id,
                  projectId: this.projId,
                  buId: this.selectedBU.Id,
                  orgId: this.orgId,
                },
              ]);
            } else {
              this.router.navigate(['/pssr-edit/', { id: data.Id }]);
            }
          }, 1000);
        },
        (error) => {
          this.toastrService.showError(error.error.ExceptionMessage);
        }
      );
  }

  loadPssrTypes() {
    this.pssrTypes = [];
    if (Utility.isValidListWithData(this.selectedBU.PssrTypes)) {
      this.selectedBU.PssrTypes.forEach((p: PSSRType) => {
        this.pssrTypes.push({ label: p.Name, value: p.Id });
        this.pssr.PssrTypeId =
          p.Name === 'PSSR' ? p.Id : this.pssrTypes[0].value;
      });
    }
  }

  // Methods

  async loadBusinessUnit() {

    await this.svcBusinessUnit
      .getUserBUs()
      .toPromise()
      .then(async (data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (
          this.lstBusinessUnit[0] != undefined &&
          this.lstBusinessUnit[0] != null &&
          this.pssr.BusinessUnitID == null
        ) {
          this.selectedBU =
            this.buId > 0
              ? this.lstBusinessUnit.find((x) => x.Id === this.buId)
              : this.lstBusinessUnit[0];
          this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
          this.pssr.BusinessUnitID = this.selectedBU.Id;
          await this.loadTaskPlan(this.selectedBU.Id);
          await this.loadOrganizations(this.selectedBU.Id);
          await this.loadArea(this.selectedBU.Id);
          if (
            this.selectedBU.ShowProject &&
            !this.selectedBU.ShowProjectInput
          ) {
            await this.loadProject();
            await this.loadProjectQAQCFunctions();
          }
          if (this.selectedBU.ShowQAQC) {
            await this.loadQAQCEvent();
          }

          this.buId = null; // -- reset after used
          this.loadPssrTypes();
        } else {
          this.lstOrgs = [];
          this.lstArea = [];
          this.lstSubArea = [];
          this.lstFacility = [];
          this.lstProject = [];
          this.lstqaqcMilestone = [];
          this.lstqaqcMilestoneWO = [];
          this.lstqaqcWO = [];
        }


      });
  }

  async loadProject() {
    await this.svcProject
      .getByOrgId(this.pssr.OrganizationID)
      .toPromise()
      .then(async (data: Project[]) => {
        this.lstProject = Utility.isValidListWithData(data)
          ? data
            .filter((p) => p.Status === ProjectStatus.Active && !p.IsDeleted)
            .sort((a, b) => a.Name.localeCompare(b.Name))
          : [];
      });
  }

  async loadTaskPlan(buId: number) {
    await this.svcTaskPlan
      .getTaskPlans(buId)
      .toPromise()
      .then((data: TaskPlan[]) => {
        this.lstTaskPlan = data.filter(
          (t) => t.TaskPlanType === TaskPlanType.PSSR && !t.IsDeleted
        );
        if (Utility.isValidListWithData(this.lstTaskPlan)) {
          if (this.taskPlanId != null && this.taskPlanId != 0) {
            this.pssr.TaskPlanID = +this.taskPlanId;
          } else {
            this.pssr.TaskPlanID = this.lstTaskPlan[0].ID;
          }
        }
      });
  }

  async loadOrganizations(buId: number) {
    await this.svcOrganization
      .getListByBU(buId)
      .toPromise()
      .then(async (data: Organization[]) => {
        this.lstOrgs = data;

        if (Utility.isValidObj(this.lstOrgs[0]) && this.pssr.OrganizationID == null) {
          const org = this.orgId > 0
            ? this.lstOrgs.find((x) => x.Id === this.orgId)
            : this.lstOrgs[0];

          this.orgName = org.Name;
          this.pssr.OrganizationID = org.Id;
          this.orgId = null; // -- reset after used
        }
      });
  }

  async loadArea(buId: number) {
    this.newSubAreaName = null;
    this.createNewSubArea = false;
    await this.svcArea
      .getAllByBusinessUnit(buId)
      .toPromise()
      .then(async (data: Area[]) => {
        this.lstArea = data
          .filter((x) => x.IsActive == true)
          .sort((a, b) => a.Name.localeCompare(b.Name));
        if (
          this.lstArea[0] != undefined &&
          this.lstArea[0] != null &&
          this.pssr.AreaID == null
        ) {
          if (this.areaId != null) {
            this.pssr.AreaID = +this.areaId;
          } else {
            this.pssr.AreaID = this.lstArea[0].Id;
          }
          if (this.pssr.AreaID > 0) {
            await this.loadSubArea(this.pssr.AreaID);
          }
        } else {
          this.lstSubArea = [];
          this.lstFacility = [];
        }
      });
  }

  async loadSubArea(areaId: number) {
    await this.svcSubArea
      .getAllByAreaId(areaId)
      .toPromise()
      .then(async (data: SubArea[]) => {
        this.lstSubArea = data
          .filter((x) => x.IsActive == true)
          .sort((a, b) => a.Name.localeCompare(b.Name));
        if (
          this.lstSubArea[0] != undefined &&
          this.lstSubArea[0] != null &&
          this.pssr.SubAreaID == null
        ) {
          this.displayFacility = true;

          if (this.subAreaId != null) {
            this.pssr.SubAreaID = +this.subAreaId;
          } else {
            this.pssr.SubAreaID = this.lstSubArea[0].Id;
          }
          if (this.pssr.SubAreaID > 0) {
            await this.loadFacility(this.pssr.SubAreaID);
          }
        } else if (
          this.lstSubArea[0] != undefined &&
          this.lstSubArea[0] != null &&
          this.pssr.SubAreaID != null
        ) {
          this.displayFacility = true;
          if (this.pssr.SubAreaID > 0) {
            await this.loadFacility(this.pssr.SubAreaID);
          }
        } else {
          this.lstFacility = [];
          this.displayFacility = false;
          this.allowSubAreaInput = true;
          this.newSubAreaValidation = false;
        }
      });
  }

  async loadFacility(subAreaId: number) {
    await this.svcFacility
      .getAllBySubArea(subAreaId)
      .toPromise()
      .then((data: Facility[]) => {
        this.lstFacility = data
          .filter((x) => x.IsActive == true)
          .sort((a, b) => a.Name.localeCompare(b.Name));
        if (
          this.lstFacility[0] != undefined &&
          this.lstSubArea[0] != null &&
          this.pssr.FacilityID == null
        ) {
          if (this.facilityId != null) {
            this.pssr.FacilityID = +this.facilityId;
          } else {
            this.pssr.FacilityID = this.lstFacility[0].Id;
          }
        }
      });
  }

  private async loadProjectQAQCFunctions() {
    if (this.projId > 0) {
      this.pssr.ProjectID = this.projId;
      this.pssr.IncludeProjectTeam = true;
      await this.loadQAQCMilestone();
      await this.loadQAQCWO();
    } else {
      this.pssr.ProjectID = null;
      this.lstqaqcMilestone = [];
      this.lstqaqcMilestoneWO = [];
      this.lstqaqcWO = [];
    }
  }

  isNotAllowedToSave(isValid) {
    return (!isValid || !this.newSubAreaValidation || this.createNewProject);
  }

}
