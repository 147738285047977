import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { EndDevice } from '#models/end-device';
import { EndDeviceTemplate } from '../../models/end-device-template';
import { ResultModel } from '../../models/result-model';

@Injectable()
export class EndDeviceTemplateService {
    constructor(
        private http: HttpClient
    ) { }

    public getAllByEquipTemplate(equipTemplateId: Number): Observable<EndDevice[]> {
        return this.http.get<EndDevice[]>(`${environment.apiEndpoint}/end-device-template/getByEquipmentTemplate/${equipTemplateId}`);
    }

    public getEndDeviceTemplateById(id: number): Observable<EndDevice> {
        return this.http.get<EndDevice>(`${environment.apiEndpoint}/end-device-template/${id}`);
    }

    public create(endDeviceTemplate: EndDevice): Observable<EndDevice> {
        return this.http.post<EndDevice>(`${environment.apiEndpoint}/end-device-template`, endDeviceTemplate);
    }

    public update(endDeviceTemplate: EndDevice): Observable<EndDevice> {
        return this.http.put<EndDevice>(`${environment.apiEndpoint}/end-device-template`, endDeviceTemplate);
    }

    public delete(id: number): Observable<EndDevice> {
        return this.http.put<EndDevice>(`${environment.apiEndpoint}/end-device-template/delete/${id}`, null);
    }

    public getAllTemplateByEquipTemplate(equipTemplateId: Number): Observable<EndDeviceTemplate[]> {
        return this.http.get<EndDeviceTemplate[]>
                    (`${environment.apiEndpoint}/end-device-template/getByEquipmentTemplate/${equipTemplateId}`);
    }

    public updateTemplate(endDeviceTemplate: EndDeviceTemplate): Observable<EndDeviceTemplate> {
        return this.http.put<EndDeviceTemplate>(`${environment.apiEndpoint}/end-device-template`, endDeviceTemplate);
    }

    public createTemplate(endDeviceTemplate: EndDeviceTemplate): Observable<EndDeviceTemplate> {
        return this.http.post<EndDeviceTemplate>(`${environment.apiEndpoint}/end-device-template`, endDeviceTemplate);
    }

    public getEndDeviceRealTemplateById(id: number): Observable<EndDeviceTemplate> {
        return this.http.get<EndDeviceTemplate>(`${environment.apiEndpoint}/end-device-template/${id}`);
    }

    moveEndDevUp(endDevId: number): Observable<ResultModel> {
        return this.http.put<ResultModel>(`${environment.apiEndpoint}/end-device-template/moveEndDevUp/${endDevId}`, null);
    }

    moveEndDevDown(endDevId: number): Observable<ResultModel> {
        return this.http.put<ResultModel>(`${environment.apiEndpoint}/end-device-template/moveEndDevDown/${endDevId}`, null);
    }
}

