import { Component, OnInit } from '@angular/core';
import { Organization } from '#models/organization';
import { OrganizationService } from '#services/api/organization.service';

import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '#services/shared/utility';
import { BusinessUnit } from '#models/business-unit';
import { ToastrService } from '#services/shared/toastr.service';



@Component({
  selector: 'app-organization-edit',
  templateUrl: './organization-edit.component.html',
})
export class OrganizationEditComponent implements OnInit {
  organization: Organization = new Organization();
  orgId: number;
  selectedBU: Partial<BusinessUnit>;



  constructor(
    private toastrService: ToastrService,
    private organizationSvc: OrganizationService,
    private router: Router,
    private route: ActivatedRoute,

  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.orgId = +v['id'];
    });
    this.getOrganizationById(this.orgId);
  }

  getOrganizationById(id: number) {

    this.organizationSvc
      .getOrganization(id)
      .toPromise()
      .then(
        (data: Organization) => {

          this.organization = data;

          this.selectedBU = {
            Id: data.BusinessUnitId,
            Name: data.BusinessUnitName,
          };
        },
        (error) => {
          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }

  save() {
    if (Utility.isValidString(this.organization.Name)) {

      this.organizationSvc
        .updateOrganization(this.organization)

        .toPromise()
        .then(
          () => {
            this.onRedirectButtonClick();
          },
          (error) => {
            this.toastrService.showError( error.error.ExceptionMessage);
          }
        );
    }
  }

  delete() {

    this.organizationSvc
      .deleteOrganization(this.organization.Id)

      .toPromise()
      .then(
        () => {
          this.onRedirectButtonClick();
        },
        (error) => {
          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/organizations']);
  }
}
