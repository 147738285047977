import { AppVersionStatus } from './enum/system-version-status';

export class MobileAppVersion {
    ID: number;
    Number: string;
    Description: string;
    ReleaseDate: Date;
    Status: AppVersionStatus;
    IsDisabled: boolean;
}
