<div class="box-shadow module">
  <div class="row">
    <div class="row">
      <h3 class="text-left col-sm-9" style="margin-bottom: 0px">
        {{ "PARTICIPANTS" | translate }}<br />
        <small>{{ "Start adding participants" | translate }}</small>
      </h3>
    </div>
    <hr />

    <form #pssrForm="ngForm">


      <div class="row">
        <div *ngFor="let r of roles">
          <div class="col-xs-3">
            <p-card>
              <table>
                <tr>
                  <td>
                    <button
                      type="button"
                      [disabled]="r.Disabled"
                      class="btn btn-sm btn-block btn-primary"
                      style="white-space: pre-wrap !important"
                      (click)="openAddParticipant(r)"
                    >
                      {{ "Add" | translate }}
                    </button>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <label>{{ r.RoleLabel | translate: bu }}</label>
                  </td>
                </tr>
              </table>
            </p-card>
            <br />
            <!-- participant label -->
            <div *ngFor="let u of pssr.PSSRUsers.Users">
              <div *ngIf="u.Role == r.RoleId">
                <div
                  class="ui-card ui-widget ui-widget-content ui-corner-all"
                  [class.participated]="u.Participated"
                >
                  <div class="ui-card-body">
                    <div class="ui-card-content">
                      <div class="row">
                        <div class="col-xs-8" style="padding: 0 0 0 0">
                          <p>{{ u.UserName }}</p>
                          <div style="font-size: 0.8em">
                            <em>{{ u.UserEmail }}</em
                            ><br />
                            <em>{{ u.UserCAI }}</em>
                          </div>
                        </div>
                        <div class="col-xs-4">
                          <button
                            type="button"
                            class="btn btn-primary"
                            style="white-space: pre-wrap !important"
                            (click)="openEditParticipant(u)"
                          >
                            {{ "Edit" | translate }}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <p-dialog
          [(visible)]="displayAddModal"
          showEffect="fade"
          [modal]="true"
        >
          <p-header>
            {{ "Select user for" | translate }}: {{ selectedRole }}
            {{ "Role" | translate }}
          </p-header>
          <input
            *ngIf="!displayChev"
            type="radio"
            id="lookup"
            class="dialog-radio"
            name="ai-assign"
            [value]="2"
            [(ngModel)]="participantType"
            [disabled]="displayChev"
          />

          <label *ngIf="!displayChev" for="lookup"
            >{{'Chevron Directory'|translate}}&nbsp; &nbsp;</label
          >
          <input
            *ngIf="!displayENonChev"
            type="radio"
            id="chevron"
            class="dialog-radio"
            name="ai-assign"
            [value]="1"
            [(ngModel)]="participantType"
            [disabled]="displayENonChev"
          />

          <label *ngIf="!displayENonChev" for="chevron"
            >{{'ExistingNonChevronShared'|translate}}&nbsp; &nbsp;</label
          >
          <input
            *ngIf="!displayNNonChev"
            type="radio"
            id="nonChevron"
            class="dialog-radio"
            name="ai-assing"
            [value]="3"
            [(ngModel)]="participantType"
            [disabled]="displayNNonChev"
          />
          <label *ngIf="!displayNNonChev" for="nonChevron"
            >{{'NewNonChevron'|translate}}</label
          >
          <div *ngIf="participantType === ParticipantType.NonChevronUser">
            <select
              class="form-control"
              [(ngModel)]="participantId"
              name="newParticipant"
              #newParticipant="ngModel"
              (change)="addNonChevronUserById(participantId)"
            >
              <option [ngValue]="null"></option>
              <option *ngFor="let au of nonChevronUsers" [ngValue]="au.ID">
                {{ au | fullName }}
                <ng-container *ngIf="au.Email">({{ au.Email }})</ng-container>
              </option>
            </select>
          </div>
          <div *ngIf="participantType === ParticipantType.ChevronOrSharedUser">
            <p-autoComplete
              [(ngModel)]="selectedUser"
              [suggestions]="participants"
              (completeMethod)="suggestParticipants($event)"
              name="usr"
              required
              #usr="ngModel"
              field="FullName"
              delay="1000"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              placeholder="type chevron user cai, name or email..."
              [minLength]="1"
              (onSelect)="addChevronUser(selectedUser)"
            >
            </p-autoComplete>
          </div>
          <input
            *ngIf="participantType === ParticipantType.External"
            type="text"
            class="form-control"
            [(ngModel)]="otherParticipant"
            (change)="addExternalUser(otherParticipant)"
            name="otherP"
            #otherP="ngModel"
          />
          <br />
          <br />
          <ng-container *ngIf="allowMulti && participantType === ParticipantType.ChevronOrSharedUser">
            <div *ngFor="let item of possibleSelectedUser">
              <p>
                {{ item | fullName }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="allowMulti && participantType === ParticipantType.NonChevronUser">
            <div *ngFor="let item of possibleParticipantId">
              <p>
                {{ item | fullName }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="allowMulti && participantType === ParticipantType.External">
            <div *ngFor="let item of possibleOtherParticipant">
              <p>
                {{ item }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <p-footer class="row">
            <div class="col-xs-9" style="text-align: left">
              {{ maxMessage }}
            </div>
            <button
              class="col-xs-3"
              type="button"
              [disabled]="disableAdd"
              pButton
              [label]="'Add' | translate"
              (click)="addParticipant()"
              style="width: 80px; white-space: pre-wrap !important"
            ></button>
          </p-footer>
        </p-dialog>

        <p-dialog
          [(visible)]="displayEditModal"
          showEffect="fade"
          [modal]="true"
        >
          <p-header> {{'EditParticipant'|translate}} </p-header>
          <div>
            {{ participantToEdit?.UserName }}
          </div>
          <div class="row" *ngIf="participantToEdit">
            <label>{{ "Role" | translate }} :</label>
            <select
              class="form-control"
              [(ngModel)]="participantToEdit.Role"
              name="newRole"
              #newRole="ngModel"
            >
              <ng-container *ngFor="let r of roles">
                <option
                  *ngIf="
                    r.RoleId !== 4 ||
                    (r.RoleId === 4 &&
                      (participantToEdit.Role === 4 ||
                        showFinalRole ||
                        bu.AllowMultiApprover))
                  "
                  [ngValue]="r.RoleId"
                >
                  {{ r.RoleName | translate: bu }}
                </option>
              </ng-container>
            </select>
          </div>

          <p-footer class="row">
            <button
              type="button"
              pButton
              [label]="'Delete' | translate"
              (click)="removeParticipant(participantToEdit)"
              style="
                width: 80px;
                white-space: pre-wrap !important;
                background-color: #c9302c;
                border-color: #ac2925;
              "
            ></button>
            <button
              type="button"
              pButton
              [label]="'Save' | translate"
              (click)="editParticipant()"
              style="width: 80px; white-space: pre-wrap !important"
            ></button>
          </p-footer>
        </p-dialog>
      </div>
    </form>

    <div class="row">
      <h3 class="text-left col-sm-9" style="margin-bottom: 0px">
        {{ "SECTIONS" | translate }}<br />
        <small>{{'QAQCAddSectionLeadToPssr'|translate}}</small>
      </h3>
    </div>
    <hr />
    <div class="row" *ngIf="pssrTaskPlanSection.length > 0">
      <div *ngFor="let sec of pssrTaskPlanSection">
        <div class="row" *ngIf="sec.SectionType == ParticipantType.NonChevronUser">
          <div class="text-left col-md-3">
            {{ sec.Description }}
          </div>

          <div class="col-md-9 section-users-header">
            <div *ngFor="let user of sec.Users" class="section-user-name">
              <button
                type="button"
                class="close"
                (click)="removeSectionUser(sec, user.UserEmail)"
              >
                <span aria-hidden="true">&times;</span>
              </button>
              {{ user.UserName }}
            </div>
            <div>
              <p-dropdown
                [options]="members"
                [(ngModel)]="sectionSelectedUser"
                [styleClass]="'text-left'"
                (ngModelChange)="addSectionUser(sec.ID)"
                optionLabel="UserName"
                placeholder="{{ 'Select Lead' | translate }}"
                appendTo="body"
              >
              </p-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog
  [(visible)]="displayImage"
  showEffect="fade"
  [modal]="true"
  (onAfterHide)="btnCollapseImage()"
>
  <p-header></p-header>
  <div style="text-align: center">
    <img style="width: 90%" src="{{ imagePhoto }}" />
  </div>
  <p-footer></p-footer>
</p-dialog>
