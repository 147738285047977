import { BusinessUnit, Organization, ProjectStatus } from '.';

export interface ProjectFilter {
  organizationID: number;
  statuses: ProjectStatus[];
  minRangeDate?: Date;
  maxRangeDate?: Date;
  isDeleted: boolean;
}

export class FilterProject {
  deletedProjectsRangeDate: Date[];
  searchDeletedProjects: boolean;
  searchText: string;
  selectedStatus: ProjectStatus[];
  selectedOrg: Organization;
  selectedBU: BusinessUnit;
}
