import { environment } from '#environments/environment';
import { TranslationRequest } from '#models/translation-request';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationRequestService {
  constructor(private http: HttpClient) {}

  getTranslationRequestsByPssrId(
    pssrId: number
  ): Observable<TranslationRequest[]> {
    return this.http.get<TranslationRequest[]>(
      `${environment.apiEndpoint}/pssr/${pssrId}/translationrequest`
    );
  }

  requestTranslation(payload: TranslationRequest): Observable<TranslationRequest> {
    return this.http.post<TranslationRequest>(
      `${environment.apiEndpoint}/translationrequest`,
      payload
    );
  }

  getTranslationRequest(requestId: number): Observable<TranslationRequest> {
    return this.http.get<TranslationRequest>(
      `${environment.apiEndpoint}/translationrequest/${requestId}`
    );
  }

  switchTranslator(requestId: number, newTranslatorEmail: string) {
    return this.http.put<TranslationRequest>(
      `${environment.apiEndpoint}/translationrequest/${requestId}`,
      {
        TranslatorEmail: newTranslatorEmail,
      }
    );
  }
  completeTranslation(requestId: number): Observable<TranslationRequest> {
    return this.http.put<TranslationRequest>(
      `${environment.apiEndpoint}/translationrequest/${requestId}/completion`,
      {
        IsCompleted: true,
      }
    );
  }
}
