import { ABChecklistResponse } from '../ab-checklist-response';

export class SectionUserList {
  SectionUsers: SectionUser[] = [];
}
export class SectionUser {
    Id: number;
    PSSRID: number;
    SectionID: number;
    UserID: number;
    UserName: string;
    SectionDescription: string;
    UserEmail: string;
    TaskPlanID: number;
    ParentUsers: string[];
}

export class SectionABCheck {
    Id: number;
    Name: string;
    SubSectionList: SubSectionABCheck[];
    EquipmentId: number;
    EndDeviceId: number;
    SectionTotal = 0;
    SectionActual = 0;
    SectionStatus = false;
}

export class SubSectionABCheck {
    Id: number;
    Name: string;
    ResponseList: ABChecklistResponse[];
    EquipmentId: number;
    SubSectionTotal = 0;
    SubSectionActual = 0;
    SubSectionStatus = false;
}
