<div class="box-shadow module">
  <div class="row">
    <h2>
      Copy to: <strong>{{ project?.Name }}</strong>
    </h2>
  </div>
  <hr />
  <div class="row">
    <h3>{{ "Projects List" | translate }}</h3>
  </div>
  <div class="row component-separator" *ngIf="projectsList?.length">
    <div class="row" *ngFor="let project of projectsList">
      <div class="row bg-info">
        <div class="col-xs-11">
          <p-radioButton
            name="group1"
            [label]="project?.Name"
            inputId="project?.Id"
            (onClick)="selectProject(project)"
          ></p-radioButton>
        </div>
      </div>
      <div class="row">
        <table class="table table-hover table-striped table-condensed">
          <thead>
            <tr>
              <td></td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>

  <div class="row component-separator">
    <div class="col-xs-1">
      <button
        type="button"
        class="btn btn-success"
        [disabled]="!selectedProject?.Id"
        (click)="copyEquipmentsProject()"
      >
        Copy Equipments to Project
      </button>
    </div>
  </div>
  <hr />
  <div class="row component-separator">
    <button type="button" class="btn btn-default" (click)="backProjectDetail()">
      Back to Project Detail
    </button>
    &nbsp;
  </div>
</div>
