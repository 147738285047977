import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitStorageService } from '#services/shared/business-unit-storage.service';
import { Directive, Input } from '@angular/core';

@Directive({
  selector: '[appBUSwitcher]',
})
export class BusinessUnitSwitcherDirective {
  constructor(private businessUnitStorageService: BusinessUnitStorageService) {}

  @Input('appBUSwitcher')
  set switchLanguage(args: BusinessUnit) {
    if (!args) {
      return;
    }

    this.businessUnitStorageService.setCurrentBusinessUnit(args);
  }
}
