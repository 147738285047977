import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { ReadSubCategoryDTO, SubCategory } from '#models/subcategory';
import { Injectable } from '@angular/core';

@Injectable()
export class SubCategoryService {
  url: string;

  constructor(private http: HttpClient) {
    this.url = `${environment.apiEndpoint}/subcategory`;
  }

  public get(): Observable<ReadSubCategoryDTO[]> {
    return this.http.get<ReadSubCategoryDTO[]>(this.url).pipe(share());
  }

  public getById(id: number): Observable<ReadSubCategoryDTO> {
    return this.http.get<ReadSubCategoryDTO>(`${this.url}/${id}`).pipe(share());
  }

  public post(category: SubCategory) {
    return this.http.post<SubCategory>(this.url, category).pipe(share());
  }

  public put(category: SubCategory) {
    return this.http.put<SubCategory>(this.url, category).pipe(share());
  }

  public delete(categoryID: number) {
    return this.http.delete(`${this.url}/${categoryID}`).pipe(share());
  }
}
