import { Component, OnInit } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';

import {
  BusinessUnit,
  EquipmentTemplate,
  EquipmentTempGroup,
  EquipmentTempGroupConfig,
} from '#models/index';

import { BusinessUnitsService } from '../../../services/api/business-unit.service';
import { EquipmentTemplateService } from '../../../services/api/equipment-template.service';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-equipment-temp-group',
  templateUrl: './equipment-temp-group.component.html',
  styleUrls: ['./equipment-temp-group.component.scss'],
})
export class EquipmentTempGroupComponent implements OnInit {
  //#region VARIABLES

  editMode: boolean;

  selectedBU: BusinessUnit;
  businessUnitList: BusinessUnit[];

  equipGroupConfigList: EquipmentTempGroupConfig[];
  equipGroupList: EquipmentTempGroup[];
  equipGroup: EquipmentTempGroup;
  selectedEquipTempGroup: EquipmentTempGroup;

  equipTempList: EquipmentTemplate[];
  equipTempListPag: EquipmentTemplate[];
  selectedEquipTemp: EquipmentTemplate[];

  groupEdit = false;
  showGroupForm = false;
  showEquipTempPanel = false;
  allTempSelected = false;

  //#endregion

  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private toastrService: ToastrService,
    private equipTempService: EquipmentTemplateService
  ) {}

  ngOnInit() {
    this.loadBusinessUnits();
  }

  loadBusinessUnits(): void {
    this.businessUnitsSvc
      .getUserBUs()

      .subscribe((data: BusinessUnit[]) => {
        this.businessUnitList = data;
        if (this.businessUnitList.length > 0) {
          this.selectedBU = this.businessUnitList[0];
          this.loadEquipGroupByBU();
        }
      });
  }

  loadEquipGroupByBU() {
    if (this.selectedBU) {
      this.equipTempService
        .getEquipTempGroupByBU(this.selectedBU.Id)
        .subscribe((groups) => {
          this.equipGroupList = groups;
        });
    }
  }

  loadEquipmentsByBU(equipGroupSelect: EquipmentTempGroup) {
    this.equipGroupConfigList = [];
    this.selectedEquipTemp = [];
    this.selectEquipGroup(equipGroupSelect);
    const equipTemp = this.equipTempService.getEquipmentTemplateByBU(
      this.selectedBU.Id
    );

    equipTemp.subscribe(
      (temps) => {
        this.equipTempList = temps;
        this.changeEquipTempPanelState();
        this.getEquipTempByGroupSelected();
      },
      (err) => {
        this.handleError(err, 'Error loading Equipment Templates: ');
      }
    );
  }

  addEditEquipGroup(equipGroupSelect?: EquipmentTempGroup) {
    this.selectEquipGroup(equipGroupSelect);
    this.showGroupForm = !this.showGroupForm;
  }

  selectEquipGroup(equipGroupSelect?: EquipmentTempGroup) {
    if (equipGroupSelect) {
      this.equipGroup = equipGroupSelect;
      this.groupEdit = true;
    } else {
      this.equipGroup = new EquipmentTempGroup();
      this.groupEdit = false;
    }
  }

  onSubmitGroup() {
    if (this.groupEdit) {
      // Edit
      const group = this.equipTempService.updateEquipTempGroup(this.equipGroup);
      group.subscribe(
        (equipGroup) => {
          const idx = this.equipGroupList.findIndex(
            (x) => x.Id === this.equipGroup.Id
          );
          this.equipGroupList.splice(idx, 1, equipGroup);
          this.finalizeRequest();
        },
        (err) => {
          this.handleError(
            err,
            `Error saving Equipment Template Group ${this.equipGroup.Name}`
          );
        }
      );
    } else {
      // Create
      this.equipGroup.BusinessUnitId = this.selectedBU.Id;
      const group = this.equipTempService.createEquipTempGroup(this.equipGroup);
      group.subscribe(
        (equipGroup) => {
          this.equipGroupList.push(equipGroup);
          this.finalizeRequest();
        },
        (err) => {
          this.handleError(
            err,
            `Error saving Equipment Template Group ${this.equipGroup.Name}`
          );
        }
      );
    }
  }

  private finalizeRequest() {
    this.groupEdit = !this.groupEdit;
    this.changeGroupPanelState();
    this.showMessage('Information Saved Successfully', 0);
  }

  changeGroupPanelState() {
    this.showGroupForm = !this.showGroupForm;
  }

  changeEquipTempPanelState() {
    this.showEquipTempPanel = !this.showEquipTempPanel;
  }

  selectEquipTempGroup(equipTempGroup: EquipmentTempGroup) {
    this.selectedEquipTempGroup = equipTempGroup;
  }

  getEquipTempByGroupSelected() {
    if (this.equipGroup) {
      const groups = this.equipTempService.getEquipTempGroupConfigByGroup(
        this.equipGroup.Id
      );
      groups.subscribe(
        (equipTempGropConfig) => {
          this.equipGroupConfigList = [];
          this.equipGroupConfigList = equipTempGropConfig;
          this.activateTemplatesSaved();
        },
        () => {
          this.showMessage(
            `Error loading Current ${this.equipGroup.Name} Group Templates`,
            1
          );
        }
      );
    }
  }

  private activateTemplatesSaved() {
    if (this.equipGroupConfigList.length) {
      this.selectedEquipTemp = [];
      this.equipGroupConfigList.forEach((config) => {
        if (this.equipTempList.length) {
          this.selectedEquipTemp.push(
            this.equipTempList.find((x) => x.Id === config.EquipmentTemplateId)
          );
        }
      });
    }
  }

  saveEquipTemplSelected() {
    if (this.selectedEquipTemp.length) {
      this.equipGroupConfigList = [];
      this.selectedEquipTemp.forEach((equipTemp) => {
        const newEquipConfig = new EquipmentTempGroupConfig();
        newEquipConfig.EquipmentTemplateId = equipTemp.Id;
        newEquipConfig.EquipmentTempGroupId = this.equipGroup.Id;
        this.equipGroupConfigList.push(newEquipConfig);
      });

      const groups = this.equipTempService.createGroupConfig(
        this.equipGroupConfigList
      );
      groups.subscribe(
        (newGroupConfig) => {
          this.equipGroupConfigList = [];
          this.equipGroupConfigList = newGroupConfig;
          this.showMessage('Information Saved Successfully', 0);
        },
        (err) => {
          this.showMessage(err, 1);
        }
      );
    }
  }

  selectAllTemplates() {
    if (!this.allTempSelected) {
      this.selectedEquipTemp = this.equipTempList.copyWithin(
        this.equipTempList.length,
        0
      );
    } else {
      this.selectedEquipTemp = [];
    }
    this.allTempSelected = !this.allTempSelected;
  }

  deleteEquipGroup() {
    if (this.selectedEquipTempGroup) {
      // Update all Existing Equipment associated with this Group to any or 0
      this.equipTempService
        .updateEquipmentByDeleted(this.selectedEquipTempGroup)
        .pipe(
          finalize(() => {
            this.deleteEquipTempGroup();
          }),
          tap(
            () => {
              // -
            },
            (err) => {
              this.showMessage(err, 1);
            }
          )
        );
    }
  }

  private deleteEquipTempGroup() {
    // Delete selected EquipmentTempGroup
    const deleteGroup = this.equipTempService
      .deleteEquipTempGroup(this.selectedEquipTempGroup.Id)
      .pipe(
        finalize(() => {
          this.loadEquipGroupByBU();
        })
      );
    deleteGroup.subscribe(
      () => {
        this.showMessage('Equipment Template Group deleted successfully', 0);
      },
      (err) => {
        this.showMessage(err, 1);
      }
    );
  }

  paginate(event) {
    this.setTemplateItemPagination(event.first, event.rows);
  }

  private setTemplateItemPagination(indx: number, items: number) {
    if (this.equipTempList.length) {
      this.equipTempListPag = [];
      this.equipTempListPag = this.equipTempList.copyWithin(items, indx);
    }
  }

  private handleError(err: any, msg: string) {
    if (err.status === 500) {
      this.showMessage(
        'The Equipment Template Group' +
          this.equipGroup.Name +
          ' already exist in the database.',
        1
      );
    } else {
      this.showMessage(msg + err.message, 1);
    }
  }

  showMessage(info: string, dv: number) {
    if (dv === 1) {
      this.toastrService.showError(info);
    } else {
      this.toastrService.showSuccess(info);

    }
  }
}
