import { Component, OnInit } from '@angular/core';
import { UserService, BusinessUnitsService } from '#services/api';
import { Utility } from '#services/shared/utility';
import { BusinessUnit } from '#models/business-unit';
import { User } from '#models/user';
import { AuthService } from '#services/shared';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';


@Component({
  selector: 'app-not-registered',
  templateUrl: './not-registered.component.html',
  providers: [TranslatePipe],
})
export class NotRegisteredComponent implements OnInit {
  inProgress: boolean;
  lstBusinessUnit: BusinessUnit[];
  selectedBU: BusinessUnit = null;
  user: User = null;
  fullName: string;
  errorMsg: string;
  promptConfirmation = false;
  email: string;

  constructor(
    private toastrService: ToastrService,
    private userService: UserService,
    private buService: BusinessUnitsService,

    private authService: AuthService,
    private translatePipe: TranslatePipe
  ) {}

  ngOnInit() {
    const localUser: User = JSON.parse(localStorage.getItem('localUserReg'));
    this.user =
      Utility.isValidObj(localUser) && localUser.ID > 0 ? localUser : null;


    this.fullName = !!this.user
      ? Utility.getFullName(this.user)
      : this.authService.getFullName();

    this.email = this.authService.getEmail();

    this.inProgress =
      Utility.isValidObj(this.user) && !Utility.isTrue(this.user.HasAccess);

    this.loadBusinessUnits();
  }

  async loadBusinessUnits() {
    await this.buService
      .getAll()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
      });
  }

  validateInput() {
    if (this.selectedBU === null) {
      return this.translatePipe.transform('BU is required');
    }
  }

  setErrorMessage(message: string): void {
    this.toastrService.showError(  message);
  }



  confirmRequest() {
    const hasInputError = this.validateInput();

    if (hasInputError) {
      this.setErrorMessage(hasInputError);
      return;
    }

    this.promptConfirmation = true;
  }

  dismiss() {
    this.promptConfirmation = false;
  }

  async sendRequest() {
    this.promptConfirmation = false;

    await this.userService
      .sendAccessRequest(this.email, this.selectedBU.Id)
      .toPromise()
      .then(
        (data: User) => {
          this.user = data;

          localStorage.removeItem('localUserReg');
          localStorage.setItem('localUserReg', JSON.stringify(data));
          this.inProgress = true;
          if (this.user.HasAccess) {
            window.location.reload();
          }
        },
        (error) => {
          this.setErrorMessage(error.error.ExceptionMessage);
        }
      );
  }
}
