import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '#services/shared';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private authSvc: AuthService, private router: Router) { }

  ngOnInit() {
    if (this.authSvc.isAuthorized()) {
      this.router.navigate(['']);
    } else {
      this.router.navigate(['b2c']);
      // this.authSvc.login();
    }
  }
}
