import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BusinessUnitsService, CategoryService } from '#services/api';
import { Router, ActivatedRoute } from '@angular/router';
import { Category } from '#models/category';
import { take } from 'rxjs/operators';
import { SubcategoryDialogComponent } from '../subcategory-dialog/subcategory-dialog.component';
import { BusinessUnit } from '#models/business-unit';
import { Subscription } from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-category-edit',
  templateUrl: './category-edit.component.html',
})
export class CategoryEditComponent implements OnInit, OnDestroy {

  constructor(
    private categorySvc: CategoryService,

    private router: Router,
    private route: ActivatedRoute,
    private businessUnitProvider: BusinessUnitsService
  ) {}
  category: Category;
  categoryID: number;
  businessUnitID: number;
  loadingCompleted: boolean;


  subscription = new Subscription();

  @ViewChild('dialog') dialog: SubcategoryDialogComponent;

  selectedBU: BusinessUnit;

  ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe((params) => {
        this.categoryID = +params['categoryId'];
        this.businessUnitID = +params['buId'];

        this.loadCategory(this.categoryID);
      })
    );
  }

  updateCategory() {

    const observable = this.categorySvc.put(this.category).pipe(
      take(1),

    );

    this.subscription.add(
      observable.subscribe(() => {
        this.router.navigate([
          'admin/category',
          { buId: this.category.BusinessUnitID },
        ]);
      })
    );
  }

  showDialog() {
    this.dialog.show();
  }

  private async loadCategory(categoryID: number) {
    try {
      this.loadingCompleted = false;


      this.category = await this.categorySvc.getByID(categoryID).toPromise();

      this.selectedBU = await this.businessUnitProvider
        .getBusinessUnitById(this.category.BusinessUnitID)
        .toPromise();
    } finally {
      this.loadingCompleted = true;


    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
