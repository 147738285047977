import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Language } from '../../../services/translate/languages';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'translateIn',
  pure: true,
})
export class TranslateInPipe implements PipeTransform {
  constructor(
    private translateService: TranslateService
  ) {}

  transform(
    key: string,
    lang: Language,
    interpolationParams: any = {}
  ): Observable<string> {
    return this.translateService
      .getTranslation(lang)
      .pipe(
        map((translations) =>
          this.translateService.getParsedResult(
            translations,
            key,
            interpolationParams
          )
        )
      );
  }
}
