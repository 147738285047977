
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingIndicatorService } from '#services/shared';

@Injectable()
export class HttpLoadingInterceptor implements HttpInterceptor {
  private totalRequests = 0;

  constructor(private loadingIndicatorService: LoadingIndicatorService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.totalRequests += 1;
    this.loadingIndicatorService.show();

    return next.handle(req).pipe(
      finalize(() => {
        this.totalRequests -= 1;

        if (this.totalRequests === 0) {
          this.loadingIndicatorService.hide();
        }
      })
    );
  }
}
