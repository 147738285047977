import { UserRole } from './enum/user-role';

export class PSSRUser {
    Id: number;
    UserID: number;
    UserName: string;
    UserCAI: string;
    UserEmail: string;
    PSSRID: number;
    Role: number = UserRole.None;
    RoleName: string;
    CanEdit = false;
    IsOrgAdmin = false;
    CanAddItem = false;
    CanEditAction = false;
    Participated = false;
    SignatureUrl: string;
    SignatureGUID: string;
    SignatureDate?: Date = null;
    GUID: string;
    SignatureImage: string;
    IsBusinessUnitAdmin = false;
    CanTranslate: boolean;
    ParentUsers: string[] = [];
    ChildUsers: string[] = [];
}


