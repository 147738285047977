<div class="box-shadow module">
  <h2>{{'Create Task Plan'|translate}}</h2>
  <hr>
  <div class="form-horizontal">
    <label style="margin-left:17%; margin-top:1%; color:orangered; font:small-caption" *ngIf="displayWarningNote">
      *{{'This Code has already been taken'|translate}}
    </label>
    <br>
    <label style="margin-left:17%; color:dimgray; font:small-caption" [innerHTML]="'TaskPlanNoteHeader'|translate">
    </label>
    <div class="form-group">
      <label class="control-label col-md-2">{{'Code'|translate}}</label>
      <div class="col-md-10">
        <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Code">
        <span class="text-danger field-validation-error" *ngIf="displayWarningCode">
          <span>{{'The Code field is required'|translate}}.</span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{'Business Unit'|translate}}</label>
      <div class="col-md-10">
        <select [appBUSwitcher]="selectedBU" class="form-control" [(ngModel)]="taskPlan.BusinessUnitId"
          (ngModelChange)="onSelectBusinessUnit($event)">
          <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu.Id">
            {{ bu.Name }}
          </option>
        </select>
        <span class="text-danger field-validation-error" *ngIf="displayWarningBusinessUnit">
          <span>{{'The Business Unit field is required'|translate}}.</span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">
        <span>{{'Task Plan'|translate}}</span>
      </label>
      <div class="col-md-10">
        <input class="form-control text-box single-line" [(ngModel)]="taskPlan.Description">
        <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
          <span>{{'The Task Plan field is required'|translate}}.</span>
        </span>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{'Hide Code'|translate}}</label>
      <div class="col-md-10">
        <input type="checkbox" [(ngModel)]="taskPlan.HideCode" value="taskPlan.HideCode">
      </div>
    </div>
    <app-toggle-rich-input [displayRichEditor]="false" [label]="'DefaultWalkdownSignatureDisclaimer' | translate"
      [(ngModel)]="taskPlan.DefaultWalkthroughSignatureDisclaimer"
      >
    </app-toggle-rich-input>

    <app-toggle-rich-input [displayRichEditor]="false" [label]="'DefaultSummarySignatureDisclaimer' | translate"
      [(ngModel)]="taskPlan.DefaultSummarySignatureDisclaimer">
    </app-toggle-rich-input>

    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <a class="btn btn-default" style="width: 100px;" (click)="createTaskPlan()">{{'Create'|translate}}</a>
      </div>
    </div>
  </div>
  <div>
    <a (click)="onRedirectButtonClick()" style="cursor: pointer;">{{'Back to Task Plan'|translate}}</a>
  </div>
</div>
