<div class="box-shadow module">

  <h2 *ngIf="!inProgress" style="margin-bottom: 0px">
    {{ "Not Registered" | translate }}
  </h2>
  <h3>
    <div *ngIf="inProgress && user !== null">
      {{ "Hi" | translate }} {{ fullName }}
      {{ "yourAccessRequestisinProgress" | translate }}
    </div>
    <div *ngIf="!inProgress" class="form-horizontal">
      <div class="form-group" style="text-align: center">
        {{ fullName }}
      </div>
      <div class="form-group" style="text-align: center">
        <label class="control-label col-md-2 col-md-offset-2"
          >{{ "Business Unit" | translate }}
          <span style="color: red">*</span></label
        >
        <div class="col-md-4">
          <select class="form-control" [(ngModel)]="selectedBU">
            <option [ngValue]="null"></option>
            <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
              {{ bu.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group" style="text-align: center">
        <button class="btn btn-success" (click)="confirmRequest()">
          {{ "Request Access" | translate }}
        </button>
      </div>
    </div>
  </h3>
</div>

<p-dialog
  [style]="{ 'max-width': '600px' }"
  [(visible)]="promptConfirmation"
  [blockScroll]="true"
  [modal]="true"
  [resizable]="true"
  appendTo="body"
>
  <p-header>{{ "Request Access" | translate }}</p-header>

  <div class="row">
    <div
      class="col-md-12"
      [innerHTML]="'Youareabouttorequestaccessfor' | translate: selectedBU"
    ></div>
  </div>
  <p-footer class="row">
    <button
      type="button"
      pButton
      [label]="'No'|translate"
      class="ui-button-secondary"
      (click)="dismiss()"
    ></button>
    <button type="button" pButton [label]="'Yes'|translate" (click)="sendRequest()"></button>
  </p-footer>
</p-dialog>
