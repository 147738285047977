<div
  class="box-shadow module"
  style="width: 80%; margin: auto; background-color: midnightblue; color: white"
>
  <h2>{{ "Attention" | translate }}!</h2>
  <hr />
  <h3>{{ "Are you sure you want to delete Task Plan below" | translate }} ?</h3>

  <div>
    <div style="width: 30%; margin: auto">
      <div class="row" style="padding-top: 10px">
        <div class="col-xs-6" style="text-align: right">
          {{ "Code" | translate }}:
        </div>
        <div class="col-xs-6">
          {{ taskPlan.Code }}
        </div>
      </div>
      <div class="row" style="padding-bottom: 10px">
        <div class="col-xs-6" style="text-align: right">
          {{ "Description" | translate }}:
        </div>
        <div class="col-xs-6">
          {{ taskPlan.Description }}
        </div>
      </div>
    </div>
    <div class="form-actions no-color">
      <div class="centerButtonContainer">
        <a
          class="btn btn-default"
          style="width: 100px"
          (click)="deleteTaskPlan()"
          >{{ "Delete" | translate }}</a
        >
        &nbsp; &nbsp;
        <a
          class="btn btn-default"
          style="width: 100px"
          (click)="onRedirectButtonClick()"
          >{{ "Back" | translate }}</a
        >
      </div>
    </div>
  </div>
</div>
