import { PSSRStatus } from '#models/pssr-status';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PSSR, BusinessUnit, QAQCItem, Project } from '../../../models';

@Component({
  selector: 'app-pssr-description',
  templateUrl: './pssr-description.component.html',
  styleUrls: ['./pssr-description.component.scss']
})
export class PssrDescriptionComponent {

  @Output() initiateAllPssrsEvent = new EventEmitter();

  //#region VARIABLES

  // Project
  @Input() project: Project;

  // PSSR
  @Input() pssrNewList: PSSR[];

  // BU
  @Input() selectedBU: BusinessUnit;
  //#endregion

  // Org
  @Input() orgId: number;

  constructor(private svcRouter: Router) { }

  get canInitiateAll(): boolean {
    return this.pssrNewList && this.pssrNewList.length > 0 ?
      this.pssrNewList.filter(x => x.Status != PSSRStatus.Draft).length == 0 : false;
  }

  initiateAllPSSRs() {
    this.initiateAllPssrsEvent.emit();
  }

  btnCreatePSSR() {
    this.svcRouter.navigate(['/pssr-create', {
      buId: this.selectedBU.Id,
      projId: this.project.Id,
      orgId: this.orgId
    }]);
  }

  btnEditPSSR(pssrId: number): void {
    this.svcRouter.navigate(['/pssr-edit/', {
      id: pssrId,
      projectId: this.project.Id,
      buId: this.selectedBU.Id,
      orgId: this.orgId
    }]);
  }

  qaqcSignatureCounter(item: QAQCItem) {
    let count = 0;
    item.Signatures.forEach((x) => {
      if (x.User.length > 0 || x.SignDate.length > 0) {
        count = count + 1;
      }
    }
    );
    return count;
  }
}
