<div class="box-shadow module" [appBUSwitcher]="selectedBU">
  <div class="row">
    <h2>
      {{ selectedArea.Name }} {{ selectedBU?.HierarchyLabel2 | translate }}
    </h2>
    <hr />
  </div>

  <div class="row padding-bottom-10">
    <div class="col-xs-4">
      <label>{{ selectedBU?.HierarchyLabel1 | translate }} :</label>
      <select
        class="form-control"
        [(ngModel)]="selectedArea"
        (change)="loadSubAreas()"
      >
        <option *ngFor="let a of lstAreas" [ngValue]="a">
          {{ a.Name }}
        </option>
      </select>
    </div>
    <div class="col-sm-8" style="text-align: end; padding-top: 40px">
      <a
        *ngIf="selectedArea.IsActive"
        (click)="onRedirectButtonClick('admin/sub-area-create', null)"
        style="cursor: pointer"
        >{{'Create New'|translate}} {{ selectedBU?.HierarchyLabel2 | translate }}</a
      >
    </div>
  </div>

  <table class="table table-hover">
    <tr *ngFor="let sub of lstSubAreas">
      <td class="col-xs-8">{{ sub.Name }}</td>
      <td class="col-xs-1 text-center">
        <div
          *ngIf="sub.IsActive"
          class="label-info padding-left-5 padding-right-5"
          style="border-radius: 1em; color: white"
        >
          {{'Active'|translate}}
        </div>
        <div
          *ngIf="!sub.IsActive"
          class="label-danger padding-left-5 padding-right-5"
          style="border-radius: 1em; color: white"
        >
          {{'Inactive'|translate}}
        </div>
      </td>
      <td class="col-xs-3 text-center">
        <a
          *ngIf="sub.IsActive"
          (click)="onRedirectButtonClick('admin/sub-area-edit', sub.Id)"
          style="cursor: pointer"
          >{{'Edit'|translate}}</a
        >
        <span *ngIf="sub.IsActive">&nbsp;|&nbsp;</span>
        <a
          (click)="onRedirectButtonClick('admin/facility', sub.Id)"
          style="cursor: pointer"
          >{{ selectedBU?.HierarchyLabel3 | translate }}</a
        >
        <span *ngIf="sub.IsActive">&nbsp;|&nbsp;</span>
        <a
          *ngIf="sub.IsActive"
          (click)="onRedirectButtonClick('admin/sub-area-delete', sub.Id)"
          style="cursor: pointer"
          >{{'Delete'|translate}}</a
        >
      </td>
    </tr>
  </table>
</div>

<div>
  <a (click)="onRedirectButtonClick('admin/area', null)" style="cursor: pointer"
    >Back to {{ selectedBU?.HierarchyLabel1 | translate }} List</a
  >
</div>
