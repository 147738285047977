import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  EventEmitter,
  Output,
} from '@angular/core';
import {
  Area,
  SubArea,
  Facility,
  PSSR,
  BusinessUnit,
  Project,
  Organization,
  TaskPlan,
  TaskPlanType,
  ProjectStatus,
} from '#models/index';
import {
  AreaService,
  SubAreaService,
  FacilityService,
  BusinessUnitsService,
  ProjectService,
  OrganizationService,
  QAQCService,
  TaskPlanService,
} from '#services/api/index';
import { Router } from '@angular/router';
import { PSSRService } from '#services/api/pssr.service';
import { PSSRStatus, PSSRStatusNames } from '#models/pssr-status';
import { Utility } from '#services/shared/utility';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { PSSRType } from '#models/pssr-type';
import { NgForm } from '@angular/forms';
import { Subscription } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  filter,
  mergeMap,
  take,
  tap,
} from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import {
  TranslationRequestDialogComponent,
  TranslationRequestDialogData,
} from '../dialogs/translation-request-dialog/translation-request-dialog.component';
import { TranslationRequestService } from '#services/api/translation-request.service';
import { TranslationRequest } from '#models/translation-request';
import { TranslatePipe } from '@ngx-translate/core';
import { Language } from '#services/translate/languages';
import { TranslationHistoryService } from '#services/api/translation-history.service';
import { TranslationHistory } from '#models/translation-history';
import { TranslationsStorageService } from '#services/shared/translations-storage.service';
import { LanguageLookupService } from '#services/api/language-lookup.service';
import { ToastrService } from '#services/shared/toastr.service';
import { IInvalidOperationResponse } from '#models/invalid-operation-response';
import { IBadRequestResponse } from '#models/bad-request-response';

@Component({
  selector: 'app-pssr-edit-header',
  templateUrl: './pssr-edit-header.component.html',
  styleUrls: ['./pssr-edit-header.component.scss'],
  providers: [ProjectService, QAQCService, TranslatePipe],
})
export class PssrEditHeaderComponent
  implements OnInit, AfterViewInit, OnDestroy {
  @Input() pssr: PSSR = new PSSR(); // Will be populated by parent
  @Output() savedPssr: EventEmitter<PSSR> = new EventEmitter<PSSR>();
  @Output() templateChanged = new EventEmitter();

  newTaskPlanId: number;
  isDraft = false;
  displayTemplateModal = false;
  templateError: string = null;

  lstOrgs: Organization[] = [{ Id: 0, Name: ' ' } as Organization];
  lstArea: Area[] = [{ Id: 0, Name: ' ' } as Area];
  lstSubArea: SubArea[] = [{ Name: ' ', Id: 0 } as SubArea];
  lstFacility: Facility[] = [{ Id: 0, Name: ' ' } as Facility];
  lstProject: Project[] = [{ Id: 0, Name: ' ' } as Project];
  lstTaskPlan: TaskPlan[] = [{ ID: 0, Name: ' ' } as TaskPlan];

  selectedBU: BusinessUnit = new BusinessUnit();
  pssrTypes: SelectItem[];

  remindSaveSort = true;

  translationRequests: TranslationRequest[] = [];
  availableLanguagesForTranslation: { value: Language; label: string }[] = [];
  languages = Utility.availableLanguages;

  loadedCollection = {
    isBULoaded: true,
    isOrgLoaded: false,
    isAreaLoaded: false,
    isSubAreaLoaded: false,
    isProjectLoaded: false,
    isFacilityLoaded: false,
    isTaskPlanLoaded: false,
  };

  previousName = '';
  previousDescription = '';
  displayHistoryModal = false;
  translationHistoryList: TranslationHistory[] = [];

  get isEditable() {
    const isStatusValid =
      [
        PSSRStatus.Draft,
        PSSRStatus.Initiated,
        PSSRStatus.InProgress,
        PSSRStatus.PendingApproval,
        PSSRStatus.Completed,
      ].indexOf(this.pssr.Status) !== -1;

    const userCanEdit = isStatusValid && this.pssr.CurrentUser.CanEdit;
    return userCanEdit || this.pssr.CurrentUser.IsBusinessUnitAdmin;
  }

  constructor(
    private router: Router,

    private svcArea: AreaService,
    private svcSubArea: SubAreaService,
    private svcFacility: FacilityService,
    private svcPSSR: PSSRService,
    private svcBU: BusinessUnitsService,
    private svcOrganization: OrganizationService,
    private svcProject: ProjectService,
    private svcTaskPlan: TaskPlanService,
    public dialog: MatDialog,
    private translationRequestService: TranslationRequestService,
    private languageLookupService: LanguageLookupService,
    private translationHistoryService: TranslationHistoryService,
    private translationsStorageService: TranslationsStorageService,
    private translatePipe: TranslatePipe,
    private toastrService: ToastrService,
    private confirmationService: ConfirmationService
  ) { }

  // Events
  @ViewChild('isValid') isValid: ElementRef;

  @ViewChild(NgForm) form: NgForm;
  private formSubscriber: Subscription = null;

  async ngOnInit() {
    this.updateRemindSaveSort();
    this.resetDescription();

    this.newTaskPlanId = this.pssr.TaskPlanID;
    this.isDraft = this.pssr.Status === PSSRStatus.Draft;
    this.previousName = this.pssr.Name;
    this.previousDescription = this.pssr.Description;

    this.translationsStorageService.data$.subscribe((data) => {
      this.pssr.translationRequestList = data;

      this.translationHistoryList = data
        .reduce((a, { TranslationHistories: b }) => [...a, ...b], [])
        .sort(this.sortTranslationHistoryRecords);
    });

    this.languageLookupService
      .getAvailableLanguagesForTranslation()
      .toPromise()
      .then((languages) => {
        this.availableLanguagesForTranslation = languages;
      });

    if (this.pssr.BusinessUnitID === null) {
      return;
    }

    this.loadDefaultDropdowns();
    this.loadPssrTranslationRequests(this.pssr.Id);

    if (this.pssr.IsDeleted) {
      this.pssr.StatusName = PSSRStatusNames.Deleted;
    }

    this.pssr.statusClass = this.svcPSSR.labelClass(
      this.pssr.IsDeleted
        ? PSSRStatus.Cancel
        : this.pssr.Status
    );

    try {
      this.selectedBU = this.pssr.buObj;
      this.loadPssrTypes();

      if (this.pssr.Status === PSSRStatus.Draft) {
        this.loadTaskPlan(this.selectedBU.Id);
      }
    } catch (e) {
      //
    }
  }

  loadDefaultDropdowns() {
    this.lstOrgs = [
      {
        Id: this.pssr.OrganizationID,
        Name: this.pssr.Organization,
      } as Organization,
    ];
    this.lstArea = [{ Id: this.pssr.AreaID, Name: this.pssr.Area } as Area];
    this.lstSubArea = [
      { Name: this.pssr.SubArea, Id: this.pssr.SubAreaID } as SubArea,
    ];
    this.lstFacility = [
      { Id: this.pssr.FacilityID, Name: this.pssr.Facility } as Facility,
    ];
    this.lstProject = [
      { Id: this.pssr.ProjectID, Name: this.pssr.Project } as Project,
    ];
  }

  private sortTranslationHistoryRecords = (
    a: TranslationHistory,
    b: TranslationHistory
  ): number => {
    if (a && !b) {
      return -1;
    }
    if (!b && a) {
      return 1;
    }

    return (
      new Date(b.UpdatedDate).getTime() - new Date(a.UpdatedDate).getTime()
    );
  }

  ngAfterViewInit() {
    this.formSubscriber = this.form.valueChanges
      .pipe(delay(200), distinctUntilChanged())
      .subscribe(() => {
        this.pssr.pssrIsValid = this.form.valid;
      });
  }

  ngOnDestroy() {
    this.translationsStorageService.clear();
    if (this.formSubscriber) {
      this.formSubscriber.unsubscribe();
    }
  }

  async getBU(buid: number) {
    await this.svcBU
      .getBusinessUnitById(buid)
      .toPromise()
      .then((data: BusinessUnit) => {
        this.selectedBU = data;
        this.loadPssrTypes();
      });
  }

  loadPssrTypes() {
    this.pssrTypes = [];
    if (Utility.isValidListWithData(this.selectedBU.PssrTypes)) {
      this.selectedBU.PssrTypes.forEach((p: PSSRType) => {
        this.pssrTypes.push({ label: p.Name, value: p.Id });
      });
    }
  }

  async changeOrgs() {
    this.pssr.ProjectID = null;
    this.pssr.AreaID = null;
    this.loadedCollection.isAreaLoaded = false;
    this.loadedCollection.isProjectLoaded = false;
    this.changeArea();
  }

  loadOrganizations() {
    if (this.pssr.BusinessUnitID > 0 && !this.loadedCollection.isOrgLoaded) {
      this.svcOrganization
        .getListByBU(this.pssr.BusinessUnitID)
        .toPromise()
        .then(async (data: Organization[]) => {
          this.lstOrgs = data;
          this.loadedCollection.isOrgLoaded = true;
        });
    }
  }

  loadProjects(): void {
    if (!this.selectedBU.ShowProject || this.selectedBU.ShowProjectInput) {
      return;
    }
    if (
      this.pssr.OrganizationID > 0 &&
      !this.loadedCollection.isProjectLoaded
    ) {
      this.svcProject
        .getByOrgId(this.pssr.OrganizationID)
        .toPromise()
        .then(async (data: Project[]) => {
          this.lstProject = Utility.isValidListWithData(data)
            ? data
              .filter(
                (p) => p.Status === ProjectStatus.Active && !p.IsDeleted
              )
              .sort((a, b) => a.Name.localeCompare(b.Name))
            : [];
          this.loadedCollection.isProjectLoaded = true;
        });
    }
  }

  changeArea() {
    this.pssr.SubAreaID = null;
    this.pssr.FacilityID = null;
    this.loadedCollection.isSubAreaLoaded = false;
    this.loadedCollection.isFacilityLoaded = false;
  }

  changeSubArea() {
    this.pssr.FacilityID = null;
    this.loadedCollection.isFacilityLoaded = false;
  }

  btnCancel() {
    this.router.navigate(['/pssr-list']);
  }



  setupTranslation() {
    if (!this.canTranslate) { return; }

    for (const req of this.currentUserTranslationRequests) {
      if (this.previousName !== this.pssr.Name) {
        this.addTranslationHistory({
          TranslationRequestId: req.Id,
          Element: 'PSSR',
          ElementId: this.pssr.Id,
          Content: 'Name',
          PreviousContent: this.previousName,
          TranslatedContent: this.pssr.Name,
          Language: req.Language,
        });
      }
      if (this.previousDescription !== this.pssr.Description) {
        this.addTranslationHistory({
          TranslationRequestId: req.Id,
          Element: 'PSSR',
          ElementId: this.pssr.Id,
          Content: 'Description',
          PreviousContent: this.previousDescription,
          TranslatedContent: this.pssr.Description,
          Language: req.Language,
        });
      }
    }
  }

  addTranslationHistory(historyObj: Partial<TranslationHistory>): void {
    this.translationHistoryService
      .saveTranslationHistory(historyObj as TranslationHistory)
      .toPromise()
      .then(
        () => {
          this.loadPssrTranslationRequests(this.pssr.Id);
        },
        (error) => {
          this.toastrService.showError(error.error.ExceptionMessage);
        }
      );
  }

  // Methods

  loadArea(): void {
    if (this.pssr.BusinessUnitID > 0 && !this.loadedCollection.isAreaLoaded) {
      this.svcArea
        .getAllByBusinessUnit(this.pssr.BusinessUnitID)
        .toPromise()
        .then((data: Area[]) => {
          this.lstArea = data.filter((x) => x.IsActive == true);
          if (this.pssr.Status == PSSRStatus.Draft) {
            // remove status if IsActive == false;
            const exists = this.lstArea.find((x) => x.Id == this.pssr.AreaID);
            if (exists == null || exists == undefined) {
              this.pssr.AreaID = null;
              this.pssr.Area = null;
              this.pssr.SubAreaID = null;
              this.pssr.SubArea = null;
              this.pssr.FacilityID = null;
              this.pssr.Facility = null;
            }
          }
          this.loadedCollection.isAreaLoaded = true;
        });
    }
  }

  loadSubArea(): void {
    if (!this.pssr.AreaID) {
      return;
    }
    if (!this.loadedCollection.isSubAreaLoaded) {
      this.svcSubArea
        .getAllByAreaId(this.pssr.AreaID)
        .toPromise()
        .then(async (data: SubArea[]) => {
          this.lstSubArea = data.filter((x) => x.IsActive == true);
          if (this.lstSubArea.length > 0) {
            if (this.pssr.SubAreaID == null) {
              this.pssr.SubAreaID = this.lstSubArea[0].Id;
            }
          } else {
            this.lstFacility = [];
          }
          if (this.pssr.Status == PSSRStatus.Draft) {
            // remove status if IsActive == false;
            const exists = this.lstSubArea.find(
              (x) => x.Id == this.pssr.SubAreaID
            );
            if (exists == null || exists == undefined) {
              this.pssr.SubAreaID = null;
              this.pssr.SubArea = null;
              this.pssr.FacilityID = null;
              this.pssr.Facility = null;
            }
          }
          this.loadedCollection.isSubAreaLoaded = true;
        });
    }
  }

  async loadFacility() {
    if (this.pssr.SubAreaID > 0 && !this.loadedCollection.isFacilityLoaded) {
      await this.svcFacility
        .getAllBySubArea(this.pssr.SubAreaID)
        .toPromise()
        .then((data: Facility[]) => {
          this.lstFacility = data.filter((x) => x.IsActive == true);

          if (this.pssr.Status == PSSRStatus.Draft) {
            // remove status if IsActive == false;
            const exists = this.lstFacility.find(
              (x) => x.Id == this.pssr.FacilityID
            );
            if (exists == null || exists == undefined) {
              this.pssr.FacilityID = null;
              this.pssr.Facility = null;
            }
          }
          this.loadedCollection.isFacilityLoaded = true;
        });
    }
  }

  deleteRemindSaveSort() {
    localStorage.setItem('remindSaveSort', 'false');
    this.remindSaveSort = false;
  }

  loadTaskPlan(buId: number): void {
    this.svcTaskPlan
      .getTaskPlans(buId)
      .toPromise()
      .then((data: TaskPlan[]) => {
        this.lstTaskPlan = data.filter(
          (t) => t.TaskPlanType === TaskPlanType.PSSR && !t.IsDeleted
        );
      });
  }

  async changeTemplate() {
    await this.svcPSSR
      .updateTaskPlan(this.pssr.Id, this.newTaskPlanId)

      .toPromise()
      .then(
        () => {
          this.templateError = '';
          this.templateChanged.emit();
        },
        (error) => {
          this.templateError = error.error.ExceptionMessage;
          setTimeout(() => (this.templateError = ''), 5000);
        }
      );
  }

  requestTranslation(): void {
    const data: TranslationRequestDialogData = {
      pssrId: this.pssr.Id,
      availableLanguages: this.availableLanguagesForTranslation,
      organizationId: this.pssr.OrganizationID,
    };

    const dialogRef = this.dialog.open(TranslationRequestDialogComponent, {
      disableClose: true,
      autoFocus: true,
      hasBackdrop: true,
      width: '400px',
      height: 'auto',
      data,
      panelClass: 'translation-request-dialog',
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((v) => !!v),
        mergeMap((payload: TranslationRequest) => this.translationRequestService.requestTranslation(payload))
      )
      .toPromise()
      .then(() => {
        this.toastrService.showSuccess(
          this.translatePipe.transform('Translation sent')
        );

        this.reload();

      }, this.handleRequestTranslationError
      )
      ;
  }

  loadPssrTranslationRequests(pssrId: number): void {
    this.translationRequestService
      .getTranslationRequestsByPssrId(pssrId)
      .toPromise()
      .then((data: TranslationRequest[]) => {
        this.translationsStorageService.setTranslations(data);
      });
  }

  get canRequestTranslation() {
    const flag =
      !this.pssr.CurrentUser.CanTranslate &&
      this.pssr.buObj &&
      this.pssr.buObj.CanTranslate;
    return flag;
  }
  async showTranslations() {
    this.displayHistoryModal = true;
  }
  confirmCompleteTranslation(request: Partial<TranslationRequest>): void {
    this.confirmationService.confirm({
      message: this.translatePipe.transform('ConfirmTranslationComplete', {
        language: Utility.availableLanguages[request.Language],
      }),
      header: this.translatePipe.transform('Confirm request'),
      accept: () => {
        this.completeTranslation(request);
      },
      reject: () => {
        // TODO reject implementation
      },
      acceptLabel: this.translatePipe.transform('Confirm'),
      rejectLabel: this.translatePipe.transform('Cancel'),
    });
  }
  completeTranslation(request: Partial<TranslationRequest>): void {
    this.translationRequestService
      .completeTranslation(request.Id)
      .pipe(take(1))
      .subscribe(
        () => {
          this.toastrService.showSuccess(
            this.translatePipe.transform('Translation was completed!')
          );
          this.loadPssrTranslationRequests(this.pssr.Id);
        },
        () => {
          this.toastrService.showWarning(this.translatePipe.transform('Translation completion had an error'));
        }
      );
  }

  get canTranslate(): boolean {
    return (
      this.pssr.buObj &&
      this.pssr.CurrentUser.CanTranslate &&
      this.currentUserTranslationRequests.length > 0
    );
  }

  get currentUserTranslationRequests(): TranslationRequest[] {
    return (
      this.pssr.translationRequestList &&
      this.pssr.translationRequestList.filter(
        (r) =>
          r.TranslatorEmail === this.pssr.CurrentUser.UserEmail ||
          this.pssr.CurrentUser.ParentUsers.includes(r.TranslatorEmail)
      )
    );
  }

  editTranslator({
    Id: requestId,
    TranslatorEmail: translatorEmail,
    PSSRID: pssrId,
  }: TranslationRequest): void {
    const data: TranslationRequestDialogData = {
      pssrId,
      translatorEmail,
      organizationId: this.pssr.OrganizationID,
    };
    const dialogRef = this.dialog.open(TranslationRequestDialogComponent, {
      disableClose: true,
      autoFocus: true,
      hasBackdrop: true,
      width: '400px',
      height: 'auto',
      data,
      panelClass: 'translation-request-dialog',
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((v) => !!v),

        mergeMap(({ TranslatorEmail }: TranslationRequest) =>
          this.translationRequestService.switchTranslator(
            requestId,
            TranslatorEmail
          )
        ),

        tap(() => {
          this.toastrService.showSuccess(
            this.translatePipe.transform('Translation sent')
          );

          this.reload();
        },
          this.handleRequestTranslationError
        )
      )
      .toPromise()
      .then(
        () => {
          // TODO implement
        },
        () => {
          // TODO implement
        }
      );
  }

  reload() {
    this.loadPssrTranslationRequests(this.pssr.Id);
  }

  private updateRemindSaveSort() {
    // TODO such variables should be defined in a common config file
    const remindSaveSort = 'remindSaveSort';
    const value = localStorage.getItem(remindSaveSort);

    if (value == null) {
      localStorage.setItem(remindSaveSort, `${this.remindSaveSort}`);
    }

    // What is the purpose? To double check?
    this.remindSaveSort = localStorage.getItem(remindSaveSort) === 'true';
  }

  private resetDescription() {
    if (!Utility.isValidString(this.pssr.Description)) {
      this.pssr.Description = '';
    }
  }

  handleRequestTranslationError = (
    err: IInvalidOperationResponse | IBadRequestResponse
  ): void => {
    let message = '';
    if ('ExceptionMessage' in err.error) {
      message = err.error.ExceptionMessage;
    } else if ('ModelState' in err.error) {
      message = Object.entries(err.error.ModelState)[0][1][0];
    }

    const userAlreadyPartipatedInPssr =
      message.replace(/\[(.+)\]/, '') ===
      this.translatePipe
        .transform('UserAlreadyPartipatedInPssr', {
          newTranslator: 'manueldejesusescobedoescareno@chevron.com',
        })
        .replace(/\[(.+)\]/, '');

    if (userAlreadyPartipatedInPssr) {
      this.toastrService.showWarning(message);
    } else {
      this.toastrService.showError(
        message || this.translatePipe.transform('An error occurred')
      );
    }
  }
}
