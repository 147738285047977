import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Feedback } from 'models';

@Injectable()
export class FeedbackService {
  constructor(private http: HttpClient) {}

  public getAdoList(tag: string): Observable<Feedback[]> {
    return this.http.get<Feedback[]>(
      `${environment.apiEndpoint}/feedback/adoList/${tag}`
    );
  }

  public create(feedback: Feedback): Observable<Feedback> {
    return this.http.post<Feedback>(
      `${environment.apiEndpoint}/feedback`,
      feedback
    );
  }

  public update(feedback: Feedback): Observable<Feedback> {
    return this.http.put<Feedback>(
      `${environment.apiEndpoint}/feedback`,
      feedback
    );
  }

  public getByADO(ado: string): Observable<Feedback> {
    return this.http.get<Feedback>(
      `${environment.apiEndpoint}/feedback/${ado}`
    );
  }
}
