<div class="box-shadow module">
  <div class="row">
    <h2>{{ "Feedback" | translate }}</h2>
  </div>
  <p-messages [(value)]="messages"></p-messages>
  <div class="top-controls">
    <p-dropdown id="buName" [appBUSwitcher]="selectedBusinessUnit" [(ngModel)]="selectedBusinessUnit"
      [options]="businessUnits" (onChange)="loadItems()" optionLabel="Name" appendTo="body"
      [style]="{ marginRight: '10px' }">
      <ng-template let-item pTemplate="selectedItem">{{ item.value?.Name | translate }}</ng-template>
      <ng-template let-item pTemplate="item">{{ item.value?.Name | translate }}</ng-template>
    </p-dropdown>

    <!-- <button
      id="createNewFeedback"
      (click)="navigateTo(selectedBusinessUnit?.Id)"
      class="btn btn-info"
    >
      <i class="pi pi-plus"></i>{{ "Add Feedback" | translate }}
    </button> -->
  </div>

  <div class="row group">
    <div class="group-header">
      <h3>{{ "ePSSR Service Now Ticketing Instructions" | translate }}</h3>
    </div>
    <ol>
      <li [innerHTML]="'ApplicationSupportRequestForm'|translate"></li>
      <li [innerHTML]="'UndertheApplicationFieldselectMobilePSSR'|translate"></li>
      <li [innerHTML]="'ToreportanissueorbugpleaseselectReportanIssueTosubmitanenhancementrequestoruseraccessrequestpleaseselectServiceRequestHitNext'|translate"></li>
    </ol>
  </div>
  <div class="row group">
    <div class="group-header">
      <h4>{{ "Report an Issue" | translate }} </h4>
    </div>
    <ol>
      <li [innerHTML]="'FillouttheDescriptionoftheProblemImpactandUrgency'|translate"></li>
      <li [innerHTML]="'PressNextandthenSubmit'|translate"></li>
      <li [innerHTML]="'TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours'|translate"></li>
    </ol>
  </div>
  <div class="row group">
    <div class="group-header">
      <h4> {{ "Enhancement Request" | translate }} </h4>
    </div>
    <ol>
      <li [innerHTML]="'PleaseselectEnhancementsunderTypeofRequests'|translate"></li>
      <li [innerHTML]="'SelectPriorityLowModerateHigh'|translate"></li>
      <li [innerHTML]="'FillouttheDescriptionofRequest'|translate"></li>
      <li [innerHTML]="'SelectNextandSubmit'|translate"></li>
      <li [innerHTML]="'TheePSSRSupportTeamwillreviewenhancementrequestsaspartoftheSurfaceDigitalPIPlanningProcess'|translate"></li>
      
    </ol>
  </div>
  <div class="row group">
    <div class="group-header">
      <h4>{{ "User Access Request" | translate }} </h4>
    </div>
    <ol>
      <li [innerHTML]="'PleaseselectRequestAccessunderTypeofRequests'|translate"></li>
      <li [innerHTML]="'SelectAccessRequestTypeAddorRemove'|translate"></li>
      <li [innerHTML]="'SelectUser'|translate"></li>
      <li [innerHTML]="'SelectPriorityLowModerateHigh'|translate"></li>
      <li [innerHTML]="'SpecifyRoleandAccesstheUserneeds'|translate"></li>
      <li [innerHTML]="'SelectNextandSubmit'|translate"></li>
      <li [innerHTML]="'TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours'|translate"></li>
    </ol>
  </div>
  <p-table *ngIf="!isEmpty" [value]="items" [scrollable]="true" class="feedback-list">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" [ngStyle]="{ width: '120px' }">
          {{ "Business Unit" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '120px' }">
          {{ "Type" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '250px' }">
          {{ "Title" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '410px' }">
          {{ "Description" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '100px' }" *ngIf="isSystemAdmin">
          {{ "ADO" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '90px' }">
          {{ "Status" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '150px' }">
          {{ "Iteration" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '100px' }">
          {{ "Priority" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '150px' }">
          {{ "Created On" | translate }}
        </th>
        <th scope="col" [ngStyle]="{ width: '150px' }">
          {{ "Edit" | translate }}
        </th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-feedback>
      <tr>
        <td [ngStyle]="{ width: '120px' }">{{ feedback.BusinessUnitName }}</td>
        <td [ngStyle]="{ width: '120px' }">{{ feedback.typeStr }}</td>
        <td [ngStyle]="{ width: '250px' }">
          {{ feedback.Title }}
        </td>
        <td [ngStyle]="{ width: '410px' }" [innerHTML]="feedback.Comments"></td>
        <td [ngStyle]="{ width: '100px' }" *ngIf="isSystemAdmin">
          <a href="https://dev.azure.com/chevron/GUIT-FWE-MobilePSSR/_workitems/edit/{{
              feedback.ADO
            }}">{{ feedback.ADO }}</a>
        </td>
        <td [ngStyle]="{ width: '90px' }">{{ feedback.Status }}</td>
        <td [ngStyle]="{ width: '150px' }">{{ feedback.Iteration }}</td>
        <td [ngStyle]="{ width: '100px' }">{{ feedback.priorityStr }}</td>
        <td [ngStyle]="{ width: '150px' }">
          {{ feedback.CreateDate | date: "short" }}
        </td>
        <td [ngStyle]="{ width: '150px' }">
          <button
            *ngIf="feedback.CanEdit || isSystemAdmin || isAdminOfThisBU(selectedBusinessUnit?.Id,feedback.BusinessUnitName)"
            (click)="navigateToEdit(feedback)" class="btn btn-info" style="white-space: break-spaces">
            {{ "Edit" | translate }}
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div *ngIf="isEmpty" style="
      text-align: center;
      color: #888;
      padding: 50px 15px;
      background-color: #ededed;
      border-radius: 8px;
    ">
    <span style="font-size: 5em">
      <i class="pi pi-comment"></i>
    </span>
    <p style="font-size: 2em">{{ "No feedback yet" | translate }}.</p>
  </div>
</div>