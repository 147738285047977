<table class="table table-hover">
    <tr>
        <th scope="col" class="col-xs-3" >Name</th>
        <th scope="col" class="col-xs-6">Description</th>
        
        <th scope="col" class="col-xs-3" style="text-align:end; padding: 1px">    
            <button *ngIf="editMode" type="button" class="btn btn-success" (click)="onRedirectButtonClick('admin/equipment-form', 0, parentID, isTemplate)" style="width:80px;">New</button>
      </th>
    </tr>
    <tr *ngFor="let d of lstEquipment">
        <td class="col-xs-3">{{d.Name}}</td>
        <td class="col-xs-6">{{d.Description}}</td>
        <td class="col-xs-3" style="text-align:end">
            <a *ngIf="editMode" (click)="onRedirectButtonClick('admin/equipment-form', d.Id, 0, isTemplate)" style="cursor: pointer;">Edit</a>
            <span *ngIf="editMode" >&nbsp;|&nbsp;</span>   
            <a *ngIf="editMode" (click)="onRedirectButtonClick('admin/end-device', 0, d.Id, isTemplate)" style="cursor: pointer;">End Devices</a>
            <a *ngIf="!editMode && isTemplate" (click)="addEquipmentToProject()" style="cursor: pointer;">Add to Project</a>
        </td>
    </tr>
  </table>
