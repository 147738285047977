import { LoggedUser } from '#models/logged-user';
import { AuthService } from '#services/shared';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class BusinessUnitGuard implements CanActivate {
  currentUser: LoggedUser;

  constructor(private authService: AuthService, private router: Router) {
    this.currentUser = this.authService.getCurrentUser();
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const buId = this.getBuId(route);

    if (this.currentUser.IsSystemAdmin) {
      return true;
    }

    if (this.currentUser.IsBUAdmin && this.currentUser.BusinessUnits.some(bu => bu.Id === buId)) {
      return true;
    }

    this.router.navigate(['/error/not-authorized']);
    return false;
  }

  private getBuId(route: ActivatedRouteSnapshot): number {
    return +route.params['id'];
  }

}
