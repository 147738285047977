<div class="box-shadow module">
  <h1>{{ "Edit Feedback" | translate }}</h1>
  <hr />
  <div class="form-horizontal">
    <!-- BU -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Business Unit" | translate }}:
      </label>
      <div class="col-md-8" style="padding-top: 7px">
        {{ feedback.BusinessUnitName }}
      </div>
    </div>
    <!-- UserId -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Created by" | translate }} :
      </label>
      <div class="col-md-8" style="padding-top: 7px">
        {{ feedback.CreatedBy }}
      </div>
    </div>
    <!-- Type -->
    <div class="form-group">
      <label class="control-label col-md-2">{{ "Type" | translate }} :</label>
      <label class="col-xs-2 form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="type"
          [value]="0"
          [(ngModel)]="feedback.Type"
        />
        &nbsp;{{ "Bug" | translate }}
      </label>
      <label class="col-xs-2 form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="type"
          [value]="1"
          [(ngModel)]="feedback.Type"
        />
        &nbsp;{{ "Enhancement" | translate }}
      </label>
    </div>
    <!-- Environment -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Environment" | translate }} :</label
      >
      <label class="col-xs-2 form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="environment"
          [value]="0"
          [(ngModel)]="feedback.Environment"
        />
        &nbsp;{{ "Mobile" | translate }}
      </label>
      <label class="col-xs-2 form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="environment"
          [value]="1"
          [(ngModel)]="feedback.Environment"
        />
        &nbsp;{{ "Web" | translate }}
      </label>
      <label class="col-xs-2 form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="environment"
          [value]="2"
          [(ngModel)]="feedback.Environment"
        />
        &nbsp;{{ "MobileWeb" | translate }}
      </label>
    </div>
    <!-- Title -->
    <div class="form-group">
      <label class="control-label col-md-2">{{ "Title" | translate }} :</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="feedback.Title"
        />
        <span *ngIf="titleWarning" class="text-danger field-validation-error">
          <b>{{ "Title is required" | translate }}.</b>
        </span>
      </div>
    </div>
    <!-- Comments -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Description" | translate }}:</label
      >
      <div class="col-md-8">
        <textarea
          class="form-control text-box single-line"
          [(ngModel)]="feedback.Comments"
          rows="10"
        >
        </textarea>
        <span
          *ngIf="descriptionWarning"
          class="text-danger field-validation-error"
        >
          <b>{{ "Description is required" | translate }}.</b>
        </span>
      </div>
    </div>
    <!-- File -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Attachments" | translate }} :</label
      >
      <div class="col-md-8">
        <div *ngFor="let file of feedback.Attachment">
          <div class="row padding-top-5">
            {{ file.Name }}
          </div>
        </div>
      </div>
    </div>
    <!-- New File -->
    <div class="form-group">
      <label class="control-label col-md-2"></label>
      <div class="col-md-8">
        <div *ngFor="let file of files">
          <div class="row padding-top-5">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              (click)="remove(file.name)"
            >
              <strong>X</strong>
            </button>
            {{ file.name }}
          </div>
        </div>
        <input
          class="padding-top-5"
          type="file"
          (change)="attachments($event)"
        />
      </div>
    </div>
    <!-- Discussion -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Discussion" | translate }} :</label
      >
      <div class="col-md-8">
        <table class="table table-hover">
          <tr>
            <th scope="col" class="col-md-2">{{ "Date" | translate }}</th>
            <th scope="col" class="col-md-8">{{ "Comment" | translate }}</th>
          </tr>
          <tr *ngFor="let comment of feedback.Discussion">
            <td class="col-md-2">
              {{
                comment.CreateDate != null
                  ? comment.CreateDate.toLocaleString()
                  : ""
              }}
            </td>
            <td class="col-md-8" [innerHtml]="comment.Text"></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-2 col-md-8">
        <textarea
          class="form-control text-box single-line"
          [(ngModel)]="feedback.NewDiscussion"
          rows="3"
          [attr.placeholder]="'Type to add a new discussion comment'|translate"
        >
        </textarea>
      </div>
    </div>
    <!-- Priority -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Priority" | translate }}:</label
      >


        <ng-container [ngSwitch]="feedback.Priority">
          <div class="col-md-8" style="padding-top: 7px" *ngSwitchCase="1">{{'High'|translate}}</div>
          <div class="col-md-8" style="padding-top: 7px" *ngSwitchCase="2">{{'Medium'|translate}}</div>
          <div class="col-md-8" style="padding-top: 7px" *ngSwitchCase="3">{{'Low'|translate}}</div>

        </ng-container>
    </div>
    <!-- ADO -->
    <div class="form-group">
      <label class="control-label col-md-2">{{'ADO'|translate}}# :</label>
      <div class="col-md-8" style="padding-top: 7px">
        <a
          *ngIf="loggedUser.IsSystemAdmin"
          href="https://dev.azure.com/chevron/GUIT-FWE-MobilePSSR/_workitems/edit/{{
            feedback.ADO
          }}"
        >
          {{ feedback.ADO }}
        </a>
        <b *ngIf="!loggedUser.IsSystemAdmin">{{ feedback.ADO }}</b>
      </div>
    </div>
    <!-- Status -->
    <div class="form-group">
      <label class="control-label col-md-2">{{'ADO Status'|translate}} :</label>
      <div class="col-md-8" style="padding-top: 7px">
        {{ feedback.Status }}
      </div>
    </div>
    <!-- CreatedOn -->
    <div class="form-group">
      <label class="control-label col-md-2">{{'Created On'|translate}} :</label>
      <div class="col-md-8" style="padding-top: 7px">
        {{ feedback.CreateDate | date: "short" }}
      </div>
    </div>
    <!-- buttons -->
    <div class="form-group">
      <div class="col-md-2"></div>
      <div class="col-md-2">
        <a class="btn btn-primary" style="width: 100px" (click)="save()"
          >{{'Save'|translate}}</a
        >
      </div>
    </div>
  </div>
  <div>
    <a (click)="onRedirectButtonClick()" style="cursor: pointer"
      >{{'Back to List'|translate}}</a
    >
  </div>
</div>
