import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { SystemAdmin } from '#models/system-admin';

@Injectable()
export class SystemAdminService {
    constructor(
        private http: HttpClient
    ) { }

    public getAll(): Observable<SystemAdmin[]>  {
        return this.http.get<SystemAdmin[]>(`${environment.apiEndpoint}/system-admin`);
    }

    public createSystemAdmin(sysAdmin: SystemAdmin): Observable<SystemAdmin> {
        return this.http.post<SystemAdmin>(`${environment.apiEndpoint}/system-admin`, sysAdmin);
    }

    public updateSystemAdmin(sysAdmin: SystemAdmin): Observable<SystemAdmin> {
        return this.http.put<SystemAdmin>(`${environment.apiEndpoint}/system-admin`, sysAdmin);
    }

}
