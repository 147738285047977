import { SharedUser } from './shared-user';
import { UserType } from './enum/user-type';

export class UserSimple {
  FirstName: string;
  LastName: string;
  MiddleName = '';
  Email: string;
  CAI: string;
  DisclaimerAccepted: boolean;
  Passcode: string;
  IsLoginOnly: boolean;
  IsTcoWhitePagesImported?: boolean;
  IsManageEquipment: boolean;
  ChildUsers: SharedUser[] = [];
  Type: UserType;
  HasAccess: boolean;
  IsChevronUser: boolean;
  TempPassword: boolean;
}
