import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import {
  PSSR,
  TaskPlanSection,
  PSSRResponse,
  TaskPlanSubSection,
  Group,
  PSSRFile,
  TaskPlanSectionType,
} from '#models/index';
import {
  TaskPlanService,
  PSSRResponseService,
  PSSRService,
} from '#services/api';

import { PSSRStatus } from '#models/pssr-status';
import { Utility } from '#services/shared/utility';
import { SectionType } from '#models/enum/section-type';
import { ResponseItem } from '#models/response-item';
import { take } from 'rxjs/operators';
import { PssrSectionOrderService } from '#services/api/pssr-section-order.service';
import { SectionInputDialogComponent } from '../dialogs/section-input-dialog/section-input-dialog.component';
import { DialogService } from '#services/shared/dialog.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { AuthService } from '#services/shared';
import { PSSRSectionOrder } from '#models/pssr-section-order';

import { TranslatePipe } from '@ngx-translate/core';
import { UserRole } from '#models/enum/user-role';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-pssr-edit-items',
  templateUrl: './pssr-edit-items.component.html',
  styleUrls: ['./pssr-edit-items.component.scss'],
  providers: [DialogService, TranslatePipe],
})
export class PssrEditItemsComponent implements OnInit {
  @Input() pssr: PSSR;
  @Input() groups: Group[] = [];
  @Input() pssrFiles: PSSRFile[] = [];

  @Output() itemUpdated = new EventEmitter();
  @Output() memberChanged = new EventEmitter();

  @ViewChild(SectionInputDialogComponent)
  sectionInputDialog: SectionInputDialogComponent;

  sections: TaskPlanSection[] = [];
  loadingCompleted = false;
  sectionsAddedInProgress: number[] = [];

  newSectionInput: TaskPlanSection = new TaskPlanSection();

  get inEditMode() {
    const isEditStatus =
      this.pssr.Status === PSSRStatus.Draft ||
      this.pssr.Status === PSSRStatus.Initiated ||
      this.inProgress;
    return isEditStatus && this.pssr.CurrentUser.CanEdit;
  }
  get inProgress() {
    const isInProgress = this.pssr.Status === PSSRStatus.InProgress;
    return isInProgress;
  }
  get standAloneSection() {
    return this.sections.find((s) => s.Type === SectionType.StandAlone);
  }

  bodySections: TaskPlanSection[] = [];

  isSystemAdmin = false;

  constructor(
    private pssrSvc: PSSRService,
    private taskPlanSvc: TaskPlanService,
    private responseSvc: PSSRResponseService,

    private pssrSectionService: PssrSectionOrderService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private translatePipe: TranslatePipe
  ) { }

  async ngOnInit() {
    this.pssr.lstResponse = [];
    this.pssr.standAloneItems = [];

    await this.reload();

    this.loadImagesAsync();

    this.isSystemAdmin = this.authService.isSystemAdmin();
  }

  async reload() {
    this.loadingCompleted = false;
    try {
      await this.loadResponses(this.pssr.Id);
      await this.loadSections(this.pssr.TaskPlanID);
      await this.loadSectionsUsers();
      await this.loadStandAloneItems(this.pssr.Id);

      this.concludeSections(this.pssr.lstResponse);

      this._sortSections(this.pssr.lstResponse);
      this.loadSubSections();
      this.addSignaturesSection();
    } catch (e) {
      //
    } finally {
      this.loadingCompleted = true;

      this.setExpandStatus();

      this.pssr = this.responseSvc.validateItemPriority(
        this.pssr,
        this.pssr.lstResponse,
        this.pssr.standAloneItems
      );
    }
  }

  sectionUpdated() {
    this.itemUpdated.emit();
  }

  /**
   * Loads the sections of the task plan
   */
  private async loadSections(taskPlanID: number): Promise<void> {
    await this.taskPlanSvc
      .getTaskPlanSectionByTaskPlan(taskPlanID)
      .toPromise()
      .then((data) => {
        this.sections = data;
      });

    this.sections.forEach((s: TaskPlanSection) => {
      s.Type =
        s.Description === 'Signatures'
          ? SectionType.Signature
          : SectionType.Body;
      s.isCollapsed = false;
    });

    this.bodySections = this.sections.filter(
      (s) => s.Type === SectionType.Body
    );

    this.sections.push(
      ...[
        <TaskPlanSection>{
          Description: 'Project Specific',
          Type: SectionType.ProjectSpecific,
          SectionType: TaskPlanSectionType.Mandatory,
          Responses: [],
          StandAloneItems: [],
          SortID: 98,
          TaskPlanSubSections: [],
          isCollapsed: false,
        },
        <TaskPlanSection>{
          Description: 'Post-Walkdown Action Items',
          Type: SectionType.StandAlone,
          SectionType: TaskPlanSectionType.Mandatory,
          Responses: [],
          StandAloneItems: [],
          SortID: 99,
          TaskPlanSubSections: [],
          isCollapsed: false,
        },
      ]
    );
  }

  /**
   * Loads the users of all sections of the PSSR
   */
  private async loadSectionsUsers() {
    this.sections.forEach(
      async (section) =>
      (section.Users = await this.pssrSvc
        .getSectionUsers(this.pssr.Id, section.ID || 0, section.Description)
        .toPromise())
    );
  }

  /**
   * Loads the questions/responses of the PSSR
   */
  private async loadResponses(pssrID: number) {
    await this.responseSvc
      .getAllByPSSRId(pssrID)
      .toPromise()
      .then((responses) => {
        responses.forEach((response) =>
          response.ActionItems.forEach((item) => this.setDateFormat(item))
        );
        this.pssr.lstResponse = responses;
      });
  }

  /**
   * Loads the stand alone action items of the PSSR
   */
  private async loadStandAloneItems(pssrID: number) {
    const standAloneItems = await this.responseSvc
      .getStandAloneItems(pssrID)
      .toPromise();

    standAloneItems.forEach((item) => this.setDateFormat(item));

    this.pssr.standAloneItems = standAloneItems;

    if (this.standAloneSection) {
      this.standAloneSection.StandAloneItems = standAloneItems;
    }
  }

  /**
   * Sets each response to its corresponding section/subsection within the PSSR
   */
  private concludeSections(responses: PSSRResponse[]) {
    const setResponses = (s: TaskPlanSection) => {
      const sectionResponses = responses.filter(
        (r) => r.SectionDescription === s.Description
      );
      s.Responses = sectionResponses;
      if (sectionResponses.length > 0) {
        s.SortID = sectionResponses[0].SectionSortID;
      }
    };

    this.sections.forEach(setResponses);

    // create sections for multiple new sections
    const remaining = this.pssr.lstResponse
      .filter(({ SectionDescription }) => {
        return this.sections.every((s) => s.Description !== SectionDescription);
      })
      .filter(
        (response, index, list) =>
          list.findIndex(
            (r) => r.SectionDescription === response.SectionDescription
          ) === index
      )
      .map<TaskPlanSection>((response) => {
        return {
          General: false,
          CreateDate: new Date(),
          SortID: response.SectionSortID,
          TaskPlanId: this.pssr.TaskPlanID,
          TaskPlanSubSections: [],
          Users: [],
          Description: response.SectionDescription,
          Type: SectionType.Body,
          SectionType: TaskPlanSectionType.Multiple,
          ID: response.SectionID,
          Responses: this.pssr.lstResponse.filter(
            (r) =>
              r.SectionID === response.SectionID &&
              r.SectionDescription == response.SectionDescription
          ),
          StandAloneItems: [],
          isCollapsed: false,
          ResponseStatus: response.ResponseStatus
        };
      });

    this.sections = this.sections.map((section) =>
      section.Type === SectionType.StandAlone
        ? {
          ...section,
          StandAloneItems: this.pssr.standAloneItems,
        }
        : section
    );

    remaining.forEach(
      async (section) =>
      (section.Users = await this.pssrSvc
        .getSectionUsers(this.pssr.Id, section.ID || 0, section.Description)
        .toPromise())
    );


    this.sections = this.sections
      .concat(remaining)
      .filter((section) =>
        this.responseSvc.isVisibleSection(section, this.pssr)
      );
  }

  private _sortSections(responses: PSSRResponse[]) {
    // get sections from responses are pulled in order (sectionsortid, subsectionsortid, sortid, createddate)
    const sections = responses
      .map(({ SectionDescription: Description }) => Description)
      .filter((v, i, l) => l.indexOf(v) === i);

    const _sortCallback = (a: TaskPlanSection, b: TaskPlanSection): number => {
      const indexA = sections.indexOf(a.Description);
      const indexB = sections.indexOf(b.Description);

      if (indexA === -1 || indexB === -1) {
        return a.SortID - b.SortID;
      }

      return indexA - indexB;
    };

    this.sections = this.sections.sort(_sortCallback);
  }

  /**
   * Loads all images related to the PSSR
   */
  private loadImagesAsync() {
    const loadActionItemImage = (actionItem: ResponseItem) =>
      this.responseSvc
        .getImagesByActionItem(actionItem.Id)
        .pipe(take(1))
        .subscribe((images) => (actionItem.Images = images));

    const loadSectionImages = (section: TaskPlanSection | TaskPlanSubSection) =>
      section.Responses.forEach((response) =>
        response.ActionItems.forEach((actionItem) =>
          loadActionItemImage(actionItem)
        )
      );

    // load body section images
    this.sections.forEach((section) => {
      if (
        section.TaskPlanSubSections &&
        section.TaskPlanSubSections.length > 0
      ) {
        section.TaskPlanSubSections.forEach((t) => loadSectionImages(t));
      } else {
        loadSectionImages(section);
      }
    });

    // load stand alone images
    if (this.standAloneSection) {
      this.standAloneSection.StandAloneItems.forEach((actionItem) =>
        loadActionItemImage(actionItem)
      );
    }
  }

  private addSignaturesSection() {
    const hasSignaturesSectionAvailable = this.sections.some(
      (s) => s.Type === SectionType.Signature && s.Description === 'Signatures'
    );

    if (this.pssr.showSignature && !hasSignaturesSectionAvailable) {
      this.sections.push(<TaskPlanSection>{
        Description: 'Signatures',
        Type: SectionType.Signature,
        SectionType: TaskPlanSectionType.Mandatory,
        Responses: [],
        StandAloneItems: [],
        Users: [],
        SortID: 97,
        TaskPlanSubSections: [],
        isCollapsed: this.pssr.buObj.ShowSectionsCollapsed,
      });
    }
  }

  isAddedInProgress(sectionID: number) {
    return this.sectionsAddedInProgress.includes(sectionID);
  }
  get listOfDescriptions(): string[] {
    return this.responseSvc.getListOfSections(this.pssr.lstResponse);
  }
  tryAddMultipleSection(section: TaskPlanSection): boolean {
    const isMultipleSection =
      section.SectionType === TaskPlanSectionType.Multiple;

    if (isMultipleSection) {
      this.newSectionInput = {
        ...section,
        Responses: [],
        StandAloneItems: [],
        Users: [],
      };

      this.sectionInputDialog.open();

      return false;
    }
    return true;
  }
  /**
   * Adds a section to the PSSR
   */
  async addSection(section: TaskPlanSection): Promise<void> {
    await this.responseSvc
      .createByPSSRSection(this.pssr.Id, {
        ...section,
        CreateDate: new Date(),
      })
      .toPromise();

    await this.reload();

    if (this.inProgress) {
      this.sectionsAddedInProgress.push(section.ID);
    }
  }

  /**
   * Performs removal of a section using the API
   */
  removeSection(section: TaskPlanSection): void {
    const sectionID = section.ID;
    this.responseSvc
      .deleteByPSSRSection(this.pssr.Id, section.ID, section.Description)
      .toPromise()
      .then(
        () => {
          this.sections = this.sections.filter((s) => {
            if (s.ID === sectionID && section.Description == s.Description) {
              return false;
            }
            return true;
          });

          this.pssr.lstResponse = this.pssr.lstResponse.filter((r) => {
            if (
              r.SectionID === sectionID &&
              r.SectionDescription == section.Description
            ) {
              return false;
            }
            return true;
          });
          this.toastrService.showSuccess( this.translatePipe.transform( 'Section was removed successfully' ), this.translatePipe.transform('Remove Section Complete'), );
        },
        () => {
          this.toastrService.showWarning( this.translatePipe.transform('Section has action items.'), this.translatePipe.transform('Unable to Remove Section'));
        }
      );
  }

  async onSortChanged({
    previousIndex,
    currentIndex,
  }: {
    previousIndex: number;
    currentIndex: number;
  }) {
    moveItemInArray(this.sections, previousIndex, currentIndex);
  }

  isSectionActive(section: TaskPlanSection): boolean {
    const isActiveSection =
      this.pssr.lstResponse.filter(
        (f) =>
          f.SectionID === section.ID &&
          section.SectionType !== TaskPlanSectionType.Multiple
      ).length === 0;

    return !isActiveSection;
  }

  private setDateFormat(actionItem: ResponseItem) {
    actionItem.DueDate = Utility.parseOffsetDate(actionItem.DueDate);
    actionItem.CreateDate = Utility.parseOffsetDate(actionItem.CreateDate);
    actionItem.ClosedOn = Utility.parseOffsetDate(actionItem.ClosedOn);
    actionItem.UpdatedDate = Utility.parseOffsetDate(actionItem.UpdatedDate);
  }

  get canRespond(): boolean {
    return this.pssrSvc.canRespond(this.pssr);
  }

  get hasEmptySections(): boolean {
    // visible
    return (
      this.pssr.Status <= PSSRStatus.Initiated &&
      this.sections
        .filter(
          (section) =>
            this.responseSvc.isVisibleSection(section, this.pssr) &&
            [SectionType.Body, SectionType.ProjectSpecific].includes(
              section.Type
            )
        )
        .every((section) => section.Responses.length === 0)
    );
  }

  loadSubSections(): void {
    this.sections.forEach((section) => {
      if (section.Responses.length > 0) {
        const subsections = section.Responses.reduce(
          (a, b) =>
            a.includes(b.SubSectionDescription)
              ? a
              : [...a, b.SubSectionDescription],
          []
        );

        if (subsections.length > 0) {
          subsections.forEach((sub) => {
            const s = new TaskPlanSubSection();
            const responses = section.Responses.filter(
              (i) => i.SubSectionDescription === sub
            );

            s.Description = sub;
            s.Responses = responses;

            if (responses.length > 0) {
              s.SortID = responses[0].SubSectionSortID;

              s.IsCommentsSubSection = responses.some((r) => r.ItemType === 3);
            }

            if (section.TaskPlanSubSections) {
              section.TaskPlanSubSections.push(s);
              section.TaskPlanSubSections = section.TaskPlanSubSections.sort(
                (a, b) => a.SortID - b.SortID
              );
            }
          });
        }
      }
    });
  }

  get maxSections(): number {
    const sections = this.sections.filter(
      (s) =>
        s.Type !== SectionType.StandAlone &&
        s.Type != SectionType.ProjectSpecific
    );
    return sections.length - 1;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.sections, event.previousIndex, event.currentIndex);
  }

  canSwitchOrder(section) {
    return (
      this.pssr.IsSectionOrderEnabled &&
      section.ID &&
      (this.pssr.CurrentUser.CanEdit ||
        this.isSystemAdmin ||
        this.pssr.CurrentUser.IsBusinessUnitAdmin ||
        this.pssr.CurrentUser.IsOrgAdmin) &&
      this.pssr.Status <= 3
    );
  }

  async saveCustomSortOrder($event) {
    const el = $event.currentTarget;

    try {
      el.disabled = true;

      const payload = this.sections
        .map<PSSRSectionOrder>(
          (s, i) =>
          ({
            PSSRID: this.pssr.Id,
            SectionDescription: s.Description,
            SortID: i,
            SectionID: s.ID,
          } as PSSRSectionOrder)
        )
        .filter((s) => s.SectionID);

      await this.pssrSectionService.bulkChangeOrder(payload).toPromise();

      this.toastrService.showSuccess( this.translatePipe.transform( 'Sections were sorted successfully' ), this.translatePipe.transform('Save Completed'), );
    } catch (e) {
      const error = e.error ? e.error.Message : e.message;

      this.toastrService.showWarning(this.translatePipe.transform(error), this.translatePipe.transform('Unable to complete operation'));
    } finally {
      el.disabled = false;
    }
  }
  setExpandStatus() {
    this.sections.forEach((s) => {
      s.isCollapsed = this.pssr.buObj.ShowSectionsCollapsed;
    });
  }

  get members() {
    return this.pssr.PSSRUsers.Users.filter(
      (u) => u.Role != UserRole.Translator
    );
  }
}
