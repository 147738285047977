import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { EndDeviceType } from '#models/end-device-type';


@Injectable()
export class EndDeviceTypeService {
    constructor(
        private http: HttpClient
    ) { }

    // public getAllByBusinessUnit(businessUnitId: Number): Observable<Area[]> {
    //     return this.http.get<Area[]>(`${environment.apiEndpoint}/end-device/getByBusinessUnit/${businessUnitId}`);
    // }

    public getEndDeviceTypeById(id: number): Observable<EndDeviceType> {
        return this.http.get<EndDeviceType>(`${environment.apiEndpoint}/end-device-type/${id}`);
    }

    public getAll(): Observable<EndDeviceType[]> {
        return this.http.get<EndDeviceType[]>(`${environment.apiEndpoint}/end-device-type/all`);
    }

    public create(endDeviceType: EndDeviceType): Observable<EndDeviceType> {
        return this.http.post<EndDeviceType>(`${environment.apiEndpoint}/end-device-type`, endDeviceType);
    }

    public update(endDeviceType: EndDeviceType): Observable<EndDeviceType> {
        return this.http.put<EndDeviceType>(`${environment.apiEndpoint}/end-device-type`, endDeviceType);
    }

    public delete(id: number): Observable<EndDeviceType> {
        return this.http.put<EndDeviceType>(`${environment.apiEndpoint}/end-device-type/delete/${id}`, null);
    }

    public getEndDeviceTypeByBU(buId: number): Observable<EndDeviceType[]> {
        return this.http.get<EndDeviceType[]>(`${environment.apiEndpoint}/end-device-type/bybu/${buId}`);
    }

}

