import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { MobileAppVersion } from '#models/mobile-app-version';
import { MobileAppVersionService } from '#services/api';

import { finalize, tap } from 'rxjs/operators';
import { AppVersionStatus } from '#models/enum/system-version-status';
import { TranslatePipe } from '@ngx-translate/core';

import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-mobile-app-version-edit-form',
  templateUrl: './mobile-app-version-edit-form.component.html',
  styleUrls: ['./mobile-app-version-edit-form.component.scss'],
  providers: [TranslatePipe],
})
export class MobileAppVersionEditFormComponent implements OnInit {
  @Input() show: boolean;
  @Input() listAppVersionStatus: SelectItem[];
  @Output() onCreate = new EventEmitter<MobileAppVersion>();
  @Output() onCancel = new EventEmitter<boolean>();
  version: MobileAppVersion;

  constructor(
    private toastrService: ToastrService,
    private mobileAppVersionSvc: MobileAppVersionService,

    private translatePipe: TranslatePipe
  ) {}

  ngOnInit() {
    this.version = new MobileAppVersion();
    this.version.Status = AppVersionStatus.Current;
  }

  save() {
    const observable = this.mobileAppVersionSvc.create(this.version).pipe(
      tap(
        (_) => _,
        (err) => {
          this.toastrService.showError( err.error.ExceptonMessage);
        }
      ),
      finalize(() => {
        this.onCancel.emit(false);
      })
    );

    observable.subscribe((updatedVersion) => {
      this.onCreate.emit(updatedVersion);

      this.toastrService.showSuccess( this.translatePipe.transform('Item has been added successfully'), this.translatePipe.transform('Save Completed') );
    });
  }

  cancel() {
    this.onCancel.emit(false);
  }
}
