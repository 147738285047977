import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { PSSRUser } from '#models/pssr-user';
import { UserRole } from '#models/enum/user-role';
import { PSSRUserList } from '#models/pssr-user-list';
import { PSSRStatus, PSSRStatusCSSClasses } from '#models/pssr-status';
import { PagedList, PaginationRequest } from '#models/pagination';
import { SectionUser } from '#models/section/section';
import { BusinessUnitsService } from './business-unit.service';
import { IdName } from '#models/id-name';

import {
  PSSRResponse,
  PSSRFile,
  PSSRFilter,
  PSSR,
  TaskPlanSection,
  BusinessUnit,
} from 'models';

@Injectable()
export class PSSRService {
  constructor(
    private http: HttpClient,
    private businessUnitService: BusinessUnitsService
  ) { }

  public getDetails(pssrId: number): Observable<PSSR> {
    return this.http.get<PSSR>(`${environment.apiEndpoint}/pssr/${pssrId}`);
  }

  public create(pssr: PSSR): Observable<PSSR> {
    return this.http.post<PSSR>(`${environment.apiEndpoint}/pssr`, pssr);
  }

  public createMulti(pssr: PSSR[]): Observable<PSSR[]> {
    return this.http.post<PSSR[]>(
      `${environment.apiEndpoint}/pssr/createMulti`,
      pssr
    );
  }

  public createMultiWithParticipant(pssr: PSSR[]): Observable<PSSR[]> {
    return this.http.post<PSSR[]>(
      `${environment.apiEndpoint}/pssr/createMultiWithParticipants`,
      pssr
    );
  }

  public update(pssr: PSSR): Observable<PSSR> {
    return this.http.put<PSSR>(`${environment.apiEndpoint}/pssr`, pssr);
  }

  public currentUserPSSRs(filter: PSSRFilter): Observable<PSSR[]> {
    return this.http.post<PSSR[]>(
      `${environment.apiEndpoint}/pssr/currentPSSR`,
      filter
    );
  }

  public getPagedPSSRs(
    filter: PSSRFilter,
    pagination: PaginationRequest
  ): Observable<PagedList<PSSR>> {
    const requestParams = {
      Filter: filter,
      Pagination: pagination,
    };

    return this.http.post<PagedList<PSSR>>(
      `${environment.apiEndpoint}/pssr/paged-list`,
      requestParams
    );
  }

  public getPSSRsByName(buId: number, name: string): Observable<IdName[]> {
    return this.http.get<IdName[]>(
      `${environment.apiEndpoint}/pssr/by-name/${buId}/${name}/`
    );
  }

  public pssrsByProjId(projId: number): Observable<PSSR[]> {
    return this.http.get<PSSR[]>(
      `${environment.apiEndpoint}/pssr/projectPSSRs/${projId}`
    );
  }

  public addParticipant(pssrUsers: PSSRUser[]): Observable<PSSRUserList> {
    return this.http.post<PSSRUserList>(
      `${environment.apiEndpoint}/pssr/addParticipant`,
      pssrUsers
    );
  }

  public updateParticipant(pssrUser: PSSRUser): Observable<any> {
    return this.http.post<any>(
      `${environment.apiEndpoint}/pssr/updateParticipant`,
      pssrUser
    );
  }

  public removeParticipant(id: number): Observable<PSSRUser> {
    return this.http.put<PSSRUser>(
      `${environment.apiEndpoint}/pssr/removeParticipant/${id}`,
      null
    );
  }

  public changeStatus(pssrId: number, statusId: number): Observable<any> {
    return this.http.put<PSSR>(
      `${environment.apiEndpoint}/pssr/changeStatus/${pssrId}/${statusId}`,
      {
        updatedDate: new Date().toISOString(),
      }
    );
  }

  public reOpenPSSR(pssrId: number) {
    return this.http.put<PSSR>(
      `${environment.apiEndpoint}/pssr/reopen/${pssrId}`,
      null
    );
  }

  public canMarkInProgress(pssr: PSSR, section: TaskPlanSection): boolean {
    const hasAtLeastOneResponse = (responses: PSSRResponse[]): boolean =>
      responses.filter((r: PSSRResponse) => r.ResponseText != '').length > 0;

    const hasAtLeastOneItem = (responses: PSSRResponse[]): boolean =>
      responses.filter((r) => r.ActionItems.length > 0).length > 0;

    return (
      // has responded
      (section.TaskPlanSubSections.some((s) =>
        hasAtLeastOneResponse(s.Responses)
      ) ||
        hasAtLeastOneResponse(section.Responses) ||
        // has action items
        section.TaskPlanSubSections.some((s) =>
          hasAtLeastOneItem(s.Responses)
        ) ||
        hasAtLeastOneItem(section.Responses)) &&
      pssr.Status === PSSRStatus.Initiated
    );
  }

  public deletePssr(pssrId: number) {
    return this.http.put<PSSR>(
      `${environment.apiEndpoint}/pssr/delete/${pssrId}`,
      null
    );
  }

  public restorePssr(pssrId: number) {
    return this.http.put<PSSR>(
      `${environment.apiEndpoint}/pssr/restore/${pssrId}`,
      null
    );
  }

  public updateTaskPlan(pssrId: number, taskPlanId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpoint}/pssr/updateTaskPlan/${pssrId}/${taskPlanId}`,
      null
    );
  }

  public labelClass(status: number): string {
    if (PSSRStatusCSSClasses.hasOwnProperty(status)) {
      return PSSRStatusCSSClasses[status];
    }

    return '';
  }

  public getSectionUsers(
    pssrId: number,
    sectionId: number,
    sectionDesc: string
  ): Observable<SectionUser[]> {
    const id = sectionId > 0 ? sectionId : 0;

    return this.http.get<SectionUser[]>(
      `${environment.apiEndpoint}/pssr/getPssrSectionUsers/${pssrId}/${id}`,
      {
        params: {
          sectionDesc,
        },
      }
    );
  }

  public addSectionUser(sectionUser: SectionUser): Observable<SectionUser> {
    return this.http.post<SectionUser>(
      `${environment.apiEndpoint}/pssr/addSectionUser`,
      sectionUser
    );
  }

  public removeSectionUser(id: number): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpoint}/pssr/removeSectionUser/${id}`,
      null
    );
  }

  public download(pssrID: number) {
    return this.http.get(`${environment.apiEndpoint}/report/pdf/${pssrID}`, {
      responseType: 'blob',
    });
  }

  public sendEmailReminderWithFreeText(pssrID: number, textForReminder: string = '') {
    return this.http.post(`${environment.apiEndpoint}/pssr/${pssrID}/email`,
      {
        AdditionalTextForEmail: textForReminder
      });
  }

  public sendEmailReminder(pssrID: number) {
    return this.http.get(`${environment.apiEndpoint}/pssr/sendEmail/${pssrID}`);
  }

  public sendEmailReminderJob2() {
    return this.http.get(`${environment.apiEndpoint}/pssr/sendEmailJob2`);
  }

  public uploadFile(file: PSSRFile): Observable<PSSRFile[]> {
    return this.http.post<PSSRFile[]>(
      `${environment.apiEndpoint}/pssr/upload-file`,
      file
    );
  }

  public uploadToMOC(pssrId: number) {
    return this.http.post(`${environment.apiEndpoint}/pssr/upload-to-moc/${pssrId}`, {});
  }

  public getPSSRFiles(pssrId: number): Observable<PSSRFile[]> {
    return this.http.get<PSSRFile[]>(
      `${environment.apiEndpoint}/pssr/getFiles/${pssrId}`
    );
  }

  public removeFileByFileId(fileId: number) {
    return this.http.delete(
      `${environment.apiEndpoint}/pssr/deleteFile/${fileId}`
    );
  }

  public getFileByGUID(pssrId: number, fileGUID: string): Observable<PSSRFile> {
    return this.http.get<PSSRFile>(
      `${environment.apiEndpoint}/pssr/${pssrId}/getFile2/${fileGUID}`
    );
  }

  public downloadPSSRFileById(
    pssrId: number,
    fileId: number
  ): Observable<PSSRFile> {
    return this.http.get<PSSRFile>(
      `${environment.apiEndpoint}/pssr/${pssrId}/getFile/${fileId}`
    );
  }

  public downloadPSSRFileByGuid(
    pssrId: number,
    guid: string
  ): Observable<PSSRFile> {
    return this.http.get<PSSRFile>(
      `${environment.apiEndpoint}/pssr/${pssrId}/getFile`,
      {
        params: {
          guid,
        },
      }
    );
  }

  canRespond(pssr: PSSR): boolean {
    return (
      ([UserRole.Approver, UserRole.Lead].includes(pssr.CurrentUser.Role) &&
        [PSSRStatus.Initiated, PSSRStatus.InProgress].includes(pssr.Status)) ||
      this.businessUnitService.allowMembersToCompletePSSR(pssr.buObj, pssr)
    );
  }


  public resetTraining(buId: number): Observable<any> {
    return this.http.put<any>(
      `${environment.apiEndpoint}/pssr/resetTraining/${buId}`,
      null
    );
  }

  canManageParticipants(currentUser: PSSRUser, pssr: PSSR, businessUnit: BusinessUnit): boolean {
    if (pssr.IsDeleted) {
      return false;
    }

    if (!businessUnit.PssrStatusesCanManageParticipants.includes(pssr.Status)) {
      return false;
    }

    if (
      pssr.Status >= PSSRStatus.Approved &&
      currentUser.IsBusinessUnitAdmin &&
      businessUnit.PssrStatusesCanManageParticipants.includes(pssr.Status)
    ) {
      return true;
    }

    if (
      pssr.Status < PSSRStatus.Approved &&
      businessUnit.PssrStatusesCanManageParticipants.includes(pssr.Status) &&
      (pssr.CurrentUser.IsBusinessUnitAdmin ||
        pssr.CurrentUser.IsOrgAdmin ||
        pssr.CurrentUser.CanEdit)
    ) {
      return true;
    }

    return false;
  }
}
