<div class="box-shadow module">

  <div class="row">
    <h2>{{businessUnit.Name}} {{'Categories'|translate}}</h2>
    <hr />
  </div>

  <div class="row padding-bottom-10">
    <div class="col-xs-4">
      <label>{{'Business Unit' | translate}} :</label>
      <select [appBUSwitcher]="businessUnit" class="form-control" id="buName" [(ngModel)]="businessUnit">
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">{{ bu.Name }}</option>
      </select>
    </div>
    <div class="col-sm-8" style="text-align:end;padding-top: 40px;">
      <a class="btn btn-link" [routerLink]="['/admin/category-create', {  categoryId: null, buId: businessUnit.Id }]"
        style="cursor: pointer;"
        id="createNewCategory">
        {{'Create New Category' | translate}}
      </a>
    </div>
  </div>

  <table class="table table-hover">
    <thead>
      <tr class="bg-info">
        <td class="col-xs-4"><strong>{{'Category' | translate}}</strong></td>
        <td class="col-xs-1"></td>
        <td class="col-xs-3"></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let c of categories">
        <td class="col-xs-4">{{ c.Name }}</td>
        <td class="col-xs-1 text-center">
          <div *ngIf="c.IsActive" class="label-info padding-left-5 padding-right-5"
            style="border-radius:1em; color: white;">{{'Active' | translate}}</div>
          <div *ngIf="!c.IsActive" class="label-danger padding-left-5 padding-right-5"
            style="border-radius:1em; color: white;">{{'Inactive' | translate}}</div>
        </td>
        <td class="col-xs-3 text-center">
          <a class="btn btn-link" *ngIf="c.IsActive" [routerLink]="['/admin/category-edit', { categoryId: c.Id, buId: c.BusinessUnitID }]"
            style="cursor: pointer;">{{'Edit' | translate}}</a>
          <span *ngIf="c.IsActive">&nbsp;|&nbsp;</span>
          <a class="btn btn-link" *ngIf="c.IsActive" [routerLink]="['/admin/category-delete', { categoryId: c.Id, buId: c.BusinessUnitID }]"
          style="cursor: pointer;">{{'Delete' | translate}}</a>
          <button class="btn btn-link" (click)="activateCategory(c)" *ngIf="!c.IsActive">{{'Activate' | translate}}</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
