<p-dialog
  [header]="'Input description for new Section'|translate"
  [(visible)]="prompt"
  [formGroup]="form"
  [blockScroll]="true"
  [maximizable]="true"
  [modal]="true"
  [style]="{'min-width':'600px'}"
  showEffect="fade"
  appendTo="body"
>

  <div class="form-group" [class.has-error]="form.invalid">
    <label for="new-section-description">{{'New Section Description'|translate}}</label>
    <input
      type="text"
      name="new-section-description"
      formControlName="Description"
      class="form-control"

    />
    <span [hidden]="form.valid" [class.help-block]="form.invalid">
      {{ errorMessage | translate }}
    </span>
  </div>
  <p-footer>
    <button type="button" [disabled]="form.invalid" (click)="saveSection()" class="btn btn-primary">
      {{'Save'|translate}}
    </button>
    <button type="button" (click)="close()" class="btn btn-default">
      {{'Cancel'|translate}}
    </button>
  </p-footer>
</p-dialog>
