import { Component, OnDestroy, OnInit } from '@angular/core';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoadingIndicatorComponent implements OnInit, OnDestroy {
  isLoading = false;
  private subject = new Subject();

  constructor(public loadingIndicatorSvc: LoadingIndicatorService) {}

  ngOnInit(): void {
    this.loadingIndicatorSvc
      .isVisible()
      .pipe(takeUntil(this.subject))
      .subscribe((isLoading: boolean) => {
        this.isLoading = isLoading;
      });
  }

  ngOnDestroy(): void {
    this.subject.next();
    this.subject.complete();
  }
}
