import { DictionaryEntries } from './dictionary-entries';

export const RU: DictionaryEntries = {
  '&': '&',
  '3rdPartyexternal': '3rd Party (внешний)',
  'AllBactionitemsmustbeclosed.': 'Все пункты действий категории «В» должны быть закрыты.',
  ABChecklist: 'AB Проверочный лист',
  ABChecklistfor: 'AB Проверочный лист для',
  ACTIONS: 'ДЕЙСТВИЯ',
  AChecklist: 'A Проверочный лист',
  Action: 'Действие',
  ActionConfirmation: 'Вы уверены, что хотите выполнить это действие?',
  ActionIsRequired: 'Поле действия обязательно',
  ActionItemAssignee: 'Ответственный за действие',
  ActionItemisneededtocompletequestion: 'Для ответа на вопрос необходимо выполнить действие',
  ActionItems: 'Пункты действий',
  ActionWillBeTransferred: 'Действие будет передано в эксплуатацию',
  Actions: 'Действия',
  Actionwillbetransferredtooperations: 'Пункт будет передан в эксплуатацию',
  Activate: 'Активировать',
  Active: 'Активный',
  Add: 'Добавить',
  AddABChecklisttoProject: 'Добавить AB Проверочный лист в Проект',
  AddABChecktoEndDevice: 'Добавить AB Проверку на конечное устройство',
  AddActionItem: 'Добавить пункт действий',
  AddComment: 'Добавить комментарий',
  AddFeedback: 'Добавить отзыв',
  AddGroup: 'Добавить группу',
  AddNew: 'ДОБАВИТЬ НОВЫЙ',
  AddQuestion: 'Добавить вопрос',
  AddRedLining: 'Добавить красные линии',
  AddSignature: 'ДОБАВИТЬ ПОДПИСЬ',
  AddTemplate: 'Добавить шаблон',
  AddUser: 'Добавить пользователя',
  AddVersion: 'Добавить версию',
  AddYellowLining: 'Добавить желтые линии',
  Addactionitem: 'Добавить пункт действий',
  AddtoEAM: 'Добавить в EAM',
  Administration: 'Управление',
  Adminmenu: 'Меню управления',
  Alert: 'Предупреждение',
  Alltherelatedsectionssubsectionsitemswillberemoved: 'Все соответствующие разделы | подраздел(ы) | пункт(ы) будут удалены',
  Alltherelatedsubsectionsitemswillberemoved: 'Все соответствующие подразделы | пункт(ы) будут удалены.',
  SendAllNotificationsToApproversInstruction: 'Если помечено, Финальный Утверждающий будет получать все уведомления',
  AllP3actionmustbeclosedtosubmitforapproval: 'для подачи на утверждение все действия категории А должны быть закрыты',
  AllowAIOwnerToAttach: 'Разрешить владельцу ПД прикреплять',
  AllowAIOwnerToAttachInstruction: 'Если флажок установлен, владелец элемента действия может прикреплять файлы даже при любом статусе ПД, пока ППТБ не закрыт.',
  AllowApproverToSubmitForApproval: 'Позволить последнему утверждающему запросить утверждение',
  AllowLeadsDeleteQuestions: 'Разрешить руководителям удалить вопросы',
  AllowMembersCompletePSSR: 'Разрешить членам завершить ППТБ',
  AllowMembersToCompletePSSR: 'Разрешить членам завершить ППТБ',
  AllowMultiApprover: 'Разрешить многократного утверждающего',
  AllowNAResponses: 'Все ответы Н/П',
  AllowWetSignature: 'Разрешить живую подпись ППТБ',
  Allpages: 'Все страницы',
  Alltherelated: 'Все связанные',
  Alltherelateditemswillberemoved: 'Все связанные элементы будут удалены',
  AngularTemplatedocumentation: 'Angular Template documentation',
  Answer: 'Ответ',
  AnswerallquestionsandsignaturestoCompletethePSSR: 'Для завершения обхода, ответьте на все вопросы и добавьте подписи',
  AnswerallquestionssignaturestoCompletethePSSR: 'Для завершения обхода, ответьте на все вопросы и добавьте подписи',
  Answerwillbecleared: 'Содержимое ответа будет стёрто',
  Approve: 'Утвердить',
  Approvebywetsignature: 'Утверждено живой подписью',
  Approved: 'Утверждено',
  ApprovedOn: 'Утверждено',
  Areyousureyouwanttodelete: 'Вы уверены, что хотите удалить',
  AreyousureyouwanttodeleteABChecklistbelow: 'Вы уверены, что хотите удалить Проверочный лист AB ниже',
  AreyousureyouwanttodeleteSubCategory: 'AreyousureyouwanttodeleteSubCategory',
  Area: 'Участок',
  AreyousureyouwanttodeleteTaskPlanbelow: 'Вы уверены, что хотите удалить План задач ниже ',
  AssignedToLeadFinalApprover: 'Назначено на Руководителя или Последнего Утверждающего',
  Assignedto: 'Назначено',
  Assignedtome: 'Назначено мне',
  Assignee: 'Исполнитель',
  Assignto: 'Назначить',
  Attach: 'Прикрепить',
  AttachedFiles: 'Прикреплённые файлы',
  Attention: 'Внимание',
  AutoLoadImages: 'Автозагруженные изображения',
  Autoclosems: 'Автозакрытие (мс.)',
  Available: 'Доступный',
  BChecklist: 'B Проверочный лист',
  BUAdmin: 'Администратор БП',
  BUAdmins: 'Администраторы БП',
  Back: 'Вернуться',
  BacktoSubCategoryList: 'Вернуться к списку подкатегорий',
  BacktoTaskPlan: 'Вернуться к плану задач',
  BacktoTaskPlanManage: 'Вернуться к управлению планом задач',
  BackToList: 'Вернуться в список ',
  Backto: 'Вернуться к',
  BacktoABChecksList: 'Вернуться к AB Проверочному листу',
  BacktoAreaList: 'Вернуться к списку областей',
  BacktoCategoryList: 'Вернуться к перечню категорий',
  BacktoGroupList: 'Вернуться к списку групп',
  BacktoList: 'Вернуться в список',
  BacktoProjectDetail: 'Вернуться к деталям проекта',
  BacktoProjectList: 'Вернуться к проектному списку',
  Bug: 'Дефект',
  BuildNumber: 'Номер сборки',
  BusinessUnit: 'Бизнес подразделение',
  BusinessUnitisrequired: 'Бизнес-подразделение обязательно',
  BusinessUnits: 'Бизнес-подразделения',
  By: 'Кем',
  ByDefaultProject: 'По умолчанию будет указано «Проект»',
  CCCORR: 'ППТБ / ССС / АЭГ',
  CanOnlyPerformAttachments: 'Может выполнять только вложения',
  Cancel: 'Отменить',
  Canceled: 'Отменено',
  CaptureSignature: 'Взять подпись',
  Categories: 'Категории',
  Category: 'Категория',
  ChangeRequestApproved: 'Запрос на изменение одобрен',
  ChangeRequestDenied: 'Запрос на изменение отклонен',
  Chevron: 'Chevron',
  ChevronCorporationAllrightsreserved: 'Корпорация Шеврон. Все права защищены.',
  ChevronDirectory: 'Справочник Chevron',
  'ChevronDirectory/SharedUsers': 'Chevron Справочник / Общие пользователи',
  ChevronIE: 'Chevron I&E',
  ChevronSFT: 'Chevron SFT',
  Clear: 'Очистить',
  ClearFilter: 'Очистить фильтр',
  ClearQuestion: 'Стереть содержимое вопроса',
  ClearSelection: 'Очистить выборку',
  Close: 'Close',
  CloseProjWarning: 'Вы хотите закрыть этот проект?',
  CloseProject: 'Закрыть проект',
  Closed: 'Закрыто',
  ClosedBy: 'Закрыто',
  ClosedOn: 'Закрыто',
  Code: 'Код',
  ComissionDate: 'Comission Date',
  Comment: 'Комментарий',
  Commentgoeshere: 'Комментарий вставить здесь',
  Commentisneededtocompletequestion: 'для закрытия вопроса необходим комментарий.',
  CompleteTranslation: 'Перевод завершен',
  CompleteTranslationWarning: 'Please translate PSSR Title, Description, Action Item Title and Action Item Comments',
  Completed: 'Завершено',
  CompletedQuestions: 'Заполненные вопросы',
  ConfigureActionItem: 'Составить пункт действия',
  Confirm: 'Утвердить',
  ConfirmDeleteHeader: 'Подтвердить удаление',
  ConfirmDeleteMessage: 'Данный пункт будет удален, хотите продолжить? ',
  ConfirmDeletePSSRFileHeader: 'Подтвердите удаление',
  ConfirmDeletePSSRFileMessage: 'Файл <b>{{fileName}}</b> будет удален навсегда, продолжить?<br/>',
  ConfirmPSSRApprovalHeader: '',
  ConfirmPSSRApprovalMessage: 'Вы уверены, что хотите утвердить?',
  ConfirmPSSRReOpenMessage: 'Вы уверены, что хотите переоткрыть этот ППТБ?',
  ConfirmPSSRReOpenHeader: '',
  ConfirmRejectionHeader: 'Подтвердить отклонение',
  ConfirmRejectionMessage: 'Если вы отклоняете пункт действия, вы не сможете утвердить данный ППТБ. Вам будет необходимо сменить статус на завершенный обход внизу странцы.',
  ConfirmTranslationComplete: 'Вы уверены, что перевод наименования, описания, ПД и комментариев завершены?',
  ConfirmTranslationRequest: 'Вы собираетесь запросить перевод на {{language}} у {{email}}',
  Confirmdelete: 'Подтвердите удаление',
  Confirmtoproceed: 'Пожалуйста, <b>подтвердите</b> для продолжения.',
  ContinueConfirmation: 'Хотите продолжить ?',
  Contractor: 'Contractor',
  CopiedImage: 'Изображение было скопировано',
  Copy: 'Копировать',
  CopyEquipmentstoProject: 'Копировать оборудование в проект',
  CopyImage: 'Копировать изображение',
  CopyImageError: 'Ошибка копирования изображения',
  CopyfromProject: 'Копировать из проекта',
  CopyingImage: 'Изображение копируется...',
  Copyto: 'Скопировать в',
  CostCode: 'Код стоимости',
  Create: 'Создать',
  CreateNewCategory: 'Создать новую категорию',
  CreateNewItem: 'Создать новый элемент',
  CreateBusinessUnit: 'Создать бизнес единицу',
  CreateGroup: 'Создать группу',
  CreateNew: 'Создавать новую',
  CreateNewSubCategory: 'Создать новую подкатегорию',
  CreatePSSR: 'Создать ППТБ',
  CreatePSSRCCC: 'Создать ППТБ / CCC',
  CreatePSSRCCCORR: 'Создать ППТБ / CCC / ORR',
  CreatePSSRORR: 'Создать ППТБ / ORR',
  CreateProject: 'Создать проект',
  CreateSubCategory: 'Создать подкатегорию',
  CreateTraining: 'Создать Обучение',
  CreateUser: 'Создать пользователя',

  CreatedBy: 'Кем создано',
  CreatedOn: 'Создано',
  DBVersion: 'Версия базы данных',
  Date: 'Дата',
  DateCaptured: 'Дата записи',
  Dates: 'Даты',
  Default: 'По умолчанию',
  DefaultSummarySignatureDisclaimer: 'Место для установленной подписи по сводной информации',
  DefaultWalkdownSignatureDisclaimer: 'Место для установленной подписи по обходу',
  Delete: 'Удалить',
  DeleteTaskPlanSection: 'Удалить раздел плана задач',
  DeleteTaskPlanSubSection: 'Удалить подраздел плана задач',
  DeleteActionItem: 'Удалить ПД',
  DeleteDevice: 'Вы согласны удалить выбранное устройство',
  DeleteEndDevice: 'Удалить конечное устройство',
  DeleteEquipment: 'Удалить оборудование',
  DeleteEquipmentTemplateGroup: 'Удалить группу шаблонов оборудования',
  DeleteManufacturerModel: 'Удалить производителя - модель',
  DeleteProject: 'Это действие приведет к удалению этого проекта и всех связанных ППТБ. Вы хотите продолжить?',
  DeleteselectedEquipmentTemplateGroup: 'Согласны ли вы удалить выбранную группу шаблонов оборудования?',
  Description: 'Описание',
  DescriptionScope: 'Описание и объем',
  Descriptioninputwasalreadyused: 'Внесенное наименование (описание) было уже использовано',
  Descriptionisrequired: 'Требуется наименование (описание)',
  DeselectAll: 'Убрать выделение',
  DeviceType: 'Тип устройства',
  DeviceTypeEAM: 'Тип устройства - EAM Имя - EAM Описание',
  Disabled: 'Отключен',
  Discard: 'Отказаться',
  DisplayRichEditor: 'Показать инстурмент для редактирования',
  Documents: 'Документы',
  Download: 'Скачать',
  DownloadError: 'Ошибка скачивания файла',
  DownloadingDocument: 'Загрузка документа...',
  DoyouagreetoDeleteselectedManufacturerModel: 'Согласны ли вы Удалить выбранный производитель - модель',
  DoyouagreetoDeleteselectedequipment: 'Вы согласны Удалить выбранное оборудование',
  DoyouagreetoRemoveselectedparticipant: 'Вы согласны Удалить выбранного участника?',
  Draft: 'Черновик',
  DraftCreatedOn: 'Создано ',
  Dropdown: 'Ниспадающее окно',
  DueDate: 'Срок устранения',
  RequireActionItemDueDateInstruction: 'Если помечено, Сроки для пунктов действий будут обязательными',
  EAMClass: 'EAM Class',
  EAMCriticality: 'EAM Критичность',
  EAMDepartment: 'EAM Отделение',
  EAMDescription: 'EAM Описание',
  EAMName: 'EAM Имя',
  EAMType: 'EAM Тип',
  ENDDEVICESPECIFICABCHECKLISTS: 'END DEVICE SPECIFIC AB CHECKLISTS',
  EQUIPMENT: 'EQUIPMENT',
  Edit: 'Редактировать',
  EditBusinessUnit: 'Внести изменения в бизнес единицу',
  EditGroup: 'Редактировать группу',
  EditImageError: 'Ошибка редактирования изображения',
  EditOrganization: 'Редактировать {{OrganizationLabel}}',
  EditParticipant: 'Редактировать участника',
  EditProject: 'Изменить проект',
  EditRedLining: 'Внести изменения в красные линии',
  EditSubCategory: 'Изменить подкатегорию',
  EditTaskPlanSection: 'Редактировать раздел плана задач',
  EditYellowLining: 'Внести изменения в желтые линии',
  Email: 'Электронный адрес',
  EndDevice: 'Конечное устройство',
  EndDeviceList: 'Список конечных устройств',
  EndDeviceTemplate: 'Шаблон конечного устройства',
  EndDevices: 'Конечные устройства',
  English: 'Английский',
  EnglishTranslationInProgress: 'Перевод на английский в процессе',
  Equipment: 'Оборудование',
  EquipmentGroup: 'Группа оборудования',
  EquipmentList: 'Перечень оборудования',
  EquipmentTemplate: 'Шаблон оборудования',
  EquipmentTemplateGroup: 'Группа шаблонов оборудования',
  EquipmentTemplateGroups: 'Группы шаблонов оборудования',
  EquipmentTemplates: 'Шаблоны оборудования',
  EraseLocalData: 'Стереть локальные данные',
  ErrorImageEditMessage: 'Изображение не удалось открыть для правок',
  ErrorImageMessage: 'Не удалось скопировать изображение',
  ErrorImageUndoMessage: 'Изображение не удалось отменить',
  EventLabel: 'Лейбл с названием события',
  EventName: 'Название события',
  ExistingNonChevronShared: 'Существующие не Chevron/Общие',
  ExportABChecklistsasPDF: 'Экспортировать Проверочный лист AB в формате PDF',
  ExportExcel: 'Экспортировать в Excel',
  Facility: 'Объект',
  FacilityHierarchy: 'Иерархия объекта',
  FacilityNo: 'Объект №',
  FacilityOperationTeam: 'Группа эксплуатации (производственного) объекта',
  Factory: 'Предприятие',
  Featurerequest: 'Запрос желаемой функциональности',
  Feedback: 'Обратная связь',
  FeedbackScreen: 'Страница обратной связи',
  Fieldisrequired: 'Обязательное поле',
  FileDuplicate: 'Документ, который вы пытаетесь загрузить, уже существует. Нажмите кнопку Отменить, чтобы отменить загрузку или нажмите на кнопку Загрузить, чтобы заменить существующий документ',
  Files: 'Файлы',
  FilesUploaded: 'Файлы успешно загружены',
  FilesUploadedWithWarning: 'Некоторые файлы были успешно загружены. Пожалуйста, попытайтесь снова.',
  FillAndSave: 'Заполните поля, отмеченные как обязательные и нажмите Сохранить.',
  Filter: 'Фильтр',
  FilterKMSListbyStatus: '"Фильтр": KMS Список по статусу',
  FilterNumberofKMSItemsreturned: '"Фильтр": Имена KMS возвращенных элементов',
  FilteredActionItemsInitialWarning: 'Страница фильтруется по умолчанию в зависимости от текущего пользователя и открытого статуса',
  FinalApproval: 'Финальное Утверждение',
  FinalApprover: 'Последний Утверждающий',
  RequireFinalApproverBeforeInitiationInstruction: 'Если помечено, для инициации ППТБ потребуется Финальный Утверждающий',
  FinalApproverPresent: 'Если указано, что Утверждающий присутствует, то он может проставить свою подпись на устройстве, если нет, следует подать на утверждение и синхронизировать.',
  FinalApproverSignature: 'Подпись Финального утверждающего',
  FirstName: 'Имя',
  FoundImages: 'Найденные изображения',
  FullName: 'Full Name',
  GENERALINFORMATION: 'ОБЩАЯ ИНФОРМАЦИЯ',
  GeneralInformation: 'Общая информация',
  GeneratePSSR: 'Создать ППТБ',
  GetEAMLoadsheet: 'Get EAM Loadsheet',
  GetEquipmentList: 'Get Equipment List',
  GoBack: 'Вернуться назад',
  Group: 'Группа',
  GroupName: 'Имя группы',
  GroupNames: 'Имена групп',
  UserGroups: 'Группы пользователей',
  Hi: 'Здравствуйте',
  HideCode: 'Скрыть код',
  HierarchyLabel1: 'Иерархия лейбл 1',
  HierarchyLabel2: 'Иерархия лейбл 2',
  HierarchyLabel3: 'Иерархия лейбл 3',
  HierarchyLabel3show: 'Иерархическая метка 3 (показать)',
  I: 'Я',
  ID: 'Идентификатор',
  Iagree: 'Я согласен',
  IagreethatallPSSRchecklistitems: 'Я согласен, что все пункты проверочных листов и все пункты действий ППТБ, необходимые для безопасного пуска, записаны точно',
  Idontagree: 'Я не согласен',
  Image: 'Изображение',
  ImageCaption: 'Заголовок',
  ImageCapture: 'Получить изображение',
  Images: 'Изображения',
  ImplementationInProgress: 'Выполнение в процессе',
  ImplementationInProgressEdit: 'Выполнение в процессе Править',
  ImportSharedUser: 'Импорт общего пользователя',
  InProgress: 'В процессе',
  Inactive: 'Неактивный',
  IncludeProjectTeam: 'Включить членов команды проекта',
  Info: 'Информация',
  Initiate: 'Инициализировать',
  Initiated: 'Инициировано',
  InitiatedOn: 'Инициировано',
  Input: 'Внесение данных',
  InputSharedUserEmail: 'Введите e-mail общего пользователя',
  InputdescriptionfornewSection: 'Внесите наименование (описание) новой секции',
  Instructions: 'Инструкции',
  InternalError: 'Внутренняя ошибка',
  IsAdmin: 'Администратор',
  IsExternalUser: 'Внешний пользователь',
  IsSectionOrderEnabled: 'Включить настраиваемый порядок разделов',
  ItemCode: 'Код элемента',
  ItemCodeMsg: '- Код элемента:  <b>',
  ItemDescriptionMsg: '</b><br/>   - Описание элемента: <b>',
  Itemsselected: 'Выбранные пункты',
  Itemwillbetransferredtooperations: 'Пункт будет передан в эксплуатацию',
  Iteration: 'Iteration',
  KMSInstruction: 'Поиск по конкретному номеру КЗИ (слева) или выбрать из ниспадающего списка КЗИ (справа)',
  Kazakh: 'Казахский',
  LOCATION: 'Местоположение',
  Language: 'Язык',
  LastName: 'Фамилия',
  LimitAccessToAIOwner: 'Ограничить доступ для владельца ПД',
  LimitAccessToAIOwnerInstruction: 'Если флажок установлен, не позволяет Руководителю Пункта Действия изменять описание ПД, дату выполнения или удалять ПД. ',
  AllowTeamLeadToAttach: 'Разрешить руководителю прикреплять и изменять ПД',
  AllowTeamLeadToAttachInstruction: 'Если флажок установлен, Руководитель после статуса Ожидание Утверждения может только прикреплять файлы, менять ответственного, статус и комментарии.',
  Links: 'Ссылки',
  List: 'Список',
  Load: 'Загрузить',
  LoadImages: 'Загрузить изображения',
  LoadNewQAQC: 'Загрузить новые QA QC элементы',
  Loading: 'Загрузка',
  LocaldbID: 'Идентификатор локальной базы данных',
  Location: 'Местоположение',
  LogScreen: 'Экран записей',
  Login: 'Войти',
  LoginExpiresOn: 'Логин истекает',
  LoginProfile: 'Войти в Профайл',
  Logout: 'Выйти',
  LookupbyPasscode: 'Поиск по паролю',
  Loose: 'Loose',
  MOC: 'КЗИ',
  'MOC#': 'КЗИ №',
  MOCWO: '№ КЗИ или рабочей заявки',
  MOCWOProjectNumberRestrictionEnabled: 'Разрешить ограничение номера проекта рабочей заявки КЗИ',
  Manage: 'Управление',
  ManageContentof: 'Управление содержимым',
  ManagesEquipments: 'Управление оборудованием',
  Mandatory: 'Обязательный',
  Manufacturer: 'Производитель - Модель',
  ManufacturerModel: 'Производитель - Модель',
  ManufacturerModelfor: 'Производитель - Модель для',
  MarkasClosed: 'Пометить как закрытый',
  Message: 'Сообщение',
  Milestone: 'Ключевые этапы обеспечения и контроля качества',
  MilestoneAndWorkOrders: 'WorkOrders',
  MilestoneWorkOrder: 'Рабочая заявка ключевого этапа обеспечения и контроля качества',
  MilestoneWorkOrders: 'Рабочие заявки ключевого этапа обеспечения и контроля качества',
  Milestones: 'Ключевой milestone обеспечения и контроля качества',
  MilestonesWorkOrdersNoavailable: 'Не доступны milestone\'s WO из QAQC',
  MobileAppVersions: 'Версии мобильного приложения',
  MobileFeature: 'Функция доступна только на мобильном',
  MobilePSSR: 'Мобильная ППТБ',
  Model: 'Model',
  Multiple: 'Multiple',
  MultipleFinalApprovers: 'Если помечено, позволяет иметь несколько Финальных Утверждающих для ППТБ',
  Mustbecompletedbeforetostartup: 'Необходимо выполнить до пуска',
  NA: 'Н/П (не применимо)',
  Name: 'Имя',
  New: 'Новый',
  NewNonChevron: 'Новый не Chevron',
  NewOrganization: 'Новая организация',
  NewPSSR: 'Новый ППТБ',
  NewPSSRCCC: 'Новый ППТБ / CCC',
  NewPSSRCCCORR: 'Новый ППТБ / CCC / ORR ',
  NewPSSRORR: 'Новый ППТБ / ORR',
  NewSectionDescription: 'Наименование (описание) новой секции',
  No: 'Нет',
  Nomobileappversionsyet: 'Версий мобильного приложения пока нет',
  NoActionItems: 'Пунктов действий нет',
  Nofeedbackyet: 'Пока нет отзывов',
  Nomilestones: 'Не доступны milestones/workorders из QAQC',
  NonMOC: 'Без КЗИ',
  NotRegistered: 'Не зарегистрирован',
  NotVisibleMobile: 'Этот раздел не отобразится на мобильных устройствах, пока пользователь не синхронизируется',
  Notauthorized: 'Не авторизовано',
  Note: 'Примечание',
  Notfound: 'Не найдено',
  Notifications: 'Уведомления',
  OPEN: 'ОТКРЫТЬ',
  OPSignature: 'Подпись ОЭ',
  ORR: 'АЭГ',
  OnHold: 'On Hold',
  OnlyFinalApprovercanApprovePSSR: 'Только последний утверждающий может утвердить ППТБ',
  OnlyPSSRTeamLeadClosePSSR: 'Только руководитель группы ППТБ может закрыть ППТБ',
  OnlyPSSRTeamLeadcanClosePSSR: 'Только Руководитель группы ППТБ может закрыть ППТБ',
  OnlyPSSRTeamLeadcanInitiatePSSR: 'Только Руководитель группы ППТБ может инициировать ППТБ',
  OnlyPSSRTeamLeadcanSubmitforPendingApproval: 'Только руководитель группы ППТБ может подать на ожидание утверждения',
  OnlyTeamLeadorSectionLeadcananswerthequestions: 'На вопросы может ответить только Руководитель группы или Начальник секции.',
  Open: 'Открыть',
  OpenStatus: 'Открыть',
  OpenError: 'Ошибка открытия файла',
  OpenErrorMsg: 'Не удалось открыть документ',
  OpeningDialog: 'Открывается диалог',
  OpeningImage: 'Изображение открывается...',
  OperationTeam: 'Отдел эксплуатации',
  OperationsRepresentative: 'Представитель Эксплуатации',
  Optional: 'Необязательный',
  Org: 'Подразделение',
  OrgLabel: 'Метка ППТБ',
  Organization: 'Организация/подразделение',
  OrganizationAdmin: 'Администратор подразделения',
  OrganizationLabel: 'Лейбл организации',
  Organizationisrequired: 'Организация обязательна',
  Organizations: 'Подразделения',
  Other: 'Другое',
  OtherOptions: 'Другие опции',
  OutstandingQuestions: 'Незаполненные вопросы',
  P3ActionItemsMustBeClosed: 'Необходимо закрыть все замечания категории {{P3Label}}',
  P3Label: 'Лейбл Р3',
  P4ActionItemsMustBeClosed: 'Необходимо закрыть все замечания категории {{P4Label}}',
  P4Label: 'Лейбл Р4',
  P5Label: 'Лейбл Р5',
  PARTICIPANTS: 'УЧАСТНИКИ',
  PDFPreview: 'Предварительный просмотр PDF',
  PIDVerificationRedLining: 'Проверка P&ID (красные линии)',
  PIDVerificationYellowLining: 'Проверка P&ID (жёлтые линии)',
  PROJECTSPECIFICABCHECKLISTS: 'PROJECT SPECIFIC AB CHECKLISTS',
  PROJECTSTATUS: 'PROJECT STATUS',
  PSSR: 'ППТБ',
  PSSRID: 'ППТБ ID',
  PSSRCCC: 'ППТБ / CCC',
  PSSRInitiatorGroup: 'Группа инициаторов ППТБ',
  PSSRLabel: 'Лейбл ППТБ',
  PSSRMembers: 'Участники ППТБ',
  PSSRORR: 'ППТБ / ORR',
  PSSRSections: 'Разделы ППТБ',
  PSSRStatus: 'Статус ППТБ',
  PSSRTeam: 'Группа ППТБ',
  PSSRTeamLead: 'Руководитель группы ППТБ',
  PSSRTemplates: 'Шаблоны ППТБ',
  PSSRTemplateisrequired: 'Шаблон ППТБ обязателен',
  PSSRTitle: 'Название ППТБ',
  PSSRType: 'Тип ППТБ',
  PSSRTypes: 'Виды ППТБ',
  PSSRmusthaveaFinalApprovertocompleteWalkdown: 'Для выполнения обхода, в ППТБ должен быть последний утверждающий',
  PSSRs: 'ППТБ',
  NavigateToMainPage: '{{PssrTitle}}',
  Page: 'Page',
  Search: 'Поиск',
  Participants: 'Участники',
  ParticipantsinBlueparticipatedinWalkdown: 'Выделенные синим лица участвовали в обходе',
  ParticipatedinWalkdown: 'Приняли участие в обходе',
  ParticipatedinWalkthrough: 'Приняли участие в обходе',
  PendingApproval: 'Ожидает утверждения',
  PendingApprovalSubmittedOn: 'Подано на утверждение ',
  Photos: 'Фотографии',
  PickError: 'Ошибка получения файла',
  PleaseNotify: 'Please notify participants to sync their mobile devices before continuing with their activities',
  PleaseclickSave: 'Пожалуйста нажмите Сохранить',
  Pleasecorrectpageaddress: 'Please correct page address or contact support if you believe this url is correct',
  Pleasefillupthefields: 'Пожалуйста, заполните поля',
  Pleasefillupthefieldsthataremarkedrequired: 'Пожалуйста, заполните поля, помеченные как обязательные',
  PleasegotoSettings: 'Перейдите в раздел \'Настройки - уведомления – Мобильная ППТБ\', чтобы разрешить уведомления',
  Pleaseselectanyoption: 'Пожалуйста выберите любую опцию, применимую к количеству изменений и шаблонов',
  Pleaseselectanyoptionthatapplytothescopeofchangeandtemplate: 'Выберите любой вариант, который относится к объему изменения и шаблону',
  'Post-WalkdownActionItems': 'Пункты зарегистрированные после обхода',
  PostWalkdown: 'После обхода',
  PreStartupSafetyReview: 'Предпусковая проверка по ТБ',
  Present: 'Присутствовал',
  PrimaryCategory: 'Основная категория',
  Print: 'Печатать',
  PrinttoPDF: 'Печать в PDF',
  Priority: 'Приоритет',
  ProcessActivated: 'Процесс активирован',
  Progress: 'Прогресс',
  Project: 'Проект',
  ProjectName: 'Имя проекта',
  ProjectTeamWillComplete: 'Пректная группа выполнит после пуска',
  Projects: 'Проекты',
  ProjectsList: 'проектному списку',
  Projectteamwillcompleteafterstartup: 'Пункт будет выполнен после пуска',
  ProvidedBy: 'Предоставлено',
  PullFromKMS: 'Извлечь из списка КЗУ',
  PushNotification: 'Пуш-уведомление',
  Question: 'Вопрос',
  RESET: 'СБРОС',
  ReOpen: 'Открыть заново',
  ReadyToStartUp: 'Готов к запуску',
  ReceivePushNotification: 'Получить пуш-уведомление',
  Receivedfrom: 'Получено от',
  Recordwillbepermanentlyremovedcontinue: 'Record will be permanently removed, continue?<br/>',
  RedirectURL: 'Перенаправить на веб адрес',
  ReferenceImage: 'Справочное изображение',
  Reject: 'Отклонить',
  Rejectionreason: 'Причина отклонения',
  ReleaseDate: 'Release Date',
  Remove: 'Удалить',
  RemoveLocalData: 'Удалить локальные данные',
  RemoveLog: 'Удалить запись в журнале',
  RemoveParticipant: 'Remove Participant',
  RemoveRedLining: 'Убрать красные линии',
  RemoveSection: 'Удалить раздел',
  RemoveSignature: 'удалить подпись',
  RemoveYellowLining: 'Убрать желтые линии',
  RemovingWarning: '<i>Пункты действий</i>, <i>комментарии</i> и <i>изображения</i> будут <u>удалены</u> из этого вопроса.',
  Reopen: 'Открыть заново',
  RequestAccess: 'Запрос доступа',
  RequestTranslation: 'Запросить перевод',
  RequestedBy: 'Кто запросил',
  RequestedOn: 'Дата запроса',
  Requestedby: 'Заявку отправил',
  RequireActionItemDueDate: 'Обязательный срок выполнения пункта действия',
  RequireFinalApproverBeforeInitiation: 'Требуется определение последнего утверждающего до инциации',
  RequiredField: 'Требуется заполнение данного поля',
  RequiresApprovalForAccess: 'Для доступа требуется утверждение',
  RequiresShowProject: '*Требует активации функции «показать проект»',
  ResetABChecklistsforEntireProject: 'Reset AB Checklists for Entire Project',
  ResetABChecklist: 'Reset AB Checklist',
  ResetData: 'Сбросить Данные',
  ResetSession: 'Сбросить сессию',
  ResolutionImages: 'Размер изображений',
  RestorePssr: 'Восстановить ППТБ',
  RestrictApproverSignatureOnLeadsDevice: 'Ограничить подпись утвреждающего на приборе руководителя',
  ReturnTeamLead: 'Возвратить руководителю группы ППТБ',
  ReviewInProgress: 'Review In Progress',
  Role: 'Роль',
  RolesChangeWarning: 'Роли и изменения будут отражены после синхронизации',
  Russian: 'Русский',
  RussianTranslationRequested: 'Запрошен перевод на русский',
  SAVESIGNATURE: 'СОХРАНИТЬ ПОДПИСЬ',
  Save: 'Сохранить',
  SaveChanges: 'Сохранить изменения',
  SaveGoBack: 'Сохранить и вернуться назад',
  SaveImage: 'Сохранить изображение',
  SaveSignature: 'Сохранить подпись',
  SavingFile: 'Файл сохраняется',
  SavingImage: 'Изображение сохраняется',
  ScheduledEmail: 'Активировать рассылку эл.сообщения по графику (каждый понедельник)',
  Screen: 'Экран',
  SearchMOCNumber: 'Искать MOC номер',
  SecondaryCategory: 'Второстепенная категория',
  SectionType: 'Тип сейкции',
  SectionLead: 'Ответственный',
  Sections: 'Разделы',
  ShowSectionsCollapsedInstruction: 'Если помечено, во время правки разделы ПТТБ будут показаны свернутыми',
  Select: 'Выбрать',
  SelectFileError: 'Ошибка выбора файла',
  SelectFromKMSMOCList: 'Выберать из KMS MOC списк',
  SelectLead: 'Выбрать Руководителя',
  SelectOption: 'Выбрать вариант',
  SelectParticipant: 'Выберите участника',
  SelectResponse: 'Выберите ответ',
  SelectaDrawing: 'Выбрать чертеж',
  SelectfromQAQC: 'Выберите из QAQC',
  Selectpages: 'Выберите страницы',
  Selectthepagestoconverttoimages: 'Выбрать страницы для конвертирования в изображение',
  Selectuserfor: 'Выбрать пользователя для',
  SelectUser: 'Выберите пользователя',
  Send: 'Отправить',
  SendAllNotificationsToApprovers: 'Утверждающие получают все уведомления ППТБ',
  SendEmailReminder: 'Отправить напоминание по почте',
  SerialNumber: 'Серийный номер',
  SerialNumberRequired: 'Требуется серийный номер',
  Settings: 'Настройки',
  Shared: 'Общий',
  Shipment: 'Отгрузка',
  ShouldAddCommentToQuestion: 'Требуется комментарий для вопроса с NA',
  Show: 'Показать',
  ShowCCC: 'Показать ССС',
  ShowDeletedPSSR: 'Показать удаленные ППТБ',
  ShowDeletedPSSRCCC: 'Показать удаленный ППТБ / CCC',
  ShowDeletedPSSRCCCORR: 'Показать удаленный ППТБ / CCC / ORR',
  ShowDeletedPSSRORR: 'Показать удаленный ППТБ / ORR',
  ShowKMS: 'Показать КЗУ',
  ShowORR: 'Показать НЭГ',
  ShowP5: 'Показать P5',
  ShowProject: 'Показать проект',
  ShowProjectInput: 'Показать внесеннные данные проекта ',
  ShowProjectInputAsDropdown: 'Показать внесенные данные проекта в ниспадающем окне',
  ShowPssrType: 'Показать вид ППТБ',
  ShowQAQC: 'Показать обеспечение и контроль качества',
  ShowSectionsCollapsed: 'Показать свернутые разделы',
  ShowVerbalApproval: 'Показать устное утверждение',
  SignatoryName: 'Имя подписавшего',
  Signature: 'Подпись',
  SignatureCapture: 'Сбор подписей',
  Signatures: 'Подпись',
  SignedBy: 'Подписано',
  SignedOnBehalfOf: 'Подписано от имени',
  Skidded: 'Skidded',
  SortBy: 'Сортировать по',
  SortChangesApplied: 'Сортировка изменений примениться после сохранения',
  SortOrder: 'EAM Порядок сортировки',
  Startaddingparticipants: 'Добавить участников',
  Started: 'В процессе с',
  Status: 'Статус',
  StatusIsRequired: 'Поле статуса обязательно',
  SubArea: 'Под-участок',
  SubCategories: 'Под-категории',
  SubSection: 'Подраздел',
  Subcategories: 'Подкатегории',
  Submit: 'Подать',
  SubmitforApproval: 'Подать на утверждение',
  Submitforapproval: 'Подать на утверждение',
  Subsection: 'Подраздел',
  SubsectionDescription: 'Описание подраздела',
  Summary: 'Краткая сводка',
  SummarySignature: 'Сводная подпись',
  Sync: 'Синхронизировать',
  SysAdmin: 'Системный администратор',
  SystemAdministrators: 'Системные администраторы',
  TEAMMEMBERS: 'Участники команды',
  Tag: 'Тег',
  TagCode: 'Код Тега',
  TagNo: 'Номер Тега',
  Takeaphoto: 'Сфотографировать',
  Tank: 'Резервуар',
  TaskPlanSections: 'Разделы плана задач',
  TaskPlan: 'План задач',
  TaskPlanNoteHeader: '(Примечание кодовое название должно быть уникальным и его нельзя изменить после создания плана задания',
  TeamLead: 'Руководитель группы',
  Template: 'Шаблон',
  Test: 'Тест',
  TestforDeleteDBwithoutApprover: 'Тест на удаление БД без Утверждающего',
  TheTaskPlanfieldisrequired: 'Поле План задач обязательно для заполнения.',
  Thereareexistingitemsusingit: 'Есть существующие элементы, использующие его',
  Therewasanerrorprocessingtryagain: 'При обработке вашего запроса произошла ошибка. Повторите попытку позже или обратитесь в службу поддержки.',
  Thisfieldisrequired: 'Требуется заполнение данного поля',
  Thisisnotareversiblechange: 'Это не обратимое изменение',
  TimeCaptured: 'Время записи',
  Title: 'Заголовок',
  Titleisrequired: 'Заголовок обязателен',
  Togglenavigation: 'Переключить навигацию',
  TotalQuestions: 'Общее количество вопросов',
  TranslationCompleted: 'Перевод выполнен',
  TranslationHistory: 'Сведения о переводе',
  TranslationInProgress: 'Перевод  в процессе',
  TranslationRequested: 'Заявка на перевод отправлена',
  Translator: 'Переводчик',
  Type: 'Тип',
  TypeOtherisonlyfornon: 'Type "Other" is only for non-Chevron users, use of this type may cause duplicate user data / Тип "Другое" предназначен только для пользователей, не являющихся пользователями Chevron. Использование этого типа может привести к дублированию пользовательских данных',
  Undo: 'Отменить',
  UndoImageError: 'Отменить ошибку изображения',
  Unit: 'Установка',
  Update: 'Обновить',
  UpdatedBy: 'Обновлено',
  UpdatedByUpdatedDate: 'Обновлено/ Дата обновления',
  UpdatedDate: 'Дата обновления',
  Upload: 'Загрузить',
  UploadDocError: 'Ошибка загрузки документа',
  UploadDocErrorMsg: 'Не удалось сохранить документ',
  UploadDocument: 'Загрузить документ',
  UploadDocuments: 'Загрузить документы',
  UploadImages: 'Загрузить Изображения',
  UploadMsg: 'Новый документ был сохранен',
  Uploadaphoto: 'Загрузить фото',
  User: 'Пользователь',
  User1: 'User 1',
  User2: 'User 2',
  User3: 'User 3',
  User4: 'User 4',
  UserAlreadyAdded: 'Пользователь уже добавлен',
  UserGuide: 'Руководство пользователя',
  UserType: 'Тип пользователя',
  Users: 'Пользователи',
  Usersavedsuccessfully: 'Пользователь успешно сохранен',
  Vendor: 'Поставщик',
  VerbalApproval: 'Устное утверждение',
  VerbalApprovalReceivedBy: 'Устное утверждение получил',
  VerbalApprovalforStartupOn: 'Устное утверждение на пуск',
  Version: 'Версия',
  ViewImage: 'Просмотр изображения',
  ViewItems: 'Просмотреть пункты',
  WO: 'Рабочая заявка',
  WTNotPresentParticipantsWarning: 'Следующие участники не были отмечены как присутствующие',
  WTParticipantsWithoutSignatureWarning: 'Следующие участники не имеют подписи',
  WTRestrictParticipantsModificationWarning: 'После завершения обхода нельзя менять участников',
  Walkdown: 'Обход',
  WalkdownSignature: 'Подпись обхода',
  WalkdownComplete: 'Обход завершен',
  WalkdownCompletedOn: 'Обход завершен',
  Warning: 'Внимание',
  WebLink: 'интернет-ссылка',
  WorkOrder: 'Рабочие заявки по обеспечению и контролю качества',
  WorkOrders: 'Рабочая заявка по обеспечению и контролю качества',
  WorkOrdersNoavailable: 'Нет доступных milestones/workorders из QAQC',
  XCoordinate: 'X координата',
  YCoordinate: 'Y координата',
  Yes: 'Да',
  Youareabouttologout: 'Вы собираетесь выйти из системы. Это приведет к удалению локальных данных на этом устройстве. Продолжить?',
  Youareabouttorequestaccessfor: 'Вы собираетесь запросить доступ к <strong>{{Name}}</strong>, Вы хотите продолжить?',
  Youareloggedinas: 'Вы вошли как',
  Youdonothaveaccesstothispage: 'У вас нет доступа к этой странице',
  Yourprofile: 'Ваш профиль',
  Youshouldnothaveemptysections: 'У вас не должно быть пустых разделов',
  approvethisPSSRreadyforstartup: 'подтверждаю, что данная ППТБ готова к пуску',
  cannotberemoved: 'не может быть удален',
  cost255: 'Код стоимости (не более 255 символов)',
  desc5000: 'Описание (не более 5000 символов)',
  description1920: 'Описание (не более 5000 символов)',
  description500: 'Описание (не более 5000 символов)',
  device150: 'Имя типа устройства (не более 5000 символов)',
  donothaveaccesstothePSSRapplication: 'нет доступа к приложению ППТБ',
  eam50: 'EAM Тип (не более 50 символов)',
  eamclass80: 'EAM Сласс (max 80 characters)',
  eamcritical50: 'EAM Критичность (max 50 characters)',
  eamdep255: 'EAM Описание (не более 255 символов)',
  eamdesc80: 'EAM Описание (не более 88 символов)',
  eamname80: 'EAM Имя (не более 80 символов)',
  fromTaskPlan: 'из плана задач',
  fromTaskPlanSection: 'из раздела плана задач',
  group155: 'Имя группы (не более 155 символов)',
  isrequired: 'обязательное поле',
  list: 'list',
  manufacturer250: 'Название производителя (не более 250 символов)',
  model250: 'Модель (не более 250 символов)',
  noticetousers: 'уведомление для пользователей',
  of10: 'из 10',
  of150: 'из 150',
  of155: 'из 155',
  of250: 'из 250',
  of255: 'из 255',
  of50: 'из 50',
  of500: 'из 500',
  of80: 'из 80',
  optional: 'необязательный',
  select1milestone: 'Выберите 1 этап, чтобы просмотреть рабочие задания в рамках этого этапа.',
  tagcode10: 'Код тега (максимум 10 символов)',
  tagno10: 'Номер тега (максимум 10 символов)',
  togglesearch: 'Переключить поиск ',
  typeemail: 'введите новый e-mail...',
  typelastname: 'введите новую фамилию пользователя...',
  typenewuser: 'введите новое имя пользователя...',
  typeusercainame: 'введите cai, имя или e-mail...',
  willberemoved: 'будет удален',
  willbeungrouped: 'Если к этой группе присоединено какое-либо оборудование, оно будет разгруппировано.',
  x255: 'X Coordinate (max 255 characters)',
  y255: 'Y Coordinate (max 255 characters)',
  yourAccessRequestisinProgress: 'ваш запрос на доступ находится в обработке',
  youwanttoproceed: 'вы хотите продолжить',
  Unabletocompleteoperation: 'Не удалось завершить операцию',
  SaveCompleted: 'Сохранить завершено',
  Itemhasbeenaddedsuccessfully: 'Товар успешно добавлен',
  ShowProjectInputInstruction: '*Отображает раскрывающийся список/ввод проекта',
  ShowQAQCInstruction: '*Включает QAQC Модуль в модуле проекта. Требуется сначала включить Показать Проект.',
  ShowKMSInstruction: '*Включает "Вытащить из KMS" кнопку при создании нового ППТБ',
  AllowWetSignatureInstruction: '*Позволяет операционному представителю утверждать ППТБ со статусом «Ожидает утверждения». Отображает кнопку «Подтвердить мокрой подписью»',
  ShowVerbalApprovalInstruction: '*Отобразить ввод в мобильном приложении, чтобы вручную написать, кто утверждает. Это не влечет за собой никаких действий. Может выполняться руководителем или утверждающим лицом.',
  ScheduledEmailInstruction: '*Каждый понедельник в 8:00 по CST для всех пользователей с ожидающими пунктами действиями отправляется электронное письмо.',
  ShowCCCInstruction: '*Отображает "CCC" в некоторых заголовках/названиях/ярлыках (Construction Complete Certificate)',
  ShowORRInstruction: '*Отображает "ORR" в некоторых заголовках/названиях/ярлыках (Operation Readines Review)',
  ShowPssrTypeInstruction: '*Отображает список "Типы ППТБ" (ниже)',
  AllowNAResponsesInstruction: '*Если отключено: вопрос в ППТБ, на который дан ответ NA, потребует комментария или элемента действия, что бы считаться выполненным.',
  PssrInstructionsInstruction: '*Отображает общие инструкции для ППТБ',
  AllowLeadsDeleteQuestionsInstruction: '*Позволяет руководителям удалять вопросы для ППТБ со статусом Draft или Initiated.',
  EnableAllowMembersCompletePSSRInstruction: '*Если флажок установлен, любой член команды может отвечать на вопросы, подписывать вопросы и создавать действия до тех пор, пока они не будут утверждены.',
  MOCWOProjectNumberRestrictionEnabledInstruction: '*Если включено: MOC и WO необходимы для создания ППТБ.',
  AllowMultiApproverInstruction: '*Если включено: ППТБ может иметь более одного утверждающего',
  RestrictApproverSignatureOnLeadsDeviceInstruction: '*Если включено: руководитель ППТБ не может входить в систему от имени утверждающего в мобильном приложении.',
  RequiresApprovalForAccessInstruction: '*Если этот флаг установлен, пользователь будет ждать, пока администратор подразделения предоставит доступ. Если отключено, пользователь получит мгновенный доступ, выбрав это подразделение',
  AllowApproverToSubmitForApprovalInstruction: '*Если флажок установлен, утверждающий сможет увидеть кнопку «Отправить на утверждение» в ППТБ.',
  OrganizationCannotBeEmpty: '{{OrganizationLabel}} не может быть пустым',
  OrganizationSuccessfullyAdded: '{{OrganizationLabel}} Успешно добавлено',

  MOCImportedSuccessfully: 'MOC Импортировано успешно',
  GeneralInformationSavedSuccessfully: 'Общая информация успешно сохранена',
  Therecordenteredalreadyexists: 'Введенная запись уже существует',
  SelectuserforTranslationRole: 'Выберите пользователя для роли переводчика',
  TranslatetoLanguage: 'Перевести на язык',
  Selecttranslator: 'Выбрать переводчика',
  PresentParticipantsInstruction: 'Выделенные <span class="box participated">&nbsp;синим&nbsp;</span> лица участвовали в обходе',
  PssrParticipantAddedSuccessMessage: 'Пользователь успешно добавлен к ППТБ',
  RoleAlreadyAddedWarningMessage: '{{RoleLabel}} Роль уже добавлена',
  MaxParticipantsPerBatchInstruction: 'Одновременно можно добавить только 5 пользователей',
  MaxParticipantsPerBatchWarningMessage: 'Достигнуто ограничение на 5 пользователей. Выберите добавить',
  ParticipantAlreadyParticipatedWarningMessage: 'Этот пользователь уже является участником этого ППТБ',
  ManageSectionUsersSyncWarning: 'Этот раздел не отображается на мобильных устройствах, пока пользователь не выполнит синхронизацию. Cообщите участникам о необходимости синхронизации своих мобильных устройств, прежде чем продолжить свою деятельность.',
  NotFoundComponentTitle: 'Не найдено',
  NotFoundComponentDescription: 'Исправьте адрес страницы или свяжитесь со службой поддержки, если вы считаете, что этот URL правильный.',
  UnauthorizedTitle: 'Не авторизован',
  UnauthorizedDescription: 'У вас нет доступа к этой странице.',
  BUisrequired: 'Бизнес подразделение обязательно к заполнению',
  SectionLeadWasAlreadyAddedWarningMessage: '{{UserName}} уже является руководителем этого раздела',
  SectionLeadAddedErrorMessage: 'Произошла ошибка при добавлении пользователя',
  SectionLeadRemovedErrorMessage: 'Произошла ошибка при удалении пользователя',
  AddResponseErrorMessage: 'Произошла ошибка при создании вопроса',
  ConfirmRemoveSectionTitle: 'Удалить раздел',
  ConfirmRemoveSectionDescription: 'Вы уверены, что хотите выполнить это действие?',
  MilestoneWorkOrdersWarningMessage: 'Выберите 1 этап, чтобы просмотреть рабочие задания в рамках этого этапа.',
  QAQCAddSectionLeadToPssr: 'Назначить руководителей раздела',
  LoadBusinessUnitsErrorMessage: 'Ошибка при получении бизнес подразделения',
  LoadOrganizationsErrorMessage: 'Ошибка при получении организаций',
  AddProjectErrorMessage: 'Ошибка добавления нового {{EventLabel}}',
  AddProjectSuccessMessage: 'Проект успешно добавлен',
  LoadQAQCErrorMessage: 'Ошибка получения {{EventLabel}} из QAQC',
  SaveQAQCAlreadyExistsWarningMessage: '{{EventLabel}} уже существует',
  SaveQAQCSuccessMessage: '{{EventLabel}} добавлен успешно',
  CompletedTotalPSSRs: 'Завершено/Всего ППТБ',
  ProjectUpdatedSucessfully: 'Проект успешно обновлен',
  ConfirmDeleteProjectTitle: 'Подтвердите удаление проекта',
  ConfirmDeleteProjectWithPssrsDescription: 'Следующее действие может удалить проект и связанные ППТБ. Хотите ли вы продолжить?',
  ConfirmDeleteProjectWithoutPssrsDescription: 'Хотите продолжить?',
  ConfirmInitiateProjectPssrsTitle: 'Инициировать все ППТБ?',
  ConfirmInitiateProjectPssrsDescription: 'Вы хотите инициировать все ППТБ?',
  UnableLoadQAQCMilestonesMessage: 'Не удалось связаться с QAQC для получения milestones. Повторите попытку позже',
  UnableLoadQAQCWorkOrdersMessage: 'Не удалось связаться с QAQC для получения заказов на работу. Повторите попытку позже',
  LoadProjectDetailsErrorMessage: 'Не удалось загрузить сведения о проекте.',
  QAQCItemsAreUpToDateMessage: 'QAQC Элементы обновлены',
  QAQCNewItemInputWarning: 'Пожалуйста, выберите элемент из списка {{qaqcLabel}} списка и выберите иерархию и/или шаблон',
  ConfirmCloseProjectTitle: 'Закрыть проект',
  ConfirmCloseProjectDescription: 'Вы хотите закрыть этот проект?',
  DownloadActionItemsExcelReportFailureMessage: 'Не удалось загрузить файл excel',
  ConfirmDeleteAreaTitle: 'Вы уверены, что хотите удалить {{hierarchyLabel1}}',
  ConfirmDeleteAreaDescription: 'Все связанные {{ hierarchyLabel2 }} & {{ hierarchyLabel3 }} будут удалены.',
  ConfirmDeleteSubAreaTitle: 'Вы уверены, что хотите удалить {{ hierarchyLabel2 }}',
  ConfirmDeleteSubAreaDescription: 'All the related {{ hierarchyLabel3 }} будут удалены',
  NewGroupadded: 'Добавлена новая группа',
  RequiredFields: 'Обязательные поля',
  AddedUserSuccessfulMessage: 'Пользователь успешно добавлен',
  DeletedUserSuccessfulMessage: 'Пользователь успешно удален',
  EditedGroupSucessfulMessage: 'Группа успешно обновлена',
  Userisrequired: 'Требуется указать пользователя',
  FirstNameisrequired: 'Требуется указать имя',
  SharedUserNameisrequired: 'Требуется указать имя общего пользователя',
  Emailisrequired: 'Требуется указать e-mail',
  User1isrequired: 'Требуется указать первого пользователя',
  Usertypeiswrong: 'Неверный тип пользователя',
  RepeatedEmailsInputWarning: 'Эти письма повторяются: [{{repeatedEmails}}}]<br/>',
  Shareduserfound: 'Найден общий пользователь',
  AddRemoveSubcategory: 'Добавить/удалить подкатегорию',

  IsActive: 'Активен',
  EditCategory: 'Изменить категорию',
  CommentisRequired: 'Требуется указать комментарий',
  RejectreasonisRequired: 'Требуется указать причину отказа',
  Duedateisrequired: 'Требуется указать дату выполнения',
  IAgree: 'Я согласен',
  All: 'Все',
  CreateFeedback: 'Создать отзыв',
  Enhancement: 'Enhancement',
  Environment: 'Environment',
  Web: 'Web',
  MobileWeb: 'Mobile & Web',
  Mobile: 'Mobile',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  FeedbackSuccessfullyadded: 'Feedback Successfully added',
  EditFeedback: 'Edit Feedback',
  Typetoaddanewdiscussioncomment: 'Type to add a new discussion comment',
  Attachments: 'Attachments',
  ADOStatus: 'ADO Status',
  FeedbackUpdated: 'Feedback Updated',
  UpdatedFeedbacksAttachmentErrorMessage: 'An error happened with the files. Please review your files are not corrupt or too big',
  LoadingKMSItemsErrorMessage: 'Произошла ошибка при поиске элементов KMS',
  TaskPlanManageTitle: 'Управление содержимым {{ Description }}',
  CreateNewSection: 'Создать новый раздел',
  NewSubsection: 'Новый подраздел',
  ItemType: 'Тип элемента',
  ConfirmDeleteTaskPlanSectionMessage: 'Вы уверены, что хотите удалить\n      <span style="color: red">{{ taskPlanSectionDesc }}</span> <br />\n      из плана задач {{ taskPlanDesc }} ?',
  ConfirmDeleteTaskPlanSubSectionMessage: 'Вы уверены, что хотите удалить <span style="color:red">{{subSection}}</span> <br> из раздела плана задач <b>{{section}}</b> ?',
  EditTaskPlanSubSection: 'Редактировать раздел плана задач',
  RequestforComments: 'Запрос комментариев',
  RequestforActionItems: 'Запрос пунктов действий',
  PrimeNgCalendarLabels: {
    firstDayOfWeek: 0,
    dayNames: [
      'Воскресенье',
      'Понедельник',
      'Вторник',
      'Среда',
      'Четверг',
      'Пятница',
      'Суббота',
    ],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    dayNamesMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    monthNamesShort: [
      'Янв',
      'Фев',
      'Мар',
      'Апр',
      'Май',
      'Июн',
      'Июл',
      'Авг',
      'Сен',
      'Окт',
      'Ноя',
      'Дек',
    ],
    today: 'Сегодня',
    clear: 'Очистить',
    dateFormat: 'mm/dd/yy',
    weekHeader: 'Wk',
  },
  ProjectSpecific: 'Project Specific',
  Showdeletedprojects: 'Показать удаленные проекты',
  ProjectManagers: 'Менеджеры проекта',
  ProjectTeamMembers: 'Члены проекта',

  GenericErrorTitle: 'Внутренняя ошибка',
  GenericErrorDescription: 'При обработке вашего запроса произошла ошибка. Повторите попытку позже или обратитесь в службу поддержки.',

  AccessUntilApprove: 'Доступ до одобрения',
  AllNotifications: 'Все уведомления',
  AreyousureyouwanttoSubCategory: 'Are you sure you want to Sub Category',
  DueDateMandatory: 'Срок обязателен',
  FinalApproverSubmit: 'Final Approver Submit',
  FinalApproverInitiate: 'Инициировать утверждающего',
  SectionsCollapsed: 'Разделы свернуты',
  Portuguese: 'Portuguese',
  Dutch: 'Dutch',
  PSSRmusthaveaFinalApprovertocompleteWalkthrough: '{{PssrTitle}} для завершения обхода должен быть назначен утверждающий',
  ChevronDisclaimer: `This Chevron software product is for use by authorized users only. To the extent permitted by law, by
  using this software product you acknowledge and consent to the monitoring, access (including the
  decryption and inspection of selected encrypted internet
  traffic), use or disclosure by Chevron of any information generated, received or stored on this
  system/device and waive any right or expectation of privacy in connection with your use. Unauthorized
  and/or improper use of this software
  product in violation of Chevron corporate policies will be not tolerated and may result in disciplinary
  action, including termination.`,
  ConfirmDeletePSSRTitle: 'Подтвердите удаление',
  ConfirmDeletePSSRMessage: 'ППТБ будет удален навсегда, продолжить?<br/>',
  ConfirmNewTrainingTitle: 'Подтвердить новый учебный ППТБ',
  ConfirmNewTrainingMessage: 'Будет создан новый учебный ППТБ.<br/> Вы хотите продолжать?<br/>',
  ConfirmDeleteActionItemMessage: 'Вы уверены, что хотите выполнить это действие?',
  Spanish: 'Spanish',
  CannotCloseProjectDueOpenPSSRs: 'Cannot close the project because there are open PSSRs',
  RestoreProject: 'Восстановить проект',
  ImportPSSRs: 'Импорт ППТБ ',
  Import: 'Импорт',
  Application: 'Приложение',
  Records: 'Записей',
  Imported: 'Импортировано',
  Errors: 'Ошибки',
  ImportAll: 'Импортировать все',
  QAQCImport: 'QAQC Import',
  Start: 'Старт ППТБ',
  PSSRIdisrequired: 'Требуется идентификатор ППТБ',
  ImportFromisrequired: 'Требуется импорт из.',
  MOCIDisalreadypresentintheImportlist: 'Идентификатор MOC уже присутствует в списке импорта.',
  ErrorhappenedwhileimportingPSSR: 'Произошла ошибка при импорте ППТБ',
  ePSSRServiceNowTicketingInstructions: 'ePSSR Service Now Инструкции по оформлению заявок',
  ReportanIssue: 'Сообщить о проблеме',
  EnhancementRequest: 'Запрос об улучшении',
  UserAccessRequest: 'Запрос доступа пользователя',
  ApplicationSupportRequestForm: 'Перейдите к <a href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">форме запроса на поддержку приложения</a> на портале ИТ-услуг Chevron`.',
  UndertheApplicationFieldselectMobilePSSR: 'В поле «Приложение» выберите «Мобильный PSSR».',
  ToreportanissueorbugpleaseselectReportanIssueTosubmitanenhancementrequestoruseraccessrequestpleaseselectServiceRequestHitNext: 'Чтобы сообщить о проблеме или ошибке, выберите <b>Сообщить о проблеме</b>.запрос на доступ, выберите <b>Запрос на обслуживание</b>. Нажмите «Далее».',
  FillouttheDescriptionoftheProblemImpactandUrgency: 'Заполните описание проблемы, воздействия и срочности.',
  PressNextandthenSubmit: 'Нажмите «Далее», а затем «Отправить».',
  TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours: 'Служба поддержки ePSSR подтвердит получение заявки в течение 24 часов в рабочее время.',
  PleaseselectEnhancementsunderTypeofRequests: 'Пожалуйста, выберите <b>Улучшения</b> в разделе "Тип запросов.',
  SelectPriorityLowModerateHigh: 'Выберите приоритет (низкий, средний, высокий)',
  FillouttheDescriptionofRequest: 'Заполните описание запроса',
  SelectNextandSubmit: 'Выберите «Далее» и «Отправить»..',
  TheePSSRSupportTeamwillreviewenhancementrequestsaspartoftheSurfaceDigitalPIPlanningProcess: 'Группа поддержки ePSSR рассмотрит запросы на усовершенствование в рамках процесса планирования Surface Digital PI.',
  PleaseselectRequestAccessunderTypeofRequests: 'Выберите «Запросить доступ» в разделе «Тип запросов».',
  SelectAccessRequestTypeAddorRemove: 'Выберите тип запроса доступа (добавить или удалить)',
  SpecifyRoleandAccesstheUserneeds: 'Укажите роль и доступ, необходимые пользователю.',
  UserAlreadyPartipatedInPssr: 'Участник ["{{newTranslator}}"] уже участвует в ППТБ',
  TranslateActionItem: 'Перевести Пункт Действий',
  AbandonedPSSRInterval: 'Интервал неактивных ППТБ',
  ShowOnlyDeletedPSSRs: 'Показать только удаленные ППТБ',
  ShowOnlyAbandonedPSSRs: 'Показать только неактивные ППТБ',
  CloseConfirmation: 'Помните, закрывая ППТБ, вы подтверждаете закрытие всех пунктов действий. Закрыть ППТБ?',
  AllowLeadtoShareDeviceWithApprover: 'Разрешить руководителю предоставить доступ в устройство Утверждающему',
  AllowLeadtoShareDeviceWithApproverInstruction: '*Если активировано: Руководитель может предоставить доступ в устройство Утверждающему, если Утверждающий присутствует для подписания',
  PSSRInitializedSuccessfully: 'ППТБ успешно инициализирован',

  ReimportAllSharedUsers: 'Синхронизировать всех общих пользователей с Белыми Страницами',
  ImportSharedUsersFailed: 'Не удалось импортировать',
  ImportSharedUsersIncompleted: 'Импорт не завершен',
  ImportSharedUsersCompleted: 'Импорт завершен за: {{ minutes }}минут',
  ConfirmReimportAllSharedUsersHeader: 'Синхронизировать всех общих пользователей',
  ConfirmReimportAllSharedUsersMessage: 'Вы собираетесь обновить конфигурации всех общих(шаред) пользователей.<br> Выполнение этой операции может занять несколько минут.',
  'SendaremindertoAssignees': 'Отправить напоминание о ПД назначенным лицам',
  SendReminderInstructions: 'Отправить напоминание исполнителям обо всех открытых ПД. Когда ППТБ находится в состоянии Ожидает Утверждения, утверждающие будут уведомлены.',
  'Sendamessage': 'Отправить сообщение',
  'SendanemailmessagetoallPSSRParticipants': 'Отправить электронное письмо всем участникам ППТБ',
  ImportSharedUserOptionWhitePagesTitle: 'Интеграция с Белыми страницами',
  ImportSharedUserOptionWhitePagesDescription: 'Используйте этот тип общей электронной почты, если вам нужно синхронизироваться с Белыми Страницами. Список пользователей будет обновляться автоматически с течением времени. Некоторые пользователи могут быть автоматически добавлены или удалены.',
  ImportSharedUserOptionCustomTitle: 'Обычный',
  ImportSharedUserOptionCustomDescription: 'Ручное управление списком пользователей',
  ImportMOCActions: 'Import MOC Action Items',
  SendMOCforStartup: 'Send to MOC record for startup approval',
  ImportActionItemsSuccessfully: 'Imported Action Items successfully',
  ImportActionItemsNone: 'No Action Items to import',
};
