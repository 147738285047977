
<div class="box-shadow module">
  <div class="row">
    <h2>{{ "BU Admin Users" | translate }}</h2>
    <hr />
  </div>

  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{
        "Business Unit" | translate
      }}</label>
      <div class="col-md-6">
        <select
          [appBUSwitcher]="selectedBU"
          class="form-control"
          [(ngModel)]="selectedBU"
          (change)="loadBUUsers()"
        >
          <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
            {{ bu.Name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        "User Type" | translate
      }}</label>
      <div class="col-xs-6 row">
        <label class="form-check-inline col-xs-3">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="1"
            [(ngModel)]="user.Type"
            (change)="clear()"
          />
          &nbsp;{{'Chevron'|translate}}
        </label>
        <label class="form-check-inline col-xs-4">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="2"
            [(ngModel)]="user.Type"
            (change)="clear()"
          />
          &nbsp;{{ "3rdPartyexternal" | translate }}
        </label>
      </div>
    </div>
    <!-- chevron-->
    <div *ngIf="user.Type ===  UserType.Chevron" class="form-group">
      <label class="control-label col-md-2"
      >{{'User'|translate}}<b style="color: red">&nbsp;*</b></label
      >
      <div class="col-md-6">
        <p-autoComplete
          [(ngModel)]="selectedUser"
          [suggestions]="users"
          (completeMethod)="getUsers($event)"
          name="usr"
          #usr="ngModel"
          field="FullName"
          delay="1000"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [minLength]="1"
          [placeholder]="'typeusercainame' | translate"
        >
        </p-autoComplete>
      </div>
    </div>
    <!-- external -->
    <div *ngIf="user.Type ===  UserType.External" class="form-group">
      <label class="control-label col-md-2">
        {{'First Name'|translate}}
        <b style="color: red">&nbsp;*</b>
        </label>
      <div class="col-md-6">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="user.FirstName"
          [attr.placeholder]="'typenewuser' | translate"
        />
      </div>
    </div>
    <!-- external -->
    <div *ngIf="user.Type ===  UserType.External" class="form-group">
      <label class="control-label col-md-2">
        {{ "Last Name" | translate }}
      </label>
      <div class="col-md-6">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="user.LastName"
          [placeholder]="'typelastname' | translate"
        />
      </div>
    </div>
    <!-- external -->
    <div *ngIf="user.Type ===  UserType.External" class="form-group">
      <label class="control-label col-md-2">
        {{'Email'|translate}}          
        <b style="color: red">&nbsp;*</b>
      </label>

      <div class="col-md-6">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="user.Email"
          [placeholder]="'typeemail' | translate"/>
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-2 col-md-4">
        (<b style="color: red">*</b>) {{ "Required Fields" | translate }}
      </div>
      <div class="col-md-2">
        <button
          class="btn btn-primary"
          style="width: 100px"
          (click)="addUser()"
        >
          {{'Save'|translate}}
        </button>
      </div>
    </div>
  </div>
  <p-confirmDialog #cd>
    <p-footer>
      <button
        type="button"
        pButton
        icon="fa-check pi pi-check"
        [label]="'Yes'|translate"
        (click)="cd.accept()"
        style="width: 80px"
      ></button>
      <button
        type="button"
        pButton
        icon="fa-close pi pi-times"
        [label]="'No'|translate"
        (click)="cd.reject()"
        style="width: 80px"
      ></button>
    </p-footer>
  </p-confirmDialog>

  <table class="table table-hover">
    <tr>
      <th scope="col">{{ "Name" | translate }}</th>
      <th scope="col">{{ "CAI" | translate }}</th>
      <th scope="col">{{ "Email" | translate }}</th>
      <th scope="col"></th>
    </tr>
    <tr *ngFor="let o of buUsers">
      <td>{{ o | fullName }}</td>
      <td>{{ o.CAI }}</td>
      <td>{{ o.Email }}</td>
      <td style="text-align: end">
        <button
          *ngIf="isSysAdmin"
          type="button"
          class="btn btn-danger"
          (click)="removeUserWarning(o.Id)"
          style="width: 80px"
        >
          {{'Delete'|translate}}
        </button>
      </td>
    </tr>
  </table>
</div>
