import { Component, OnInit } from '@angular/core';
import { AreaService } from '#services/api/area.service';
import { Area } from '#models/area';

import { Router, ActivatedRoute } from '@angular/router';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-area-delete',
  templateUrl: './area-delete.component.html',
})
export class AreaDeleteComponent implements OnInit {
  area: Area = new Area();
  areaName: string;
  displayWarningArea: boolean;
  hierarchyLabel1: string;
  hierarchyLabel2: string;
  hierarchyLabel3: string;



  confirmDeleteAreaTitle = 'ConfirmDeleteAreaTitle';
  confirmDeleteAreaDescription = 'ConfirmDeleteAreaDescription';
  hierarchyLabels = {
    hierarchyLabel1: '',
    hierarchyLabel2: '',
    hierarchyLabel3: '',
  };

  constructor(
    private toastrService: ToastrService,
    private areaSvc: AreaService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.area.Id = +v['areaId'];
      this.area.BusinessUnitID = +v['buId'];
      this.hierarchyLabel1 = v['hierarchyLabel1'];
      this.hierarchyLabel2 = v['hierarchyLabel2'];
      this.hierarchyLabel3 = v['hierarchyLabel3'];

      this.hierarchyLabels = {
        hierarchyLabel1: this.hierarchyLabel1,
        hierarchyLabel2: this.hierarchyLabel2,
        hierarchyLabel3: this.hierarchyLabel3,
      };
    });
    this.getAreaById(this.area.Id);
    this.displayWarningArea = false;
  }

  onRedirectButtonClick(buId: number): void {
    this.router.navigate(['admin/area', { buId: buId }]);
  }

  getAreaById(id: number): void {

    this.areaSvc
      .getAreaById(id)

      .subscribe(
        (data: Area) => {
          this.area = data;
          this.areaName = this.area.Name;
        },
        (error) => {
        this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }

  deleteArea(): void {

    this.areaSvc
      .deleteArea(this.area.Id)

      .subscribe(
        (data: any) => {
          this.onRedirectButtonClick(this.area.BusinessUnitID);
        },
        (error) => {

          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }
}
