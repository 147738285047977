<div
  class="box-shadow module"
  style="width: 80%; margin: auto; margin-bottom: 20px"
  [appBUSwitcher]="selectedBU"
>

  <h2>{{ "Attention" | translate }}!</h2>
  <hr />
  <h3>
    {{ "Are you sure you want to delete" | translate }} {{ hierarchyLabel3 }}:
    <span style="background-color: yellow">{{ facility.Name }}</span> ?
  </h3>
  <h3>
    <a class="btn btn-danger" style="width: 100px" (click)="deleteFacility()">{{
      "Delete" | translate
    }}</a>
  </h3>
</div>

<div style="width: 80%; margin: auto">
  <a (click)="onRedirectButtonClick()" style="cursor: pointer"
    >{{ "Back to" | translate }} {{ hierarchyLabel3 }} List</a
  >
</div>
