import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/index';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';

@Component({
  selector: 'app-task-plan-edit',
  templateUrl: './task-plan-edit.component.html',
  styleUrls: ['./task-plan-edit.component.scss'],
})
export class TaskPlanEditComponent implements OnInit {
  //#region VARIABLES

  lstBusinessUnit: BusinessUnit[] = [];
  taskPlan: TaskPlan = new TaskPlan();
  taskPlanId: number;
  displayWarningDesc: boolean;
  displayWarningBusinessUnit: boolean;
  selectedBU = new BusinessUnit();
  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,
    private businessUnitsSvc: BusinessUnitsService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.taskPlanId = +v['id'];
    });
    this.getTaskPlanById(this.taskPlanId);
    this.displayWarningDesc = false;
    this.displayWarningBusinessUnit = false;
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/task-plan']);
  }

  loadBusinessUnits(): void {
    this.businessUnitsSvc
      .getUserBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length == 0) {
          this.lstBusinessUnit = [];
        }
        this.selectedBU = data[0];
      });
  }

  getTaskPlanById(id: number) {
    this.taskPlanSvc
      .getTaskPlanById(id)
      .toPromise()
      .then((data) => {
        this.taskPlan.Code = data.Code;
        this.taskPlan.Description = data.Description;
        this.taskPlan.ID = data.ID;
        this.taskPlan.BusinessUnitId = data.BusinessUnitId;
        this.taskPlan.HideCode = data.HideCode;
        this.taskPlan.TaskPlanType = data.TaskPlanType;

        this.taskPlan.DefaultWalkthroughSignatureDisclaimer =
          data.DefaultWalkthroughSignatureDisclaimer;
        this.taskPlan.DefaultSummarySignatureDisclaimer =
          data.DefaultSummarySignatureDisclaimer;

        this.loadBusinessUnits();
      });
  }

  public updateTaskPlan(): void {
    // tslint:disable-next-line: max-line-length
    this.displayWarningDesc =
      this.taskPlan.Description === undefined ||
      this.taskPlan.Description === null ||
      this.taskPlan.Description.length === 0;
    // tslint:disable-next-line: max-line-length
    this.displayWarningBusinessUnit =
      this.taskPlan.BusinessUnitId === undefined ||
      this.taskPlan.BusinessUnitId === null ||
      this.taskPlan.BusinessUnitId < 1;

    if (!this.displayWarningDesc && !this.displayWarningBusinessUnit) {
      this.taskPlanSvc
        .updateTaskPlan(this.taskPlan)

        .subscribe((data: any) => {
          this.onRedirectButtonClick();
        });
    }
  }
  onSelectBusinessUnit(selectedBUId: number) {
    this.selectedBU = this.lstBusinessUnit.find(({ Id }) => Id == selectedBUId);
  }

  hasRichText(text: string): boolean {
    return text && text.includes('<');
  }
}
