import { Component, OnInit } from '@angular/core';
import { AuthService } from '#services/shared';
import { Utility } from '#services/shared/utility';
import { B2CResponse } from '#models/b2c';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  userName: string;
  isB2CLogged = false;

  constructor(
    private authSvc: AuthService,
  ) { }

  ngOnInit() {
    const b2c: B2CResponse = JSON.parse(localStorage.getItem('localB2CLogin'));
    this.isB2CLogged = Utility.isValidObj(b2c);
    this.userName = this.isB2CLogged ? b2c.username : this.authSvc.getFullName();
  }

  logout() {
    localStorage.removeItem('localB2CLogin');
    window.location.reload();
  }

}
