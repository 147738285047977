<table class="table table-hover table-stripped table-condensed" style="margin-bottom: 2px;">
  <thead>
    <tr>
      <td class="col-md-3">{{ 'Signed By' | translate }}</td>
      <td class="col-md-3">{{ 'Name' | translate }}</td>
      <td class="col-md-3">{{ 'Date Captured' | translate }}</td>
      <td class="col-md-3"></td>
    </tr>
  </thead>
  <tbody>
    <ng-container *ngIf="isFinalSignature; else sectionSignature" [ngTemplateOutlet]="finalSignature">
    </ng-container>
  </tbody>
</table>

<ng-template #sectionSignature>
  <tr>
    <td class="col-md-3" [innerHTML]="response?.ItemDescription"></td>
    <td class="col-md-3">
      <span *ngIf="singleSignature">{{ singleSignature.Caption}}</span>
    </td>
    <td class="col-md-3">
      <span *ngIf="singleSignature">{{ singleSignature.CreateDate | date }}</span>
    </td>
    <td class="col-md-3">
      <img *ngIf="singleSignature && singleSignature.Photo" (click)="expand(response.ActionItems[0]?.Images[0]?.Photo)"
        src="data:image/png;base64,{{response.ActionItems[0]?.Images[0]?.Photo}}">
    </td>
    <td class="col-md-3">
      <button *ngIf="canUpdateSignature && !singleSignature?.Photo"
        type="button" (click)="sign()" class="btn btn-primary">{{'Capture Signature' | translate}}</button>
      <button *ngIf="canRemoveSignature && singleSignature?.Photo"
        type="button" (click)="removeSignature()" class="btn btn-danger">{{'Remove Signature' | translate}}</button>
    </td>
  </tr>
</ng-template>

<ng-template #finalSignature>
  <tr>
    <td class="col-md-3">{{ pssr.pssrSignatureLabel }}</td>
    <td class="col-md-3">{{ pssr.FinalApprover ? pssr.FinalApprover.UserName : '' }}</td>
    <td class="col-md-3">{{ pssr.pssrSignatureDate }}</td>
    <td colspan="9" class="col-md-3">
      <img *ngIf="pssr.FinalSignature" src="data:image/png;base64,{{pssr.FinalSignature}}"
        (click)="expand(pssr.FinalSignature)">
    </td>
  </tr>
</ng-template>

<p-dialog [(visible)]="display" showEffect="fade" [modal]="true" (onAfterHide)="collapse()">
  <p-header></p-header>
  <div style="text-align: center;">
    <img *ngIf="selectedImage" src="data:image/png;base64,{{selectedImage}}">
  </div>
  <p-footer></p-footer>
</p-dialog>



<div #signatureTemplate class="signature-template">
  <div class="signature-title"><strong>{{'Completed by'|translate}}:</strong></div>
  <div class="signature-person"><strong>{{ pssr?.CurrentUser?.UserName }}</strong></div>
  <br>
  <div [innerHTML]="response?.DefaultSignatureDisclaimer"></div>
  <br>
  <div class="signature-footer">{{'Completed on'|translate}}: {{ today | date:'yyyy-MM-dd, hh:mm:ss z' }}</div>
</div>
