<div class="box-shadow module form-horizontal" [appBUSwitcher]="selectedBU" style="margin: auto auto 20px; width: 80%;">
  <h3>{{'Create Task Plan Item'|translate}}</h3>

  <div class="form-group">
    <label class="control-label col-md-2">{{'Code'|translate}}</label>
    <div class="col-md-4">
      <input class="form-control text-box single-line" [(ngModel)]="taskPlanItem.Code">
      <span class="text-danger field-validation-error" *ngIf="displayWarningCode">
        <span>{{'The Code field is required'|translate}}.</span>
      </span>
    </div>
  </div>
  <app-toggle-rich-input [displayRichEditor]="displayRichEditor" label="Description"
    [(ngModel)]="taskPlanItem.Description">
    <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
      <span>{{'The Description field is required'|translate}}.</span>
    </span>
  </app-toggle-rich-input>
  <div class="form-group">
    <label class="control-label col-md-2">{{'Item Type'|translate}}</label>
    <div class="col-md-10">
      <select class="form-control" (ngModelChange)="onItemTypeChanged($event)" [(ngModel)]="taskPlanItem.ItemType">
        <option [ngValue]="0">{{'Default'|translate|translate}}</option>
        <option [ngValue]="1">{{'Summary Signature'|translate}}</option>
        <option [ngValue]="2"> {{'Walkdown Signature'|translate}}</option>
        <option [ngValue]="3"> {{'Request for Comments'|translate}}</option>
        <option [ngValue]="4">{{'Request for Action Items' | translate}}</option>
      </select>
    </div>
  </div>

  <div class="form-group" *ngIf="taskPlanItem.ItemType === 0">
    <label class="control-label col-md-2">{{'Should Add Comment To Question'|translate}}</label>
    <div class="col-md-10">
      <input type="checkbox" [(ngModel)]="taskPlanItem.ShouldAddCommentToQuestion"
        value="taskPlanItem.ShouldAddCommentToQuestion">
    </div>
  </div>

  <div class="row">
    <div class="col-md-offset-2 col-md-10">
      <button type="button" class="btn btn-primary" (click)="createTaskPlanItem()">{{'Create'|translate}}</button>
    </div>
  </div>
</div>

<div>
  <button type="button" class="btn btn-link" (click)="onRedirectButtonClick()" style="cursor: pointer;">{{'Back to Task
    Plan'|translate}}</button>
</div>
