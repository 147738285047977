import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-pssr-kms',
  templateUrl: './pssr-kms.component.html',
  styleUrls: ['./pssr-kms.component.scss']
})
export class PssrKmsComponent implements OnInit {

  buId: number;
  orgId: number;
  projId: number;

  constructor(private activeRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(p => {
      this.buId = +p['buId'];
      this.orgId = +p['orgId'];
      this.projId = +p['projId'];
    });
  }

}
