import { environment } from '#environments/environment';
import { PSSRType } from '#models/pssr-type';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PssrTypeService {
  constructor(private _http: HttpClient) {}
  loadPssrTypes(): Observable<PSSRType[]> {
    return this._http.get<PSSRType[]>(`${environment.apiEndpoint}/pssr-types`);
  }
}
