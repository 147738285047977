<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">
  <h2>{{'Edit Task Plan Sub Section'|translate}}</h2>
  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{'Description'|translate}}</label>
      <div class="col-md-10">
        <input class="form-control text-box single-line" [(ngModel)]="taskPlanSubSection.Description">
        <span class="text-danger field-validation-error" *ngIf="displayWarningDesc">
          {{'This field is required' | translate }}
        </span>
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <button class="btn btn-default" style="width: 100px;" (click)="updateTaskPlanSubSection()">{{'Save'|translate}}</button>
      </div>
    </div>
  </div>
</div>
<div style="width:80%; margin:auto">
  <a (click)="onRedirectButtonClick()" style="cursor: pointer;">{{'Back to Task Plan Manage'|translate}}</a>
</div>
