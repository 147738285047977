import { TaskPlan } from 'models/task-plan';
import { TaskPlanSection } from 'models/task-plan-section';
import { TaskPlanSubSection } from 'models/task-plan-sub-section';
import { TaskPlanItem } from 'models/task-plan-item';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class TaskPlanService {
    constructor(
        private http: HttpClient
    ) { }

    // ---------------TASK PLAN------------------------------------

    public getTaskPlans(buid: number): Observable<TaskPlan[]> {
        return this.http.get<TaskPlan[]>(`${environment.apiEndpoint}/task-plan/getTaskPlans/${buid}`);
    }

    public getTaskPlanById(id: number): Observable<TaskPlan> {
        return this.http.get<TaskPlan>(`${environment.apiEndpoint}/task-plan/getTaskPlanById/${id}`);
    }

    public createTaskPlan(taskPlan: TaskPlan): Observable<TaskPlan> {
        return this.http.post<TaskPlan>(`${environment.apiEndpoint}/task-plan/createTaskPlan`, taskPlan);
    }

    public updateTaskPlan(taskPlan: TaskPlan): Observable<TaskPlan> {
        return this.http.put<TaskPlan>(`${environment.apiEndpoint}/task-plan/updateTaskPlan`, taskPlan);
    }

    public deleteTaskPlan(id: number): Observable<any> {
        return this.http.put<any>(`${environment.apiEndpoint}/task-plan/deleteTaskPlan/${id}`, null);
    }

    public resetTaskPlan(id: number): Observable<TaskPlan> {
        return this.http.put<TaskPlan>(`${environment.apiEndpoint}/task-plan/resetTaskPlan/${id}`, null);
    }

    // ---------------TASK PLAN SECTION------------------------------------

    public getTaskPlanSectionById(id: number): Observable<TaskPlanSection[]> {
        return this.http.get<TaskPlanSection[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanSectionById/${id}`);
    }

    public getTaskPlanSectionByTaskPlan(taskPlanId: number): Observable<TaskPlanSection[]> {
        return this.http.get<TaskPlanSection[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanSectionByTaskPlan/${taskPlanId}`);
    }

    public createTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
        return this.http.post<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/createTaskPlanSection`, taskPlanSection);
    }


    public updateTaskPlanSection(taskPlanSection: TaskPlanSection): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/updateTaskPlanSection`, taskPlanSection);
    }

    public deleteTaskPlanSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/deleteTaskPlanSection/${id}`, null);
    }

    public moveUpTaskPlanSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanSection/${id}`, null);
    }

    public moveDownTaskPlanSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanSection/${id}`, null);
    }

    // ---------------TASK PLAN SUB SECTION------------------------------------

    public getTaskPlanSubSections(id: number): Observable<TaskPlanSubSection[]> {
        return this.http.get<TaskPlanSubSection[]>(`${environment.apiEndpoint}/task-plan/getTaskPlanSubSections/${id}`);
    }

    public getTaskPlanSubSectionById(id: number): Observable<TaskPlanSubSection> {
        return this.http.get<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/getTaskPlanSubSectionById/${id}`);
    }

    public createTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
        return this.http.post<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/createTaskPlanSubSection`, taskPlanSubSection);
    }

    public updateTaskPlanSubSection(taskPlanSubSection: TaskPlanSubSection): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/updateTaskPlanSubSection`, taskPlanSubSection);
    }

    public deleteTaskPlanSubSection(id: number): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/deleteTaskPlanSubSection/${id}`, null);
    }

    public moveUpTaskPlanSubSection(id: number): Observable<TaskPlanSubSection> {
        return this.http.put<TaskPlanSubSection>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanSubSection/${id}`, null);
    }

    public moveDownTaskPlanSubSection(id: number): Observable<TaskPlanSection> {
        return this.http.put<TaskPlanSection>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanSubSection/${id}`, null);
    }

    // ---------------TASK PLAN ITEM ------------------------------------

    public getTaskPlanItemById(id: number): Observable<TaskPlanItem> {
        return this.http.get<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/getTaskPlanItem/${id}`);
    }

    public createTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
        return this.http.post<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/createTaskPlanItem`, taskPlanItem);
    }

    public updateTaskPlanItem(taskPlanItem: TaskPlanItem): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/updateTaskPlanItem`, taskPlanItem);
    }

    public deleteTaskPlanItem(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/deleteTaskPlanItem/${id}`, null);
    }

    public moveUpTaskPlanItem(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/moveUpTaskPlanItem/${id}`, null);
    }

    public moveDownTaskPlanItem(id: number): Observable<TaskPlanItem> {
        return this.http.put<TaskPlanItem>(`${environment.apiEndpoint}/task-plan/moveDownTaskPlanItem/${id}`, null);
    }

    // ------ GENERAL FUNCTION

    public isInvalidString(value: string): boolean {
        return value == undefined || value == null || value.length == 0;
    }

    public isInvalidInt(value: number): boolean {
        return value == undefined || value == null || value == 0;
    }
}
