<div class="box-shadow module">
  <div class="row">
    <h2>Equipment List</h2>
  </div>



  <div class="row padding-bottom-10 component-separator">
    <div class="col-xs-6">
      <label>Business Unit :</label>
      <select [appBUSwitcher]="selectedBU" class="form-control" [(ngModel)]="selectedBU"
        (change)="loadEquipmentsByBU()">
        <option *ngFor="let bu of businessUnitList" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
  </div>

  <table class="table table-hover" *ngIf="selectedBU">
    <tr>
      <th scope="col" class="col-xs-10">Name</th>
      <th scope="col" class="col-xs-2" style="text-align:end; padding: 1px">
        <button type="button" class="btn btn-success"
          (click)="onRedirectButtonClick('admin/equipment-form', 0, selectedBU.Id)" style="width:80px;">New</button>
      </th>
    </tr>
    <tr *ngFor="let d of equipTempList">
      <td class="col-xs-10">{{d.Name}}</td>
      <td class="col-xs-2" style="text-align:end">
        <a (click)="onRedirectButtonClick('admin/equipment-form', d.Id, selectedBU.Id)"
          style="cursor: pointer;">Edit</a>
      </td>
    </tr>
  </table>

  <hr>
  <div class="row component-separator">
    <div class="col-xs-10"></div>
    <div class="col-xs-2 form-group">
      <button type="button" class="btn btn-primary" style="width: 100%;" (click)="downloadTemplates()">
        Export Excel</button>
    </div>
  </div>
</div>
