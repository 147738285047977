import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DialogService {
  private _isOpen = new BehaviorSubject<boolean>(false);

  open() {
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  get isOpen() {
    return this._isOpen.value;
  }

  set isOpen(value) {
    this._isOpen.next(value);
  }

  isOpen$() {
    return this._isOpen.asObservable();
  }
}
