<div class="box-shadow module">
  <h2>{{ "Create Feedback" | translate }}</h2>
  <hr />
  <div class="form-horizontal">
    <!-- BU -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Business Unit" | translate }} :
      </label>
      <div class="col-md-8">
        <select
          class="form-control"
          (change)="navigateTo(feedback.BusinessUnitId)"
          [(ngModel)]="feedback.BusinessUnitId"
        >
          <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu.Id">
            {{ bu.Name }}
          </option>
        </select>
        <span *ngIf="buWarning" class="text-danger field-validation-error">
          <b>{{'BU is required'|translate}}.</b>
        </span>
      </div>
    </div>
    <!-- UserId -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Created By" | translate }} :
      </label>
      <div class="col-md-8" style="padding-top: 7px">
        {{ loggedUser.FirstName + " " + loggedUser.LastName }}
      </div>
    </div>
    <!-- Type -->
    <div class="form-group">
      <label class="control-label col-md-2">{{'Type'|translate}} :</label>
      <div class="col-md-8">
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="0"
            [(ngModel)]="feedback.Type"
          />
          &nbsp;{{'Bug'|translate}}
        </label>
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="1"
            [(ngModel)]="feedback.Type"
          />
          &nbsp;{{'Enhancement'|translate}}
        </label>
      </div>
    </div>
    <!-- Environment -->
    <div class="form-group">
      <label class="control-label col-md-2">{{'Environment'|translate}} :</label>
      <div class="row col-xs-8">
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="environment"
            [value]="0"
            [(ngModel)]="feedback.Environment"
          />
          &nbsp;{{'Mobile'|translate}}
        </label>
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="environment"
            [value]="1"
            [(ngModel)]="feedback.Environment"
          />
          &nbsp;{{'Web'|translate}}
        </label>
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="environment"
            [value]="2"
            [(ngModel)]="feedback.Environment"
          />
          &nbsp;{{'MobileWeb'|translate}}
        </label>
      </div>
    </div>
    <!-- Title -->
    <div class="form-group">
      <label class="control-label col-md-2">{{'Title'|translate}} :</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="feedback.Title"
        />
        <span *ngIf="titleWarning" class="text-danger field-validation-error">
          <b>{{ "Title is required" | translate }}.</b>
        </span>
      </div>
    </div>
    <!-- Comments -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Description" | translate }} :</label
      >
      <div class="col-md-8">
        <textarea
          class="form-control text-box single-line"
          [(ngModel)]="feedback.Comments"
        >
        </textarea>
        <span
          *ngIf="descriptionWarning"
          class="text-danger field-validation-error"
        >
          <b>{{ "Description is required" | translate }}.</b>
        </span>
      </div>
    </div>

    <!-- Priority -->
    <div class="form-group">
      <label class="control-label col-md-2">{{'Priority'|translate}} :</label>
      <div class="row col-xs-8">
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="priority"
            [value]="1"
            [(ngModel)]="feedback.Priority"
          />
          &nbsp;{{'High'|translate}}
        </label>
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="priority"
            [value]="2"
            [(ngModel)]="feedback.Priority"
          />
          &nbsp;{{'Medium'|translate}}
        </label>
        <label class="col-xs-2 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="priority"
            [value]="3"
            [(ngModel)]="feedback.Priority"
          />
          &nbsp;{{'Low'|translate}}
        </label>
      </div>
    </div>
    <!-- File -->
    <div class="form-group">
      <label class="control-label col-md-2"
        >{{ "Attachments" | translate }} :</label
      >
      <div class="col-md-8">
        <div *ngFor="let file of files">
          <div class="row padding-top-5">
            <button
              type="button"
              class="btn btn-sm btn-danger"
              (click)="remove(file.name)"
            >
              <strong>X</strong>
            </button>
            {{ file.name }}
          </div>
        </div>
        <input
          class="padding-top-5"
          type="file"
          (change)="attachments($event)"
        />
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-2"></div>
      <div class="col-md-2" *ngIf="!(isLoading$|async) as isLoading">
        <a class="btn btn-primary" style="width: 100px" (click)="save()">{{
          "Create" | translate
        }}</a>
      </div>
    </div>
  </div>
  <div>
    <a
      (click)="onRedirectButtonClick()"
      style="cursor: pointer"
      id="backToFeedbackList"
      >{{ "Back to List" | translate }}</a
    >
  </div>
</div>
