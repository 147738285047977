<div class="box-shadow module">
  <div class="row">
    <h2>{{ "Sub Categories" | translate }}</h2>
    <hr />
  </div>

  <div class="row padding-bottom-10">
    <div class="col-sm-12" style="text-align: end; padding-top: 40px">
      <a [routerLink]="['/admin/subcategory-create']">{{
        "Create New Sub Category" | translate
      }}</a>
    </div>
  </div>

  <table class="table table-hover">
    <thead>
      <tr class="bg-info">
        <th scope="col" class="col-xs-6">{{'Sub Category'|translate}}</th>
        <th scope="col" class="col-xs-4">{{'Categories'|translate}}</th>
        <th scope="col" class="col-xs-2"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let s of subCategories">
        <td class="subcategory-name col-xs-6">{{ s.Name }}</td>
        <td class="categories col-xs-4" [innerHTML]="map(s.Categories)"></td>
        <td class="col-xs-2 text-center">
          <a
            [routerLink]="['/admin/subcategory-edit', { subCategoryId: s.Id }]"
            style="cursor: pointer"
            >{{'Edit'|translate}}</a
          >
          <span>&nbsp;|&nbsp;</span>
          <a
            [routerLink]="[
              '/admin/subcategory-delete',
              { subCategoryId: s.Id }
            ]"
            style="cursor: pointer"
            >{{'Delete'|translate}}</a
          >
        </td>
      </tr>
    </tbody>
  </table>
</div>
