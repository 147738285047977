<p-dialog
  header="New Version"
  [(visible)]="show"
  [modal]="true"
  [style]="{ width: '300px' }"
>
  <form (ngSubmit)="save()" #addVersionForm="ngForm">
    <div class="row">
      <div class="col-md-6">
        <label
          >{{ "Number" | translate }} *
          <input
            pInputText
            type="text"
            [(ngModel)]="version.Number"
            name="version-number"
            required
          />
        </label>
      </div>
      <div class="col-md-6">
        <label
          >{{ "Release Date" | translate }} *
          <p-calendar
            [(ngModel)]="version.ReleaseDate"
            [showIcon]="true"
            [yearNavigator]="true"
            yearRange="2000:2030"
            name="release-date"
            [locale]="'PrimeNgCalendarLabels'|translate"
            required
          >
          </p-calendar>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label
          >{{ "Description" | translate }}
          <input pInputText [(ngModel)]="version.Description" name="desc" />
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label
          >{{ "Status" | translate }}
          <p-dropdown
            [options]="listAppVersionStatus"
            [(ngModel)]="version.Status"
            name="status"
            appendTo="body"
          >
          </p-dropdown>
        </label>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="bottom-controls" style="margin-top: 15px">
          <button
            type="submit"
            class="btn btn-info"
            [disabled]="addVersionForm.form.invalid"
          >
            {{ "Save" | translate }}
          </button>
          <button type="button" class="btn btn-default" (click)="cancel()">
            {{ "Cancel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</p-dialog>
