<div class="box-shadow module">
  <!-- Task Plan -->
  <div class="row">
    <h2>{{ "PSSR Template" | translate }}</h2>
    <hr />
  </div>

  <div class="row padding-bottom-10">
    <div class="col-xs-6">
      <label>{{'Business Unit'|translate}} :</label>
      <select
        [appBUSwitcher]="selectedBU"
        class="form-control"
        [(ngModel)]="selectedBU"
        (change)="loadTaskPlans()"
      >
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
    <div class="col-xs-6" style="text-align: end; padding-top: 40px">
      <a
        (click)="onRedirectButtonClick('create', null)"
        style="cursor: pointer"
        >{{ "Create New" | translate }}</a
      >
    </div>
  </div>

  <table class="table table-hover">
    <thead>
      <tr class="bg-info">
        <th scope="col">{{ "Code" | translate }}</th>
        <th scope="col">{{ "Description" | translate }}</th>
        <th scope="col">{{ "Hide Code" | translate }}</th>
        <th id="thHolder"></th>
      </tr>
    </thead>
    <tr *ngFor="let tp of taskPlans">
      <td>{{ tp.Code }}</td>
      <td>{{ tp.Description }}</td>
      <td>
        <input
          style="padding-right: 0px; padding-left: 0px; text-align: center"
          type="checkbox"
          [(ngModel)]="tp.HideCode"
          value="tp.HideCode"
          [disabled]="true"
        />
      </td>
      <td style="text-align: end">
        <a
          (click)="onRedirectButtonClick('edit', tp.ID)"
          style="cursor: pointer"
          >{{'Edit'|translate}}</a
        >
        |
        <a
          (click)="onRedirectButtonClick('manage', tp.ID)"
          style="cursor: pointer"
          >{{'Manage'|translate}}</a
        >
        |
        <a
          (click)="onRedirectButtonClick('delete', tp.ID)"
          style="cursor: pointer"
          >{{'Delete'|translate}}</a
        >
      </td>
    </tr>
  </table>
</div>
