import { Component, OnInit } from '@angular/core';
import { FacilityService } from '#services/api/facility.service';
import { Facility } from '#models/facility';
import { SubAreaService } from '#services/api/sub-area.service';
import { SubArea } from '#models/sub-area';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';
import { AreaService } from '#services/api/area.service';
import { Area } from '#models/area';
import { Utility } from '#services/shared/utility';


@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
})
export class FacilityComponent implements OnInit {
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  selectedArea: Area;
  lstAreas: Area[] = [];
  subArea: SubArea;
  lstSubAreas: SubArea[] = [];
  lstFacilities: Facility[] = [];
  subAreaId: number;
  areaId: number;
  buId: number;

  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private areaService: AreaService,
    private facilitySvc: FacilityService,

    private subAreaService: SubAreaService,
    private _router: Router,
    private aRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.subArea = new SubArea();
    this.aRoute.params.subscribe((v) => {
      this.buId = +v['buId'];
      this.areaId = +v['areaId'];
      this.subAreaId = +v['subAreaId'];
    });

    this.subArea.Id = this.subAreaId;
    this.subArea.AreaId = this.areaId;

    this.loadSubAreas();
    this.loadFacilities();
    this.loadBusinessUnits();
  }

  loadSubAreas(): void {
    this.subAreaService
      .getAllByAreaId(this.areaId)
      .subscribe((data: SubArea[]) => {
        this.lstSubAreas = data.sort((a, b) => a.Name.localeCompare(b.Name));
        this.subArea = this.lstSubAreas.find((x) => x.Id === this.subAreaId);
        this.areaId = this.subArea.AreaId;
      });
  }

  loadFacilities(): void {

    this.facilitySvc
      .getAllBySubArea(this.subArea.Id)

      .subscribe((data: Facility[]) => {
        this.lstFacilities = [];
        if (Utility.isValidListWithData(data)) {
          this.lstFacilities = data.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
        }
      });
  }

  loadAreas(): void {
    this.areaService
      .getAllByBusinessUnit(this.buId)
      .subscribe((data: Area[]) => {
        this.lstAreas = data.sort((a, b) => a.Name.localeCompare(b.Name));
        this.selectedArea = this.lstAreas.find((x) => x.Id === this.areaId);
        this.buId = this.selectedArea.BusinessUnitID;
      });
  }

  loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserBUs().subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        if (this.buId == null || this.buId == undefined) {
          this.selectedBU = this.lstBusinessUnit[0];
        } else {
          this.selectedBU = this.lstBusinessUnit.find(
            (x) => x.Id === this.buId
          );
        }
        this.loadAreas();
      }
    });
  }
  onRedirectButtonClick(url: string, facilityId: number): void {
    this._router.navigate([
      url,
      {
        buId: this.buId,
        areaId: this.subArea.AreaId,
        subAreaId: this.subArea.Id,
        facilityId: facilityId,
        hierarchyLabel3: this.selectedBU.HierarchyLabel3,
      },
    ]);
  }
}
