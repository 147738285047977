import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '#services/shared/shared.module';
import { LoadingIndicatorService } from '#services/shared/loading-indicator.service';
import { HomeComponent } from './home/home.component';
import { DocumentationComponent } from './documentation/documentation.component';
import { FormsModule } from '@angular/forms';
import { ComponentsModule } from '#components/components.module';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { LoginComponent } from './login/login.component';
import { PssrPagesModule } from './pssr/pssr.pages.module';
import { SharedPagesModule } from './shared/shared.pages.module';
import { PssrListComponent } from './pssr';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { LanguageLoader } from '#services/shared/language-loader';
import { LanguageParser } from '#services/shared/language-parser';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    HomeComponent,
    DocumentationComponent,
    LoginComponent,
    PssrListComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ComponentsModule,

    PssrPagesModule,
    SharedPagesModule,

    // PrimeNG
    InputTextModule,
    ButtonModule,
    MultiSelectModule,
    AutoCompleteModule,
    SplitButtonModule,
    DialogModule,
    DropdownModule,
    ConfirmDialogModule,

    NgxPaginationModule,

    // Dropdown
    MatExpansionModule,
    MatInputModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: LanguageLoader },
      parser: {
        provide: TranslateParser,
        useClass: LanguageParser
      },
      useDefaultLang: true,
    }),
  ],
  providers: [LoadingIndicatorService],
})
export class PagesModule { }
