import { Injectable } from '@angular/core';

import { UserService } from '#services/api';
import { LoggedUser } from 'models';
import { Role } from 'models/enum/role';
import { finalize } from 'rxjs/operators';
import { B2CResponse } from '#models/b2c';
import { BehaviorSubject } from 'rxjs';
import { Utility } from './utility';
import { MsalService } from '@azure/msal-angular';
import { isIE } from 'app/msal/utils';

@Injectable()
export class AuthService {
  private currentUser: LoggedUser = new LoggedUser();
  public b2cResp: B2CResponse;

  private _currentUser: BehaviorSubject<LoggedUser> =
    new BehaviorSubject<LoggedUser>(null);

  public readonly loggedUser$ = this._currentUser.asObservable();

  constructor(private msalService: MsalService, private userSvc: UserService) {}

  setCurrentUser(user: Partial<LoggedUser>): void {
    const newUser = { ...this.currentUser, ...user };
    this.currentUser = newUser;
    this._currentUser.next(newUser);
  }

  getFullName(): string {
    const acct = this.msalService.getAccount();
    return acct ? acct.name : '';
  }

  getEmail() {
    const acct = this.msalService.getAccount();
    return acct ? acct.userName : '';
  }

  isMsalAuthenticated() {
    const acct = this.msalService.getAccount();
    return acct !== null;
  }

  isAuthenticated(): boolean {
    if (this.isb2cLoged()) {
      return this.isAuthorized();
    }

    return this.isMsalAuthenticated();
  }

  isAuthorized(): boolean {
    return !!this.currentUser && this.currentUser.ID > 0;
  }

  getCurrentUser(): LoggedUser {
    return this.currentUser;
  }

  isSystemAdmin(): boolean {
    return !!this.currentUser && this.currentUser.IsSystemAdmin;
  }

  isBUAdmin(): boolean {
    return !!this.currentUser && this.currentUser.IsBUAdmin;
  }

  isOrgAdmin(): boolean {
    return !!this.currentUser && this.currentUser.IsOrgAdmin;
  }

  login(force: boolean = false): void {
    if (isIE) {
      this.msalService.loginRedirect();
    } else {
      this.msalService.loginPopup();
    }
  }

  logout(): void {
    this.currentUser = null;
    this.msalService.logout();
  }

  async loadUserInfo(): Promise<LoggedUser> {
    return await this.userSvc
      .getCurrentUser()
      .toPromise()
      .then((userInfo: LoggedUser) => {
        this.setCurrentUser(userInfo);
        return userInfo;
      });
  }

  isb2cLoged(): boolean {
    const b2cUser: B2CResponse = JSON.parse(
      localStorage.getItem('localB2CLogin')
    );
    this.b2cResp = b2cUser;
    const isExpired =
      !Utility.isValidObj(b2cUser) ||
      Math.floor(Date.now() / 1000) - b2cUser.dateInSeconds >
        b2cUser.expires_in;
    if (isExpired) {
      localStorage.removeItem('localB2CLogin');
    }
    return !isExpired;
  }

  setB2CToken(resp: B2CResponse) {
    resp.dateInSeconds = new Date().getTime() / 1000;
    this.b2cResp = resp;
    localStorage.removeItem('localB2CLogin'); // clear cache
    localStorage.setItem('localB2CLogin', JSON.stringify(this.b2cResp));
  }

  logoutB2C() {
    this.currentUser = null;
    this.b2cResp = null;
    localStorage.removeItem('localB2CLogin');
  }

  acceptDisclaimer(userId: number) {
    this.userSvc
      .acceptDisclaimer(userId)
      .pipe(
        finalize(() => {
          window.location.replace('');
        })
      )
      .subscribe();
  }

  /**
   * Determines if the current user has permissions for the given role
   * @param role
   */
  hasRole(role: Role): boolean {
    switch (role) {
      case Role.User: {
        return this.isAuthorized();
      }
      case Role.OrganizationUser: {
        return this.isOrgAdmin();
      }
      case Role.BusinessUnitAdmin: {
        return this.isBUAdmin();
      }
      case Role.SystemAdmin: {
        return this.isSystemAdmin();
      }
    }
  }
}
