import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { KMSRequest, KMSRequestList, KMSResponse, KMSResponseItem } from '#models/kms';

@Injectable({
  providedIn: 'root'
})
export class KMSService {

  constructor(
    private http: HttpClient
  ) { }

  public getKMSManagementOfChangeList(item: KMSRequestList) {
    return this.http.post<KMSResponse>(`${environment.apiEndpoint}/pssr/kms/GetManagementOfChangeList/`, item);
  }

  public getKMSManagementOfChange(item: KMSRequest): Observable<KMSResponseItem> {
    return this.http.post<KMSResponseItem>(`${environment.apiEndpoint}/pssr/kms/GetManagementOfChange/`, item);
  }

}
