import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { ToolbarSettingsModel } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
  selector: 'app-toggle-rich-input',
  templateUrl: './toggle-rich-input.component.html',
  styleUrls: ['./toggle-rich-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ToggleRichInputComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ToggleRichInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleRichInputComponent
  implements ControlValueAccessor, Validator {
  _displayRichEditor = false;
  @Input()
  set displayRichEditor(val) {
    this._displayRichEditor = val;
  }
  get displayRichEditor() {
    return this._displayRichEditor;
  }

  disabled = false;

  @Output() displayRichEditorChanged = new EventEmitter<boolean>();

  @Input() label = 'Description';
  @Input() toolbarSettings: ToolbarSettingsModel = {
    items: [
      'Bold',
      'Italic',
      'Underline',
      '|',
      'Formats',
      'Alignments',
      'OrderedList',
      'UnorderedList',
    ],
  };

  private _value = '';

  @Input()
  set value(val: string) {
    this._value = val;

    this.onChange(val);
    this.onTouched();
  }

  get value(): string {
    return this._value;
  }

  onChange = (_) => {
    // TODO implment
  }

  onTouched = () => {
    // TODO implment
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }
  writeValue(value) {
    this.value = value;
  }
  validate(): ValidationErrors | null {
    return null;
  }

  onToggleRichEditor(flag) {
    this.displayRichEditorChanged.emit(flag);
    this.displayRichEditor = flag;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
