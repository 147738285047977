import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from '#models/user';
import { UserService } from '#services/api';
import { Utility } from '#services/shared/utility';
import { SharedUser } from '#models/shared-user';

@Component({
  selector: 'app-shared-user-form',
  styleUrls: ['./shared-user-form.component.scss'],
  templateUrl: './shared-user-form.component.html'
})

export class SharedUserFormComponent implements OnChanges, OnDestroy {
  @Input() parentUserId: number;
  @Input() sharedUsers: SharedUser[];
  @Output() userListChanged = new EventEmitter<{ users: SharedUser[], isValid: boolean }>();
  @Input() disabled = false;

  private ngUnsubscribe$: Subject<any> = new Subject();
  public usersSuggestions: User[] = [];
  public form: FormGroup;

  public get validationForm(): FormGroup {
    return this.form;
  }

  public get userListGroup(): FormArray {
    return this.form.get('userList') as FormArray;
  }

  constructor(
    private fb: FormBuilder,
    private userService: UserService) {

    this.form = this.fb.group({ userList: this.fb.array([]) });
  }


  private populateSharedUsers() {
    this.sharedUsers?.forEach(u => this.userListGroup.push(this.createGroup(u)));
  }

  private hasChanged(previousValue: SharedUser[] = [], currentValue: SharedUser[] = []): boolean {

    if (previousValue.length !== currentValue.length) { return true; }

    for (let i = 0, len = previousValue.length; i < len; i += 1) {
      if (previousValue[i].ParentUserId !== currentValue[i].ParentUserId ||
        previousValue[i].UserID !== currentValue[i].UserID ||
        previousValue[i].FullName !== currentValue[i].FullName ||
        previousValue[i].Email !== currentValue[i].Email) {
        return true;
      }
    }

    return false;
  }



  ngOnChanges(changes: SimpleChanges) {
    if ('sharedUsers' in changes && !this.hasChanged(changes['sharedUsers'].previousValue, changes['sharedUsers'].currentValue)) {
      return;
    }

    if ('disabled' in changes && changes['disabled'].previousValue === changes['disabled'].currentValue) {
      return;
    }

    if (this.userListGroup) {
      this.userListGroup.clear();
    }
    this.populateSharedUsers();
  }

  private createGroup(user: SharedUser): FormGroup {
    return this.fb.group({
      ParentUserId: this.parentUserId,
      UserID: user?.UserID,
      Email: [user?.Email, Validators.required],
      FullName: user?.FullName,

      selectedValue: new FormControl({
        value: user?.Email && {
          FullName: `${user.FullName} (${user.Email})`
        }, disabled: this.disabled
      })
    });
  }

  private raiseUsersChangedEvent() {
    const users: SharedUser[] = [];
    for (const user of this.userListGroup.value) {
      users.push(<SharedUser>{
        Email: user.Email,
        FullName: user.FullName,
        UserID: user.UserID,
        ParentUserId: user.ParentUserId
      });
    }

    this.userListChanged.emit({
      users,
      isValid: this.form.valid
    });
  }

  addNewUser() {
    this.userListGroup.push(this.createGroup(null));
    this.raiseUsersChangedEvent();
  }

  deleteUser(index) {
    this.userListGroup.removeAt(index);
    this.raiseUsersChangedEvent();
  }

  selectUser(user: User, i: number): void {
    this.userListGroup.at(i).patchValue({
      ParentUserId: this.parentUserId,
      UserID: user.ID,
      Email: user.Email,
      FullName: user.FullName,

      selectedValue: user.Email && {
        FullName: `${user.FullName} (${user.Email})`
      }
    });

    this.raiseUsersChangedEvent();
  }

  clearSelectedUser(i: number) {
    this.userListGroup.at(i).patchValue({
      ParentUserId: 0,
      UserID: 0,
      Email: null,
      FullName: null,
      selectedValue: null
    });
  }

  getUsers({ query }) {
    this.userService.searchUser(query)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((data) => {
        this.usersSuggestions = data.map((user) => ({
          ...user,
          FullName: Utility.getFullName(user),
        }));
      });
  }

  onChange(): void {
    this.raiseUsersChangedEvent();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
