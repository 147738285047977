import { Component, OnInit } from '@angular/core';
import { BusinessUnitsService, GroupService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Router, ActivatedRoute } from '@angular/router';
import { Group } from '#models/group';
import { Utility } from '#services/shared/utility';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';


@Component({
  selector: 'app-group-create',
  templateUrl: './group-create.component.html',
  styleUrls: ['./group-create.component.scss'],
  providers: [TranslatePipe]
})
export class GroupCreateComponent implements OnInit {
  displayWarning: boolean;

  lstBusinessUnit: BusinessUnit[] = [];
  selectedBU: BusinessUnit;
  group: Group;
  buId: number;
  groupName: string;


  constructor(
    private toastrService: ToastrService,
    private buService: BusinessUnitsService,
    private groupService: GroupService,
    private activeRoute: ActivatedRoute,
    private _router: Router,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.activeRoute.params.subscribe(p => {
      this.buId = Number(p['buId']);
    });

    this.loadBusinessUnits();
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['admin/groups']);
  }

  loadBusinessUnits(): void {
    this.buService.getUserBUs().toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          const bus = this.lstBusinessUnit.filter(x => x.Id === this.buId);
          this.selectedBU = Utility.isValidListWithData(bus) ? bus[0] : this.lstBusinessUnit[0];
          this.buId = null;
        }
      },
      error => {
        this.toastrService.showError( error.error.ExceptionMessage);
      });
  }

  public async create() {

    const tempGroup = new Group();
    tempGroup.Name = this.groupName;
    tempGroup.BusinessUnitId = this.selectedBU.Id;
    this.groupName = '';

    this.groupService.addNewGroup(tempGroup).toPromise()
    .then((res: Group) => {
      this.toastrService.showSuccess( this.translatePipe.transform('New Group added'));

      setTimeout(() => {

        this._router.navigate(['/admin/group-edit', { groupId: res.Id }]);
      }, 1000);
    },
    error => {
      this.toastrService.showError( error.error.ExceptionMessage);
    });
  }

}
