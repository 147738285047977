import { SubCategory } from '#models/subcategory';
import { SubCategoryService } from '#services/api';
import { ToastrService } from '#services/shared/toastr.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs-compat/Subscription';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-subcategory-edit',
  templateUrl: './subcategory-edit.component.html',
})
export class SubcategoryEditComponent implements OnInit, OnDestroy {
  subCategoryForm: FormGroup;

  displayWarning = false;
  loadingCompleted = true;
  sub = new Subscription();
  subCategory = new SubCategory();

  constructor(
    private toastrService: ToastrService,
    private formBuild: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private subCategoryService: SubCategoryService,

  ) {
    this.subCategoryForm = this.formBuild.group({
      Name: ['', Validators.required],
      IsActive: [true],
    });
  }

  ngOnInit() {
    this.sub.add(
      this.route.params.subscribe((params) => {
        this.subCategory.Id = +params['subCategoryId'];

        this.loadSubCategory(this.subCategory.Id);
      })
    );
  }

  async loadSubCategory(subCategoryId: number): Promise<void> {
    try {
      this.loadingCompleted = false;


      this.subCategory = await this.subCategoryService
        .getById(subCategoryId)
        .toPromise();
      this.subCategoryForm.patchValue(this.subCategory);
    } catch (e) {
      // TODO implement
    } finally {
      this.loadingCompleted = true;

    }
  }

  updateSubcategory(subCategory: SubCategory): void {
    this.displayWarning = !this.subCategoryForm.valid;

    if (this.displayWarning) { return; }


    this.sub.add(
      this.subCategoryService
        .put({ Id: this.subCategory.Id, ...subCategory })
        .pipe(
          take(1),
        )
        .subscribe(
          () => {
            this.router.navigate(['/admin/subcategory']);
          },
          (err) => {
            this.toastrService.showError( err.error.ExceptionMessage);


          }
        )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
