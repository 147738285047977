<div class="box-shadow module" [appBUSwitcher]="selectedBU">
  <div class="row">
    <h2>
      <div class="text-left">
        <span *ngIf="deviceId > 0; else altPrefix">Edit</span>
        <ng-template #altPrefix>Add</ng-template> End Device Template
      </div>
    </h2>
  </div>
  <div class="row">
    <h3>
      <div class="text-left">
        Equipment Template: <strong>{{ equipmentTemplate?.Name }}</strong>
      </div>
    </h3>
    <hr />
  </div>

  <form
    (ngSubmit)="onSubmitEndDevice()"
    #endDeviceForm="ngForm"
    name="endDeviceForm"
  >


    <div>
      <header role="banner">
        <h4>{{ endDevice.Description }}</h4>
      </header>

      <div class="container-fluid">
        <div class="row">
          <div class="col-xs-6">
            <div class="form-group">
              <label>Tag Code:</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="endDevice.TagCode"
                name="tagcode"
                #tagcode="ngModel"
                [readonly]="endDevice.Id > 0 && !canEdit"
                maxlength="10"
                placeholder="Tag Code (max 10 characters)"
                required
              />
              <div class="row">
                <span
                  class="col-sm-10 text-danger field-validation-error"
                  *ngIf="!tagcode.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
                <span class="col-sm-2 charSpan"
                  >{{ endDevice.TagCode?.length }} of 10</span
                >
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="form-group">
              <label>Tag No:</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="endDevice.TagNo"
                name="tagno"
                #tagno="ngModel"
                [readonly]="endDevice.Id > 0 && !canEdit"
                maxlength="10"
                placeholder="Tag No. (max 10 characters)"
                required
              />
              <div class="row">
                <span
                  class="col-sm-10 text-danger field-validation-error"
                  *ngIf="!tagno.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
                <span class="col-sm-2 charSpan"
                  >{{ endDevice.TagNo?.length }} of 10</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label>Device Type - EAM Name - EAM Description:</label>
              <div>
                <select
                  class="form-control"
                  [(ngModel)]="endDevice.EndDeviceTypeId"
                  name="enddevicetype"
                  #enddevicetype="ngModel"
                  required
                  (change)="loadManufModelByEndDevTypeId()"
                  required
                >
                  <option
                    *ngFor="let endDeviceType of lstEndDeviceTypes"
                    [ngValue]="endDeviceType.Id"
                  >
                    {{ endDeviceType.Name }} / {{ endDeviceType.EAMName }} /
                    {{ endDeviceType.Description }}
                  </option>
                </select>
                <span
                  class="text-danger field-validation-error"
                  *ngIf="!enddevicetype.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label>Manufacturer - Model:</label>
              <div>
                <select
                  class="form-control"
                  [(ngModel)]="endDevice.ManufacturerModelId"
                  name="manufMode"
                  #manufMode="ngModel"
                  required
                >
                  <option
                    *ngFor="let manMod of manufModelList"
                    [ngValue]="manMod.Id"
                  >
                    {{ manMod.Manufacturer }} / {{ manMod.Model }}
                  </option>
                </select>
                <span
                  class="text-danger field-validation-error"
                  *ngIf="!manufMode.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-12">
            <div class="form-group">
              <label>Description :</label>
              <textarea
                cols="4"
                id="description"
                class="form-control"
                [(ngModel)]="endDevice.Description"
                name="description"
                #description="ngModel"
                maxlength="500"
                placeholder="Add description here (max 500 characters)"
                [readonly]="!canEdit"
                required
              >
              </textarea>
              <div class="row">
                <span
                  class="col-sm-10 text-danger field-validation-error"
                  *ngIf="!description.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
                <span class="col-sm-2 charSpan"
                  >{{ endDevice.Description?.length }} of 500</span
                >
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-xs-6">
            <label>Provided By:</label>
            <select
              class="form-control custom-select"
              [(ngModel)]="endDevice.ProvidedBy"
              name="providedBy"
              #providedBy="ngModel"
            >
              <option value="Chevron I&E">Chevron I&E</option>
              <option value="Chevron SFT">Chevron SFT</option>
              <option value="Contractor">Contractor</option>
              <option value="Vendor">Vendor</option>
            </select>
            <span
              class="text-danger field-validation-error"
              *ngIf="!providedBy.valid"
            >
              <span>{{ "This field is required" | translate }}.</span>
            </span>
          </div>
          <div class="col-xs-6">
            <label>Shipment:</label>
            <select
              class="form-control custom-select"
              [(ngModel)]="endDevice.Shipment"
              name="shipment"
              #shipment="ngModel"
            >
              <option value="Loose">Loose</option>
              <option value="Skidded">Skidded</option>
            </select>
            <span
              class="text-danger field-validation-error"
              *ngIf="!shipment.valid"
            >
              <span>{{ "This field is required" | translate }}.</span>
            </span>
          </div>
        </div>

        <div class="row row-top-pad">
          <div class="col-xs-6">
            <div class="form-group">
              <label>EAM Type</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="endDevice.EAMType"
                name="etype"
                #etype="ngModel"
                [readonly]="endDevice.Id > 0 && !canEdit"
                maxlength="50"
                placeholder="EAM Type (max 50 characters)"
              />
              <div class="row">
                <span class="col-sm-2 charSpan"
                  >{{ endDevice.EAMType?.length }} of 50</span
                >
              </div>
            </div>
          </div>
          <div class="col-xs-6">
            <div class="form-group">
              <label>EAM Criticality</label>
              <input
                type="text"
                class="form-control"
                [(ngModel)]="endDevice.EAMCriticality"
                name="criti"
                #criti="ngModel"
                [readonly]="endDevice.Id > 0 && !canEdit"
                maxlength="50"
                placeholder="EAM Criticality (max 50 characters)"
              />
              <div class="row">
                <span class="col-sm-2 charSpan"
                  >{{ endDevice.EAMCriticality?.length }} of 50</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />

    <div
      class="row"
      [hidden]="endDeviceForm.form.valid"
      class="alert alert-warning component-separator"
      role="alert"
    >
      <strong>Note :</strong>Please fill up the fields that are marked required.
      Please click Save.
    </div>
    <div *ngIf="canEdit" class="row text-right component-separator">
      <button
        type="submit"
        class="btn btn-primary"
        [disabled]="!endDeviceForm.form.valid"
      >
        Save
      </button>
    </div>
    <hr />
  </form>
  <input #isValid id="isValid" value="{{ endDeviceForm.form.valid }}" hidden />

  <div class="row text-left">
    <button
      type="button"
      class="btn btn-default"
      (click)="onRedirectButtonClick('admin/equipment-form')"
    >
      Back to List
    </button>
    &nbsp;
  </div>
</div>
