<div class="box-shadow module">
  <div class="row">
    <div class="row">
      <h3 class="text-left col-sm-9" style="margin-bottom: 0px">
        {{ "PARTICIPANTS" | translate }}<br />
        <small>{{ "Start adding participants" | translate }}</small>
      </h3>
      <div class="col-sm-3 padding-top-20">
        <div
          style="float: left; font-size: 14px"
          [innerHTML]="'PresentParticipantsInstruction' | translate"
        ></div>
      </div>
    </div>
    <hr />

    <form #pssrForm="ngForm">
      <div class="row">
        <div *ngFor="let r of roles">
          <div class="col-md-3">
            <p-card>
              <table>
                <tr>
                  <td>
                    <button
                      *ngIf="canManageParticipants"
                      type="button"
                      [disabled]="r.Disabled"
                      class="add-participant btn btn-sm btn-block btn-primary"
                      style="white-space: pre-wrap !important"
                      (click)="openAddParticipant(r)"
                    >
                      {{ "Add" | translate }}
                    </button>
                  </td>
                  <td>&nbsp;</td>
                  <td>
                    <label>{{ r.RoleLabel | translate: pssr.buObj }}</label>
                  </td>
                </tr>
              </table>
            </p-card>
            <br />
            <!-- participant label -->
            <div *ngFor="let u of pssr.PSSRUsers.Users">
              <div
                *ngIf="
                  u.Role == r.RoleId ||
                  (u.Role == r.RoleId - 2 && r.RoleId == 2)
                "
              >
                <div
                  class="ui-card ui-widget ui-widget-content ui-corner-all"
                  [class.participated]="u.Participated"
                >
                  <div class="ui-card-body">
                    <div class="ui-card-content">
                      <div class="row">
                        <div class="col-xs-8" style="padding: 0 0 0 0">
                          <p>{{ u.UserName }}</p>
                          <div style="font-size: 0.8em">
                            <em>{{ u.UserEmail }}</em
                            ><br />
                            <em>{{ u.UserCAI }}</em>
                          </div>
                        </div>
                        <ng-container
                          *ngIf="u.Role == r.RoleId - 2 && r.RoleId == 2"
                        >
                          <div
                            class="col-xs-8"
                            style="
                              padding: 0 0 0 0;
                              font-style: italic;
                              font-size: small;
                            "
                          >
                            {{'Action Item Assignee'|translate}}
                          </div>
                        </ng-container>
                        <div class="col-xs-4">
                          <button
                            *ngIf="(showEditButton(u) || pssr.CurrentUser.CanEdit)"
                            type="button"
                            class="btn btn-primary"
                            id="edit-participant"
                            style="white-space: pre-wrap !important"
                            (click)="openEditParticipant(u)"
                          >
                            {{ "Edit" | translate }}
                          </button>
                        </div>
                      </div>
                      <div class="participant-signature">
                        <img
                          *ngIf="u.SignatureImage"
                          src="data:image/png;base64,{{ u.SignatureImage }}"
                          width="50%"
                          (click)="btnExpandImage(u.SignatureImage)"
                          style="cursor: pointer"
                        />
                        <br *ngIf="u.SignatureDate != null" />
                        {{
                          u.SignatureDate != null
                            ? u.SignatureDate.toLocaleString()
                            : ""
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <br />
              </div>
            </div>
          </div>
        </div>

        <p-dialog
          [style]="{'width':'550px','height':'300px'}"
          styleClass="add-members-dialog"
          [(visible)]="displayAddModal"
          [maximizable]="true"
          showEffect="fade"
          [modal]="true"
        >
          <p-header>
            {{ "Selectuserfor" | translate }} {{ selectedRole }}
            {{ "Role" | translate }}
          </p-header>

          <input
            *ngIf="!displayChev"
            type="radio"
            id="lookup"
            class="dialog-radio"
            name="ai-assign"
            [value]="2"
            [(ngModel)]="participantType"
            (click)="collapseDialog()"
            [disabled]="displayChev"
          />
          <label *ngIf="!displayChev" for="lookup"
            >{{ "ChevronDirectory/SharedUsers" | translate }}&nbsp;
            &nbsp;</label
          >

          <input
            *ngIf="!displayNNonChev"
            type="radio"
            id="nonChevron"
            class="dialog-radio"
            name="ai-assing"
            [value]="3"
            [(ngModel)]="participantType"
            (click)="collapseDialog()"
            [disabled]="displayNNonChev"
          />
          <label *ngIf="!displayNNonChev" for="nonChevron"
            >{{'New Non-Chevron'|translate}}</label
          >

          <div *ngIf="participantType === ParticipantType.ChevronOrSharedUser">
            <p-autoComplete
              [(ngModel)]="selectedUser"
              [suggestions]="participants"
              (completeMethod)="getParticipants($event)"
              name="usr"
              required
              #usr="ngModel"
              field="FullName"
              delay="1000"
              [style]="{ width: '100%' }"
              [inputStyle]="{ width: '100%' }"
              [placeholder]="'typeusercainame'|translate"
              [minLength]="1"
              (onClear)="collapseDialog()"
              (onSelect)="addChevronUser(selectedUser)"
              appendTo = "body"
            >
              <ng-template let-participant pTemplate="item">
                {{ participant | participantType }}
              </ng-template>
            </p-autoComplete>
          </div>

          <input
            *ngIf="participantType === ParticipantType.External"
            type="text"
            class="form-control"
            [(ngModel)]="otherParticipant"
            (change)="addExternalUser(otherParticipant)"
            name="otherP"
            #otherP="ngModel"
          />

          <br />
          <br />
          <ng-container *ngIf="allowMulti && participantType == ParticipantType.ChevronOrSharedUser">
            <div *ngFor="let item of possibleSelectedUser">
              <p>
                {{ item | participantType }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="allowMulti && participantType === ParticipantType.External">
            <div *ngFor="let item of possibleOtherParticipant">
              <p>
                {{ item }}
                <button
                  type="button"
                  class="close"
                  aria-label="Close"
                  (click)="removePossibleParticipant(item)"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </p>
            </div>
          </ng-container>
          <p-footer class="row">
            <div class="col-xs-9" *ngIf="maxMessage" style="text-align: left">
              {{ maxMessage }}
            </div>
            <div class="col-xs-3" [class.col-xs-push-9]="!maxMessage">
              <button
                type="button"
                [disabled]="disableAdd"
                pButton
                [label]="'Add' | translate"
                (click)="addParticipant()"
                style="width: 80px; white-space: pre-wrap !important"
              ></button>
            </div>
          </p-footer>
        </p-dialog>

        <p-dialog
          [(visible)]="displayEditModal"
          showEffect="fade"
          [modal]="true"
        >
          <p-header> {{'Edit Participant'|translate}} </p-header>
          <div>
            {{ participantToEdit.UserName }}
          </div>
          <div class="row" *ngIf="participantToEdit">
            <ng-container
              *ngIf="
              (pssr.CurrentUser.CanEdit || pssr.CurrentUser.IsBusinessUnitAdmin) &&
                !disablePartic
              "
            >
              <label>{{ "Role" | translate }} :</label>
              
              <select
                class="form-control"
                name="newRole"
                #newRole
                style="margin-bottom: 5px"
                (change)="onSelectNewRole($event)"
              >
                <ng-container *ngFor="let r of roles">
                  <option
                    *ngIf="
                      r.RoleId !== 4 ||
                      (r.RoleId === 4 &&
                        (participantToEdit.Role === 4 ||
                          showFinalRole ||
                          allowMultiApprover))
                    "
                    [selected]="participantToEdit.Role == r.RoleId"
                    [value]="r.RoleId"
                  >
                    {{ r.RoleName | translate: pssr?.buObj }}
                  </option>
                </ng-container>
              </select>
              
            </ng-container>

            <div
              *ngIf="
                canCaptureSignature &&
                !showSignature &&
                !participantToEdit.SignatureGUID &&
                pssr.Status &gt; PSSRStatus.Draft
              "
              class="row"
              style="text-align: center"
            >
              <button
                type="button"
                pButton
                id="capture-signature"
                [label]="'Capture Signature' | translate"
                (click)="captureSignature()"
                style="white-space: pre-wrap !important"
              ></button>
            </div>
            <div
              *ngIf="canCaptureSignature && participantToEdit.SignatureGUID"
              class="row"
              style="text-align: center"
            >
              <button
                type="button"
                pButton
                [label]="'Remove Signature' | translate"
                (click)="removesign()"
                style="white-space: pre-wrap !important"
              ></button>
            </div>
            <div
              *ngIf="showSignature"
              #signatureTemplate
              class="signature-template"
            >
              <ng-container *ngIf="signedbycurrentuser">
                <div><strong>{{ "Signed by" | translate }}:</strong></div>
                <div><strong>{{  participantToEdit.UserName }}</strong></div>
              </ng-container>
              <ng-container *ngIf="!signedbycurrentuser">
                <div><strong>{{ "Signed On Behalf Of" | translate }}:</strong></div>
                <div><strong>{{  participantToEdit.UserName }}</strong></div>
                <br>
                <div><strong>{{ "Signed by" | translate }}:</strong></div>
                <div><strong>{{  pssr.CurrentUser.UserName }}</strong></div>
              </ng-container>
              <br />
              <div>
                {{ "Signed on" | translate }}:
                {{ today | date: "yyyy-MM-dd, hh:mm:ss z" }}
              </div>
            </div>
          </div>

          <p-footer class="row">
            <ng-container *ngIf="!showSignature">
              <button
                *ngIf="
                  !participantToEdit.SignatureGUID &&
                  (pssr.CurrentUser.CanEdit || pssr.CurrentUser.IsBusinessUnitAdmin) &&
                  !disablePartic
                "
                type="button"
                pButton
                id="delete-participant"
                [label]="'Delete' | translate"
                (click)="removeParticipant(participantToEdit)"
                style="
                  white-space: pre-wrap !important;
                  background-color: #c9302c;
                  border-color: #ac2925;
                "
              ></button>
              
              <button
                type="button"
                pButton
                [label]="'Save' | translate"
                (click)="editParticipant(editParticipantRole)"
                style="white-space: pre-wrap !important"
              ></button>
            </ng-container>
            <ng-container *ngIf="showSignature">
              <button
                type="button"
                pButton
                [label]="'Cancel' | translate"
                (click)="showSignature = false"
                style="
                  width: 80px;
                  white-space: pre-wrap !important;
                  background-color: #c9302c;
                  border-color: #ac2925;
                "
              ></button>
              <button
                type="button"
                pButton
                [label]="'Confirm' | translate"
                (click)="sign()"
                style="width: 80px; white-space: pre-wrap !important"
              ></button>
            </ng-container>
          </p-footer>
        </p-dialog>
      </div>
    </form>
  </div>
</div>

<p-dialog
  [(visible)]="displayImage"
  showEffect="fade"
  [modal]="true"
  (onAfterHide)="btnCollapseImage()"
>
  <p-header></p-header>
  <div style="text-align: center">
    <img style="width: 90%" src="{{ imagePhoto }}" />
  </div>
  <p-footer></p-footer>
</p-dialog>
