export class SubCategory {
  Id: number;
  Name: string;
  IsActive: boolean;
}

export interface ReadCategorySubCategoriesDTO {
  Id: number;
  Name: string;
  IsActive: boolean;
  BusinessUnitID: number;
  BusinessUnitName: string;
}

export interface ReadSubCategoryDTO {
  Id: number;
  Name: string;
  IsActive: boolean;
  Categories: ReadCategorySubCategoriesDTO[];
}
