<div class="box-shadow module"  [appBUSwitcher]="selectedBU">

    <h2>Create {{ hierarchyLabel2 }}</h2>
    <hr>
    <div class="form-horizontal">
        <div class="form-group">
            <label class="control-label col-md-2">Name</label>
            <div class="col-md-10">
                <input class="form-control text-box single-line" [(ngModel)]="subArea.Name">
                <span class="text-danger field-validation-error" *ngIf="displayWarningName">
                    {{'This field is required' | translate }}
                </span>
            </div>
        </div>
        <div class="form-group">
            <div class="col-md-offset-2 col-md-10">
                <a class="btn btn-default" style="width: 100px;" (click)="createSubArea()">Create</a>
            </div>
        </div>
    </div>
    <div>
        <a (click)="onRedirectButtonClick(subArea.AreaId)" style="cursor: pointer;">Back to {{ hierarchyLabel2 }}</a>
    </div>
</div>
