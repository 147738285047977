import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApiModule } from '#services/api/api.module';
import { SharedModule } from '#services/shared/shared.module';
import {
  AreaService,
  SubAreaService,
  FacilityService,
  PSSRResponseService,
  PSSRImportService,
} from '#services/api/index';
import { ActionItemCategoryService } from '#services/api/action-item-category.service';
import {
  PssrCreateComponent,
  PssrEditComponent,
  PssrDescriptionComponent,
  ImportComponent,
} from './index';
import { PssrActionItemListComponent } from '#pages/pssr/pssr-action-item-list/pssr-action-item-list.component';
import { PssrProjectComponent } from '#pages/pssr/pssr-project/pssr-project.component';
import { SharedPagesModule } from '#pages/shared/shared.pages.module';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { AccordionModule } from 'primeng/accordion';
import { PanelModule } from 'primeng/panel';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { MultiSelectModule } from 'primeng/multiselect';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';

// dropdown
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// pssr-project
import { PssrProjectDashboardComponent } from './pssr-project-dashboard/pssr-project-dashboard.component';
import { PssrProjectDetailComponent } from './pssr-project-detail/pssr-project-detail.component';
import { PssrProjectDetailCopyEquipComponent } from './pssr-project-detail-copy-equip/pssr-project-detail-copy-equip.component';
import { ManufacturerModelPipe } from '../../components/pipes/manufacturerModel.pipe';
import { GroupStatusPipe } from '../../components/pipes/groupStatusList.pipe';
import {FilterPipe} from '../../components/pipes/filterPipe.pipe';
import { ComponentsModule } from '../../components/components.module';
import { EndDeviceByEquipmentPipe } from '../../components/pipes/endDeviceByEquipment.pipe';
import { ABCheckRespByEquipPipe } from '../../components/pipes/abcheckRespByEquip.pipe';
import { ProjectMembersComponent } from '../shared/project-members/project-members.component';
import { InputTextModule } from 'primeng/inputtext';
import { MessageService, ConfirmationService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ABCheckRespIsEndDev } from '../../components/pipes/abcheckRespIsEndDev.pipe';
import { PssrKmsComponent } from './pssr-kms/pssr-kms.component';
import { PssrActionItemComponent } from './pssr-action-item/pssr-action-item.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { LanguageLoader } from '#services/shared/language-loader';
import { LanguageParser } from '#services/shared/language-parser';
import { DropdownModule } from 'primeng/dropdown';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    // Components
    PssrCreateComponent,
    ImportComponent,
    PssrEditComponent,
    PssrActionItemListComponent,
    PssrProjectComponent,
    PssrDescriptionComponent,
    PssrProjectDashboardComponent,
    PssrProjectDetailComponent,
    PssrProjectDetailCopyEquipComponent,
    ManufacturerModelPipe,
    GroupStatusPipe,
    FilterPipe,
    EndDeviceByEquipmentPipe,
    ABCheckRespByEquipPipe,
    ProjectMembersComponent,
    ABCheckRespIsEndDev,
    PssrKmsComponent,
    PssrActionItemComponent,
  ],
  imports: [
    // Angular
    CommonModule,
    ReactiveFormsModule,

    // App
    ApiModule,
    SharedModule,
    FormsModule,
    SharedPagesModule,
    ComponentsModule,

    // PrimeNG
    CheckboxModule,
    CardModule,
    AccordionModule,
    DialogModule,
    ConfirmDialogModule,
    AutoCompleteModule,
    MultiSelectModule,
    CalendarModule,
    PanelModule,
    RadioButtonModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    InputTextModule,
    MessageModule,
    MessagesModule,
    DropdownModule,
    MatExpansionModule,
    MatInputModule,
    BrowserAnimationsModule,

    NgxPaginationModule,

    ComponentsModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: LanguageLoader },
      parser: {
        provide: TranslateParser,
        useClass: LanguageParser,
      },
      useDefaultLang: true,
    }),
  ],
  providers: [
    AreaService,
    SubAreaService,
    FacilityService,
    PSSRResponseService,
    ActionItemCategoryService,
    MessageService,
    ConfirmationService,
    PSSRImportService
  ],
})
export class PssrPagesModule {}
