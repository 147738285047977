import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan, TaskPlanType } from '#models/index';

import { Router } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';

@Component({
  selector: 'app-task-plan-create',
  templateUrl: './task-plan-create.component.html',
  styleUrls: ['./task-plan-create.component.scss'],
})
export class TaskPlanCreateComponent implements OnInit {
  //#region VARIABLES

  taskPlan: TaskPlan = new TaskPlan();
  lstBusinessUnit: BusinessUnit[] = [];
  displayWarningNote: boolean;
  displayWarningCode: boolean;
  displayWarningDesc: boolean;
  displayWarningBusinessUnit: boolean;

  selectedBU = new BusinessUnit();

  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,
    private businessUnitsSvc: BusinessUnitsService,

    private router: Router
  ) {}

  ngOnInit() {
    this.displayWarningNote = false;
    this.displayWarningCode = false;
    this.displayWarningDesc = false;
    this.displayWarningBusinessUnit = false;
    this.loadBusinessUnits();
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/task-plan']);
  }

  loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserBUs().subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length === 0) {
        this.lstBusinessUnit = [];
      }
    });
  }

  async createTaskPlan() {
    this.taskPlan.TaskPlanType = TaskPlanType.PSSR;
    this.displayWarningCode =
      this.taskPlan.Code === undefined ||
      this.taskPlan.Code === null ||
      this.taskPlan.Code.length === 0;
    // tslint:disable-next-line: max-line-length
    this.displayWarningDesc =
      this.taskPlan.Description === undefined ||
      this.taskPlan.Description === null ||
      this.taskPlan.Description.length === 0;
    // tslint:disable-next-line: max-line-length
    this.displayWarningBusinessUnit =
      this.taskPlan.BusinessUnitId === undefined ||
      this.taskPlan.BusinessUnitId === null ||
      this.taskPlan.BusinessUnitId < 1;

    if (
      !this.displayWarningCode &&
      !this.displayWarningDesc &&
      !this.displayWarningBusinessUnit
    ) {
      this.taskPlanSvc
        .createTaskPlan(this.taskPlan)
        .toPromise()
        .then(() => {
          this.onRedirectButtonClick();
        });
    }
  }
  onSelectBusinessUnit(buId) {
    this.selectedBU = this.lstBusinessUnit.find(({ Id }) => Id == buId);
  }
}
