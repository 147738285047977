<div class="box-shadow module">
  <div class="row">
    <h2>{{ "Action Items" | translate }}</h2>
  </div>


  <div *ngIf="selectedBU" class="row">
    <div class="col-xs-1"></div>
    <div class="col-xs-10 row">
      <div class="row">
        <div class="col-xs-4">
          <div class="p3Class col-xs-2 text-center filter-by-priority">
            <label>{{ selectedBU?.P3Label || "P3" }}</label>
          </div>
          <div class="col-xs-10" style="padding-top: 7px">
            <label> {{ "Mustbecompletedbeforetostartup" | translate }}</label>
          </div>
        </div>
        <div class="col-xs-4">
          <div class="p4Class col-xs-2 text-center filter-by-priority">
            <label>{{ selectedBU?.P4Label || "P4" }}</label>
          </div>
          <div class="col-xs-10" style="padding-top: 7px">
            <label>
              {{ "Projectteamwillcompleteafterstartup" | translate }}</label
            >
          </div>
        </div>
        <div *ngIf="selectedBU?.ShowP5" class="col-xs-4">
          <div class="p5Class col-xs-2 text-center filter-by-priority">
            <label>{{ selectedBU?.P5Label || "P5" }}</label>
          </div>
          <div class="col-xs-10" style="padding-top: 7px">
            <label
              >{{ "Actionwillbetransferredtooperations" | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xs-1"></div>
  </div>

  <!-- filters 1st Line-->
  <div class="row">
    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ "Business Unit" | translate }}</label>
        <p-dropdown
          id="selectBusinessUnit"
          [appBUSwitcher]="selectedBU"
          [options]="lstBUs"
          [(ngModel)]="selectedBU"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          placeholder="{{ 'Select Option' | translate }}"
          (onChange)="changeBU()"
          (onClick)="loadBUs()"
          optionLabel="Name"
          appendTo="body"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ (selectedBU?.OrganizationLabel || "Organization") | translate }} :</label>
        <p-dropdown
          id="selectOrganization"
          [options]="lstOrgs"
          [(ngModel)]="filter.Organization"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          placeholder="{{ 'Select Option' | translate }}"
          optionLabel="Name"
          appendTo="body"
          (onChange)="changeOrg()"
          (onClick)="loadOrganizations()"
          [disabled]="!(selectedBU !== null && selectedBU?.Id &gt; 0)"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ (selectedBU?.HierarchyLabel1 || "Area") | translate }}</label>
        <p-dropdown
          id="selectArea"
          [options]="lstArea"
          [(ngModel)]="filter.Area"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          optionLabel="Name"
          placeholder="{{ 'Select Option' | translate }}"
          appendTo="body"
          (onChange)="changeHierarchyOne()"
          (onClick)="loadHierarchyOne()"
          [disabled]="!(filter.Organization !== null && filter.Organization?.Id  &gt; 0)"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ (selectedBU?.HierarchyLabel2 || "SubArea" ) | translate }}</label>
        <p-dropdown
          id="selectSubArea"
          [options]="lstSubArea"
          [(ngModel)]="filter.SubArea"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          (onChange)="changeHierarchyTwo()"
          optionLabel="Name"
          appendTo="body"
          placeholder="{{ 'Select Option' | translate }}"
          (onClick)="loadHierarchyTwo()"
          [disabled]="!(filter.Area && filter.Area?.Id  &gt; 0)"
        >
        </p-dropdown>
      </div>
    </div>

    <div *ngIf="selectedBU?.ShowHierarchyLabel3" class="col-xs-3">
      <div class="form-group">
        <label>{{ (selectedBU?.HierarchyLabel3 || "Facility") | translate }}</label>
        <p-dropdown
          id="selectFacility"
          [options]="lstFacility"
          [(ngModel)]="filter.Facility"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          optionLabel="Name"
          appendTo="body"
          placeholder="{{ 'Select Option' | translate }}"
          (onClick)="loadHierarchyThree()"
          [disabled]="!(filter.SubArea && filter.SubArea?.Id  &gt; 0)"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ "Status" | translate }}</label>
        <br />
        <p-multiSelect
          id="multiSelectStatus"
          [options]="allStatus"
          [(ngModel)]="filter.Status"
          [style]="{ width: '100%', minHeight: '34px' }"
        >
          <ng-template let-item pTemplate="item">
            {{ item.label | translate }}</ng-template
          ><ng-template let-items pTemplate="selectedItems"
            >
            <span *ngIf="items?.length === 0">{{ "Select option" | translate}}</span>
            <span *ngFor="let selectedItem of items; let i = index"
              ><span *ngIf="i > 0">,&nbsp;</span
              >{{ ResponseItemStatus[selectedItem] | translate }}</span
            >
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ "Priority" | translate }}</label>
        <br />
        <p-multiSelect
          id="selectPriority"
          [options]="allPriorities"
          [(ngModel)]="filter.Priority"
          defaultLabel="{{ 'Select Option' | translate}}"
          [style]="{ width: '100%', minHeight: '34px' }"
        >
        </p-multiSelect>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
          <label> {{ getPssrStatusLabel() }}</label>
        <br />
        <p-multiSelect
          id="selectPssrStatus"
          [options]="pssrStatus"
          [(ngModel)]="filter.PSSRStatus"
          [style]="{ width: '100%', minHeight: '34px' }"
        >
          <ng-template let-item pTemplate="item">
            {{ item.label | translate }}</ng-template
          ><ng-template let-items pTemplate="selectedItems">
            <span *ngIf="items?.length === 0">{{ "Select option" | translate}}</span>
            <span *ngFor="let selectedItem of items; let i = index"
              ><span *ngIf="i > 0">, &nbsp;</span
              >{{ PSSRStatus[selectedItem] | translate }}</span
            >
          </ng-template></p-multiSelect
        >
      </div>
    </div>

    <div *ngIf="selectedBU?.ShowPssrType" class="col-xs-3">
      <div class="form-group">
        <label>{{ "Type" | translate }}</label>
        <br />
        <p-multiSelect
          id="selectPssrType"
          [options]="pssrTypes"
          [(ngModel)]="filter.PssrTypeIds"
          [style]="{ width: '100%', minHeight: '34px' }"
        >
          <ng-template let-item pTemplate="item">
            {{ item.label | translate }}</ng-template
          ><ng-template let-items pTemplate="selectedItems">
            <span *ngIf="items?.length === 0">{{ "Select option" | translate}}</span>
            <span *ngFor="let selectedItem of items; let i = index"
              ><span *ngIf="i > 0">,&nbsp;</span
              >{{ getPssrType(selectedItem) | translate }}</span
            >
          </ng-template>
        </p-multiSelect>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ selectedBU?.PSSRLabel | translate }}</label>
        <br />
        <p-autoComplete
          id="selectpssrAC"
          [(ngModel)]="filter.PSSRName"
          [suggestions]="lstPssrAutocomplete"
          (completeMethod)="getPssrAutocomplete($event)"
          name="pssrAC"
          #pssrAC="ngModel"
          placeholder=""
          delay="1000"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [minLength]="1"
          [disabled]="!(selectedBU !== null && selectedBU?.Id &gt; 0)"
        ></p-autoComplete>
      </div>
    </div>

    <div
      *ngIf="selectedBU?.ShowProject"
      class="col-xs-3"
    >
      <div class="form-group">
        <label>{{ "Project" | translate }}</label>
        <ng-container *ngIf="!selectedBU?.ShowProjectInput">
          <p-dropdown
            id="selectProject"
            [options]="lstProject"
            [(ngModel)]="filter.Project"
            [styleClass]="'text-left'"
            [style]="{ width: '100%' }"
            optionLabel="Name"
            appendTo="body"
            placeholder="{{ 'Select Option' | translate }}"
            (onClick)="loadProject()"
          [disabled]="!(filter.Organization && filter.Organization?.Id  &gt; 0)"
          >
          </p-dropdown>
        </ng-container>
        <input
          id="projNum"
          *ngIf="selectedBU?.ShowProjectInput"
          type="text"
          class="form-control"
          [(ngModel)]="filter.ProjectNumber"
          name="projNum"
          #projNum="ngModel"
        />
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ "Template" | translate }}</label>
        <p-dropdown
          id="selectTemplate"
          [options]="lstTaskPlan"
          [(ngModel)]="filter.TaskPlan"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          optionLabel="Description"
          appendTo="body"
          placeholder="{{ 'Select Option' | translate }}"
          (onClick)="loadTaskPlan()"
          [disabled]="!(filter.Organization && filter.Organization?.Id  &gt; 0)"
        >
        </p-dropdown>
      </div>
    </div>
  </div>

  <!-- filters 2nd Line -->
  <div class="row">
    <div class="form-group col-xs-3" style="padding-left: 0px">
      <div class="form-group">
        <label>{{ "Assigned to" | translate }}</label>
        <br />
        <p-autoComplete
          [(ngModel)]="selectedUser"
          [suggestions]="users"
          (completeMethod)="getAsigneeAutocomplete($event)"
          id="usrAC"
          name="usrAC"
          #usrAC="ngModel"
          field="FullName"
          placeholder=""
          delay="1000"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [minLength]="1"
        >
        </p-autoComplete>
      </div>
    </div>
    <div class="form-group col-xs-3" style="padding-left: 0px">
      <div class="form-group">
        <label [innerHTML]="'AssignedToLeadFinalApprover' | translate"></label>
        <br />
        <p-autoComplete
          [(ngModel)]="selectedLead"
          [suggestions]="leads"
          (completeMethod)="getLeadAutocomplete($event)"
          id="leadAC"
          name="leadAC"
          #usrAC="ngModel"
          field="FullName"
          placeholder=""
          delay="1000"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [minLength]="1"
        >
        </p-autoComplete>
      </div>
    </div>
    <div class="form-group col-xs-3">
      <input id="showImagesChkBox" class="col-xs-1" type="checkbox" [(ngModel)]="filter.ShowImages" />
      <label class="col-xs-11 padding-left-5 padding-right-0">
        &nbsp; {{ "AutoLoadImages" | translate }}&nbsp;
      </label>
    </div>
    <div *ngIf="isAdmin" class="form-group col-xs-3">
      <input id="showDeletedPssrChkBox"
        class="col-xs-1"
        type="checkbox"
        [(ngModel)]="filter.ShowDeletedPssr"
      />
      <label class="col-xs-11 padding-left-5 padding-right-0">
        <span [innerHTML]="'ShowDeletedPSSR' | translate: selectedBU"></span>
      </label>
    </div>
  </div>

  <!-- filters buttons -->
  <div class="row">
    <div class="col-xs-6 col-xs-offset-6">
      <div class="row">
        <div class="col-xs-3 form-group">
          <button
            id="loadImagesBtn"
            type="button"
            class="btn btn-info whiteSpaceBtn"
            style="width: 100%"
            (click)="loadImages()"
            [disabled]="showLoadingImagesIcon"
          >
            {{ "Load Images" | translate }}
          </button>
        </div>
        <div class="col-xs-3 form-group whiteSpaceBtn">
          <button
            id="filterBtn"
            type="button"
            class="btn btn-primary"
            style="width: 100%"
            (click)="filterInfo()"
            [disabled]="!(selectedBU?.Id)"
          >
            {{ "Filter" | translate }}
          </button>
        </div>
        <div class="col-xs-3 form-group">
          <button
            id="clearFilterBtn"
            type="button"
            class="btn btn-warning whiteSpaceBtn"
            style="width: 100%"
            (click)="clearFilter()"
          >
            {{ "Clear Filter" | translate }}
          </button>
        </div>
        <div class="col-xs-3 form-group">
          <button
            id="exprtExcelBtn"
            type="button"
            class="btn btn-success whiteSpaceBtn"
            style="width: 100%"
            (click)="export()"
            [disabled] ="!(finalListPssr.length &gt; 0)"
          >
            {{ "Export Excel" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="showInitialFilterIndicator"
    class="row"
    class="alert alert-warning"
    role="alert"
  >
    {{ "FilteredActionItemsInitialWarning" | translate }}
  </div>
 
  <!-- Pagination Control -->
  <ng-container [ngTemplateOutlet]="paginationControl"></ng-container>

  <!-- item list -->
  <div>
    <app-pssr-action-item
      *ngFor="let p of finalListPssr | paginate: { id: 'paginator', itemsPerPage: pagination.pageSize, currentPage: pagination.pageNumber, totalItems: pagination.totalCount }"
      [shouldLoadActionItemImages]="filter.ShowImages"
      [selectedBU]="selectedBU"
      [p]="p">
    </app-pssr-action-item>      
  </div>
  
  <!-- Pagination Control -->
  <ng-container [ngTemplateOutlet]="paginationControl"></ng-container>
</div>

<!-- expand image dialog box-->
<p-dialog
  [(visible)]="displayImage"
  showEffect="fade"
  [modal]="true"
  (onAfterHide)="btnCollapseImage()"
>
  <p-header></p-header>
  <div style="text-align: center">
    <img style="width: 90%" src="{{ imagePhoto }}" />
  </div>
  <p-footer></p-footer>
</p-dialog>

<ng-template #paginationControl>
  <div class="pagination-container" *ngIf="pagination.totalCount > pagination.pageSize">
    <pagination-controls id="paginator" class="pagination-control" (pageChange)="onPageChange($event)" maxSize="{{pagination.pageSize}}"></pagination-controls>
    <p>{{ pagination.currentRange }}</p>
  </div>
</ng-template>
