import { EndDeviceTemplate } from '#models/end-device-template';
import { BusinessUnit } from './business-unit';

export class EquipmentTemplate {
    Id: number;
    Description: string;
    Name: string;
    Type: string;
    EndDeviceTemplates: EndDeviceTemplate[];
    BusinessUnitId: number;
    BusinessUnit: BusinessUnit;
    TagCode: string;
    TagNo: string;
    EAMName: string;
    EAMClass: string;
    EAMType: string;
    EAMCriticality: string;
    IsInEAM: boolean;
}
