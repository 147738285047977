export class PSSRFile {

  static readonly validImageExtensions = [
    'image/gif',
    'image/png',
    'image/jpg',
    'image/jpeg',
    'image/bmp',
  ];

  static readonly validBinaryExtensions = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.ms-excel',
    'application/octet-stream',
    'application/pdf',
    'video/mp4',
    'application/acad',
    'application/x-dwf'
  ];

  static readonly acceptableExtensions = [
    ...PSSRFile.validImageExtensions,
    ...PSSRFile.validBinaryExtensions,
  ].join();
  Id: number;
  File: string;
  FileType: number;
  PSSRID: number;
  GUID: string;
  Description: string;
  CreateDate: Date;
  ContentType: string;
  IsImage: boolean;
  SelectedPages?: number[];
}


