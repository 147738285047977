<div class="box-shadow module">
  <h2 class="text-center">{{ (pageFilter.selectedBU?.EventLabel | translate) || ("Projects" | translate) }}</h2>


  <div class="row component-separator">
    <form #searchForm="ngForm">
      <div class="group">
        <div class="group-header">
          <h3>{{ "Filter" | translate }}</h3>
        </div>

        <div class="group-item col-md-3">
          <label>{{ "Business Unit" | translate }}</label>
          <p-dropdown [options]="lstBUs" [(ngModel)]="pageFilter.selectedBU" [style]="{ width: '100%' }"
            optionLabel="Name" id="bu" name="bu" appendTo="body" (onChange)="changeBusinessUnit()"
            (onClick)="loadBusinessUnits()" placeholder="{{ 'Select Option' | translate }}">
          </p-dropdown>
        </div>

        <div class="group-item col-md-3">
          <label>{{ (pageFilter.selectedBU?.OrganizationLabel || 'Organization') | translate }}</label>
          <p-dropdown [options]="lstOrgs" [(ngModel)]="pageFilter.selectedOrg" [style]="{ width: '100%' }"
            optionLabel="Name" appendTo="body" id="org" name="org" (onClick)="loadOrganizations()"
            [disabled]="!(pageFilter.selectedBU !== null && pageFilter.selectedBU?.Id &gt; 0)"
            placeholder="{{ 'Select Option' | translate }}">
          </p-dropdown>
        </div>

        <div class="group-item col-md-3">
          <label>{{ "Status" | translate }}</label>
          <p-multiSelect id="projectStatuses" [options]="projStatusList" [(ngModel)]="pageFilter.selectedStatus"
            name="projectStatuses" [style]="{ width: '100%', minHeight: '34px' }">
            <ng-template let-item pTemplate="item">
              {{ item.label | translate }}</ng-template>
            <ng-template let-items pTemplate="selectedItems">
              <span *ngFor="let selectedItem of items; let i = index">
                <span *ngIf="i > 0">,</span> &nbsp;
                {{ ProjectStatus[selectedItem] | translate }}
              </span>
            </ng-template>
          </p-multiSelect>
        </div>

        <div class="group-item col-md-3">
          <label>{{ "ProjectName" | translate }}</label>
          <input type="text" class="form-control" [(ngModel)]="pageFilter.searchText" name="searchProj" maxlength="50">
        </div>

        <div class="group-item col-md-3" *ngIf="showDeleteOptions">
          <label>
            &nbsp;
            {{ "Show deleted projects" | translate }}</label>
          <input [(ngModel)]="pageFilter.searchDeletedProjects" id="isProjectDeleted" name="isProjectDeleted"
            class="col-xs-1" type="checkbox" />
          <p-calendar [disabled]="!pageFilter.searchDeletedProjects" id="dateRangeOfProjects" name="dateRangeOfProjects"
            [(ngModel)]="pageFilter.deletedProjectsRangeDate" selectionMode="range" dateFormat="mm/dd/yy"
            showButtonBar="true" class="paddingAligned col-xs-11" [locale]="'PrimeNgCalendarLabels' | translate">
          </p-calendar>
        </div>
      </div>
    </form>
  </div>


  <!-- QAQC Integration -->
  <ng-container *ngIf="showQAQC">
    <div class="group">
      <div class="group-header">
        <h3>{{ "QAQC Import" | translate }}</h3>
      </div>

      <div class="group-item col-md-3">
        <label>{{ pageFilter.selectedBU?.EventLabel | translate }} </label>
        <p-dropdown id="qaqcEventItem" [options]="lstqaqcEvents" [(ngModel)]="qaqcEventItem" [styleClass]="'text-left'"
          [style]="{ width: '100%' }" (onChange)="loadQAQC()" optionLabel="Name" appendTo="body"
          [attr.placeholder]="'Select Option' | translate" placeholder="{{ 'Select Option' | translate }}"
          (onClick)="loadQAQCCollections()">
        </p-dropdown>
      </div>
      <div class="group-item col-md-3">
        <button id="loadnewqaqcBtn" type="button" style="margin-top: 25px" class="btn btn-primary" (click)="loadQAQC()">
          {{ "Load" | translate }}
        </button>
      </div>
    </div>

    <!-- PSSSR Filter Set -->
    <ng-container *ngIf="showFilter && qaqcEventItem">
      <form (ngSubmit)="onSubmitPSSR()" #pssrForm="ngForm">
        <div class="row">
          <div class="col-md-3" *ngIf="qaqcEventItem">
            <div *ngIf="lstqaqcMilestone.length > 0 || lstqaqcWO.length > 0" class="form-group">
              <div class="row">
                <div class="col-xs-6" style="text-align: left; padding: 0px">
                  <label>{{ "Milestone" | translate }}</label>&nbsp;
                  <input type="checkbox" id="qaqcmilestone" name="qaqcmilestone" value="qaqcmilestone"
                    [(ngModel)]="isQAQCMilestone" (change)="qaqcTypeChange(true)" />
                </div>
                <div class="col-xs-6" style="text-align: right; padding: 0px">
                  <label>{{ "WorkOrders" | translate }}</label>&nbsp;
                  <input type="checkbox" id="qaqcworkoder" name="qaqcworkoder" value="qaqcworkoder"
                    [(ngModel)]="isQAQCWorkOrder" (change)="qaqcTypeChange(false)" />
                </div>
              </div>

              <ng-container *ngIf="isQAQCMilestone">
                <p-multiSelect [options]="milestoneSelectItem" [(ngModel)]="qaqcSelectedItems" id="qaqcMilstone"
                  name="qaqcMilstone" required [style]="{ width: '100%', minHeight: '30px' }"
                  (onChange)="qaqcMilestoneChange()">
                </p-multiSelect>
              </ng-container>

              <ng-container *ngIf="isQAQCWorkOrder">
                <p-multiSelect [options]="workorderSelectItem" [(ngModel)]="qaqcSelectedItems" id="qaqcWO" name="qaqcWO"
                  required [style]="{ width: '100%', minHeight: '30px' }"></p-multiSelect>
              </ng-container>
            </div>

            <div *ngIf="lstqaqcMilestone.length === 0 && lstqaqcWO.length === 0" class="form-group">
              <label>{{ "MilestoneAndWorkOrders" | translate }} </label>
              <div class="row" *ngIf="pageFilter.selectedBU?.ShowQAQC" class="alert alert-warning" role="alert">
                No available milestones/workorders from QAQC
              </div>
            </div>
          </div>

          <div class="col-md-3" *ngIf="qaqcSelectedItems && !isQAQCWorkOrder">
            <div *ngIf="qaqcSelectedItems.length == 1" class="form-group">
              <label>{{ "MilestoneWorkOrders" | translate }} (optional):</label>
              <p-multiSelect [options]="milestoneWOSelectItem" [(ngModel)]="qaqcSelectedItemsMWO" id="qaqcMWO"
                name="qaqcMWO" [style]="{ width: '100%', minHeight: '30px' }"
                (onChange)="qaqcMilestoneChangeWO()"></p-multiSelect>
            </div>

            <div *ngIf="qaqcSelectedItems.length > 1" class="form-group">
              <label>{{ "MilestoneWorkOrders" | translate }} (optional):</label>
              <div class="row" *ngIf="pageFilter.selectedBU?.ShowQAQC" class="alert alert-warning" role="alert">
                {{ "MilestoneWorkOrdersWarningMessage" | translate }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label>{{ pageFilter.selectedBU.HierarchyLabel1 | translate }}</label>
              <select id="area" class="form-control" (change)="changeArea()" [(ngModel)]="areaID" name="area"
                #area="ngModel" required>
                <option *ngIf="lstArea.length > 0" value=""></option>
                <option *ngFor="let area of lstArea" [ngValue]="area.Id">
                  {{ area.Name }}
                </option>
              </select>
              <div [hidden]="area.valid || area.pristine" class="alert alert-warning" role="alert">
                {{ pageFilter.selectedBU?.HierarchyLabel1 | translate }} is required.
              </div>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>{{ pageFilter.selectedBU.HierarchyLabel2 | translate }}</label>
              <select id="subArea" class="form-control" (change)="changeSubArea()" [(ngModel)]="subareaID"
                name="subArea" #subArea="ngModel" required>
                <option *ngIf="lstSubArea.length > 0" value=""></option>
                <option *ngFor="let subarea of lstSubArea" [ngValue]="subarea.Id">
                  {{ subarea.Name }}
                </option>
              </select>
              <div [hidden]="subArea.valid || subArea.pristine" class="alert alert-warning" role="alert">
                {{ pageFilter.selectedBU?.HierarchyLabel2 | translate }} is required.
              </div>
            </div>
          </div>

          <div class="col-md-3" *ngIf="pageFilter.selectedBU.ShowHierarchyLabel3 && lstFacility.length > 0">
            <div class="form-group">
              <label>{{ pageFilter.selectedBU.HierarchyLabel3 | translate }}</label>
              <select id="facility" class="form-control" [(ngModel)]="facilityID" name="facility" #facility="ngModel">
                <option *ngIf="lstFacility.length > 0" value=""></option>
                <option *ngFor="let facility of lstFacility" [ngValue]="facility.Id">
                  {{ facility.Name }}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-3">
            <div class="form-group">
              <label>{{ "Template" | translate }}</label>
              <select class="form-control" [(ngModel)]="taskplanID" id="pssrTemplate" name="pssrTemplate" required
                #template="ngModel">
                <option *ngFor="let taskplan of lstTaskPlan" [ngValue]="taskplan.ID">
                  {{ taskplan.Description }}
                </option>
              </select>
              <div [hidden]="template.valid || template.pristine" class="alert alert-warning" role="alert">
                {{ "Template is required" | translate }}.
              </div>
            </div>
          </div>

          <div *ngIf="pageFilter.selectedBU?.ShowPssrType" class="col-md-3">
            <div class="form-group">
              <label>{{ "Type" | translate }} </label>
              <select class="form-control" [(ngModel)]="pssrTypeID" id="pssrTypeId" name="pssrTypeId"
                #pssrTypeId="ngModel">
                <option [ngValue]="null"></option>
                <option *ngFor="let pt of pssrTypes" [ngValue]="pt.value">
                  {{ pt.label | translate }}
                </option>
              </select>
            </div>
          </div>
        </div>

        <div class="row" [hidden]="pssrForm.form.valid" class="alert alert-warning" role="alert">
          <strong>{{ "Note" | translate }} :</strong>{{ "Please fill up the fields that are marked required" | translate
          }}
        </div>

        <div class="row" *ngIf="taskplanID > 0">
          <app-pssr-edit-qaqc-members (addedPssrUsers)="addedPssrUsers($event)"
            (addedSectionLeads)="addedSectionLeads($event)" [orgId]="pageFilter?.selectedOrg?.Id"
            [templateId]="taskplanID" [bu]="pageFilter.selectedBU">
          </app-pssr-edit-qaqc-members>
        </div>

        <div class="row text-right">
          <button id="cancelPSSRBtn" type="button" class="btn btn-default" (click)="btnCancelPSSR()"
            style="white-space: pre-wrap !important">
            {{ "Cancel" | translate }}
          </button>
          &nbsp;&nbsp;
          <button id="savePSSRBtn" type="submit" class="btn btn-primary" [disabled]="!pssrForm.form.valid"
            style="white-space: pre-wrap !important">
            {{ "GeneratePSSR" | translate: pageFilter.selectedBU }}
          </button>
        </div>
      </form>
    </ng-container>
  </ng-container>

  <!-- Add new Project -->
  <div *ngIf="message" class="alert" [class.alert-success]="successMsg" [class.alert-danger]="!successMsg" role="alert">
    {{ message }}
  </div>
  <form (ngSubmit)="onSubmitNewProject()" (keydown.enter)="$event.preventDefault()" #projectForm="ngForm"
    name="projectForm">
    <div class="group">
      <div class="group-header">
        <h3>
          {{ "New" | translate }}
          {{ pageFilter.selectedBU?.EventLabel | translate }}
        </h3>
      </div>
      <div class="col-md-12 m-0 p-0">
        <div class="group-item col-md-10">
          <input id="projName" class="form-control text-box single-line" [(ngModel)]="newProject.Name" name="projName"
            #projName="ngModel" [attr.placeholder]="pageFilter.selectedBU?.EventLabel | translate" maxlength="255"
            required />
          <span class="col-sm-4 charSpan">{{ newProject.Name?.length }} of 255</span>
        </div>
        <div class="group-item col-md-2">
          <button id="createBtn" type="submit" class="btn btn-success btn-block"
            [disabled]="!projectForm.form.valid || createBtnClicked">
            {{ "Create" | translate }} {{ pageFilter.selectedBU?.EventLabel | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>

  <br />

  <!--Project List-->
  <div *ngIf="projects?.length">
    <div>
      <table class="table table-hover table-condensed">
        <thead>
          <tr class="bg-info">
            <th id="thName" class="col-sm-5 text-center">
              {{ "Name" | translate }}
            </th>
            <th id="thStatus" class="col-sm-2 text-center">
              {{ "Status" | translate }}
            </th>
            <th id="thCompleted" class="col-sm-1 text-center">
              {{ "CompletedTotalPSSRs" | translate }}
            </th>
            <th id="thActions" class="col-sm-1 text-center">
              {{ "Actions" | translate }}
            </th>
            <th id="thDateCreated" class="col-sm-1 text-center">
              {{ "Date Created" | translate }}
            </th>
            <th *ngIf="pageFilter.searchDeletedProjects" id="thDateDeleted" class="col-sm-1 text-center">
              {{ "Date Deleted" | translate }}
            </th>
            <th id="thHolder" class="col-sm-1 text-center"></th>
          </tr>
        </thead>
        <tbody *ngFor="let project of projects | filter: searchFn">
          <tr>
            <td class="col-sm-5">
              <div class="row">
                <h3>{{ project.Name }}</h3>
              </div>
            </td>
            <td class="col-sm-2">
              <div class="col-sm-offset-2 col-sm-8 text-center {{
                  project.statusClass
                }}" style="border-radius: 1em; color: white; max-width: 250px">
                {{
                (project.IsDeleted ? "Deleted" : project.StatusName)
                | translate
                }}
              </div>
            </td>
            <td class="col-sm-1 text-center">
              <strong>
                {{ project?.PssrActual }}/{{ project?.PssrTotal }}
              </strong>
            </td>
            <td class="col-sm-1 text-center">
              <table>
                <tbody *ngFor="let q of project.ActionsProject; let i = index">
                  <tr *ngIf="(i === 2 && pageFilter.selectedBU.ShowP5) || i < 2">
                    <td class="col-sm-2 text-center">
                      <strong>{{ buPLabels[i] || "P" }}: </strong>{{ q.Value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>

            <td class="col-sm-1 text-center">
              <strong>
                {{ project.CreateDate | date: "short" }}
              </strong>
            </td>

            <td *ngIf="pageFilter.searchDeletedProjects" class="col-sm-1 text-center">
              <strong>
                {{ project.DeletedOn | date: "short" }}
              </strong>
            </td>
            <td class="col-sm-1">
              <button type="button" class="btn btn-primary btn-block" (click)="visitProjectDetails(project.Id)">
                {{ "Open" | translate }}
              </button>
              <button *ngIf="project.IsDeleted && showDeleteOptions" type="button"
                class="btn btn-default btn-block restore-project" (click)="restoreProject(project.Id)">
                {{ "Restore" | translate }}
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>