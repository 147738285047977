<div
  class="box-shadow module"
  style="width: 80%; margin: auto; margin-bottom: 20px"
>
  <h2>{{ "Attention" | translate }}!</h2>
  <hr />
  <h3>
    Are you sure you want to delete Category:
    <span style="background-color: yellow"
      >{{ category.Name }} ({{ category.TypeName }} Type)</span
    >
    ?
  </h3>
  <h3>
    <a class="btn btn-danger" style="width: 100px" (click)="deleteCategory()"
      >Delete</a
    >
  </h3>
</div>

<div style="width: 80%; margin: auto">
  <a
    (click)="onRedirectButtonClick(category.BusinessUnitID)"
    style="cursor: pointer"
    id="backToCategoryList"
    >Back to Category List</a
  >
</div>
