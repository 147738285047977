import { AuthService } from '#services/shared';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';


import { Utility } from '#services/shared/utility';


@Injectable()
export class Bootstrap {

  constructor(private authSvc: AuthService, private msalService: MsalService) { }

  isB2C() {
    return window.location.pathname === '/b2c';
  }

  init(): Promise<any> {
    if (this.isB2C() && Utility.validateEnvironment()) {
      return Promise.resolve();
    }

    if (this.authSvc.isMsalAuthenticated()) {
      const redirectUri = this.getLastRedirectedUri();
      if (redirectUri !== null) {
        this.clearLastRedirectUri();
        this.goTo(redirectUri);
        return;
      }
      return this.authSvc.loadUserInfo().catch(console.error);
    }

    if (this.authSvc.isb2cLoged()) {
      return this.authSvc.loadUserInfo().catch(console.error);
    }

    if (!this.isHomePage()) {
      this.saveLastRedirectUri();
      this.goTo('/');
      return;
    }

    return new Promise((resolve, reject) => {
      this.msalService.handleRedirectCallback((authError, response) => {
        if (authError) {
          reject(authError);
        } else {
          resolve(response);
        }
      });

      this.msalService.loginRedirect();
    });
  }

  private clearLastRedirectUri() {
    localStorage.removeItem('redirectUri');
  }

  isHomePage() {
    return window.location.pathname === '/';
  }

  saveLastRedirectUri(): void {
    localStorage.setItem('redirectUri', window.location.pathname);
  }

  getLastRedirectedUri() {
    return localStorage.getItem('redirectUri');
  }

  goTo(redirectUri: string) {
    location.href = redirectUri;
  }
}

export const initializeApp = (bootstrap: Bootstrap) => () => bootstrap.init();
