import { Component, OnInit } from '@angular/core';
import {
  BusinessUnitsService,
  OrganizationService,
  UserService,
} from '#services/api';
import { BusinessUnit } from '#models/business-unit';

import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationUser } from '#models/organization-user';
import { Organization } from '#models/organization';
import { User } from '#models/user';
import { Utility } from '#services/shared/utility';
import { UserType } from '#models/enum/user-type';
import { ToastrService } from '#services/shared/toastr.service';
import { TranslatePipe } from '@ngx-translate/core';
import { FormControl, Validators } from '@angular/forms';
import { BusinessUnitCode } from '#models/business-unit-code';

@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  providers: [TranslatePipe],
  styleUrls: ['./user-create.component.scss']
})
export class UserCreateComponent implements OnInit {
  UserType = UserType;

  sharedUsersCtrlInvalid = false;
  orgUsr: OrganizationUser = new OrganizationUser();
  displayWarning: boolean;
  lstBusinessUnit: BusinessUnit[] = [];
  lstOrganization: Organization[] = [];
  selectedBU: BusinessUnit;
  selectedOrg: Organization;
  selectedUser: User;
  users: User[];
  buId: number;
  orgId: number;

  BusinessUnitCode = BusinessUnitCode;

  constructor(
    private buService: BusinessUnitsService,
    private orgService: OrganizationService,
    private userService: UserService,
    private activeRoute: ActivatedRoute,
    private _router: Router,
    private toastrService: ToastrService,
    private translatePipe: TranslatePipe
  ) { }

  async ngOnInit() {
    this.activeRoute.params.subscribe((p) => {
      this.buId = Number(p['buId']);
      this.orgId = Number(p['orgId']);
    });

    this.orgUsr.Id = null;
    this.orgUsr.Type = UserType.Chevron;
    this.orgUsr.IsLoginOnly = false;
    this.orgUsr.IsAdmin = false;

    this.displayWarning = false;

    await this.loadBusinessUnits();
    await this.loadOrganizations();
  }

  onRedirectButtonClick(): void {
    this._router.navigate(['admin/users']);
  }

  async loadBusinessUnits() {
    await this.buService
      .getUserBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          const bus = this.lstBusinessUnit.filter((x) => x.Id === this.buId);
          this.selectedBU = Utility.isValidListWithData(bus)
            ? bus[0]
            : this.lstBusinessUnit[0];

          this.buId = null;
        }
      });
  }

  async loadOrganizations() {
    if (Utility.isValidObj(this.selectedBU)) {
      this.orgUsr.IsTcoWhitePagesImported = this.selectedBU.Code === BusinessUnitCode.TCO;

      await this.orgService
        .getUserAdminOrgs(this.selectedBU.Id)
        .toPromise()
        .then((data: Organization[]) => {
          this.lstOrganization = data;
          if (Utility.isValidListWithData(this.lstOrganization)) {
            const org = this.lstOrganization.filter(
              (x) => x.Id === this.orgId
            )[0];
            this.selectedOrg = Utility.isValidObj(org)
              ? org
              : this.lstOrganization[0];
            this.orgId = null;
            this.orgUsr.OrganizationId = this.selectedOrg.Id;
          } else {
            this.selectedOrg = null;
            this.orgUsr.OrganizationId = 0;
          }
        });
    } else {
      this.orgUsr.OrganizationId = 0;
      this.lstOrganization = [];
    }
  }

  getUsers(event) {
    const query = event.query;
    this.userService.searchUser(query).subscribe((data) => {
      this.users = data;
      this.users.forEach(function (obj, index) {
        this[index].FullName = Utility.getFullName(this[index]);
      }, this.users);
    });
  }

  removeUsrData() {
    this.selectedUser = null;
    this.orgUsr.UserID = null;
    this.orgUsr.FirstName = null;
    this.orgUsr.MiddleName = null;
    this.orgUsr.LastName = null;
    this.orgUsr.Email = null;
    this.orgUsr.CAI = null;
    this.orgUsr.Passcode = null;
    this.orgUsr.IsLoginOnly = false;
    this.orgUsr.ChildUsers = [];
  }

  async create() {
    this.orgUsr.CAI = null;
    this.orgUsr.OrganizationId = Utility.isValidObj(this.selectedOrg)
      ? this.selectedOrg.Id
      : 0;

    if (this.orgUsr.Type === UserType.Chevron) {
      this.orgUsr.CAI = null;
      this.orgUsr.Email = null;

      if (Utility.isValidObj(this.selectedUser)) {
        this.orgUsr.CAI = this.selectedUser.CAI;
        this.orgUsr.Email = this.selectedUser.Email;
      }
    }

    const hasInputError = this.validateUserInput();

    if (hasInputError) {
      this.toastrService.showWarning(hasInputError);
    } else {
      this.orgUsr.HasAccess = true;

      await this.orgService
        .addOrganizationUser(this.orgUsr)
        .toPromise()
        .then(
          (data) => {
            this._router.navigate([
              'admin/user-edit/',
              {
                orgId: this.orgUsr.OrganizationId,
                userId: data.UserID,
                buId: this.selectedBU.Id,
                isNew: 1,
              },
            ]);
          },
          (error) => {
            this.toastrService.showWarning(error.error.ExceptionMessage);
          }
        );
    }
  }

  validateUserInput() {
    const errors = [];

    if (!Utility.isValidObj(this.selectedOrg)) {
      errors.push(this.translatePipe.transform('BusinessUnitisrequired'));
    }

    if (this.orgUsr.OrganizationId <= 0) {
      errors.push(this.translatePipe.transform('Organizationisrequired'));
    }

    if (
      this.orgUsr.Type === UserType.Chevron &&
      !Utility.isValidString(this.orgUsr.Email)
    ) {
      errors.push(this.translatePipe.transform('Userisrequired'));
    }

    if (this.orgUsr.Type === UserType.External) {
      !Utility.isValidString(this.orgUsr.FirstName) &&
        errors.push(this.translatePipe.transform('First Name is required')
        );
      !Utility.isValidString(this.orgUsr.Email) &&
        errors.push(this.translatePipe.transform('Email is required'));
    }

    if (this.orgUsr.Type === UserType.Shared) {
      !Utility.isValidString(this.orgUsr.FirstName) &&
        errors.push(
          this.translatePipe.transform('Shared User Name is required')
        );
      !Utility.isValidString(this.orgUsr.Email) &&
        errors.push(this.translatePipe.transform('Email is required'));
    }

    if (
      ![UserType.Chevron, UserType.External, UserType.Shared].includes(
        this.orgUsr.Type
      )
    ) {
      errors.push(this.translatePipe.transform('User type is wrong'));
    }

    const repeatedSharedUsers = this.checkForRepeatedUsers();
    if (repeatedSharedUsers.length > 0) {
      errors.push(
        this.translatePipe.transform('RepeatedEmailsInputWarning', {
          repeatedEmails: repeatedSharedUsers.join('] ['),
        })
      );
    }

    return errors.join('<br>');
  }

  checkForRepeatedUsers() {
    const sharedEmails = this.orgUsr.ChildUsers.map(({ Email: ChildUserEmail }) => ChildUserEmail);

    const sharedUsersCount = sharedEmails.length;
    const repeatedSharedEmails = [];
    for (let i = 0; i < sharedUsersCount; i += 1) {
      const email = sharedEmails[i];

      if (Utility.isValidString(email)) {
        sharedEmails.forEach((userEmail, index) => {
          if (
            index !== i &&
            userEmail === email &&
            !repeatedSharedEmails.some((r) => r === email)
          ) {
            repeatedSharedEmails.push(userEmail);
          }
        });
      }
    }
    return repeatedSharedEmails;
  }

  async importSharedUsers(buId: number, sharedEmailAddress: string): Promise<void> {
    try {
      this.orgUsr.ChildUsers = []; // This trick helps the component to re-read the list of users we are importing below.

      const sharedUser = await this.userService.importSharedUser(buId, sharedEmailAddress).toPromise();
      this.orgUsr.ChildUsers = sharedUser.ChildUsers;
      this.sharedUsersCtrlInvalid = sharedUser.ChildUsers.some(c => Validators.required({ value: c.Email } as FormControl) !== null);

      this.toastrService.showSuccess(this.translatePipe.transform('Shareduserfound'), 'Success');
    } catch (e) {
      this.toastrService.showWarning(this.translatePipe.transform('Shared user not found'));
    }
  }

  onSharedUsersListChanged(event) {
    const { users, isValid } = event;

    this.sharedUsersCtrlInvalid = !isValid;
    this.orgUsr.ChildUsers = users;
  }

  onImportSharedUsersOptionSelected(isTcoWhitePagesImported: boolean): void {

    if (isTcoWhitePagesImported) {

      this.orgUsr.ChildUsers = this.orgUsr.ChildUsers.filter(c =>
        c.Email !== null && c.FullName !== null && c.UserID !== null
      );
    }
  }
}
