import { Component, OnInit } from '@angular/core';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Organization } from '#models/organization';
import { OrganizationService } from '#services/api/organization.service';
import { AuthService } from '#services/shared';
import { Router } from '@angular/router';
import { Utility } from '#services/shared/utility';
import { Role } from '#models/enum/role';

import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';


@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss'],
  providers: [TranslatePipe],
})
export class OrganizationComponent implements OnInit {
  newOrganzationName = '';
  lstBusinessUnit: BusinessUnit[] = [];
  selectedBU: BusinessUnit;
  lstOrganization: Organization[] = [];

  constructor(
    private toastrService: ToastrService,
    private businessUnitsSvc: BusinessUnitsService,
    private organizationSvc: OrganizationService,

    private svcRouter: Router,
    private authService: AuthService,
    private translatePipe: TranslatePipe
  ) { }

  async ngOnInit() {
    await this.loadBusinessUnits();
    await this.loadOrganizations();
  }

  async loadBusinessUnits() {
    await this.businessUnitsSvc
      .getUserAdminBUs(
        true
      )
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          this.selectedBU = this.lstBusinessUnit[0];
        }
      });
  }

  async loadOrganizations() {
    this.lstOrganization = [];
    if (this.selectedBU) {
      await this.organizationSvc
        .getUserAdminOrgs(this.selectedBU.Id)
        .toPromise()
        .then((data: Organization[]) => {
          this.lstOrganization = data;
        });
    }
  }

  onChangeBU() {
    this.loadOrganizations();
  }

  async createOrganization() {
    if (!Utility.isValidString(this.newOrganzationName)) {

      this.toastrService.showWarning(this.translatePipe.transform('OrganizationCannotBeEmpty', { OrganizationLabel: this.translatePipe.transform(this.selectedBU.OrganizationLabel), }));
    } else {
      this.organizationSvc
        .addOrganization(this.selectedBU.Id, this.newOrganzationName)

        .toPromise()
        .then(
          (data: Organization) => {
            this.toastrService.showSuccess(this.translatePipe.transform('OrganizationSuccessfullyAdded', { OrganizationLabel: this.translatePipe.transform(this.selectedBU.OrganizationLabel), }));
            this.newOrganzationName = '';
            this.lstOrganization.push(data);
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);
          }
        );
    }
  }

  edit(orgId: number): void {
    this.svcRouter.navigate(['admin/organization-edit/', { id: orgId }]);
  }

  get canCreateOrganizations(): boolean {
    return (
      this.authService.hasRole(Role.SystemAdmin) ||
      this.authService.hasRole(Role.BusinessUnitAdmin)
    );
  }
}
