import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastSeverity } from '../../models/enum/toast-severity';

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(private messageService: MessageService) {}
  showError(detail: string, summary: string = ''): void {
    this.messageService.add({
      detail,
      summary,
      sticky: true,
      closable: true,
      severity: ToastSeverity.Error,


    });
  }

  showWarning(detail: string, summary: string = '', life: number = 5000): void {
    this.messageService.add({
      detail,
      summary,
      severity: ToastSeverity.Warn,

      life,
    });
  }

  showSuccess(detail: string, summary: string = ''): void {
    this.messageService.clear();


    this.messageService.add({
      detail,
      summary,

      severity:  ToastSeverity.Success
    });
  }

  showInfo(detail: string, summary: string = '', life: number = 3000): void {
    this.messageService.add({
      detail,
      summary,
      life,
      severity: ToastSeverity.Info,

    });
  }
}
