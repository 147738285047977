import { Inject, InjectionToken, Injectable } from '@angular/core';

export const pdfjsLoader =
  (lib = null) =>
  (): PdfJsLib => {
    if (lib) { return lib; }
    return {
      PDFDocumentFactory: {
        load() {
          throw new Error('No pdf lib was loaded');
        },
      },
    };
  };

export interface PdfJsLib {
  PDFDocumentFactory: {
    load(args): any;
  };
}
export const PDFJS_LIB = new InjectionToken<PdfJsLib>('PDFJS_LIB');

@Injectable({
  providedIn: 'root',
})
export class PdfHelperService {
  constructor(@Inject(PDFJS_LIB) public pdfJsLib: PdfJsLib) {}
  getDocumentAsync(data): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        const content = this.pdfJsLib.PDFDocumentFactory.load(data);

        resolve(content);
      } catch (e) {
        reject(e);
      }
    });
  }
}
