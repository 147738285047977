export enum PSSRStatus {
  Draft = 1,
  Initiated = 2,
  InProgress = 3,
  Completed = 4,
  PendingApproval = 5,
  Approved = 6,
  Closed = 7,
  Cancel = 8,
}
export const PSSRStatusNames = { Deleted: 'Deleted' };

export const PSSRStatusCSSClasses = {
  [PSSRStatus.Draft]: 'label-draft',
  [PSSRStatus.Initiated]: 'label-initiated',
  [PSSRStatus.InProgress]: 'label-progress',
  [PSSRStatus.Completed]: 'label-completed',
  [PSSRStatus.PendingApproval]: 'label-pending',
  [PSSRStatus.Approved]: 'label-approved',
  [PSSRStatus.Closed]: 'label-closed',
  [PSSRStatus.Cancel]: 'label-cancel',
};
