import { Component, OnInit } from '@angular/core';
import { ActionItemCategoryService } from '#services/api/action-item-category.service';
import { ActionItemCategory } from '#models/action-item-category';
import { Router, ActivatedRoute } from '@angular/router';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-category-create',
  templateUrl: './category-create.component.html',
})
export class CategoryCreateComponent implements OnInit {
  category: ActionItemCategory = new ActionItemCategory();
  displayWarning: boolean;

  errorMsg: string;

  constructor(

    private categoryService: ActionItemCategoryService,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.category.Id = +v['categoryId'];
      this.category.BusinessUnitID = +v['buId'];
    });
    this.displayWarning = false;
  }

  onRedirectButtonClick(buId: number): void {
    this.router.navigate(['admin/category', { buId: buId }]);
  }

  public createCategory(): void {
    this.displayWarning =
      this.category.Name == undefined ||
      this.category.Name == null ||
      this.category.Name.length == 0;

    if (!this.displayWarning) {

      this.categoryService
        .createCategory(this.category as ActionItemCategory)

        .subscribe(
          (data: ActionItemCategory) => {
            this.onRedirectButtonClick(data.BusinessUnitID);
          },
          (error) => {
            this.toastrService.showError( error.error.ExceptionMessage);
          }
        );
    }
  }
}
