import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanItem, TaskPlanItemType } from '#models/task-plan-item';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-task-plan-item-create',
  templateUrl: './task-plan-item-create.component.html',
})
export class TaskPlanItemCreateComponent implements OnInit {
  //#region VARIABLES

  taskPlanId: number;
  subSectionId: number;
  taskPlanItem: TaskPlanItem = new TaskPlanItem();
  displayWarningCode: boolean;
  displayWarningDesc: boolean;
  displayRichEditor: boolean;
  sectionId: number;
  errorMsg: string;
  taskPlanPage: string;

  selectedBU: BusinessUnit;

  //#endregion

  constructor(
    private toastrService: ToastrService,
    private taskPlanSvc: TaskPlanService,

    private _router: Router,
    private aRoute: ActivatedRoute,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.taskPlanItem.Description = '';
    this.aRoute.params.subscribe((v) => {
      this.taskPlanId = +v['taskPlanId'];
      this.taskPlanItem.TaskPlanSubSectionId = +v['subSectionId'];
      this.sectionId = +v['sectionId'];
      this.taskPlanPage = v['page'];
    });
    this.displayWarningCode = false;
    this.displayWarningDesc = false;
    this.displayRichEditor = false;

    this.loadTaskPlan();
  }

  private loadTaskPlan() {
    this.taskPlanSvc
      .getTaskPlanById(this.taskPlanId)
      .toPromise()
      .then((data) => {
        this.taskPlanItem.Code =
          this.taskPlanPage === 'pssr'
            ? data.Code + '-' + data.NextItemID
            : data.Code + '-' + (data.NextItemID + 1);

        this.businessUnitService
          .getBusinessUnitById(data.BusinessUnitId)
          .toPromise()
          .then((bu) => {
            this.selectedBU = bu;
          });
      });
  }

  onRedirectButtonClick(): void {
    this._router.navigate([
      'admin/task-plan-manage',
      {
        id: this.taskPlanId,
        sectionId: this.sectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public createTaskPlanItem(): void {
    this.displayWarningCode = this.taskPlanSvc.isInvalidString(
      this.taskPlanItem.Code
    );
    this.displayWarningDesc = this.taskPlanSvc.isInvalidString(
      this.taskPlanItem.Description
    );
    if (!this.displayWarningCode && !this.displayWarningDesc) {

      this.taskPlanSvc
        .createTaskPlanItem(this.taskPlanItem) // Assign object with placeholder description, request for action itmes

        .subscribe((data: any) => {
          if (data.Success) {
            this.onRedirectButtonClick();
          } else {
            this.toastrService.showError( data.Message);

          }
        });
    }
  }
  onItemTypeChanged(itemType: TaskPlanItemType) {
    if (itemType !== TaskPlanItemType.Default) {
      this.taskPlanItem.ShouldAddCommentToQuestion = false;
    }
  }
}
