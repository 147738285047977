import { Utility } from '#services/shared/utility';
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'participantType',
})
export class ParticipantTypePipe implements PipeTransform {
  transform(participant): string {
    return Utility.formatUser(participant);
  }
}
