import { User } from './user';

export class Feedback {
    Id: number;
    BusinessUnitId: number;
    BusinessUnitName: string;
    UserId: number;
    User: User;
    Type: number;
    Environment: number;
    Title: string;
    Comments: string;
    Iteration: string;
    Priority: number;
    ADO: string;
    Status: string;
    VerifiedBy: string;
    VerifiedDate: Date;
    IsActive: boolean;
    CreateDate: Date;
    CreatedBy: string;
    // --
    priorityStr: string;
    typeStr: string;
    envStr: string;
    statusStr: number;
    // --
    Attachment: Attachment[];
    CanEdit: boolean;
    Discussion: Discussion[];
    NewDiscussion: string;
}

export class Attachment {
  name: string;
  base64: string;
  contentType: string;
}

export class Discussion {
  Id: number;
  Text: string;
  CreateDate: Date;
}

export enum FeedbackStatus {
  Resolved = 1,
  Active = 2,
  New = 3
}

export enum FeedbackPriority {
    High = 1,
    Medium = 2,
    Low = 3
}

export enum FeedbackType {
    Bug = 0,
    Enhancement = 1
}

export enum FeedbackEnvironment {
    Mobile = 0,
    Web = 1,
    MobileAndWeb = 2
}

export class FeedbackFilter {
  BUId: number;
}
