import { Component, OnInit } from '@angular/core';
import { ActionItemCategoryService } from '#services/api/action-item-category.service';
import { ActionItemCategory } from '#models/action-item-category';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '#services/shared/toastr.service';


@Component({
  selector: 'app-category-delete',
  templateUrl: './category-delete.component.html',
})
export class CategoryDeleteComponent implements OnInit {
  category: ActionItemCategory = new ActionItemCategory();
  categoryName: string;
  displayWarningCategory: boolean;

  errorMsg: string;

  constructor(
    private categorySvc: ActionItemCategoryService,
    private router: Router,
    private toastrService: ToastrService,
    private route: ActivatedRoute
  ) { }


  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.category.Id = +v['categoryId'];
      this.category.BusinessUnitID = +v['buId'];
    });
    this.getCategoryById(this.category.Id);
    this.displayWarningCategory = false;
  }

  onRedirectButtonClick(buId: number): void {
    this.router.navigate(['admin/category', {buId: buId}]);
  }

  getCategoryById(id: number): void {
    this.categorySvc.getCategoryById(id)
    .subscribe(
      (data: ActionItemCategory) => {
        this.category = data;
        this.categoryName = this.category.Name;
      },
      error => {
        this.toastrService.showError( error.error.ExceptionMessage);
      }
    );
  }

  deleteCategory(): void {
    this.categorySvc.deleteCategory(this.category.Id)
    .subscribe(
      (data: any) => {
        this.onRedirectButtonClick(this.category.BusinessUnitID);
      },
      error => {
        this.toastrService.showError( error.error.ExceptionMessage);
      }
    );
  }
}
