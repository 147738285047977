import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan, TaskPlanType } from '#models/index';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';

@Component({
  selector: 'app-task-plan',
  templateUrl: './task-plan.component.html',
  styleUrls: ['./task-plan.component.scss'],
})
export class TaskPlanComponent implements OnInit {
  //#region VARIABLES

  taskPlans: TaskPlan[];
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];

  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,
    private businessUnitsSvc: BusinessUnitsService,

    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.url.subscribe(() => {
      this.loadBusinessUnits();
    });
  }

  ngOnInit() {
    this.loadBusinessUnits();
  }

  loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserAdminBUs().subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        this.selectedBU = this.lstBusinessUnit[0];
        this.loadTaskPlans();
      } else {
        this.taskPlans = [];
      }
    });
  }

  onRedirectButtonClick(sufix: string, id: number) {
    if (id > 0) {
      this.router.navigate([`admin/task-plan-${sufix}`, { id: id}]);
    } else {
      this.router.navigate([`admin/task-plan-${sufix}`]);
    }
  }

  public loadTaskPlans(): void {

    this.taskPlanSvc
      .getTaskPlans(this.selectedBU.Id)

      .subscribe((data: TaskPlan[]) => {
        this.taskPlans = data
          .filter((x) => x.TaskPlanType === TaskPlanType.PSSR && !x.IsDeleted)
          .sort((a, b) => a.Description.localeCompare(b.Description));
      });
  }
}
