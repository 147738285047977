import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { Equipment } from '#models/equipment';
import { EquipmentTemplate } from '../../models/equipment-template';
import { BusinessUnit } from '../../models/business-unit';
import { EquipmentTempGroup } from '../../models/equipment-group';
import { EquipmentTempGroupConfig } from '../../models/equipment-group-config';


@Injectable()
export class EquipmentTemplateService {
    constructor(
        private http: HttpClient
    ) { }

    public getAllEquipments(): Observable<Equipment[]> {
        return this.http.get<Equipment[]>(`${environment.apiEndpoint}/equipment-template/all`);
    }


    public getEquipmentTemplateById(id: number): Observable<Equipment> {
        return this.http.get<Equipment>(`${environment.apiEndpoint}/equipment-template/${id}`);
    }

    public create(equipmenttemplate: Equipment): Observable<Equipment> {
        return this.http.post<Equipment>(`${environment.apiEndpoint}/equipment-template`, equipmenttemplate);
    }

    public update(equipmenttemplate: Equipment): Observable<Equipment> {
        return this.http.put<Equipment>(`${environment.apiEndpoint}/equipment-template`, equipmenttemplate);
    }

    public delete(id: number): Observable<Equipment> {
        return this.http.put<Equipment>(`${environment.apiEndpoint}/equipment-template/delete/${id}`, null);
    }

    public getAllEquipmentTemplates(): Observable<EquipmentTemplate[]> {
        return this.http.get<EquipmentTemplate[]>(`${environment.apiEndpoint}/equipment-template/all`);
    }

    public createTemplate(equipmenttemplate: EquipmentTemplate): Observable<EquipmentTemplate> {
        return this.http.post<EquipmentTemplate>(`${environment.apiEndpoint}/equipment-template`, equipmenttemplate);
    }

    public updateTemplate(equipmenttemplate: EquipmentTemplate): Observable<EquipmentTemplate> {
        return this.http.put<EquipmentTemplate>(`${environment.apiEndpoint}/equipment-template`, equipmenttemplate);
    }

    public getEquipTemplateById(id: number): Observable<EquipmentTemplate> {
        return this.http.get<EquipmentTemplate>(`${environment.apiEndpoint}/equipment-template/${id}`);
    }

    public getEquipmentTemplateByBU(buId: number): Observable<EquipmentTemplate[]> {
        return this.http.get<EquipmentTemplate[]>(`${environment.apiEndpoint}/equipment-template/bybu/${buId}`);
    }

    public downloadEquipTemp(bu: BusinessUnit): Observable<Object> {
        const result = this.http.post<Object>(`${environment.apiEndpoint}/equipment-template/templates`, bu,
            { responseType: 'blob' as 'json' });
        return result;
    }

    // **** EquipmentTempGroups ****
    public getEquipTempGroupByBU(id: number): Observable<EquipmentTempGroup[]> {
        return this.http.get<EquipmentTempGroup[]>(`${environment.apiEndpoint}/equip-temp-group/groupsByBu/${id}`);
    }

    public createEquipTempGroup(equipTempGroup: EquipmentTempGroup): Observable<EquipmentTempGroup> {
        return this.http.post<EquipmentTempGroup>(`${environment.apiEndpoint}/equip-temp-group`, equipTempGroup);
    }

    public updateEquipTempGroup(equipTempGroup: EquipmentTempGroup) {
        return this.http.put<EquipmentTempGroup>(`${environment.apiEndpoint}/equip-temp-group`, equipTempGroup);
    }

    public deleteEquipTempGroup(id: number) {
        return this.http.put(`${environment.apiEndpoint}/equip-temp-group/delete/${id}`, null);
    }

    public updateEquipmentByDeleted(equipTempGroup: EquipmentTempGroup) {
        return this.http.put(`${environment.apiEndpoint}/equip-temp-group/updateEquipments`, equipTempGroup);
    }

    // **** EquipmentTempGroupConfig ****
    public getEquipTempGroupConfigByGroup(groupId: number): Observable<EquipmentTempGroupConfig[]> {
        return this.http.get<EquipmentTempGroupConfig[]>(`${environment.apiEndpoint}/equip-temp-group/configByGroup/${groupId}`);
    }

    public createGroupConfig(equipTempGroupConfig: EquipmentTempGroupConfig[]): Observable<EquipmentTempGroupConfig[]> {
        return this.http.post<EquipmentTempGroupConfig[]>(`${environment.apiEndpoint}/equip-temp-group/createConfig`,
                                                                                                            equipTempGroupConfig);
    }
}

