import {
  AfterViewInit,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  PSSR,
  BusinessUnit,
  Area,
  SubArea,
  Facility,
  TaskPlan,
  Project,
  QAQCResponse,
  QAQCRequest,
  QAQCItem,
  QAQCType,
  ProjectStatus,
  TaskPlanType,
  FilterProject,
  LocationFilter,
} from '#models/index';
import { PSSRUser } from '#models/pssr-user';
import { PSSRUserList } from '#models/pssr-user-list';

import {
  OrganizationService,
  BusinessUnitsService,
  ProjectService,
  PSSRService,
  QAQCService,
  AreaService,
  SubAreaService,
  FacilityService,
  TaskPlanService,
} from '#services/api';
import { SectionUser, SectionUserList } from '#models/section/section';
import { Organization } from '#models/organization';
import { SelectItem } from 'primeng/api';
import {
  finalize,
  take,
  tap,
  distinctUntilChanged,
  concatMap,
  filter,
  debounceTime,
  switchMap,
} from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { Utility } from '#services/shared/utility';
import { PSSRType } from '#models/pssr-type';
import { PSSRStatus } from '#models/pssr-status';
import { AuthService } from '#services/shared';
import { NgForm } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { FilterPipe } from '#components/pipes/filterPipe.pipe';
import { ToastrService } from '#services/shared/toastr.service';
import { ProjectUser } from '#models/project-user';

@Component({
  selector: 'app-pssr-project',
  templateUrl: './pssr-project.component.html',
  styleUrls: ['./pssr-project.component.scss'],
  providers: [TranslatePipe, FilterPipe],
})
export class PssrProjectComponent implements OnInit, AfterViewInit, OnDestroy {
  pageFilter: FilterProject;
  savedLocationFilter: LocationFilter;

  // BU & Organization
  buPLabels: string[] = [];

  // Project
  projects: Project[] = [];
  originalProjects: Project[] = [];
  newProject: Project = new Project();

  // PSSR
  pssrListSelected: PSSR[];

  // BU, Organization, Area, SubArea, Task, Actions
  lstBUs: BusinessUnit[] = [];
  lstOrgs: Organization[] = [{ Id: 0, Name: ' ' } as Organization];

  lstArea: Area[] = [{ Id: 0, Name: ' ' } as Area];
  lstSubArea: SubArea[] = [{ Name: ' ', Id: 0 } as SubArea];
  lstFacility: Facility[] = [{ Id: 0, Name: ' ' } as Facility];
  lstTaskPlan: TaskPlan[] = [{ ID: 0, Name: ' ' } as TaskPlan];

  // panelOpenState: boolean = false;

  buId: number;
  orgId: number;
  projId: number;
  projStatusList: SelectItem[];
  selectedStatus: number[];
  disableFilter: boolean;
  projectSelected: boolean;
  templateSelected: boolean;
  successMsg: boolean;
  message: string;
  createBtnClicked = false;

  // QAQC
  showQAQC: Boolean;
  showFilter: Boolean = false;
  qaqcEvent: QAQCResponse;
  qaqcEventItem: QAQCItem;

  // QAQC List Items
  lstqaqcEvents: QAQCItem[] = [];
  lstqaqcMilestone: QAQCItem[] = [];
  lstqaqcMilestoneWO: QAQCItem[] = [];
  lstqaqcWO: QAQCItem[] = [];
  milestoneSelectItem: SelectItem[] = [];
  milestoneWOSelectItem: SelectItem[] = [];
  workorderSelectItem: SelectItem[] = [];
  // QAQC Items to Generate
  qaqcSelectedItems: string[] = [];
  qaqcSelectedItemsMWO: string[] = [];
  qaqcItemSubmit: QAQCItem[] = [];
  // QAQC Identifier
  isQAQCMilestone = true;
  isQAQCMilestoneWO = false;
  isQAQCWorkOrder = false;

  qaqcMilestoneId: string;
  qaqcMilestoneWOId: string;
  qaqcWOId: string;
  qaqcItemId: string;
  qaqcItem: QAQCItem[] = [];

  // PSSRCreation
  areaID: number;
  subareaID: number;
  facilityID: number;
  taskplanID: number;
  pssrTypeID: number;
  projectID: number;
  pssrTypes: SelectItem[];

  loadedCollection = {
    isBULoaded: false,
    isOrgLoaded: false,
    isAreaLoaded: false,
    isSubAreaLoaded: false,
    isProjectLoaded: false,
    isFacilityLoaded: false,
    isTaskPlanLoaded: false,
  };

  // Participants and SectionLeads
  bulkPssrUsers: PSSRUser[] = [];
  bulkSectionLeads: SectionUser[] = [];

  showDeleteOptions = false;

  @ViewChild(NgForm) searchForm: NgForm;
  //#endregion

  ProjectStatus = ProjectStatus;
  subscription = new Subscription();

  constructor(
    private toastrService: ToastrService,
    private svcRouter: Router,
    private activeRoute: ActivatedRoute,

    public svcPSSR: PSSRService,
    public svcBU: BusinessUnitsService,
    public svcOrg: OrganizationService,
    private svcProject: ProjectService,
    private svcQAQC: QAQCService,
    private svcArea: AreaService,
    private svcSubArea: SubAreaService,
    private svcFacility: FacilityService,
    private svcTaskPlan: TaskPlanService,
    private authService: AuthService,
    private translatePipe: TranslatePipe
  ) {
    this.projStatusList = [
      { label: 'Active', value: 1 },
      { label: 'Closed', value: 2 },
    ];
    this.selectedStatus = [ProjectStatus.Active];
    this.projectSelected = false;
    this.templateSelected = false;
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.searchForm.valueChanges
        .pipe(
          filter((data) => {
            return !!data.org && data.searchProj == this.pageFilter.searchText;
          }),
          debounceTime(500),
          distinctUntilChanged(),
          concatMap(this.searchProjectsList)
        )
        .subscribe()
    );
  }

  ngOnInit() {
    this.disableFilter = true;
    this.showQAQC = false;

    this.activeRoute.params.subscribe((p) => {
      this.buId = +p['buId'];
      this.orgId = +p['orgId'];
    });

    this.loadUserPermissions();
    this.getFilter();
    this.setRangeOfDatesInput();

    // Load Pascagoula integration section
    if (this.pageFilter.selectedBU.Name === 'Pascagoula') {
      this.loadBusinessUnits();
    }
  }

  private setRangeOfDatesInput(): void {
    if (this.pageFilter.deletedProjectsRangeDate.length == 0) {
      const today = new Date();
      const beginingOfTheMonth = this.decorateMinRangeDate(
        new Date(today.getFullYear(), today.getMonth(), 1)
      );
      const todayEOD = this.decorateMaxRangeDate(new Date());
      this.pageFilter.deletedProjectsRangeDate = [beginingOfTheMonth, todayEOD];
    } else {
      const today = new Date(this.pageFilter.deletedProjectsRangeDate[0]);
      const beginingOfTheMonth = this.decorateMinRangeDate(
        new Date(today.getFullYear(), today.getMonth(), today.getDate())
      );
      const todayEOD = this.decorateMaxRangeDate(new Date(this.pageFilter.deletedProjectsRangeDate[1]));
      this.pageFilter.deletedProjectsRangeDate = [beginingOfTheMonth, todayEOD];
    }
  }

  private decorateMinRangeDate(minDate: Date): Date {
    if (minDate === null) {
      return minDate;
    }
    const date = new Date(minDate);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    return date;
  }
  private decorateMaxRangeDate(maxDate: Date): Date {
    if (maxDate === null) {
      return maxDate;
    }
    const date = new Date(maxDate);
    date.setHours(23);
    date.setMinutes(59);
    date.setSeconds(59);
    return date;
  }

  getFilter() {
    const localFilter = localStorage.getItem('locationFilter');

    if (Utility.isValidString(localFilter)) {
      const { currenUser, ...currentFilter } = JSON.parse(localFilter);
      this.savedLocationFilter = currentFilter;
    }

    this.pageFilter = new FilterProject();
    this.pageFilter.selectedBU = new BusinessUnit;
    this.pageFilter.selectedOrg = new Organization;
    this.pageFilter.searchDeletedProjects = false;
    this.pageFilter.deletedProjectsRangeDate = [];
    this.pageFilter.selectedStatus = this.selectedStatus;

    if (this.savedLocationFilter != null) {
      this.lstBUs = !!this.savedLocationFilter.buId ? [{ Id: this.savedLocationFilter.buId, Name: this.savedLocationFilter.buName } as BusinessUnit] : [];
      this.lstOrgs = !!this.savedLocationFilter.orgId ? [{ Id: this.savedLocationFilter.orgId, Name: this.savedLocationFilter.orgName } as Organization] : [];
      this.pageFilter.selectedBU = !!this.savedLocationFilter.buId ? { Id: this.savedLocationFilter.buId, Name: this.savedLocationFilter.buName } as BusinessUnit : new BusinessUnit;
      this.pageFilter.selectedOrg = !!this.savedLocationFilter.orgId ? { Id: this.savedLocationFilter.orgId, Name: this.savedLocationFilter.orgName } as Organization : new Organization;
      this.buId = this.savedLocationFilter.buId;
      this.orgId = this.savedLocationFilter.orgId;
    }
  }

  setFilter() {
    this.savedLocationFilter = new LocationFilter();
    this.savedLocationFilter.buId = this.pageFilter.selectedBU?.Id;
    this.savedLocationFilter.buName = this.pageFilter.selectedBU?.Name;
    this.savedLocationFilter.orgId = this.pageFilter.selectedOrg?.Id;
    this.savedLocationFilter.orgName = this.pageFilter.selectedOrg?.Name;

    localStorage.setItem('locationFilter', JSON.stringify({ ...this.savedLocationFilter }));
  }

  private loadUserPermissions(): void {
    const user = this.authService.getCurrentUser();
    this.showDeleteOptions =
      user.IsBUAdmin || user.IsSystemAdmin || user.IsOrgAdmin;
  }

  loadBusinessUnits(): Promise<void> {
    if (this.loadedCollection.isBULoaded) {
      return;
    }

    return this.svcBU
      .getUserBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBUs = data;
        this.loadedCollection.isBULoaded = true;

        if (this.lstBUs.length === 0) {
          return;
        }

        this.pageFilter.selectedBU = this.buId > 0
          ? this.lstBUs.find((x) => x.Id == this.buId)
          : this.lstBUs[0];

        if (!Utility.isValidObj(this.pageFilter.selectedBU)) {
          this.pageFilter.selectedBU = this.lstBUs[0];
        }

        this.loadBUInfo();
        this.setFilter();
        this.loadedCollection.isBULoaded = true;
      })
      .catch(() => this.toastrService.showError(this.translatePipe.transform('LoadBusinessUnitsErrorMessage')));
  }

  loadBUInfo() {
    this.buPLabels = [];
    this.buPLabels.push(this.pageFilter.selectedBU.P3Label);
    this.buPLabels.push(this.pageFilter.selectedBU.P4Label);

    if (this.pageFilter.selectedBU.ShowP5) {
      this.buPLabels.push(this.pageFilter.selectedBU.P5Label);
    }

    this.pageFilter.selectedBU.EventLabel = this.pageFilter.selectedBU.EventLabel
      ? this.pageFilter.selectedBU.EventLabel
      : 'Project';

    this.loadPssrTypes();

    this.showQAQC = this.pageFilter.selectedBU.ShowQAQC;
  }

  loadQAQCCollections() {
    if (!!!this.lstqaqcEvents?.length) {
      this.loadQAQCEvent();
      this.loadArea();
      this.loadTaskPlan();
    }
  }

  loadOrganizations() {
    if (!this.pageFilter.selectedBU) {
      return;
    }

    if (!this.loadedCollection.isOrgLoaded) {
      this.lstqaqcEvents = [];
      this.qaqcEventItem = null;

      this.lstOrgs = [];
      this.buPLabels = [];
      this.buPLabels.push(this.pageFilter.selectedBU.P3Label);
      this.buPLabels.push(this.pageFilter.selectedBU.P4Label);

      if (this.pageFilter.selectedBU.ShowP5) {
        this.buPLabels.push(this.pageFilter.selectedBU.P5Label);
      }

      this.clearfilter();

      this.svcOrg
        .getListByBU(this.pageFilter.selectedBU.Id)
        .toPromise()
        .then(
          (data: Organization[]) => {
            this.lstOrgs = data.sort((a, b) => a.Name.localeCompare(b.Name));

            this.pageFilter.selectedOrg = this.orgId > 0
              ? this.lstOrgs.find((x) => x.Id == this.orgId)
              : this.lstOrgs[0];

            if (!Utility.isValidObj(this.pageFilter.selectedOrg)) {
              this.pageFilter.selectedOrg = this.lstOrgs[0];
            }

            this.loadedCollection.isOrgLoaded = true;
            this.searchProjectsList(this.searchForm.value);

            this.setFilter();
          },
          () => this.toastrService.showError(this.translatePipe.transform('LoadOrganizationsErrorMessage'))
        );
    }
  }

  changeBusinessUnit() {
    const org = this.pageFilter.selectedOrg;
    this.loadedCollection.isOrgLoaded = false;
    this.pageFilter.selectedOrg = null;
    this.searchForm.value.org = null;
    this.loadBUInfo();
    if (org?.Id > 0) {
      this.changeOrg();
    }

    this.showQAQC = this.pageFilter.selectedBU.ShowQAQC;
    this.lstqaqcEvents = [];
    this.qaqcEventItem = null;
  }

  changeOrg() {
    this.searchProjectsList(this.searchForm.value).toPromise();
  }

  // Participants Bulk Loading
  addedPssrUsers(user: PSSRUser[]) {
    this.bulkPssrUsers = user;
  }

  addedSectionLeads(sectionLeads: SectionUser[]) {
    this.bulkSectionLeads = sectionLeads;
  }

  // QAQC
  loadQAQCEvent() {
    const qaqcRequestEvent = new QAQCRequest();
    qaqcRequestEvent.Site = this.pageFilter.selectedBU.Name;

    this.svcQAQC
      .getQAQCEventBySite(qaqcRequestEvent)
      .pipe(take(1))
      .subscribe(
        (data: QAQCResponse) => {
          this.lstqaqcEvents = data.Results.sort((a, b) => a.Name.localeCompare(b.Name));
          this.lstqaqcEvents = this.lstqaqcEvents.filter((x) => x.Name.length > 0);
        },
        () => this.toastrService.showError(
          this.translatePipe.transform('LoadQAQCErrorMessage', {
            EventLabel: this.translatePipe.transform(this.pageFilter.selectedBU.EventLabel)
          })
        )
      );
  }

  loadQAQCMilestone() {
    const qaqcRequest = new QAQCRequest();
    qaqcRequest.Site = this.pageFilter.selectedBU.Name;
    qaqcRequest.EventId = this.qaqcEventItem.Id;

    this.svcQAQC
      .getQAQCMilestoneBySite(qaqcRequest)
      .pipe(take(1))
      .subscribe((data: QAQCResponse) => {
        this.lstqaqcMilestone = data.Results.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        this.lstqaqcMilestone = this.lstqaqcMilestone.filter(
          (x) => x.Name.length > 0
        );
        this.lstqaqcMilestone.forEach((p: QAQCItem) => {
          this.milestoneSelectItem.push({
            label: p.Description !== null ? p.Description : p.Name,
            value: p.Id,
          });
        });

        this.showFilter = data.Error.length <= 1;
      });
  }

  loadQAQCWorkOrder() {
    const qaqcRequestWO = new QAQCRequest();
    qaqcRequestWO.Site = this.pageFilter.selectedBU.Name;
    qaqcRequestWO.EventId = this.qaqcEventItem.Id;

    this.svcQAQC
      .getQAQCWorkOrderBySite(qaqcRequestWO)
      .pipe(take(1))
      .subscribe((data: QAQCResponse) => {
        this.lstqaqcWO = data.Results.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        this.lstqaqcWO = this.lstqaqcWO.filter((x) => x.Name.length > 0);
        this.lstqaqcWO.forEach((p: QAQCItem) => {
          this.workorderSelectItem.push({
            label: p.Description !== null ? p.Description : p.Name,
            value: p.Id,
          });
        });

        this.showFilter = data.Error.length <= 1;
      });
  }

  loadQAQCMilestoneWO(item: string) {
    const qaqcRequestMWO = new QAQCRequest();
    qaqcRequestMWO.Site = this.pageFilter.selectedBU.Name;
    qaqcRequestMWO.MilestoneId = item;

    this.svcQAQC
      .getQAQCWorkOrderByMilestone(qaqcRequestMWO)
      .pipe(take(1))
      .subscribe((data: QAQCResponse) => {
        this.lstqaqcMilestoneWO = data.Results.sort((a, b) =>
          a.Name.localeCompare(b.Name)
        );
        this.lstqaqcMilestoneWO = this.lstqaqcMilestoneWO.filter(
          (x) => x.Name.length > 0
        );
        this.lstqaqcMilestoneWO.forEach((p: QAQCItem) => {
          this.milestoneWOSelectItem.push({
            label: p.Description !== null ? p.Description : p.Name,
            value: p.Id,
          });
        });
      });
  }

  qaqcTypeChange(type: boolean) {
    this.qaqcSelectedItems = [];
    if (type) {
      this.isQAQCWorkOrder = false;
    } else {
      this.isQAQCMilestone = false;
      this.isQAQCMilestoneWO = false;
      this.lstqaqcMilestoneWO = [];
      this.milestoneWOSelectItem = [];
    }
  }

  qaqcMilestoneChange() {
    if (this.isQAQCMilestone) {
      this.isQAQCMilestoneWO = false;
      if (this.qaqcSelectedItems.length === 1) {
        this.qaqcSelectedItemsMWO = [];
        this.lstqaqcMilestoneWO = [];
        this.milestoneWOSelectItem = [];
        this.loadQAQCMilestoneWO(this.qaqcSelectedItems[0]);
      }
    }
  }

  qaqcMilestoneChangeWO() {
    this.isQAQCMilestone = false;
    this.isQAQCMilestoneWO = true;
    if (this.qaqcSelectedItemsMWO.length === 0) {
      this.isQAQCMilestone = true;
      this.isQAQCMilestoneWO = false;
    }
  }

  async loadQAQC() {
    //
    // Check if Project Name already exist:
    this.showFilter = false;
    if (this.projects.find((x) => x.QAQCEventId === this.qaqcEventItem.Id)) {
      this.toastrService.showWarning(
        this.translatePipe.transform('SaveQAQCAlreadyExistsWarningMessage', {
          EventLabel: this.translatePipe.transform(this.pageFilter.selectedBU.EventLabel)
        })
      );
    } else {
      // QAQC Call
      this.isQAQCMilestone = true;
      this.isQAQCMilestoneWO = false;
      this.isQAQCWorkOrder = false;
      this.lstqaqcMilestone = [];
      this.lstqaqcMilestoneWO = [];
      this.lstqaqcWO = [];
      this.milestoneSelectItem = [];
      this.milestoneWOSelectItem = [];
      this.workorderSelectItem = [];

      this.loadQAQCMilestone();
      this.loadQAQCWorkOrder();
    }
  }

  // Create PSSR for qaqc
  onSubmitPSSR() {
    this.qaqcItemSubmit = [];
    let newProj = this.createNewProject();
    const qaqcM = this.getQAQCM(newProj.QAQCMilestoneId);

    newProj.ProjectUsers.Users = this.bulkPssrUsers.map<ProjectUser>(pu => ({
      ProjectID: this.newProject.Id,
      Role: pu.Role,
      UserEmail: pu.UserEmail,
      UserName: pu.UserName,
      UserCAI: pu.UserCAI,
      UserID: pu.UserID
    }) as ProjectUser);
    const createdProj = this.svcProject.addProject(newProj).pipe(
      take(1),
      finalize(() => {
        this.toastrService.showSuccess(
          this.translatePipe.transform('SaveQAQCSuccessMessage', {
            EventLabel: this.translatePipe.transform(this.pageFilter.selectedBU.EventLabel)
          })
        );
        newProj = new Project();
      }),
      tap(
        () => {
          // -
        },
        () => this.toastrService.showError(
          this.translatePipe.transform('AddProjectErrorMessage', {
            EventLabel: this.translatePipe.transform(this.pageFilter.selectedBU.EventLabel)
          })
        )
      )
    );

    createdProj.subscribe(async (res: Project) => {
      this.projectID = res.Id;

      if (this.qaqcItemSubmit.length > 0) {
        const automatedPSSR = this.createAutomatedPSSRs(qaqcM);

        this.createMultiPSSR(automatedPSSR).then(() => {
          this.svcRouter.navigate([
            'pssr-project-detail',
            {
              itemId: this.projectID,
              parentId: this.pageFilter.selectedBU.Id,
              orgId: this.orgId,
            },
          ]);
        });
      } else {
        this.toastrService.showError(this.qaqcEvent.Error);
      }
    });

    this.searchProjectsList(this.searchForm.value);
  }

  createMultiPSSR(pssrsList: PSSR[]): Promise<PSSR[]> {
    return this.svcPSSR
      .createMultiWithParticipant(pssrsList)
      .pipe(
        tap(
          () => {
            //
          },
          (error) => this.toastrService.showError(error.error.ExceptionMessage)
        )
      )
      .toPromise();
  }

  btnCancelPSSR() {
    this.softClearfilter();
  }

  loadPssrTypes() {
    this.pssrTypes = [];
    if (Utility.isValidListWithData(this.pageFilter.selectedBU.PssrTypes)) {
      this.pageFilter.selectedBU.PssrTypes.forEach((p: PSSRType) => {
        this.pssrTypes.push({ label: p.Name, value: p.Id });
      });
    }
  }

  visitProjectDetails(projectId: number): void {
    if (projectId >= 0) {
      this.projId = projectId;
      this.projectSelected = true;

      this.svcRouter.navigate([
        'pssr-project-detail',
        {
          itemId: projectId,
          parentId: this.pageFilter.selectedBU.Id,
          orgId: this.pageFilter.selectedOrg.Id,
        },
      ]);
    } else {
      this.projectSelected = false;
    }
  }

  searchProjectsList = (
    value: {
      org: { Id: number };
      projectStatuses: ProjectStatus[];
      isProjectDeleted: boolean;
      dateRangeOfProjects: Date[];
    } = {
        org: { Id: 0 },
        projectStatuses: [ProjectStatus.Active],
        isProjectDeleted: false,
        dateRangeOfProjects: [null, null],
      }
  ): Observable<Project[]> => {
    value = {
      ...{
        projectStatuses: [ProjectStatus.Active],
        isProjectDeleted: false,
      },
      ...value,
    };
    value.org = value.org || { Id: 0 };
    value.dateRangeOfProjects = value.dateRangeOfProjects || [null, null];

    let [minRangeDate = null, maxRangeDate = null] = value.dateRangeOfProjects;
    if (minRangeDate !== null) {
      minRangeDate = this.decorateMinRangeDate(minRangeDate);
    }
    if (maxRangeDate !== null) {
      maxRangeDate = this.decorateMaxRangeDate(maxRangeDate);
    }

    if (value.org.Id === 0) {
      this.projects = [];
      return of([]);
    }

    return this.svcProject
      .searchProjectsList({
        isDeleted: value.isProjectDeleted,
        organizationID: value.org.Id,
        statuses: value.projectStatuses,
        minRangeDate,
        maxRangeDate,
      })
      .pipe(tap((data) => this.prepareProjectsList(data)));
  }

  private prepareProjectsList(data: Project[]): void {
    this.projects = data
      .map((project) => ({
        ...project,
        statusClass: this.svcProject.getLabelClass(project),
      }))
      .sort((a, b) => a.Name.localeCompare(b.Name));

    this.setFilter();
  }

  enableFilter() {
    this.disableFilter = false;
  }

  onRedirectButtonClick(
    url: string,
    id: number,
    pId: number,
    templateMode: boolean
  ): void {
    this.svcRouter.navigate([
      url,
      { itemId: id, parentId: pId, isTemplate: templateMode },
    ]);
  }

  onSubmitNewProject() {
    if (!this.pageFilter.selectedOrg?.Id) {
      this.toastrService.showWarning(this.translatePipe.transform('Organizationisrequired'));
      this.createBtnClicked = false;
      return;
    }

    this.newProject.OrganizationId = this.pageFilter.selectedOrg.Id;
    this.createBtnClicked = true;
    this.newProject.Description = '';
    this.newProject.Status = ProjectStatus.Active;
    const newProj = this.svcProject.addProject(this.newProject).pipe(
      take(1),
      finalize(() => {
        this.toastrService.showSuccess(
          this.translatePipe.transform('AddProjectSuccessMessage')
        );

        this.createBtnClicked = false;
        this.newProject = new Project();
      }),
      tap(
        () => {
          //
        },
        () => {
          this.toastrService.showError(
            this.translatePipe.transform('AddProjectErrorMessage', {
              EventLabel: this.translatePipe.transform(this.pageFilter.selectedBU.EventLabel)
            })
          );

          this.createBtnClicked = false;
        }
      ),
      switchMap(() => this.searchProjectsList(this.searchForm.value))
    );

    newProj.subscribe();
  }

  loadArea() {
    if (this.pageFilter.selectedBU) {
      this.svcArea
        .getAllByBusinessUnit(this.pageFilter.selectedBU.Id)
        .pipe(take(1))
        .subscribe((data: Area[]) => {
          this.lstArea = data.sort((a, b) => a.Name.localeCompare(b.Name));

          if (this.lstArea.length == 1) {
            this.areaID = this.lstArea[0].Id;
          }

          this.setFilter();
        });
    }
  }

  loadSubArea() {
    if (this.areaID > 0) {
      this.svcSubArea
        .getAllByAreaId(this.areaID)
        .pipe(take(1))
        .subscribe((data: SubArea[]) => {
          this.lstSubArea = data.sort((a, b) => a.Name.localeCompare(b.Name));

          if (this.lstSubArea.length == 1) {
            this.subareaID = this.lstSubArea[0].Id;
          }

          this.setFilter();
        });
    }
  }

  loadFacility() {
    if (this.subareaID > 0) {
      this.svcFacility
        .getAllBySubArea(this.subareaID)
        .pipe(take(1))
        .subscribe((data: Facility[]) => {
          this.lstFacility = data.sort((a, b) => a.Name.localeCompare(b.Name));

          if (this.lstFacility.length == 1) {
            this.facilityID = this.lstFacility[0].Id;
          }

          this.setFilter();
        });
    }
  }

  loadTaskPlan() {
    this.svcTaskPlan
      .getTaskPlans(this.pageFilter.selectedBU.Id)
      .pipe(take(1))
      .subscribe(
        (data: TaskPlan[]) => {
          this.lstTaskPlan = data.filter(
            (t) => t.TaskPlanType === TaskPlanType.PSSR && !t.IsDeleted
          );
        },
        () => {
          // TODO implement
        }
      );
  }

  async changeArea() {
    this.subareaID = null;
    this.facilityID = null;
    this.lstSubArea = [];
    this.lstFacility = [];
    this.loadSubArea();
  }

  changeSubArea() {
    this.facilityID = null;
    this.lstFacility = [];
    this.loadFacility();
  }

  softClearfilter() {
    this.showFilter = false;
    this.taskplanID = null;
    this.areaID = null;
    this.subareaID = null;
    this.facilityID = null;
    this.pssrTypeID = null;
    this.projectID = null;
  }

  clearfilter() {
    this.showFilter = false;
    this.taskplanID = null;
    this.areaID = null;
    this.subareaID = null;
    this.facilityID = null;
    this.pssrTypeID = null;
    this.projectID = null;
    this.lstArea = [];
    this.lstSubArea = [];
    this.lstFacility = [];
    this.lstTaskPlan = [];
  }

  private createNewProject(): Project {
    const newProj = new Project();

    newProj.Name = this.qaqcEventItem.Name;
    newProj.QAQCEventId = this.qaqcEventItem.Id;
    newProj.Status = ProjectStatus.Active;

    if (this.isQAQCMilestone) {
      newProj.QAQCType = QAQCType.Milestone;
      this.qaqcSelectedItems.forEach((itm) =>
        this.qaqcItemSubmit.push(
          this.lstqaqcMilestone.find((x) => x.Id === itm)
        )
      );
    } else if (this.isQAQCWorkOrder) {
      newProj.QAQCType = QAQCType.WorkOrder;
      this.qaqcSelectedItems.forEach((itm) =>
        this.qaqcItemSubmit.push(this.lstqaqcWO.find((x) => x.Id === itm))
      );
    } else if (this.isQAQCMilestoneWO) {
      newProj.QAQCType = QAQCType.MilestoneWorkOrder;
      this.qaqcSelectedItemsMWO.forEach((itm) =>
        this.qaqcItemSubmit.push(
          this.lstqaqcMilestoneWO.find((x) => x.Id === itm)
        )
      );

      newProj.QAQCMilestoneId = this.qaqcSelectedItems[0];
    }

    newProj.OrganizationId = this.pageFilter.selectedOrg.Id;
    newProj.Description = this.qaqcEventItem.Description
      ? this.qaqcEventItem.Description
      : '';

    return newProj;
  }

  private getQAQCM(id: string): QAQCItem {
    return this.lstqaqcMilestone.find((x) => x.Id === id);
  }

  private createAutomatedPSSRs(qaqcM: QAQCItem): PSSR[] {
    const automatedPSSR: PSSR[] = [];

    for (const item of this.qaqcItemSubmit) {
      const newPSSR = new PSSR();
      newPSSR.AreaID = this.areaID;
      newPSSR.BusinessUnitID = this.pageFilter.selectedBU.Id;
      newPSSR.Name = item.Description !== null ? item.Description : item.Name;
      newPSSR.Description = item.Description;
      newPSSR.OrganizationID = this.pageFilter.selectedOrg.Id;
      newPSSR.ProjectID = this.projectID;
      newPSSR.Status = PSSRStatus.Draft;
      newPSSR.SubAreaID = this.subareaID;
      newPSSR.TaskPlanID = this.taskplanID;
      newPSSR.FacilityID = this.facilityID;
      newPSSR.PssrTypeId = this.pssrTypeID;
      newPSSR.MOC = item.MOCNumber;

      if (this.isQAQCMilestone) {
        newPSSR.QAQCMilestoneId = item.Id;
        newPSSR.QAQCMilestone = item.Name;
        newPSSR.QAQCType = QAQCType.Milestone;
      } else if (this.isQAQCWorkOrder) {
        newPSSR.QAQCWorkOrder = item.Name;
        newPSSR.QAQCWorkOrderId = item.Id;
        newPSSR.WO = item.Name;
        newPSSR.QAQCType = QAQCType.WorkOrder;
      } else if (this.isQAQCMilestoneWO) {
        newPSSR.QAQCMilestone = qaqcM.Name;
        newPSSR.QAQCMilestoneId = qaqcM.Id;
        newPSSR.QAQCWorkOrder = item.Name;
        newPSSR.QAQCWorkOrderId = item.Id;
        newPSSR.WO = item.Name;
        newPSSR.QAQCType = QAQCType.MilestoneWorkOrder;
      }

      // Include Participants and Section lead with bulk upload:
      newPSSR.PSSRSectionUsers = new SectionUserList();
      newPSSR.PSSRSectionUsers.SectionUsers = this.bulkSectionLeads;
      newPSSR.PSSRUsers = new PSSRUserList();
      newPSSR.PSSRUsers.Users = this.bulkPssrUsers;

      automatedPSSR.push(newPSSR);
    }

    return automatedPSSR;
  }

  restoreProject(projectId: number): void {
    this.svcProject
      .restoreProject(projectId)
      .pipe(take(1))

      .subscribe(
        (res) => {
          this.pageFilter.searchDeletedProjects = false;
          this.selectedStatus = [ProjectStatus.Active];
          this.toastrService.showSuccess(res.Message);
        },
        (err) => this.toastrService.showError(err.error.Message)
      );
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  searchFn = (item) => {
    if (this.pageFilter.searchText != '' && this.pageFilter.searchText != null) {
      return (
        item.Name.toLowerCase().indexOf(this.pageFilter.searchText.toLowerCase()) !== -1
      );
    } else {
      return true;
    }
  }
}
