import { Component, OnInit, Input } from '@angular/core';

import { CategoryService, SubCategoryService } from '#services/api';
import { SubCategory } from '#models/subcategory';
import { Category } from '#models/category';

@Component({
  selector: 'app-subcategory-dialog',
  templateUrl: './subcategory-dialog.component.html',
  styleUrls: ['./subcategory-dialog.component.scss'],
})
export class SubcategoryDialogComponent implements OnInit {
  @Input() category = {
    SubCategories: [],
  } as Category;

  visible: boolean;
  subcategories: SubCategory[] = [];
  newSubCategoryName: string;
  selectedValues: number[] = [];

  constructor(
    public categorySvc: CategoryService,
    public subcategorySvc: SubCategoryService
  ) {}

  ngOnInit() {
    this.loadSubCategories();
    this.selectedValues = this.category.SubCategories.map((s) => s.Id);
  }

  show() {
    this.visible = true;
  }

  hide() {
    this.visible = false;
  }

  async save() {
    try {
      const selectedSubCatgories = this.subcategories.filter(
        (s) => this.selectedValues.indexOf(s.Id) !== -1
      );

      this.category.SubCategories = selectedSubCatgories;
      this.categorySvc.put(this.category).toPromise();
    } catch (e) {
      // TODO implement
    } finally {
      this.hide();
    }
  }

  addSubCategory() {
    const entity = <SubCategory>{
      Name: this.newSubCategoryName,
      IsActive: true,
    };

    this.subcategorySvc
      .post(entity)
      .toPromise()
      .then((newSubCategory) => {
        this.subcategories = [newSubCategory, ...this.subcategories];
        this.newSubCategoryName = '';
      });
  }

  private async loadSubCategories() {
    this.subcategories = await this.subcategorySvc.get().toPromise();
  }
}
