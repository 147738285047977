import { Injectable } from '@angular/core';
import { MOCChecklistType } from '#models/enum/moc-checklist-type';


@Injectable()
export class MOCChecklistHelper {

  lockedMOCChecklistTypes = [MOCChecklistType.FRA as string, MOCChecklistType.FR as string, MOCChecklistType.PSI as string,  MOCChecklistType.RS as string, MOCChecklistType.PSSR as string, MOCChecklistType.ROOTMOC as string];

  public isMOCChecklistLocked(itemCode: string) {
    return (this.lockedMOCChecklistTypes.includes(itemCode) || itemCode.startsWith(MOCChecklistType.IS));
  }
}
