<div class="box-shadow module">

  <h2>{{'EditOrganization'|translate}}</h2>
  <hr />
  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{'Business Unit'|translate}}</label>
      <div class="col-md-8">
        <input
          [appBUSwitcher]="selectedBU"
          class="form-control text-box single-line"
          [(ngModel)]="organization.BusinessUnitName"
          [disabled]="true"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{'Name'|translate}}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="organization.Name"
        />
        <span
          *ngIf="
            organization.Name == null ||
            organization.Name == undefined ||
            organization.Name.length == 0
          "
          class="text-danger field-validation-error"
        >
          {{ "This field is required" | translate }}
        </span>
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-4 col-md-1">
        <a class="btn btn-default" style="width: 100px" (click)="save()"
          >{{'Save'|translate}}</a
        >
      </div>
      <div class="col-md-offset-1 col-md-2">
        <a class="btn btn-danger" style="width: 100px" (click)="delete()"
          >{{'Delete'|translate}}</a
        >
      </div>
    </div>
  </div>
  <div>
    <a (click)="onRedirectButtonClick()" style="cursor: pointer"
      >{{'Back to List'|translate}}</a
    >
  </div>
</div>
