import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { Attachment, Feedback } from '#models/feedback';
import {
  FeedbackService,
  UserService,
  BusinessUnitsService,
} from '#services/api';
import { User } from '#models/user';
import { BusinessUnit } from '#models/business-unit';
import { AuthService } from '#services/shared';
import { LoggedUser } from '#models/logged-user';
import { Utility } from '#services/shared/utility';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-feedback-edit',
  templateUrl: './feedback-edit.component.html',
  providers: [TranslatePipe],
})
export class FeedbackEditComponent implements OnInit {
  feedback: Feedback = new Feedback();

  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];

  selectedUser: User;
  users: User[];

  loggedUser: LoggedUser;

  buWarning: boolean;
  userWarning: boolean;
  titleWarning: boolean;
  descriptionWarning: boolean;

  selectedFile: File;
  fileBase64: string;
  files: Attachment[] = [];

  constructor(
    private feedbackSvc: FeedbackService,
    private router: Router,
    private userSvc: UserService,
    private businessUnitsSvc: BusinessUnitsService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private translatePipe: TranslatePipe,
    private toastrService: ToastrService
  ) {}

  async ngOnInit() {
    this.buWarning = false;
    this.userWarning = false;
    this.titleWarning = false;
    this.descriptionWarning = false;
    this.route.params.subscribe((v) => {
      this.feedback.ADO = v['ado'];
    });

    this.loggedUser = this.authService.getCurrentUser();
    if (this.loggedUser.IsSystemAdmin) {
      await this.loadBusinessUnits();
    } else {
      this.selectedBU = this.loggedUser.BusinessUnits[0];
      this.feedback.BusinessUnitId = this.selectedBU
        ? this.selectedBU.Id
        : null;
    }
    await this.getByADO(this.feedback.ADO);
  }

  async getByADO(ado: string) {
    await this.feedbackSvc
      .getByADO(ado)
      .toPromise()
      .then((data: Feedback) => {
        this.feedback = data;
        const comments = this.feedback.Comments.replace(/<[^>]+>/g, '');
        this.feedback.Comments = comments.replace(
          new RegExp('&nbsp;', 'g'),
          ''
        );
      });
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['feedback']);
  }

  async loadBusinessUnits() {
    await this.businessUnitsSvc
      .getUserBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        this.selectedBU = this.lstBusinessUnit[0];
      });
  }

  getUsers(event) {
    const query = event.query;
    this.userSvc
      .autocompleteUsersByBU(this.feedback.BusinessUnitId, query)
      .subscribe((data) => {
        this.users = data;
        this.users.forEach(function (_, index) {
          this[index].FullName = Utility.formatUser(this[index]);
        }, this.users);
      });
  }

  save(redirect: boolean = true): void {
    this.feedback.Attachment = this.files;
    this.feedback.UserId = this.loggedUser.ID;
    if (this.isValid()) {
      this.feedbackSvc
        .update(this.feedback)
        .toPromise()
        .then(
          (data: Feedback) => {
            if (redirect) {
              this.onRedirectButtonClick();
            }
            this.feedback = data;
            this.toastrService.showSuccess(
              this.translatePipe.transform('Feedback Updated')
            );
          },
          (error) => {
            if (this.feedback.Attachment.length > 0) {
              this.toastrService.showError(
                this.translatePipe.transform(
                  'UpdatedFeedbacksAttachmentErrorMessage'
                )
              );
            } else {
              this.toastrService.showError(error.error.ExceptionMessage);
            }

            window.scrollTo(0, 0);
          }
        );
    }
  }

  isValid(): boolean {
    this.titleWarning = !Utility.isValidString(this.feedback.Title);
    this.descriptionWarning = !Utility.isValidString(this.feedback.Comments);
    return (
      !this.buWarning &&
      !this.userWarning &&
      !this.titleWarning &&
      !this.descriptionWarning
    );
  }

  async attachments(event) {
    this.selectedFile = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = await this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(this.selectedFile);
  }

  remove(fileName: string) {
    const index = this.files.findIndex((x) => x.name === fileName);
    this.files.splice(index, 1);
  }

  async _handleReaderLoaded(readerEvt) {
    // show loading
    const binaryString = readerEvt.target.result;
    this.fileBase64 = btoa(binaryString);

    // Construct Object
    const attach = new Attachment();
    attach.base64 = this.fileBase64;
    attach.name = this.selectedFile.name;
    if (this.selectedFile.type.length < 1) {
      const fileParts = this.selectedFile.name.split('.');
      if (fileParts !== null && fileParts !== undefined) {
        attach.contentType = fileParts[fileParts.length - 1];
      }
    } else {
      attach.contentType = this.selectedFile.type;
    }
    this.files.push(attach);
  }
}
