import { Component, OnInit } from '@angular/core';
import { AreaService } from '#services/api/area.service';
import { Area } from '#models/area';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Utility } from '#services/shared/utility';

@Component({
  selector: 'app-area',
  templateUrl: './area.component.html',
})
export class AreaComponent implements OnInit {
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  lstAreas: Area[] = [];
  buId: number;

  constructor(
    private businessUnitsSvc: BusinessUnitsService,

    private areaService: AreaService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.selectedBU = new BusinessUnit();
    this.route.params.subscribe((v) => {
      this.buId = parseInt(v['buId'], 10);
    });
    this.loadBusinessUnits();
  }

  loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserAdminBUs().subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        if (isNaN(this.buId)) {
          this.selectedBU = this.lstBusinessUnit[0];
        } else {
          this.selectedBU = this.lstBusinessUnit.find((x) => x.Id === this.buId);
        }
        this.loadAreas();
      }
    });
  }

  loadAreas(): void {

    this.areaService
      .getAllByBusinessUnit(this.selectedBU.Id)

      .subscribe((data: Area[]) => {
        this.lstAreas = [];
        if (Utility.isValidListWithData(data)) {
          this.lstAreas = data.sort((a, b) => a.Name.localeCompare(b.Name));
        }
      });
  }

  onRedirectButtonClick(url: string, buId: number, areaId: number): void {
    this.router.navigate([
      url,
      { areaId: areaId, buId: buId, hierarchyLabel1: this.selectedBU.HierarchyLabel1, hierarchyLabel2: this.selectedBU.HierarchyLabel2, hierarchyLabel3: this.selectedBU.HierarchyLabel3 },
    ]);
  }
}
