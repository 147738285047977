
import { PSSR } from './pssr';
import { ProjectUser } from './project-user';
import { ProjectUserList } from './project-user-list';
import { QAQCType } from './qaqc-milestone';

export class Project {
  Id: number;
  Name: string;
  Description: string;
  OrganizationId: number;

  Toggle: Boolean;

  // ** Internal Variables **
  PSSR: PSSR[];
  StatusList: any[];
  ActionsList: any[];
  ActionsProject: any[];
  CostCode: string;
  ComissionDate: Date;
  Latitude: number;
  Longitude: number;

  Status: ProjectStatus;
  StatusName: string;
  statusClass: string;


  PssrTotal: number;
  PssrActual: number;
  PssrOverall: number;

  CurrentUser: ProjectUser;
  ProjectUsers: ProjectUserList;
  QAQCEventId: string;
  QAQCMilestoneId: string;
  QAQCWorkOrderId: string;
  QAQCType: QAQCType;

  IsDeleted: boolean;

  DeletedOn: Date;
  DeletedBy: string;
  RestoredOn: Date;
  RestoredBy: string;
  CreateDate: Date;

  constructor() {
    this.Toggle = false;
    this.CurrentUser = {
      CanEdit: false,
      IsBusinessUnitAdmin: false,
      IsOrgAdmin: false
    } as ProjectUser;
    this.ProjectUsers = {
      ResultModel: {
        Message: '',
        Success: false,
      },
      Users: []
    };
  }

}

export enum ProjectStatus {
  Active = 1,
  Closed = 2
}
