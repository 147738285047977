export class KMSRequest {
  Type: string;
  ReferenceNumber: string;
  Period: number;
  Number: number;
}

export class KMSRequestList {
  Status: string;
  FacilityName: string;
  PageNumber: number;
  PageSize: number;
}

export class KMSResponse {
  moreResults: boolean;
  totalRecords: number;
  pageNumber: number;
  pageSize: number;
  mocResponses: KMSResponseItem[];
}

export class KMSResponseItem {
  equipmentRevDate: string;
  initiationDate: string;
  emergencyApprovalTwoComment: string;
  acceptedDate: string;
  changeCategory: string;
  emergencyApprovalTwo: string;
  equipmentName: string;
  approvalType: string;
  emergencyApprovalFour: string;
  owner: string;
  responsiblePersonCai: string;
  unitPriority: string;
  emergencyApprovalThree: string;
  approvalClosureComments: string;
  targetClosureDate: string;
  approvalTargetDate: string;
  responsiblePerson: string;
  title: string;
  wrType: string;
  riskRank: string;
  suggestedFix: string;
  approvalAssignee: string;
  acceptedByCai: string;
  facilityName: string;
  actualClosureDate: string;
  legacyReferenceNumber: string;
  isConfidential: string;
  riskRankingChoice: string;
  emergencyApprovalThreeComment: string;
  workOrderNumber: string;
  status: string;
  approvalResolution: string;
  acceptedBy: string;
  justification: string;
  wrPeriod: number;
  description: string;
  approvalCloseDate: string;
  unitName: string;
  temporaryTerminationDate: string;
  emergencyApprovalOne: string;
  targetStartupDate: string;
  emergencyApprovalOneComment: string;
  riskTimeFrame: string;
  basis: string;
  sourceType: string;
  emergencyType: string;
  isTemporary: string;
  sourceId: string;
  equipmentId2: string;
  equipmentType: string;
  equipmentId1: string;
  approvalJustification: string;
  emergencyApprovalFourComment: string;
  ownerCai: string;
  approvalStatus: string;
  wrNumber: number;
  temporaryExpirationDate: string;
  approvalAssigneeCai: string;
  parentReferenceNumber: string;
  actualStartupDate: string;
}
