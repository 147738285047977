import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusinessUnitsService, CategoryService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Category } from '#models/category';

import { Subscription } from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
})
export class CategoryComponent implements OnInit, OnDestroy {
  businessUnit: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  buId: number;

  subscription = new Subscription();

  get categories() {
    return this.businessUnit.Categories;
  }

  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private route: ActivatedRoute,
    private categorySvc: CategoryService
  ) {}

  ngOnInit() {
    this.businessUnit = new BusinessUnit();

    this.subscription.add(
      this.route.params.subscribe((params) => {
        this.buId = parseInt(params['buId'], 10);
      })
    );

    this.loadBusinessUnits();
  }

  loadBusinessUnits(): void {
    this.businessUnitsSvc
      .getUserAdminBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          if (isNaN(this.buId)) {
            this.businessUnit = this.lstBusinessUnit[0];
          } else {
            this.businessUnit = this.lstBusinessUnit.find(
              (x) => x.Id === this.buId
            );
          }
        }
      });
  }

  activateCategory(category: Category): void {
    this.categorySvc
      .put({ ...category, IsActive: true })
      .toPromise()
      .then((cat) => {
        this.businessUnit.Categories = this.businessUnit.Categories.map((c) =>
          c.Id === cat.Id ? cat : c
        );
      });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
