import { Observable } from 'rxjs';
import { MobileAppVersion } from '#models/mobile-app-version';
import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@Injectable()
export class MobileAppVersionService {
  constructor(private http: HttpClient) {}

  public getAppVersions(): Observable<Array<MobileAppVersion>> {
    return this.http
      .get<Array<MobileAppVersion>>(
        `${environment.apiEndpoint}/mobile-app-version`
      )
      .pipe(share());
  }

  public getByID(appVersionID: number): Observable<MobileAppVersion> {
    return this.http
      .get<MobileAppVersion>(
        `${environment.apiEndpoint}/mobile-app-version/${appVersionID}`
      )
      .pipe(share());
  }

  public create(appVersion: MobileAppVersion): Observable<MobileAppVersion> {
    return this.http.post<MobileAppVersion>(`${environment.apiEndpoint}/mobile-app-version`, appVersion)
            .pipe(share());
  }

  public update(appVersion: MobileAppVersion): Observable<MobileAppVersion> {
    return this.http
      .put<MobileAppVersion>(
        `${environment.apiEndpoint}/mobile-app-version`,
        appVersion
      )
      .pipe(share());

  }
}
