import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotFoundComponent } from './not-found/not-found.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { NotAuthorizedComponent } from './not-authorized/not-authorized.component';
import { NotRegisteredComponent } from './not-registered/not-registered.component';
import { FormsModule } from '@angular/forms';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { LanguageLoader } from '#services/shared/language-loader';
import { LanguageParser } from '#services/shared/language-parser';

@NgModule({
  declarations: [
    NotFoundComponent,
    GenericErrorComponent,
    NotAuthorizedComponent,
    NotRegisteredComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: LanguageLoader },
      parser: {
        provide: TranslateParser,
        useClass: LanguageParser,
      },
      useDefaultLang: true,
    }),
  ],
  providers: [],
})
export class ErrorPagesModule {}
