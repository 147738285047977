import { Component, OnInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import {
  Feedback,
  FeedbackPriority,
  FeedbackType,
  FeedbackEnvironment,
  Attachment,
} from '#models/feedback';
import {
  FeedbackService,
  UserService,
  BusinessUnitsService,
} from '#services/api';
import { User } from '#models/user';
import { BusinessUnit } from '#models/business-unit';
import { AuthService, LoadingIndicatorService } from '#services/shared';
import { LoggedUser } from '#models/logged-user';
import { Utility } from '#services/shared/utility';
import { ToastrService } from '#services/shared/toastr.service';


@Component({
  selector: 'app-feedback-create',
  templateUrl: './feedback-create.component.html',
})
export class FeedbackCreateComponent implements OnInit {
  feedback: Feedback = new Feedback();
  files: Attachment[] = [];

  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];

  selectedUser: User;
  users: User[];

  loggedUser: LoggedUser;

  buWarning: boolean;
  userWarning: boolean;
  titleWarning: boolean;
  descriptionWarning: boolean;

  selectedFile: File;
  fileBase64: string;

  constructor(
    private loadingIndicatorService: LoadingIndicatorService,
    private feedbackSvc: FeedbackService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private userSvc: UserService,
    private businessUnitsSvc: BusinessUnitsService,
    private authService: AuthService,
    private toastrService: ToastrService
  ) {}

  isLoading$ = this.loadingIndicatorService.isVisible();

  async ngOnInit() {
    this.buWarning = false;
    this.userWarning = false;
    this.titleWarning = false;
    this.descriptionWarning = false;
    this.feedback.Type = FeedbackType.Bug;
    this.feedback.Environment = FeedbackEnvironment.Mobile;
    this.feedback.Priority = FeedbackPriority.High;
    this.feedback.IsActive = true;
    this.loggedUser = this.authService.getCurrentUser();

    await this.loadBusinessUnits();

    this.selectedBU =
      this.loggedUser.BusinessUnits.find(
        (b) => +this.activatedRoute.snapshot.params['buId'] === b.Id
      ) || this.loggedUser.BusinessUnits[0];
    this.feedback.BusinessUnitId = this.selectedBU ? this.selectedBU.Id : null;
    this.feedback.UserId = this.loggedUser.ID;
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['feedback']);
  }

  async loadBusinessUnits() {
    await this.businessUnitsSvc
      .getUserBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
      });
  }

  getUsers(event: any) {
    const query = event.query;
    const observable = this.userSvc.autocompleteUsersByBU(
      this.feedback.BusinessUnitId,
      query
    );
    observable.subscribe((users) => {
      this.users = users;
      this.users.forEach((user) => {
        user.FullName = Utility.formatUser(user);
      });
    });
  }

  async save() {
    this.feedback.Attachment = this.files;
    this.feedback.UserId = this.loggedUser.ID;

    if (this.isValid()) {
      await this.feedbackSvc
        .create(this.feedback)
        .toPromise()
        .then(
          () => {
            this.toastrService.showSuccess('Feedback Successfully added');

            setTimeout(() => {
              this.onRedirectButtonClick();
            }, 2000);
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);
          }
        );
    }
  }

  isValid(): boolean {
    if (this.loggedUser.IsSystemAdmin) {
      this.userWarning = !(this.feedback.UserId > 0);
    }

    this.buWarning = !(this.feedback.BusinessUnitId > 0);
    this.titleWarning = !Utility.isValidString(this.feedback.Title);
    this.descriptionWarning = !Utility.isValidString(this.feedback.Comments);

    return (
      !this.buWarning &&
      !this.userWarning &&
      !this.titleWarning &&
      !this.descriptionWarning
    );
  }

  async attachments(event) {
    this.selectedFile = <File>event.target.files[0];
    const reader = new FileReader();
    reader.onload = await this._handleReaderLoaded.bind(this);
    reader.readAsBinaryString(this.selectedFile);
  }

  async _handleReaderLoaded(readerEvt) {
    // show loading
    const binaryString = readerEvt.target.result;
    this.fileBase64 = btoa(binaryString);

    // Construct Object
    const attach = new Attachment();
    attach.base64 = this.fileBase64;
    attach.name = this.selectedFile.name;
    if (this.selectedFile.type.length < 1) {
      const fileParts = this.selectedFile.name.split('.');
      if (fileParts !== null && fileParts !== undefined) {
        attach.contentType = fileParts[fileParts.length - 1];
      }
    } else {
      attach.contentType = this.selectedFile.type;
    }
    this.files.push(attach);
  }
  remove(fileName: string) {
    const index = this.files.findIndex((x) => x.name === fileName);
    this.files.splice(index, 1);
  }
  navigateTo(buId) {
    this.router.navigate([
      '/admin/feedback-create',
      {
        buId,
      },
    ]);
  }
}
