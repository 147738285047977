
import { Utility } from '#services/shared/utility';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class EnvironmentGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!Utility.validateEnvironment(route.data.environments)) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
