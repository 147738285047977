<form [formGroup]="translationRequestForm">
  <div
    class="ui-dialog-titlebar ui-widget-header ui-helper-clearfix ui-corner-top"
  >
    <span class="ui-dialog-title">{{
      "Select user for Translation Role" | translate
    }}</span>
    <button
      (click)="dialogRef.close()"
      role="button"
      class="ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all"
    >
      <span class="pi pi-times"></span>
    </button>
  </div>

  <div class="ui-dialog-content ui-widget-content">
    <ng-container *ngIf="data.availableLanguages">
      <label for="Language">{{ "Translate to Language" | translate }}</label>
      <select class="form-control" formControlName="Language">
        <option
          *ngFor="let option of data.availableLanguages"
          [value]="option.value"
        >
          {{ option.label | translateIn:option.value | async }}
        </option>
      </select>
    </ng-container>

    <label for="SelectedParticipant"
      >{{ "Select translator" | translate }}&nbsp; &nbsp;</label
    >
    <p-autoComplete
      formControlName="SelectedParticipant"
      [suggestions]="participants"
      (completeMethod)="lookupParticipants($event)"
      field="FullName"
      delay="1000"
      [style]="{ width: '100%' }"
      (onSelect)="selectParticipant($event)"
      [inputStyle]="{ width: '100%' }"
      [placeholder]="'typeusercainame'|translate"
      (onClear)="onClearLookup()"
    >
      <ng-template let-participant pTemplate="item">
        {{ participant | participantType }}
      </ng-template>
    </p-autoComplete>

    <div *ngIf="translationRequestForm.controls.TranslatorEmail?.value">
      <p>
        {{ translationRequestForm.controls.TranslatorEmail?.value }}
      </p>
    </div>
  </div>

  <div class="row ui-dialog-footer ui-widget-content">
    <button type="button" class="btn btn-default" (click)="dialogRef.close()">
      {{ "Cancel" | translate }}
    </button>
    <button
      type="submit"
      [disabled]="translationRequestForm.invalid"
      (click)="requestTranslation(translationRequestForm.value)"
      class="btn btn-primary"
    >
      {{ "Request Translation" | translate }}
    </button>
  </div>
</form>
