import { Component, OnInit, Input } from '@angular/core';
import { EndDeviceType } from '#models/end-device-type';
import { EndDeviceTypeService } from '#services/api/end-device-type.service';

@Component({
  selector: 'app-end-device-type-name',
  templateUrl: './end-device-type-name.component.html',
  styleUrls: ['./end-device-type-name.component.scss'],
  providers: [EndDeviceTypeService]
})
export class EndDeviceTypeNameComponent implements OnInit {
  @Input() id: number;
  endDeviceType: EndDeviceType;

  constructor(public svcEndDeviceType: EndDeviceTypeService) { }

  ngOnInit() {
    this.loadDeviceType(this.id);
  }

  public loadDeviceType(typeId: number) {
    this.svcEndDeviceType.getEndDeviceTypeById(typeId)
      .subscribe((data: EndDeviceType) => {
        this.endDeviceType = data;
      });
  }

}
