<div class="welcome-module module box-shadow vertical-align row" *ngIf="userName">
  <span class="col-md-3">
    <!-- Place holder-->
  </span>
  <div class="col-md-6" style="text-align: center;">
    <h4 class="hidden-xs">
      <span id="welcome-user">{{'You are logged in as' | translate}}: {{userName}}</span>
    </h4>
  </div>
  <div class="col-md-3" style="text-align: right;">
    <button *ngIf="isB2CLogged" class="btn btn-danger" (click)="logout()">{{'Logout'|translate}}</button>
  </div>
</div>
