import { BusinessUnit } from '#models/business-unit';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Utility } from './utility';

@Injectable({ providedIn: 'root' })
export class BusinessUnitStorageService {
  private _currentBusinessUnit: BehaviorSubject<BusinessUnit> =
    new BehaviorSubject<BusinessUnit>(null);

  public readonly currentBusinessUnit$ =
    this._currentBusinessUnit.asObservable();

  public setCurrentBusinessUnit(bu: BusinessUnit) {
    this._currentBusinessUnit.next({
      ...bu,
      PssrTitle: Utility.getPssrTitle(bu),
    });
  }
}
