import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlan } from '#models/task-plan';

import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '#services/shared/toastr.service';
import { ResultModel } from '#models/result-model';

@Component({
  selector: 'app-task-plan-delete',
  templateUrl: './task-plan-delete.component.html',
  styleUrls: ['./task-plan-delete.component.scss'],
})
export class TaskPlanDeleteComponent implements OnInit {
  //#region VARIABLES

  taskPlan: TaskPlan = new TaskPlan();
  taskPlanId: number;
  //#endregion

  constructor(
    private toastrService: ToastrService,
    private taskPlanSvc: TaskPlanService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.taskPlanId = +v['id'];
    });
    this.getTaskPlanById(this.taskPlanId);
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/task-plan']);
  }

  public getTaskPlanById(id: number): void {

    this.taskPlanSvc
      .getTaskPlanById(id)

      .subscribe((data: any) => {
        this.taskPlan.Code = data.Code;
        this.taskPlan.Description = data.Description;
        this.taskPlan.ID = data.ID;
      });
  }

  async deleteTaskPlan() {

    await this.taskPlanSvc
      .deleteTaskPlan(this.taskPlanId)

      .toPromise()
      .then(
        (data: ResultModel) => {
          if (data.Success) {
            this.onRedirectButtonClick();
          } else {
            this.toastrService.showError( data.Message);


          }
        },
        (error) => {
          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }
}
