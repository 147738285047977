import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GroupService } from '#services/api';

import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';

@Component({
  selector: 'app-groups',
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.scss'],
  providers: [GroupService]
})
export class GroupsComponent implements OnInit {
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  public groups: any;
  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private GroupServiceSvc: GroupService,

    private svcRouter: Router
  ) {
  }

  ngOnInit() {
    this.loadBusinessUnits();
  }

  private loadgroups(buId: number): void {

    this.GroupServiceSvc.getCurrentUserAdminGroups(buId)

      .subscribe((data: any) => {
        this.groups = data;
      });
  }

  private loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserAdminBUs()
      .subscribe((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          this.selectedBU = this.lstBusinessUnit[0];
          this.loadgroups(this.selectedBU.Id);
        }
      });
  }
  public loadOrganizations(): void {
    if (this.selectedBU) {
      this.loadgroups(this.selectedBU.Id);
    }
  }

  btnEdit(group): void {
    this.svcRouter.navigate(['/admin/group-edit', { groupId: group.Id }]);
  }

  btnCreate(): void {
    this.svcRouter.navigate(['/admin/group-create', { buId: this.selectedBU.Id }]);
  }
}
