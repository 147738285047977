import { BusinessUnitSwitcherDirective } from '../../components/directives/bu-switcher.directive';
import { NgModule } from '@angular/core';
import { LoggerService, AuthService, NotificationService } from './';
import { DownloadComponent } from '#pages/shared/download/download.component';

@NgModule({
  declarations: [BusinessUnitSwitcherDirective, DownloadComponent],
  imports: [],
  providers: [LoggerService, AuthService, NotificationService],
  exports: [BusinessUnitSwitcherDirective, DownloadComponent],
})
export class SharedModule {}
