<div class="box-shadow module" [appBUSwitcher]="selectedBU">
  <h2>
    <span *ngIf="equipmentId > 0; else altPrefix">Edit</span>
    <ng-template #altPrefix>Add</ng-template> Equipment
    <span *ngIf="isTemplate">Template</span>
  </h2>
  <h3>{{ equipmentTemplate.Name }}</h3>
  <hr />



  <!-- Edit Equipment Template form -->
  <form
    (ngSubmit)="onSubmitEquipment()"
    #equipmentForm="ngForm"
    name="equipmentForm"
  >
    <div class="row">
      <div class="col-xs-12">
        <div class="form-group">
          <label class="control-label col-md-1">Name</label>
          <input
            class="col-md-11 form-control text-box single-line"
            [(ngModel)]="equipmentTemplate.Name"
            name="equipName"
            #equipName="ngModel"
            placeholder="{{ equipmentTemplate.Name }}"
            maxlength="155"
            required
          />
          <div class="row">
            <span
              class="col-sm-8 text-danger field-validation-error"
              *ngIf="!equipmentForm.valid"
            >
              <span>{{ "This field is required" | translate }}.</span>
            </span>
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.Name?.length }} of 155</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label class="control-label col-md-4">EAM Name</label>
          <input
            class="form-control text-box single-line"
            [(ngModel)]="equipmentTemplate.EAMName"
            name="eamname"
            #eamname="ngModel"
            placeholder="{{ equipmentTemplate.EAMName }}"
            maxlength="155"
          />
          <div class="row">
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.EAMName?.length }} of 155</span
            >
          </div>
        </div>
      </div>

      <div class="col-xs-6">
        <div class="form-group">
          <label class="control-label col-md-4">EAM Description</label>
          <input
            class="form-control text-box single-line"
            [(ngModel)]="equipmentTemplate.Description"
            name="description"
            #description="ngModel"
            placeholder="{{ equipmentTemplate.Description }}"
            maxlength="255"
          />
          <div class="row">
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.Description?.length }} of 255</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-6">
        <div class="form-group">
          <label>Tag Code:</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="equipmentTemplate.TagCode"
            name="tagcode"
            #tagcode="ngModel"
            maxlength="50"
          />
          <div class="row">
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.TagCode?.length }} of 50</span
            >
          </div>
        </div>
      </div>
      <div class="col-xs-6">
        <div class="form-group">
          <label>Tag No:</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="equipmentTemplate.TagNo"
            name="tagno"
            #tagno="ngModel"
            maxlength="50"
          />
          <div class="row">
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.TagNo?.length }} of 50</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-4">
        <div class="form-group">
          <label>EAM Class:</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="equipmentTemplate.EAMClass"
            name="eamclass"
            #eamclass="ngModel"
            maxlength="50"
          />
          <div class="row">
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.EAMClass?.length }} of 50</span
            >
          </div>
        </div>
      </div>
      <div class="col-xs-4">
        <div class="form-group">
          <label>EAM Type:</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="equipmentTemplate.EAMType"
            name="eamtype"
            #eamtype="ngModel"
            maxlength="50"
          />
          <div class="row">
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.EAMType?.length }} of 50</span
            >
          </div>
        </div>
      </div>
      <div class="col-xs-4">
        <div class="form-group">
          <label>EAM Criticality:</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="equipmentTemplate.EAMCriticality"
            name="eamcrit"
            #eamcrit="ngModel"
            maxlength="50"
          />
          <div class="row">
            <span class="col-sm-4 charSpan"
              >{{ equipmentTemplate.EAMCriticality?.length }} of 50</span
            >
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-offset-10 col-xs-2 form-group">
        <button
          type="submit"
          class="btn btn-primary"
          [disabled]="!equipmentForm.form.valid"
        >
          Save
        </button>
      </div>
    </div>
  </form>

  <!-- End Device List -->
  <div *ngIf="!newMode">
    <h3>End Device List</h3>
    <app-end-device-list
      [parentID]="equipmentId"
      [buId]="businessUnitId"
    ></app-end-device-list>
  </div>

  <!-- A/B Checklist -->
  <div *ngIf="!newMode">
    <div class="form-horizontal component-separator">
      <h3>AB Checklist</h3>
    </div>
    <br />
  </div>
  <hr />

  <!-- Back Button -->
  <div>
    <button
      type="button"
      class="btn btn-default"
      (click)="router.navigate(['/admin/equipment/'])"
    >
      Back to List
    </button>
    &nbsp;
  </div>
</div>
