import { HttpClient } from '@angular/common/http';
import { Category } from '#models/category';
import { share } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';

@Injectable()
export class CategoryService {

    url: string;

    constructor(
        private http: HttpClient
    ) {
        this.url = `${environment.apiEndpoint}/category`;
    }

    public get(): Observable<Category[]> {
        return this.http.get<Category[]>(this.url).pipe(share());
    }

    public getByID(id: number): Observable<Category> {
        return this.http.get<Category>(`${this.url}/${id}`).pipe(share());
    }

    public post(category: Category) {
        return this.http.post<Category>(this.url, category).pipe(share());
    }

    public put(category: Category) {
        return this.http.put<Category>(this.url, category).pipe(share());
    }

    public delete(categoryID: number) {
        return this.http.delete(`${this.url}/${categoryID}`).pipe(share());
    }
}
