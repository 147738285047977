<div class="box-shadow module">

  <h2>{{ "Edit Project" | translate }}</h2>
  <hr />
  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{ "Name" | translate }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="proj.Name"
        />
        <span class="text-danger field-validation-error" *ngIf="displayWarning">
          {{ "This field is required" | translate }}
        </span>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        "Description" | translate
      }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="proj.Description"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <a class="btn btn-default" style="width: 100px" (click)="save()"
          >{{'Save'|translate}}</a
        >
      </div>
    </div>
  </div>

  <h4>{{'Equipment'|translate}}</h4>
  <app-equipment-list
    *ngIf="projid > 0"
    [isTemplate]="false"
    [parentID]="projid"
    [editMode]="false"
  ></app-equipment-list>

  <h4>Equipment Template</h4>
  <app-equipment-list
    [isTemplate]="true"
    [editMode]="false"
  ></app-equipment-list>

  <div>
    <a (click)="onRedirectButtonClick()" style="cursor: pointer"
      >{{'Back to List'|translate}}</a
    >
  </div>
</div>
