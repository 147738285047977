<div class="box-shadow module">
  <h2>{{ 'EditBusinessUnit' | translate }}</h2>
  <hr />
  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{ 'Name' | translate }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.Name"
        />
        <span class="text-danger field-validation-error" *ngIf="displayWarning">
          <span>{{ 'RequiredField' | translate }}</span>
        </span>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'HierarchyLabel1' | translate
      }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.HierarchyLabel1"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'HierarchyLabel2' | translate
      }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.HierarchyLabel2"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'HierarchyLabel3' | translate
      }}</label>
      <div class="col-md-7">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.HierarchyLabel3"
          [disabled]="!bu.ShowHierarchyLabel3"
        />
      </div>
      <label class="col-md-1 control-label"
        >&nbsp;{{ 'Show' | translate }}&nbsp;&nbsp;<input
          type="checkbox"
          [(ngModel)]="bu.ShowHierarchyLabel3"
          value="bu.ShowHierarchyLabel3"
      /></label>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'PSSRLabel' | translate
      }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.PSSRLabel"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'OrganizationLabel' | translate
      }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.OrganizationLabel"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{ 'P3Label' | translate }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.P3Label"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{ 'P4Label' | translate }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.P4Label"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{ 'P5Label' | translate }}</label>
      <div class="col-md-7">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.P5Label"
          [disabled]="!bu.ShowP5"
        />
      </div>
      <label class="control-label"
        >&nbsp;{{ 'ShowP5' | translate }}&nbsp;&nbsp;<input
          type="checkbox"
          [(ngModel)]="bu.ShowP5"
          value="bu.ShowP5"
      /></label>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'EventLabel' | translate
      }}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="bu.EventLabel"
        />
        ({{ 'ByDefaultProject' | translate }})
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AbandonedPSSRInterval' | translate
      }}</label>
      <div class="col-md-8">
        <p-dropdown
        [options]="months"
        [(ngModel)]="bu.PssrAbandonedInterval"
        ></p-dropdown>
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'ShowProject' | translate
      }}</label>
      <div class="col-md-1">
        <input
          type="checkbox"
          [(ngModel)]="bu.ShowProject"
          value="bu.ShowProject"
        />
      </div>
      <div class="col-md-4">
        <label class="col-xs-6 form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="false"
            [(ngModel)]="bu.ShowProjectInput"
            [disabled]="!bu.ShowProject"
          />
          &nbsp;{{ 'Dropdown' | translate }}
        </label>
        <label class="form-check-inline">
          <input
            class="form-check-input"
            type="radio"
            name="type"
            [value]="true"
            [(ngModel)]="bu.ShowProjectInput"
            [disabled]="!bu.ShowProject"
          />
          &nbsp;{{ 'Input' | translate }}
        </label>
      </div>
      <div class="col-md-4">
        {{ 'ShowProjectInputInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{ 'ShowQAQC' | translate }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.ShowQAQC"
          value="bu.ShowQAQC"
          [disabled]="!bu.ShowProject"
        />
        {{ 'ShowQAQCInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{ 'ShowKMS' | translate }}</label>
      <div class="col-md-8">
        <input type="checkbox" [(ngModel)]="bu.ShowKMS" value="bu.ShowKMS" />
        {{ 'ShowKMSInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowWetSignature' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowWetSignature"
          value="bu.AllowWetSignature"
        />
        {{ 'AllowWetSignatureInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'ShowVerbalApproval' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.ShowVerbalApproval"
          value="bu.ShowVerbalApproval"
        />
        {{ 'ShowVerbalApprovalInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'ScheduledEmail' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.ScheduledEmail"
          value="bu.ScheduledEmail"
        />
        {{ 'ScheduledEmailInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{ 'ShowCCC' | translate }}</label>
      <div class="col-md-8">
        <input type="checkbox" [(ngModel)]="bu.ShowCCC" value="bu.ShowCCC" />
        {{ 'ShowCCCInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{ 'ShowORR' | translate }}</label>
      <div class="col-md-8">
        <input type="checkbox" [(ngModel)]="bu.ShowORR" value="bu.ShowORR" />
        {{ 'ShowORRInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'ShowPssrType' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.ShowPssrType"
          value="bu.ShowPssrType"
        />
        {{ 'ShowPssrTypeInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'PSSRTypes' | translate
      }}</label>
      <div
        *ngFor="let p of pssrTypes; let i = index"
        class="col-md-1"
        style="font-weight: bold; padding-top: 5px"
      >
        {{ p.Name }}&nbsp;<input
          type="checkbox"
          [checked]="isPssrTypeChecked(p)"
          [value]="p.Id"
          (change)="pssrTypeChecked($event)"
        />
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowNAResponses' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowNAResponses"
          value="bu.AllowNAResponses"
        />
        {{ 'AllowNAResponsesInstruction' | translate }}
      </div>
    </div>

    <app-toggle-rich-input
      [displayRichEditor]="displayRichEditor"
      [label]="'Instructions' | translate"
      [(ngModel)]="bu.Instructions"
    >
      {{ 'PssrInstructionsInstruction' | translate }}
    </app-toggle-rich-input>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowLeadsDeleteQuestions' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowLeadsDeleteQuestions"
          [value]="bu.AllowLeadsDeleteQuestions"
        />
        {{ 'AllowLeadsDeleteQuestionsInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowMembersCompletePSSR' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowMembersToCompletePSSR"
          value="bu.AllowMembersToCompletePSSR"
        />
        {{ 'EnableAllowMembersCompletePSSRInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'MOCWOProjectNumberRestrictionEnabled' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.MOCWOProjectNumberRestrictionEnabled"
          value="bu.MOCWOProjectNumberRestrictionEnabled"
        />
        {{ 'MOCWOProjectNumberRestrictionEnabledInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowMultiApprover' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowMultiApprover"
          value="bu.AllowMultiApprover"
        />
        {{ 'AllowMultiApproverInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowLeadtoShareDeviceWithApprover' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowLeadtoShareDeviceWithApprover"
          value="bu.AllowLeadtoShareDeviceWithApprover"
        />
        {{ 'AllowLeadtoShareDeviceWithApproverInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'RequireFinalApproverBeforeInitiation' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.RequireFinalApproverBeforeInitiation"
          value="bu.RequireFinalApproverBeforeInitiation"
        />
        *{{ 'RequireFinalApproverBeforeInitiationInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'RequireActionItemDueDate' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.RequireActionItemDueDate"
          value="bu.RequireActionItemDueDate"
        />
        *{{ 'RequireActionItemDueDateInstruction' | translate }}
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{
        'ShowSectionsCollapsed' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.ShowSectionsCollapsed"
          value="bu.ShowSectionsCollapsed"
        />
        *{{ 'ShowSectionsCollapsedInstruction' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'SendAllNotificationsToApprovers' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.SendAllNotificationsToApprovers"
          value="bu.SendAllNotificationsToApprovers"
        />
        *{{ 'SendAllNotificationsToApproversInstruction' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowApproverToSubmitForApproval' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowApproverToSubmitForApproval"
          value="bu.AllowApproverToSubmitForApproval"
        />
        {{ 'AllowApproverToSubmitForApprovalInstruction' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'RequiresApprovalForAccess' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.RequiresApprovalForAccess"
          value="bu.RequiresApprovalForAccess"
        />
        {{ 'RequiresApprovalForAccessInstruction' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'LimitAccessToAIOwner' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.LimitAccessToAIOwner"
          value="bu.LimitAccessToAIOwner"
        />
        *{{ 'LimitAccessToAIOwnerInstruction' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowTeamLeadToAttach' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowTeamLeadToAttach"
          value="bu.AllowTeamLeadToAttach"
        />
        *{{ 'AllowTeamLeadToAttachInstruction' | translate }}
      </div>
    </div>
    <div class="form-group">
      <label class="control-label col-md-2">{{
        'AllowAIOwnerToAttach' | translate
      }}</label>
      <div class="col-md-8">
        <input
          type="checkbox"
          [(ngModel)]="bu.AllowAIOwnerToAttach"
          value="bu.AllowAIOwnerToAttach"
        />
        *{{ 'AllowAIOwnerToAttachInstruction' | translate }}
      </div>
    </div>
    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <button class="btn btn-primary" style="width: 100px" (click)="save()">
          {{ 'Save' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div>
    <a routerLink="/admin/business-units" style="cursor: pointer">{{
      'BackToList' | translate
    }}</a>
  </div>
</div>
