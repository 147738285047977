import { BusinessUnit } from './business-unit';
import { OrganizationUser } from './organization-user';
import { Utility } from '#services/shared/utility';
import { UserSimple } from './user-simple';
import { Language } from '#services/translate/languages';

export class User extends UserSimple {
  ID: number;
  FullName: string;
  BusinessUnits: BusinessUnit[] = [];

  constructor(orgUsr: OrganizationUser = null) {
    super();
    if (orgUsr != null) {
      this.ID = orgUsr.UserID;

      this.FirstName = orgUsr.FirstName;
      this.MiddleName = orgUsr.MiddleName;
      this.LastName = orgUsr.LastName;
      this.Email = orgUsr.Email;
      this.CAI = orgUsr.CAI;
      this.DisclaimerAccepted = orgUsr.DisclaimerAccepted;
      this.Passcode = orgUsr.Passcode;
      this.IsLoginOnly = orgUsr.IsLoginOnly;
      this.ChildUsers = orgUsr.ChildUsers;
      this.Type = orgUsr.Type;
      this.IsTcoWhitePagesImported = orgUsr.IsTcoWhitePagesImported;
      this.FullName = Utility.getFullName(orgUsr);
    }

    return this;
  }

  PreferredLanguage: Language = 'en';
}
