import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-task-plan-section-delete',
  templateUrl: './task-plan-section-delete.component.html',
})
export class TaskPlanSectionDeleteComponent implements OnInit {
  //#region VARIABLES

  taskPlanId: number;
  taskPlanDesc: string;
  taskPlanSectionId: number;
  taskPlanSectionDesc: string;
  taskPlanPage: string;

  taskPlanLabels = {
    taskPlanSectionDesc: '',
    taskPlanDesc: ''
  };
  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.taskPlanId = +v['taskPlanId'];
      this.taskPlanDesc = v['taskPlanDesc'];
      this.taskPlanSectionId = +v['id'];
      this.taskPlanSectionDesc = v['desc'];
      this.taskPlanPage = v['page'];

      this.taskPlanLabels = {
        taskPlanSectionDesc: this.taskPlanSectionDesc,
        taskPlanDesc: this.taskPlanDesc
      };
    });
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/task-plan-manage',
      {
        id: this.taskPlanId,
        sectionId: this.taskPlanSectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public deleteTaskPlanSection(): void {

    this.taskPlanSvc
      .deleteTaskPlanSection(this.taskPlanSectionId)

      .subscribe(() => {
        this.onRedirectButtonClick();
      });
  }
}
