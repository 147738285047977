<div class="box-shadow module">
  <div class="row">
    <h2>{{ selectedBU.Name }} {{ selectedBU.HierarchyLabel1 | translate }}</h2>
    <hr />
  </div>

  <div class="row padding-bottom-10">
    <div class="col-xs-4">
      <label>{{'Business Unit'|translate}} :</label>
      <select
        [appBUSwitcher]="selectedBU"
        class="form-control"
        [(ngModel)]="selectedBU"
        (change)="loadAreas()"
      >
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
    <div class="col-sm-8" style="text-align: end; padding-top: 40px">
      <a
        (click)="
          onRedirectButtonClick('admin/area-create', selectedBU.Id, null)
        "
        style="cursor: pointer"
        >{{'Create New'|translate}} {{ selectedBU.HierarchyLabel1 | translate }}</a
      >
    </div>
  </div>

  <table class="table table-hover">
    <tr *ngFor="let area of lstAreas">
      <td class="col-xs-8">{{ area.Name }}</td>
      <td class="col-xs-1 text-center">
        <div
          *ngIf="area.IsActive"
          class="label-info padding-left-5 padding-right-5"
          style="border-radius: 1em; color: white"
        >
          {{'Active'|translate}}
        </div>
        <div
          *ngIf="!area.IsActive"
          class="label-danger padding-left-5 padding-right-5"
          style="border-radius: 1em; color: white"
        >
          {{'Inactive'|translate}}
        </div>
      </td>
      <td class="col-xs-3 text-center">
        <a
          *ngIf="area.IsActive"
          (click)="
            onRedirectButtonClick(
              'admin/area-edit',
              area.BusinessUnitID,
              area.Id
            )
          "
          style="cursor: pointer"
          >{{'Edit'}}</a
        >
        <span *ngIf="area.IsActive">&nbsp;|&nbsp;</span>
        <a
          (click)="
            onRedirectButtonClick(
              'admin/sub-area',
              area.BusinessUnitID,
              area.Id
            )
          "
          style="cursor: pointer"
          >{{ selectedBU.HierarchyLabel2 | translate }}
        </a>
        <span *ngIf="area.IsActive">&nbsp;|&nbsp;</span>
        <a
          *ngIf="area.IsActive"
          (click)="
            onRedirectButtonClick(
              'admin/area-delete',
              area.BusinessUnitID,
              area.Id
            )
          "
          style="cursor: pointer"
          >{{'Delete'|translate}}</a
        >
      </td>
    </tr>
  </table>
</div>
