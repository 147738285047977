import { Component, OnInit } from '@angular/core';
import { ProjectService } from '#services/api';
import { Project } from '#models/project';

import { Router } from '@angular/router';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  providers: [ProjectService],
})
export class ProjectCreateComponent implements OnInit {
  proj: Project = new Project();
  displayWarning: boolean;

  constructor(

    private toastrService: ToastrService,
    private projService: ProjectService,
    private router: Router
  ) {}

  ngOnInit() {
    this.displayWarning = false;
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/project']);
  }

  public create(): void {
    this.displayWarning =
      this.proj.Name == undefined ||
      this.proj.Name == null ||
      this.proj.Name.length == 0;
    if (!this.displayWarning) {

      this.projService
        .addProject(this.proj)

        .subscribe(
          () => {
            this.onRedirectButtonClick();
          },
          (error) => {
            this.toastrService.showError( error.error.ExceptionMessage);

          }
        );
    }
  }
}
