import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { B2C, B2CResponse } from '#models/b2c';

@Injectable()
export class B2CService {
  constructor(private http: HttpClient) {}

  public login(b2c: B2C): Observable<B2CResponse> {
    return this.http.post<B2CResponse>(
      `${environment.apiEndpoint}/b2clogin`,
      b2c
    );
  }
}
