import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ReadCategorySubCategoriesDTO,
  ReadSubCategoryDTO,
} from '#models/subcategory';
import { SubCategoryService } from '#services/api';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs-compat/Subscription';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class SubcategoryComponent implements OnInit, OnDestroy {
  subCategories: ReadSubCategoryDTO[] = [];

  sub = new Subscription();

  constructor(private subcategoryService: SubCategoryService) {}

  ngOnInit() {
    this.sub.add(
      this.subcategoryService
        .get()
        .pipe(take(1))
        .subscribe((subCategoriesList: ReadSubCategoryDTO[]) => {
          this.subCategories = subCategoriesList;
        })
    );
  }

  map(categories: ReadCategorySubCategoriesDTO[] = []): string {
    const s = categories
      .map(
        (c) =>
          `${c.IsActive ? c.Name : '<i>' + c.Name + ' (Inactive) </i>'} (${
            c.BusinessUnitName
          })`
      )
      .join('<br>');
    return s;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
