import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { Organization } from '#models/organization';
import { OrganizationUser } from '#models/organization-user';
import { IdName } from '#models/id-name';

@Injectable()
export class OrganizationService {
  constructor(private http: HttpClient) { }

  public getUserAdminOrgs(businessUnitId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiEndpoint}/organization/currentUserAdminOrgs/${businessUnitId}`
    );
  }

  public getOrgList(businessUnitId: number): Observable<IdName[]> {
    return this.http.get<IdName[]>(
      `${environment.apiEndpoint}/organization/by-bu/${businessUnitId}`
    );
  }

  public getListByBU(businessUnitId: number): Observable<Organization[]> {
    return this.http.get<Organization[]>(
      `${environment.apiEndpoint}/organization/by-current-user/${businessUnitId}`
    );
  }

  public getOrganization(orgId: number): Observable<Organization> {
    return this.http.get<Organization>(
      `${environment.apiEndpoint}/organization/${orgId}`
    );
  }

  public addOrganization(
    businessUnitId: number,
    organizationName: string
  ): Observable<Organization> {
    const bodyData = {
      Name: organizationName,
      BusinessUnitId: businessUnitId,
    };
    return this.http.post<Organization>(
      `${environment.apiEndpoint}/organization`,
      bodyData
    );
  }

  public updateOrganization(
    organization: Organization
  ): Observable<Organization> {
    return this.http.put<Organization>(
      `${environment.apiEndpoint}/organization`,
      organization
    );
  }

  public deleteOrganization(id: number): Observable<void> {
    return this.http.put<void>(
      `${environment.apiEndpoint}/organization/delete/${id}`,
      null
    );
  }

  public addOrganizationUser(
    orgUsr: OrganizationUser
  ): Observable<OrganizationUser> {
    return this.http.post<OrganizationUser>(
      `${environment.apiEndpoint}/organization/addOrganizationUser`,
      orgUsr
    );
  }

  public removeOrganizationUser(id: number): Observable<OrganizationUser> {
    return this.http.put<OrganizationUser>(
      `${environment.apiEndpoint}/organization/removeOrganizationUser/${id}`,
      null
    );
  }

  public updateOrganizationUser(
    orgUsr: OrganizationUser
  ): Observable<OrganizationUser> {

    return this.http.put<OrganizationUser>(
      `${environment.apiEndpoint}/organization/updateOrganizationUser`,
      orgUsr
    );
  }

  public getOrganizationUser(
    orgId: number,
    userId: number
  ): Observable<OrganizationUser> {
    return this.http.get<OrganizationUser>(
      `${environment.apiEndpoint}/organization/getOrganizationUser/${orgId}/${userId}`
    );
  }

  public getOrgUsersByOrgId(orgId: number): Observable<OrganizationUser[]> {
    return this.http.get<OrganizationUser[]>(
      `${environment.apiEndpoint}/organization/getOrgUsersByOrgId/${orgId}`
    );
  }

  public searchOrgUsers(orgId: number, search: string): Observable<OrganizationUser[]> {
    return this.http.get<OrganizationUser[]>(
      `${environment.apiEndpoint}/organization/users/${orgId}`,
      {
        params: {
          search,
        },
      }
    );
  }
}
