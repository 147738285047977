import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { Area } from '#models/area';

@Injectable()
export class AreaService {

    constructor(
        private http: HttpClient
    ) { }

    public getAllByBusinessUnit(businessUnitId: Number): Observable<Area[]> {
        return this.http.get<Area[]>(`${environment.apiEndpoint}/area/getByBusinessUnit/${businessUnitId}`);
    }

    public getAreaById(id: number): Observable<Area> {
        return this.http.get<Area>(`${environment.apiEndpoint}/area/${id}`);
    }

    public createArea(area: Area): Observable<Area> {
        return this.http.post<Area>(`${environment.apiEndpoint}/area`, area);
    }

    public updateArea(area: Area): Observable<Area> {
        return this.http.put<Area>(`${environment.apiEndpoint}/area`, area);
    }

    public deleteArea(id: number): Observable<Area> {
        return this.http.put<Area>(`${environment.apiEndpoint}/area/delete/${id}`, null);
    }

}

