import { Component, OnInit } from '@angular/core';
import { AreaService } from '#services/api/area.service';
import { Area } from '#models/area';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';

import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-area-create',
  templateUrl: './area-create.component.html',
})
export class AreaCreateComponent implements OnInit {
  area: Area = new Area();
  displayWarningArea: boolean;
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];

  buId: number;

  constructor(
    private toastrService: ToastrService,
    private businessUnitsSvc: BusinessUnitsService,

    private areaService: AreaService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.selectedBU = new BusinessUnit();
    this.route.params.subscribe((v) => {
      this.area.Id = +v['areaId'];
      this.area.BusinessUnitID = +v['buId'];
    });
    this.displayWarningArea = false;
    this.loadBusinessUnits();
  }

  onRedirectButtonClick(buId: number): void {
    this.router.navigate(['admin/area', { buId: buId }]);
  }

  public createArea(): void {
    const invalidName =
      this.area.Name == undefined ||
      this.area.Name == null ||
      this.area.Name.length == 0;
    this.displayWarningArea = invalidName;
    if (!invalidName) {
      this.areaService
        .createArea(this.area)

        .subscribe(
          (data: Area) => {
            this.onRedirectButtonClick(data.BusinessUnitID);
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);

          }
        );
    }
  }
  loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserBUs().subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        if (
          this.area.BusinessUnitID == null ||
          this.area.BusinessUnitID == undefined
        ) {
          this.selectedBU = this.lstBusinessUnit[0];
        } else {
          this.selectedBU = this.lstBusinessUnit.find(
            (x) => x.Id === this.area.BusinessUnitID
          );
        }
      }
    });
  }
}
