<div class="row form-group text-center" *ngIf="endDevPosChange">
  <p-progressSpinner animationDuration=".5s"></p-progressSpinner>
</div>
<div *ngIf="!endDevPosChange">
  <table class="table table-hover table-responsive">
    <tr>
      <th scope="col" class="col-xs-1">Tag</th>
      <th scope="col" class="col-xs-1">Code</th>
      <th scope="col" class="col-xs-2">Type</th>
      <th scope="col" class="col-xs-3">Description</th>
      <th scope="col"class="col-xs-1">EAM Type</th>
      <th scope="col" class="col-xs-1">EAM Criticality</th>
      <th scope="col" class="col-xs-1" style="text-align: center;">Sort Order</th>
      <th scope="col" class="col-xs-2" style="text-align:end; padding: 1px">
        <button type="button" class="btn btn-success"
          (click)="onRedirectButtonClick('admin/end-device-form', 0, parentID)" style="width:80px;">New</button>
      </th>
    </tr>
    <ng-container *ngFor="let d of lstEndDevice">
      <tr *ngIf="d.IsEnable">
        <td class="col-xs-1">{{d.TagCode}}</td>
        <td class="col-xs-1">{{d.TagNo}}</td>
        <td class="col-xs-2">
          <app-end-device-type-name [id]="d.EndDeviceTypeId"></app-end-device-type-name>
        </td>
        <td class="col-xs-3">{{d.Description}}</td>
        <td class="col-xs-1">{{d.EAMType}}</td>
        <td class="col-xs-1">{{d.EAMCriticality}}</td>
        <td class="col-xs-1" style="text-align: center;">
          <img src="assets/images/blank.png" alt="Inactive" class="imageIcon-sm ShowOnHover"
            *ngIf="d.Id == firstEndDevId" />
          <a (click)="moveDeviceUp(d.Id)" *ngIf="d.Id != firstEndDevId">
            <img src="assets/images/upTriangle.png" class="imageIcon-sm ShowOnHover" />
          </a>
          &nbsp;
          <img src="assets/images/blank.png" alt="Inactive" class="imageIcon-sm ShowOnHover"
            *ngIf="d.Id == lastEndDevId" />
          <a (click)="moveDeviceDown(d.Id)" *ngIf="d.Id != lastEndDevId">
            <img src="assets/images/dnTriangle.png" class="imageIcon-sm ShowOnHover" />
          </a>
        </td>
        <td class="col-xs-2" style="text-align:end">
          <a (click)="onRedirectButtonClick('admin/end-device-form', d.Id, 0)"
            style="cursor: pointer;">Edit</a>
          <span>&nbsp;|&nbsp;</span>
          <a (click)="deviceToDelete(d)" data-toggle="modal" data-target="#modalDelete"
            style="cursor: pointer; color: red;">Delete</a>
        </td>
      </tr>
    </ng-container>
  </table>
</div>

<!--Modal to Ask Delete Device-->
<div class="modal fade" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="modalSaveChanges"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="exampleModalCenterTitle">Delete End Device</h4>
      </div>
      <div class="modal-body">
        <h4>Do you agree to Delete selected Device&#63;</h4>
        <h3>{{selectedDevice?.Description}}</h3>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-danger" (click)="deleteSelectedDevice()"
          data-dismiss="modal">Delete</button>
      </div>
    </div>
  </div>
</div>
