import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { LoggedUser, User } from 'models';
import { Language } from '#services/translate/languages';

@Injectable()
export class UserService {
  constructor(private http: HttpClient) {}

  public getCurrentUser(): Observable<LoggedUser> {
    return this.http.get<LoggedUser>(`${environment.apiEndpoint}/user/current`);
  }

  public searchParticipants(
    query: string,
    organizationId: number
  ): Observable<User[]> {
    return this.http.post<User[]>(
      `${environment.apiEndpoint}/user/searchParticipants`,
      {
        query,
        limitResults: 20,
        organizationId,
      }
    );
  }

  searchUser(query: string): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/user/search/${query}/`
    );
  }

  public autocompleteUsersByBU(
    buId: number,
    query: string
  ): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/user/autocompleteUsersByBU/${buId}/${query}/`
    );
  }

  public getUsersByOrg(orgId: number): Observable<User[]> {
    return this.http.get<User[]>(
      `${environment.apiEndpoint}/user/getByOrganization/${orgId}`
    );
  }

  public acceptDisclaimer(userId: number): Observable<User> {
    return this.http.put<User>(
      `${environment.apiEndpoint}/user/acceptDisclaimer/${userId}`,
      null
    );
  }

  public sendAccessRequest(email: string, buId: number): Observable<User> {
    return this.http.get<User>(
      `${environment.apiEndpoint}/user/requestAccess/${email}/${buId}`
    );
  }

  public delete(id: number): Observable<void> {
    return this.http.put<void>(
      `${environment.apiEndpoint}/user/delete/${id}`,
      null
    );
  }
  public importSharedUser(
    businessUnitId: number,
    sharedUserEmail: string
  ): Observable<LoggedUser> {
    return this.http.post<LoggedUser>(
      `${environment.apiEndpoint}/user/getSharedUser`,
      {
        SharedUserEmail: sharedUserEmail,
        BusinessUnitID: businessUnitId,
      }
    );
  }

  savePreferences({ language }: { language: Language }) {
    return this.http.put(
      `${environment.apiEndpoint}/user/preferences`,
      {
        language,
      }
    );
  }
}
