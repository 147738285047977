import { Component, OnInit } from '@angular/core';
import { FeedbackService, BusinessUnitsService } from '#services/api';
import {
  Feedback,
  FeedbackPriority,
  FeedbackEnvironment,
  FeedbackType,
  FeedbackStatus,
} from '#models/feedback';
import { AuthService } from '#services/shared';
import { LoggedUser } from '#models/logged-user';
import { Utility } from '#services/shared/utility';
import { BusinessUnit } from 'models';
import { Router } from '@angular/router';

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'],
})
export class FeedbackComponent implements OnInit {
  feedbackItems: Feedback[];
  businessUnits: Array<BusinessUnit>;
  selectedBusinessUnit: BusinessUnit = new BusinessUnit();
  currentUser: LoggedUser;

  messages: Array<any> = [];
  localBUId: number;
  userBUAdmin: Array<any> = [];
  get isSystemAdmin(): boolean {
    return this.currentUser.IsSystemAdmin;
  }
  get isEmpty(): boolean {
    return !this.items || this.items.length === 0;
  }
  get items(): Feedback[] {
    return this.feedbackItems;
  }

  constructor(
    private authService: AuthService,
    private feedbackSvc: FeedbackService,
    private businesUnitSvc: BusinessUnitsService,

    private router: Router
  ) {}

  async ngOnInit() {

    this.currentUser = this.authService.getCurrentUser();
    this.getFilter();
    await this.loadBusinessUnits();
    await this.getUserBUAdmin();
    await this.loadADOFeedbackItems(this.selectedBusinessUnit.Name);
    this.setFilter();
    window.scrollTo(0, 0);

  }

  getFilter() {
    const localFilter = localStorage.getItem('localBUFilter');
    if (Utility.isValidString(localFilter)) {
      this.localBUId = +localFilter;
    }
  }

  setFilter() {
    localStorage.removeItem('localBUFilter');
    localStorage.setItem('localBUFilter', this.localBUId.toString());
  }

  async loadItems() {
    this.localBUId = this.selectedBusinessUnit.Id;
    this.setFilter();
    await this.loadADOFeedbackItems(this.selectedBusinessUnit.Name);
  }

  /**
   * Gets the list of business units
   */
  async loadBusinessUnits() {
    await this.businesUnitSvc
      .getUserBUs()
      .toPromise()
      .then(
        (data: BusinessUnit[]) => {
          this.businessUnits = data;
          const allBU = new BusinessUnit();
          allBU.Id = -1;
          allBU.Name = 'All';
          this.businessUnits.unshift(allBU);
          if (Utility.isValidObj(this.localBUId)) {
            this.selectedBusinessUnit = this.businessUnits.find(
              (x) => x.Id === this.localBUId
            );
          } else {
            this.selectedBusinessUnit = this.businessUnits[0];
            this.localBUId = this.selectedBusinessUnit.Id;
          }
        },
        (error) => {
          this.messages = [];
          this.messages.push({
            severity: 'error',
            summary: 'Unable to get Business Units',
            detail: error.error.ExceptionMessage, closable: true,
            sticky: true
          });
        }
      );
  }
  /** Gets BU where user is admin */
  async getUserBUAdmin() {
    await this.businesUnitSvc
      .getUserAdminBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.userBUAdmin = data;
      });
  }

  isAdminOfThisBU(buID = null, buName = null) {
    if (
      buID != null &&
      buID > 0 &&
      this.userBUAdmin &&
      this.userBUAdmin.length > 0
    ) {
      const tmp = this.userBUAdmin.find((x) => x.Id === buID);
      if (tmp) {
        return true;
      }
    } else {
      /* When All is selected */
      if (buID < 0 && buName != null) {
        const buNameTrim = buName
          .replace('PSSR; ', ' ')
          .replace('; PSSR', '')
          .trim();
        const tmp = this.userBUAdmin.find((x) => x.Name === buNameTrim);

        if (tmp) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }
    return false;
  }
  /**
   * Gets the list of feecdback records from ADO
   */
  async loadADOFeedbackItems(tag: string) {
    await this.feedbackSvc
      .getAdoList(tag)
      .toPromise()
      .then(
        (data: Feedback[]) => {
          this.feedbackItems = data;
          this.feedbackItems.forEach((x) => {
            x.priorityStr = FeedbackPriority[x.Priority];
            x.typeStr = FeedbackType[x.Type];
            x.envStr =
              x.Environment == FeedbackEnvironment.MobileAndWeb
                ? 'Mobile & Web'
                : FeedbackEnvironment[x.Environment];
            x.VerifiedDate = Utility.parseOffsetDate(x.VerifiedDate);
            x.CreateDate = Utility.parseOffsetDate(x.CreateDate);
            x.statusStr = FeedbackStatus[x.Status];
          });
        },
        (error) => {
          this.messages = [];
          this.messages.push({
            severity: 'error',
            summary: 'Unable to load data',
            detail: error.error.ExceptionMessage, closable: true,
            sticky: true
          });
        }
      );
  }

  navigateTo(buId) {
    this.router.navigate([
      '/admin/feedback-create',
      {
        buId,
      },
    ]);
  }

  navigateToEdit(feedback: Feedback) {
    this.router.navigate([
      '/admin/feedback-edit',
      {
        ado: feedback.ADO,
      },
    ]);
  }
}
