<div class="box-shadow module" [appBUSwitcher]="selectedBU">
  <div class="row">
    <h2>{{ subArea.Name }} {{ selectedBU?.HierarchyLabel3 | translate }}</h2>
    <hr />
  </div>

  <div class="row padding-bottom-10">
    <div class="col-xs-4">
      <label>{{ selectedBU?.HierarchyLabel2 }} :</label>
      <select
        class="form-control"
        [(ngModel)]="subArea"
        (change)="loadFacilities()"
      >
        <option *ngFor="let s of lstSubAreas" [ngValue]="s">
          {{ s.Name }}
        </option>
      </select>
    </div>
    <div class="col-sm-8" style="text-align: end; padding-top: 40px">
      <a
        *ngIf="subArea.IsActive"
        (click)="onRedirectButtonClick('admin/facility-create', null)"
        style="cursor: pointer"
        >{{'Create New' | translate}} {{ selectedBU?.HierarchyLabel3 | translate }}</a
      >
    </div>
  </div>

  <table class="table table-hover">
    <tr *ngFor="let f of lstFacilities">
      <td class="col-xs-8">{{ f.Name }}</td>
      <td class="col-xs-2 text-center">
        <div
          *ngIf="f.IsActive"
          class="label-info padding-left-5 padding-right-5"
          style="border-radius: 1em; color: white"
        >
          {{'Active'|translate}}
        </div>
        <div
          *ngIf="!f.IsActive"
          class="label-danger padding-left-5 padding-right-5"
          style="border-radius: 1em; color: white"
        >
          {{'Inactive'|translate}}
        </div>
      </td>
      <td class="col-xs-2 text-center">
        <a
          *ngIf="f.IsActive"
          (click)="onRedirectButtonClick('admin/facility-edit', f.Id)"
          style="cursor: pointer"
          >{{'Edit'|translate}}</a
        >
        <span *ngIf="f.IsActive">&nbsp;|&nbsp;</span>
        <a
          *ngIf="f.IsActive"
          (click)="onRedirectButtonClick('admin/facility-delete', f.Id)"
          style="cursor: pointer"
          >{{'Delete'|translate}}</a
        >
      </td>
    </tr>
  </table>
</div>

<div>
  <a
    (click)="onRedirectButtonClick('admin/sub-area', null)"
    style="cursor: pointer"
    >{{'Back to'|translate}} {{ selectedBU?.HierarchyLabel2 }} List</a
  >
</div>
