<h2>
{{'TaskPlanManageTitle'|translate:taskPlan}}
</h2>
<div class="form-horizontal" [appBUSwitcher]="selectedBU">
  <hr />
  <div class="row is-flex">
    <!-- *START* Left Panel -->
    <div class="col-sm-4 box-shadow module">
      <h4 style="color: dimgray">{{'Task Plan Sections'|translate}}</h4>
      <table class="table">
        <tbody>
          <tr *ngFor="let tps of taskPlan.TaskPlanSections" [ngClass]="{ isSelected: tps.ID == selectedSectionId }">
            <td>
              <a (click)="selectTaskPlanSections(tps.ID)" *ngIf="tps.ID != selectedSectionId">
                <span *ngIf="tps.SectionType == 1">&#10004;&nbsp;&nbsp;{{ tps.Description }}</span>
                <em *ngIf="tps.SectionType == 0">{{ tps.Description }}</em>
                <span *ngIf="tps.SectionType == 2">{{ tps.Description }}</span>
                <u *ngIf="tps.SectionType == 3">{{ tps.Description }}</u>
              </a>
              <div style="padding: 6px 12px; color: white; display: inline-block" *ngIf="tps.ID == selectedSectionId">
                <span *ngIf="tps.SectionType == 1">&#10004;&nbsp;&nbsp;{{ tps.Description }}</span>
                <em *ngIf="tps.SectionType == 0">{{ tps.Description }}</em>
                <span *ngIf="tps.SectionType == 2">{{ tps.Description }}</span>
                <u *ngIf="tps.SectionType == 3">{{ tps.Description }}</u>
              </div>
            </td>
            <td>
              <img src="assets/images/blank.png" alt="" class="imageIcon-sm ShowOnHover" *ngIf="tps.ID == firstSectionId" />
              <a (click)="moveUpTaskPlanSection(tps.ID)" *ngIf="tps.ID != firstSectionId">
                <img src="assets/images/upTriangle.png" alt="Move up" class="imageIcon-sm ShowOnHover" />
              </a>
            </td>
            <td>
              <img src="assets/images/blank.png" alt="" class="imageIcon-sm ShowOnHover" *ngIf="tps.ID == lastSectionId" />
              <a (click)="moveDownTaskPlanSection(tps.ID)" *ngIf="tps.ID != lastSectionId">
                <img src="assets/images/dnTriangle.png" alt="Move down" class="imageIcon-sm ShowOnHover" />
              </a>
            </td>
            <td>
              <img src="assets/images/blank.png" alt="" class="imageIcon-sm ShowOnHover" *ngIf="tps.General" />
              <a (click)="updateTaskPlanSection(tps.ID, tps.Description)" *ngIf="!tps.General">
                <img src="assets/images/edit.png" alt="Edit" class="imageIcon-sm ShowOnHover" />
              </a>
            </td>
            <td>
              <img src="assets/images/blank.png" alt="" class="imageIcon-sm ShowOnHover" *ngIf="tps.General" />
              <a (click)="deleteTaskPlanSection(tps.ID, tps.Description)" *ngIf="!tps.General">
                <img src="assets/images/cross_color.png" alt="Delete" class="imageIcon-sm ShowOnHover" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <hr style="border-color: lightgray" />
      <!-- Add New Sections Sub-panel-->
      <div class="form-horizontal">
        <h4>{{'Create New Section'|translate}}</h4>
        <div class="form-group">
          <div class="col-md-10 col-md-offset-1">
            <input
              class="form-control text-box single-line"
              [(ngModel)]="taskPlanSection.Description"
            />
            <span
              class="text-danger field-validation-error"
              *ngIf="displayWarningSec"
            >
              {{ "This field is required" | translate }}
            </span>
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-10 col-md-offset-1">
            <label for="section-type">{{'Section Type'|translate}}</label>
            <select
              class="form-control"
              id="section-type"
              [(ngModel)]="taskPlanSection.SectionType"
            >
              <option value="1" selected>{{'Mandatory'|translate}}</option>
              <option value="2">{{'Default'|translate}}</option>
              <option value="0">{{'Optional'|translate}}</option>
              <option value="3">{{'Multiple'|translate}}</option>
            </select>
            <!-- &#10003;  &#x2714; -->
          </div>
        </div>
        <div class="form-group">
          <div class="col-md-10 col-md-offset-1">
            <a
              class="btn btn-default"
              style="width: 100px"
              (click)="createTaskPlanSection()"
              >{{'Create'|translate}}</a
            >
          </div>
        </div>
      </div>
    </div>
    <!-- *END* Left Panel -->
    <!-- *START* Right Panel -->
    <div class="col-sm-8" *ngIf="hasSections">
      <p-confirmDialog #cd>
        <p-footer>
          <button
            type="button"
            pButton
            icon="fa-check pi pi-check"
            [label]="'Yes' | translate"
            (click)="cd.accept()"
            style="width: 80px"
          ></button>
          <button
            type="button"
            pButton
            icon="fa-close pi pi-times"
            [label]="'No' | translate"
            (click)="cd.reject()"
            style="width: 80px"
          ></button>
        </p-footer>
      </p-confirmDialog>

      <div class="box-shadow module">
        <div class="accordionContainer-lg">
          <!-- sub-panel *START* (NewSubSection) -->
          <div>
            <h3 class="sectionHeader">
              <div class="_accordion-title">
                <span class="text-tab">{{'New Subsection'|translate}}</span>
              </div>
            </h3>
            <hr style="border-color: lightgray" />
            <div class="form-horizontal">
              <div class="form-group">
                <label class="control-label col-md-2"
                  >{{'Subsection Description'|translate}}</label
                >
                <div class="col-md-10">
                  <input
                    class="form-control text-box single-line"
                    [(ngModel)]="subSection.Description"
                  />
                  <span
                    class="text-danger field-validation-error"
                    *ngIf="displayWarningSubSec"
                  >
                    {{ "This field is required" | translate }}
                  </span>
                </div>
              </div>
              <div class="form-group">
                <div class="col-md-offset-2 col-md-10">
                  <a
                    class="btn btn-default"
                    style="width: 100px"
                    (click)="createSubSection()"
                    >{{'Create'|translate}}</a
                  >
                </div>
              </div>
            </div>
            <hr style="border-color: lightgray" />
          </div>
          <!-- sub-panel *END* (NewSubSection) -->
          <!-- sub-panel *START*  (SubSectionList) -->
          <div *ngFor="let ss of subSectionList">
            <h5 class="subSectionHeader">
              <div class="_accordion-title">
                <span class="text-tab">{{ ss.Description }}</span>
              </div>
              <div class="_accordion-icons">
                <img
                  src="assets/images/blank.png"
                  class="imageIcon-sm imageIcon-tab-left"
                  *ngIf="ss.ID == firstSubSectionId"
                />
                <a
                  (click)="moveUpTaskPlanSubSection(ss.ID)"
                  *ngIf="ss.ID != firstSubSectionId"
                >
                  <div class="imageIcon-tab-left">
                    <img
                      src="assets/images/upTriangle_white.png"
                      class="imageIcon-sm ShowOnHover"
                    />
                  </div>
                </a>
                <img
                  src="assets/images/blank.png"
                  class="imageIcon-sm imageIcon-tab-left"
                  *ngIf="ss.ID == lastSubSectionId"
                />
                <a
                  (click)="moveDownTaskPlanSubSection(ss.ID)"
                  *ngIf="ss.ID != lastSubSectionId"
                >
                  <div class="imageIcon-tab-left">
                    <img
                      src="assets/images/dnTriangle_white.png"
                      class="imageIcon-sm ShowOnHover"
                    />
                  </div>
                </a>
                <a (click)="updateTaskPlanSubSection(ss.ID)">
                  <div class="imageIcon-tab-left">
                    <img
                      src="assets/images/edit.png"
                      class="imageIcon-sm ShowOnHover"
                    />
                  </div>
                </a>
                <a (click)="deleteTaskPlanSubSection(ss.ID)">
                  <div class="imageIcon-tab-left">
                    <img
                      src="assets/images/cross_color.png"
                      class="imageIcon-sm ShowOnHover"
                    />
                  </div>
                </a>
              </div>
            </h5>
            <!-- *END* subSectionHeader -->
            <!-- *START* subSectionBody -->
            <div class="ui-accordion-content ui-helper-reset ui-corner-bottom">
              <!-- items list -->
              <table
                class="table table-hover"
                style="width: 100%; margin-bottom: 0px"
              >
              <tr>
                <th style="width: 20%"> Code </th> 
                <th class="row" style="width: 70%">
                  <div class="col-md-10"style="padding: 0px">
                  Description
                  </div>
                  <div class="col-md-2" style="padding: 0px; text-align: center;">
                    Type
                  </div>               
                </th>
                <th></th>
                <th></th>
                <th></th>
              </tr>
                <tr *ngFor="let item of ss.TaskPlanItems">
                  <td style="width: 20%">
                    <a (click)="updateItem(item.ID)">
                      {{ item.Code }}
                    </a>
                  </td>
                  <td class="row" style="width: 70%">
                    <div
                      class="col-md-10"
                      [innerHTML]="item.Description"
                      style="padding: 0px"
                    ></div>
                    <div
                      *ngIf="item.ItemType == 1"
                      class="col-md-2"
                      style="
                        padding: 0px;
                        text-align: center;
                        background-color: lightblue;
                      "
                    >
                      {{'Summary Signature'|translate}}
                    </div>
                    <div
                      *ngIf="item.ItemType == 2"
                      class="col-md-2"
                      style="
                        padding: 0px;
                        text-align: center;
                        background-color: lightblue;
                      "
                    >
                      {{'Walkdown Signature'|translate}}
                    </div>
                    <div
                      *ngIf="item.ItemType == 0"
                      class="col-md-2"
                      style="
                        padding: 0px;
                        text-align: center;
                        background-color: lightgray;
                      "
                    >
                      {{'Default'|translate}}
                    </div>
                  </td>
                  <td>
                    <img
                      src="assets/images/blank.png"
                      alt="Inactive"
                      class="imageIcon-sm ShowOnHover"
                      *ngIf="item.ID == ss.firstItemId"
                    />
                    <a
                      (click)="moveUpTaskPlanItem(item.ID, ss.ID)"
                      *ngIf="item.ID != ss.firstItemId"
                    >
                      <img
                        src="assets/images/upTriangle.png"
                        class="imageIcon-sm ShowOnHover"
                      />
                    </a>
                  </td>
                  <td>
                    <img
                      src="assets/images/blank.png"
                      alt="Inactive"
                      class="imageIcon-sm ShowOnHover"
                      *ngIf="item.ID == ss.lastItemId"
                    />
                    <a
                      (click)="moveDownTaskPlanItem(item.ID, ss.ID)"
                      *ngIf="item.ID != ss.lastItemId"
                    >
                      <img
                        src="assets/images/dnTriangle.png"
                        class="imageIcon-sm ShowOnHover"
                      />
                    </a>
                  </td>
                  <td>
                    <a (click)="deleteItem(item.ID, ss.ID)">
                      <img
                        src="assets/images/cross_color.png"
                        class="imageIcon-sm ShowOnHover"
                      />
                    </a>
                  </td>
                </tr>
              </table>
              <!-- end items list -->
              <div class="form-group">
                <div class="col-md-10">
                  <a class="btn btn-primary" (click)="createItem(ss.ID)"
                    >{{'Create New Item'|translate}}</a
                  >
                </div>
              </div>
            </div>
            <!-- *END* subSectionBody -->
          </div>
          <!-- *END* SubSectionList -->
        </div>
      </div>
    </div>
    <!-- *END* Right Panel -->
  </div>
  <hr />
</div>
<div class="row">
  <div class="col-sm-6">
    <a (click)="onRedirectButtonClick()" style="cursor: pointer"
      >{{'Back to Task Plan'|translate}}</a
    >
  </div>
  <div class="col-sm-6"></div>
</div>
