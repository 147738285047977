<div class="row">
  <app-pssr-edit-header (templateChanged)="loadChangebleCollections()" (savedPssr)="onreloadItem()" *ngIf="pssr && pssr.buObj" [pssr]="pssr">
  </app-pssr-edit-header>
</div>

<ng-container *ngIf="pssr" [ngTemplateOutlet]="buttonControl"></ng-container>

<div class="row">
  <app-pssr-edit-members 
    *ngIf="pssr && pssr.buObj" 
    [pssr]="pssr"
    [allowMultiApprover]="businessUnit.AllowMultiApprover"
    (memberChanged)="loadChangebleCollections()">
  </app-pssr-edit-members>
</div>

<div class="row">
  <app-pssr-edit-items [pssrFiles]="pssrFiles" *ngIf="pssr && pssr.buObj" [pssr]="pssr" [groups]="groups" (itemUpdated)="loadChangebleCollections()">
  </app-pssr-edit-items>
</div>

<p-confirmDialog #cd>
  <p-footer>
    <button id="acceptBtn" type="button" pButton icon="fa-check pi pi-check whiteSpaceBtn" [label]="'Yes' | translate"
      (click)="cd.accept()" style="width:80px;">
    </button>
    <button id="rejectBtn" type="button" pButton icon="fa-close pi pi-times whiteSpaceBtn" [label]="'No' | translate"
      (click)="cd.reject()" style="width:80px;">
    </button>
  </p-footer>
</p-confirmDialog>
<!-- File Attachment -->
<div class="row">
  <div class="col-md-6">
    <app-pssr-file *ngIf="pssr" [accept]="validExtensionsDocumentsUpload" [pssrFiles]="pssrDocuments" [pssr]="pssr"
      [title]="'Documents' | translate" [isImage]="false">
    </app-pssr-file>
  </div>
  <div class="col-md-6">
    <app-pssr-file [accept]="validExtensionsImagesUpload" *ngIf="pssr" [pssrFiles]="pssrImages" [pssr]="pssr"
      [title]="'Images' | translate" [isImage]="true">
    </app-pssr-file>
  </div>
</div>

<!-- Buttons -->
<ng-container *ngIf="pssr" [ngTemplateOutlet]="buttonControl"></ng-container>


<!-- Reject Modal -->
<p-dialog [(visible)]="displayRejectModal" [maximizable]="true" showEffect="fade" [modal]="true">
  <p-header>
    {{ "Reject Reason" | translate }}
  </p-header>
  <textarea type="text" style="width: 350px; height: 100px;" class="form-control" [(ngModel)]="rejectReason">
  </textarea>
  <p-footer class="row">
    <button type="button" pButton [label]="'Cancel' | translate" (click)="cancelReject()"
      style="width: 80px; white-space: pre-wrap !important; background-color: #c9302c; border-color: #ac2925;">
    </button>
    <button type="button" pButton [label]="'Confirm' | translate" (click)="confirmReject()"
      style="width: 80px; white-space: pre-wrap !important"></button>
  </p-footer>
</p-dialog>

<!-- Send Email Reminder Modal Dialog-->
<p-dialog [(visible)]="displayEmailReminderModal" (onHide)="cancelSendReminder()" [maximizable]="true" showEffect="fade" [modal]="true" [style]="{ width: '500px' }">
  <p-header>
    {{ "Send Email Reminder" | translate }}
  </p-header>
  <div class="send-reminder-dialog">
    <!-- First option-->
    <div class="send-reminder-dialog-group-option">
      <label>
        <input
          class="form-check-input"
          type="radio"
          name="type"
          [value]="false"
          [(ngModel)]="sendFreeText"
        />
        {{ 'Send a reminder to Assignees' | translate }}
      </label>
      <p class="send-reminder-dialog-description">{{ 'SendReminderInstructions' | translate }}</p>
    </div>
      
    <!-- Second option-->
    <div class="send-reminder-dialog-group-option"> 
      <label>
        <input
          class="form-check-input"
          type="radio"
          name="type"
          [value]="true"
          [(ngModel)]="sendFreeText"
        />
        {{ 'Send a message' | translate }}
      </label>
      <p class="send-reminder-dialog-description"> {{'Send an email message to all PSSR Participants' | translate }}</p>
      <textarea *ngIf="sendFreeText" 
        type="text" 
        style="width: 100%; height: 100px; margin-top: 1em" 
        class="form-control" 
        [(ngModel)]="textForReminder">
      </textarea>
    </div>
  </div>

  <p-footer class="row">
    <button type="button" pButton [label]="'Cancel' | translate" (click)="cancelSendReminder()"
      style="width: 80px; white-space: pre-wrap !important; background-color: #c9302c; border-color: #ac2925;">
    </button>
    <button type="button" [disabled] = "sendFreeText && !textForReminder" pButton [label]="'Confirm' | translate" (click)="sendEmail()"
      style="width: 80px; white-space: pre-wrap !important"></button>
  </p-footer>
</p-dialog>

<!--Template for buttons-->
<ng-template #buttonControl>
  <div class="box-shadow module" >
    <div class="row">
      <div class="col-md-12">
        <div *ngIf="pssr.validationMsg != null" class="alert alert-warning" role="alert">
          {{ pssr.validationMsg | translate:pssr.buObj }}
        </div>
      </div>
    </div>

    <div class="row">

      <div class="action-buttons-container">
        <button id="save" type="button" class="btn btn-primary whiteSpaceBtn" (click)="save()"
          style="width: 100%;">{{'Save' | translate}}</button>
        <button *ngIf="pssr.Status &gt; PSSRStatus.Draft" id="printtopdfBtn" type="button" class="btn btn-blk whiteSpaceBtn"
          (click)="downloadPdf()" style="width: 100%;">{{'Print to PDF' | translate}}</button>
        <button *ngIf="(pssr.Status &gt; PSSRStatus.Draft) && !pssr.IsDeleted" id="sendemailreminderBtn" type="button" class="btn btn-blk whiteSpaceBtn"
          (click)="showEmailReminderDialog()" style="width: 100%;">{{'Send Email Reminder' | translate}}</button>
        <label *ngIf="(pssr.Status &gt; PSSRStatus.Draft) && pssr.EmailSentDate">
          Last sent: {{ pssr.EmailSentDate | date:'shortDate' }}
        </label>
        <button *ngIf="canImportMOCAIs()" id="importAIsMOCBtn" type="button" class="btn btn-primary whiteSpaceBtn"
        (click)="importMOCAIs()" style="width: 100%;">{{'Import MOC Action Items' | translate}}</button>
        <button *ngIf="(pssr.Status == PSSRStatus.Approved || pssr.Status == PSSRStatus.Closed) && !pssr.IsDeleted && (isBUAdmin || isLead) && isImportedMOC()" id="sendToMOCStartBtn" type="button" class="btn btn-primary whiteSpaceBtn"
        (click)="sendMOCToStartApproval()" style="width: 100%;">{{'Send to MOC record for startup approval' | translate}}</button>
        <button *ngIf="canChangeStatus" [class.greenButton]="pssr.NextStatusId === PSSRStatus.InProgress" id="changestatusBtn" [disabled]="!pssr.pssrIsValid || pssr.validationMsg != null"
          type="button" class="btn btn-primary whiteSpaceBtn" (click)="showChangeStatusDialog()" style="width: 100%;">{{
          ((pssr.NextStatusId ===
          PSSRStatus.Completed && pssr.Status === PSSRStatus.PendingApproval) ? 'ReturnTeamLead' : pssr.NextStatus) | translate}}</button>
        <button *ngIf="canReject" id="canRejectBtn" type="button" class="btn btn-warning whiteSpaceBtn"
          (click)="rejectPSSR()" style="width: 100%;">{{ 'Reject' | translate}}</button>
        <button id="deleteBtn" *ngIf="canDelete && !pssr.IsDeleted" type="button" class="btn btn-danger whiteSpaceBtn"
          (click)="confirmDelete()" style="width: 100%;">{{'Delete' | translate}}
        </button>
        <button id="restorepssrBtn" *ngIf="canDelete && pssr.IsDeleted" type="button"
          class="btn btn-success whiteSpaceBtn" (click)="restorePssr()" style="width: 100%;">{{'Restore Pssr' |
          translate:pssr.buObj}}
        </button>
        <button *ngIf="canReOpen" id="reOpenBtn" type="button" class="btn btn-primary whiteSpaceBtn"
          (click)="showReopenPSSRDialog()" style="width: 100%;">{{'Reopen' | translate}}
        </button>

      </div>
    </div>
  </div>
</ng-template>
