import { EndDeviceType } from './end-device-type';

import { ManufacturerModel } from '#models/manufacturer-model';

export class EndDeviceTemplate {
    public Id: number;
    public Description: string;
    public EndDeviceTypeId: number;
    public EndDeviceType: EndDeviceType;
    public Manufacturer: string;
    public Model: string;
    public ProvidedBy: string;
    public Shipment: string;
    public TagCode: string;
    public TagNo: string;
    public EquipmentTemplateId: number;

    public EAMEquipNum: string;
    public EAMParent: string;
    public EAMClass: string;
    public EAMType: string;
    public EAMDescription: string;
    public EAMCriticality: string;
    public ManufacturerModelId: number;
    public ManufacturerModel: ManufacturerModel;
    SortID: number;
    IsEnable: boolean;
}
