<div class="box-shadow module">
  <div class="row">
    <h2>{{'Groups'|translate}}</h2>
  </div>

  <div class="row padding-bottom-5">
    <div class="col-xs-6">
      <label>{{'Business Unit'|translate}} :</label>
      <select
        [appBUSwitcher]="selectedBU"
        class="form-control"
        [(ngModel)]="selectedBU"
        (change)="loadOrganizations()"
      >
        <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
  </div>

  <table class="table table-hover">
    <tr>
      <th scope="col" class="col-xs-4">{{'Group Names'|translate}}</th>
      <td scope="col" class="col-xs-1" style="text-align: end">
        <button
          type="button"
          class="btn btn-success"
          (click)="btnCreate()"
          style="width: 80px"
        >
          {{'New'|translate}}
        </button>
      </td>
    </tr>
    <tr *ngFor="let group of groups">
      <td class="col-xs-4">{{ group.Name }}</td>
      <td class="col-xs-1" style="text-align: end">
        <button
          type="button"
          class="btn btn-primary"
          (click)="btnEdit(group)"
          style="width: 80px"
        >
          {{'Edit'|translate}}
        </button>
      </td>
    </tr>
  </table>
</div>
