<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">

    <h2>{{'Attention'|translate}}!</h2>
    <hr>
    <h3>{{'Are you sure you want to delete Sub Category'|translate}}: <span style="background-color: yellow;">{{subCategory.Name}}</span> ?</h3>
    <h3><a class="btn btn-danger" style="width: 100px;" (click)="deleteSubCategory(subCategory.Id)" >{{'Delete'|translate}}</a></h3>
</div>

<div style="width:80%; margin:auto">
    <a [routerLink]="['/admin/subcategory']" style="cursor: pointer;" id="backToCategoryList">{{'Back to Category List'|translate}}</a>
</div>

