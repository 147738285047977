import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { ApiModule } from '#services/api/api.module';
import { SharedModule } from '#services/shared/shared.module';
import { ComponentsModule } from '../../components/components.module';
import { SignatureComponent } from './signature/signature.component';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { PssrEditItemsSectionComponent } from './pssr-edit-items-section/pssr-edit-items-section.component';
import { PssrEditItemsComponent } from './pssr-edit-items/pssr-edit-items.component';
import { ActionItemComponent } from './action-item/action-item.component';
import { PssrCreateHeaderComponent } from './pssr-create-header/pssr-create-header.component';
import { PssrEditHeaderComponent } from './pssr-edit-header/pssr-edit-header.component';
import { PssrEditMembersComponent } from './pssr-edit-members/pssr-edit-members.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { ConfirmationService } from 'primeng/api';
import { PssrFileComponent } from './pssr-file/pssr-file.component';
import { ActionItemDialogComponent } from './dialogs/action-item-dialog/action-item-dialog.component';
import { CommentDialogComponent } from './dialogs/comment-dialog/comment-dialog.component';
import { PssrCreateKmsHeaderComponent } from './pssr-create-kms-header/pssr-create-kms-header.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { PssrEditQaqcMembersComponent } from './pssr-edit-qaqc-members/pssr-edit-qaqc-members.component';
import { UnescapePipe } from './pipes/unescape.pipe';
import { FilesFormComponent } from './files-form/files-form.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { FileUploadModule } from 'primeng/fileupload';
import {
  TranslateLoader,
  TranslateModule,
  TranslateParser,
} from '@ngx-translate/core';
import { LanguageLoader } from '#services/shared/language-loader';
import { LanguageParser } from '#services/shared/language-parser';
import { SectionInputDialogComponent } from './dialogs/section-input-dialog/section-input-dialog.component';
import { PdfPagesDialogComponent } from './dialogs/pdf-pages-dialog/pdf-pages-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ToggleRichInputComponent } from './toggle-rich-input/toggle-rich-input.component';
import { RichTextEditorModule } from '@syncfusion/ej2-angular-richtexteditor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslationRequestDialogComponent } from './dialogs/translation-request-dialog/translation-request-dialog.component';
import { ParticipantTypePipe } from './pipes/participant-type.pipe';
import { FullNamePipe } from './pipes/fullname.pipe';
import { TranslateInPipe } from './pipes/translate-in.pipe';
import { SharedUserFormComponent } from './shared-user-form/shared-user-form.component';

@NgModule({
  declarations: [
    // Components
    ActionItemComponent,
    PssrCreateHeaderComponent,
    PssrEditHeaderComponent,
    PssrEditItemsComponent,
    PssrEditItemsSectionComponent,
    PssrEditMembersComponent,
    SignatureComponent,
    PssrFileComponent,

    UnescapePipe,
    ParticipantTypePipe,
    FullNamePipe,
    TranslateInPipe,
    ActionItemDialogComponent,
    CommentDialogComponent,
    PssrCreateKmsHeaderComponent,
    PssrEditQaqcMembersComponent,
    FilesFormComponent,
    SectionInputDialogComponent,
    PdfPagesDialogComponent,
    ToggleRichInputComponent,
    TranslationRequestDialogComponent,
    SharedUserFormComponent
  ],
  exports: [
    ActionItemComponent,
    PssrCreateHeaderComponent,
    PssrEditHeaderComponent,
    PssrEditItemsComponent,
    PssrEditItemsSectionComponent,
    PssrEditMembersComponent,
    PssrFileComponent,
    UnescapePipe,
    ParticipantTypePipe,
    FullNamePipe,
    TranslateInPipe,
    ActionItemDialogComponent,
    TranslationRequestDialogComponent,
    CommentDialogComponent,
    PssrCreateKmsHeaderComponent,
    PssrEditQaqcMembersComponent,
    FilesFormComponent,
    SectionInputDialogComponent,
    ToggleRichInputComponent,
    SharedUserFormComponent
  ],
  imports: [
    // Angular
    DragDropModule,
    CommonModule,
    ReactiveFormsModule,
    DialogModule,
    DropdownModule,
    AutoCompleteModule,
    CalendarModule,
    CardModule,
    InputTextModule,
    MultiSelectModule,
    MatDialogModule,
    RichTextEditorModule,
    // App
    ApiModule,
    SharedModule,
    FormsModule,
    ComponentsModule,
    ConfirmDialogModule,
    FileUploadModule,
    TranslateModule.forChild({
      loader: { provide: TranslateLoader, useClass: LanguageLoader },
      useDefaultLang: true,
      parser: {
        provide: TranslateParser,
        useClass: LanguageParser,
      },
    }),
  ],
  providers: [ConfirmationService],
  entryComponents: [PdfPagesDialogComponent, TranslationRequestDialogComponent],
})
export class SharedPagesModule { }
