import { SubCategory } from '#models/subcategory';
import { SubCategoryService } from '#services/api';


import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs-compat/Subscription';
import { take } from 'rxjs/operators';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-subcategory-create',
  templateUrl: './subcategory-create.component.html',
})
export class SubcategoryCreateComponent implements OnDestroy {
  subCategoryForm: FormGroup;
  displayWarning = false;


  sub = new Subscription();

  constructor(
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,

    private router: Router,
    private subCategoryService: SubCategoryService
  ) {
    this.subCategoryForm = this.formBuilder.group({
      Name: ['', Validators.required],
      IsActive: [true],
    });
  }

  createSubCategory(subCategory: SubCategory): void {
    this.displayWarning = !this.subCategoryForm.valid;

    if (this.displayWarning) { return; }



    this.sub.add(
      this.subCategoryService
        .post(subCategory)
        .pipe(
          take(1),

        )
        .subscribe(
          () => {
            this.router.navigate(['/admin/subcategory']);
          },
          (err) => {
            this.toastrService.showError( err.error.ExceptionMessage);

          }
        )
    );
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
