
<div class="box-shadow module" [appBUSwitcher]="selectedBU">
  <div class="row">
    <h2>{{ "Edit Group" | translate }}</h2>
    <hr />
  </div>

  <div class="form-horizontal">


    <div class="form-horizontal">
      <div class="form-group">
        <label class="control-label col-md-2">{{
          "Group Name" | translate
        }}</label>
        <div class="col-md-6">
          <input
            class="form-control text-box single-line"
            [(ngModel)]="group.Name"
          />
        </div>
        <div class="col-md-offset-1 col-md-2">
          <a class="btn btn-default" style="width: 100px" (click)="save()">{{
            "Save" | translate
          }}</a>
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-2">{{
          "User Type" | translate
        }}</label>
        <div class="col-xs-6 row">
          <label class="form-check-inline col-xs-3">
            <input
              class="form-check-input"
              type="radio"
              name="type"
              [value]="1"
              [(ngModel)]="user.Type"
              (change)="clear()"
            />
            &nbsp;{{ "Chevron" | translate }}
          </label>
          <label class="form-check-inline col-xs-4">
            <input
              class="form-check-input"
              type="radio"
              name="type"
              [value]="2"
              [(ngModel)]="user.Type"
              (change)="clear()"
            />
            &nbsp;{{ "3rdPartyexternal" | translate }}
          </label>
          <label class="form-check-inline col-xs-3">
            <input
              class="form-check-input"
              type="radio"
              name="type"
              [value]="3"
              [(ngModel)]="user.Type"
              (change)="clear()"
            />
            &nbsp;{{ "Shared" | translate }}
          </label>
        </div>
      </div>
      <!-- chevron-->
      <div *ngIf="user.Type === UserType.Chevron" class="form-group">
        <label class="control-label col-md-2"
          >{{'User'|translate}}<b style="color: red">&nbsp;*</b></label
        >
        <div class="col-md-6">
          <p-autoComplete
            [(ngModel)]="selectedUser"
            [suggestions]="users"
            (completeMethod)="getUsers($event)"
            name="usr"
            #usr="ngModel"
            field="FullName"
            delay="1000"
            [style]="{ width: '100%' }"
            [inputStyle]="{ width: '100%' }"
            [minLength]="1"
            [placeholder]="'typeusercainame' | translate"
          >
          </p-autoComplete>
        </div>
      </div>
      <!-- external or shared -->
      <div *ngIf="user.Type === UserType.External || user.Type === UserType.Shared" class="form-group">
        <label class="control-label col-md-2"
          >
          <ng-container *ngIf="user.Type === UserType.External">{{'First Name'|translate}}</ng-container>
          <ng-container *ngIf="user.Type === UserType.Shared">{{'Shared User'|translate}}</ng-container>
          <b style="color: red">&nbsp;*</b></label
        >
        <div class="col-md-6">
          <input
            class="form-control text-box single-line"
            [(ngModel)]="user.FirstName"
            [attr.placeholder]="'typenewuser' | translate"
          />
        </div>
      </div>
      <!-- external -->
      <div *ngIf="user.Type === UserType.External" class="form-group">
        <label class="control-label col-md-2">{{
          "Last Name" | translate
        }}</label>
        <div class="col-md-6">
          <input
            class="form-control text-box single-line"
            [(ngModel)]="user.LastName"
            [placeholder]="'typelastname' | translate"
          />
        </div>
      </div>
      <!-- external or shared -->
      <div *ngIf="user.Type === UserType.External || user.Type === UserType.Shared" class="form-group">
        <label class="control-label col-md-2"
          >
          <ng-container *ngIf="user.Type === UserType.External">{{'Email'|translate}}</ng-container>
          <ng-container *ngIf="user.Type === UserType.Shared">{{'Shared Email'|translate}}</ng-container>
          <b style="color: red">&nbsp;*</b></label
        >
        <div class="col-md-6">
          <ng-container
            *ngIf="
              user.Type === UserType.External ||
              (!selectedBU.CanImportSharedUsers && user.Type === UserType.Shared)
            "
          >
            <input
              class="form-control text-box single-line"
              [(ngModel)]="user.Email"
              [placeholder]="'typeemail' | translate"
            />
          </ng-container>
          <div
            class="has-feedback"
            *ngIf="selectedBU.CanImportSharedUsers && user.Type === UserType.Shared"
          >
            <div class="input-group">
              <input
                type="email"
                required
                #sharedUserSearch="ngModel"
                email
                class="form-control"
                (keydown.enter)="
                  sharedUserSearch.valid &&
                  importSharedUsers(selectedBU?.Id, user.Email)
                "
                [(ngModel)]="user.Email"
                name="sharedUserSearch"
                id="sharedUserSearch"
                [minLength]="1"
                [placeholder]="'InputSharedUserEmail' | translate"
              />
              <span class="input-group-btn">
                <button
                  class="btn btn-default"
                  id="import-shared-user-btn"
                  [disabled]="sharedUserSearch.invalid"
                  type="button"
                  (click)="importSharedUsers(selectedBU?.Id, user.Email)"
                >
                  {{ "Import Shared User" | translate }}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
      
      <!-- Shared users list component -->
      <div class="form-group" *ngIf="user?.Type === UserType.Shared && user?.ChildUsers != null">
        <app-shared-user-form
          class="col-md-9 ml-5"
          id="sharedUserComponent"
          [parentUserId]="user?.ID"
          [sharedUsers]="user?.ChildUsers"
          (userListChanged)="onSharedUsersListChanged($event)">
        </app-shared-user-form>
      </div>

      <div class="form-group">
        <div class="col-md-offset-2 col-md-4">
          (<b style="color: red">*</b>) {{ "Required Fields" | translate }}
        </div>
        <div class="col-md-2">
          <button
            class="btn btn-primary"
            style="width: 100px"
            (click)="addUser()"
            [disabled]="sharedUsersCtrlInvalid"
          >
            {{ "Add" | translate }}
          </button>
        </div>
      </div>
    </div>
    <p-confirmDialog #cd>
      <p-footer>
        <button
          type="button"
          pButton
          icon="fa-check pi pi-check"
          [label]="'Yes' | translate"
          (click)="cd.accept()"
          style="width: 80px"
        ></button>
        <button
          type="button"
          pButton
          icon="fa-close pi pi-times"
          [label]="'No'|translate"
          (click)="cd.reject()"
          style="width: 80px"
        ></button>
      </p-footer>
    </p-confirmDialog>

    <table class="table table-hover">
      <tr>
        <th scope="col">{{ "Name" | translate }}</th>
        <th scope="col">{{ "CAI" | translate }}</th>
        <th scope="col">{{ "Email" | translate }}</th>
        <th id="thHolder"></th>
      </tr>
      <tr *ngFor="let o of groupUsers">
        <td>{{ o | fullName }}</td>
        <td>{{ o.CAI }}</td>
        <td>{{ o.Email }}</td>
        <td style="text-align: end">
          <button
            *ngIf="isSysAdmin"
            type="button"
            class="btn btn-danger"
            (click)="removeUser(o.Id)"
            style="width: 80px"
          >
            {{ "Delete" | translate }}
          </button>
        </td>
      </tr>
    </table>
  </div>
  <div>
    <a (click)="redirectBack()" style="cursor: pointer">{{
      "Back to List" | translate
    }}</a>
  </div>
</div>
