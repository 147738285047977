import { Configuration } from 'msal';

import { environment } from '#environments/environment';

export function MsalConfigFactory(): Configuration {
  return {
    auth: environment.cal.auth,
    cache: environment.cal.cache,
  } as Configuration;
}
