<div class="box-shadow module" [appBUSwitcher]="selectedBU">
  <h2 *ngIf="orgUsr.HasAccess">{{ "Edit User" | translate }}</h2>
  <h2 *ngIf="!orgUsr.HasAccess">{{ "User Access Request" | translate }}</h2>
  <hr />

  <p-confirmDialog #cd>
    <p-footer>
      <button type="button" pButton icon="fa-check pi pi-check" [label]="'Yes' | translate" (click)="cd.accept()"
        style="width: 80px"></button>
      <button type="button" pButton icon="fa-close pi pi-times" [label]="'No' | translate" (click)="cd.reject()"
        style="width: 80px"></button>
    </p-footer>
  </p-confirmDialog>
  <div *ngIf="!orgUsr.HasAccess" class="form-horizontal">
    <form #accessForm="ngForm">
      <div class="form-group">
        <label class="control-label col-md-offset-2 col-md-2">{{
          "Business Unit" | translate
          }}</label>
        <div class="col-md-4">
          <select name="selectedBUId" *ngIf="selectedBU" class="form-control text-box single-line"
            (change)="loadOrganizations()" [(ngModel)]="selectedBU.Id" required>
            <option *ngFor="let bu of lstBusinessUnit" [ngValue]="bu.Id">
              {{ bu.Name }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-offset-2 col-md-2">{{
          selectedBU?.OrganizationLabel | translate
          }}</label>
        <div class="col-md-4">
          <div *ngIf="selectedOrg">
            <input name="selectedOrgName" class="form-control text-box single-line" [(ngModel)]="selectedOrg.Name"
              [disabled]="true" />
          </div>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-md-offset-2 col-md-2">{{
          "User" | translate
          }}</label>
        <div class="col-md-4">
          <input name="selectedUserFullname" *ngIf="selectedUser" class="form-control text-box single-line"
            [(ngModel)]="selectedUser.FullName" [disabled]="true" />
        </div>
      </div>
      <div class="form-group" style="text-align: center">
        <button style="margin-right: 1vh" class="btn btn-success" (click)="approveAccess()">
          {{ "Approve" | translate }}
        </button>
        <button style="margin-left: 1vh" class="btn btn-danger" (click)="removeUser()">
          {{ "Reject" | translate }}
        </button>
      </div>
    </form>
  </div>

  <div *ngIf="orgUsr.HasAccess" class="form-horizontal">
    <form #userForm="ngForm" (keydown.enter)="$event.preventDefault()">
      <div class="form-group">
        <label class="control-label col-md-2">{{
          "Business Unit" | translate
          }}</label>
        <div class="col-md-8">
          <input name="orgUsrBusinessUnitName" class="form-control text-box single-line"
            [(ngModel)]="orgUsr.BusinessUnitName" [disabled]="true" />
        </div>
      </div>

      <div class="form-group">
        <label class="control-label col-md-2">{{
          selectedBU?.OrganizationLabel
          }}</label>
        <div class="col-md-8">
          <input name="orgUsrOrganizationName" class="form-control text-box single-line"
            [(ngModel)]="orgUsr.OrganizationName" [disabled]="true" />
        </div>
      </div>

      <div class="form-group user-type-control">
        <label class="user-type-control-title col-xs-12 control-label col-md-2">{{
          "User Type" | translate
          }}</label>
        <div class="col-xs-7 row">
          <label class="user-type-control-item-title form-check-inline col-xs-3">
            <input disabled class="form-check-input" type="radio" name="type" [value]="1" [(ngModel)]="orgUsr.Type" />
            &nbsp;{{ "Chevron" | translate }}
          </label>
          <label class="user-type-control-item-title form-check-inline col-xs-4">
            <input disabled class="form-check-input" type="radio" name="type" [value]="2" [(ngModel)]="orgUsr.Type" />
            &nbsp;{{ "3rdPartyexternal" | translate }}
          </label>
          <label class="user-type-control-item-title form-check-inline col-xs-3">
            <input disabled class="form-check-input" type="radio" name="type" [value]="3" [(ngModel)]="orgUsr.Type" />
            &nbsp;{{ "Shared" | translate }}
          </label>
        </div>
        <div class="col-xs-3 padding-0">&nbsp;</div>
      </div>

      <!-- Shared users list component -->
      <fieldset class="import-shared-users-options"
        *ngIf="selectedBU?.CanImportSharedUsers && selectedBU?.Code === BusinessUnitCode.TCO && orgUsr.Type === UserType.Shared">
        <div class="form-group">
          <div class="col-md-offset-2 col-md-8">
            <div class="checkbox">
              <label for="importSharedUserByTcoWhitePages-1" class="import-shared-users-option-container">
                <input type="radio" id="importSharedUserByTcoWhitePages-1" name="importSharedUserByTcoWhitePages"
                  [(ngModel)]="orgUsr.IsTcoWhitePagesImported" (ngModelChange)="onImportSharedUsersOptionSelected($event)" [value]="true" class="import-shared-users-option-input">
                <div class="import-shared-users-option-text-container">
                  <h4 class="import-shared-users-option-title">{{ 'ImportSharedUserOptionWhitePagesTitle' | translate }}
                  </h4>
                  <p class="import-shared-users-option-description">{{ 'ImportSharedUserOptionWhitePagesDescription' |
                    translate }}</p>
                </div>
              </label>
            </div>
          </div>
        </div>

        <div class="form-group">
          <div class="col-md-offset-2 col-md-10">
            <div class="checkbox">
              <label for="importSharedUserByTcoWhitePages-2" class="import-shared-users-option-container">
                <input type="radio" id="importSharedUserByTcoWhitePages-2" name="importSharedUserByTcoWhitePages"
                  [(ngModel)]="orgUsr.IsTcoWhitePagesImported" [value]="false" class="import-shared-users-option-input" (ngModelChange)="onImportSharedUsersOptionSelected($event)">
                <div class="import-shared-users-option-text-container">
                  <h4 class="import-shared-users-option-title">{{'ImportSharedUserOptionCustomTitle'|translate}} </h4>
                  <p class="import-shared-users-option-description">
                    {{'ImportSharedUserOptionCustomDescription'|translate}}
                  </p>
                </div>
              </label>
            </div>
          </div>
        </div>
      </fieldset>
      <!-- chevron-->
      <div *ngIf="orgUsr.Type === UserType.Chevron" class="form-group">
        <label class="control-label col-md-2">{{ "User" | translate }}<b style="color: red">&nbsp;*</b></label>
        <div class="col-md-8">
          <p-autoComplete [(ngModel)]="selectedUser" [suggestions]="users" (completeMethod)="getUsers($event)"
            name="usr" #usr="ngModel" field="FullName" [style]="{ width: '100%' }" [inputStyle]="{ width: '100%' }"
            [minLength]="1" [placeholder]="'typeusercainame' | translate">
          </p-autoComplete>
        </div>
      </div>
      <!-- external or shared -->
      <div *ngIf="orgUsr.Type === UserType.External || orgUsr.Type === UserType.Shared" class="form-group">
        <label class="control-label col-md-2">
          <ng-container *ngIf="orgUsr.Type === UserType.External">{{
            "First Name" | translate
            }}</ng-container>
          <ng-container *ngIf="orgUsr.Type === UserType.Shared">{{
            "Shared User" | translate
            }}</ng-container>
          <b style="color: red">&nbsp;*</b>
        </label>
        <div class="col-md-8">
          <input name="orgUsrFirstName" required class="form-control text-box single-line"
            [(ngModel)]="orgUsr.FirstName" [attr.placeholder]="'typenewuser' | translate" />
        </div>
      </div>
      <!-- external -->
      <div *ngIf="orgUsr.Type === UserType.External" class="form-group">
        <label class="control-label col-md-2">{{ "Last Name" | translate }}</label>
        <div class="col-md-8">
          <input name="orgUsrLastName" class="form-control text-box single-line" [(ngModel)]="orgUsr.LastName"
            [placeholder]="'typelastname' | translate" />
        </div>
      </div>
      <!-- external or shared -->
      <div *ngIf="orgUsr.Type === UserType.External || orgUsr.Type === UserType.Shared" class="form-group">
        <label class="control-label col-md-2">
          <ng-container *ngIf="orgUsr.Type === UserType.External">{{
            "Email" | translate
            }}</ng-container>
          <ng-container *ngIf="orgUsr.Type === UserType.Shared">{{
            "Shared Email" | translate
            }}</ng-container>
          <b style="color: red">&nbsp;*</b>
        </label>
        <div class="col-md-8">
          <ng-container *ngIf="
            orgUsr.Type === UserType.External ||
            (!selectedBU.CanImportSharedUsers && orgUsr.Type === UserType.Shared)
          ">
            <input name="orgUsrEmail" required class="form-control text-box single-line" [(ngModel)]="orgUsr.Email"
              [placeholder]="'typeemail' | translate" />
          </ng-container>
          <div class="has-feedback" *ngIf="selectedBU.CanImportSharedUsers && orgUsr.Type === UserType.Shared">
            <div [class.input-group]="orgUsr.IsTcoWhitePagesImported">
              <input name="orgUsrEmail" required type="email" required #sharedUserSearch="ngModel" email
                class="form-control" (keydown.enter)="
                sharedUserSearch.valid &&
                  getSharedUsers(selectedBU?.Id, orgUsr.Email)
              " [(ngModel)]="orgUsr.Email" name="sharedUserSearch" id="sharedUserSearch" [minLength]="1"
                [placeholder]="'InputSharedUserEmail' | translate" />
              <span *ngIf="orgUsr.IsTcoWhitePagesImported" class="input-group-btn">
                <button class="btn btn-default" id="import-shared-user-btn" [disabled]="sharedUserSearch.invalid"
                  type="button" (click)="getSharedUsers(selectedBU?.Id, orgUsr.Email)">
                  {{ "Import Shared User" | translate }}
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>


      <app-shared-user-form *ngIf="orgUsr.Type === UserType.Shared" id="sharedUserComponent"
        [parentUserId]="selectedUser.ID" [sharedUsers]="orgUsr.ChildUsers"
        [disabled]="orgUsr.IsTcoWhitePagesImported && selectedBU?.Code === BusinessUnitCode.TCO"
        (userListChanged)="onSharedUsersListChanged($event)">
      </app-shared-user-form>

      <div class="form-group" *ngIf="orgUsr.Type !==  UserType.Shared">
        <label class="control-label col-md-2">{{ "Organization Admin" | translate }}</label>
        <div class="col-md-8">
          <input name="orgUsrIsAdmin" type="checkbox" [(ngModel)]="orgUsr.IsAdmin" value="orgUsr.IsAdmin" />
        </div>
      </div>

      <div class="row">
        <div class="col-md-offset-2 col-xs-12">
          (<b style="color: red">*</b>) {{ "Required Fields" | translate }}
        </div>

        <div class="col-md-offset-7 col-md-1 col-xs-4 col-xs-offset-2 text-center">
          <button class="btn btn-danger" style="width: 100px" (click)="removeUser()">{{
            "Remove" | translate
            }}</button>
        </div>
        <div class="col-md-offset-1 col-md-1 col-xs-4 col-xs-offset-1 text-center">
          <button [disabled]="userForm.invalid || sharedUsersCtrlInvalid" type="submit" (click)="save()"
            class="btn btn-default" style="width: 100px">
            {{ "Save" | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
  <div>
    <button id="back-to-users-list" class="btn btn-link" (click)="onRedirectButtonClick()" style="cursor: pointer">
      {{ "Back to List" | translate }}
    </button>
  </div>
</div>