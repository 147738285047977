<div class="box-shadow module">
  <div class="row">
    <h2>{{ "System Administrators" | translate }}</h2>
    <hr />
  </div>

  <div class="form-horizontal padding-bottom-10">
    <div>
      <label>{{ "User" | translate }} :</label>

      <div id="find-user-container">
        <p-autoComplete
          [(ngModel)]="selectedUser"
          [suggestions]="users"
          (completeMethod)="getUsers($event)"
          name="usr"
          #usr="ngModel"
          field="FullName"
          delay="1000"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [minLength]="1"
        ></p-autoComplete>

        <button
          type="button"
          id="add-system-admin-user"

          [disabled]="!selectedUser"
          class="btn btn-primary"
          (click)="addSysAdmin()"
        >
          {{ "Save" | translate }}
        </button>
      </div>
    </div>
  </div>

  <table class="table table-hover">
    <tr>
      <th scope="col">{{ "Name" | translate }}</th>
      <th scope="col">{{ "CAI" | translate }}</th>
      <th scope="col">{{ "Email" | translate }}</th>
      <th scope="col">{{ "Disabled" | translate }}</th>
    </tr>
    <tr *ngFor="let o of sysAdminList">
      <td>{{ o | fullName }}</td>
      <td>{{ o.CAI }}</td>
      <td>{{ o.Email }}</td>
      <td>
        <input
          type="checkbox"
          [(ngModel)]="o.IsDisabled"
          (click)="updateSysAdmin(o.ID)"
          value="o.IsDisabled"
        />
      </td>
    </tr>
  </table>
</div>
