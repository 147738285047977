import { User } from '#models/user';
import { Utility } from '#services/shared/utility';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullName',
})
export class FullNamePipe implements PipeTransform {
  transform(value: User): string {
    return Utility.getFullName(value);
  }
}
