import { PSSRStatus } from '#models/pssr-status';
import { Organization, Area, SubArea, Facility, Project, TaskPlan, BusinessUnit } from '.';

export class PSSRFilter {
  BUId: number;
  OrganizationId: number;
  AreaId: number;
  SubAreaId: number;
  FacilityId: number;
  Organization?: Organization;
  BusinessUnit?: BusinessUnit;
  Area?: Area;
  SubArea?: SubArea;
  Facility?: Facility;
  Project?: Project;
  TaskPlan?: TaskPlan;
  StatusList: PSSRStatus[];
  PSSRName: string;
  Search: string;
  TaskPlanId: number;
  ProjectId: number;
  ShowDeleted: boolean;
  ProjectNumber: string;
  PssrTypeIds: number[];
  AssignedUserId?: number;
  ShowAbandoned: boolean;
  ShowAllPssrTypes: boolean;
}
