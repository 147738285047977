<app-loading-indicator></app-loading-indicator>

<app-header></app-header>

<div id="body">
  <main tabindex="-1" id="main-content" role="main">
    <div class="row">
      <div class="col-md-12 p-0">
        <app-welcome></app-welcome>
      </div>
    </div>
    <div *ngIf="showDisclaimer" class="module box-shadow text-center">
      <h3 style="margin-bottom: 0px">
        {{ "noticetousers" | translate | uppercase }}
      </h3>
      <h4 [innerHTML]="'ChevronDisclaimer' | translate"></h4>
      <button type="button" class="btn btn-light" (click)="iAccept()">
        {{ "I Agree" | translate }}
      </button>
    </div>
    <div *ngIf="!showDisclaimer" style="min-height: 500px">
      <div class="content">
        <ng-container *ngIf="!isIframe">
          <router-outlet (deactivate)="clearToastrs()"></router-outlet>
          <router-outlet name="download"></router-outlet>
        </ng-container>
      </div>
    </div>
  </main>
</div>
<app-footer></app-footer>

<p-toast baseZIndex="1101" position="top-right">
  <ng-template let-message pTemplate="message">
    <div class="ui-toast-summary">{{ message.summary }}</div>
    <div class="ui-toast-detail" [innerHTML]="message.detail"></div>
  </ng-template>
</p-toast>
