<div class="box-shadow module">

  <div class="row">
    <h2>Non Chevron Login</h2>
    <hr>
  </div>



  <div class="form-horizontal">

    <div class="form-group">
      <label class="control-label col-md-offset-2 col-md-2">{{'Email'|translate}}</label>
      <div class="col-md-4">
        <input id="email" class="form-control text-box single-line" [(ngModel)]="b2c.username"
          (keyup.enter)="loginB2C()">
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-offset-2 col-md-2">{{'Password'|translate}}</label>
      <div class="col-md-4">
        <input id="password" class="form-control text-box single-line" [(ngModel)]="b2c.password" type="password">
      </div>
    </div>

    <div class="form-group" style="text-align: center;">
      <button id="loginB2C"
        [disabled]="!b2c.username || !b2c.password"
        style="margin-right: 1vh;"
        class="btn btn-success"
        (click)="loginB2C()">
      {{'Login'|translate}}
    </button>
    </div>

  </div>

</div>
