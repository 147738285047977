<div class="form-group row">
      <label class="control-label col-md-2">{{
        "Display Rich Editor" | translate
      }}</label>
      <div class="col-md-4">
        <input
          style="margin-top: 10px;"
          type="checkbox"
          [disabled]="disabled"
          [checked]="displayRichEditor"
          (change)="onToggleRichEditor($event.target.checked)"
        />
      </div>
    </div>
    <div class="form-group row">
      <label class="control-label col-md-2">
        {{ label }}
      </label>
      <div class="col-md-10">
        <ejs-richtexteditor
          *ngIf="displayRichEditor"
          [(ngModel)]="value"
          [disabled]="disabled"
          [toolbarSettings]="toolbarSettings"
        >
        </ejs-richtexteditor>
        <textarea
          *ngIf="!displayRichEditor"
          class="form-control"
          height="auto"
          [disabled]="disabled"
          [(ngModel)]="value"
        >
        </textarea>
        <ng-content></ng-content>
      </div>
    </div>
