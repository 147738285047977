import { TranslationRequest } from '#models/translation-request';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TranslationsStorageService {
  private _data$ = new BehaviorSubject<TranslationRequest[]>([]);

  public readonly data$ = this._data$.asObservable();

  public setTranslations(data: TranslationRequest[] = []) {
    this._data$.next(data);
  }

  public clear() {
    this.setTranslations([]);
  }
}
