export class B2C {
  username: string;
  password: string;
}

export class B2CResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  id_token: string;
  error: string;
  error_description: string;

  dateInSeconds: number;

  username: string;
}
