import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { BusinessUnitsComponent } from '#pages/admin/business-units/business-units.component';

import { ProjectCreateComponent } from '#pages/admin/project-create/project-create.component';
import { ProjectEditComponent } from '#pages/admin/project-edit/project-edit.component';

import { EndDeviceComponent } from '#pages/admin/end-device/end-device.component';
import { EndDeviceFormComponent } from '#pages/admin/end-device-form/end-device-form.component';
import { EquipmentComponent } from '#pages/admin/equipment/equipment.component';
import { EquipmentFormComponent } from '#pages/admin/equipment-form/equipment-form.component';
import { EquipmentTempGroupComponent } from '../pages/admin/equipment-group/equipment-temp-group.component';
import { EndDeviceTypeFormComponent } from '#pages/admin/end-device-type-form/end-device-type-form.component';
import { EndDeviceTypeComponent } from '#pages/admin/end-device-type/end-device-type.component';

import { TaskPlanComponent } from '#pages/admin/task-plan/task-plan.component';
import { TaskPlanCreateComponent } from '#pages/admin/task-plan-create/task-plan-create.component';
import { TaskPlanEditComponent } from '#pages/admin/task-plan-edit/task-plan-edit.component';
import { TaskPlanManageComponent } from '#pages/admin/task-plan-manage/task-plan-manage.component';
import { TaskPlanDeleteComponent } from '#pages/admin/task-plan-delete/task-plan-delete.component';
import { TaskPlanSectionEditComponent } from '#pages/admin/task-plan-section-edit/task-plan-section-edit.component';
import { TaskPlanSectionDeleteComponent } from '#pages/admin/task-plan-section-delete/task-plan-section-delete.component';
import { TaskPlanSubSectionEditComponent } from '#pages/admin/task-plan-sub-section-edit/task-plan-sub-section-edit.component';
import { TaskPlanSubSectionDeleteComponent } from '#pages/admin/task-plan-sub-section-delete/task-plan-sub-section-delete.component';
import { TaskPlanItemCreateComponent } from '#pages/admin/task-plan-item-create/task-plan-item-create.component';
import { TaskPlanItemEditComponent } from '#pages/admin/task-plan-item-edit/task-plan-item-edit.component';

import { PagesModule } from '#pages/pages.module';
import { AdminPagesModule } from '#pages/admin/admin.pages.module';
import { NotFoundComponent } from '#pages/error/not-found/not-found.component';
import { ErrorPagesModule } from '#pages/error/error.pages.module';
import { HomeComponent } from '#pages/home/home.component';
import { GenericErrorComponent } from '#pages/error/generic-error/generic-error.component';
import { NotAuthorizedComponent } from '#pages/error/not-authorized/not-authorized.component';
import { NotRegisteredComponent } from '#pages/error/not-registered/not-registered.component';
import { AuthGuard } from './app.routing.guards';
import { Role } from 'models/enum/role';
import { LoginComponent } from '#pages/login/login.component';
import { OrganizationComponent } from '#pages/admin';
import { UserComponent } from '#pages/admin/user/user.component';
import { UserCreateComponent } from '#pages/admin/user-create/user-create.component';
import { UserEditComponent } from '#pages/admin/user-edit/user-edit.component';

import {
  PssrListComponent,
  PssrCreateComponent,
  PssrEditComponent,
  ImportComponent,
} from '#pages/pssr/index';
import { PssrActionItemListComponent } from '#pages/pssr/pssr-action-item-list/pssr-action-item-list.component';

import {
  PssrProjectComponent,
  PssrProjectDetailComponent,
  PssrProjectDetailCopyEquipComponent,
} from '#pages/pssr/index';

import { AreaComponent } from '#pages/admin/facility-hierarchy/area/area.component';
import { AreaCreateComponent } from '#pages/admin/facility-hierarchy/area-create/area-create.component';
import { AreaEditComponent } from '#pages/admin/facility-hierarchy/area-edit/area-edit.component';
import { AreaDeleteComponent } from '#pages/admin/facility-hierarchy/area-delete/area-delete.component';

import { SubAreaComponent } from '#pages/admin/facility-hierarchy/sub-area/sub-area.component';
import { SubAreaCreateComponent } from '#pages/admin/facility-hierarchy/sub-area-create/sub-area-create.component';
import { SubAreaEditComponent } from '#pages/admin/facility-hierarchy/sub-area-edit/sub-area-edit.component';
import { SubAreaDeleteComponent } from '#pages/admin/facility-hierarchy/sub-area-delete/sub-area-delete.component';

import { FacilityComponent } from '#pages/admin/facility-hierarchy/facility/facility.component';
import { FacilityCreateComponent } from '#pages/admin/facility-hierarchy/facility-create/facility-create.component';
import { FacilityEditComponent } from '#pages/admin/facility-hierarchy/facility-edit/facility-edit.component';
import { FacilityDeleteComponent } from '#pages/admin/facility-hierarchy/facility-delete/facility-delete.component';

import { CategoryComponent } from '#pages/admin/category/category.component';
import { CategoryCreateComponent } from '#pages/admin/category-create/category-create.component';
import { CategoryEditComponent } from '#pages/admin/category-edit/category-edit.component';
import { CategoryDeleteComponent } from '#pages/admin/category-delete/category-delete.component';
import { BusinessUnitUserComponent } from '#pages/admin/business-unit-user/business-unit-user.component';
import { SystemAdminComponent } from '#pages/admin/system-admin/system-admin.component';

import { BusinessUnitCreateComponent } from '#pages/admin/business-unit-create/business-unit-create.component';
import { BusinessUnitEditComponent } from '#pages/admin/business-unit-edit/business-unit-edit.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { FeedbackCreateComponent } from './feedback/feedback-create/feedback-create.component';
import { FeedbackEditComponent } from './feedback/feedback-edit/feedback-edit.component';
import { GroupsComponent } from '#pages/admin/groups/groups.component';
import { GroupEditComponent } from '#pages/admin/group-edit/group-edit.component';
import { GroupCreateComponent } from '#pages/admin/group-create/group-create.component';
import { MobileAppVersionComponent } from '#pages/admin/mobile-app-version/mobile-app-version.component';
import { OrganizationEditComponent } from '#pages/admin/organization-edit/organization-edit.component';
import { PssrKmsComponent } from '#pages/pssr/pssr-kms/pssr-kms.component';
import { FileResolver } from '#pages/shared/download/file.resolver';
import { DownloadComponent } from '#pages/shared/download/download.component';
import { SubcategoryComponent } from '#pages/admin/subcategory/subcategory.component';
import { SubcategoryCreateComponent } from '#pages/admin/subcategory-create/subcategory-create.component';
import { SubcategoryEditComponent } from '#pages/admin/subcategory-edit/subcategory-edit.component';
import { SubcategoryDeleteComponent } from '#pages/admin/subcategory-delete/subcategory-delete.component';
import { B2CLoginComponent } from '../app/b2c/b2c.component';
import { EnvironmentGuard } from '#services/guards/environment.guard';
import { BusinessUnitGuard } from '#services/guards/businessunit.guard';

const routes: Route[] = [
  // Home page and slash-appender
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: [Role.User] },
    component: HomeComponent,
  },

  // Pages
  {
    path: '',
    canActivate: [AuthGuard],
    data: { roles: [Role.User] },
    children: [
      { path: 'pssr-list', component: PssrListComponent },
      { path: 'pssr-create', component: PssrCreateComponent },
      { path: 'pssr-import', component: ImportComponent },
      { path: 'pssr-edit', component: PssrEditComponent },
      { path: 'pssr-action-item-list', component: PssrActionItemListComponent },
      { path: 'pssr-project', component: PssrProjectComponent },
      { path: 'pssr-project-detail', component: PssrProjectDetailComponent },
      {
        path: 'pssr-project-detail-copy-equip',
        component: PssrProjectDetailCopyEquipComponent,
      },
      { path: 'admin/feedback-create', component: FeedbackCreateComponent },
      { path: 'admin/feedback-edit', component: FeedbackEditComponent },
      { path: 'pssr-kms', component: PssrKmsComponent },
    ],
  },

  // Admin pages
  {
    path: 'admin',
    canActivate: [AuthGuard],
    data: {
      roles: [Role.OrganizationUser, Role.BusinessUnitAdmin, Role.SystemAdmin],
    },
    children: [
      // Equipment Tab
      {
        path: '',
        children: [
          { path: 'equipment', component: EquipmentComponent },
          { path: 'equipment-form', component: EquipmentFormComponent },
          { path: 'equipment-group', component: EquipmentTempGroupComponent },
          { path: 'end-device-type', component: EndDeviceTypeComponent },
          {
            path: 'end-device-type-form',
            component: EndDeviceTypeFormComponent,
          },
          { path: 'end-device', component: EndDeviceComponent },
          { path: 'end-device-form', component: EndDeviceFormComponent },
        ],
      },
      // User and AB Checklist
      {
        path: '',
        canActivate: [AuthGuard],
        data: {
          roles: [
            Role.OrganizationUser,
            Role.BusinessUnitAdmin,
            Role.SystemAdmin,
          ],
        },
        children: [
          { path: 'users', component: UserComponent },
          { path: 'user-create', component: UserCreateComponent },
          { path: 'user-edit', component: UserEditComponent },
        ],
      },
      // Task Plan
      {
        path: '',
        canActivate: [AuthGuard],
        data: { roles: [Role.BusinessUnitAdmin, Role.SystemAdmin] },
        children: [
          { path: 'task-plan', component: TaskPlanComponent },
          { path: 'task-plan-create', component: TaskPlanCreateComponent },
          { path: 'task-plan-edit', component: TaskPlanEditComponent },
          { path: 'task-plan-manage', component: TaskPlanManageComponent },
          { path: 'task-plan-delete', component: TaskPlanDeleteComponent },
          {
            path: 'task-plan-section-edit',
            component: TaskPlanSectionEditComponent,
          },
          {
            path: 'task-plan-section-delete',
            component: TaskPlanSectionDeleteComponent,
          },
          {
            path: 'task-plan-sub-section-edit',
            component: TaskPlanSubSectionEditComponent,
          },
          {
            path: 'task-plan-sub-section-delete',
            component: TaskPlanSubSectionDeleteComponent,
          },
          {
            path: 'task-plan-item-create',
            component: TaskPlanItemCreateComponent,
          },
          { path: 'task-plan-item-edit', component: TaskPlanItemEditComponent },
        ],
      },
      // Administration Tab
      {
        path: '',
        canActivate: [AuthGuard],
        data: { roles: [Role.BusinessUnitAdmin, Role.SystemAdmin] },
        children: [
          { path: 'project-create', component: ProjectCreateComponent },
          { path: 'project-edit', component: ProjectEditComponent },
          { path: 'area', component: AreaComponent },
          { path: 'area-create', component: AreaCreateComponent },
          { path: 'area-edit', component: AreaEditComponent },
          { path: 'area-delete', component: AreaDeleteComponent },
          { path: 'sub-area', component: SubAreaComponent },
          { path: 'sub-area-create', component: SubAreaCreateComponent },
          { path: 'sub-area-edit', component: SubAreaEditComponent },
          { path: 'sub-area-delete', component: SubAreaDeleteComponent },
          { path: 'facility', component: FacilityComponent },
          { path: 'facility-create', component: FacilityCreateComponent },
          { path: 'facility-edit', component: FacilityEditComponent },
          { path: 'facility-delete', component: FacilityDeleteComponent },
          { path: 'category', component: CategoryComponent },
          { path: 'category-create', component: CategoryCreateComponent },
          { path: 'category-edit', component: CategoryEditComponent },
          { path: 'category-delete', component: CategoryDeleteComponent },
          { path: 'business-unit-user', component: BusinessUnitUserComponent },
          { path: 'groups', component: GroupsComponent },
          { path: 'group-edit', component: GroupEditComponent },
          { path: 'group-create', component: GroupCreateComponent },
          { path: 'mobile-app-versions', component: MobileAppVersionComponent },
          {
            path: 'business-unit-edit', component: BusinessUnitEditComponent,
            canActivate: [BusinessUnitGuard]
          },
        ],
      },
      {
        path: '',
        canActivate: [AuthGuard],
        data: { roles: [Role.SystemAdmin] },
        children: [
          {
            path: 'business-unit-create',
            component: BusinessUnitCreateComponent,
          },
        ],
      },
      // System Admin Menu
      {
        path: '',
        canActivate: [AuthGuard],
        data: { roles: [Role.SystemAdmin] },
        children: [
          {
            path: 'system-admin',
            component: SystemAdminComponent,
          },
        ],
      },
      {
        path: '',
        canActivate: [AuthGuard],
        data: {
          roles: [Role.BusinessUnitAdmin, Role.SystemAdmin],
        },
        children: [
          { path: 'subcategory', component: SubcategoryComponent },
          { path: 'subcategory-create', component: SubcategoryCreateComponent },
          { path: 'subcategory-edit', component: SubcategoryEditComponent },
          { path: 'subcategory-delete', component: SubcategoryDeleteComponent },
        ],
      },
      {
        path: '',
        canActivate: [AuthGuard],
        data: {
          roles: [
            Role.SystemAdmin,
            Role.BusinessUnitAdmin,
            Role.OrganizationUser,
          ],
        },
        children: [
          {
            path: 'organizations',
            component: OrganizationComponent,
          },
          { path: 'organization-edit', component: OrganizationEditComponent },
        ],
      },
      {
        path: 'business-units',
        component: BusinessUnitsComponent,
        canActivate: [AuthGuard],
        data: { roles: [Role.BusinessUnitAdmin, Role.SystemAdmin] },
      },
    ],
  },

  // "Awaiting login" page
  {
    path: 'login',
    component: LoginComponent,
  },

  // b2c page
  {
    path: 'b2c',
    component: B2CLoginComponent,
    data: {
      environments: ['local', 'dev'],
    },
    canActivate: [EnvironmentGuard],
  },

  // feedback
  {
    path: 'feedback',
    component: FeedbackComponent,
  },

  // Error pages
  {
    path: 'error',
    children: [
      { path: 'not-found', component: NotFoundComponent },
      { path: 'internal-error', component: GenericErrorComponent },
      { path: 'not-authorized', component: NotAuthorizedComponent },
      { path: 'not-registered', component: NotRegisteredComponent },
    ],
  },

  {
    path: 'file/:pssrId/:fileId',
    component: DownloadComponent,
    resolve: {
      file: FileResolver,
    },
    outlet: 'download',
  },

  // Displays 404 "Not Found" error page for all unrecognized router.
  // Instead of redirecting, renders context to preserve original "incorrect" url
  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    PagesModule,
    AdminPagesModule,
    ErrorPagesModule,
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule { }
