import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { ProjectService } from '#services/api';
import { BusinessUnitsService } from '../../../services/api/business-unit.service';
import { EquipmentTemplateService } from '../../../services/api/equipment-template.service';

import { BusinessUnit, EquipmentTemplate } from '#models/index';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.scss'],
  providers: [ProjectService],
})
export class EquipmentComponent implements OnInit {
  //#region VARIABLES

  selectedBU: BusinessUnit;
  businessUnitList: BusinessUnit[];

  equipTempList: EquipmentTemplate[] = [];

  //#endregion

  constructor(
    private toastrService: ToastrService,
    public svcProject: ProjectService,
    private businessUnitsSvc: BusinessUnitsService,
    private equipTempService: EquipmentTemplateService,

    private router: Router
  ) {}

  ngOnInit() {
    this.loadBusinessUnits();
  }

  loadBusinessUnits(): void {
    const bu = this.businessUnitsSvc.getUserBUs().pipe(
      catchError((err) => {
        this.showMessage('Error retrieving Business Units', 1);
        return EMPTY;
      })
    );
    bu.subscribe((bus) => {
      if (bus.length) {
        this.businessUnitList = bus;
        this.selectedBU = this.businessUnitList[0];
        this.loadEquipmentsByBU();
      }
    });
  }

  loadEquipmentsByBU() {
    const equipTemp = this.equipTempService
      .getEquipmentTemplateByBU(this.selectedBU.Id)
      .pipe(
        catchError((err) => {
          this.showMessage('Error retrieving Equipment Templates', 1);
          return EMPTY;
        })
      );
    equipTemp.subscribe((equipTemps) => {
      this.equipTempList = equipTemps;
    });
  }

  onRedirectButtonClick(url: string, id: number, pId: number): void {
    this.router.navigate([url, { itemId: id, parentId: pId }]);
  }

  downloadTemplates() {
    const source = this.equipTempService
      .downloadEquipTemp(this.selectedBU)
      .pipe(
        tap(
          () => {
            // --
          },
          () => {
            this.toastrService.showError( 'Failed to download the excel file');
          }
        )
      );

    source.subscribe((file: Object) => {
      const fileName = 'EquipmentTemplates.xlsx';
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(file, fileName);
      } else {
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(file);
        link.download = fileName;
        link.click();
      }
    });
  }

  showMessage(info: string, dv: number) {
    if (dv === 1) {
      this.toastrService.showError( info);
    } else {
      this.toastrService.showSuccess( info);

    }
  }
}
