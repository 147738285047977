import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { BusinessUnit, EndDeviceType, ManufacturerModel } from '#models/index';

import { EndDeviceTypeService } from '#services/api/end-device-type.service';
import { ManufacturerModelService } from '../../../services/api/manufacturer-model.service';
import { BusinessUnitsService } from '#services/api';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-end-device-type-form',
  templateUrl: './end-device-type-form.component.html',
  styleUrls: ['./end-device-type-form.component.scss'],
})
export class EndDeviceTypeFormComponent implements OnInit {
  //#region VARIABLES

  endDeviceType: EndDeviceType = new EndDeviceType();
  endDeviceTypeId: number;

  canEdit = true;
  businessUnitId: number;

  manufModel: ManufacturerModel;
  manufModelList: ManufacturerModel[] = [];

  manufModEdit = false;
  showManufModPanel = false;
  showModal = false;

  selectedBU = new BusinessUnit();
  //#endregion

  constructor(
    private toastrService: ToastrService,
    public router: Router,

    public svcEndDeviceType: EndDeviceTypeService,
    private aRoute: ActivatedRoute,
    private manufModelService: ManufacturerModelService,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.endDeviceTypeId = this.aRoute.snapshot.params['itemId'];
    this.businessUnitId = this.aRoute.snapshot.params['parentId'];

    if (this.endDeviceTypeId > 0) {
      this.canEdit = false;
      this.loadEndDeviceType();
    } else {
      this.endDeviceType.IsSerialNumReq = true;
    }

    if (this.businessUnitId > 0) {
      this.businessUnitService
        .getBusinessUnitById(this.businessUnitId)
        .toPromise()
        .then((bu) => (this.selectedBU = bu));
    }
  }

  onSubmitEndDeviceType() {
    this.endDeviceType.BusinessUnitId = this.businessUnitId;
    this.endDeviceType.Description = this.endDeviceType.IsInEAM
      ? this.endDeviceType.Description
      : this.endDeviceType.Name;
    if (this.endDeviceType.Id > 0) {
      // Update
      const updDevType = this.svcEndDeviceType.update(this.endDeviceType);
      updDevType.subscribe((updType: EndDeviceType) => {
        this.endDeviceType = updType;
        this.showMessage('Device Type saved successfully', 0);
      }, (err) => {
        this.showMessage('Error saving End Device Type: ' + err.message, 1);

      });
    } else {
      // Create
      const createDev = this.svcEndDeviceType.create(this.endDeviceType);
      createDev.subscribe((newDev) => {
        this.endDeviceType = newDev;
        this.endDeviceTypeId = newDev.Id;
        this.showMessage('Device Type saved successfully', 0);
      }, (err) => {
        this.handleError(err, 'Error creating Device Type: ');

      });
    }
  }

  private loadEndDeviceType() {
    if (this.endDeviceTypeId > 0) {
      const endDevType = this.svcEndDeviceType
        .getEndDeviceTypeById(this.endDeviceTypeId);

      endDevType.subscribe((endDev) => {
        this.endDeviceType = endDev;
        this.loadManufModelByEndDevTypeId();
      }, (err) => {
        this.handleError(err, 'Error loading Device Type: ');

      });
    }
  }

  private loadManufModelByEndDevTypeId() {
    const manufMod = this.manufModelService
      .getByEndDevTypeId(this.endDeviceTypeId);

    manufMod.subscribe((manufModels) => {
      this.manufModelList = manufModels;
    }, (err) => {
      this.handleError(err, 'Error retrieving Manufacturer / Model: ');

    });
  }

  addEditManufModel(manufModSel?: ManufacturerModel) {
    if (manufModSel) {
      this.manufModel = manufModSel;
      this.manufModEdit = true;
    } else {
      this.manufModel = new ManufacturerModel();
      this.manufModEdit = false;
    }
    this.showManufModPanel = !this.showManufModPanel;
  }

  onSubmitManufMod() {
    this.manufModel.EndDeviceTypeId = this.endDeviceType.Id;
    // Update
    if (this.manufModEdit) {
      const updManuf = this.manufModelService.update(this.manufModel);
      updManuf.subscribe((updManufMod) => {
        const idx = this.manufModelList.findIndex(
          (x) => x.Id === updManufMod.Id
        );
        this.manufModelList.splice(idx, 1, updManufMod);
        this.showMessage('Information saved successfully', 0);
      }, (err) => {
        this.handleError(err, 'Error updating Manufacturer / Model: ');

      });
    } else {
      // Create
      const createManuf = this.manufModelService.create(this.manufModel);
      createManuf.subscribe((newManuf) => {
        this.manufModelList.push(newManuf);
        this.showMessage('Information saved successfully', 0);
      }, (err) => {
        this.handleError(err, 'Error creating Manufacturer / Model: ');

      });
    }

    this.manufModEdit = !this.manufModEdit;
    this.changeManufModPanelState();
  }

  showModalEliminateManMod(manufMod) {
    this.manufModel = manufMod;
  }

  deleteManufMod() {
    if (this.manufModel) {
      this.manufModelService
        .delete(this.manufModel.Id)

        .subscribe(
          () => {
            this.showMessage('Information Deleted Successfully', 0);
            this.loadManufModelByEndDevTypeId();
          },
          (error) => {
            if (error.status === 500) {
              // tslint:disable-next-line: max-line-length
              this.showMessage(
                'The selected Manufacturer - Model can not be deleted because is assigned in at least one Device Template',
                1
              );
            } else {
              this.showMessage(
                error.error.InnerException.InnerException.ExceptionMessage,
                1
              );
            }
          }
        );
    }
  }

  changeManufModPanelState() {
    this.showManufModPanel = !this.showManufModPanel;
  }

  private handleError(err: any, msg: string) {
    if (err.status === 500) {
      this.showMessage('The data already exist in the database.', 1);
    } else {
      this.showMessage(msg + err.message, 1);
    }
  }

  showMessage(info: string, dv: number) {
    if (dv === 1) {
      this.toastrService.showError(info);
    } else {
      this.toastrService.showSuccess(info);

    }
  }
}
