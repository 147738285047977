import {
  Component,
  forwardRef,
  Output,
  EventEmitter,
  OnDestroy,
  Input,
  OnInit,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormGroup,
  FormBuilder,
  Validators,
  ValidationErrors,
  ControlValueAccessor,
  Validator,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { TaskPlanSection } from '#models/task-plan-section';
import { DialogService } from '#services/shared/dialog.service';
import { Utility } from '#services/shared/utility';

@Component({
  selector: 'app-section-input-dialog',
  templateUrl: './section-input-dialog.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SectionInputDialogComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SectionInputDialogComponent),
      multi: true,
    },
  ],
})
export class SectionInputDialogComponent implements OnInit, ControlValueAccessor, Validator, OnDestroy {
  set prompt(value) {
    if (value) { this.dialogService.open(); } else { this.dialogService.close(); }
  }
  get prompt() {
    return this.dialogService.isOpen;
  }

  constructor(private dialogService: DialogService, private fb: FormBuilder) { }

  get errors() {
    let errors = {};
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (this.form.controls[key].errors !== null) {
          errors = { ...errors, [key]: this.form.controls[key].errors };
        }
      }
    }
    return errors;
  }
  private value = ''; // tslint:disable-line

  disabled = false;

  form: FormGroup;

  private destroyed$ = new Subject<boolean>();

  errorMessage = '';

  @Input()
  listOfDescriptions: string[] = [];

  @Input()
  isEditMode = false;

  @Input()
  sectionInput = new TaskPlanSection();

  @Output() saved = new EventEmitter();
  onChange = (input) => {
    // TODO implment
  }
  onTouched = () => {
    // TODO implment
  }

  ngOnInit() {
    this.form = this.fb.group({
      Description: [
        '',
        Validators.compose([
          Validators.required,
          this.validateRepeatedDescription,
        ]),
      ],
    });

    this.form.valueChanges
      .pipe(distinctUntilChanged(), takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (!this.form.valid) {
          this.errorMessage = this.getErrorMessage();
        }
        this.onChange(value.Description);
        this.onTouched();
      });

    this.form.patchValue(this.sectionInput);
  }

  validateRepeatedDescription = ({ value = '' }): ValidationErrors | null => {
    if (this.isEditMode && value && Utility.trimAll(value.toLowerCase()) === Utility.trimAll(this.sectionInput.Description.toLowerCase())) {
      return null;
    }
    const includes = this.listOfDescriptions.some(item => value && Utility.trimAll(item.toLowerCase()) === Utility.trimAll(value.toLowerCase()));
    if (includes) {
      return {
        repeated: true,
      };
    }

    return null;
  }

  async saveSection() {
    if (this.form.invalid) {
      this.errorMessage = this.getErrorMessage();
      setTimeout(() => {
        this.errorMessage = '';
      }, 5000);
      return;
    }
    this.disabled = true;

    this.close();
    this.saved.emit({
      ...this.sectionInput,
      ...this.form.value,
    });
  }

  getErrorMessage() {
    const msg = '';
    if (this.form.valid) {
      return '';
    }
    if (this.errors['Description'].required) { return 'Description is required'; }
    if (this.errors['Description'].repeated) {
      return 'Description input was already used';
    }
    return msg;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  writeValue(value) {
    this.value = value;
    this.form.setValue({
      Description: value,
    });
    this.onChange(value);
    this.onTouched();
  }

  open() {
    this.prompt = true;

    this.writeValue(this.sectionInput.Description);

    this.form.get('Description').updateValueAndValidity();
  }

  close() {
    this.prompt = false;
    this.errorMessage = '';
    this.value = '';
  }

  validate() {
    if (this.form.valid) {
      return null;
    }
    return {
      dialog: this.errors,
    };
  }
}
