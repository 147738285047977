<div class="box-shadow module">
  <h2>Equipment Template Groups</h2>

  <!-- BU Select -->
  <div
    class="row padding-bottom-10 component-separator"
    *ngIf="!showEquipTempPanel"
  >
    <hr />
    <div class="col-xs-6">
      <label>Business Unit :</label>
      <select
        class="form-control"
        [appBUSwitcher]="selectedBU"
        [(ngModel)]="selectedBU"
        (change)="loadEquipGroupByBU()"
      >
        <option *ngFor="let bu of businessUnitList" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
  </div>

  <!-- EquipmentTempGroup -->
  <div class="component-separator">
    <!-- EquipmentTempGroup Table -->
    <div
      class="form-horizontal component-separator"
      *ngIf="!showEquipTempPanel"
    >
      <table class="table table-hover table-responsive component-separator">
        <tr>
          <th scope="col" class="col-xs-3">Name</th>
          <th
            scope="col"
            class="col-xs-3"
            style="text-align: end; padding: 1px"
          >
            <button
              type="button"
              class="btn btn-success"
              (click)="addEditEquipGroup()"
              style="width: 80px"
            >
              New
            </button>
          </th>
        </tr>
        <tr *ngFor="let group of equipGroupList">
          <td class="col-xs-3">{{ group.Name }}</td>
          <td class="col-xs-3" style="text-align: end; padding: 1px">
            <a (click)="addEditEquipGroup(group)" style="cursor: pointer"
              ><strong>Edit</strong></a
            >
            <span>&nbsp;|&nbsp;</span>
            <a
              (click)="loadEquipmentsByBU(group)"
              style="cursor: pointer; color: dodgerblue"
            >
              <strong>Equipment Templates</strong></a
            >
            <span>&nbsp;|&nbsp;</span>
            <a
              style="cursor: pointer; color: red"
              data-toggle="modal"
              data-target="#modalDelete"
              (click)="selectEquipTempGroup(group)"
              ><strong>Delete</strong></a
            >
          </td>
        </tr>
      </table>
    </div>

    <!-- EquipmentTempGroup Add / Edit form -->
    <div class="component-separator" *ngIf="showGroupForm">
      <hr />
      <h3>
        <span *ngIf="groupEdit"
          >Edit
          <strong style="color: dodgerblue">{{
            equipGroup?.Name
          }}</strong></span
        ><span *ngIf="!groupEdit">New Group</span>
      </h3>
      <form (ngSubmit)="onSubmitGroup()" #groupForm="ngForm">
        <div class="form-horizontal">
          <div class="form-group">
            <label class="control-label col-md-2">Name</label>
            <div class="col-md-8">
              <input
                class="form-control text-box single-line"
                [(ngModel)]="equipGroup.Name"
                name="groupName"
                #groupName="ngModel"
                maxlength="155"
                placeholder="Group Name (max 155 characters)"
                required
              />
              <div class="row">
                <span
                  class="col-sm-8 text-danger field-validation-error"
                  *ngIf="!groupForm.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
                <span class="col-sm-4 charSpan"
                  >{{ equipGroup.Name?.length }} of 155</span
                >
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-offset-2 col-md-10">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!groupForm.form.valid"
              >
                <span *ngIf="groupEdit">Update</span>
                <span *ngIf="!groupEdit">Add</span> Group
              </button>
              <button
                type="button"
                (click)="changeGroupPanelState()"
                class="col-md-offset-1 btn btn-secondary"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
      <hr />
    </div>

    <!--Modal to Ask Delete EquipmentTempGroup-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1000"
      role="dialog"
      aria-labelledby="modalDeleted"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="modalCenterTitle">
              Delete Equipment Template Group
            </h4>
          </div>
          <div class="modal-body">
            <h4>
              Do you agree to Delete selected Equipment Template Group&#63;
            </h4>
            <h3>{{ selectedEquipTempGroup?.Name }}</h3>
            <h5 style="color: red">
              If exist some Equipments joined to this Group, these will be
              ungrouped
            </h5>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              (click)="deleteEquipGroup()"
              data-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- EquipmentTemplates -->
  <div *ngIf="showEquipTempPanel">
    <hr />
    <div
      class="component-separator"
      style="padding-right: 5%; padding-left: 5%"
    >
      <h3>
        <strong style="color: dodgerblue">{{ equipGroup?.Name }}</strong>
        Equipment Templates
      </h3>

      <table class="table table-hover">
        <tr class="row">
          <th
            scope="col"
            class="col-xs-9"
            style="text-align: start; padding: 1px"
          >
            <button
              type="button"
              class="btn btn-default"
              style="width: 150px"
              (click)="changeEquipTempPanelState()"
            >
              Back to Group List
            </button>
          </th>
          <th
            scope="col"
            class="col-xs-1"
            style="text-align: end; padding: 1px"
          >
            <button
              *ngIf="!allTempSelected"
              type="button"
              class="btn btn-info"
              (click)="selectAllTemplates()"
              style="width: 100px"
            >
              Select All
            </button>
            <button
              *ngIf="allTempSelected"
              type="button"
              class="btn btn-info"
              (click)="selectAllTemplates()"
              style="width: 100px"
            >
              Deselect All
            </button>
          </th>
          <th
            scope="col"
            class="col-xs-1"
            style="text-align: end; padding: 1px"
          >
            <button
              type="button"
              class="btn btn-primary"
              (click)="saveEquipTemplSelected()"
              style="width: 100px"
              [disabled]="!selectedEquipTemp.length"
            >
              Save
            </button>
          </th>
          <th
            scope="col"
            class="col-xs-1"
            style="text-align: end; padding: 1px"
          >
            <button
              type="button"
              class="btn btn-danger"
              (click)="changeEquipTempPanelState()"
              style="width: 100px"
            >
              Cancel
            </button>
          </th>
        </tr>
        <tr class="row" *ngFor="let equipTemp of equipTempList">
          <td class="col-xs-9">
            <div class="ui-g-12">
              <p-checkbox
                name="equipTemps"
                [value]="equipTemp"
                [label]="equipTemp?.Name"
                [(ngModel)]="selectedEquipTemp"
                inputId="equipTemp"
              ></p-checkbox>
            </div>
          </td>
          <td class="col-xs-1"></td>
          <td class="col-xs-1"></td>
          <td class="col-xs-1"></td>
        </tr>
      </table>
    </div>
    <div class="row component-separator">
      <div class="col-xs-6 form-group text-center">
        <button
          type="button"
          class="btn btn-primary"
          style="width: 30%"
          (click)="saveEquipTemplSelected()"
          [disabled]="!selectedEquipTemp.length"
        >
          Save
        </button>
      </div>
      <div class="col-xs-6 form-group text-center">
        <button
          type="button"
          class="btn btn-danger"
          style="width: 30%"
          (click)="changeEquipTempPanelState()"
        >
          Cancel
        </button>
      </div>
    </div>
    <hr />
  </div>
</div>
