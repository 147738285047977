import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Project, ProjectStatus } from '../../../models/project';
import { UserUiRole } from '#models/user-ui-role';
import { ProjectUser } from '../../../models/project-user';
import { User } from '../../../models/user';
import { OtherParticipantType3 } from '../../../models/other-participant-type3';
import { UserService } from '../../../services/api/user.service';

import { ProjectService } from '../../../services/api/project.service';
import { UserType } from '#models/enum/user-type';
import { Utility } from '#services/shared/utility';
import { ToastrService } from '#services/shared/toastr.service';
import { ParticipantType } from '#models/enum/participant-type';


@Component({
  selector: 'app-project-members',
  templateUrl: './project-members.component.html',
  styleUrls: ['./project-members.component.scss'],
})
export class ProjectMembersComponent implements OnInit {
  //#region VARIABLES

  ParticipantType = ParticipantType;

  @Input() project: Project;
  @Output() projUserUpd = new EventEmitter<boolean>();

  roles: UserUiRole[] = [];
  nonChevronUsers: User[] = [];
  possibleSelectedUser: User[] = [];
  possibleParticipantId: User[] = [];
  possibleOtherParticipant: OtherParticipantType3[];
  participants: User[] = [];
  participantList: ProjectUser[] = [];

  selectedUser: User;
  participantToEdit: ProjectUser;

  selectedRole: string;
  otherParticipant = new OtherParticipantType3();
  message: string;
  maxMessage = '';
  maxMessageWarning = 'Only 5 users can be added at a time';
  maxMessageLimit = '5 user limit reached, please select add';
  participateMessageWarning = 'This user is already a member of this Project';
  selectedRoleId: number;
  participantType: ParticipantType;
  participantId: number;

  allowMulti = true;
  displayAddModal = false;
  displayEditModal = false;
  displayChev = false;
  displayENonChev = false;
  displayNNonChev = false;
  displayMain = false;
  disableAdd = true;
  displayNull = true;
  disablePartic: boolean;

  //#endregion

  constructor(
    private toastrService: ToastrService,
    public userService: UserService,

    private projectService: ProjectService
  ) { }

  async ngOnInit() {
    this.participantToEdit = new ProjectUser();
    this.participantId = 0;
    this.participantType = ParticipantType.ChevronOrSharedUser; // chevron
    this.possibleSelectedUser = [];
    this.setRoles();
    await this.getUsersByOrganization();
  }

  setRoles() {
    this.disablePartic =
      this.project.Status === ProjectStatus.Closed;
    this.roles = [
      {
        RoleId: 1,
        RoleName: 'Project Manager',
        RoleLabel: 'Project Managers',
        Disabled: this.disablePartic,
      },
      {
        RoleId: 2,
        RoleName: 'Project Member',
        RoleLabel: 'Project Team Members',
        Disabled: this.disablePartic,
      },
      {
        RoleId: 3,
        RoleName: 'Operations Representative',
        RoleLabel: 'Operations Representative',
        Disabled: this.disablePartic,
      },
      {
        RoleId: 4,
        RoleName: 'Final Approver',
        RoleLabel: 'Final Approver',
        Disabled: this.disablePartic,
      },
    ];
  }

  openAddParticipant(role: UserUiRole) {
    this.maxMessage = '';
    this.possibleSelectedUser = [];
    this.possibleOtherParticipant = [];
    this.possibleParticipantId = [];
    this.displayChev = false;
    this.displayENonChev = false;
    this.displayNNonChev = false;
    this.allowMulti = true;
    this.displayMain = true;
    this.displayNull = true;
    this.displayAddModal = true;
    this.selectedRole = role.RoleLabel;
    this.selectedRoleId = role.RoleId;
    this.selectedUser = null;

    this.otherParticipant.Name = '';
    this.otherParticipant.Email = '';
  }

  openEditParticipant(user: ProjectUser) {
    const copy = JSON.parse(JSON.stringify(user));
    this.participantToEdit = copy;
    this.displayEditModal = true;
  }

  async suggestParticipants(event: { query: any; }): Promise<void> {
    try {
      const query = event.query;
      this.participants = await this.userService.searchUser(query).toPromise();

      this.participants.forEach((user) => {
        user.FullName = Utility.formatUser(user);
      });

    } catch (e) {
      console.error(e);
    }
  }

  addNonChevronUserById(userId: number): void {
    if (this.possibleParticipantId.length >= 5) {
      this.maxMessage = this.maxMessageLimit;
      return;
    }
    if (userId === null) {
      return;
    }
    let tempUser: User;
    tempUser = this.nonChevronUsers.find(({ ID }) => ID === userId);

    if (this.possibleParticipantId.some((item) => item.ID === userId)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    if (this.project.ProjectUsers.Users.some((val) => val.UserID === userId)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    this.disableAdd = true;


    this.possibleParticipantId.push(tempUser);

    if (this.displayMain) {
      this.displayChev = true;
      this.displayNNonChev = true;
      this.maxMessage = this.maxMessageWarning;
    }

    this.disableAdd = false;
  }

  addChevronUser(entry: User): void {
    if (this.possibleSelectedUser.length >= 5) {
      this.maxMessage = this.maxMessageLimit;
      return;
    }

    if (this.possibleSelectedUser.some((item) => item.CAI === entry.CAI)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    if (
      this.project.ProjectUsers.Users.some(
        (item) => item.UserEmail === entry.Email
      )
    ) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    this.disableAdd = true;

    this.possibleSelectedUser.push(entry);

    if (this.displayMain) {
      this.displayENonChev = true;
      this.displayNNonChev = true;
      this.maxMessage = this.maxMessageWarning;
    }

    if (this.displayNull) {
      this.selectedUser = null;
    }

    this.disableAdd = false;
  }

  addExternalUser() {
    if (this.possibleOtherParticipant.length >= 5) {
      this.maxMessage = this.maxMessageLimit;
      return;
    }

    if (this.possibleOtherParticipant.some((item) => item.Name === this.otherParticipant.Name)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    if (
      this.project.ProjectUsers.Users.some(
        (item) => item.UserEmail === this.otherParticipant.Email ||
          item.UserName === this.otherParticipant.Name
      )
    ) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    this.disableAdd = true;

    this.possibleOtherParticipant.push({
      Name: this.otherParticipant.Name,
      Email: this.otherParticipant.Email
    });

    if (this.displayMain) {
      this.displayChev = true;
      this.displayENonChev = true;

      this.maxMessage = this.maxMessageWarning;
    }

    if (this.displayNull) {
      this.otherParticipant.Name = '';
      this.otherParticipant.Email = '';
    }

    this.disableAdd = false;

  }

  async addParticipant() {
    this.displayAddModal = false;
    this.participantList = [];
    let temp: ProjectUser;

    switch (this.participantType) {
      // TODO participantType should represent an enum type
      case ParticipantType.NonChevronUser:
        for (let i = 0; i < this.possibleParticipantId.length; i++) {
          temp = new ProjectUser();
          temp.ProjectID = this.project.Id;
          temp.Role = this.selectedRoleId;
          temp.UserID = this.possibleParticipantId[i].ID;
          this.participantList.push(temp);
        }
        break;

      case ParticipantType.ChevronOrSharedUser:
        for (let i = 0; i < this.possibleSelectedUser.length; i++) {
          temp = new ProjectUser();
          temp.ProjectID = this.project.Id;
          temp.Role = this.selectedRoleId;
          temp.UserEmail = this.possibleSelectedUser[i].Email;
          this.participantList.push(temp);
        }
        break;

      default:
        for (let i = 0; i < this.possibleOtherParticipant.length; i++) {
          temp = new ProjectUser();
          temp.ProjectID = this.project.Id;
          temp.Role = this.selectedRoleId;
          temp.UserEmail = this.possibleOtherParticipant[i].Email;
          temp.UserName = this.possibleOtherParticipant[i].Name;
          this.participantList.push(temp);
        }
        break;
    }

    try {
      const newPart = await this.projectService
        .addParticipant(this.participantList)
        .toPromise();

      this.participantList = [];

      for (let i = 0; i < newPart.Users.length; i += 1) {
        this.project.ProjectUsers.Users.push(newPart.Users[i]);
      }

      if (newPart.Users.length > 0) {
        this.projUserUpd.emit(true);
      }
      if (newPart.ResultModel && newPart.ResultModel.Success) {
        this.showMessage('User(s) added succesfully to Project', 0);
      }
    } catch (err) {
      this.showMessage('Error Adding Project Participants: ' + err, 1);
    }
  }

  removePossibleParticipant(entry: User | OtherParticipantType3): void {

    switch (this.participantType) {
      case ParticipantType.NonChevronUser:
        {
          const index: number = this.possibleParticipantId.findIndex(r => r.ID == (entry as User).ID);

          if (index !== -1) {
            this.possibleParticipantId = this.possibleParticipantId.filter((_, i) => i !== index);
          }

          if (this.possibleParticipantId.length === 0) {
            this.displayENonChev = false;
            this.displayNNonChev = false;
            this.displayChev = false;
            this.disableAdd = true;

            this.maxMessage = '';

            return;
          }
        }
        break;
      case ParticipantType.ChevronOrSharedUser:
        {
          const index: number = this.possibleSelectedUser.findIndex(selectedUser => selectedUser.ID === (entry as User).ID);
          if (index !== -1) {
            this.possibleSelectedUser = this.possibleSelectedUser.filter((_, i) => i !== index);
          }
          if (this.possibleSelectedUser.length === 0) {
            this.displayENonChev = false;
            this.displayNNonChev = false;
            this.displayChev = false;
            this.disableAdd = true;

            this.maxMessage = '';

            return;
          }
        }
        break;
      case ParticipantType.External: {
        const index: number = this.possibleOtherParticipant.findIndex(p => p.Email === (entry as OtherParticipantType3).Email || p.Name === (entry as OtherParticipantType3).Name);

        if (index !== -1) {
          this.possibleOtherParticipant = this.possibleOtherParticipant.filter((_, i) => i !== index);
        }

        if (this.possibleOtherParticipant.length === 0) {
          this.displayENonChev = false;
          this.displayNNonChev = false;
          this.displayChev = false;
          this.disableAdd = true;

          this.maxMessage = '';
          return;
        }
      } break;
    }

    this.maxMessage = this.maxMessageWarning;
  }

  async removeParticipant(participant: ProjectUser) {
    this.displayEditModal = false;

    try {
      await this.projectService.removeParticipant(participant.Id)
        .toPromise();

      const usrIx = this.project.ProjectUsers.Users.findIndex(
        (x) => x.UserID === participant.UserID
      );
      if (usrIx > -1) {
        this.project.ProjectUsers.Users.splice(usrIx, 1);
        this.projUserUpd.emit(true);
        this.showMessage('Participant removed successfully', 0);
      }
    } catch (err) {
      this.showMessage('Error removing Project Participant: ' + err, 1);
    }
  }

  removeCancel() {
    this.displayEditModal = false;
  }

  showMessage(message: string, severity: number): void {
    if (severity === 1) {
      this.toastrService.showError(message);

    } else {
      this.toastrService.showSuccess(message);
    }
  }

  async getUsersByOrganization() {
    let data: User[] = [];

    try {
      data = await this.userService
        .getUsersByOrg(this.project.OrganizationId)
        .toPromise();

    } catch (e) {
      console.error(e);
    } finally {
      const usrs: User[] = data.filter((u) => u.Type !== UserType.Chevron);
      this.nonChevronUsers = usrs.sort((one, two) =>
        one.FirstName.toUpperCase() < two.FirstName.toUpperCase() ? -1 : 1
      );
    }
  }
}
