export class PaginationSettings {
  count: number;
  totalCount: number;
  pageNumber: number;
  pageSize: number;
  currentRange: string;

  constructor(pageSize: number = 10) {
    this.count = 0;
    this.totalCount = 0;
    this.pageNumber = 1;
    this.pageSize = pageSize;
  }

  private recalcCurrentRange() {
    const pages = Math.ceil(this.totalCount / this.pageNumber);

    let currentRangeStart = (!!this.pageNumber ? this.pageNumber - 1 : 0) * this.pageSize;
    if (currentRangeStart > 0) {
      currentRangeStart++;
    }

    const currentRangeEnd = this.pageNumber === pages
      ? this.totalCount
      : currentRangeStart + this.pageSize;

    this.currentRange = `${currentRangeStart} - ${currentRangeEnd} of ${this.totalCount}`;
  }

  reset() {
    this.count = 0;
    this.totalCount = 0;
    this.pageNumber = 1;
  }

  updateCounters(count: number, totalCount: number) {
    this.count = count;
    this.totalCount = totalCount;

    this.recalcCurrentRange();
  }
}
