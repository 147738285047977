<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px" [appBUSwitcher]="selectedBU">

    <h2>{{'Attention'|translate}}!</h2>
    <hr>
    <h3>{{confirmDeleteSubAreaTitle}} : <span style="background-color: yellow;">{{subArea.Name}}</span> ?</h3>
    <h4>{{confirmDeleteSubAreaDescription}}.</h4>
    <h3><a class="btn btn-danger" style="width: 100px;" (click)="deleteSubArea()">{{'Delete'|translate}}</a></h3>
</div>

<div style="width:80%; margin:auto">
    <a (click)="onRedirectButtonClick()" style="cursor: pointer;">{{'Back to'|translate}} {{hierarchyLabel2}}</a>
</div>
