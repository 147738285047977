import { Component } from '@angular/core';

declare const __APP_VERSION__: string;

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent {
  appVersion = __APP_VERSION__;
}
