<div class="box-shadow module">
  <div class="row">
    <h2>Devices List
    </h2>
    <h3 *ngIf="equipment">Equipment: <strong>{{equipment.Name}}</strong></h3>
  </div>
  <div>
  </div>


 
  <app-end-device-list [parentID]="equipmentId" [buId]="buId"></app-end-device-list>

  <div class="row component-separator">
    <button type="button" class="btn btn-default" (click)="backEquipmentList()">Back to List</button>
  </div>

</div>
