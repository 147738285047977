import { Component, OnInit } from '@angular/core';
import { SubAreaService } from '#services/api/sub-area.service';
import { SubArea } from '#models/sub-area';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';


import { ToastrService } from '#services/shared/toastr.service';


@Component({
  selector: 'app-sub-area-edit',
  templateUrl: './sub-area-edit.component.html',
})
export class SubAreaEditComponent implements OnInit {
  subArea: SubArea = new SubArea();
  subAreaName: string;
  displayWarningName: boolean;
  buId: number;
  hierarchyLabel2: string;



  selectedBU: BusinessUnit;

  constructor(
    private toastrService: ToastrService,
    private subAreaSvc: SubAreaService,

    private router: Router,
    private route: ActivatedRoute,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.buId = this.route.snapshot.params['buId'];
    this.subArea.Id = this.route.snapshot.params['subAreaId'];
    this.subArea.AreaId = this.route.snapshot.params['areaId'];
    this.hierarchyLabel2 = this.route.snapshot.params['hierarchyLabel2'];

    this.getSubAreaById(this.subArea.Id);
    this.displayWarningName = false;

    if (this.buId > 0) {
      this.businessUnitService
        .getBusinessUnitById(this.buId)
        .toPromise()
        .then((bu) => (this.selectedBU = bu));
    }
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/sub-area',
      { buId: this.buId, areaId: this.subArea.AreaId },
    ]);
  }

  getSubAreaById(id: number): void {

    this.subAreaSvc
      .getSubAreaById(id)

      .subscribe(
        (data: SubArea) => {
          this.subArea = data;
          this.subAreaName = this.subArea.Name;
        },
        (error) => {
          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }

  updateSubArea(): void {
    this.displayWarningName =
      this.subArea.Name == undefined ||
      this.subArea.Name == null ||
      this.subArea.Name.length == 0;
    if (!this.displayWarningName) {

      this.subAreaSvc
        .updateSubArea(this.subArea)

        .subscribe(
          (data: any) => {
            this.onRedirectButtonClick();
          },
          (error) => {
            this.toastrService.showError( error.error.ExceptionMessage);
          }
        );
    }
  }
}
