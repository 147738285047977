
<ng-container *ngIf="loadingCompleted">

  <div class="box-shadow module" *ngIf="inEditMode">
    <div class="row">
      <div class="col-md-12">
        <h3 class="text-left">
          {{'Sections' | translate}} <br />
          <small>{{'Please select any option that apply to the scope of change and template' | translate}}.</small>
        </h3>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3" *ngFor="let section of bodySections">
        <p-card>
          <button type="button" class="btn btn-primary" [disabled]="isSectionActive(section)"
            (click)="tryAddMultipleSection(section) && addSection(section)">
            {{'Add' | translate}}
          </button>
          <label> &nbsp;{{ section.Description | translate }}</label>
        </p-card>
      </div>
    </div>
  </div>

  <div class="sections-list">
    <div class="clearfix" style="padding: 10px 20px;" *ngIf="pssr.IsSectionOrderEnabled && (pssr.CurrentUser.CanEdit || isSystemAdmin || pssr.CurrentUser.IsBusinessUnitAdmin || pssr.CurrentUser.IsOrgAdmin) && pssr.Status &lt;= 3">
      <div class="pull-left text-left alert alert-warning alert-dismissible" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        {{'Sort changes will be applied after hit "Save custom order" button'|translate}}
      </div>
      <button class="pull-right btn btn-primary" (click)="saveCustomSortOrder($event)">{{'Save custom order'|translate}}</button>
    </div>
    <div class="box-shadow module" cdkDropList (cdkDropListDropped)="drop($event)">
      <div class="row" *ngIf="!canRespond && pssr.Status &gt; 1 && pssr.Status &lt; 4"
        class="alert alert-warning text-align" role="alert">
        <strong>{{'Note' | translate}} :&nbsp;</strong>{{'Only Team Lead or Section Lead can answer the questions' | translate}}.
      </div>
      <div class="row" *ngIf="hasEmptySections" class="alert alert-warning" role="alert">
        <strong>{{'Note'|translate}}:</strong>&nbsp; {{'You should not have empty sections' | translate}}
      </div>

      <ng-container *ngFor="let section of sections; let i = index">
        <div class="drag-item" *ngIf="canSwitchOrder(section)" cdkDragBoundary=".sections-list" cdkDrag>
          <app-pssr-edit-items-section
            (sortChanged)="onSortChanged($event)"
            [maxSections]="maxSections"
            [index]="i"
            [pssr]="pssr"
            [groups]="groups"
            [section]="section"
            [members]="members"
            [pssrFiles]="pssrFiles"
            (updatedSection)="sectionUpdated()"
            (onRemoveSection)="removeSection($event)">
          </app-pssr-edit-items-section>

          <div cdkDragHandle class="drag-handle btn btn-default">
            <span class="glyphicon glyphicon-move"></span>
          </div>

          <div class="drag-item-placeholder" *cdkDragPlaceholder></div>
        </div>

        <app-pssr-edit-items-section *ngIf="!canSwitchOrder(section)"
          (sortChanged)="onSortChanged($event)"
          [maxSections]="maxSections"
          [index]="i"
          [pssr]="pssr"
          [groups]="groups"
          [section]="section"
          [members]="members"
          [pssrFiles]="pssrFiles"
          (updatedSection)="sectionUpdated()"
          (onRemoveSection)="removeSection($event)">
        </app-pssr-edit-items-section>

        <div class="warning-message" *ngIf="isAddedInProgress(section.ID)">
          {{'ManageSectionUsersSyncWarning'|translate}}
        </div>
      </ng-container>

    </div>
  </div>
</ng-container>

<app-section-input-dialog
  *ngIf="newSectionInput"
  [listOfDescriptions]="listOfDescriptions"
  (saved)="addSection($event)"
  [sectionInput]="newSectionInput"
  [(ngModel)]="newSectionInput.Description"
></app-section-input-dialog>
