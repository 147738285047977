import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  SimpleChange,
  OnChanges,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  NG_VALIDATORS,
  Validator,
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import { DialogService } from '../../../../services/shared/dialog.service';
import { ResponseItemStatus } from '../../../../models/response-item-status';
import { BusinessUnit } from '../../../../models/business-unit';
import { PSSR } from '../../../../models/pssr';
import { Group, PSSRFile } from '../../../../models/';
import {
  UserService,
  PSSRResponseService,
  BusinessUnitsService,
} from '../../../../services/api';
import { User } from '../../../../models/';
import { UserRole } from '../../../../models/enum/user-role';
import { Observable, combineLatest, Subject, BehaviorSubject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ActionItemDialogService } from './action-item-dialog.service';
import { FilesFormComponent } from '../../files-form/files-form.component';
import { FilesFormService } from '../../files-form/files-form.service';
import { ResponseItem } from '#models/response-item';
import { Utility } from '#services/shared/utility';
import { ConfirmationService } from 'primeng/api';
import { TranslationHistoryService } from '#services/api/translation-history.service';
import { TranslationHistory } from '#models/translation-history';
import { TranslationsStorageService } from '#services/shared/translations-storage.service';
import { TranslationRequestService } from '#services/api/translation-request.service';
import { PSSRStatus } from '#models/pssr-status';
import { TranslatePipe } from '@ngx-translate/core';
import { ActionEditType } from '#models/enum/action-edit-type';

@Component({
  selector: 'app-action-item-dialog',
  templateUrl: './action-item-dialog.component.html',
  providers: [
    TranslatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ActionItemDialogComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ActionItemDialogComponent,
      multi: true,
    },
    DialogService,
    ActionItemDialogService,
    FilesFormService,
  ],
  styles: [
    `
      .radio-toolbar input[type="radio"] {
        display: none;
      }

      .radio-toolbar label {
        display: inline-block;
        padding: 4px 11px;
        cursor: pointer;
        color: darkgray;
        font-weight: lighter;
      }

      .radio-toolbar input[type="radio"]:checked + label {
        font-weight: bold;
        color: black;
      }

      .disabledColor {
        color: darkgray;
      }

      .normalItem {
        background-color: white !important;
        transition: 1s ease-in-out all;
      }

      .modifiedItem {
        background-color: rgb(190, 255, 190) !important;
      }

      .p3Class {
        background-color: rgb(208, 2, 27);
        border-radius: 1em;
        color: white;
        text-align: center;
        font-weight: normal !important;
        padding-left: 10px;
        padding-right: 10px;
      }

      .p4Class {
        background-color: rgb(250, 171, 24);
        border-radius: 1em;
        color: white;
        text-align: center;
        font-weight: normal !important;
        padding-left: 10px;
        padding-right: 10px;
      }

      .p5Class {
        background-color: rgb(128, 128, 128);
        border-radius: 1em;
        color: white;
        text-align: center;
        font-weight: normal !important;
        padding-left: 10px;
        padding-right: 10px;
      }
      .status {
        word-break: break-word;
      }

      .whiteSpaceBtn {
        white-space: pre-wrap !important;
      }

      .is-attachment {
        text-align: center;
      }
    `,
  ],
})
export class ActionItemDialogComponent
  implements OnChanges, OnDestroy, ControlValueAccessor, Validator {
  @Input()
  set prompt(value) {
    if (this.actionType == ActionEditType.Translate) {
      this.actionLabel = 'Translate Action Item';
    } else {
      this.actionLabel = 'Configure Action Item';
    }

    if (value) {
      this.dialogService.open();
    } else {
      this.dialogService.close();
    }
  }
  get prompt() {
    return this.dialogService.isOpen;
  }

  get allowMembersToCompletePSSR() {
    return this.businessUnitService.allowMembersToCompletePSSR(
      this.pssr.buObj,
      this.pssr
    );
  }

  private get isAdmin(): boolean {
    return (
      !this.onlyAttachments &&
      (this.pssr.CurrentUser.CanEditAction ||
        this.pssr.CurrentUser.CanAddItem ||
        this.canAnswer ||
        this.isSectionLead)
    );
  }

  private get isLimitAdmin(): boolean {
    return (
      (this.selectedBU.LimitAccessToAIOwner &&
        this.pssr.Status > PSSRStatus.Completed) ||
      (this.selectedBU.AllowTeamLeadToAttach &&
        this.pssr.Status > PSSRStatus.Completed)
    );
  }

  get value() {
    return this.form.value;
  }

  set value(value) {
    this.form.patchValue(value);

    this.onChange(value);
    this.onTouched();
  }

  get errors() {
    let errors = null;
    for (const key in this.form.controls) {
      if (this.form.controls[key].errors !== null) {
        errors = { ...errors, [key]: this.form.controls[key].errors };
      }
    }
    if (this.form.errors) {
      errors = { ...errors, ...this.form.errors };
    }
    return errors;
  }

  get isLead() {
    return (
      this.pssr.CurrentUser &&
      (this.pssr.CurrentUser.Role === UserRole.Lead ||
        this.pssr.CurrentUser.Role === UserRole.Approver)
    );
  }

  constructor(
    private fb: FormBuilder,
    private dialogService: DialogService,
    private userService: UserService,
    private responseSvc: PSSRResponseService,

    private businessUnitService: BusinessUnitsService,
    private actionItemDialogService: ActionItemDialogService,
    private filesFormService: FilesFormService,
    private confirmationSvc: ConfirmationService,
    private translationHistoryService: TranslationHistoryService,
    private translationRequestService: TranslationRequestService,
    private translationsStorageService: TranslationsStorageService,
    private translatePipe: TranslatePipe,
    private confirmationService: ConfirmationService
  ) {
    this.form = this.fb.group({
      Action: ['', Validators.required],
      ActionID: null,
      ExternalId: null,
      AssignedGroupID: null,
      AssignedTo: '',
      AssignedCAI: '',
      AssignedGroupName: '',
      DueDate: null,
      Status: [
        0,
        Validators.compose([
          Validators.min(ResponseItemStatus.Open),
          Validators.max(ResponseItemStatus.Rejected),
        ]),
      ],
      StatusName: 'None',
      Priority: [0, Validators.compose([Validators.min(3), Validators.max(5)])],
      CreateDate: new Date(),
      CreatedBy: null,
      ClosedBy: null,
      ClosedOn: null,
      CanRejectItem: false,
      CanEditItem: false,
      CanReOpenItem: false,
      IsActionItem: true,
      ResponseID: '',
      Id: 0,
      GUID: '',
      PSSRID: [0, Validators.min(1)],
      OrganizationId: 0,
      Comment: '',
      AssignedUserID: 0,
      AfterWalkthrough: false,
      RejectReason: null,
      AssignedText: '',
      CategoryID: 0,
      SubCategoryID: 0,
      UpdatedDate: new Date(),
      UpdatedBy: null,
      Links: [],
      CurrentUserIsActionItemAssignee: false,
    });

    this.form.setValidators(
      Validators.compose([this.validateStatus, this.validateDueDate])
    );

    this.assignToForm = this.fb.group({
      assignToType: 1,
      selectedUser: null,
      selectedCategory: null,
      selectedSubCategory: null,
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((input) => {
        this.onChange(input);
        this.onTouched();
      });

    this.isDisabledForm$ = combineLatest([
      this.form.valueChanges,
      this.assignToForm.valueChanges,
      this.submitted$,
    ]).pipe(
      map(
        ([actionItem, { assignToType, selectedUser }, submitted]) =>
          submitted ||
          (assignToType == 3 && !actionItem.AssignedText) ||
          (assignToType == 2 && !selectedUser) ||
          (assignToType == 4 && !actionItem.AssignedGroupID)
      )
    );
  }

  get canTranslate(): boolean {
    if (!this.pssr.translationRequestList) {
      return false;
    }

    return (
      this.pssr.CurrentUser.CanTranslate && this.translationRequests.length > 0
    );
  }

  private get translationRequests() {
    return this.pssr.translationRequestList.filter(
      (r) =>
        r.TranslatorEmail === this.pssr.CurrentUser.UserEmail ||
        this.pssr.CurrentUser.ParentUsers.includes(r.TranslatorEmail)
    );
  }
  @ViewChild(FilesFormComponent) filesForm: FilesFormComponent;
  actionLabel = '';
  @Input() isSectionLead = false;
  @Input() pssr = new PSSR();
  @Input() isStandAlone = false;
  @Input() groups: Group[] = [];
  @Input() canAnswer = false;
  @Input() pssrFiles: PSSRFile[] = [];
  @Input() onlyAttachments = false;
  @Input() actionType = ActionEditType.Edit;

  @Output() saved = new EventEmitter();

  readonlyAction = false;

  canEditAction = false;
  canAttach = false;
  canEditPriority = false;
  canAddP3 = false;
  canAssignTo = false;
  canChangeCategories = false;
  canChangeStatus = false;
  canChangeDueDate = false;

  assignedToRO: string;
  statusNameRO: string;
  isAssignee = false;
  canDelete = false;

  form: FormGroup;
  assignToForm: FormGroup;

  errorMessage = '';
  previousAction = '';
  previousComment = '';
  participants: User[] = [];
  queryParticipant = '';
  selectedBU = new BusinessUnit();
  selectedGroup = new Group();

  private destroyed$ = new Subject<boolean>();
  private submitted$ = new BehaviorSubject<boolean>(false);
  isDisabledForm$: Observable<boolean>;

  UserRole = UserRole;
  initialPriorityValue: any;

  ngOnChanges(simpleChanges: {
    [T in keyof ActionItemDialogComponent]: SimpleChange;
  }) {
    if (
      simpleChanges.hasOwnProperty('pssr') &&
      simpleChanges.pssr.currentValue.hasOwnProperty('BusinessUnitID')
    ) {
      this.selectedBU = this.pssr.buObj;
      this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
    }
  }

  onInit() {
    const isNew = !Utility.isValidObj(this.value.Id);
    this.assignedToRO = this.value.AssignedTo;
    this.statusNameRO = this.value.StatusName;
    this.canUpdateAssignee();
    this.canUpdateAttachments();

    const isAdmin = this.isAdmin;
    const isLimitAdmin = this.isLimitAdmin;

    // this.pssr.CurrentUser.CanEditAction = LEAD || FA || BUADMIN || ORGADMIN
    // this.pssr.CurrentUser.CanAddItem = LEAD || FA || BUADMIN || ORGADMIN && (isCompleted || isPendingApprival)

    this.updateCanEditAction(isNew, isAdmin, isLimitAdmin);
    this.canAttach = this.pssr.Status <= PSSRStatus.Approved;
    this.updateCanEditPriority(isNew, isAdmin, isLimitAdmin);
    this.updateCanAddP3(isAdmin, isLimitAdmin);
    this.updateCanAssignTo(isNew, isAdmin);
    this.updateCanChangeCategories(isNew, isAdmin, isLimitAdmin);
    this.updateCanChangeStatus(isNew, isAdmin);
    this.updateCanChangeDueDate(isNew, isAdmin, isLimitAdmin);
    this.updateCanDelete(isAdmin, isLimitAdmin);

    this.initialPriorityValue = this.form.get('Priority').value;
  }

  onChange = (value) => {
    // TODO implement
  }
  onTouched = () => {
    // TODO implement
  }

  validateStatus = ({ value: { Comment, RejectReason, Status: status } }) => {
    if (status == ResponseItemStatus.Rejected && !RejectReason) {
      return {
        Status: {
          rejectReasonIsRequired: this.translatePipe.transform(
            'Reject reason is Required'
          ),
        },
      };
    }
    if (status == ResponseItemStatus.Closed && !Comment) {
      return {
        Status: {
          closedReasonIsRequired: this.translatePipe.transform(
            'Comment is required'
          ),
        },
      };
    }

    return null;
  }

  validateDueDate = ({ value: { DueDate = null } }) => {
    if (!DueDate && this.selectedBU.RequireActionItemDueDate) {
      return {
        DueDate: {
          dueDateRequired: this.translatePipe.transform('Due date is required'),
        },
      };
    }
    return null;
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }
  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate() {
    if (this.form.valid) {
      return null;
    }

    return {
      dialog: this.errors,
    };
  }

  writeValue(value) {
    if (!value) {
      this.form.reset();
    } else {
      this.value = value;
      this.previousComment = this.value.Comment;
      this.previousAction = this.value.Action;
    }
  }

  async open() {
    this.onInit();
    if (!this.prompt) {
      this.prompt = true;
    }
    this.submitted$.next(false);
  }

  close(reset = true) {
    this.prompt = false;
    this.errorMessage = '';
    if (this.filesForm) {
      this.filesForm.clearFiles();
    }
    if (reset) {
      this.form.reset();
    }
  }

  async saveActionItem() {
    if (this.validate() !== null) {
      this.errorMessage = this.getErrorMessage();
      setTimeout(() => (this.errorMessage = ''), 5000);
      return;
    }
    try {
      this.submitted$.next(true);

      this.completeActionItem();
      this.setupTranslation();

      let savedItem = await this.responseSvc
        .updateActionItem(this.value)
        .toPromise();

      // upload files and attach links
      if (this.filesForm && this.filesForm.hasFilesPendingToUpload() > 0) {
        savedItem = (await this.filesFormService.uploadFiles(
          this.filesForm.getFiles(),
          savedItem
        )) as ResponseItem;

        if (this.value.Links.length !== savedItem.Links.length) {
          savedItem = await this.responseSvc
            .updateActionItem(savedItem)
            .toPromise();
        }
      }

      this.close(false);

      this.saved.emit(savedItem);
    } catch (e) {
      this.submitted$.next(false);
      this.errorMessage = e.error
        ? e.error.ExceptionMessage
        : JSON.stringify(e);

      setTimeout(() => (this.errorMessage = ''), 5000);
    }
  }

  async saveUpdateActionItem() {
    const currentPriority = this.form.get('Priority').value;
    if (this.initialPriorityValue && currentPriority !== this.initialPriorityValue) {
      this.confirmationService.confirm({
        message: 'Priority has changed. Do you want to proceed?',
        accept: () => {
          this.saveActionItem();
        }
      });
    } else {
      this.saveActionItem();
    }
  }

  setupTranslation(): void {
    if (!this.canTranslate) {
      return;
    }

    for (const req of this.translationRequests) {
      if (this.previousAction !== this.value.Action) {
        this.addTranslationHistory({
          TranslationRequestId: req.Id,
          Element: 'ActionItem',
          Content: 'Action',
          ElementId: this.value.Id,
          Language: req.Language,
          PreviousContent: this.previousAction,
          TranslatedContent: this.value.Action,
        });
      }
      if (this.previousComment !== this.value.Comment) {
        this.addTranslationHistory({
          TranslationRequestId: req.Id,
          Element: 'ActionItem',
          Content: 'Comment',
          ElementId: this.value.Id,
          Language: req.Language,
          PreviousContent: this.previousComment,
          TranslatedContent: this.value.Comment,
        });
      }
    }
  }

  addTranslationHistory(historyObj: Partial<TranslationHistory>): void {
    this.translationHistoryService
      .saveTranslationHistory(historyObj as TranslationHistory)
      .toPromise()
      .then(
        () => {
          this.loadPssrTranslationRequests(this.pssr.Id);
        },
        (error) => {
          this.errorMessage = error.error.ExceptionMessage;
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
        }
      );
  }

  loadPssrTranslationRequests(id): void {
    this.translationRequestService
      .getTranslationRequestsByPssrId(id)
      .toPromise()
      .then((data) => {
        this.translationsStorageService.setTranslations(data);
      });
  }

  confirmDeleteAI(itemId) {
    this.confirmationSvc.confirm({
      header: this.translatePipe.transform('Delete Action Item'),
      message: this.translatePipe.transform('ConfirmDeleteActionItemMessage'),
      accept: () => this.deleteActionItem(itemId),
    });
  }

  async deleteActionItem(itemId) {
    const item: ResponseItem = this.value as ResponseItem;
    item.IsDeleted = true;
    try {
      this.submitted$.next(true);

      this.responseSvc
        .deleteResponseItem(itemId)
        .toPromise()
        .then(
          () => {
            this.close();
            this.saved.emit(item);
          },
          (error) => {
            this.errorMessage = error.error;
            setTimeout(() => (this.errorMessage = ''), 5000);
          }
        );
    } catch (e) {
      this.submitted$.next(false);
      this.errorMessage = e.error
        ? e.error.ExceptionMessage
        : JSON.stringify(e);
      setTimeout(() => (this.errorMessage = ''), 5000);
    }
  }

  private completeActionItem() {
    const actionItem = this.actionItemDialogService.completeActionItem({
      actionItem: this.form.value,
      assignTo: this.assignToForm.value,
      queryParticipant: this.queryParticipant,
      groups: this.groups,
      isTraslator: this.pssr.CurrentUser.CanTranslate,
    });
    this.form.setValue(actionItem);
  }

  getErrorMessage() {
    if (!this.errors) {
      return '';
    }

    const errorMessage: string[] = [];

    if (this.errors['Action'] && this.errors['Action'].required) {
      errorMessage.push(this.translatePipe.transform('ActionIsRequired'));
    }

    if (this.errors['Status'] && this.errors['Status'].min) {
      errorMessage.push(this.translatePipe.transform('StatusIsRequired'));
    }

    if (this.errors['Status'] && this.errors['Status'].rejectReasonIsRequired) {
      errorMessage.push(`${this.errors['Status'].rejectReasonIsRequired}`);
    }

    if (this.errors['Status'] && this.errors['Status'].closedReasonIsRequired) {
      errorMessage.push(`${this.errors['Status'].closedReasonIsRequired}`);
    }

    if (this.errors['DueDate'] && this.errors['DueDate'].dueDateRequired) {
      errorMessage.push(`${this.errors['DueDate'].dueDateRequired}`);
    }

    if (this.errors['Comment'] && this.errors['Comment'].required) {
      errorMessage.push(this.translatePipe.transform('CommentisRequired'));
    }
    // concatenicate each sentence sepearted by a space,
    // because the error toast does not recognize new line characters
    return errorMessage.join('<br>');
  }

  async getParticipants({ query }) {
    try {
      this.queryParticipant = query;

      this.participants = await this.userService
        .searchParticipants(query, this.pssr.OrganizationID)
        .toPromise();

      this.participants.forEach((obj) => {
        obj.FullName = Utility.formatUser(obj);
      });
    } finally {
      // TODO implement
    }
  }

  onCategoryChange(event) {
    this.form.get('CategoryID').setValue(event ? event.Id : null);
    if (!event) {
      this.form.get('SubCategoryID').setValue(null);
    }
  }

  onSubCategoryChange(event: any) {
    this.form.get('SubCategoryID').setValue(event ? event.Id : null);
  }

  prepareForEdit() {
    const { assignTo, selectedGroup } =
      this.actionItemDialogService.prepareActionItem({
        actionItem: this.value,
        pssr: this.pssr,
        selectedBU: this.selectedBU,
      });
    this.assignToForm.patchValue(assignTo);
    this.selectedGroup = { ...selectedGroup };
  }

  onChangeStatus(status) {
    const statusName = ResponseItemStatus[status];
    this.form.get('StatusName').setValue(statusName);
  }

  loadBussinesUnit() {
    this.selectedBU = this.pssr.buObj;
  }

  private canUpdateAttachments() {
    this.onlyAttachments =
      !this.pssr.CurrentUser.CanTranslate &&
      !this.value.CanEditItem &&
      this.isAssignee &&
      this.selectedBU.AllowAIOwnerToAttach;
  }

  private canUpdateAssignee() {
    this.isAssignee = this.value.CurrentUserIsActionItemAssignee;
  }

  private updateCanEditAction(
    isNew: boolean,
    isAdmin: boolean,
    isLimitAdmin: boolean
  ) {
    this.canEditAction =
      isNew ||
      (isAdmin && !isLimitAdmin) ||
      (this.isAssignee && !this.selectedBU.LimitAccessToAIOwner) ||
      (!this.onlyAttachments && this.pssr.CurrentUser.CanTranslate);
  }

  private updateCanAddP3(isAdmin: boolean, isLimitAdmin: boolean) {
    this.canAddP3 =
      isAdmin &&
      !isLimitAdmin &&
      (this.pssr.Status === PSSRStatus.Initiated ||
        this.pssr.Status === PSSRStatus.InProgress ||
        this.pssr.Status === PSSRStatus.Completed);
  }

  private updateCanEditPriority(
    isNew: boolean,
    isAdmin: boolean,
    isLimitAdmin: boolean
  ) {
    this.canEditPriority =
      isNew || (isAdmin && !isLimitAdmin) || (isAdmin && this.isStandAlone);
  }

  private updateCanAssignTo(isNew: boolean, isAdmin: boolean) {
    this.canAssignTo =
      isNew ||
      isAdmin ||
      (this.isStandAlone && this.pssr.CurrentUser.CanAddItem);
  }

  private updateCanChangeCategories(
    isNew: boolean,
    isAdmin: boolean,
    isLimitAdmin: boolean
  ) {
    this.canChangeCategories = isNew || (isAdmin && !isLimitAdmin);
  }

  private updateCanChangeStatus(isNew: boolean, isAdmin: boolean) {
    const etc =
      !this.onlyAttachments &&
      this.isAssignee &&
      (this.value.Status === ResponseItemStatus.Open ||
        this.value.Status === ResponseItemStatus.Rejected);

    this.canChangeStatus = isNew || isAdmin || etc;
  }

  private updateCanChangeDueDate(
    isNew: boolean,
    isAdmin: boolean,
    isLimitAdmin: boolean
  ) {
    this.canChangeDueDate =
      isNew ||
      (this.selectedBU.RequireActionItemDueDate &&
        ((isAdmin && !isLimitAdmin) ||
          (!this.onlyAttachments &&
            this.isAssignee &&
            !this.selectedBU.LimitAccessToAIOwner) ||
          (!this.onlyAttachments &&
            this.isAssignee &&
            !this.selectedBU.AllowTeamLeadToAttach)));
  }

  private updateCanDelete(isAdmin: boolean, isLimitAdmin: boolean) {
    this.canDelete = isAdmin
      && !isLimitAdmin
      && !this.onlyAttachments;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
