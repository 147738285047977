import { Component, OnInit } from '@angular/core';
import { ProjectService } from '#services/api';
import { Project } from '#models/project';

import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  providers: [ ProjectService ]
})
export class ProjectEditComponent implements OnInit {
  proj: Project = new Project();
  displayWarning: boolean;

  projid: number;

  constructor(
    private toastrService: ToastrService,

    private projService: ProjectService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.displayWarning = false;
    this.route.params.subscribe(v => {
      this.projid = +v['id'];
    });
    this.getProjById(this.projid);
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/project']);
  }

  getProjById(id: number): void {

    this.projService.getProjectById(id)

    .subscribe(
      (data: Project) => {
        this.proj = data;
      },
      error => {
        this.toastrService.showError( error.error.ExceptionMessage);

      }
    );
  }

  public save(): void {
    this.displayWarning = this.proj.Name == undefined || this.proj.Name == null || this.proj.Name.length == 0;
    if (!this.displayWarning) {

      this.projService.updateProject(this.proj)

      .subscribe(
        () => {
          this.onRedirectButtonClick();
        },
        error => {
          this.toastrService.showError( error.error.ExceptionMessage);

        }
      );
    }
  }

}
