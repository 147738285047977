import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { ActionItemCategory } from '#models/action-item-category';

@Injectable()
export class ActionItemCategoryService {
    constructor(
        private http: HttpClient
    ) { }

    public getAllByBusinessUnit(businessUnitId: Number): Observable<ActionItemCategory[]> {
        return this.http.get<ActionItemCategory[]>(`${environment.apiEndpoint}/category/getAllByBusinessUnit/${businessUnitId}`);
    }

    public getCategoryById(id: number): Observable<ActionItemCategory> {
        return this.http.get<ActionItemCategory>(`${environment.apiEndpoint}/category/${id}`);
    }

    public createCategory(category: ActionItemCategory): Observable<ActionItemCategory> {
        return this.http.post<ActionItemCategory>(`${environment.apiEndpoint}/category`, category);
    }

    public updateCategory(category: ActionItemCategory): Observable<ActionItemCategory> {
        return this.http.put<ActionItemCategory>(`${environment.apiEndpoint}/category`, category);
    }

    public deleteCategory(id: number): Observable<ActionItemCategory> {
        return this.http.delete<ActionItemCategory>(`${environment.apiEndpoint}/category/${id}`);
    }

}

