

export enum TaskPlanItemType {
  Default = 0,
  Signature = 1,
  WalkdownSignature = 2,
  RequestComments = 3,
  ActionItem = 4,
}

export class TaskPlanItem {
  public ID = 0;
  public Code = '';
  public Description = '';
  public TaskPlanSubSectionId = 0;
  public SortID = 0;
  public ItemType: TaskPlanItemType = TaskPlanItemType.Default;
  public ShouldAddCommentToQuestion = false;
}
