import { OrganizationUser } from '#models/organization-user';

export class Organization {
    Id: number;
    Name: string;
    OrganizationUsers: OrganizationUser[];
    BusinessUnitId: number;
    BusinessUnitName: string;

}
