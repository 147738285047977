<p-dialog
  [style]="{'height':'350px'}"
  [contentStyle]="{ overflow: 'hidden' }"
  [(visible)]="visible"
  [blockScroll]="true"
  [modal]="true"
  [resizable]="true"
  [header]="'Subcategories'|translate"
  appendTo="body"
  *ngIf="visible"
>
  <div class="row">
    <div class="col-md-12">
      <input type="text" pInputText [(ngModel)]="newSubCategoryName" />
      <button
        name="new-sub-category"
        pButton
        type="button"
        [label]="'Add New'|translate"
        (click)="addSubCategory()"
      ></button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <div class="subcategories-list-container">
        <p-checkbox
          *ngFor="let subcategory of subcategories"
          name="subcategories"
          [value]="subcategory.Id"
          [label]="subcategory.Name"
          [(ngModel)]="selectedValues"
        >
        </p-checkbox>
      </div>
    </div>
  </div>
  <p-footer>
    <button
      name="cancel"
      type="button"
      pButton
      [label]="'Cancel' | translate"
      class="ui-button-secondary"
      (click)="hide()"
    ></button>
    <button
      name="save"
      type="button"
      pButton
      [label]="'Save' | translate"
      (click)="save()"
    ></button>
  </p-footer>
</p-dialog>
