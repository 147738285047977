import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Group } from '#models/group';
import { User } from '#models/user';
import { UserService } from '#services/api/user.service';
import { ConfirmationService } from 'primeng/api';

import { GroupService } from '#services/api';
import { GroupUser } from '#models/group-user';
import { Utility } from '#services/shared/utility';
import { UserType } from '#models/enum/user-type';
import { BusinessUnit } from '#models/business-unit';
import { AuthService } from '#services/shared';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-group-edit',
  templateUrl: './group-edit.component.html',
  styleUrls: ['./group-edit.component.scss'],
  providers: [TranslatePipe],
})
export class GroupEditComponent implements OnInit {
  UserType = UserType;

  sharedUsersCtrlInvalid = false;
  groupId: number;
  group: Group = new Group();
  groupUsers: GroupUser[];
  user: GroupUser = new GroupUser();
  selectedUser: User;
  users: User[];
  isSysAdmin = true;
  selectedBU: Partial<BusinessUnit>;

  constructor(
    private groupService: GroupService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private userService: UserService,
    private confirmationService: ConfirmationService,

    private authService: AuthService,
    private toastrService: ToastrService,
    private translatePipe: TranslatePipe
  ) { }

  ngOnInit() {
    this.user.Type = UserType.Chevron;
    this.activeRoute.params.subscribe((p) => {
      this.groupId = +p['groupId'];
    });

    this.getGroup();
  }

  getGroup() {
    this.groupService
      .getGroupById(this.groupId)
      .toPromise()
      .then((data: Group) => {
        this.group = data;
        this.selectedBU = {
          Id: data.BusinessUnitId,
          Name: data.BusinessUnitName,
        };

        this.completeSelectedBusinessUnit(data);
        this.groupUsers = this.group.GroupUsers;
      });
  }

  private completeSelectedBusinessUnit(data: Group): void {
    const user = this.authService.getCurrentUser();
    const bu = user.BusinessUnits.find((b) => b.Id === data.BusinessUnitId);

    if ('undefined' !== typeof bu) {
      this.selectedBU = {
        ...this.selectedBU,
        ...bu,
      };
    }
  }

  getUsers(event) {
    const query = event.query;
    this.userService.searchUser(query).subscribe((data) => {
      this.users = data;
      this.users.forEach(function (obj, index) {
        this[index].FullName = Utility.formatUser(this[index]);
      }, this.users);
    });
  }

  async addUser() {
    this.user.GroupId = this.groupId;
    this.user.CAI = null;

    if (this.user.Type === UserType.Chevron) {
      this.user.CAI = Utility.isValidObj(this.selectedUser)
        ? this.selectedUser.CAI
        : null;

      this.user.Email = Utility.isValidObj(this.selectedUser)
        ? this.selectedUser.Email
        : null;
    }

    const hasInvalidInput = this.validateUserInput();
    if (hasInvalidInput) {
      this.toastrService.showError(hasInvalidInput);
    } else {
      this.user.HasAccess = true;

      await this.groupService
        .addGroupUser(this.user)
        .toPromise()
        .then(
          (data) => {
            this.groupUsers.push(data);
            this.toastrService.showSuccess(this.translatePipe.transform('AddedUserSuccessfulMessage'));
            this.clear();
          },
          (error) => this.toastrService.showError(error.error.ExceptionMessage)
        );
    }
  }

  removeUser(id: number) {
    const message = this.translatePipe.transform('Recordwillbepermanentlyremovedcontinue');

    this.confirmationService.confirm({
      message: message,
      header: this.translatePipe.transform('ConfirmDeleteHeader'),
      accept: () => {
        this.deleteUser(id);
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  async deleteUser(id: number) {
    await this.groupService
      .removeGroupUser(id)
      .toPromise()
      .then(
        () => {
          const userOrgIndex = this.groupUsers.findIndex((x) => x.Id == id);
          this.groupUsers.splice(userOrgIndex, 1);
          this.toastrService.showSuccess(this.translatePipe.transform('DeletedUserSuccessfulMessage'));
        },
        (error) => this.toastrService.showError(error.error.ExceptionMessage)
      );
  }

  redirectBack(): void {
    this.router.navigate(['/admin/groups']);
  }

  async save() {
    const hasInputError = Utility.isValidString(this.group.Name) ? null : '';
    if (hasInputError) {
      this.toastrService.showError(hasInputError);
    } else {
      this.groupService
        .updateGroup(this.group)
        .toPromise()
        .then(
          () => {
            this.toastrService.showSuccess(this.translatePipe.transform('EditedGroupSucessfulMessage'));
          },
          (err) => this.toastrService.showError(err.error.ExceptionMessage)
        );
    }
  }

  clear() {
    this.selectedUser = null;
    this.user.UserId = null;
    this.user.FirstName = null;
    this.user.MiddleName = null;
    this.user.LastName = null;
    this.user.Email = null;
    this.user.CAI = null;
    this.user.Passcode = null;
    this.user.IsLoginOnly = false;
    this.user.ChildUsers = null;

    // This trick helps shared user component to re-read the list of users
    setTimeout(() => this.user.ChildUsers = []);
  }

  checkForRepeatedUsers() {
    const sharedEmails = this.user.ChildUsers.map(({ Email: ChildUserEmail }) => ChildUserEmail);
    const sharedUsersCount = sharedEmails.length;
    const repeatedSharedEmails = [];
    for (let i = 0; i < sharedUsersCount; i += 1) {
      const email = sharedEmails[i];

      if (Utility.isValidString(email)) {
        sharedEmails.forEach((userEmail, index) => {
          if (
            index !== i &&
            userEmail === email &&
            !repeatedSharedEmails.some((r) => r === email)
          ) {
            repeatedSharedEmails.push(userEmail);
          }
        });
      }
    }
    return repeatedSharedEmails;
  }

  validateUserInput() {
    const errors = [];
    if (
      this.user.Type === UserType.Chevron &&
      !Utility.isValidString(this.user.Email)
    ) {
      errors.push(this.translatePipe.transform('User is required'));
    }
    if (
      this.user.Type === UserType.External &&
      !Utility.isValidString(this.user.FirstName)
    ) {
      errors.push(this.translatePipe.transform('First Name is required'));
    }

    if (
      this.user.Type === UserType.Shared &&
      !Utility.isValidString(this.user.FirstName)
    ) {
      errors.push(this.translatePipe.transform('Shared User Name is required'));
    }

    if (
      this.user.Type === UserType.Shared &&
      !Utility.isValidString(this.user.Email)
    ) {
      errors.push(this.translatePipe.transform('Email is required'));
    }

    if (
      ![UserType.Chevron, UserType.External, UserType.Shared].includes(
        this.user.Type
      )
    ) {
      errors.push(this.translatePipe.transform('User type is wrong.'));
    }

    const repeatedSharedUsers = this.checkForRepeatedUsers();
    if (repeatedSharedUsers.length > 0) {
      errors.push(
        this.translatePipe.transform('RepeatedEmailsInputWarning', {
          repeatedEmails: repeatedSharedUsers.join('] ['),
        })
      );
    }

    return errors.join('<br>');
  }

  async importSharedUsers(buId: number, sharedEmailAddress: string): Promise<void> {
    try {
      // This trick helps shared user component to re-read the list of users
      this.user.ChildUsers = null;

      const sharedUser = await this.userService.importSharedUser(buId, sharedEmailAddress).toPromise();
      this.user.ChildUsers = sharedUser.ChildUsers;

      this.toastrService.showSuccess(this.translatePipe.transform('Shareduserfound'), 'Success');
    } catch (e) {
      this.toastrService.showWarning(this.translatePipe.transform('Shared user not found'));
    }
  }

  onSharedUsersListChanged(event) {
    const { users, isValid } = event;

    this.sharedUsersCtrlInvalid = !isValid;
    this.user.ChildUsers = users;
  }
}
