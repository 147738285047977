import { Component, OnInit } from '@angular/core';
import { finalize, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Utility } from '#services/shared/utility';
import {
  PSSRService,
  ProjectService,
  BusinessUnitsService,
  QAQCService,
  AreaService,
  SubAreaService,
  FacilityService,
  TaskPlanService,
} from '../../../services/api/index';

import {
  PSSR,
  PSSRFilter,
  Project,
  Area,
  SubArea,
  Facility,
  TaskPlan,
  BusinessUnit,
  QAQCResponse,
  QAQCItem,
  QAQCRequest,
  QAQCType,
  ProjectStatus,
  TaskPlanType,
  LoggedUser,
} from '../../../models/index';
import { SectionUser, SectionUserList } from '../../../models/section/section';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { PSSRType } from '#models/pssr-type';
import { PSSRUser } from '#models/pssr-user';
import { PSSRUserList } from '#models/pssr-user-list';
import { AuthService } from '#services/shared';
import { PSSRStatus } from '#models/pssr-status';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-pssr-project-detail',
  templateUrl: './pssr-project-detail.component.html',
  styleUrls: ['./pssr-project-detail.component.scss'],
  providers: [TranslatePipe],
})
export class PssrProjectDetailComponent implements OnInit {
  //#region VARIABLES

  businessUnitId: number;
  selectedBU: BusinessUnit = new BusinessUnit();
  PssrTypeIds = [];

  orgId: number;

  projectId: number;
  project: Project = new Project();

  pssrListSelected: PSSR[];
  hasPssrs = false;
  hasOpenPSSRs = true;
  templateSelected: boolean;
  successMsg: boolean;
  successMsgQA: boolean;
  errorMsg: boolean;
  message: string;
  saving = false;
  canInitiateAll = false;
  // QAQC
  showQAQC: Boolean;
  QAQCMessage: string;
  qaqcLabel = 'QAQC';

  // QAQC List Items
  qaqcItem: QAQCItem[] = [];
  qaqcSelectItem: SelectItem[] = [];
  qaqcSelectedItem: string[] = [];
  qaqcItemSubmit: QAQCItem[] = [];
  lstqaqcMilestone: QAQCItem[] = [];
  lstqaqcWO: QAQCItem[] = [];

  // PSSRCreation
  areaID: number;
  subareaID: number;
  facilityID: number;
  taskplanID: number;
  pssrTypeID: number;
  projectID: number;

  // BU, Organization, Area, SubArea, Task, Actions
  lstArea: Area[] = [];
  lstSubArea: SubArea[] = [];
  lstFacility: Facility[] = [];
  lstTaskPlan: TaskPlan[] = [];

  pssrTypes: SelectItem[];

  // Participants and SectionLeads
  bulkPssrUsers: PSSRUser[] = [];
  bulkSectionLeads: SectionUser[] = [];
  //#endregion

  // A project can not be deleted if it is closed (only by admins)
  canDeleteProject = false;
  isConfirmDelete = false;

  constructor(
    private toastrService: ToastrService,
    private activeRoute: ActivatedRoute,
    private projectService: ProjectService,
    private svcPSSR: PSSRService,

    private router: Router,
    private businessUnitService: BusinessUnitsService,
    private qaqcService: QAQCService,
    private svcArea: AreaService,
    private svcSubArea: SubAreaService,
    private svcFacility: FacilityService,
    private svcTaskPlan: TaskPlanService,
    private confirmationSvc: ConfirmationService,
    private authService: AuthService,
    private translatePipe: TranslatePipe
  ) {
    this.activeRoute.params.subscribe((p) => {
      this.projectId = +p['itemId'];
      this.businessUnitId = +p['parentId'];
      this.orgId = +p['orgId'];
    });
  }

  // Participants Bulk Loading
  addedPssrUsers(user: PSSRUser[]) {
    this.bulkPssrUsers = user;
  }
  addedSectionLeads(sectionLeads: SectionUser[]) {
    this.bulkSectionLeads = sectionLeads;
  }

  async ngOnInit() {
    await this.validateAuthorization();
    await this.getProjectInfo();
    await this.loadBusinessUnit();
    await this.loadPssrs();

    await this.loadArea();
    await this.loadSubArea();
    await this.loadFacility();
    await this.loadTaskPlan();
    await this.getPSSRQAQCDetail();

    this.completePssrData();
    this.templateSelected = false;
  }

  async validateAuthorization() {
    await this.businessUnitService
      .getUserBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        const buMatch = data.find((x) => x.Id == this.businessUnitId);
        if (!Utility.isValidObj(buMatch)) {
          this.router.navigate(['/error/not-authorized']);
        }
      });
  }

  async getProjectInfo() {
    this.canDeleteProject = false;
    const currentUser = this.authService.getCurrentUser();
    await this.projectService
      .getProjectById(this.projectId)
      .toPromise()
      .then(
        (data: Project) => {
          this._prepareProjectData(data, currentUser);
        },
        () => this.showMessage(this.translatePipe.transform('LoadProjectDetailsErrorMessage'), 1)
      );
  }

  private _prepareProjectData(data: Project, currentUser: LoggedUser): void {
    this.project = data;

    if (this.project.IsDeleted) {
      this.project.CurrentUser.CanEdit = false;
      this.project.StatusName = 'Deleted';
    }

    this.canDeleteProject =
      (currentUser && currentUser.IsSystemAdmin) ||
      this.project.CurrentUser.IsBusinessUnitAdmin ||
      this.project.CurrentUser.IsOrgAdmin;

    this.project.statusClass = this.projectService.getLabelClass(this.project);

    this.project.ComissionDate = Utility.parseOffsetDate(
      this.project.ComissionDate
    );
  }

  async loadBusinessUnit() {
    if (this.businessUnitId > 0) {
      await this.businessUnitService
        .getBusinessUnitById(this.businessUnitId)
        .toPromise()
        .then(
          (bu) => {
            this.selectedBU = bu;
            this.selectedBU.PssrTitle = Utility.getPssrTitle(this.selectedBU);
            this.loadPssrTypes();
          },
          () => this.showMessage(this.translatePipe.transform('LoadBusinessUnitsErrorMessage'), 1)
        );
    }
  }

  loadPssrTypes() {
    this.pssrTypes = [];
    this.PssrTypeIds.push(null);

    if (Utility.isValidListWithData(this.selectedBU.PssrTypes)) {
      this.selectedBU.PssrTypes.forEach((p: PSSRType) => {
        this.PssrTypeIds.push(p.Id);
        this.pssrTypes.push({ label: p.Name, value: p.Id });
      });
    }
  }

  async loadArea() {
    if (this.selectedBU && this.selectedBU.ShowQAQC) {
      await this.svcArea
        .getAllByBusinessUnit(this.selectedBU.Id)
        .toPromise()
        .then((data: Area[]) => {
          this.lstArea = data;
          this.lstArea.sort((a, b) => a.Name.localeCompare(b.Name));
          if (this.lstArea.length > 0) {
            this.areaID = this.lstArea[0].Id;
          }
        });
    }
  }

  async loadSubArea() {
    if (this.areaID > 0 && this.selectedBU.ShowQAQC) {
      await this.svcSubArea
        .getAllByAreaId(this.areaID)
        .toPromise()
        .then((data: SubArea[]) => {
          this.lstSubArea = data;
          this.lstSubArea.sort((a, b) => a.Name.localeCompare(b.Name));
          if (this.lstSubArea.length > 0) {
            this.subareaID = this.lstSubArea[0].Id;
          }
        });
    }
  }

  async loadFacility() {
    if (
      this.subareaID > 0 &&
      this.selectedBU.ShowHierarchyLabel3 &&
      this.selectedBU.ShowQAQC
    ) {
      await this.svcFacility
        .getAllBySubArea(this.subareaID)
        .toPromise()
        .then((data: Facility[]) => {
          this.lstFacility = data;
          this.lstFacility.sort((a, b) => a.Name.localeCompare(b.Name));
          if (this.lstFacility.length > 0) {
            this.facilityID = this.lstFacility[0].Id;
          }
        });
    }
  }

  async loadTaskPlan() {
    if (this.selectedBU.ShowQAQC) {
      await this.svcTaskPlan
        .getTaskPlans(this.selectedBU.Id)
        .toPromise()
        .then((data: TaskPlan[]) => {
          this.lstTaskPlan = data.filter(
            (t) => t.TaskPlanType === TaskPlanType.PSSR
          );
        });
    }
  }

  // QAQC
  async getPSSRQAQCDetail() {
    let successQAQC: boolean;
    this.qaqcItem = [];

    if (!this.selectedBU.ShowQAQC) {
      return;
    }

    switch (this.project.QAQCType) {
      case 1:
        successQAQC = await this.loadQAQCMilestoneBySite();
        break;
      case 2:
        successQAQC = await this.loadQAQCWorkOrderBySite();
        break;
      case 3:
        successQAQC = await this.loadQAQCWorkOrderByMilestone();
        break;
    }

    if (successQAQC) {
      await this.loadQAQCMilestone();
      await this.loadQAQCWorkOrder();
    }
  }

  async createNewQAQCItems() {
    // Validate Hierarchies:
    if (
      this.areaID &&
      this.subareaID &&
      this.taskplanID &&
      this.qaqcSelectedItem.length > 0
    ) {
      if (this.canDisplayFacilities()) {
        try {

          this.updateqQAQCItems();
          await this.createMultiPSSR();
          await this.loadPssrs();
        } finally {

          this.showMessage(
            this.translatePipe.transform('QAQCItemsAreUpToDateMessage'),
            0
          );
        }
      } else {
        this.showMessage(
          this.translatePipe.transform('QAQCNewItemInputWarning', this),
          1
        );
      }
    } else {
      this.showMessage(
        this.translatePipe.transform('QAQCNewItemInputWarning', this),
        1
      );
    }
  }


  async createMultiPSSR() {
    const pssrs: PSSR[] = this.convertQAQCToPSSR();

    await this.svcPSSR
      .createMultiWithParticipant(pssrs)
      .toPromise()
      .then(
        () => {
          //
        },
        (error) => {
          this.showMessage(error.error.ExceptionMessage, 1);
        }
      );
  }

  async loadQAQCWorkOrder() {
    const qaqcRequestWO = new QAQCRequest();
    qaqcRequestWO.Site = this.selectedBU.Name;
    qaqcRequestWO.EventId = this.project.QAQCEventId;
    await this.qaqcService
      .getQAQCWorkOrderBySite(qaqcRequestWO)
      .toPromise()
      .then((data: QAQCResponse) => {
        this.lstqaqcWO = data.Results;
        this.qaqcItem.sort((a, b) => a.Name.localeCompare(b.Name));
        this.lstqaqcWO = this.lstqaqcWO.filter((x) => x.Name.length > 0);
      });
  }

  async loadQAQCMilestone() {
    const qaqcRequest = new QAQCRequest();
    qaqcRequest.Site = this.selectedBU.Name;
    qaqcRequest.EventId = this.project.QAQCEventId;
    await this.qaqcService
      .getQAQCMilestoneBySite(qaqcRequest)
      .toPromise()
      .then((data: QAQCResponse) => {
        this.lstqaqcMilestone = data.Results;
        this.qaqcItem.sort((a, b) => a.Name.localeCompare(b.Name));
        this.lstqaqcMilestone = this.lstqaqcMilestone.filter(
          (x) => x.Name.length > 0
        );
      });
  }

  async loadPssrs() {
    if (this.projectId > 0) {
      const filter = new PSSRFilter();
      filter.ShowDeleted = false;
      filter.PssrTypeIds = this.PssrTypeIds;
      filter.StatusList = [1, 2, 3, 4, 5, 6];
      filter.BUId = this.businessUnitId;
      filter.ProjectId = this.projectId;
      await this.svcPSSR
        .pssrsByProjId(this.projectId)
        .toPromise()
        .then((data: PSSR[]) => {
          this.pssrListSelected = data;
          this.hasPssrs = this.pssrListSelected.length > 0;
          this.hasOpenPSSRs = this.pssrListSelected.findIndex(x => x.Status !== PSSRStatus.Closed) > -1;
        });
    }
  }

  completePssrData() {
    if (this.pssrListSelected) {
      this.pssrListSelected.forEach((z) => {
        if (this.project.QAQCType === 1) {
          z.QAQCItem = this.qaqcItem.find((x) => x.Id === z.QAQCMilestoneId);
        } else if (this.project.QAQCType === 2 || this.project.QAQCType === 3) {
          z.QAQCItem = this.qaqcItem.find((x) => x.Id === z.QAQCWorkOrderId);
        }
        z.statusClass = this.svcPSSR.labelClass(z.Status);
        z.QuestionsFormated = [];
        z.Questions.forEach((q) => {
          const res = q.split(':');
          let priorityName = '';
          switch (res[0].trim()) {
            case 'P3':
              priorityName = this.selectedBU.P3Label;
              break;
            case 'P4':
              priorityName = this.selectedBU.P4Label;
              break;
            case 'P5':
              priorityName = this.selectedBU.P5Label;
              break;
            default:
              priorityName = res[0].trim();
              break;
          }
          const obj: any = { name: priorityName, value: res[1] };
          if (!(!this.selectedBU.ShowP5 && res[0].trim() == 'P5')) {
            z.QuestionsFormated.push(obj);
          }
        });
      });
    }
  }

  backProjectList() {
    // Timeout is a workaround to overcome Popup destory before routing to list page added setTimeout
    // ERROR: Attempt to use a destroyed view: detectChanges
    setTimeout(() => {
      this.router.navigate([
        'pssr-project',
        {
          buId: this.selectedBU.Id,
          orgId: this.orgId,
        },
      ]);
    }, 400);
  }

  confirmClose() {
    this.isConfirmDelete = false;
    const message = this.hasOpenPSSRs ? 'CannotCloseProjectDueOpenPSSRs' : 'ConfirmCloseProjectDescription';
    this.confirmationSvc.confirm({
      header: this.translatePipe.transform('ConfirmCloseProjectTitle'),
      message: this.translatePipe.transform(message),
      accept: () => {
        this.project.Status = ProjectStatus.Closed;
        this.project.StatusName = 'Closed';
        this.project.statusClass = this.projectService.getLabelClass(
          this.project
        );
        this.updateProject();
      },
    });
  }

  async updateProject() {

    this.projectService
      .updateProject(this.project)
      .pipe(
        take(1),
        finalize(() => {

          this.showMessage(
            this.translatePipe.transform('ProjectUpdatedSucessfully'),
            1
          );
        })
      )
      .subscribe(() => {
        // TODO implement
      });
  }

  confirmDelete() {
    this.isConfirmDelete = true;
    const message = this.hasPssrs
      ? 'ConfirmDeleteProjectWithPssrsDescription'
      : 'ConfirmDeleteProjectWithoutPssrsDescription';
    this.confirmationSvc.confirm({
      header: this.translatePipe.transform('ConfirmDeleteProjectTitle'),
      message: this.translatePipe.transform(message),
      accept: () => {
        this.deleteProject(true);
      },
    });
  }

  partialDelete() {
    this.deleteProject(false);
  }

  async deleteProject(deletePSSRs: boolean) {
    try {

      await this.projectService
        .delete(this.project.Id, deletePSSRs)
        .toPromise();
    } catch (error) {
      this.showMessage(error.error.ExceptionMessage, 0);
    } finally {

      this.backProjectList();
    }
  }

  onMembersChanged(membersChange: boolean) {
    if (membersChange) {
      this.getProjectInfo();
    }
  }

  showMessage(info: string, dv: number = 1) {


    if (dv === 0) {
      this.toastrService.showSuccess(info);
    } else {

      this.toastrService.showSuccess(info);
    }

  }

  async changeArea() {


    this.subareaID = null;
    this.facilityID = null;
    this.lstSubArea = [];
    this.lstFacility = [];
    await this.loadSubArea();
    await this.loadFacility();

  }

  async changeSubArea() {

    this.facilityID = null;
    this.lstFacility = [];
    await this.loadFacility();
  }

  initiateAllPssrsEvent() {

    this.isConfirmDelete = false;
    this.canInitiateAll = true;
    this.confirmationSvc.confirm({
      header: this.translatePipe.transform('ConfirmInitiateProjectPssrsTitle'),
      message: this.translatePipe.transform(
        'ConfirmInitiateProjectPssrsDescription'
      ),
      accept: () => {
        this.initiatePSSRs();
        this.canInitiateAll = false;
      },
      reject: () => {
        this.canInitiateAll = false;
      }
    });

  }

  private async initiatePSSRs() {
    let errorM = '';
    for (const p of this.pssrListSelected) {
      await this.svcPSSR
        .changeStatus(p.Id, PSSRStatus.Initiated)
        .toPromise()
        .then(
          (data: PSSR) => {
            p.statusClass = this.svcPSSR.labelClass(data.Status);
            p.Status = data.Status;
            p.StatusName = data.StatusName;
          },
          (error) => {
            errorM += error.error.ExceptionMessage + '<br />';
          }
        );
    }
    if (errorM) {
      this.showMessage(errorM, 1);
      window.scrollTo(0, 0);
    }
  }

  private async loadQAQCMilestoneBySite(): Promise<boolean> {
    let successQAQC: boolean;
    const qaqcRequest = new QAQCRequest();

    qaqcRequest.Site = this.selectedBU.Name;
    qaqcRequest.EventId = this.project.QAQCEventId;

    await this.qaqcService
      .getQAQCMilestoneBySite(qaqcRequest)
      .toPromise()
      .then(
        (data: QAQCResponse) => {
          successQAQC = true;
          this.qaqcItem = data.Results.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
          this.qaqcItem = this.qaqcItem.filter((x) => x.Name.length > 0);
          this.qaqcItem.forEach((p: QAQCItem) => {
            this.qaqcSelectItem.push({
              label: p.Description !== null ? p.Description : p.Name,
              value: p.Id,
            });
          });
          this.qaqcLabel = 'Milestone';
        },
        () => {
          this.showMessage(
            this.translatePipe.transform('UnableLoadQAQCMilestonesMessage'),
            1
          );
        }
      );

    return successQAQC;
  }

  private async loadQAQCWorkOrderBySite(): Promise<boolean> {
    let successQAQC: boolean;
    const qaqcRequest = new QAQCRequest();

    qaqcRequest.Site = this.selectedBU.Name;
    qaqcRequest.EventId = this.project.QAQCEventId;

    await this.qaqcService
      .getQAQCWorkOrderBySite(qaqcRequest)
      .toPromise()
      .then(
        (data: QAQCResponse) => {
          successQAQC = true;
          this.qaqcItem = data.Results.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
          this.qaqcItem = this.qaqcItem.filter((x) => x.Name.length > 0);
          this.qaqcItem.forEach((p: QAQCItem) => {
            this.qaqcSelectItem.push({
              label: p.Description !== null ? p.Description : p.Name,
              value: p.Id,
            });
          });
          this.qaqcLabel = 'WorkOrders';
        },
        () => {
          this.showMessage(
            this.translatePipe.transform('UnableLoadQAQCWorkOrdersMessage'),
            1
          );
        }
      );

    return successQAQC;
  }

  private async loadQAQCWorkOrderByMilestone(): Promise<boolean> {
    let successQAQC: boolean;
    const qaqcRequest = new QAQCRequest();

    qaqcRequest.Site = this.selectedBU.Name;
    qaqcRequest.MilestoneId = this.project.QAQCMilestoneId;

    await this.qaqcService
      .getQAQCWorkOrderByMilestone(qaqcRequest)
      .toPromise()
      .then(
        (data: QAQCResponse) => {
          successQAQC = true;
          this.qaqcItem = data.Results.sort((a, b) =>
            a.Name.localeCompare(b.Name)
          );
          this.qaqcItem = this.qaqcItem.filter((x) => x.Name.length > 0);
          this.qaqcItem.forEach((p: QAQCItem) => {
            this.qaqcSelectItem.push({
              label: p.Description !== null ? p.Description : p.Name,
              value: p.Id,
            });
          });
          this.qaqcLabel = 'MilestoneWorkOrders';
        },
        () => {
          this.showMessage(
            this.translatePipe.transform('UnableLoadQAQCMilestonesMessage'),
            1
          );
        }
      );

    return successQAQC;
  }

  private canDisplayFacilities(): boolean {
    return (
      !!(
        this.selectedBU.ShowHierarchyLabel3 &&
        (this.facilityID || this.lstFacility.length === 0)
      ) || !this.selectedBU.ShowHierarchyLabel3
    );
  }

  private convertQAQCToPSSR(): PSSR[] {
    const automatedPSSR: PSSR[] = [];

    for (const item of this.qaqcItemSubmit) {
      const newPSSR = new PSSR();
      newPSSR.AreaID = this.areaID;
      newPSSR.BusinessUnitID = this.businessUnitId;
      newPSSR.OrganizationID = this.project.OrganizationId;
      newPSSR.ProjectID = this.projectId;
      newPSSR.Status = 1;
      newPSSR.SubAreaID = this.subareaID;
      newPSSR.TaskPlanID = this.taskplanID;
      newPSSR.FacilityID = this.facilityID;
      newPSSR.PssrTypeId = this.pssrTypeID;
      newPSSR.Name = item.Description || item.Name;
      newPSSR.MOC = item.MOCNumber;
      newPSSR.Description = item.Description;

      switch (this.project.QAQCType) {
        case 1:
          newPSSR.QAQCMilestoneId = item.Id;
          newPSSR.QAQCMilestone = item.Name;
          newPSSR.QAQCType = QAQCType.Milestone;
          break;
        case 2:
          newPSSR.QAQCWorkOrder = item.Name;
          newPSSR.QAQCWorkOrderId = item.Id;
          newPSSR.WO = item.Name;
          newPSSR.QAQCType = QAQCType.WorkOrder;
          break;
        case 3:
          newPSSR.QAQCMilestone = this.lstqaqcMilestone.find(
            (x) => x.Id === this.project.QAQCMilestoneId
          ).Name;
          newPSSR.QAQCMilestoneId = this.project.QAQCMilestoneId;
          newPSSR.QAQCWorkOrder = item.Name;
          newPSSR.QAQCWorkOrderId = item.Id;
          newPSSR.WO = item.Name;
          newPSSR.QAQCType = QAQCType.MilestoneWorkOrder;
          break;
      }

      // Include Participants and Section lead with bulk upload:
      newPSSR.PSSRSectionUsers = new SectionUserList();
      newPSSR.PSSRSectionUsers.SectionUsers = this.bulkSectionLeads;
      newPSSR.PSSRUsers = new PSSRUserList();
      newPSSR.PSSRUsers.Users = this.bulkPssrUsers;
      automatedPSSR.push(newPSSR);
    }

    return automatedPSSR;
  }

  private updateqQAQCItems() {
    const newqaqcSelectedItem: string[] = [];

    // Validate if PSSR already exists for that item, Skips if it find duplicate
    this.qaqcSelectedItem.forEach((itm) => {
      switch (this.project.QAQCType) {
        case 1:
          if (
            this.pssrListSelected.find((x) => x.QAQCMilestoneId === itm) == null
          ) {
            newqaqcSelectedItem.push(itm);
          }
          break;
        case 2:
        case 3:
          if (
            this.pssrListSelected.find((x) => x.QAQCWorkOrderId === itm) == null
          ) {
            newqaqcSelectedItem.push(itm);
          }
          break;
      }
    });

    // Create List for auto generation
    newqaqcSelectedItem.forEach((newItm) => {
      this.qaqcItemSubmit.push(this.qaqcItem.find((x) => x.Id === newItm));
    });
  }

  restoreProject(projectId: number): void {

    this.projectService
      .restoreProject(projectId)

      .subscribe(
        (res) => {
          this.getProjectInfo();

          this.showMessage(res.Message);
        },
        (err) => {
          this.showMessage(err.error.Message, 0);
        }
      );
  }
}
