import { FileService } from '#services/shared/file.service';
import { Utility } from '#services/shared/utility';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  Validators,
  ValidationErrors,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pdf-pages-dialog',
  styleUrls: ['./pdf-pages-dialog.component.scss'],
  templateUrl: './pdf-pages-dialog.component.html',
})
export class PdfPagesDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private fileService: FileService,
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<PdfPagesDialogComponent>
  ) { }

  async ngOnInit() {
    await this.buildForm();
  }

  validate =
    (files) =>
      (group): ValidationErrors | null => {
        for (let i = 0; i < files.length; i += 1) {
          const control = group.get(files[i].guid).get('pages') || { value: [] };

          if (files.length === 1 && control.value.length === 0) {
            return {
              pagesSelection: {
                invalid: true,
                message: 'Could not detect pages count',
              },
            };
          }

          if (control.value.length === 0 && files[i].pages === 0) {
            continue;
          }

          const notChecked = (control.value as boolean[]).every(
            (v) => v == false
          );

          if (notChecked) {
            return {
              pagesSelection: {
                invalid: true,
                message: 'You should select at least one page for each file',
              },
            };
          }
        }
        return null;
      }

  async buildForm() {
    let obj = null;

    for (let i = 0; i < this.data.files.length; i += 1) {
      const guid = Utility.guid();
      this.data.files[i].guid = guid;

      const pages = await this.fileService
        .countPages(this.data.files[i])
        .catch(() => 0);

      this.data.files[i].pages = pages;

      const controls = [];
      for (let j = 0; j < pages; j += 1) {
        controls.push(new FormControl(true));
      }
      if (obj === null) { obj = {}; }
      obj[guid] = new FormGroup({
        pages: new FormArray(controls),
        select: new FormControl(false),
      });
    }

    if (obj === null) { return; }
    this.form = this.formBuilder.group(obj);

    this.form.setValidators(
      Validators.compose([this.validate(this.data.files)])
    );
    this.form.updateValueAndValidity();
  }

  submit() {
    const value = this.form.value;

    /* tslint:disable */
    for (const control in value) {
      value[control].pages = value[control].pages.reduce(
        (a, b, i) => (!!b ? [...a, i + 1] : a),
        []
      );
    }
    /* tslint:enable */

    this.dialogRef.close(
      this.data.files.map((f) => {
        return {
          name: f.name,
          pages: value[f.guid].pages,
        };
      })
    );
  }

  cancel() {
    this.dialogRef.close(null);
  }

  markAllPages(guid, toggle) {
    const control = this.form.get(guid).get('pages');
    const values = control.value;
    control.setValue(values.map(() => toggle));
  }

  getPagesControl(guid): FormArray {
    return this.form.get(guid).get('pages') as FormArray;
  }
}
