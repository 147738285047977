import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { EndDevice } from '#models/end-device';
import { Equipment } from '#models/equipment';

@Injectable()
export class EndDeviceService {
    constructor(
        private http: HttpClient
    ) { }

    public getAllByEquipment(equipmentId: Number): Observable<EndDevice[]> {
        return this.http.get<EndDevice[]>(`${environment.apiEndpoint}/end-device/getByEquipment/${equipmentId}`);
    }

    public getEndDeviceById(id: number): Observable<EndDevice> {
        return this.http.get<EndDevice>(`${environment.apiEndpoint}/end-device/${id}`);
    }

    public create(endDevice: EndDevice): Observable<EndDevice> {
        return this.http.post<EndDevice>(`${environment.apiEndpoint}/end-device`, endDevice);
    }

    public update(endDevice: EndDevice): Observable<EndDevice> {
        return this.http.put<EndDevice>(`${environment.apiEndpoint}/end-device`, endDevice);
    }

    public updateAll(endDevices: EndDevice[]): Observable<EndDevice[]> {
        return this.http.put<EndDevice[]>(`${environment.apiEndpoint}/end-device/updateAll`, endDevices);
    }

    public delete(id: number): Observable<EndDevice> {
        return this.http.put<EndDevice>(`${environment.apiEndpoint}/end-device/delete/${id}`, null);
    }

    public createByEquipment(equipment: Equipment) {
        return this.http.post(`${environment.apiEndpoint}/end-device/copyFromEquipment`, equipment);
    }
}
