
export class OtherParticipantType3 {
  Name: string;
  Email: string;

  constructor() {
    this.Name = '';
    this.Email = '';
  }
}
