import { Component, Input, ViewChild } from '@angular/core';
import { PSSR, PSSRFile } from '#models/index';
import { PSSRService } from '#services/api';

import { ConfirmationService } from 'primeng/api';
import { PssrFileStorageService } from '#services/shared/pssr-file-storage.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { PdfPagesDialogComponent } from '../dialogs/pdf-pages-dialog/pdf-pages-dialog.component';
import { FileUpload } from 'primeng/fileupload';
import { delay, map } from 'rxjs/operators';
import { TranslatePipe } from '@ngx-translate/core';
import { FileService } from '#services/shared/file.service';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-pssr-file',
  templateUrl: './pssr-file.component.html',
  styleUrls: ['./pssr-file.component.scss'],
  providers: [TranslatePipe],
})
export class PssrFileComponent {
  @Input() pssr: PSSR;
  @Input() title: string;
  @Input() isImage: boolean;
  @Input() pssrFiles: PSSRFile[] = [];
  @Input() maxFileSize = 20 * 1024 * 1024;
  @Input() accept = '';

  @ViewChild(FileUpload) filesForm: FileUpload;

  constructor(
    private pssrService: PSSRService,

    private confirmationService: ConfirmationService,
    private pssrFileService: PssrFileStorageService,
    public dialog: MatDialog,
    private translatePipe: TranslatePipe,
    private fileService: FileService,
    private toastrService: ToastrService
  ) {}

  async upload($event) {
    const pdfFiles = [];
    for (let i = 0; i < $event.files.length; i += 1) {
      if ($event.files[i].type.includes('pdf')) {
        pdfFiles.push($event.files[i]);
      }
    }

    const pagesSelection = await this.promptPagesSelection(pdfFiles);

    const batch = this.prepareBulkUpload($event, pagesSelection);

    let errors = '',
      hasUploaded = false;

    for await (const result of batch) {
      if (typeof result === 'string') {
        errors += `${result}\n`;
      } else {
        hasUploaded = true;
      }
    }

    this.filesForm.clear();

    if (hasUploaded) {
      if (errors) {
        this.toastrService.showWarning( this.translatePipe.transform('FilesUploadedWithWarning'));
      } else {
        this.toastrService.showSuccess(this.translatePipe.transform('FilesUploaded'));
      }
    }
    return false;
  }

  private async *prepareBulkUpload($event, pagesSelection: any[]) {
    for (let i = 0; i < $event.files.length; i += 1) {
      const file = $event.files[i];

      const btoa = await this.fileService.getFileAsBinaryString(file);
      const body: PSSRFile = this.preparePayload(btoa, file, pagesSelection);

      if (this.isValid(body)) {
        yield this.pssrService
          .uploadFile(body)
          .pipe(delay(200))
          .toPromise()

          .then((data) => {
            this.pssrFileService.addFiles(data);
            return data;
          })
          .catch(
            (error) => `<b>${file.name}</b>: ${error.error.ExceptionMessage}`
          );
      }
    }
  }

  private preparePayload(
    fileContentBase64: string,
    file: File,
    pagesSelection: any[]
  ) {
    const body: PSSRFile = {
      File: fileContentBase64,
      PSSRID: this.pssr.Id,
      Description: file.name,
      IsImage: this.isImage,
      ContentType: file.type,
      CreateDate: new Date(),
      FileType: 0,
      GUID: null,
      Id: 0,
    };

    if (body.ContentType.includes('pdf') && body.IsImage) {
      const fileWithPagesSelection = pagesSelection.find(
        (f) => f.name == file.name
      );
      if (fileWithPagesSelection) {
        body.SelectedPages = fileWithPagesSelection.pages;
      }
    }
    return body;
  }

  private async promptPagesSelection(pdfFiles: any[]) {
    let pagesSelection = [];
    if (pdfFiles.length > 0 && this.isImage) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.hasBackdrop = true;
      dialogConfig.width = '400px';
      dialogConfig.height = 'auto';
      dialogConfig.data = {
        files: pdfFiles,
      };

      const dialogRef = this.dialog.open(PdfPagesDialogComponent, dialogConfig);

      pagesSelection = await dialogRef
        .afterClosed()
        .pipe(map((v) => (v === '' ? [] : v)))
        .toPromise();
    }
    return pagesSelection;
  }

  isValid(body) {
    if (
      body.ContentType.includes('pdf') &&
      body.IsImage &&
      !Array.isArray(body.SelectedPages)
    ) {
      return false;
    }

    return this.accept.includes(body.ContentType);
  }

  //
  async downloadFile(fileId: number) {
    try {
      const res = await this.pssrService
        .downloadPSSRFileById(this.pssr.Id, fileId)
        .toPromise();

      this.fileService.downloadByClick({
        file: res.File,
        contentType: res.ContentType,
        description: res.Description,
      });
    } catch (e) {
      this.toastrService.showError( e.error.ExceptionMessage);
    }
  }

  confirmToRemove(pssrFile: PSSRFile) {
    const message = this.translatePipe.transform(
      'ConfirmDeletePSSRFileMessage',
      {
        fileName: pssrFile.Description,
      }
    );
    const header = this.translatePipe.transform('ConfirmDeletePSSRFileHeader');

    this.confirmationService.confirm({
      message,
      header,
      accept: () => {
        this.removeFile(pssrFile.Id);
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }
  //
  async removeFile(fileId: number) {
    try {
      await this.pssrService.removeFileByFileId(fileId).toPromise();

      this.pssrFileService.removeFile(fileId);
    } catch (error) {
      this.toastrService.showError( error.error.ExceptionMessage);
    }
  }
}
