import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { QAQCRequest, QAQCResponse } from '#models/qaqc-milestone';

@Injectable({
  providedIn: 'root',
})
export class QAQCService {
  constructor(private http: HttpClient) {}

  public getQAQCMilestoneBySite(event: QAQCRequest): Observable<QAQCResponse> {

    return this.http.post<QAQCResponse>(
      `${environment.apiEndpoint}/pssr/qaqc/GetMilestoneBySite/`,
      event
    );
  }

  public getQAQCEventBySite(event: QAQCRequest): Observable<QAQCResponse> {

    return this.http.post<QAQCResponse>(
      `${environment.apiEndpoint}/pssr/qaqc/GetEventBySite/`,
      event
    );
  }

  public getQAQCWorkOrderByMilestone(
    event: QAQCRequest
  ): Observable<QAQCResponse> {

    return this.http.post<QAQCResponse>(
      `${environment.apiEndpoint}/pssr/qaqc/GetWOsByMilestone/`,
      event
    );
  }

  public getQAQCWorkOrderBySite(event: QAQCRequest): Observable<QAQCResponse> {

    return this.http.post<QAQCResponse>(
      `${environment.apiEndpoint}/pssr/qaqc/GetWorkOrderBySite/`,
      event
    );
  }
}
