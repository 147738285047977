import { from, Observable } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { AuthService } from '#services/shared';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Utility } from '#services/shared/utility';
import * as MsalUtils from 'app/msal/utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private authServ: AuthService,
    private msalService: MsalService,
    private broadcastService: BroadcastService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const isB2C = window.location.pathname === '/b2c';
    const isB2cLogged = this.authServ.isb2cLoged();

    if (isB2cLogged) {
      return next.handle(
        request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authServ.b2cResp.access_token}`,
          },
        })
      );
    }

    if (isB2C && Utility.validateEnvironment() && !isB2cLogged) {
      return next.handle(request);
    }

    const scopes = this.msalService.getScopesForEndpoint(request.url);
    if (scopes && scopes.length === 0) {
      return next.handle(request);
    }

    const acct = this.msalService.getAccount();
    if (acct === null) {
      return next.handle(request);
    }

    if (MsalUtils.isIframe) {
      return next.handle(request);
    }

    let accessToken = '';
    return from(
      this.msalService
        .ssoSilent({
          loginHint: acct.userName,
          scopes,
        })
        .then(({ idToken: { rawIdToken: token } }) => {
          accessToken = token;

          return request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
        })
    ).pipe(
      mergeMap((authRequest) => next.handle(authRequest)),
      tap(
        () => {
          //
        },
        (error) => {
          if (error instanceof HttpErrorResponse && error.status === 401) {
            this.msalService.clearCacheForScope(accessToken);
            this.broadcastService.broadcast(
              'msal:notAuthorized',
              error.message
            );
          }
        }
      )
    );
  }
}
