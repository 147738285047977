import { ResponseItemImage } from '#models/response-item-image';
import { ResponseStatus } from './enum/response-status';
import { ResponseItemStatus } from './response-item-status';

export class ResponseItem {
  Id: number;
  ExternalId: string;
  AssignedUserID: number;
  AssignedCAI: string;
  AssignedText: string;
  Priority: number;
  Status: ResponseItemStatus;
  StatusName: string;
  Comment: string;
  RejectReason: string;
  Images: ResponseItemImage[];
  IsActionItem: boolean;
  ResponseID: number;
  ResponseGUID: string;
  PSSRID: number;
  PSSRDescription: string;
  AfterWalkthrough: boolean;
  AssignedTo: string;
  OrganizationId: number;
  Action: string;
  GUID: string;

  // TODO: replace old category properties
  PrimaryActionItemCategoryID: number;
  SecondaryActionItemCategoryID: number;
  CategoryID: number;
  CategoryName: string;
  SubCategoryID: number;
  SubCategoryName: string;

  PrimaryCategory: string;
  SecondaryCategory: string;
  CanEditItem: boolean;
  CanReOpenItem: boolean;
  CanRejectItem: boolean;
  ActionID: number;
  DueDate: Date;
  // -- internal
  inEdit: boolean;
  hasFoundImages: boolean;
  hasResolvedImages: boolean;
  isSignature: boolean;
  imageCount: number;
  showLoading: boolean;

  ClosedBy: string;
  ClosedOn: Date;

  CreateDate: Date = new Date();
  CreatedBy: string;

  AssignedGroupID: number;
  AssignedGroupName: string;

  UpdatedDate: Date;

  Links: string[];
  IsDeleted: boolean;

  originalAction: string;
  originalComment: string;

  CurrentUserIsActionItemAssignee = false;

  ResponseStatus: ResponseStatus;
}

export interface ResponseItemLink {
  url: string;
  title: string;
}
