import { Component, OnInit } from '@angular/core';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Router, ActivatedRoute } from '@angular/router';
import { PSSRType } from '#models/pssr-type';
import { PssrTypeService } from '#services/api/pssrtype.service';


import { ToastrService } from '#services/shared/toastr.service';
import { SelectItem } from 'primeng';

@Component({
  selector: 'app-business-unit-edit',
  styleUrls: ['./business-units-edit.component.scss'],
  templateUrl: './business-unit-edit.component.html',
})
export class BusinessUnitEditComponent implements OnInit {
  bu: BusinessUnit = new BusinessUnit();
  displayWarning: boolean;

  months: SelectItem[] = [
    { label: '1 month', value: 1 },
    { label: '2 months', value: 2 },
    { label: '3 months', value: 3 },
    { label: '4 months', value: 4 },
    { label: '5 months', value: 5 },
    { label: '6 months', value: 6 },
    { label: '7 months', value: 7 },
    { label: '8 months', value: 8 },
    { label: '9 months', value: 9 },
    { label: '10 months', value: 10 },
    { label: '11 months', value: 11 },
    { label: '12 months', value: 12 }
  ];

  displayRichEditor: boolean;
  pssrTypes: PSSRType[] = [];
  businessUnitId: number;

  constructor(
    private buService: BusinessUnitsService,
    private router: Router,
    private route: ActivatedRoute,
    private pssrTypeService: PssrTypeService,
    private toastrService: ToastrService
  ) { }

  ngOnInit() {
    this.displayWarning = false;

    this.businessUnitId = +this.route.snapshot.params['id'];

    this.getBuById(this.businessUnitId);
    this.loadPssrTypes();
  }

  onRedirectButtonClick(): void {
    this.router.navigate(['admin/business-units']);
  }

  getBuById(id: number): void {
    this.buService
      .getBusinessUnitById(id)
      .toPromise()
      .then(
        (data: BusinessUnit) => {

          this.bu = { ...data, AllowLeadtoShareDeviceWithApprover: !data.RestrictApproverSignatureOnLeadsDevice };

          this.displayRichEditor =
            this.bu.Instructions && this.bu.Instructions.startsWith('<');
        },
        (error) => {
          this.toastrService.showError(error.error.ExceptionMessage);
        }
      );
  }

  public save(): void {
    this.displayWarning =
      this.bu.Name == undefined ||
      this.bu.Name == null ||
      this.bu.Name.length == 0;
    if (!this.displayWarning) {
      this.buService
        .updateBusinessUnit({
          ...this.bu,
          RestrictApproverSignatureOnLeadsDevice: !this.bu.AllowLeadtoShareDeviceWithApprover
        })

        .subscribe(
          (_data: BusinessUnit) => {
            this.onRedirectButtonClick();
          },
          (error) => {
            this.toastrService.showError(error.error.ExceptionMessage);
          }
        );
    }
  }

  private loadPssrTypes() {
    this.pssrTypeService
      .loadPssrTypes()
      .toPromise()
      .then((types) => (this.pssrTypes = types));
  }

  isPssrTypeChecked = ({ Id }) =>
    this.bu.PssrTypes.some(({ Id: pssrTypeId }) => Id === pssrTypeId)


  pssrTypeChecked({ target: { value, checked } }) {
    const pssrTypeId = +value;

    const hasPssrType = this.bu.PssrTypes.some(({ Id }) => Id === pssrTypeId);

    if (!hasPssrType && checked) {
      this.bu.PssrTypes.push(
        this.pssrTypes.find(({ Id }) => Id === pssrTypeId)
      );
    }

    if (hasPssrType && !checked) {
      this.bu.PssrTypes = this.bu.PssrTypes.filter(
        ({ Id }) => Id !== pssrTypeId
      );
    }
  }
}
