import { ResponseItem, ResponseItemLink } from '#models/response-item';
import { PSSRResponseService, PSSRService } from '#services/api';
import { Utility } from '#services/shared/utility';
import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { AttachedFile } from './attached-file.enum';
import { environment } from '#environments/environment';
import { PssrFileStorageService } from '#services/shared/pssr-file-storage.service';
import { PSSRFile } from '#models/pssr-file';
import { FileService } from '#services/shared/file.service';

export function validateLink(link: string): ValidationErrors {
  let result = null;
  try {
    new URL(link); // tslint:disable-line
  } catch (e) {
    result = {
      invalidUrl: 'Invalid url',
    };
  }
  return result;
}

@Injectable()
export class FilesFormService {
  constructor(
    private pssrService: PSSRService,
    private responseService: PSSRResponseService,
    private pssrFileService: PssrFileStorageService,
    private fileService: FileService
  ) { }
  validateForm = ({ value }: AbstractControl): ValidationErrors => {
    const { addedActionLink, attachFileType, linkItem } = value;

    if (!addedActionLink && attachFileType == AttachedFile.ActionLink) {
      return {
        missingActionLink: 'Action link is missing',
      };
    }
    if (!linkItem && attachFileType == AttachedFile.ProvidedLink) {
      return {
        missingLinkItem: 'Link item is missing',
      };
    }
    if (linkItem && attachFileType == AttachedFile.ProvidedLink) {
      return validateLink(linkItem);
    }

    return null;
  }

  async uploadFiles(
    files: File[],
    responseItem: Partial<ResponseItem>
  ): Promise<Partial<ResponseItem>> {
    const uploadCall = this.prepareUploadServiceCalls(files, responseItem);

    const links = [];
    for await (const linkResponse of uploadCall) {
      if (!!linkResponse) {
        links.push(linkResponse);
      }
    }

    return Promise.resolve({
      ...responseItem,
      Links: [...responseItem.Links, ...links],
    });
  }
  private async *prepareUploadServiceCalls(
    files: File[],
    responseItem: Partial<ResponseItem>
  ) {
    for (const file of files) {
      const btoa = await this.fileService.getFileAsBinaryString(file);
      const description = Utility.extractDescriptionFromFileName(file.name);

      const isImage = PSSRFile.validImageExtensions.includes(file.type);
      if (isImage) {
        const imageGUID = Utility.guid();
        yield this.responseService
          .uploadImage({
            GUID: imageGUID,
            Id: 0,
            Photo: btoa,
            ImageType: 1,
            ResponseItemID: responseItem.Id,
            PSSRID: responseItem.PSSRID,
            Caption: description,
            CreateDate: new Date(),
            ResponseID: responseItem.ResponseID,
            ResponseGUID: null,
            ResponseItemGUID: null,
            ParticipantGUID: null,
            IsDeleted: false,
          })
          .toPromise()
          .then(
            () => '',
            () => ''
          );
      } else {
        yield this.pssrService
          .uploadFile({
            File: btoa,
            PSSRID: responseItem.PSSRID,
            Description: file.name,
            IsImage: false,
            ContentType: file.type,
            CreateDate: new Date(),
            FileType: 0,
            GUID: null,
            Id: 0,
          })
          .toPromise()
          .then(
            ([result]) => {
              this.pssrFileService.addFile(result);

              return `[${description}](${environment.azureContainerEndpoint}/${result.GUID})`;
            },
            () => ''
          );
      }
    }
  }

  parseLinks(links: string[] | null): ResponseItemLink[] {
    const markdownLinksRegex = /\[(.*?)\]\((.*?)\)/im;

    if (!links) { return []; }

    const parsedLinks = [];
    let title = '',
      url = '',
      _ = ''; // tslint:disable-line

    for (let i = 0, len = links.length; i < len; i += 1) {
      try {
        [_, title, url] = links[i].match(markdownLinksRegex);
      } finally {
        parsedLinks.push({
          title,
          url,
        });
      }
    }

    return parsedLinks;
  }
}
