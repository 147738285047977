import { Organization, Area, SubArea, Facility, Project, TaskPlan } from '.';

export class ActionItemFilter {
    BUId: number;
    OrganizationId: number;
    AreaId: number;
    SubAreaId: number;
    FacilityId: number;
    Status: number[];
    Priority: number[];

    PSSRStatus: number[];
    PSSRName: string;
    TaskPlanId: number;
    ProjectId: number;
    Organization?: Organization;
    Area?: Area;
    SubArea?: SubArea;
    Facility?: Facility;
    Project?: Project;
    TaskPlan?: TaskPlan;
    ShowImages: boolean;
    ShowDeletedPssr: boolean;
    ProjectNumber: string;
    AssignedUserId: number;
    AssignedUserEmail: string;
    PssrTypeIds: number[];
    LeadId: number;
    ActionItemsList: number[] = [];
}

export class ActionItemStatus {
    Id: number;
    Name: string;
}

export class ActionItemPriority {
    Id: number;
    Name: string;
}

export class ActionItemPriorityLegend {
    Id: number;
    Name: string;
    Legend: string;
}
