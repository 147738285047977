import { Component, OnInit } from '@angular/core';
import { SubAreaService } from '#services/api/sub-area.service';
import { SubArea } from '#models/sub-area';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';


import { ToastrService } from '#services/shared/toastr.service';


@Component({
  selector: 'app-sub-area-create',
  templateUrl: './sub-area-create.component.html',
})
export class SubAreaCreateComponent implements OnInit {
  subArea: SubArea = new SubArea();
  displayWarningName: boolean;
  buId: number;
  hierarchyLabel2: string;


  selectedBU: BusinessUnit;

  constructor(

    private toastrService: ToastrService,
    private subAreaService: SubAreaService,
    private router: Router,
    private route: ActivatedRoute,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.buId = this.route.snapshot.params['buId'];
    this.subArea.Id = this.route.snapshot.params['subAreaId'];
    this.subArea.AreaId = this.route.snapshot.params['areaId'];
    this.hierarchyLabel2 = this.route.snapshot.params['hierarchyLabel2'];

    this.displayWarningName = false;

    if (this.buId > 0) {
      this.businessUnitService
        .getBusinessUnitById(this.buId)
        .toPromise()
        .then((bu) => (this.selectedBU = bu));
    }
  }

  onRedirectButtonClick(areaId: number): void {
    this.router.navigate([
      'admin/sub-area',
      { buId: this.buId, areaId: areaId },
    ]);
  }

  public createSubArea(): void {
    const invalidName =
      this.subArea.Name == undefined ||
      this.subArea.Name == null ||
      this.subArea.Name.length == 0;
    this.displayWarningName = invalidName;
    if (!invalidName) {

      this.subAreaService
        .createSubArea(this.subArea)

        .subscribe(
          (data: SubArea) => {
            this.onRedirectButtonClick(data.AreaId);
          },
          (error) => {
            this.toastrService.showError( error.error.ExceptionMessage);
          }
        );
    }
  }
}
