<div class="box-shadow module">
  <a #msgFocusId href=""></a>

  <div class="row">
    <h2 [innerHTML]="pageTitle | translate"></h2>
  </div>

  <form (ngSubmit)="addPSSR()" name="addPSSRForm" #addPSSRForm="ngForm">
    <div class="group">
      <div class="group-header">
        <h3>{{ "Location" | translate }}</h3>
      </div>

      <div class="group-item col-md-3">
        <label>{{ "BusinessUnit" | translate }}</label>
        <p-dropdown id="selectBU" name="selectBU" [appBUSwitcher]="selectedBU" [options]="lstBUs"
          [(ngModel)]="selectedBU" [styleClass]="'text-left'" [style]="{ width: '100%' }" (onChange)="changeBU()"
          optionLabel="Name" appendTo="body" (onClick)="loadBUs()" placeholder="{{ 'Select Option' | translate }}">
        </p-dropdown>
      </div>

      <div class="group-item col-md-3">
        <label>{{ (selectedBU.OrganizationLabel || 'Organization') | translate }} </label>
        <p-dropdown id="selectOrg" name="selectOrg" [appBUSwitcher]="selectedOrg" [options]="lstOrgs"
          [(ngModel)]="selectedOrg" [styleClass]="'text-left'" [style]="{ width: '100%' }"
          (onChange)="changeOrganization()" optionLabel="Name" appendTo="body" (onClick)="loadOrganizations()"
          placeholder="{{ 'Select Option' | translate }}"
          [disabled]="!(selectedBU !== null && selectedBU?.Id &gt; 0)">
        </p-dropdown>
      </div>

      <div class="group-item col-md-3">
        <label>{{ (selectedBU.HierarchyLabel1 || 'HierarchyLabel1') | translate }} </label>
        <p-dropdown id="selectArea" name="selectArea" [options]="lstArea" [(ngModel)]="selectedArea"
          [styleClass]="'text-left'" (onClick)="loadArea()" [style]="{ width: '100%' }" (onChange)="changeArea()"
          optionLabel="Name" appendTo="body" placeholder="{{ 'Select Option' | translate }}"
          [disabled]="!(selectedOrg !== null && selectedOrg?.Id  &gt; 0)">
        </p-dropdown>
      </div>

      <div class="group-item col-md-3">
        <label>{{ (selectedBU.HierarchyLabel2 || 'HierarchyLabel2') | translate }} </label>
        <p-dropdown id="selectSubArea" name="selectSubArea" [options]="lstSubArea" [(ngModel)]="selectedSubArea"
          [styleClass]="'text-left'" (onClick)="loadSubArea()" [style]="{ width: '100%' }" optionLabel="Name"
          appendTo="body" placeholder="{{ 'Select Option' | translate }}" (onChange)="onChangeDisableAll()"
          [disabled]="!(selectedArea && selectedArea?.Id  &gt; 0)">
        </p-dropdown>
      </div>
    </div>

    <div class="group">
      <div class="group-header">
        <h3>{{ "Import" | translate }}</h3>
      </div>

      <div class="group-item col-md-5">
        <label>{{ 'Application' | translate }}</label>
        <p-dropdown id="selectImport" name="selectImport" [options]="applicationList"
          [(ngModel)]="selectedApplication" [styleClass]="'text-left'" [style]="{ width: '100%' }" optionLabel="Name"
          appendTo="body" placeholder="{{ 'Select Option' | translate }}" (onChange)="onChangeDisableAll()"
          (onClick)="loadApplications()" [disabled]="!(selectedSubArea && selectedSubArea?.Id  &gt; 0)">
        </p-dropdown>
      </div>

      <div class="group-item col-md-5">
        <label>{{'ID' | translate}}</label>
        <input type="text" required  pattern="^[a-zA-Z0-9-_]+$"
          class="form-control text-box single-line" maxlength="20" [(ngModel)]="pssrId" name="pssrIdText"
            [disabled]="importAllStarted || disableAll" #pssrIdText="ngModel"/>
      </div>

      <div class="group-item col-md-2">
        <button id="addPSSR" [disabled]="!addPSSRForm.form.valid || importAllStarted || disableAll" type="submit"
          class="btn btn-primary add-btn" style="width: 100%;">
          {{ "Add" | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="group">
    <div class="group-item col-md-12">
      <div class="row">
        <table class="table table-hover table-condensed">
          <thead>
            <tr class="bg-info">
              <th id="thChkBox" style="width:5%;">
                <input [(ngModel)]="isAllSelected" id="selectAll" name="selectAll" type="checkbox" (change)="selectAll()"
                  [disabled]="importAllStarted" />
              </th>
              <th id="thId" style="width:5%;">
                {{ "ID" | translate }}
              </th>
              <th id="thPSSRId" style="width:5%;">
                {{ "PSSR ID" | translate }}
              </th>
              <th id="thName" style="width:80%;">
                {{ "Name" | translate }}
              </th>
              <th id="thStatus" style="width:10%;">
                {{ "Status" | translate }}
              </th>
            </tr>
          </thead>

          <tbody *ngFor="let import of importList">
            <tr>
              <td style="width:5%;">
                <input [(ngModel)]="import.selected" (change)="invertSelection()"
                  [disabled]="importAllStarted || import.status === ImportStatusEnum.Imported" type="checkbox" />
              </td>
              <td style="width:5%;">
                <span class="row">
                  {{ import.id }}
                </span>
              </td>
              <td style="width:5%;">
                <span class="row">
                  <a *ngIf="!!import.pssrId" (click)="editPSSR(import.pssrId)" style="cursor: pointer" target="_blank">{{ import.pssrId }}</a>                 
                </span>
              </td>
              <td style="width:80%;">
                {{import.name}}
              </td>
              <td *ngIf="import.status === ImportStatusEnum.Error" style="width:10%;">
                <div class="import-status-error" title="{{import.error}}">
                  <span class="error-row">{{ "Error" | translate }} </span>
                  <i style="padding-top:1px;" class="pi pi-exclamation-triangle error-row"></i>
                </div>
              </td>
              <td *ngIf="import.status === ImportStatusEnum.Warning" style="width:10%;">
                <div class="import-status-error" title="{{import.error}}">
                  <span class="warning-row">{{ "Warning" | translate }} </span>
                  <i style="padding-top:1px;" class="pi pi-exclamation-triangle warning-row"></i>
                </div>
              </td>
              <td *ngIf="import.status !== ImportStatusEnum.Error && import.status !==ImportStatusEnum.Warning" class="col-sm-2">
                {{ getStatusText(import.status) | translate }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row" style="align-items: baseline; display: flex;">
        <div class="col-md-1 p-0 text-overflow">
          {{ "Records" | translate }} : {{importList?.length}}
        </div>
        <div class="col-md-1 p-0 text-overflow">
          {{ "Imported" | translate }} : {{importedCount}}
        </div>
        <div class="col-md-1 p-0 text-overflow">
          {{ "Errors" | translate }}: {{errorCount}}
        </div>
        <div class="col-md-7">&nbsp;</div>
        <div class="col-md-2 import-all-container">
          <button id="importAll" [disabled]="!enableImportAll" type="button" class="btn btn-primary"
            style="width: 100%" (click)="importAll()">
            {{ "ImportAll" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
