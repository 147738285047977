import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { BusinessUnit, PSSRResponse, TaskPlanSection } from '#models/index';
import { ResponseItem } from '#models/response-item';
import { ResponseItemImage } from '#models/response-item-image';
import { ActionItemFilter } from '#models/action-item-filter';
import { PSSR } from '#models/pssr';
import { PSSRStatus } from '#models/pssr-status';
import { ResponseItemStatus } from '#models/response-item-status';
import { exhaustMap, map, tap } from 'rxjs/operators';
import { UserRole } from '#models/enum/user-role';
import { SectionType } from '#models/enum/section-type';
import { BusinessUnitsService } from './business-unit.service';
import { Utility } from '#services/shared/utility';
import { ResponseValidator } from '#services/shared/response-validator.service';
import { ValidationErrors } from '@angular/forms';
import { PagedList, PaginationRequest } from '#models/pagination';
import * as moment from 'moment';
import { SectionUser } from '#models/section/section';
import { PSSRUser } from '#models/pssr-user';
import { BusinessUnitCode } from '#models/business-unit-code';
import { ResponseItemPriority } from '#models/enum/response-item-priority';

@Injectable()
export class PSSRResponseService {
  constructor(
    private http: HttpClient,
    private businessUnitService: BusinessUnitsService,
    private responseValidator: ResponseValidator
  ) { }

  public create(pssrResponse: PSSRResponse): Observable<PSSRResponse> {
    return this.http.post<PSSRResponse>(
      `${environment.apiEndpoint}/response`,
      pssrResponse
    );
  }

  public update(pssrResponse: PSSRResponse): Observable<PSSRResponse> {
    return this.http.put<PSSRResponse>(
      `${environment.apiEndpoint}/response`,
      { ...pssrResponse, ActionItems: [] }
    );
  }

  public clearResponse(pssrResponse: PSSRResponse): Observable<PSSRResponse> {
    return this.http.put<PSSRResponse>(
      `${environment.apiEndpoint}/response/clearResponse`,
      pssrResponse
    );
  }

  canAddActionItem(response: PSSRResponse, pssr: PSSR) {
    if (
      [PSSRStatus.Draft, PSSRStatus.Approved, PSSRStatus.Closed].includes(
        pssr.Status
      )
    ) {
      return false;
    }

    if (response.SectionID == null && pssr.Status < PSSRStatus.Initiated) {
      return false;
    }
    return (
      response.CanAnswer ||
      pssr.CurrentUser.CanAddItem ||
      this.businessUnitService.allowMembersToCompletePSSR(pssr.buObj, pssr)
    );
  }

  public delete(responseId: number) {
    return this.http.delete<PSSRResponse[]>(
      `${environment.apiEndpoint}/response/${responseId}`
    );
  }

  public getAllByPSSRId(pssrId: number): Observable<PSSRResponse[]> {
    return this.http.get<PSSRResponse[]>(
      `${environment.apiEndpoint}/response/pssrResponses/${pssrId}`
    );
  }

  public createByPSSRSection(
    pssrId: number,
    section: TaskPlanSection
  ): Observable<PSSRResponse[]> {
    return this.http.post<PSSRResponse[]>(
      `${environment.apiEndpoint}/response/createByPSSRSection/${pssrId}`,
      section
    );
  }

  public deleteByPSSRSection(
    pssrId: number,
    taskPlanSectionId: number,
    taskPlanSectionDescription?: string
  ) {
    let params = {};
    if (taskPlanSectionDescription !== undefined) {
      params = {
        params: {
          taskPlanSectionDescription,
        },
      };
    }
    return this.http.delete<PSSRResponse[]>(
      `${environment.apiEndpoint}/response/deleteByPSSRSection/${pssrId}/${taskPlanSectionId}`,
      params
    );
  }

  public getStandAloneItems(pssrId: number): Observable<ResponseItem[]> {
    return this.http.get<ResponseItem[]>(
      `${environment.apiEndpoint}/response/standAloneItems/${pssrId}`
    );
  }

  public updateActionItem(
    responseItem: Partial<ResponseItem>
  ): Observable<ResponseItem> {
    return this.http
      .post<ResponseItem>(
        `${environment.apiEndpoint}/response/updateActionItem`,
        {
          ...responseItem,
          Comment: Utility.toHtmlEntities(responseItem.Comment),
          DueDate: moment(responseItem.DueDate).format()
        }
      )
      .pipe(
        map((res) => {
          return { ...res, DueDate: res.DueDate && new Date(res.DueDate) };
        })
      );
  }

  public deleteResponseItem(itemId: number): Observable<ResponseItem> {
    return this.http.delete<ResponseItem>(
      `${environment.apiEndpoint}/response/deleteResponseItem/${itemId}`
    );
  }

  public getPagedActionItems(filter: ActionItemFilter, pagination: PaginationRequest): Observable<PagedList<PSSR>> {
    const requestParams = {
      Filter: filter,
      Pagination: pagination,
    };

    return this.http.post<PagedList<PSSR>>(
      `${environment.apiEndpoint}/response/paged-list`,
      requestParams
    );
  }

  public getImagesByActionItem(
    actionItemId: number
  ): Observable<ResponseItemImage[]> {
    return this.http.get<ResponseItemImage[]>(
      `${environment.apiEndpoint}/response/getImageList/${actionItemId}`
    );
  }

  public getImageByGUID(
    responseItemId: number,
    imageGUID: string
  ): Observable<ResponseItemImage> {
    return this.http.get<ResponseItemImage>(
      `${environment.apiEndpoint}/response/${responseItemId}/getImage2/${imageGUID}`
    );
  }

  private hasActionItemsCallbackFn =
    (
      priority: number = 3,
      statuses: ResponseItemStatus[] = [
        ResponseItemStatus.Open,
        ResponseItemStatus.Rejected,
      ]
    ) =>
      (f: ResponseItem) =>
        f.Priority === priority && statuses.includes(f.Status) && f.IsActionItem

  public hasActionItems = ({
    responses = [],
    standAloneItems = [],
    priority = 3,
    statuses = [ResponseItemStatus.Rejected],
  }: {
    responses?: PSSRResponse[];
    standAloneItems?: ResponseItem[];
    priority?: number;
    statuses?: ResponseItemStatus[];
  }) => (
    standAloneItems.some(this.hasActionItemsCallbackFn(priority, statuses)) ||
    responses.some(
      (r) =>
        r.ActionItems != null &&
        r.ActionItems.some(this.hasActionItemsCallbackFn(priority, statuses))
    )
  )

  private validateResponses({
    responses,
    bu,
  }: {
    responses: PSSRResponse[];
    bu: BusinessUnit;
  }): ValidationErrors | null {
    return responses.reduce<ValidationErrors | null>((a, b) => {
      const validator = this.responseValidator.validate(bu)({ value: b });
      if (validator === null && a === null) {
        return null;
      }
      if (validator === null && a !== null) {
        return a;
      }
      if (validator !== null && a === null) {
        return validator;
      }
      return { ...a, ...validator };
    }, null);
  }

  private getValidationMessageWhenPssrIsPendingApproval(
    pssr: PSSR,
    lstResponse: PSSRResponse[] = [],
    standAloneItems: ResponseItem[] = []
  ): string | null {
    const p3sRejected = this.hasActionItems({
      responses: lstResponse,
      priority: 3,
      standAloneItems: standAloneItems,
      statuses: [ResponseItemStatus.Rejected],
    });

    if (p3sRejected && pssr.CurrentUser.Role == UserRole.Approver) {
      return null;
    }
    if (pssr.NextStatus == null) {
      return 'OnlyFinalApprovercanApprovePSSR';
    }
    if (!pssr.p3Closed) {
      return 'P3ActionItemsMustBeClosed';
    }

    return null;
  }

  private getValidationMessageWhenPsssrIsDraft(pssr: PSSR): string | null {
    const noFinalApprover = !pssr.PSSRUsers.Users.some(
      (u) => u.Role == UserRole.Approver
    );

    if (!pssr.CurrentUser.CanEdit) {
      return 'OnlyPSSRTeamLeadcanInitiatePSSR';
    }
    if (pssr.buObj.RequireFinalApproverBeforeInitiation && noFinalApprover) {
      return 'RequireFinalApproverBeforeInitiation';
    }
    return null;
  }

  private getValidationMessageWhenPsssrIsInProgess(
    pssr: PSSR,
    lstResponse: PSSRResponse[] = []
  ): string | null {
    const noFinalApprover = !pssr.PSSRUsers.Users.some(
      (u) => u.Role == UserRole.Approver
    );

    if (
      this.validateResponses({
        bu: pssr.buObj,
        responses: lstResponse,
      }) !== null
    ) {
      return 'Answer all questions and signatures to Complete the PSSR';
    }
    if (noFinalApprover) {
      return 'PSSR must have a Final Approver to complete Walkdown';
    }
    return null;
  }

  private getValidationMessageWhenPssrIsCompleted(pssr: PSSR): string | null {
    if (pssr.buObj && pssr.buObj.AllowApproverToSubmitForApproval && pssr.CurrentUser.Role === UserRole.Approver) {
      return null;
    }
    if (!pssr.CurrentUser.CanEdit) {
      return 'OnlyPSSRTeamLeadcanSubmitforPendingApproval';
    }
    if (!pssr.p3Closed) {
      return 'P3ActionItemsMustBeClosed';
    }
    return null;
  }

  private getValidationMessageWhenPssrIsApproved(pssr: PSSR): string | null {
    if (!pssr.CurrentUser.CanEdit && !pssr.CurrentUser.IsBusinessUnitAdmin) {
      return 'OnlyPSSRTeamLeadClosePSSR';
    }
    if (!pssr.p3Closed) {
      return 'P3ActionItemsMustBeClosed';
    }
    if (!pssr.p4Closed) {
      return 'P4ActionItemsMustBeClosed';
    }
    return null;
  }

  private getValidationMessage(
    pssr: PSSR,
    lstResponse: PSSRResponse[] = [],
    standAloneItems: ResponseItem[] = []
  ): string {
    switch (pssr.Status) {
      case PSSRStatus.Draft:
        return this.getValidationMessageWhenPsssrIsDraft(pssr);

      case PSSRStatus.InProgress:
        return this.getValidationMessageWhenPsssrIsInProgess(pssr, lstResponse);

      case PSSRStatus.Completed:
        return this.getValidationMessageWhenPssrIsCompleted(pssr);

      case PSSRStatus.PendingApproval:
        return this.getValidationMessageWhenPssrIsPendingApproval(
          pssr,
          lstResponse,
          standAloneItems
        );

      case PSSRStatus.Approved:
        return this.getValidationMessageWhenPssrIsApproved(pssr);
    }

    return null;
  }

  public validateItemPriority(
    pssr: PSSR,
    lstResponse: PSSRResponse[] = [],
    standAloneItems: ResponseItem[] = []
  ) {
    const p3sRejected = this.hasActionItems({
      responses: lstResponse,
      priority: ResponseItemPriority.P3,
      standAloneItems: standAloneItems,
      statuses: [ResponseItemStatus.Rejected],
    });

    pssr.p3Closed =
      !this.hasActionItems({
        responses: lstResponse,
        standAloneItems,
        priority: ResponseItemPriority.P3,
        statuses: [ResponseItemStatus.Open, ResponseItemStatus.Rejected]
      }) &&
      pssr.Status >= PSSRStatus.Completed;

    pssr.p4Closed =
      !this.hasActionItems({
        responses: lstResponse,
        standAloneItems,
        priority: ResponseItemPriority.P4,
        statuses: [ResponseItemStatus.Open, ResponseItemStatus.Rejected]
      });

    pssr.validationMsg = this.getValidationMessage(
      pssr,
      lstResponse,
      standAloneItems
    );

    if (
      pssr.Status === PSSRStatus.PendingApproval &&
      p3sRejected &&
      pssr.CurrentUser.Role == UserRole.Approver
    ) {
      pssr.NextStatus = 'Walkdown Complete';
      pssr.NextStatusId = PSSRStatus.Completed;
    }

    return pssr;
  }

  public export(filter: ActionItemFilter): Observable<Object> {
    const result = this.http.post<Object>(
      `${environment.apiEndpoint}/response/downloadExcel`,
      filter,
      { responseType: 'blob' as 'json' }
    );
    return result;
  }

  canRespond(response: PSSRResponse, pssr: PSSR): boolean {
    if (![PSSRStatus.Initiated, PSSRStatus.InProgress].includes(pssr.Status)) {
      return false;
    }

    if (response.SectionID == null && pssr.Status < PSSRStatus.Initiated) {
      return false;
    }

    return (
      response.CanAnswer ||
      this.businessUnitService.allowMembersToCompletePSSR(pssr.buObj, pssr)
    );
  }

  canDeleteResponse(response: PSSRResponse, pssr: PSSR) {
    if (![PSSRStatus.Initiated, PSSRStatus.Draft].includes(pssr.Status)) {
      return false;
    }

    if (
      (!response.SectionID || !response.ItemCode) &&
      pssr.CurrentUser.CanEdit
    ) {
      return true;
    }

    return (
      pssr.buObj.AllowLeadsDeleteQuestions &&
      pssr.CurrentUser.Role === UserRole.Lead
    );
  }
  isBlankSection(section: TaskPlanSection) {
    return section.Type === SectionType.StandAlone
      ? section.StandAloneItems.length === 0
      : section.Responses.length === 0;
  }
  isVisibleSection(section: TaskPlanSection, pssr: PSSR) {
    switch (section.Type) {
      case SectionType.Body: {
        return !this.isBlankSection(section);
      }
      case SectionType.ProjectSpecific: {
        return (
          !this.isBlankSection(section) ||
          ((pssr.Status === PSSRStatus.Draft ||
            pssr.Status === PSSRStatus.Initiated) &&
            pssr.CurrentUser.CanEdit)
        );
      }
      case SectionType.StandAlone: {
        if (
          pssr.Status >= PSSRStatus.Approved &&
          this.isBlankSection(section)
        ) {
          return false;
        }

        if (pssr.Status < PSSRStatus.Completed) { return false; }

        return true;
      }
      case SectionType.Signature: {
        return (
          pssr.Status !== PSSRStatus.Draft && !this.isBlankSection(section)
        );
      }
    }
  }
  uploadImage(image: ResponseItemImage) {
    return this.http.post(
      `${environment.apiEndpoint}/response/upload-image`,
      image
    );
  }

  signResponse({
    response,
    signature,
    signatureDate,
    signedBy,
  }: {
    response: PSSRResponse;
    signature: string;
    signatureDate: Date;
    signedBy: string;
  }): Observable<PSSRResponse> {
    return this.updateActionItem({
      Id: 0,
      ExternalId: null,
      AssignedUserID: null,
      AssignedGroupID: null,
      AssignedGroupName: null,
      AssignedTo: null,
      AssignedCAI: null,
      AssignedText: null,
      Priority: 0,
      Status: 0,
      Comment: `Completed on Web by: ${signedBy}, at: ${signatureDate.toISOString()}`,
      RejectReason: null,
      IsActionItem: false,
      ResponseID: response.Id,
      GUID: Utility.guid(),
      ResponseGUID: null,
      PSSRID: response.PSSRID,
      Action: null,
      PrimaryActionItemCategoryID: null,
      SecondaryActionItemCategoryID: null,
      ActionID: null,
      CreateDate: signatureDate,
      ClosedBy: null,
      ClosedOn: null,
      DueDate: null,
      CategoryID: null,
      CategoryName: null,
      SubCategoryID: null,
      SubCategoryName: null,
      Links: [],
    }).pipe(
      tap((responseItem) => (response.ActionItems = [responseItem])),
      exhaustMap((responseItem) => {
        return this.uploadImage({
          GUID: Utility.guid(),
          Id: 0,
          Photo: signature,
          ResponseItemGUID: null,
          ImageType: 1,
          ResponseItemID: responseItem.Id,
          PSSRID: response.PSSRID,
          Caption: signedBy,
          CreateDate: signatureDate,
          ResponseID: response.Id,
          ResponseGUID: null,
          ParticipantGUID: null,
          IsDeleted: false,
        }).pipe(
          exhaustMap(() =>
            this.getImagesByActionItem(responseItem.Id).pipe(
              tap((images) => {
                response.ActionItems[0].Images = images;
              })
            )
          ),
          map(() => response)
        );
      })
    );
  }

  updatePssrSection(
    pssrId,
    {
      sectionId,
      sectionDescription,
      newSection,
    }: {
      sectionId: number;
      sectionDescription: string;
      newSection: TaskPlanSection;
    }
  ): Observable<PSSRResponse[]> {
    return this.http.put<PSSRResponse[]>(
      `${environment.apiEndpoint}/response/updatePSSRSection/${pssrId}`,
      newSection,
      {
        params: {
          sectionId: `${sectionId}`,
          sectionDescription,
        },
      }
    );
  }

  getListOfSections(responses: PSSRResponse[]): string[] {
    return responses
      .filter(
        (response, index, list) =>
          list.findIndex(
            (item) => item.SectionDescription === response.SectionDescription
          ) === index
      )
      .map(({ SectionDescription }) => SectionDescription);
  }

  isSectionLead(args:
    { sectionDescription: string, sectionId: number, sectionUser: SectionUser, currentUser: PSSRUser, businessUnitCode: BusinessUnitCode }
  ): boolean {
    const { sectionDescription = '', sectionId = 0, sectionUser, currentUser, businessUnitCode = BusinessUnitCode.Default } = args;

    return (sectionUser.SectionDescription == sectionDescription || sectionUser.SectionID == sectionId) &&
      (
        (
          sectionUser.UserID === currentUser.UserID || currentUser.ParentUsers.includes(sectionUser.UserEmail)
        ) ||
        (
          businessUnitCode === BusinessUnitCode.TCO &&
          currentUser.ParentUsers.some(parentUser =>
            sectionUser.ParentUsers.includes(parentUser)
          )
        )
      );
  }
}
