<div class="box-shadow module">
  <div class="row">
    <h2> Device Types </h2>
  </div>

  <div class="row padding-bottom-10 component-separator">
    <div class="col-xs-6">
      <label>Business Unit :</label>
      <select [appBUSwitcher]="selectedBU" class="form-control" [(ngModel)]="selectedBU"
        (change)="loadEndDeviceTypesByBU()">
        <option *ngFor="let bu of businessUnitList" [ngValue]="bu">
          {{ bu.Name }}
        </option>
      </select>
    </div>
  </div>

  <table class="table table-hover component-separator" *ngIf="selectedBU">
    <tr>
      <th scope="col" class="col-xs-2">Name</th>
      <th scope="col" class="col-xs-2">EAM Name</th>
      <th scope="col" class="col-xs-2">EAM Description</th>
      <th scope="col" class="col-xs-2">EAM Class</th>
      <th scope="col" class="col-xs-1">Serial Number Required</th>
      <th scope="col" class="col-xs-1">Add to EAM</th>
      <th scope="col" class="col-xs-2" style="text-align:end; padding: 1px">
        <button type="button" class="btn btn-success" (click)="onRedirectButtonClick('admin/end-device-type-form', 0)"
          style="width:80px;">New</button>
      </th>
    </tr>
    <tr *ngFor="let d of lstEndDeviceType">
      <td class="col-xs-2">{{d.Name}}</td>
      <td class="col-xs-2">{{d.EAMName}}</td>
      <td class="col-xs-2">{{d.Description}}</td>
      <td class="col-xs-2">{{d.EAMClass}}</td>
      <td class="col-xs-1">
        <input style="padding-right: 0px;padding-left: 0px;text-align: center;" type="checkbox"
          [(ngModel)]="d.IsSerialNumReq" value="d.IsSerialNumReq" [disabled]="true">
      </td>
      <td class="col-xs-1">
        <input style="padding-right: 0px;padding-left: 0px;text-align: center;" type="checkbox" [(ngModel)]="d.IsInEAM"
          value="d.IsInEAM" [disabled]="true">
      </td>
      <td class="col-xs-2" style="text-align:end">
        <a (click)="onRedirectButtonClick('admin/end-device-type-form', d.Id)" style="cursor: pointer;">Edit</a>
      </td>
    </tr>
  </table>
</div>
