import { Component, OnInit } from '@angular/core';
import {
  BusinessUnitsService,
  OrganizationService,
  UserService,
} from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { Router, ActivatedRoute } from '@angular/router';
import { OrganizationUser } from '#models/organization-user';
import { Organization } from '#models/organization';
import { User } from '#models/user';
import { ConfirmationService } from 'primeng/api';
import { Utility } from '#services/shared/utility';
import { UserType } from '#models/enum/user-type';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';
import { FormControl, Validators } from '@angular/forms';
import { BusinessUnitCode } from '#models/business-unit-code';

@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  providers: [TranslatePipe],
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit {
  UserType = UserType;

  sharedUsersCtrlInvalid: boolean;
  orgUsr: OrganizationUser = new OrganizationUser();
  orgUsrCpy: OrganizationUser = new OrganizationUser();
  displayWarning: boolean;
  lstBusinessUnit: BusinessUnit[] = [];
  lstOrganization: Organization[] = [];
  selectedBU: BusinessUnit = new BusinessUnit();
  selectedOrg: Organization;
  selectedUser: User;
  users: User[];

  BusinessUnitCode = BusinessUnitCode;

  constructor(
    private orgService: OrganizationService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private toastrService: ToastrService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private buService: BusinessUnitsService,
    private translatePipe: TranslatePipe
  ) { }

  async ngOnInit() {
    this.populateInformationFromRoute(this.activeRoute.snapshot.params);

    this.activeRoute.params.subscribe((p) =>
      this.populateInformationFromRoute(p)
    );

    await this.loadBUs();
    await this.loadBusinessUnits();
    await this.loadOrganizations();
    await this.getOrgUser();
    this.displayWarning = false;
  }

  populateInformationFromRoute(routeParams) {
    this.orgUsr.OrganizationID = +routeParams['orgId'];
    this.orgUsr.UserID = +routeParams['userId'];
    this.selectedBU.Id = +routeParams['buId'];

    if (+routeParams['isNew'] === 1) {
      this.toastrService.showSuccess(
        this.translatePipe.transform('User saved successfully!')
      );
    }
  }

  onRedirectButtonClick(): void {
    sessionStorage.setItem('showMessage', 'true');
    setTimeout(() => { this.router.navigate(['admin/users']); }, 400); // To overcome Popup destory before routing to list page added setTimeout
  }

  async loadBusinessUnits() {
    if (this.selectedBU) {
      await this.buService
        .getBusinessUnitById(this.selectedBU.Id)
        .toPromise()
        .then((bu) => {
          this.selectedBU = bu;
        });
    }
  }

  async loadOrganizations() {
    if (Utility.isValidObj(this.selectedBU)) {
      await this.orgService
        .getUserAdminOrgs(this.selectedBU.Id)
        .toPromise()
        .then((data: Organization[]) => {
          this.lstOrganization = data;
          this.selectedOrg = this.lstOrganization.find(
            (x) => x.Id === this.orgUsr.OrganizationID
          );
          if (!Utility.isValidObj(this.selectedOrg)) {
            this.selectedOrg = this.lstOrganization[0];
          }
          this.orgUsr.OrganizationID = this.selectedOrg.Id;
        });
    } else {
      this.orgUsr.OrganizationId = 0;
      this.lstOrganization = [];
    }
  }

  async getOrgUser() {
    await this.orgService
      .getOrganizationUser(this.orgUsr.OrganizationID, this.orgUsr.UserID)
      .toPromise()
      .then(
        (data) => {
          this.orgUsr = data === null ? null : {
            ...data,
            IsTcoWhitePagesImported:
              this.selectedBU.Code === BusinessUnitCode.TCO
                ? data.IsTcoWhitePagesImported !== false
                : data.IsTcoWhitePagesImported
          };
          this.orgUsrCpy = data === null ? null : {
            ...data, IsTcoWhitePagesImported:
              this.selectedBU.Code === BusinessUnitCode.TCO
                ? data.IsTcoWhitePagesImported !== false
                : data.IsTcoWhitePagesImported
          };
          this.selectedUser = new User(this.orgUsr);
        },
        (error) => {
          this.toastrService.showWarning(error.error.ExceptionMessage);
        }
      );
  }

  async loadBUs() {
    await this.buService
      .getUserBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
      });
  }

  getUsers({ query }) {
    this.userService.searchUser(query).subscribe((data) => {
      this.users = data.map((user) => ({
        ...user,
        FullName: Utility.getFullName(user),
      }));
    });
  }

  getUsersFullName() {
    return this.users?.map((u) => u.FullName);
  }

  onSharedUsersListChanged(event) {
    const { users, isValid } = event;

    this.sharedUsersCtrlInvalid = !isValid;
    this.orgUsr.ChildUsers = users;
  }

  async save() {
    if (this.orgUsr.Type === UserType.Chevron) {
      this.orgUsr.FirstName = this.selectedUser.FirstName;
      this.orgUsr.MiddleName = this.selectedUser.MiddleName;
      this.orgUsr.LastName = this.selectedUser.LastName;
      this.orgUsr.CAI = this.selectedUser.CAI;
      this.orgUsr.Email = Utility.isValidObj(this.selectedUser)
        ? this.selectedUser.Email
        : null;
    }

    await this.orgService
      .updateOrganizationUser(this.orgUsr)
      .toPromise()
      .then(
        () => this.onRedirectButtonClick(),
        (error) => this.toastrService.showWarning(error.error.ExceptionMessage)
      );
  }

  removeUser() {
    const message = this.translatePipe.transform('Recordwillbepermanentlyremovedcontinue');

    this.confirmationService.confirm({
      message: message,
      header: this.translatePipe.transform('Confirmdelete'),
      accept: () => {
        this.deleteUser();
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  async deleteUser() {
    await this.orgService
      .removeOrganizationUser(this.orgUsr.Id)
      .toPromise()
      .then(
        () => {
          sessionStorage.setItem('messageType', 'remove');
          this.onRedirectButtonClick();
        },
        (error) => {
          this.toastrService.showWarning(error.error.ExceptionMessage);
        }
      );
  }

  async approveAccess() {
    if (JSON.stringify(this.orgUsr) === JSON.stringify(this.orgUsrCpy)) {
      this.orgUsr.HasAccess = true;

      await this.orgService
        .updateOrganizationUser(this.orgUsr)

        .toPromise()
        .then(
          () => {
            sessionStorage.setItem('messageType', 'approve');
            this.onRedirectButtonClick();
          },
          (error) => {
            this.orgUsr.HasAccess = false;
            this.toastrService.showWarning(error.error.ExceptionMessage);
          }
        );
    } else {
      await this.orgService.removeOrganizationUser(this.orgUsr.Id).toPromise();
      await this.orgService
        .addOrganizationUser(this.orgUsr)

        .toPromise()
        .then(
          () => {
            this.onRedirectButtonClick();
          },
          (error) => {
            this.orgUsr.HasAccess = false;
            this.toastrService.showWarning(error.error.ExceptionMessage);
          }
        );
    }
  }
  validateUserInput() {
    const errors = [];

    if (
      this.orgUsr.Type == UserType.Chevron &&
      !Utility.isValidString(this.orgUsr.Email)
    ) {
      errors.push(this.translatePipe.transform('User is required.'));
    }

    if (
      this.orgUsr.Type == UserType.External &&
      !Utility.isValidString(this.orgUsr.FirstName)
    ) {
      errors.push(this.translatePipe.transform('First Name is required.'));
    }

    if (
      this.orgUsr.Type == UserType.Shared &&
      !Utility.isValidString(this.orgUsr.FirstName)
    ) {
      errors.push(
        this.translatePipe.transform('Shared User Name is required.')
      );
    }

    if (
      this.orgUsr.Type == UserType.Shared &&
      !Utility.isValidString(this.orgUsr.Email)
    ) {
      errors.push(this.translatePipe.transform('Email is required.'));
    }

    const repeatedSharedUsers = this.checkForRepeatedUsers();
    if (repeatedSharedUsers.length > 0) {
      errors.push(
        `These emails are repeated: [${repeatedSharedUsers.join('] [')}]<br/>`
      );
    }

    return errors.join('<br>');
  }

  checkForRepeatedUsers() {
    const sharedEmails = this.orgUsr.ChildUsers.map(({ Email: ChildUserEmail }) => ChildUserEmail);
    const sharedUsersCount = sharedEmails.length;
    const repeatedSharedEmails = [];

    for (let i = 0; i < sharedUsersCount; i += 1) {
      const email = sharedEmails[i];

      if (Utility.isValidString(email)) {
        sharedEmails.forEach((userEmail, index) => {
          if (
            index !== i &&
            userEmail === email &&
            !repeatedSharedEmails.some((r) => r === email)
          ) {
            repeatedSharedEmails.push(userEmail);
          }
        });
      }
    }

    return repeatedSharedEmails;
  }

  async getSharedUsers(
    buId: number,
    sharedEmailAddress: string
  ): Promise<void> {
    try {
      const user = await this.userService
        .importSharedUser(buId, sharedEmailAddress)
        .toPromise();

      this.orgUsr.ChildUsers = user.ChildUsers.map(u => ({
        ...u,
        ParentUserId: this.selectedUser.ID
      }));
      this.sharedUsersCtrlInvalid = user.ChildUsers.some(c => Validators.required({ value: c.Email } as FormControl) !== null);

      this.toastrService.showSuccess('Shared user found');
    } catch (e) {
      this.orgUsr.ChildUsers = [];
      this.toastrService.showWarning(this.translatePipe.transform('Shared user not found'));
    }
  }

  onImportSharedUsersOptionSelected(isTcoWhitePagesImported: boolean): void {

    if (isTcoWhitePagesImported) {

      this.orgUsr.ChildUsers = this.orgUsr.ChildUsers.filter(c =>
        c.Email !== null && c.FullName !== null && c.UserID !== null
      );
    }
  }
}
