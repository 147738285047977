import { Injectable } from '@angular/core';
import { AuthService } from '#services/shared';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Role } from 'models/enum/role';
import { Utility } from '#services/shared/utility';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      const roles = route.data.roles as Role[];

      if (!roles) {
        throw new Error('Property roles is missing');
      }

      const usr = this.authService.getCurrentUser();

      if (Utility.isValidObj(usr) && Utility.isTrue(usr.HasAccess)) {
        if (roles.some((role) => this.authService.hasRole(role))) {
          return true;
        } else {
          this.router.navigate(['/error/not-authorized']);
        }
      } else {
        localStorage.removeItem('localUserReg');
        localStorage.setItem('localUserReg', JSON.stringify(usr));
        this.router.navigate(['/error/not-registered']);
      }
      // user not authorized
      return false;
    }

    // user not authenticated
    this.router.navigate(['/login']);
    return false;
  }
}
