<div class="panel-heading">
  <div class="row">
    <div class="col-xs-10 padding-left-0 text-left" title="PSSR ID: {{ p.Id }}">
      <strong> {{ p.Name }} </strong>
      <span *ngIf="leads">
        <br />
        <b>{{ 'PSSR Team Lead' | translate:p.buObj }} :</b> {{ leads }}
      </span>
      <span *ngIf="approvers">
        <br />
        <b>{{ 'Final Approver' | translate }} :</b> {{ approvers }}
      </span>
    </div>
    <div
      class="{{ p.statusClass }} col-xs-2 text-center"
      style="
        border-radius: 1em;
        color: white;
        border-style: solid;
        border-color: white;
        border-width: thin;
      "
    >
      {{ p.StatusName | translate }}
    </div>
  </div>
</div>
<div
  *ngFor="let res of p.RespActionItems"
  class="panel-body"
  style="padding: 0px"
>
  <table
    class="table table-hover table-stripped table-condensed"
    style="margin-bottom: 0px"
  >
    <thead>
      <tr
        class="bg-info"
        *ngIf="res.SectionDescription != 'Signatures' && res.Id &gt; 0"
      >
        <td class="col-xs-2 padding-left-10">
          <strong>{{ res.SectionDescription }}</strong>
        </td>
        <td class="col-xs-2">
          <strong>{{ "Code" | translate }}: </strong>{{ res.ItemCode }}
        </td>
        <td class="col-xs-7">
          <strong [innerHTML]="res?.ItemDescription"></strong>
        </td>
        <td class="col-xs-1">
          <strong>{{ "Answer" | translate }}: </strong>{{ res.ResponseText }}
        </td>
      </tr>
      <tr
        class="bg-info"
        *ngIf="
          res.SectionDescription == 'Signatures' ||
          res.Id == null ||
          res.Id == 0
        "
      >
        <td class="col-xs-12 padding-left-10">
          <strong>{{ res.SectionDescription }}</strong>
        </td>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          *ngIf="(res.ItemType == 0 || res.ItemType == 4) && res.ActionItems.length &gt; 0"
          colspan="5"
        >
          <app-action-item
            [pssr]="p"
            [isSectionLead]="isSectionLead(res)"
            [pssrFiles]="pssrFiles"
            [lstActionItems]="res.ActionItems"
            [isStandAlone]="false"
            [isSignature]="false"
            [selectedBU]="selectedBU"
            [shouldLoadActionItemImages]="shouldLoadActionItemImages"
            [itemCode]="res.ItemCode"
          >
          </app-action-item>
        </td>
      </tr>
    </tbody>
  </table>
</div>
