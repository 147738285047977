import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '#environments/environment';
import { Injectable } from '@angular/core';
import { IdName } from '#models/id-name';
import { ImportResponse } from '#models/Import-response';

@Injectable()
export class PSSRImportService {

  constructor(
    private http: HttpClient
  ) { }

  public getApplications(): Observable<IdName[]> {
    return this.http.get<IdName[]>(`${environment.apiEndpoint}/pssr/import/applications`);
  }

  public getPSSRInfo(applicationId: number, pssrId: string): Observable<ImportResponse> {
    return this.http.get<ImportResponse>(`${environment.apiEndpoint}/pssr/import-info`,
      {
        params: {
          applicationId: applicationId.toString(),
          pssrId
        },
      }
    );
  }

  public importPSSR(pssrId: string, applicationId: number, businessUnitId: number, organizationId: number, areaId: number, subAreaId: number) {
    return this.http.post<ImportResponse>(`${environment.apiEndpoint}/pssr/import`,
      {
        applicationId,
        pssrId: pssrId,
        businessUnitId,
        organizationId,
        areaId,
        subAreaId
      });
  }

  public importMOCActions(pssrId: number) {
    return this.http.post<ImportResponse>(`${environment.apiEndpoint}/pssr/import-moc-actions`,
      {
        pssrId: pssrId
      });
  }
}
