<div class="box-shadow module">

  <h2>{{ "Create Project" | translate }}</h2>
  <hr />
  <div class="form-horizontal">
    <div class="form-group">
      <label class="control-label col-md-2">{{'Name'|translate}}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="proj.Name"
        />
        <span class="text-danger field-validation-error" *ngIf="displayWarning">
          <span>{{ "This field is required" | translate }}.</span>
        </span>
      </div>
    </div>

    <div class="form-group">
      <label class="control-label col-md-2">{{'Description'|translate}}</label>
      <div class="col-md-8">
        <input
          class="form-control text-box single-line"
          [(ngModel)]="proj.Description"
        />
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-2 col-md-10">
        <a class="btn btn-default" style="width: 100px" (click)="create()"
          >{{'Create'|translate}}</a
        >
      </div>
    </div>
  </div>
  <div>
    <a (click)="onRedirectButtonClick()" style="cursor: pointer"
      >{{'Back to List'|translate}}</a
    >
  </div>
</div>
