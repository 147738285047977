import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { PSSR, User, Group, BusinessUnit } from '#models/index';
import { BehaviorSubject } from 'rxjs';
import {
  PSSRResponseService,
  GroupService,
  BusinessUnitsService,
  PSSRService,
} from '#services/api';
import { ResponseItem, ResponseItemLink } from '#models/response-item';
import { ResponseItemStatus } from '#models/response-item-status';
import { PSSRFile } from '#models/pssr-file';
import { Utility } from '#services/shared/utility';
import { UserRole } from '#models/enum/user-role';
import { SubCategory } from '#models/subcategory';
import { Category } from '#models/category';
import { UnescapePipe } from '../pipes/unescape.pipe';
import { ActionItemDialogComponent } from '../dialogs/action-item-dialog/action-item-dialog.component';
import { ActionItemDialogService } from '../dialogs/action-item-dialog/action-item-dialog.service';
import { ConfirmationService } from 'primeng/api';

import { FilesFormService } from '../files-form/files-form.service';
import { TranslatePipe } from '@ngx-translate/core';
import { CommentDialogComponent } from '../dialogs/comment-dialog/comment-dialog.component';
import { FileService } from '#services/shared/file.service';

import { environment } from '#environments/environment';
import { ResponseItemPriority } from '#models/enum/response-item-priority';
import { PSSRStatus } from '#models/pssr-status';
import { ToastrService } from '#services/shared/toastr.service';
import { ActionEditType } from '#models/enum/action-edit-type';
import { ResponseStatus } from '#models/enum/response-status';
import { ItemType } from '#models/enum/item-type';
import { ResponseValidator } from '#services/shared/response-validator.service';
import { MOCChecklistHelper } from '#services/shared/moc-checklist-helper.service';

@Component({
  selector: 'app-action-item',
  templateUrl: './action-item.component.html',
  styleUrls: ['./action-item.component.scss'],
  providers: [
    UnescapePipe,
    ActionItemDialogService,
    ConfirmationService,
    TranslatePipe,
    FilesFormService,
  ],
})
export class ActionItemComponent implements OnInit {
  public ItemTypeEnum = ItemType;
  private _actionItems = new BehaviorSubject<ResponseItem[]>([]);

  @Input() responseType: ItemType = ItemType.Default;
  @Input() questionId: number;
  @Input() actionId: number;

  @Input() pssr: PSSR = new PSSR();
  @Input() pssrFiles: Partial<PSSRFile>[] = [];
  @Input()
  set lstActionItems(value: ResponseItem[]) {
    this._actionItems.next(value);
  }
  get lstActionItems(): ResponseItem[] {
    return this._actionItems.getValue();
  }
  @Input() isStandAlone = false;
  @Input() isSignature: boolean;
  @Input('selectedBU') selectedBU: BusinessUnit;
  @Input() shouldLoadActionItemImages = true;
  @Input() isSectionLead = false;
  @Input() actionType: ActionEditType = ActionEditType.Edit;
  @Input() itemCode = '';
  actionItems: ResponseItem[] = [];


  // --------------------
  newActionItem: Partial<ResponseItem> = {};
  // displayActionItem: boolean;
  assignToType: number;
  selectedUser: User;
  participants: User[];
  // --------------------
  queryParticipant: string;
  displayImage: boolean;
  imagePhoto: string;
  // --------------------
  groups: Group[] = [];
  selectedGroup: Group;
  showGroup: boolean;
  // --------------------
  linkItem: string;
  addedActionLink: PSSRFile;
  attachFileType = 1;

  canEdit: boolean;
  actualDateMs: number;
  isLead: boolean;

  selectedCategory: Category;
  selectedSubCategory: SubCategory;
  isSaving: boolean;

  showRejectActionItemDialog = false;
  onlyAttachments = false;

  @Input()
  canTranslate = false;

  @Output()
  actionItemChanged = new EventEmitter();

  @ViewChild(ActionItemDialogComponent)
  actionItemDialog: ActionItemDialogComponent;

  @ViewChild(CommentDialogComponent)
  commentDialog: CommentDialogComponent;

  constructor(
    private svcPSSRResponse: PSSRResponseService,
    private svcGroup: GroupService,
    private businessUnitService: BusinessUnitsService,
    private unescapePipe: UnescapePipe,
    private actionItemDialogService: ActionItemDialogService,
    private confirmationService: ConfirmationService,
    private translatePipe: TranslatePipe,
    private filesFormService: FilesFormService,
    private pssrService: PSSRService,
    private fileService: FileService,
    private toastrService: ToastrService,
    private responseValidator: ResponseValidator,
    private mocChecklistHelper: MOCChecklistHelper
  ) { }

  ngOnInit() {
    this.isSaving = false;
    this.actualDateMs = new Date().getTime();
    this.displayImage = false;
    this.showGroup = true;
    this.isLead = this.pssr.CurrentUser.Role === UserRole.Lead;

    this.pssr.buObj = this.selectedBU;
    this._actionItems.subscribe((lstActionItems = []) => {
      this.actionItems = lstActionItems;

      // Calculate all action items response statuses
      this.calculateActionItemsStatuses();
    });
  }

  calculateActionItemsStatuses(): void {
    this.actionItems?.forEach(r => {
      r.ResponseStatus = this.responseValidator.getItemStatus(r);
    });
  }

  calculateHeaderClass() {
    if (this.actionItems && this.actionItems.length > 0) {
      const aiResultSummary = <ResponseStatus>Math.max(...(this.actionItems?.map<number>(ai => ai.ResponseStatus) ?? [ResponseStatus.Answered]));
      return this.getElementColorByType(aiResultSummary);
    }

    return '';
  }

  getElementColorByType(status: ResponseStatus) {
    return Utility.getResponseStatusCSSClass(status);
  }

  async btnAddActionItem() {
    // -----------------------------------------

    await this.getGroups();


    this.newActionItem = this.actionItemDialogService.setupActionItem({
      pssr: this.pssr,
      responseId: this.isStandAlone ? null : this.questionId,
      responseItem: {
        AfterWalkthrough: this.isStandAlone,
      },
    });


    this.actionItemDialog.open();
  }

  async editActionItem(actionItem: ResponseItem) {

    this.onlyAttachments = false;

    this.actionType = ActionEditType.Edit;

    // ) as ResponseItem;
    this.newActionItem = this.actionItemDialogService.setupActionItem({
      pssr: this.pssr,
      responseId: actionItem.ResponseID,
      responseItem: {
        ...actionItem,
        Comment: this.unescapePipe.transform(actionItem.Comment),
      },
    });
    this.newActionItem.originalAction = this.newActionItem.Action;
    this.newActionItem.originalComment = this.newActionItem.Comment;
    await this.getGroups();

    this.actionItemDialog.open();
  }

  async translateActionItem(actionItem: ResponseItem) {

    this.onlyAttachments = false;

    this.actionType = ActionEditType.Translate;

    this.newActionItem = this.actionItemDialogService.setupActionItem({
      pssr: this.pssr,
      responseId: actionItem.ResponseID,
      responseItem: {
        ...actionItem,
        Comment: this.unescapePipe.transform(actionItem.Comment),
      },
    });
    this.newActionItem.originalAction = this.newActionItem.Action;
    this.newActionItem.originalComment = this.newActionItem.Comment;
    await this.getGroups();

    this.actionItemDialog.open();
  }

  async editComment(actionItem: ResponseItem) {
    this.newActionItem = actionItem;
    this.newActionItem.PSSRID = this.pssr.Id;
    this.commentDialog.open();
  }

  async reOpenActionItem(actionItem: ResponseItem) {
    // removed the delete of the comment Comment: "",
    this.newActionItem = this.actionItemDialogService.setupActionItem({
      pssr: this.pssr,
      responseId: actionItem.ResponseID,
      responseItem: {
        ...actionItem,
        Status: ResponseItemStatus.Open,
      },
    });

    const { assignTo } = this.actionItemDialogService.prepareActionItem({
      actionItem: this.newActionItem as ResponseItem,
      pssr: this.pssr,
      selectedBU: this.selectedBU,
    });

    this.newActionItem = this.actionItemDialogService.completeActionItem({
      actionItem: this.newActionItem as ResponseItem,
      assignTo: {
        assignToType: assignTo.assignToType,
        selectedCategory: assignTo.selectedCategory,
        selectedSubCategory: assignTo.selectedSubCategory,
        selectedUser: assignTo.selectedUser as User,
      },

      groups: this.groups,
      isTraslator: false
    });

    this.updateActionItem();
  }

  // -- images
  btnExpandImage(image: string) {
    this.displayImage = true;
    this.imagePhoto = 'data:image/png;base64,' + image;
  }

  btnCollapseImage() {
    this.displayImage = false;
    this.imagePhoto = null;
  }

  // -- action items

  // public async addLink() {
  //   let link = "";
  //   if (this.attachFileType == 1) {
  //     link =
  //       '<br />Attached Link: <a href="https://mobilepssrt10uscvx.blob.core.windows.net/mobilepssr-files-dev/' +
  //       this.addedActionLink.GUID +
  //       '" target="_blank">' +
  //       this.addedActionLink.Description +
  //       "</a>";
  //   } else {
  //     link =
  //       '<br />Attached Link: <a href="' +
  //       this.linkItem +
  //       '" target="_blank">' +
  //       this.linkItem +
  //       "</a>";
  //   }
  //   this.newActionItem.Comment = this.newActionItem.Comment + link;
  //   this.addedActionLink = null;
  //   this.linkItem = null;
  // }

  public async onSavedActionItem(data: ResponseItem): Promise<void> {
    if (this.showRejectActionItemDialog) {
      this.showRejectActionItemDialog = false;
    }

    this.isSaving = false;

    data = this.formatResponseItemLogDates(data);

    const idx = this.lstActionItems.findIndex(this.findActionItem(data));
    if (idx === -1) {
      // -- standalone action item
      if (this.isStandAlone) {
        this.actionItemChanged.emit();
        return;
      }

      this.addActionItem(data);
    } else {
      this.refreshActionItem(idx, data);

      if (data.IsDeleted) {
        this.removeActionItem(idx);
      } else {
        this.markAsEdit(idx);
      }
    }

    if (this.shouldLoadActionItemImages) {
      this.refreshActionItemImages(data);
    }

    this.calculateActionItemsStatuses();
    this.refreshPssrValidations();
  }

  private refreshPssrValidations() {
    this.pssr = this.svcPSSRResponse.validateItemPriority(
      this.pssr,
      this.pssr.lstResponse,
      this.pssr.standAloneItems
    );
  }

  private formatResponseItemLogDates(data: ResponseItem): ResponseItem {
    return {
      ...data,
      CreateDate: Utility.parseOffsetDate(data.CreateDate),
      DueDate: Utility.parseOffsetDate(data.DueDate),
      ClosedOn: Utility.parseOffsetDate(data.ClosedOn),
      UpdatedDate: Utility.parseOffsetDate(data.UpdatedDate),
    };
  }

  private addActionItem(data: ResponseItem): void {
    this.lstActionItems.push(data);
    this.newActionItem = data;
  }

  private findActionItem =
    (data: ResponseItem) =>
      (f: ResponseItem): boolean =>
        f.Id === this.newActionItem.Id || f.Id === data.Id

  private refreshActionItem(ix: number, data: ResponseItem): void {
    this.lstActionItems[ix] = data;
  }

  private refreshActionItemImages(data: ResponseItem): void {
    this.svcPSSRResponse
      .getImagesByActionItem(data.Id)
      .toPromise()
      .then((images) => {
        const ix = this.lstActionItems.findIndex(this.findActionItem(data));
        if (ix >= 0) { this.lstActionItems[ix].Images = images; }
      });
  }

  private markAsEdit(ix: number): void {
    this.lstActionItems[ix].inEdit = true;
    setTimeout(() => {
      this.lstActionItems[ix].inEdit = true;
    }, 500);
  }

  private removeActionItem(ix: number): void {
    this.lstActionItems.splice(ix, 1);
  }

  // getParticipants(event) {
  //   let query = event.query;
  //   this.queryParticipant = query;
  //   this.userService.getParticipants(query).subscribe((data) => {
  //     this.participants = data;
  //     this.participants.forEach(function (obj, index) {
  //       this[index].FullName =
  //         this[index].FirstName + " " + this[index].LastName;
  //     }, this.participants);
  //   });
  // }

  async getGroups() {
    this.groups = [];
    await this.svcGroup
      .getGroupsByBu(this.pssr.BusinessUnitID)
      .toPromise()
      .then((data: Group[] = []) => {
        this.groups = data;
      });
    if (this.groups.length === 0) {
      this.showGroup = false;
    }
  }

  openRejectActionItemDialog(actionItem: ResponseItem): void {
    this.newActionItem = this.actionItemDialogService.setupActionItem({
      pssr: this.pssr,
      responseId: actionItem.ResponseID,
      responseItem: {
        ...actionItem,
        Status: ResponseItemStatus.Rejected,
        StatusName: 'Rejected',
        RejectReason: '',
        ClosedBy: null,
        ClosedOn: null,
      },
    });

    // this.selectedCategory = this.selectedBU.Categories.find(
    //   (c) => c.Id === actionItem.CategoryID
    // );

    // if (this.selectedCategory)
    //   this.selectedSubCategory = this.selectedCategory.SubCategories.find(
    //     (s) => s.Id === actionItem.SubCategoryID
    //   );

    // var pssrUser = this.pssr.PSSRUsers.Users.find(
    //   (f) => f.UserID == actionItem.AssignedUserID
    // );

    // if (pssrUser != null) {
    //   this.assignToType = 1; //PSSR MEMBER
    // } else if (actionItem.AssignedUserID != null) {
    //   this.assignToType = 2; //CAI
    //   this.selectedUser = new User();
    //   this.selectedUser.ID = actionItem.AssignedUserID;
    //   this.selectedUser.FullName = actionItem.AssignedTo;
    //   this.selectedUser.CAI = actionItem.AssignedCAI;
    // } else if (actionItem.AssignedGroupID != null) {
    //   this.assignToType = 4; //GROUP
    //   this.selectedGroup = new Group();
    //   this.selectedGroup.Id = actionItem.AssignedGroupID;
    //   this.selectedGroup.Name = actionItem.AssignedGroupName;
    // } else {
    //   this.assignToType = 3; //OTHER
    // }

    // const {
    //   assignTo,
    //   selectedGroup,
    // } = this.actionItemDialogService.prepareActionItem({
    //   actionItem: this.newActionItem as ResponseItem,
    //   assignTo: {
    //     assignToType: this.assignToType,
    //     selectedCategory: this.selectedCategory,
    //     selectedSubCategory: this.selectedSubCategory,
    //     selectedUser: this.selectedUser,
    //   },
    //   pssr: this.pssr,
    //   selectedBU: this.selectedBU,
    // });
    // this.selectedGroup = {...selectedGroup};
    // this.assignToType = assignTo.assignToType;
    // this.selectedCategory = assignTo.selectedCategory;
    // this.selectedSubCategory = assignTo.selectedSubCategory;
    // this.selectedUser = assignTo.selectedUser as User;

    // -----------------------------------------

    this.showRejectActionItemDialog = true;
  }

  get allowMembersToCompletePSSR() {
    return this.businessUnitService.allowMembersToCompletePSSR(
      this.pssr.buObj,
      this.pssr
    );
  }

  async rejectActionItem() {
    if (
      this.newActionItem.Status == ResponseItemStatus.Rejected &&
      (!this.newActionItem.RejectReason ||
        this.newActionItem.RejectReason.trim().length === 0)
    ) {
      this.toastrService.showError('Reject reason is Required');

      this.isSaving = false;
      return;
    }

    const { assignTo } = this.actionItemDialogService.prepareActionItem({
      actionItem: this.newActionItem as ResponseItem,
      pssr: this.pssr,
      selectedBU: this.selectedBU,
    });
    this.newActionItem = this.actionItemDialogService.completeActionItem({
      actionItem: this.newActionItem as ResponseItem,
      assignTo: {
        assignToType: assignTo.assignToType,
        selectedCategory: assignTo.selectedCategory,
        selectedSubCategory: assignTo.selectedSubCategory,
        selectedUser: assignTo.selectedUser as User,
      },

      groups: this.groups,
      isTraslator: false
    });

    let confirmRejectionMessage = this.translatePipe.transform(
      'ConfirmRejectionMessage',
      {
        actionsButton: this.translatePipe.transform('ReturnTeamLead'),
      }
    );

    if (this.newActionItem.Priority && this.newActionItem.Priority == ResponseItemPriority.P4) {
      confirmRejectionMessage = this.translatePipe.transform(
        'ConfirmRejectionHeader',
        {
          actionsButton: this.translatePipe.transform('OK'),
        }
      );
    }


    this.confirmationService.confirm({
      message: confirmRejectionMessage,
      accept: () => {
        this.updateActionItem();
      },
    });
  }

  updateActionItem() {
    this.isSaving = true;
    this.svcPSSRResponse
      .updateActionItem(this.newActionItem as ResponseItem)
      .toPromise()
      .then(
        (data) => {

          this.isSaving = false;
          this.onlyAttachments = false;
          this.onSavedActionItem(data);
        },
        (error) => {

          this.isSaving = false;
          this.toastrService.showError(error.error.ExceptionMessage);
        }
      );
  }

  extractLinks(links: string[] = []): ResponseItemLink[] {
    return this.filesFormService.parseLinks(links);
  }

  async openLink(link: ResponseItemLink): Promise<void> {
    if (link.url.includes(environment.azureContainerEndpoint)) {
      try {
        const [fileGuid] = link.url.match(/([a-z0-9\-])+$/g);



        const res = await this.pssrService
          .downloadPSSRFileByGuid(this.pssr.Id, fileGuid)
          .toPromise();

        this.fileService.downloadByClick({
          file: res.File,
          contentType: res.ContentType,
          description: res.Description,
        });
      } catch (e) {
        this.toastrService.showError(e.error.ExceptionMessage);
      }
    } else {
      if (!window.open(link.url, '_blank')) {
        this.toastrService.showWarning('Cannot open link');
      }
    }
  }

  isActiveCategory(categoryID: number): boolean {
    return (
      this.selectedBU.Categories.find(
        (c) => c.Id === +categoryID && c.IsActive
      ) !== undefined
    );
  }

  canEditAI(ai: ResponseItem): boolean {
    return (
      ai.CanEditItem &&
      ((ai.Priority === ResponseItemPriority.P3 &&
        this.pssr.Status !== PSSRStatus.PendingApproval) ||
        (ai.Priority === ResponseItemPriority.P3 &&
          ai.Status === ResponseItemStatus.Open) ||
        (ai.Priority > ResponseItemPriority.P3 &&
          this.pssr.Status >= PSSRStatus.InProgress) ||
        (ai.Priority === ResponseItemPriority.P3 && this.isLead
          && this.selectedBU.AllowTeamLeadToAttach &&
          this.pssr.Status >= PSSRStatus.PendingApproval) ||
        ai.Priority === ResponseItemPriority.P4)
    );
  }

  canAttach(ai: ResponseItem): boolean {
    return (
      !ai.CanEditItem &&
      this.selectedBU.AllowAIOwnerToAttach &&
      this.pssr.Status !== PSSRStatus.Closed &&
      (ai.AssignedUserID === this.pssr.CurrentUser.UserID  ||
      this.pssr.CurrentUser.Role == UserRole.Lead ||
      this.pssr.CurrentUser.IsBusinessUnitAdmin)
    );
  }

  editToAttach(ai: ResponseItem) {
    this.onlyAttachments = true;
    this.editActionItem(ai);
  }

  getStatusTitle(ai: ResponseItem) {
    let title = ai.StatusName;

    if (ai.Status === ResponseItemStatus.Closed) {
      if (ai.ClosedBy) {
        title += ` by  ${ai.ClosedBy}`;
      }

      if (ai.ClosedOn) {
        title += ` on  ${ai.ClosedOn.toLocaleString()}`;
      }
    }

    return title;
  }

  isMOCLockedChecklist() {
    return this.itemCode && this.mocChecklistHelper.isMOCChecklistLocked(this.itemCode);
  }

  get canAddMasterPunchActionItem() {
    if (this.isMOCLockedChecklist()) {
      return false;
    }
    return this.responseType == this.ItemTypeEnum.ActionItem &&
      this.pssr.Status >= PSSRStatus.InProgress &&
      (this.pssr.CurrentUser.CanAddItem ||
        this.allowMembersToCompletePSSR ||
        this.isSectionLead);
  }

  get canAddPostWalkdownActionItem() {
    if (this.isMOCLockedChecklist()) {
      return false;
    }

    return this.isStandAlone &&
      (this.pssr.CurrentUser.CanAddItem ||
        this.allowMembersToCompletePSSR ||
        this.isSectionLead);
  }
}
