import { Component, OnInit } from '@angular/core';
import { AreaService } from '#services/api/area.service';
import { Area } from '#models/area';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-area-edit',
  templateUrl: './area-edit.component.html'
})
export class AreaEditComponent implements OnInit {
  area: Area = new Area();
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  areaName: string;
  displayWarningArea: boolean;
  buId: number;

    constructor(
    private toastrService: ToastrService,
    private businessUnitsSvc: BusinessUnitsService,
    private areaSvc: AreaService,

    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.selectedBU = new BusinessUnit();
    this.route.params.subscribe(v => {
      this.area.Id = +v['areaId'];
      this.area.BusinessUnitID = +v['buId'];
    });
    this.getAreaById(this.area.Id);
    this.displayWarningArea = false;
    this.loadBusinessUnits();
  }

  onRedirectButtonClick(buId: number): void {
    this.router.navigate(['admin/area', {buId: buId}]);
  }

  getAreaById(id: number): void {

    this.areaSvc.getAreaById(id)

    .subscribe(
      (data: Area) => {
        this.area = data;
        this.areaName = this.area.Name;
      },
      error => {
        this.toastrService.showError( error.error.ExceptionMessage);

      }
    );
  }

  updateArea(): void {
    this.displayWarningArea = this.area.Name == undefined || this.area.Name == null || this.area.Name.length == 0;
    if (!this.displayWarningArea) {

      this.areaSvc.updateArea(this.area)

      .subscribe(
        () => {
          this.onRedirectButtonClick(this.area.BusinessUnitID);
        },
        error => {
          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
    }
  }
  loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserBUs()
    .subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        if (this.area.BusinessUnitID == null || this.area.BusinessUnitID == undefined) {
          this.selectedBU = this.lstBusinessUnit[0];
        } else {
          this.selectedBU = this.lstBusinessUnit.find(x => x.Id === this.area.BusinessUnitID);
        }

      }
    });
  }

}
