<h4 class="text-left">{{'Select the pages to convert to images'|translate}}</h4>
<form *ngIf="form" [formGroup]="form">
  <div *ngFor="let file of data.files">
    <p>
      <b>{{file.name}}</b>
    </p>
    <div [formGroupName]="file.guid">
      <div class="row" *ngIf="file.pages &gt; 0">

        <div class="col-xs-6">
          <label>
            <input class="form-check-input" type="radio" formControlName="select"
              (change)="markAllPages(file.guid, true)" [value]="false">
            {{'All pages'|translate}}
          </label>
        </div>
        <div class="col-xs-6">
          <label>
            <input class="form-check-input" type="radio" formControlName="select" [value]="true"
              (change)="markAllPages(file.guid, false)">
            {{'Select pages'|translate}}
          </label>
        </div>
      </div>
      <div class="row" *ngIf="file.pages === 0">
        <div class="col-xs-12">
          <em>{{'Could not detect pages count'|translate}}</em>
        </div>
      </div>
      <div formArrayName="pages">
        <label *ngFor="let page of getPagesControl(file.guid).controls; let i = index">
          <ng-container *ngIf="form.get(file.guid).get('select')?.value">

            <input type="checkbox" [formControlName]="i">
            {{'Page'|translate}} {{i+1}}
          </ng-container>
        </label>
      </div>
    </div>
    <hr>
  </div>
  <div class="buttons">
    <button mat-dialog-close type="button" class="btn btn-sm btn-danger">{{'Cancel'|translate}}</button>
    <button (click)="submit()" [disabled]="form.invalid" type="button" class="btn btn-default">{{'Select'|translate}}</button>
  </div>
</form>
