<div *ngIf="pssr">
  <div class="row">
    <div class="col-md-12 box-shadow module">
      <h3>
        {{ title }}
      </h3>

      <br />

      <div class="row padding-left-5 padding-right-5">
        <p-fileUpload
          [disabled]="pssr.Status &gt;= 6"
          [uploadLabel]="'Upload' | translate"
          [maxFileSize]="maxFileSize"
          #fileUpload
          multiple="multiple"
          name="files[]"
          mode="advanced"
          [accept]="accept"
          [showCancelButton]="false"
          [chooseLabel]="'Select' | translate"
          customUpload="true"
          (uploadHandler)="upload($event)"
        >
          <ng-template pTemplate="content">
            <h4 class="text-left">{{ "AttachedFiles" | translate }}:</h4>
            <table style="width: 100%">
              <tbody>
                <tr class="col-md-12 row padding-bottom-5">
                  <th class="col-md-8 text-left padding-left-5 padding-right-5">
                  </th>
                  <th class="col-md-3 text-center padding-left-5 padding-right-5">
                  </th>
                  <th class="col-md-1 text-center padding-left-5 padding-right-5">
                  </th>
                </tr>
                <tr class="col-md-12 row padding-bottom-5" *ngFor="let fileItem of pssrFiles">
                  <td class="col-md-8 text-left padding-left-5 padding-right-5">
                    <strong>{{ fileItem.Description }}&nbsp;</strong>
                  </td>
                  <td class="col-md-3 text-center padding-left-5 padding-right-5">
                    <button (click)="downloadFile(fileItem.Id)" type="button" class="btn btn-blk whiteSpaceBtn">
                      {{ "Download" | translate }}
                    </button>
                  </td>
                  <td class="col-md-1 text-center padding-left-5 padding-right-5" *ngIf="pssr.Status &lt;6">
                    <button type="button" class="btn btn-sm btn-danger" (click)="confirmToRemove(fileItem)">
                      <strong>X</strong>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </p-fileUpload>
      </div>
    </div>
  </div>
</div>
