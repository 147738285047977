<div class="box-shadow module">
  <div class="row">
    <h2>{{'Business Units'|translate}}</h2>
  </div>
  <table class="table table-hover">
    <tr style="font-weight: bold;">
      <th class="col-xs-4 row padding-right-0 padding-left-0 text-align-center">
        <span class="col-xs-3 padding-right-0 text-align-center">{{'Business Unit'|translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{'Hierarchy Label 1'|translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{'Hierarchy Label 2'|translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{'Hierarchy Label 3 show'|translate}} </span>
      </th>
      <th class="col-xs-4 row padding-right-0 text-align-center padding-left-0">
        <span class="col-xs-3 padding-right-0 text-align-center">{{'PSSR Label' | translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{'Org Label' | translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{'P3 Label' | translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{'P4 Label' | translate}}</span>
      </th>
      <th class="col-xs-4 row padding-right-0 padding-left-0">
        <span class="col-xs-9 padding-right-0 text-align-center">{{'Other Options'| translate}}</span>
        <span class="col-xs-3" style="text-align:end">
          <button *ngIf="canCreateBusinessUnits" type="button" class="btn btn-success"
            (click)="btnCreate()">{{'New'|translate}}</button>
        </span>
      </th>
    </tr>
    <tr *ngFor="let b of businessUnits">
      <td class="col-xs-4 row padding-right-0 text-align-center padding-left-0">
        <span class="col-xs-3 padding-right-0 text-align-center">{{b.Name}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{b.HierarchyLabel1|translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{b.HierarchyLabel2|translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">
          <span class="col-xs-12" style="align-content: center;">
            <input class="col-xs-12" type="checkbox" [(ngModel)]="b.ShowHierarchyLabel3" value="b.ShowHierarchyLabel3"
              [disabled]="true">
          </span>
          <span class="col-xs-12"> {{b.HierarchyLabel3 | translate}} </span>
        </span>
      </td>
      <td class="col-xs-4 row padding-right-0 text-align-center padding-left-0">
        <span class="col-xs-3 padding-right-0 text-align-center" style="word-break: break-word;">{{b.PSSRLabel | translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center"
          style="word-break: break-word;">{{b.OrganizationLabel | translate}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{b.P3Label}}</span>
        <span class="col-xs-3 padding-right-0 text-align-center">{{b.P4Label}}</span>
      </td>
      <td class="col-xs-4 row padding-right-0 padding-left-0">
        <span class="col-xs-9 padding-right-0 text-left">
          <span class="col-xs-12"> {{getOtherOptions(b)}} </span>
        </span>
        <span class="col-xs-3" style="text-align:end">
          <button type="button" class="btn btn-primary" (click)="btnEdit(b.Id)">{{'Edit'|translate}}</button>
        </span>
      </td>
    </tr>
  </table>
</div>
