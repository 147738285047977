import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * @description Workaround for weird angular / JSON.parse() behavior unable to automatically parse json datetime into javascript Date object
 */
@Injectable()
export class DatetimeParseInterceptor implements HttpInterceptor {
  private dateRegex =
    /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)[zZ]?$/;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          this.convertDates(event.body);
        }
      })
    );
  }

  private convertDates(object: Object) {
    if (this.isNotObject(object)) {
      return;
    }

    if (object instanceof Array) {
      for (const item of object) {
        this.convertDates(item);
      }
    }

    for (const key of Object.keys(object)) {
      const value = object[key];

      if (value instanceof Array) {
        for (const item of value) {
          this.convertDates(item);
        }
      }

      if (value instanceof Object) {
        this.convertDates(value);
      }

      if (this.isDate(value)) {
        object[key] = new Date(value);
      } else if (this.canConvertToStr(value)) {
        object[key] = new Date(value.toString());
      }
    }
  }

  private isDate(value): boolean {
    return typeof value === 'string' && this.dateRegex.test(value);
  }

  private canConvertToStr(value): boolean {
    return value instanceof String && this.dateRegex.test(value.toString());
  }

  private isNotObject(object): boolean {
    return !object || !(object instanceof Object);
  }
}
