import { TaskPlanItem } from '#models/task-plan-item';
import { ResponseStatus } from './enum/response-status';
import { PSSRResponse } from './pssr-response';

export class TaskPlanSubSection {
  public Description: string;
  public ID: number;
  public TaskPlanSectionId: number;
  public SortID: number;
  public TaskPlanItems: TaskPlanItem[];
  public firstItemId: number;
  public lastItemId: number;
  public isCollapsed = false;
  public IsCommentsSubSection = false;
  // Custom properties (not in DTO)
  Responses: PSSRResponse[];
  ResponseStatus: ResponseStatus;
}
