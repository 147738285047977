<div class="section panel panel-primary" id="section-{{ section.ID }}">
  <!-- Section Header -->
  <div class="section-header panel-heading" [ngClass]="getElementColorByType(section?.ResponseStatus)">
    <div  class="row">
      <div class="col-md-6">
        <h3
          [class.editable]="section.SectionType === TaskPlanSectionType.Multiple
          && (pssr.CurrentUser.CanEdit
            || isSystemAdmin
            || pssr.CurrentUser.IsBusinessUnitAdmin
            || pssr.CurrentUser.IsOrgAdmin)
          && pssr.Status &lt;= 3"
          (click)="editSection()"
          class="text-left"
        >
          <button
            class="btn btn-default"
            (click)="toggleContent($event, section.Description)"
          >
            <span
              class="accordion-button pi"
              [class.pi-plus]="section.isCollapsed"
              [class.pi-minus]="!section.isCollapsed"
            ></span>
          </button>
          {{ section.Description | translate }}
        </h3>
      </div>
      <div class="col-md-2">
        <strong>{{ pssr.buObj?.P3Label }}</strong
        >:{{ actionItemsStats[3] }}<br>

        <strong>{{ pssr.buObj?.P4Label }}</strong
        >:{{ actionItemsStats[4] }}<br>

        <ng-container *ngIf="pssr.buObj?.ShowP5">
          <strong> {{ pssr.buObj?.P5Label }}</strong>:{{ actionItemsStats[5] }}
        </ng-container>
      </div>
      <div  class="col-md-4 section-users-header">
        <div *ngFor="let user of section.Users" class="section-user-name">
          <button
            *ngIf="canAddSectionUser"
            type="button"
            class="close"
            (click)="removeSectionUser(user.Id)"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          {{ user.UserName }}
        </div>

        <div *ngIf="canAddSectionUser">
          <p-dropdown
            [options]="members"
            [(ngModel)]="selectedUser"
            [styleClass]="'text-left'"
            (ngModelChange)="addSectionUser()"
            optionLabel="UserName"
            placeholder="{{ 'Select Lead' | translate }}"
            appendTo="body"
          >
          </p-dropdown>
        </div>
        <div
          class="remove-section-btn"
          *ngIf="
            section.Type !== SectionType.ProjectSpecific &&
            (isAdminAndCanDeleteSection ||
              (isUserAllowedToDelete && section.Type !== SectionType.Signature))
          "
        >
          <button
            type="button"
            class="btn btn-danger"
            (click)="removeSection()"
          >
            {{ "Remove" | translate }}
          </button>
        </div>
        <div
          *ngIf="(pssr.CurrentUser.CanEdit || isSystemAdmin ||currentUser.IsBusinessUnitAdmin ||currentUser.IsOrgAdmin) && pssr.IsSectionOrderEnabled && pssr.Status &lt; 4 && section.ID"
        >
          <div class="btn-group" role="group" aria-label="Sort Section">
            <button
              type="button"
              class="btn btn-default section-sort"
              *ngIf="index &gt; 0"
              (click)="switchSectionOrder(index - 1)"
            >
              <img src="assets/images/upTriangle.png" />
            </button>
            <button
              type="button"
              class="btn btn-default section-sort"
              *ngIf="index &lt; maxSections"
              (click)="switchSectionOrder(index + 1)"
            >
              <img src="assets/images/dnTriangle.png" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Section Content -->
  <div class="section-body panel-body" [hidden]="section.isCollapsed" >
    <table *ngIf="showHeaders" class="responses-table table table-hover">
      <thead >
        <tr [ngClass]="getElementColorByType(section?.ResponseStatus)">
          <td *ngIf="showCode" class="col-md-1">{{ "Code" | translate }}</td>
          <td [class.col-md-6]="!showCode" [class.col-md-4]="showCode">
            {{ "Question" | translate }}
          </td>
          <td class="col-md-2 text-center">{{ "Answer" | translate }}</td>

          <td class="col-md-1" colspan="2"></td>
          <td class="col-md-3">{{ "UpdatedByUpdatedDate" | translate }}</td>
          <td></td>
        </tr>
      </thead>
    </table>
    <table class="responses-table table table-hover no-padding-table">
      <tbody>
        <ng-container [ngSwitch]="section.Type">
          <ng-container *ngSwitchCase="SectionType.StandAlone">
            <tr>
              <td class="stand-alone-group" [attr.colspan]="showCode ? 7 : 6">
                <app-action-item
                  [canTranslate]="canTranslate"
                  [pssr]="pssr"
                  [pssrFiles]="pssrFiles"
                  [lstActionItems]="section.StandAloneItems"
                  [isStandAlone]="true"
                  [isSignature]="false"
                  [selectedBU]="pssr.buObj"
                  [isSectionLead]="isSectionLead"
                  (actionItemChanged)="actionItemChanged()"
                >
                </app-action-item>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngSwitchCase="SectionType.Signature">
            <ng-container *ngIf="!hasSubSections; else subSectionsContent">
              <div
                *ngFor="let response of section.Responses" [ngClass]="getElementColorByType(response?.ResponseStatus)"
                [ngTemplateOutlet]="responseRow"
                [ngTemplateOutletContext]="{
                  response: response,
                  isCollapsed: section.isCollapsed
                }"
              >
              </div>
            </ng-container>
            <tr *ngIf="pssr.showSignature">
              <td [attr.colspan]="showCode ? 7 : 6">
                <app-signature
                  [pssr]="pssr"
                  [allowMembersToCompletePSSR]="allowMembersToCompletePSSR"
                  [isFinalSignature]="true"
                >
                </app-signature>
              </td>
            </tr>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <ng-container *ngIf="!hasSubSections; else subSectionsContent">
              <div 
                *ngFor="let response of section.Responses" [ngClass]="getElementColorByType(response?.ResponseStatus)"
                [ngTemplateOutlet]="responseRow"
                [ngTemplateOutletContext]="{
                  response: response,
                  isCollapsed: section.isCollapsed
                }"
              >
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </tbody>
    </table>

    <!-- Section Footer -->
    <div
      class="row add-question"
      *ngIf="isUserAllowedToEdit && canDisplayQuestionSection() && section.Type !== SectionType.Signature"
    >
      <h4 class="text-left col-md-12">{{ "Add Question" | translate }}</h4>

      <div class="form-group row" *ngIf="isProjectSpecific">
        <label class="col-md-2" for="newResponseItemCode">{{
          "Item Code" | translate
        }}</label>
        <input
          *ngIf="newResponse"
          style="margin-left: 15px"
          name="newResponseItemCode"
          type="text"
          pInputText
          disabled="disabled"
          [(ngModel)]="newResponse.ItemCode"
        />
      </div>

      <div class="form-group row" *ngIf="!isProjectSpecific && hasSubSections">
        <label class="col-md-2" for="selectedSubSection">
          {{ "Sub Section" | translate }}
        </label>

        <p-dropdown
          class="col-md-10"
          name="selectedSubSection"
          [options]="subSections"
          [(ngModel)]="selectedSubSection"
          optionLabel="Description"
          appendTo="body"
          [showClear]="true"
        >
        </p-dropdown>
      </div>

      <app-toggle-rich-input
        *ngIf="newResponse"
        name="responseItemDescription"
        [label]="'Description' | translate"
        [(ngModel)]="newResponse.ItemDescription"
      >
      </app-toggle-rich-input>

      <div class="col-md-12 text-right">
        <button
          type="button"
          class="btn btn-primary"
          [disabled]="!newResponse?.ItemDescription || disableAddQuestion"
          (click)="addResponse()"
        >
          {{ "Save" | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #subSectionsContent>
  <ng-container *ngFor="let subSection of subSections">
    <tr *ngIf="subSection.Description">
      <td class="subsection-header" [ngClass]="getSubsectionElementColorByType(subSection)" [attr.colspan]="showCode ? 7 : 6">
        <button
          class="btn btn-default"
          (click)="
            toggleContent($event, section.Description, subSection.Description)
          "
        >
          <span
            class="accordion-button pi"
            [class.pi-plus]="subSection.isCollapsed"
            [class.pi-minus]="!subSection.isCollapsed"
          ></span>
        </button>
        <strong
          ><em>{{ subSection.Description }}</em></strong
        >
      </td>
    </tr>
    <div
      *ngFor="let response of subSection.Responses"
      [ngTemplateOutlet]="responseRow"
      [ngClass]="getElementColorByType(response?.ResponseStatus)"

      [ngTemplateOutletContext]="{
        response: response,
        isCollapsed: subSection.isCollapsed
      }"
    >
    </div>
  </ng-container>
</ng-template>

<ng-template #responseRow let-response="response" let-isCollapsed="isCollapsed">
  <ng-container *ngIf="response.ItemType === ItemType.Default">
    <tr [hidden]="isCollapsed">
      <td [ngClass]="getQuestionElementColorByType(response.ResponseStatus)"></td>
      <td *ngIf="showCode" class="col-md-1">{{ response.ItemCode }}</td>
      <td
        [class.col-md-6]="!showCode"
        [class.col-md-4]="showCode"
        [innerHTML]="response?.ItemDescription"
       
      ></td>
      <td class="col-md-2 text-center">
        <span *ngIf="!canRespond(response)">{{ response.ResponseText }}</span>
        <div class="row" *ngIf="canRespond(response)">
          <div class="col-sm-6 form-group">
            <p-dropdown
              [style]="{ width: '100%' }"
              [options]="responseOptions"
              [(ngModel)]="response.ResponseText"
              [placeholder]="'Select Response' | translate"
              (ngModelChange)="saveResponse(response)"
              appendTo="body"
            >
              <ng-template let-item pTemplate="selectedItem">
                {{ item.value | translate }}
              </ng-template>
              <ng-template let-item pTemplate="item">
                {{ item.value | translate }}
              </ng-template>
            </p-dropdown>
          </div>
          <div class="col-sm-6">
            <button
              class="btn btn-primary"
              style="width: 100%"
              (click)="clearOldWay(response)"
              type="button"
            >
              {{ "Clear" | translate }}
            </button>
          </div>
        </div>
        <p-dialog
          [style]="{ width: '300px' }"
          [(visible)]="displayClearModal"
          [maximizable]="true"
          showEffect="fade"
          [modal]="true"
        >
          <p-header>
            {{ "Clear Question" | translate }}
          </p-header>
          <div>
            <b>{{ "This is not a reversible change" | translate }}.</b>
            <br />
            {{ "Answer will be cleared" | translate }}.
            <br />
            {{ "RemovingWarning" | translate }}.
            <!-- You are about to clear the Answer and remove all Action Items & Comments from this question. -->
            <br />
            {{ "Confirmtoproceed" | translate }}
          </div>
          <p-footer class="row">
            <button
              type="button"
              pButton
              [label]="'Cancel' | translate"
              (click)="displayClearModal = false"
              style="
                width: 80px;
                white-space: pre-wrap !important;
                background-color: #c9302c;
                border-color: #ac2925;
              "
            ></button>
            <button
              type="button"
              pButton
              [label]="'Confirm' | translate"
              (click)="clearSelection(response)"
              style="width: 80px; white-space: pre-wrap !important"
            ></button>
          </p-footer>
        </p-dialog>
      </td>
      <td class="col-md-1">
        <ng-container *ngIf="canAddActionItemAndComment(response) || isSectionLead">
          <button
            class="btn btn-primary add-action-item"
            (click)="addActionItem(response, actionItemDialog)"
            type="button"
          >
            {{ "Add Action Item" | translate }}
          </button>
          <app-action-item-dialog
            [pssrFiles]="pssrFiles"
            [canAnswer]="response.CanAnswer || allowMembersToCompletePSSR"
            #actionItemDialog
            [isStandAlone]="false"
            [isSectionLead]="isSectionLead"
            (saved)="saveResponseItem($event)"
            [(ngModel)]="newActionItem"
            [groups]="groups"
            [pssr]="pssr"
          >
          </app-action-item-dialog>
        </ng-container>
      </td>
      <td class="col-md-1">
        <ng-container *ngIf="canAddActionItemAndComment(response) || isSectionLead">
          <button
            class="btn btn-primary add-comment"
            (click)="addComment(response, addCommentDialog)"
            type="button"
          >
            {{ "Add Comment" | translate }}
          </button>
          <app-comment-dialog
            #addCommentDialog
            [canDelete]="pssr.Status == 3"
            (saved)="saveResponseItem($event)"
            [(ngModel)]="newComment"
          >
          </app-comment-dialog>
        </ng-container>
      </td>
      <td class="col-md-3">
        {{ response.UpdatedBy }} {{ response.UpdatedDate | date: "short" }}
      </td>
      <td class="text-center">
        <button
          *ngIf="canDeleteResponse(response)"
          type="button"
          class="btn btn-danger"
          (click)="removeResponse(response)"
        >
          <strong>X</strong>
        </button>
      </td>
    </tr>
    <tr
      [hidden]="isCollapsed"
      *ngIf="isActionItemNeeded(response) && canRespond(response)"
    >
      <td [attr.colspan]="showCode ? 7 : 6">
        <div
          class="row"
          [hidden]="false"
          class="alert alert-warning"
          role="alert"
          [innerHTML]="getErrorMessage(response)"
        ></div>
      </td>
    </tr>
  </ng-container>

  <tr
    *ngIf="response.ItemType === ItemType.Signature || response.ItemType === ItemType.WalkdownSignature"
    [hidden]="isCollapsed" [ngClass]="getElementColorByType(response?.ResponseStatus)"
  >
    <td [attr.colspan]="showCode ? 7 : 6" style="padding: 2px">
      <app-signature
        (signed)="onResponseSigned($event)"
        [pssr]="pssr"
        [allowMembersToCompletePSSR]="allowMembersToCompletePSSR"
        [response]="response"
        [isFinalSignature]="false"
      >
      </app-signature>
    </td>
  </tr>

  <tr *ngIf="response.ItemType === ItemType.RequestComments" [hidden]="isCollapsed">
    <td [attr.colspan]="showCode ? 7 : 6">
      <p style="font-size: 1.5rem">
        <span [innerHTML]="response?.ItemDescription"></span>
      </p>
      <button
        *ngIf="(pssr.Status &lt;= 4 ) && (response.CanAnswer || pssr.CurrentUser.CanAddItem || allowMembersToCompletePSSR || isSectionLead)"
        class="btn btn-primary"
        (click)="addComment(response, addObservation)"
        type="button"
      >
        {{ "Add
        Comment" | translate }}
      </button>
      <app-comment-dialog
        #addObservation
        [canDelete]="pssr.Status == 3"
        (saved)="saveResponseItem($event)"
        [(ngModel)]="newComment"
      >
      </app-comment-dialog>
    </td>
  </tr>

  <!--Respond with action items table-->
  <tr
    [hidden]="isCollapsed"
    *ngIf="response.ActionItems.length > 0 || response.ItemType === ItemType.ActionItem"
  >
    <td
      *ngIf="response.ItemType !== ItemType.Signature && response.ItemType !== ItemType.WalkdownSignature"
      [class.observations]="response.ItemType === ItemType.RequestComments"
      [attr.colspan]="showCode ? 7 : 6"
      style="padding-left: 0px !important;padding-top:0px;padding-bottom:0px;">
      <app-action-item
        [canTranslate]="canTranslate"
        [pssr]="pssr"
        [pssrFiles]="pssrFiles"
        [lstActionItems]="response.ActionItems"
        [isStandAlone]="false"
        [responseType]="response.ItemType"
        [questionId]="response.Id"
        [isSignature]="false"
        [selectedBU]="pssr.buObj"
        [isSectionLead]="isSectionLead"
        [itemCode]="response.ItemCode">
      </app-action-item>
    </td>
  </tr>
</ng-template>

<app-section-input-dialog
  *ngIf="section.SectionType === TaskPlanSectionType.Multiple &&
    (
      pssr.CurrentUser.CanEdit ||
      isSystemAdmin ||
      pssr.CurrentUser.IsBusinessUnitAdmin ||
      pssr.CurrentUser.IsOrgAdmin
    ) && pssr.Status &lt;= 3"
  [listOfDescriptions]="listOfDescriptions"
  (saved)="updateSection($event)"
  [isEditMode]="true"
  [sectionInput]="section"
  [(ngModel)]="editedSection.Description"
></app-section-input-dialog>
