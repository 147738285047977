import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanSection } from '#models/task-plan-section';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-task-plan-section-edit',
  templateUrl: './task-plan-section-edit.component.html',
})
export class TaskPlanSectionEditComponent implements OnInit {
  //#region VARIABLES

  sectionId: number;
  taskPlanSection: TaskPlanSection = new TaskPlanSection();
  displayWarningDesc: boolean;
  taskPlanPage: string;

  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.sectionId = +v['id'];
      this.taskPlanPage = v['page'];
    });
    this.getTaskPlanSectionById(this.sectionId);
    this.displayWarningDesc = false;
  }

  public getTaskPlanSectionById(id: number): void {

    this.taskPlanSvc
      .getTaskPlanSectionById(id)

      .subscribe((data: any) => {
        this.taskPlanSection = data;
      });
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/task-plan-manage',
      {
        id: this.taskPlanSection.TaskPlanId,
        sectionId: this.sectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public updateTaskPlanSection(): void {
    this.displayWarningDesc =
      this.taskPlanSection.Description == undefined ||
      this.taskPlanSection.Description == null ||
      this.taskPlanSection.Description.length == 0;
    if (!this.displayWarningDesc) {

      this.taskPlanSvc
        .updateTaskPlanSection(this.taskPlanSection)

        .subscribe(() => {
          this.onRedirectButtonClick();
        });
    }
  }
}
