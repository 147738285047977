import { Component, OnInit } from '@angular/core';
import { TaskPlanService } from '#services/api/task-plan.service';
import { TaskPlanSubSection } from '#models/task-plan-sub-section';

import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-task-plan-sub-section-delete',
  templateUrl: './task-plan-sub-section-delete.component.html',
})
export class TaskPlanSubSectionDeleteComponent implements OnInit {
  //#region VARIABLES

  taskPlanId: number;
  sectionId: number;
  section: string;
  taskPlanSubSection: TaskPlanSubSection = new TaskPlanSubSection();
  displayWarningDesc: boolean;
  taskPlanPage: string;

  taskPlanSubSectionsLabels = {
    subSection: '',
    section: '',
  };

  //#endregion

  constructor(
    private taskPlanSvc: TaskPlanService,

    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((v) => {
      this.taskPlanId = +v['taskPlanId'];
      this.section = v['section'];
      this.sectionId = +v['sectionId'];
      this.taskPlanSubSection.ID = +v['id'];
      this.taskPlanPage = v['page'];

      this.taskPlanSubSectionsLabels.section = v['section'];
    });
    this.displayWarningDesc = false;
    this.getTaskPlanSubSectionById(this.taskPlanSubSection.ID);
  }

  public getTaskPlanSubSectionById(id: number): void {

    this.taskPlanSvc
      .getTaskPlanSubSectionById(id)

      .subscribe((data: any) => {
        this.taskPlanSubSection = data;

        this.taskPlanSubSectionsLabels.subSection =
          this.taskPlanSubSection.Description;
      });
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/task-plan-manage',
      {
        id: this.taskPlanId,
        sectionId: this.sectionId,
        page: this.taskPlanPage,
      },
    ]);
  }

  public deleteTaskPlanSubSection(): void {

    this.taskPlanSvc
      .deleteTaskPlanSubSection(this.taskPlanSubSection.ID)

      .subscribe(() => {
        this.onRedirectButtonClick();
      });
  }
}
