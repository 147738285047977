import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Language } from '#services/translate/languages';
import { Utility } from '#services/shared/utility';

@Injectable({
  providedIn: 'root',
})
export class LanguageLookupService {
  public getLanguagesPreferences(): Observable<
    Array<{ label: string; value: Language }>
  > {
    const languagePreferences = Object.entries(Utility.availableLanguages)
      .map<{
        label: string;
        value: Language;
      }>(([value, label]) => ({
        label,
        value: value as Language,
      }));


    return of<Array<{ label: string; value: Language }>>(languagePreferences);
  }

  public getAvailableLanguagesForTranslation(): Observable<
    Array<{ label: string; value: Language }>
  > {
    const languagePreferences = Object.entries(Utility.availableLanguages)
      .map<{ label: string; value: Language }>(([value, label]) => ({
        label,
        value: value as Language,
      }))
      .filter((language) => ['en', 'ru', 'kk'].includes(language.value));

    return of<Array<{ label: string; value: Language }>>(languagePreferences);
  }
}
