<div>
  <div class="row justify-content-end">
    <div class="col-sm-10">
      <button
        id="createpssrBtn"
        *ngIf="!project.IsDeleted && project.StatusName !== 'Closed'"
        type="button"
        class="btn btn-success whiteSpaceBtn"
        (click)="btnCreatePSSR()"
      >
        <span *ngIf="!selectedBU?.ShowCCC && !selectedBU?.ShowORR">{{
          "New PSSR" | translate
        }}</span>
        <span *ngIf="selectedBU?.ShowCCC && !selectedBU?.ShowORR">{{
          "NewPSSRCCC" | translate
        }}</span>
        <span *ngIf="selectedBU?.ShowORR && !selectedBU?.ShowCCC">{{
          "NewPSSRORR" | translate
        }}</span>
        <span *ngIf="selectedBU?.ShowCCC && selectedBU?.ShowORR">{{
          "NewPSSRCCCORR" | translate
        }}</span>
      </button>
    </div>
    <div class="col-sm-2">
      <button
        id="initiateAllPSSRsBtn"
        *ngIf="canInitiateAll"
        type="button"
        class="btn btn-primary whiteSpaceBtn"
        (click)="initiateAllPSSRs()"
      >
        {{ "Initiate All PSSRs" | translate }}
      </button>
    </div>
  </div>
  <br />
  <table
    class="table table-hover table-condensed"
    *ngIf="pssrNewList?.length > 0"
  >
    <thead>
      <tr class="bg-info">
        <th id="thDescription" class="col-sm-3">
          {{ "DescriptionScope" | translate }}
        </th>
        <th id="thStatus" class="col-sm-3" *ngIf="selectedBU.ShowQAQC">
          QA QC Status
        </th>
        <th id="thShowQAQC" class="col-sm-3" *ngIf="!selectedBU.ShowQAQC"></th>
        <th id="thLocation" class="col-sm-2 text-center">
          {{ "Location" | translate }}
        </th>
        <th id="thDates" class="col-sm-2">{{ "Dates" | translate }}</th>
        <th id="thActions" class="col-sm-1">{{ "Actions" | translate }}</th>
        <th id="thHolder" class="col-sm-1">&nbsp;</th>
      </tr>
    </thead>
    <tbody *ngFor="let p of pssrNewList">
      <tr>
        <td class="col-sm-3">
          <div class="row padding-left-5">
            {{ p.Name }}
          </div>
          <div
            *ngIf="p.QAQCMilestone && selectedBU.ShowQAQC"
            class="row padding-left-5"
          >
            <b>QA QC Milestone:</b> {{ p.QAQCMilestone }}
          </div>
          <div
            *ngIf="p.QAQCWorkOrder && selectedBU.ShowQAQC"
            class="row padding-left-5"
          >
            <b>QA QC WorkOrder:</b> {{ p.QAQCWorkOrder }}
          </div>
          <div *ngIf="p.MOC" class="row padding-left-5">
            <b>MOC#:</b> {{ p.MOC }}
          </div>
          <div
            *ngIf="p.Description"
            class="row padding-left-5"
            style="height: 2.7em; line-height: 1.3em; overflow: hidden"
          >
            <b>{{ "Description" | translate }}:</b> {{ p.Description }}
          </div>
          <div class="row">
            <div
              class="col-sm-5 text-center {{
                p.statusClass
              }} padding-left-5 padding-right-5"
              style="border-radius: 1em; color: white; max-width: 250px"
            >
              {{ p.StatusName | translate }}
            </div>
          </div>
        </td>
        <td class="col-sm-3">
          <ng-container *ngIf="p.QAQCItem && selectedBU.ShowQAQC">
            <div class="row padding-left-5">
              <div class="col-xs-4 padding-left-5">
                <b>% ready:</b>
              </div>
              <div
                class="col-sm-5 text-center qaqcClassReady padding-left-5 padding-right-5"
                style="border-radius: 1em; color: black; max-width: 150px"
                [ngStyle]="{ width: p.QAQCItem.PercentReady * 2 + 'px' }"
              >
                {{ p.QAQCItem.PercentReady }}
              </div>
            </div>
            <div class="row padding-left-5">
              <div class="col-xs-4 padding-left-5">
                <b>% complete:</b>
              </div>
              <div
                class="col-sm-5 text-center qaqcClassComplete padding-left-5 padding-right-5"
                style="border-radius: 1em; color: black; max-width: 150px"
                [ngStyle]="{ width: p.QAQCItem.PercentComplete * 2 + 'px' }"
              >
                {{ p.QAQCItem.PercentComplete }}
              </div>
            </div>
            <div class="row padding-left-5">
              <div class="col-xs-4 padding-left-5">
                <b>% accepted:</b>
              </div>
              <div
                class="col-sm-5 text-center qaqcClassAccepted padding-left-5 padding-right-5"
                style="border-radius: 1em; color: black; max-width: 150px"
                [ngStyle]="{ width: p.QAQCItem.PercentAccepted * 2 + 'px' }"
              >
                {{ p.QAQCItem.PercentAccepted }}
              </div>
            </div>
            <div *ngIf="p.QAQCItem.Status" class="row padding-left-5">
              <div class="col-xs-4 padding-left-5">
                <b>status:</b>
              </div>
              {{ p.QAQCItem.Status }}
            </div>
            <div *ngIf="p.QAQCItem.Signatures" class="row padding-left-5">
              <div class="col-xs-4 padding-left-5">
                <b>signatures:</b>
              </div>
              {{ qaqcSignatureCounter(p.QAQCItem) }}/3
            </div>
            <div *ngIf="p.QAQCItem.Uri" class="row padding-left-5">
              <a href="{{ p.QAQCItem.Uri }}" rel="noopener" target="_blank"
                >QA QC Documents</a
              >
            </div>
          </ng-container>
          <ng-container *ngIf="!p.QAQCItem && selectedBU.ShowQAQC">
            <div class="row padding-left-5">
              <div class="col-xs-4 padding-left-5">
                <b>no QA QC data</b>
              </div>
            </div>
          </ng-container>
        </td>
        <td class="col-sm-2">
          <div class="row">
            <div class="col-xs-7 text-right text-muted padding-left-0">
              <strong>{{ selectedBU.HierarchyLabel1 | translate }} :</strong>
            </div>
            <div class="col-xs-5 text-left padding-left-0">
              {{ p.Area }}
            </div>
          </div>
          <div class="row">
            <div class="col-xs-7 text-right text-muted padding-left-0">
              <strong>{{ selectedBU.HierarchyLabel2 | translate }} :</strong>
            </div>
            <div class="col-xs-5 text-left padding-left-0">
              {{ p.SubArea }}
            </div>
          </div>
          <div class="row" *ngIf="selectedBU.ShowHierarchyLabel3">
            <div class="col-xs-7 text-right text-muted padding-left-0">
              <strong>{{ selectedBU.HierarchyLabel3 | translate }} :</strong>
            </div>
            <div class="col-xs-5 text-left padding-left-0">
              {{ p.Facility }}
            </div>
          </div>
        </td>
        <td class="col-sm-2">
          <table>
            <tr *ngIf="p.CreateDate">
              <td nowrap class="col-xs-5 text-right padding-left-0">
                <strong
                  >{{ "Draft Created
                On" | translate }}:</strong
                >
              </td>
              <td class="col-xs-7 text-left padding-left-0">
                {{ p.CreateDate | date: "shortDate" }}
              </td>
            </tr>
            <tr *ngIf="p.InitiatedOn">
              <td nowrap class="col-xs-5 text-right padding-left-0">
                <strong>{{ "Initiated On" | translate }}:</strong>
              </td>
              <td class="col-xs-7 text-left padding-left-0">
                {{ p.InitiatedOn | date: "shortDate" }}&nbsp;
              </td>
            </tr>
            <tr *ngIf="p.StartedOn">
              <td nowrap class="col-xs-5 text-right padding-left-0">
                <strong>{{ "Started" | translate }}:</strong>
              </td>
              <td class="col-xs-7 text-left padding-left-0">
                {{ p.StartedOn | date: "shortDate" }}&nbsp;
              </td>
            </tr>
            <tr *ngIf="p.CompletedOn">
              <td nowrap class="col-xs-5 text-right padding-left-0">
                <strong
                  >{{
                    "Walkdown Completed
                On" | translate
                  }}:</strong
                >
              </td>
              <td class="col-xs-7 text-left padding-left-0">
                {{ p.CompletedOn | date: "shortDate" }}&nbsp;
              </td>
            </tr>
            <tr *ngIf="p.PendingApprovalOn">
              <td nowrap class="col-xs-5 text-right padding-left-0">
                <strong
                  >{{
                    "Pending Approval Submitted
                On" | translate
                  }}:</strong
                >
              </td>
              <td class="col-xs-7 text-left padding-left-0">
                {{ p.PendingApprovalOn | date: "shortDate" }}&nbsp;
              </td>
            </tr>
            <tr *ngIf="p.ApprovedOn">
              <td nowrap class="col-xs-5 text-right padding-left-0">
                <strong>{{ "Approved On" | translate }}:</strong>
              </td>
              <td class="col-xs-7 text-left padding-left-0">
                {{ p.ApprovedOn | date: "shortDate" }}&nbsp;
              </td>
            </tr>
            <tr *ngIf="p.ClosedOn">
              <td nowrap class="col-xs-5 text-right padding-left-0">
                <strong>{{ "Closed On" | translate }}: </strong>
              </td>
              <td class="col-xs-7 text-left padding-left-0">
                {{ p.ClosedOn | date: "shortDate" }}&nbsp;
              </td>
            </tr>
          </table>
        </td>
        <td class="col-sm-1">
          <table>
            <tr *ngFor="let q of p.QuestionsFormated">
              <td nowrap class="col-xs-5 text-right text-muted padding-left-0">
                <strong>{{ q.name }} :</strong>
              </td>
              <td class="col-xs-7 text-left padding-left-0">{{ q.value }}</td>
            </tr>
          </table>
        </td>
        <td class="col-sm-1">
          <div class="row">
            <button
              id="editpssrBtn"
              type="button"
              class="btn btn-primary btn-block whiteSpaceBtn"
              (click)="btnEditPSSR(p.Id)"
            >
              {{ "Open" | translate }}
            </button>
            <button
              id="viewitemsBtn"
              *ngIf="p.showViewItems"
              type="button"
              class="btn btn-success btn-block whiteSpaceBtn"
            >
              {{ "View Items" | translate }}
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
