export class QAQCResponse {
  Error: string;
  Results: QAQCItem[];
  RequestId: string;
}

export class QAQCItem {
  Type: string;
  Name: string;
  PercentReady: number;
  PercentComplete: number;
  PercentAccepted: number;
  Uri: string;
  Id: string;
  Description: string;
  Status: string;
  Signatures: QAQCSignature[];
  SignatureNumber = 0;
  MOCNumber: string;
}

export class QAQCRequest {
  Site: string;
  EventId: string;
  Type: string;
  MilestoneId: string;
}

export class QAQCSignature {
  Label: string;
  User: string;
  SignDate: string;
  Na: string;
}

export enum QAQCType {
  None = 0,
  Milestone = 1,
  WorkOrder = 2,
  MilestoneWorkOrder = 3
}
