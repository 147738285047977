import { Component, OnInit } from '@angular/core';
import { FacilityService } from '#services/api/facility.service';
import { Facility } from '#models/facility';
import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';


import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-facility-delete',
  templateUrl: './facility-delete.component.html',
})
export class FacilityDeleteComponent implements OnInit {
  facility: Facility = new Facility();
  facilityName: string;
  displayWarningName: boolean;
  subAreaId: number;
  areaId: number;
  buId: number;
  hierarchyLabel3: string;



  selectedBU: BusinessUnit;

  constructor(
    private toastrService: ToastrService,
    private facilitySvc: FacilityService,

    private router: Router,
    private route: ActivatedRoute,
    private businessUnitService: BusinessUnitsService
  ) {}

  ngOnInit() {
    this.buId = this.route.snapshot.params['buId'];
    this.buId = this.route.snapshot.params['buId'];
    this.areaId = this.route.snapshot.params['areaId'];
    this.subAreaId = this.route.snapshot.params['subAreaId'];
    this.facility.Id = this.route.snapshot.params['facilityId'];
    this.hierarchyLabel3 = this.route.snapshot.params['hierarchyLabel3'];

    this.getFacilityById(this.facility.Id);
    this.displayWarningName = false;

    this.businessUnitService
      .getBusinessUnitById(this.buId)
      .toPromise()
      .then((bu) => (this.selectedBU = bu));
  }

  onRedirectButtonClick(): void {
    this.router.navigate([
      'admin/facility',
      { buId: this.buId, areaId: this.areaId, subAreaId: this.subAreaId },
    ]);
  }

  getFacilityById(id: number): void {

    this.facilitySvc
      .getFacilityById(id)
      .subscribe(
        (data: Facility) => {
          this.facility = data;
          this.facilityName = this.facility.Name;
        },
        (error) => {

          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }

  deleteFacility(): void {

    this.facilitySvc
      .deleteFacility(this.facility.Id)

      .subscribe(
        () => {
          this.onRedirectButtonClick();
        },
        (error) => {

          this.toastrService.showError( error.error.ExceptionMessage);
        }
      );
  }
}
