import { TaskPlanSubSection } from '#models/task-plan-sub-section';
import { SectionUser } from './section/section';
import { SectionType } from './enum/section-type';

import { PSSRResponse } from './pssr-response';
import { ResponseItem } from './response-item';
import { ResponseStatus } from './enum/response-status';

export enum TaskPlanSectionType {
  Optional = 0,
  Mandatory,
  Default,
  Multiple,
}

export class TaskPlanSection {
  constructor() {
    this.Responses = new Array<PSSRResponse>();
    this.TaskPlanSubSections = new Array<TaskPlanSubSection>();
    this.StandAloneItems = [];
    this.Description = '';
    this.isCollapsed = false;
    this.CreateDate = new Date();
  }

  public General: boolean;
  public Description: string;
  public ID: number;
  public TaskPlanId: number;
  public SortID: number;
  public SectionType: TaskPlanSectionType = TaskPlanSectionType.Mandatory;
  public TaskPlanSubSections: TaskPlanSubSection[] = [];
  Users: SectionUser[];

  // Custom properties (not in DTO)
  Type: SectionType;
  Responses: Array<PSSRResponse> = [];
  StandAloneItems: Array<ResponseItem> = [];
  isCollapsed: boolean;
  CreateDate: Date = new Date();
  ResponseStatus: ResponseStatus;
}
