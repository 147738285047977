export class LocationFilter {
  buId: number;
  buName: string;

  orgId: number;
  orgName: string;

  areaId: number;
  areaName: string;

  subAreaId: number;
  subAreaName: string;
}
