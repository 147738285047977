import { BusinessUnit } from '#models/business-unit';
import { PSSR } from '#models/pssr';
import { User } from '#models/user';
import { Category } from '#models/category';
import { PSSRStatus } from '#models/pssr-status';
import { ResponseItem } from '#models/response-item';
import { ResponseItemStatus } from '#models/response-item-status';
import { SubCategory } from '#models/subcategory';
import { AuthService } from '#services/shared';
import { Utility } from '#services/shared/utility';
import { Injectable } from '@angular/core';

@Injectable()
export class ActionItemDialogService {
  constructor(private authService: AuthService) {}
  completeActionItem({
    actionItem,
    assignTo,
    queryParticipant = '',
    groups = [],
    isTraslator = false
  }: {
    actionItem: ResponseItem;
    assignTo: {
      assignToType: number;
      selectedUser: User;
      selectedCategory: Category;
      selectedSubCategory: SubCategory;
    };
    queryParticipant?: string;
    groups: any[];
    isTraslator: boolean;
  }): ResponseItem {
    if (!isTraslator && actionItem.Status === ResponseItemStatus.Closed) {
      // if ActionItem is marked as closed.
      const currentUser = this.authService.getCurrentUser();
      actionItem.ClosedBy = `${currentUser.FirstName} ${currentUser.LastName}`;
      actionItem.ClosedOn = new Date(new Date().getTime());
    }

    const { assignToType } = assignTo;
    if (assignToType == 1) {
      actionItem.AssignedCAI = null;
      actionItem.AssignedText = null;
      actionItem.AssignedGroupID = null;
      actionItem.AssignedGroupName = null;
    } else if (assignToType == 2) {
      // CAI - Azure service
      const selectedUser = assignTo.selectedUser;
      actionItem.AssignedUserID = selectedUser.ID;
      actionItem.AssignedCAI =
        selectedUser.CAI != undefined ? selectedUser.CAI : queryParticipant;
      actionItem.AssignedText = selectedUser.Email;
      actionItem.AssignedGroupID = null;
      actionItem.AssignedGroupName = null;
    } else if (assignToType == 3) {
      actionItem.AssignedUserID = null;
      actionItem.AssignedCAI = null;
      actionItem.AssignedGroupID = null;
      actionItem.AssignedGroupName = null;
    } else if (assignToType == 4) {
      actionItem.AssignedUserID = null;
      actionItem.AssignedCAI = null;
      actionItem.AssignedText = null;

      if (groups.length > 0) {
        const selectedGroup = groups.find(
          (x) => x.Id - actionItem.AssignedGroupID === 0
        );

        actionItem.AssignedGroupName = selectedGroup ? selectedGroup.Name : '';
      }
    }
    return actionItem;
  }
  getFullName(actionItem: ResponseItem): string {
    let fullName = actionItem.AssignedTo;
    if (actionItem.AssignedText) {
      fullName +=
        actionItem.AssignedText.includes('tengizchevroil.com') &&
        actionItem.AssignedText.includes(actionItem.AssignedCAI)
          ? ' (Private)'
          : ' (Shared)';
      fullName += ` <${actionItem.AssignedText}>`;
    }
    if (actionItem.AssignedCAI) {
      fullName += ` ${actionItem.AssignedCAI}`;
    }
    return fullName;
  }
  prepareActionItem({
    actionItem,

    pssr,
    selectedBU,
  }: {
    actionItem: ResponseItem;

    pssr: PSSR;
    selectedBU: BusinessUnit;
  }) {
    let selectedGroup;
    const assignTo = {
      selectedCategory: null,
      selectedSubCategory: null,
      assignToType: 1,
      selectedUser: null,
    };

    assignTo.selectedCategory = selectedBU.Categories.find(
      (c) => c.Id === actionItem.CategoryID
    );

    if (assignTo.selectedCategory) {
      assignTo.selectedSubCategory =
        assignTo.selectedCategory.SubCategories.find(
          (s) => s.Id === actionItem.SubCategoryID
        );
    }

    const pssrUser = pssr.PSSRUsers.Users.find(
      (f) => f.UserID == actionItem.AssignedUserID
    );

    if (pssrUser != null) {
      assignTo.assignToType = 1; // PSSR MEMBER
    } else if (actionItem.AssignedUserID != null) {
      assignTo.assignToType = 2; // CAI
      assignTo.selectedUser = {
        ID: actionItem.AssignedUserID,
        FullName: this.getFullName(actionItem),
        CAI: actionItem.AssignedCAI,
        Email: actionItem.AssignedText
      };
    } else if (actionItem.AssignedGroupID != null) {
      assignTo.assignToType = 4; // GROUP
      selectedGroup = {
        Id: actionItem.AssignedGroupID,
        Name: actionItem.AssignedGroupName,
      };
    } else {
      assignTo.assignToType = 3; // OTHER
    }

    return {
      assignTo,
      selectedGroup,
    };
  }
  setupActionItem({
    pssr,
    responseItem = {},
    responseId = null,
  }: {
    pssr: PSSR;
    responseId?: number;
    responseItem?: Partial<ResponseItem>;
  }): Partial<ResponseItem> {
    const defaultItems = {
      Action: '',
      DueDate: null,
      ActionID: null,
      Status: ResponseItemStatus.Open,
      StatusName: 'Open',
      CreateDate: new Date(),
      ClosedBy: null,
      ClosedOn: null,
      Comment: '',
      CanEditItem: false,
      CanReOpenItem: false,
      IsActionItem: true,
      CanRejectItem: false,
      AfterWalkthrough: false,
      RejectReason: null,
      Id: null,
      AssignedText: '',
      AssignedGroupID: null,
      CategoryID: null,
      SubCategoryID: null,
      AssignedCAI: null,
      AssignedGroupName: '',
      AssignedTo: '',
      UpdatedDate: new Date(),
      Links: [],
      CurrentUserIsActionItemAssignee: false,
    };

    responseItem = { ...defaultItems, ...responseItem };
    const userId = Utility.isValidObj(responseItem) && responseItem.AssignedUserID > 0 ?
      responseItem.AssignedUserID :
        Utility.isValidObj(pssr.PSSRUsers.Users[0]) ?
          pssr.PSSRUsers.Users[0].UserID : 0;
    return {
      Priority: pssr.Status === PSSRStatus.Completed ? 3 : 4,
      AssignedUserID: userId,
      ...responseItem,
      PSSRID: pssr.Id,
      ResponseID: responseId,
      OrganizationId: pssr.OrganizationID,
    };
  }
  setupComment({ pssrId, responseId, organizationId }): Partial<ResponseItem> {
    return {
      Action: null,
      ActionID: null,
      Status: ResponseItemStatus.None,
      StatusName: 'None',
      Priority: 0,
      CreateDate: new Date(),
      ClosedBy: null,
      ClosedOn: null,
      Comment: '',
      CanEditItem: false,
      CanReOpenItem: false,
      IsActionItem: false,
      CanRejectItem: false,
      Links: [],
      Id: 0,
      ResponseID: responseId,
      PSSRID: pssrId,
      OrganizationId: organizationId,
      UpdatedDate: new Date(),
    };
  }
}
