import { UserSimple } from './user-simple';

export class OrganizationUser extends UserSimple {
  Id: number;
  OrganizationId: number;
  UserID: number;
  IsAdmin: boolean;
  OrganizationName: string;
  BusinessUnitName: string;
  OrganizationID?: number;
}
