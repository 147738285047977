import { DictionaryEntries } from './dictionary-entries';

export const EN: DictionaryEntries = {
  '&': '&',
  'AllBactionitemsmustbeclosed.': 'All B action items must be closed',
  '3rdPartyexternal': '3rd Party (external)',
  ABChecklist: 'AB Checklist',
  ABChecklistfor: 'AB Checklist for',
  ACTIONS: 'ACTIONS',
  AChecklist: 'A Checklist',
  Action: 'Action',
  ActionConfirmation: 'Are you sure that you want to perform this action?',
  ActionItemAssignee: 'Action Item Assignee',
  ActionItemisneededtocompletequestion: 'Action Item is needed to complete question',
  ActionItems: 'Action Items',
  ActionIsRequired: 'Action is required',
  ActionWillBeTransferred: 'Action will be transferred to operations',
  Actions: 'Actions',
  Actionwillbetransferredtooperations: 'Item will be transferred to operations',
  Active: 'Active',
  Add: 'Add',
  AddABChecklisttoProject: 'Add ABChecklist to Project',
  AddABChecktoEndDevice: 'Add ABCheck to End Device',
  AddComment: 'Add Comment',
  AddFeedback: 'Add Feedback',
  AddGroup: 'Add Group',
  AddNew: 'ADD NEW',
  AddQuestion: 'Add Question',
  AddRedLining: 'Add Red Lining',
  AddSignature: 'ADD SIGNATURE',
  AddTemplate: 'Add Template',
  AddUser: 'Add User',
  AddVersion: 'Add Version',
  AddYellowLining: 'Add Yellow Lining',
  Addactionitem: 'Add Action Item',
  AddtoEAM: 'Add to EAM',
  Alert: 'Alert',
  Alltherelatedsectionssubsectionsitemswillberemoved: 'All the related section(s) | subsection(s) | item(s) will be removed',
  Alltherelatedsubsectionsitemswillberemoved: 'All the related subsection(s) | item(s) will be removed',
  SendAllNotificationsToApproversInstruction: '*If Checked Final Approver will receive all notifications',
  AllP3actionmustbeclosedtosubmitforapproval: 'All {{P3Label}} action must be closed to submit for approval',
  AllowAIOwnerToAttach: 'Allow AI Owner To Attach',
  AllowAIOwnerToAttachInstruction: 'If Checked allows Action Item Owner to attach files even at any status of the AI while PSSR is not closed',
  AllowApproverToSubmitForApproval: 'Allow Final Approver To Submit For Approval',
  AllowLeadsDeleteQuestions: 'Allow Leads Delete Questions',
  AllowMembersCompletePSSR: 'Allow Members To Complete PSSR',
  AllowMembersToCompletePSSR: 'Allow Members To Complete PSSR',
  AllowMultiApprover: 'Allow Multi Approver',
  AllowNAResponses: 'Allow NA Responses',
  AllowWetSignature: 'Allow PSSR wet signature',
  Allpages: 'All pages',
  Alltherelated: 'All the related',
  Alltherelateditemswillberemoved: 'All the related item(s) will be removed',
  AngularTemplatedocumentation: 'Angular Template documentation',
  Answer: 'Answer',
  AnswerallquestionsandsignaturestoCompletethePSSR: 'Answer all questions & signatures to Complete the {{PssrTitle}}',
  AnswerallquestionssignaturestoCompletethePSSR: 'Answer all questions & signatures to Complete the {{PssrTitle}}',
  Answerwillbecleared: 'Answer will be cleared',
  Approve: 'Approve',
  Approvebywetsignature: 'Approve by wet signature',
  Approved: 'Approved',
  ApprovedOn: 'Approved On',
  AreyousureyouwanttodeleteABChecklistbelow: 'Are you sure you want to delete AB Checklist below',
  AreyousureyouwanttodeleteSubCategory: 'Are you sure you want to delete Sub Category',
  Area: 'Area',
  Areyousureyouwanttodelete: 'Are you sure you want to delete',
  AreyousureyouwanttodeleteTaskPlanbelow: 'Are you sure you want to delete Task Plan below ',
  AssignedToLeadFinalApprover: 'Assigned to Lead / Final Approver',
  Assignedto: 'Assigned to',
  Assignedtome: 'Assigned to me',
  Assignee: 'Assignee',
  Assignto: 'Assign to',
  Attach: 'Attach',
  AttachedFiles: 'Attached Files',
  AutoLoadImages: 'Auto-Load Images',
  Autoclosems: 'Auto close (ms.)',
  Available: 'Available',
  BChecklist: 'B Checklist',
  BUAdmin: 'BU Admin',
  BUAdmins: 'BU Admins',
  Back: 'Back',
  BacktoSubCategoryList: 'Back to Sub Category List',
  BacktoTaskPlan: 'Back to Task Plan',
  BackToList: 'Back to List',
  Backto: 'Back to',
  BacktoABChecksList: 'Back to ABChecks List',
  BacktoAreaList: 'Back to Area List',
  BacktoGroupList: 'Back to Group List',
  BacktoList: ' Back to List',
  BacktoProjectDetail: 'Back to Project Detail',
  BacktoProjectList: 'Back to Project List',
  BacktoTaskPlanManage: 'Back to Task Plan Manage',
  Bug: 'Bug',
  BuildNumber: 'Build Number',
  BusinessUnit: 'Business Unit',
  BusinessUnitisrequired: 'Business Unit is required',
  BusinessUnits: 'Business Units',
  By: 'By ',
  ByDefaultProject: 'By default is \'Project\'',
  CCCORR: 'PSSR / CCC / ORR',
  CanOnlyPerformAttachments: 'Can Only Perform Attachments',
  Cancel: 'Cancel',
  Canceled: 'Canceled',
  CaptureSignature: 'Capture Signature',
  Categories: 'Categories',
  ChangeRequestApproved: 'Change Request Approved',
  ChangeRequestDenied: 'Change Request Denied',
  Chevron: 'Chevron',
  ChevronCorporationAllrightsreserved: 'Chevron Corporation. All rights reserved.',
  ChevronDirectory: 'Chevron Directory',
  'ChevronDirectory/SharedUsers': 'Chevron Directory / Shared Users',
  ChevronIE: 'Chevron I&E',
  ChevronSFT: 'Chevron SFT',
  Clear: 'Clear',
  ClearFilter: 'Clear Filter',
  ClearQuestion: 'Clear Question',
  ClearSelection: 'Clear Selection',
  Close: 'Close',
  CloseProjWarning: 'Do you want to close this project?',
  CloseProject: 'Close Project',
  Closed: 'Closed',
  ClosedBy: 'Closed By',
  ClosedOn: 'Closed On',
  Code: 'Code',
  ComissionDate: 'Comission Date',
  Comment: 'Comment',
  Commentgoeshere: 'Comment goes here',
  Commentisneededtocompletequestion: 'Comment is needed to complete question',
  CompleteTranslation: 'Complete Translation',
  Completed: 'Completed',
  CompletedQuestions: 'Completed Questions',
  ConfigureActionItem: 'Configure Action Item',
  Confirm: 'Confirm',
  ConfirmDeleteHeader: 'Confirm Delete',
  ConfirmDeleteMessage: 'You are about to delete this item, do you want to proceed?',
  ConfirmDeletePSSRFileHeader: 'Confirm delete',
  ConfirmDeletePSSRFileMessage: 'File <b>{{fileName}}</b> will be permanently removed, continue?<br/>',
  ConfirmPSSRApprovalHeader: '',
  ConfirmPSSRApprovalMessage: 'Are you sure you want to approve it?',
  ConfirmPSSRReOpenMessage: 'Are you sure you want to re-open this PSSR?',
  ConfirmPSSRReOpenHeader: '',
  ConfirmRejectionHeader: 'Confirm Rejection',
  ConfirmRejectionMessage: 'If you reject action item, you will be unable to approve this pssr. You will need to change the status to walkdown complete by hitting the <b>{{actionsButton}}\'s</b> button at the bottom of the page.',
  ConfirmTranslationComplete: 'Are you sure that Title, Description, Action Items and Comments has been translated?',
  Confirmdelete: 'Confirm delete',
  Confirmtoproceed: 'Please <b>Confirm</b> to proceed',
  ContinueConfirmation: 'You want to continue?',
  Contractor: 'Contractor',
  CopiedImage: 'Image <b>{{docName}}</b> has been copied',
  Copy: 'Copy',
  CopyEquipmentstoProject: 'Copy Equipments to Project',
  CopyImage: 'Copy Image',
  CopyImageError: 'Copy Image Error',
  CopyfromProject: 'Copy from Project',
  CopyingImage: 'Copying image...',
  Copyto: 'Copy to',
  CostCode: 'Cost Code',
  Create: 'Create',
  CreateNewItem: 'Create New Item',
  CreateBusinessUnit: 'Create Business Unit',
  CreateGroup: 'Create Group',
  CreateNew: 'Create New',
  CreateNewSubCategory: 'Create New Sub Category',
  CreatePSSR: 'Create {{PssrTitle}}',
  CreatePSSRCCC: 'Create PSSR / CCC',
  CreatePSSRCCCORR: 'Create PSSR / CCC / ORR',
  CreatePSSRORR: 'Create PSSR / ORR',
  CreateProject: 'Create Project',
  CreateSubCategory: 'Create Sub Category',
  CreateTraining: 'Create Training',
  CreateUser: 'Create User',
  CreatedBy: 'Created By',
  CreatedOn: 'Created On',
  DBVersion: 'DB Version',
  Date: 'Date',
  DateCaptured: 'Date Captured',
  Dates: 'Dates',
  Default: 'Default',
  DefaultSummarySignatureDisclaimer: 'Default Summary Signature Disclaimer',
  DefaultWalkdownSignatureDisclaimer: 'Default Walkdown Signature Disclaimer',
  Delete: 'Delete',
  DeleteActionItem: 'Delete Action Item',
  DeleteDevice: 'Do you agree to Delete selected Device',
  DeleteEndDevice: 'Delete End Device',
  DeleteEquipment: 'Delete Equipment',
  DeleteEquipmentTemplateGroup: 'Delete Equipment Template Group',
  DeleteManufacturerModel: 'Delete Manufacturer - Model',
  DeleteProject: 'This action will result in the deletion of this project and all PSSR involved. Do you want to proceed?',
  DeleteTaskPlanSection: 'Delete Task Plan Section',
  DeleteTaskPlanSubSection: 'Delete Task Plan Sub Section',
  DeleteselectedEquipmentTemplateGroup: 'Do you agree to Delete selected Equipment Template Group',
  Description: 'Description',
  DescriptionScope: 'Description & Scope',
  Descriptioninputwasalreadyused: 'Description input was already used',
  Descriptionisrequired: 'Description is required',
  DeviceType: 'Device Type',
  DeviceTypeEAM: 'Device Type - EAM Name - EAM Description',
  Disabled: 'Disabled',
  Discard: 'Discard',
  DisplayRichEditor: 'Display Rich Editor',
  Documents: 'Documents',
  Download: 'Download',
  DownloadError: 'Download File Error',
  DownloadingDocument: 'Downloading document...',
  DoyouagreetoDeleteselectedManufacturerModel: 'Do you agree to Delete selected Manufacturer - Model',
  DoyouagreetoDeleteselectedequipment: 'Do you agree to Delete selected equipment',
  DoyouagreetoRemoveselectedparticipant: 'Do you agree to Remove selected participant',
  Draft: 'Draft',
  DraftCreatedOn: 'Draft Created On',
  Dropdown: 'Dropdown',
  DueDate: 'Due Date',
  RequireActionItemDueDateInstruction: 'If checked, the Due Date for action items will be mandatory',
  EAMClass: 'EAM Class',
  EAMCriticality: 'EAM Criticality',
  EAMDepartment: 'EAM Department',
  EAMDescription: 'EAM Description',
  EAMName: 'EAM Name',
  EAMType: 'EAM Type',
  ENDDEVICESPECIFICABCHECKLISTS: 'END DEVICE SPECIFIC AB CHECKLISTS',
  EQUIPMENT: 'EQUIPMENT',
  Edit: 'Edit',
  EditBusinessUnit: 'Edit Business Unit',
  EditGroup: 'Edit Group',
  EditImageError: 'Edit Image Error',
  EditOrganization: 'Edit Organization',
  EditParticipant: 'Edit Participant',
  EditProject: 'Edit Project',
  EditRedLining: 'Edit Red Lining',
  EditSubCategory: 'Edit Sub Category',
  EditTaskPlanSection: 'Edit Task Plan Section',
  EditYellowLining: 'Edit Yellow Lining',
  Email: 'Email',
  EndDevice: 'End Device',
  EndDeviceList: 'End Device List',
  EndDeviceTemplate: 'End Device Template',
  EndDevices: 'End Devices',
  English: 'English',
  EnglishTranslationInProgress: 'English Translation In Progress',
  Equipment: 'Equipment',
  EquipmentGroup: 'Equipment Group',
  EquipmentList: 'Equipment List',
  EquipmentTemplate: 'Equipment Template',
  EquipmentTemplateGroup: 'Equipment Template Group',
  EquipmentTemplateGroups: 'Equipment Template Groups',
  EquipmentTemplates: 'Equipment Templates',
  EraseLocalData: 'Erase Local Data',
  ErrorImageEditMessage: 'It was not possible to open image to edit',
  ErrorImageMessage: 'It was not possible to copy image',
  ErrorImageUndoMessage: 'It was not possible to undo image',
  EventLabel: 'Event Name Label',
  EventName: 'Event Name',
  ExistingNonChevronShared: 'Existing Non-Chevron/Shared',
  ExportABChecklistsasPDF: 'Export AB Checklists as PDF',
  ExportExcel: 'Export Excel',
  Facility: 'Facility',
  FacilityHierarchy: 'Facility Hierarchy',
  FacilityNo: 'Facility #',
  FacilityOperationTeam: 'Facility Operation Team',
  Factory: 'Factory',
  Featurerequest: 'Feature request',
  Feedback: 'Feedback',
  FeedbackScreen: 'Feedback Screen',
  Fieldisrequired: 'Field is required',
  FileDuplicate: 'The document you are trying to upload already exists. Please click Cancel button to cancel the upload or click Upload button to replace the existing document',
  Files: 'Files',
  FilesUploaded: 'Files were successfully uploaded',
  FilesUploadedWithWarning: 'Some files were successfully uploaded. Please try again.',
  FillAndSave: 'Please fill up the fields that are marked required. Please click Save.',
  Filter: 'Filter',
  FilterKMSListbyStatus: 'Filter: KMS List by Status',
  FilterNumberofKMSItemsreturned: 'Filter: Number of KMS Items returned',
  FilteredActionItemsInitialWarning: ' Page is filtered by default to current user and status open',
  FinalApproval: 'Final Approval',
  FinalApprover: 'Final Approver',
  RequireFinalApproverBeforeInitiationInstruction: 'If checked, the PSSR will require to have a Final Approver to Initiate it',
  FinalApproverPresent: 'If approver is marked present, final sign off can be provided on device, if not submit for approval and Sync',
  FinalApproverSignature: 'Final Approver Signature',
  FoundImages: 'Found Images',
  FullName: 'Full Name',
  GENERALINFORMATION: 'GENERAL INFORMATION',
  GeneralInformation: 'General Information',
  GeneratePSSR: 'Generate {{PSSRLabel}}',
  GetEAMLoadsheet: 'Get EAM Loadsheet',
  GetEquipmentList: 'Get Equipment List',
  GoBack: 'Go Back',
  Group: 'Group',
  GroupName: 'Group Name',
  GroupNames: 'Group Names',
  UserGroups: 'User Groups',
  Hi: 'Hi',
  HideCode: 'Hide Code',
  HierarchyLabel1: 'Hierarchy Label 1',
  HierarchyLabel2: 'Hierarchy Label 2',
  HierarchyLabel3: 'Hierarchy Label 3',
  HierarchyLabel3show: 'Hierarchy Label 3 (show)',
  I: 'I',
  ID: 'ID',
  Iagree: 'I agree',
  IagreethatallPSSRchecklistitems: 'I agree that all PSSR checklist items and all action items needed for a safe startup have been recorded accurately',
  Idontagree: 'I don\'t agree',
  Image: 'Image',
  ImageCaption: 'Caption',
  ImageCapture: 'Image Capture',
  Images: 'Images, PSI Drawings',
  ImplementationInProgress: 'Implementation In Progress',
  ImplementationInProgressEdit: 'Implementation In Progress Edit',
  ImportSharedUser: 'Import Shared User',
  ImportMOCActions: 'Import MOC Action Items',
  InProgress: 'In Progress',
  Inactive: 'Inactive',
  IncludeProjectTeam: 'Include Project Team Members',
  Info: 'Info',
  Initiate: 'Initiate',
  Initiated: 'Initiated',
  InitiatedOn: 'Initiated',
  Input: 'Input',
  InputSharedUserEmail: 'Input shared user email',
  InputdescriptionfornewSection: 'Input description for new Section',
  Instructions: 'Instructions',
  InternalError: 'Internal Error',
  IsAdmin: 'Is Admin',
  IsExternalUser: 'Is External User',
  IsSectionOrderEnabled: 'Enable Custom Order for Sections',
  ItemCode: 'ItemCode',
  ItemCodeMsg: '- Item Code:  <b>',
  ItemDescriptionMsg: '</b><br/>   - Item Description: <b>',
  Itemwillbetransferredtooperations: 'Item will be transferred to operations',
  Iteration: 'Iteration',
  KMSInstruction: 'Search by a specific MOC number (left) or select from the MOC List Dropdown (right)',

  LOCATION: 'LOCATION',
  LimitAccessToAIOwner: 'Limit Access To AI Owner',
  LimitAccessToAIOwnerInstruction: 'If Checked:  does not allow Action Item Owner/Assignee to modify AI description, due date, or delete the AI. ',
  AllowTeamLeadToAttach: 'Allow team lead to attach and modify AI',
  AllowTeamLeadToAttachInstruction: 'If Checked: for Leads after pending approval can only attach files, assignee, status and comment',
  Links: 'Links ',
  List: 'List',
  Load: 'Load',
  LoadImages: 'Load Images',
  LoadNewQAQC: 'Load New QA QC Items',
  Loading: 'Loading',
  LocaldbID: 'Local db ID',
  Location: 'Location',
  LogScreen: 'Log Screen',
  Login: 'Log in',
  LoginExpiresOn: 'Login Expires On',
  LoginProfile: 'Login Profile',
  Logout: 'Log out',
  LookupbyPasscode: 'Lookup by Passcode',
  Loose: 'Loose',
  MOC: 'MOC',
  MOCWO: 'MOC or WO#',
  MOCWOProjectNumberRestrictionEnabled: 'MOCWO Project Number Restriction Enabled',
  Manage: 'Manage',
  ManageContentof: 'Manage Content of',
  ManagesEquipments: 'Manages Equipments',
  Mandatory: 'Mandatory',
  Manufacturer: 'Manufacturer - Model',
  ManufacturerModel: 'Manufacturer - Model',
  ManufacturerModelfor: 'Manufacturer - Model for',
  MarkasClosed: 'Mark as Closed',
  Message: 'Message',
  Milestone: 'QAQC Milestone',
  MilestoneAndWorkOrders: 'QAQC Milestones/WorkOrders',
  MilestoneWorkOrder: 'QAQC Milestone\'s WO',
  MilestoneWorkOrders: 'QAQC Milestone\'s Work Orders',
  Milestones: 'QAQC Milestones',
  MilestonesWorkOrdersNoavailable: 'No available milestone\'s WO from QAQC',
  MobileAppVersions: 'Mobile App Versions',
  MobileFeature: 'Feature\'s only available on mobile',
  MobilePSSR: 'Mobile PSSR',
  Model: 'Model',
  Multiple: 'Multiple',
  MultipleFinalApprovers: 'If checked, allows PSSR to have multiple Final Approvers',
  Mustbecompletedbeforetostartup: 'Item must be completed before start up',
  NA: 'N/A',
  Name: 'Name',
  NewNonChevron: 'New Non-Chevron',
  NewOrganization: 'New Organization',
  NewPSSR: 'New PSSR',
  NewPSSRCCC: 'New PSSR / CCC',
  NewPSSRCCCORR: 'New PSSR / CCC / ORR ',
  NewPSSRORR: 'New PSSR / ORR',
  NewSectionDescription: 'New Section Description',
  No: 'No',
  Nomobileappversionsyet: 'No mobile app versions yet',
  NoActionItems: 'No Action Items',
  Nofeedbackyet: 'No feedback yet',
  Nomilestones: 'No available milestones/workorders from QAQC',
  NonMOC: 'Non MOC',
  NotRegistered: 'Not Registered',
  NotVisibleMobile: 'This section is not yet visible on mobile until user syncs',
  Notauthorized: 'Not authorized',
  Note: 'Note',
  Notfound: 'Not found',
  Notifications: 'Notifications',
  OPEN: 'OPEN',
  OPSignature: 'OP Signature',
  ORR: 'ORR',
  OnHold: 'On Hold',
  OnlyFinalApprovercanApprovePSSR: 'Only Final Approver can Approve {{PssrTitle}}',
  OnlyPSSRTeamLeadClosePSSR: 'Only {{PssrTitle}} Team Lead can Close PSSR.',
  OnlyPSSRTeamLeadcanClosePSSR: 'Only PSSR Team Lead and Final Approver can Close PSSR',
  OnlyPSSRTeamLeadcanInitiatePSSR: 'Only {{PssrTitle}} Team Lead can Initiate PSSR',
  OnlyPSSRTeamLeadcanSubmitforPendingApproval: 'Only {{PssrTitle}} Team Lead can Submit for Pending Approval.',
  OnlyTeamLeadorSectionLeadcananswerthequestions: 'Only Team Lead or Section Lead can answer the questions.',
  Open: 'Open',
  OpenStatus: 'Open',
  OpenError: 'Open File Error',
  OpenErrorMsg: 'It was not possible open document',
  OpeningDialog: 'Opening dialog',
  OpeningImage: 'Opening image...',
  OperationTeam: 'Operation Team',
  OperationsRepresentative: 'Operations Representative',
  Optional: 'Optional',
  OrgLabel: 'Org Label',
  Organization: 'Organization',
  OrganizationAdmin: 'Organization Admin',
  OrganizationLabel: 'Organization Label',
  Organizationisrequired: 'Organization is required',
  Other: 'Other',
  OtherOptions: 'Other Options',
  OutstandingQuestions: 'Outstanding Questions',
  P3ActionItemsMustBeClosed: '{{P3Label}} Action Items must be closed.',
  P3Label: 'P3 Label',
  P4ActionItemsMustBeClosed: '{{P4Label}} Action Items must be closed.',
  P4Label: 'P4 Label',
  P5Label: 'P5 Label',
  PARTICIPANTS: 'PARTICIPANTS',
  PDFPreview: 'PDF Preview',
  PIDVerificationRedLining: 'P&ID Verification (Red Lining)',
  PIDVerificationYellowLining: 'P&ID Verification (Yellow Lining)',
  PROJECTSPECIFICABCHECKLISTS: 'PROJECT SPECIFIC AB CHECKLISTS',
  PROJECTSTATUS: 'PROJECT STATUS',
  PSSR: 'PSSR',
  PSSRID: 'PSSR ID',
  PSSRCCC: 'PSSR / CCC',
  PSSRInitiatorGroup: 'PSSR Initiator Group',
  PSSRLabel: 'PSSR Label',
  PSSRMembers: '{{PssrTitle}} Members',
  PSSRORR: 'PSSR / ORR',
  PSSRSections: 'PSSR Sections',
  PSSRStatus: '{{PssrTitle}} Status',
  PSSRTeam: '{{PssrTitle}} Team',
  PSSRTeamLead: '{{PssrTitle}} Team Lead',
  PSSRTemplates: 'PSSR Templates',
  PSSRTemplateisrequired: 'PSSR Template is required',
  PSSRTitle: 'PSSR Title',
  PSSRType: 'PSSR Type',
  PSSRTypes: 'PSSR Types',
  PSSRmusthaveaFinalApprovertocompleteWalkthrough: '{{PssrTitle}} must have a Final Approver to complete Walkthrough',
  PSSRs: 'PSSRs',
  NavigateToMainPage: '{{PssrTitle}}s',
  Page: 'Page',
  Search: 'Search',
  Participants: 'Participants',
  ParticipantsinBlueparticipatedinWalkdown: 'Participants in Blue participated in Walkdown',
  ParticipatedinWalkdown: 'Participated in Walkdown',
  PendingApproval: 'Pending Approval',
  PendingApprovalSubmittedOn: 'Pending Approval Submitted On',
  Photos: 'Photos',
  PickError: 'Pick File Error',
  PleaseNotify: 'Please notify participants to sync their mobile devices before continuing with their activities',
  PleaseclickSave: 'Please click Save',
  Pleasecorrectpageaddress: 'Please correct page address or contact support if you believe this url is correct',
  Pleasefillupthefields: 'Please fill up the fields',
  Pleasefillupthefieldsthataremarkedrequired: 'Please fill up the fields that are marked required',
  PleasegotoSettings: 'Please go to \'Settings - Notifications - Mobile PSSR\' to allow notification',
  Pleaseselectanyoption: 'Please select any option that apply to the scope of change and template',
  Pleaseselectanyoptionthatapplytothescopeofchangeandtemplate: 'Please select any option that apply to the scope of change and template',
  'Post-WalkdownActionItems': 'Post-Walkdown Action Items',
  PostWalkdown: 'Post Walkdown',
  PreStartupSafetyReview: 'Pre-Startup Safety Review',
  Present: 'Present',
  PrimaryCategory: 'Primary Category',
  Print: 'Print',
  PrinttoPDF: 'Print to PDF',
  Priority: 'Priority',
  ProcessActivated: 'Process Activated',
  Progress: 'Progress',
  Project: 'Project',
  ProjectName: 'Project Name',
  ProjectTeamWillComplete: 'Project team will complete after start up',
  Projects: 'Projects',
  ProjectsList: 'Projects List',
  Projectteamwillcompleteafterstartup: 'Item will be complete after start up',
  ProvidedBy: 'Provided By',
  PullFromKMS: 'Pull from KMS',
  PushNotification: 'Push Notification',
  Question: 'Question',
  RESET: 'RESET',
  ReOpen: 'Reopen',
  ReadyToStartUp: 'Ready To Start Up',
  ReceivePushNotification: 'Receive Push Notification',
  Receivedfrom: 'Received from',
  Recordwillbepermanentlyremovedcontinue: 'Record will be permanently removed, continue?<br/>',
  RedirectURL: 'Redirect URL',
  ReferenceImage: 'Reference Image',
  Reject: 'Reject',
  Rejectionreason: 'Rejection reason',
  ReleaseDate: 'Release Date',
  Remove: 'Remove',
  RemoveLocalData: 'Remove Local Data',
  RemoveLog: 'Remove Log',
  RemoveParticipant: 'Remove Participant',
  RemoveRedLining: 'Remove Red Lining',
  RemoveSection: 'Remove Section',
  RemoveSignature: 'Remove Signature',
  RemoveYellowLining: 'Remove Yellow Lining',
  RemovingWarning: '<i>Action Items</i>, <i>Comments</i> and <i>Images</i> will be <u>Removed</u> from this question',
  Reopen: 'Reopen',
  RequestAccess: 'Request Access',
  RequestTranslation: 'Request Translation',
  RequestedBy: 'Requested By',
  RequestedOn: 'Requested On',
  Requestedby: 'Requested by',
  RequireActionItemDueDate: 'Require Action Item Due Date',
  RequireFinalApproverBeforeInitiation: 'Require Final Approver Before Initiation',
  RequiredField: 'This field is required.',
  RequiresApprovalForAccess: 'Requires Approval For Access',
  RequiresShowProject: '*Requires Show Project to be enabled.',
  ResetABChecklistsforEntireProject: 'Reset AB Checklists for Entire Project',
  ResetABChecklist: 'Reset AB Checklist',
  ResetData: 'Reset Data',
  ResetSession: 'Reset Session',
  ResolutionImages: ' Resolution Images',
  RestorePssr: 'Restore {{PssrTitle}}',
  RestrictApproverSignatureOnLeadsDevice: 'Restrict Approver Signature On Leads Device',
  ReturnTeamLead: 'Return to Team Lead',
  ReviewInProgress: 'Review In Progress',
  Role: 'Role',
  RolesChangeWarning: 'Roles and changes will be reflected after Sync',
  Russian: 'Russian',
  RussianTranslationRequested: 'Russian Translation Requested',
  SAVESIGNATURE: 'SAVE SIGNATURE',
  Save: 'Save',
  SaveChanges: 'Save Changes',
  SaveGoBack: 'Save & Go Back',
  SaveImage: 'Save Image',
  SaveSignature: 'Save Signature',
  SavingFile: 'Saving File',
  SavingImage: 'Saving image',
  ScheduledEmail: 'Trigger Scheduled Email (Every Monday)',
  Screen: 'Screen',
  SearchMOCNumber: 'Search MOC Number',
  SecondaryCategory: 'Secondary Category',
  SectionType: 'Section Type',
  SectionLead: 'Section Lead',
  Sections: 'Sections',
  ShowSectionsCollapsedInstruction: '*If checked PSSR sections will be displayed collapsed when editing',
  Select: 'Select',
  SelectFileError: 'Select File Error',
  SelectFromKMSMOCList: 'Select From KMS MOC List',
  SelectLead: 'Select Lead',
  SelectOption: 'Select Option',
  SelectParticipant: 'Select Participant',
  SelectResponse: 'Select Response',
  SelectaDrawing: 'Select a Drawing',
  SelectfromQAQC: 'Select from QAQC',
  Selectpages: 'Select pages',
  Selectthepagestoconverttoimages: 'Select the pages to convert to images',
  Selectuserfor: 'Select user for',
  SelectUser: 'Select a user',
  Send: 'Send',
  SendAllNotificationsToApprovers: 'Approvers receive all pssr notifications',
  SendEmailReminder: 'Send Email Reminder',
  SendMOCforStartup: 'Send to MOC record for startup approval',
  SerialNumber: 'Serial Number',
  SerialNumberRequired: 'Serial Number Required',
  Settings: 'Settings',
  Shared: 'Shared',
  Shipment: 'Shipment',
  ShouldAddCommentToQuestion: 'Require Comment for NA Question',
  Show: 'Show',
  ShowCCC: 'Show CCC',
  ShowDeletedPSSR: 'Show Deleted {{PssrTitle}}',
  ShowKMS: 'Show KMS',
  ShowORR: 'Show ORR',
  ShowP5: 'Show P5',
  ShowProject: 'Show Project',
  ShowProjectInput: 'Show Project Input',
  ShowProjectInputAsDropdown: 'Show Project Input As Dropdown',
  ShowPssrType: 'Show PSSR Type',
  ShowQAQC: 'Show QAQC',
  ShowSectionsCollapsed: 'Show Sections Collapsed',
  ShowVerbalApproval: 'Show Verbal Approval',
  SignatoryName: 'Signatory Name',
  Signature: 'Signature',
  SignatureCapture: 'Signature Capture',
  Signatures: 'Signatures',
  SignedBy: 'Signed By',
  SignedOnBehalfOf: 'Signed On Behalf Of',
  Skidded: 'Skidded',
  SortBy: 'Sort By',
  SortChangesApplied: 'Sort changes will be applied after hit Save button ',
  SortOrder: 'EAM Sort Order',
  Startaddingparticipants: 'Start adding participants',
  Started: 'Started',
  Status: 'Status',
  StatusIsRequired: 'Status is required',
  SubArea: 'SubArea',
  SubCategories: 'Sub Categories',
  SubSection: 'Sub Section',
  Subcategories: 'Subcategories',
  Submit: 'Submit',
  SubmitforApproval: 'Submit For Approval',
  Submitforapproval: 'Submit For Approval',
  SubsectionDescription: 'Subsection Description',
  Summary: 'Summary',
  SummarySignature: 'Summary Signature',
  Sync: 'Sync',
  SystemAdministrators: 'System Administrators',
  TEAMMEMBERS: 'TEAM MEMBERS',
  Tag: 'Tag',
  TagCode: 'Tag Code',
  TagNo: 'Tag No',
  Takeaphoto: 'Take a photo',
  Tank: 'Tank',
  TaskPlanSections: 'Task Plan Sections',
  TaskPlan: 'Task Plan',
  TaskPlanNoteHeader: '[Note]&nbsp;Code name need to be unique, and it cannot be renamed after Task Plan is created',
  TeamLead: 'Team Lead',
  Template: 'Template',
  Test: 'Test',
  TestforDeleteDBwithoutApprover: 'Test for Delete DB without Approver',
  TheTaskPlanfieldisrequired: 'The Task Plan field is required',
  Thereareexistingitemsusingit: 'There are existing items using it',
  Therewasanerrorprocessingtryagain: 'There was an error processing your request. Please try again later or contact helpdesk.',
  Thisfieldisrequired: 'This field is required.',
  Thisisnotareversiblechange: 'This is not a reversible change',
  TimeCaptured: 'Time Captured',
  Title: 'Title',
  Titleisrequired: 'Title is required',
  TotalQuestions: 'Total Questions',
  TranslationCompleted: 'Translation Completed',
  TranslationHistory: 'Translation History',
  TranslationInProgress: 'Translation In Progress',
  TranslationRequested: 'Translation Requested',
  Translator: 'Translator',
  Type: 'Type',
  TypeOtherisonlyfornon: 'Type "Other" is only for non-Chevron users, use of this type may cause duplicate user data',
  Undo: 'Undo',
  UndoImageError: 'Undo Image Error',
  Unit: 'Unit',
  Update: 'Update',
  UpdatedBy: 'Updated By',
  UpdatedByUpdatedDate: 'Updated By/ Updated Date',
  UpdatedDate: 'Updated Date',
  Upload: 'Upload',
  UploadDocError: 'Upload Document Error',
  UploadDocErrorMsg: 'It was not possible save document',
  UploadDocument: 'Upload Document',
  UploadDocuments: 'Upload Documents',
  UploadImages: 'Upload Images',
  UploadMsg: 'New document was saved',
  Uploadaphoto: 'Upload a photo',
  User: 'User',
  User1: 'User 1',
  User2: 'User 2',
  User3: 'User 3',
  User4: 'User 4',
  UserAlreadyAdded: 'User Already Added',
  UserType: 'User Type',
  Users: 'Users',
  Usersavedsuccessfully: 'User saved successfully',
  Vendor: 'Vendor',
  VerbalApproval: 'Verbal Approval',
  VerbalApprovalReceivedBy: 'Verbal Approval Received By',
  VerbalApprovalforStartupOn: 'Verbal Approval for Startup On',
  Version: 'Version',
  ViewImage: 'View Image',
  ViewItems: 'View Items',
  WO: 'WO',
  WTNotPresentParticipantsWarning: 'The following participants were not selected as present',
  WTParticipantsWithoutSignatureWarning: 'The following participants does\'t have signature',
  WTRestrictParticipantsModificationWarning: 'After Complete Walkdown participants wont be modifiable',
  Walkdown: 'Walkdown',
  WalkdownSignature: 'Walkdown Signature',
  WalkdownComplete: 'Walkdown complete',
  WalkdownCompletedOn: 'Walkdown Completed On',
  Warning: 'Warning',
  WebLink: 'Web Link',
  WorkOrder: 'QAQC Work Order',
  WorkOrders: 'QAQC Work Orders',
  WorkOrdersNoavailable: 'No available milestones/workorders from QAQC',
  XCoordinate: 'X Coordinate',
  YCoordinate: 'Y Coordinate',
  Yes: 'Yes',
  Youareabouttologout: 'You are about to log out, this will remove local data on this device, do you want to proceed?',
  Youareabouttorequestaccessfor: 'You are about to request access for <strong>{{Name}}</strong>, you want to proceed?',
  Youareloggedinas: 'You are logged in as ',
  Youdonothaveaccesstothispage: 'You do not have access to this page',
  Yourprofile: 'Your profile',
  approvethisPSSRreadyforstartup: 'approve this PSSR ready for startup',
  cannotberemoved: 'can not be removed',
  cost255: 'Cost Code (max 255 characters)',
  desc5000: 'Add description here (max 5000 characters)',
  description1920: 'Add description here (max 1920 characters)',
  description500: 'Add description here (max 500 characters)',
  device150: 'Device Type Name (max 150 characters)',
  donothaveaccesstothePSSRapplication: 'do not have access to the PSSR application',
  eam50: 'EAM Type (max 50 characters)',
  eamclass80: 'EAM Class (max 80 characters)',
  eamcritical50: 'EAM Criticality (max 50 characters)',
  eamdep255: 'EAM Department (max 255 characters)',
  eamdesc80: 'EAM Description (max 80 characters)',
  eamname80: 'EAM Name (max 80 characters)',
  fromTaskPlan: 'from Task Plan',
  fromTaskPlanSection: 'from Task Plan Section',
  group155: 'Group Name (max 155 characters)',
  isrequired: 'is required',
  list: 'list',
  manufacturer250: 'Manufacturer Name (max 250 characters)',
  model250: 'Model (max 250 characters)',
  noticetousers: 'Notice to users',
  of10: 'of 10',
  of150: 'of 150',
  of155: 'of 155',
  of250: 'of 250',
  of255: 'of 255',
  of50: 'of 50',
  of500: 'of 500',
  of80: 'of 80',
  optional: 'optional',
  select1milestone: 'Please select 1 milestone to view work orders within that milestone',
  tagcode10: 'Tag Code (max 10 characters)',
  tagno10: 'Tag No. (max 10 characters)',
  typeemail: 'type new user email...',
  typelastname: 'type new user last name...',
  typenewuser: 'type new user name...',
  typeusercainame: 'type chevron user cai, name or email...',
  willberemoved: 'will be removed',
  willbeungrouped: 'If exist some Equipments joined to this Group, these will be ungrouped',
  x255: 'X Coordinate (max 255 characters)',
  y255: 'Y Coordinate (max 255 characters)',
  yourAccessRequestisinProgress: 'your Access Request is in Progress',
  youwanttoproceed: 'you want to proceed',
  Unabletocompleteoperation: 'Unable to complete operation',
  SaveCompleted: 'Save Completed',
  Itemhasbeenaddedsuccessfully: 'Item has been added successfully',
  ShowProjectInputInstruction: '*Displays project dropdown/input',
  ShowQAQCInstruction: '*Enables QAQC Module at project module. Requires Show Project to be enabled first.',
  ShowKMSInstruction: '*Displays "Pull from KMS" button when you are creating a new PSSR',
  AllowWetSignatureInstruction: '*Allows Operations Representative to approve the PSSR at "Pending approval" status. Displays button "Approve by wet signature"',
  ShowVerbalApprovalInstruction: '*Display an input in Mobile App to manually write who is approving. This doesn\'t cause any action.Can be performed by Lead or Approver',
  ScheduledEmailInstruction: '*Each Monday at 8:00 am CST an email is triggered for all the users with pending action items',
  ShowCCCInstruction: '*Displays "CCC" at some headers/titles/labels (Construction Complete Certificate)',
  ShowORRInstruction: '*Displays "ORR" at some headers/titles/labels (Operation Readines Review)',
  ShowPssrTypeInstruction: '*Displays "PSSR Types" dropdown (below)',
  AllowNAResponsesInstruction: '*If Disabled: a question at PSSR that is answered as NA would require a comment or action item to be considered as completed.',
  PssrInstructionsInstruction: '*Displays general instructions for the PSSR',
  AllowLeadsDeleteQuestionsInstruction: '*Allows Leads to delete questions for a PSSR on status Draft or Initiated.',
  EnableAllowMembersCompletePSSRInstruction: '*If checked, allows any team member to answer questions, sign questions, and create action items up until it is approved',
  MOCWOProjectNumberRestrictionEnabledInstruction: '*If Enabled: MOC and WO are required to create a PSSR',
  AllowMultiApproverInstruction: '*If Enabled: PSSR can have more than one Approver',
  RestrictApproverSignatureOnLeadsDeviceInstruction: '*If Enabled: PSSR Lead cannot sign in behalf of the Approver in Mobile App',
  AllowLeadtoShareDeviceWithApprover: 'Allow Lead to Share Device With Approver',
  AllowLeadtoShareDeviceWithApproverInstruction: '*If Enabled: The Lead can share the device with the Approver if the Approver is present to sign.',
  RequiresApprovalForAccessInstruction: '*If this flag is Enabled the user will wait until BU Admin grants access. If disabled, user will get instant access by selecting this BU',
  AllowApproverToSubmitForApprovalInstruction: '*If Checked Final Approval will be able to see Submit for Approval button in PSSR',
  OrganizationCannotBeEmpty: '{{OrganizationLabel}} cannot be empty',
  OrganizationSuccessfullyAdded: '{{OrganizationLabel}} Successfully added',
  MOCImportedSuccessfully: 'MOC Imported Successfully',
  GeneralInformationSavedSuccessfully: 'General Information Saved Successfully',
  Therecordenteredalreadyexists: 'The record entered already exists',
  SelectuserforTranslationRole: 'Select user for Translation Role',
  TranslatetoLanguage: 'Translate to Language',
  Selecttranslator: 'Select translator',
  PresentParticipantsInstruction: 'Participants in<span class="box participated">&nbsp;Blue&nbsp;</span>participated in Walkdown',
  PssrParticipantAddedSuccessMessage: 'User added to PSSR participants successfully',
  RoleAlreadyAddedWarningMessage: '{{RoleLabel}} Role is already added',
  MaxParticipantsPerBatchInstruction: 'Only 5 users can be added at a time',
  MaxParticipantsPerBatchWarningMessage: '5 user limit reached, please select add',
  ParticipantAlreadyParticipatedWarningMessage: 'This user is already a member of this pssr',
  ManageSectionUsersSyncWarning: 'This section is not yet visible on mobile until user syncs. Please notify participants to sync their mobile devices before continuing with their activities.',
  NotFoundComponentTitle: 'Not found',
  NotFoundComponentDescription: 'Please correct page address or contact support if you believe this url is correct',
  UnauthorizedTitle: 'Not authorized',
  UnauthorizedDescription: 'You do not have access to this page.',
  BUisrequired: 'BU is required',
  SectionLeadWasAlreadyAddedWarningMessage: '{{UserName}} is already a lead of this section',
  SectionLeadAddedErrorMessage: 'Error happened while adding user',
  SectionLeadRemovedErrorMessage: 'Error happened while removing user',
  AddResponseErrorMessage: 'Error happened while creating response question',
  ConfirmRemoveSectionTitle: 'Remove Section',
  ConfirmRemoveSectionDescription: 'Are you sure that you want to perform this action?',
  MilestoneWorkOrdersWarningMessage: 'Please select 1 milestone to view work orders within that milestone',
  QAQCAddSectionLeadToPssr: 'Assign section leads',
  LoadBusinessUnitsErrorMessage: 'Error getting Business Units',
  LoadOrganizationsErrorMessage: 'Error getting Organizations',
  AddProjectErrorMessage: 'Error adding new {{EventLabel}}',
  AddProjectSuccessMessage: 'Project added successfully',
  LoadQAQCErrorMessage: 'Error getting {{EventLabel}} from QAQC',
  SaveQAQCAlreadyExistsWarningMessage: '{{EventLabel}} already exists',
  SaveQAQCSuccessMessage: '{{EventLabel}} added successfully',
  CompletedTotalPSSRs: 'Completed/Total PSSRs',
  ProjectUpdatedSucessfully: 'Project updated successfully',
  ConfirmDeleteProjectTitle: 'Confirm Project Deletion',
  ConfirmDeleteProjectWithPssrsDescription: 'The following action could delete the Project and the PSSRs involved. Would you like to continue?',
  ConfirmDeleteProjectWithoutPssrsDescription: 'Would you like to proceed?',
  ConfirmInitiateProjectPssrsTitle: 'Do you want to initiate All PSSRs?',
  ConfirmInitiateProjectPssrsDescription: 'Do you want to initiate All PSSRs?',
  UnableLoadQAQCMilestonesMessage: 'Unable to reach qaqc system for milestones, please try again later',
  UnableLoadQAQCWorkOrdersMessage: 'Unable to reach qaqc system for workorders, please try again later',
  LoadProjectDetailsErrorMessage: 'Error loading project details',
  QAQCItemsAreUpToDateMessage: 'QAQC Items are up to date',
  QAQCNewItemInputWarning: 'Please select an item from the {{qaqcLabel}} list and select the hierarchy values and/or template',
  ConfirmCloseProjectTitle: 'Close Project',
  ConfirmCloseProjectDescription: 'Do you want to close this project?',
  DownloadActionItemsExcelReportFailureMessage: 'Failed to download the excel file',
  ConfirmDeleteAreaTitle: 'Are you sure you want to delete {{hierarchyLabel1}}',
  ConfirmDeleteAreaDescription: 'All the related {{ hierarchyLabel2 }} & {{ hierarchyLabel3 }} will be removed.',
  ConfirmDeleteSubAreaTitle: 'Are you sure you want to delete {{ hierarchyLabel2 }}',
  ConfirmDeleteSubAreaDescription: 'All the related {{ hierarchyLabel3 }} will be removed',
  NewGroupadded: 'New Group added',
  RequiredFields: 'Required Fields',
  AddedUserSuccessfulMessage: 'User Successfully added',
  DeletedUserSuccessfulMessage: 'User Successfully deleted',
  EditedGroupSucessfulMessage: 'Group successfully updated',
  Userisrequired: 'User is required',
  FirstNameisrequired: 'First Name is required',
  SharedUserNameisrequired: 'Shared User Name is required',
  Emailisrequired: 'Email is required',
  User1isrequired: 'User 1 is required',
  Usertypeiswrong: 'User type is wrong',
  RepeatedEmailsInputWarning: 'These emails are repeated: [{{repeatedEmails}}}]<br/>',
  Shareduserfound: 'Shared user found',
  AddRemoveSubcategory: 'Add/Remove Subcategory',
  CreateNewCategory: 'Create New Category',
  IsActive: 'Is Active',
  EditCategory: 'Edit Category',
  CommentisRequired: 'Comment is Required<br>',
  RejectreasonisRequired: 'Reject reason is Required<br>',
  Duedateisrequired: 'Due date is required',
  IAgree: 'I Agree',
  All: 'All',
  CreateFeedback: 'Create Feedback',
  Enhancement: 'Enhancement',
  Environment: 'Environment',
  Web: 'Web',
  MobileWeb: 'Mobile & Web',
  Mobile: 'Mobile',
  High: 'High',
  Medium: 'Medium',
  Low: 'Low',
  FeedbackSuccessfullyadded: 'Feedback Successfully added',
  EditFeedback: 'Edit Feedback',
  Typetoaddanewdiscussioncomment: 'Type to add a new discussion comment',
  Attachments: 'Attachments',
  ADOStatus: 'ADO Status',
  FeedbackUpdated: 'Feedback Updated',
  UpdatedFeedbacksAttachmentErrorMessage: 'An error happened with the files. Please review your files are not corrupt or too big',
  LoadingKMSItemsErrorMessage: 'An error occurred when searching KMS items',
  TaskPlanManageTitle: 'Manage Content of {{ Description }}',
  CreateNewSection: 'Create New Section',
  NewSubsection: 'New Subsection',
  ItemType: 'Item Type',
  ConfirmDeleteTaskPlanSectionMessage: 'Are you sure you want to delete\n    <span style="color: red">{{ taskPlanSectionDesc }}</span> <br />\n    from Task Plan {{ taskPlanDesc }} ?',
  ConfirmDeleteTaskPlanSubSectionMessage: 'Are you sure you want to delete <span style="color:red">{{subSection}}</span> <br> from Task Plan Section <b>{{section}}</b> ?',
  EditTaskPlanSubSection: 'Edit Task Plan Sub Section',
  RequestforComments: 'Request for Comments',
  RequestforActionItems: 'Request for Action Items',
  PSSRInitializedSuccessfully: 'PSSR Initialized Successfully',
  PrimeNgCalendarLabels: {
    firstDayOfWeek: 0,
    dayNames: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'mm/dd/yy',
    weekHeader: 'Wk',
  },
  Adminmenu: 'Admin menu',
  Organizations: 'Organizations',
  UserGuide: 'User Guide',
  Administration: 'Administration',
  ProjectSpecific: 'Project Specific',
  Showdeletedprojects: 'Show deleted projects',
  ProjectManagers: 'Project Managers',
  ProjectTeamMembers: 'Project Team Members',
  Activate: 'Activate',
  AddActionItem: 'Add Action Item',

  Attention: 'Attention',
  BacktoCategoryList: 'Back to Category List',
  Category: 'Category',
  CompleteTranslationWarning: 'Please translate PSSR Title, Description, Action Item Title and Action Item Comments',
  ConfirmTranslationRequest: 'You are about to request a translation in {{language}} to {{email}}',
  DeselectAll: 'Deselect All',
  FirstName: 'First Name',
  Itemsselected: 'Items selected',
  Language: 'Language',
  LastName: 'Last Name',
  'MOC#': 'MOC #',

  New: 'New',

  Org: 'Org',

  ShowDeletedPSSRCCC: 'Show Deleted PSSR / CCC',
  ShowDeletedPSSRCCCORR: 'Show Deleted PSSR / CCC / ORR',
  ShowDeletedPSSRORR: 'Show Deleted PSSR / ORR',

  Togglenavigation: 'Toggle navigation',

  Youshouldnothaveemptysections: 'You should not have empty sections',
  togglesearch: 'toggle search',

  GenericErrorTitle: 'Internal Error',
  GenericErrorDescription: 'There was an error processing your request. Please try again later or contact helpdesk.',
  Kazakh: 'Kazakh',
  PSSRmusthaveaFinalApprovertocompleteWalkdown: 'PSSR must have a Final Approver to complete Walkdown',
  ParticipatedinWalkthrough: 'Participated in Walkthrough',
  Subsection: 'Sub section',
  AccessUntilApprove: 'Access Until Approve',
  AllNotifications: 'All Notifications',
  AreyousureyouwanttoSubCategory: 'Are you sure you want to Sub Category',
  DueDateMandatory: 'Due Date Mandatory',
  FinalApproverSubmit: 'Final Approver Submit',
  FinalApproverInitiate: 'Final Approver Initiate',
  SectionsCollapsed: 'Sections Collapsed',
  Portuguese: 'Portuguese',
  Dutch: 'Dutch',
  SysAdmin: 'Sys Admin',
  ChevronDisclaimer: `This Chevron software product is for use by authorized users only. To the extent permitted by law, by
  using this software product you acknowledge and consent to the monitoring, access (including the
  decryption and inspection of selected encrypted internet
  traffic), use or disclosure by Chevron of any information generated, received or stored on this
  system/device and waive any right or expectation of privacy in connection with your use. Unauthorized
  and/or improper use of this software
  product in violation of Chevron corporate policies will be not tolerated and may result in disciplinary
  action, including termination.`,
  ConfirmDeletePSSRTitle: 'Confirm Delete',
  ConfirmDeletePSSRMessage: 'PSSR will be permanently removed, continue?<br/>',
  ConfirmNewTrainingTitle: 'Confirm new PSSR Training',
  ConfirmNewTrainingMessage: 'New Training PSSRs will be created.<br/> Do you want to continue?<br/>',
  ConfirmDeleteActionItemMessage: 'Are you sure that you want to perform this action?',
  CannotCloseProjectDueOpenPSSRs: 'Cannot close the project because there are open PSSRs',
  PSSRIdisrequired: 'PSSR Id is required',
  ImportFromisrequired: 'Import From is required.',
  MOCIDisalreadypresentintheImportlist: 'MOC ID is already present in the Import list.',
  ErrorhappenedwhileimportingPSSR: 'Error happened while importing PSSR',
  RestoreProject: 'Restore Project',
  Spanish: 'Spanish',
  ImportPSSRs: 'Import PSSRs',
  Import: 'Import',
  Application: 'Application',
  Records: 'Records',
  Imported: 'Imported',
  Errors: 'Errors',
  ImportAll: 'Import All',
  QAQCImport: 'QAQC Import',
  Start: 'Start PSSR',
  ePSSRServiceNowTicketingInstructions: 'ePSSR Service Now Ticketing Instructions',
  ReportanIssue: 'Report an Issue',
  EnhancementRequest: 'Enhancement Request',
  UserAccessRequest: 'User Access Request',
  ApplicationSupportRequestForm: 'Go to the <a href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">Application Support Request Form</a> in the Chevron IT Service Portal',
  UndertheApplicationFieldselectMobilePSSR: 'Under the Application Field select <b>Mobile PSSR</b>.',
  ToreportanissueorbugpleaseselectReportanIssueTosubmitanenhancementrequestoruseraccessrequestpleaseselectServiceRequestHitNext: 'To report an issue or bug please select <b>Report an Issue</b>. To submit an enhancement request or user access request please select <b>Service Request</b>. Hit Next.',
  FillouttheDescriptionoftheProblemImpactandUrgency: 'Fill out the Description of the Problem, Impact and Urgency.',
  PressNextandthenSubmit: 'Press Next and then Submit.',
  TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours: 'The ePSSR Support Team will acknowledge the receipt of the ticket in 24 hours during business hours.',
  PleaseselectEnhancementsunderTypeofRequests: 'Please select <b>Enhancements</b> under Type of Requests.',
  SelectPriorityLowModerateHigh: 'Select Priority <b>(Low, Moderate, High)</b>',
  FillouttheDescriptionofRequest: 'Fill out the Description of Request',
  SelectNextandSubmit: 'Select Next and Submit.',
  TheePSSRSupportTeamwillreviewenhancementrequestsaspartoftheSurfaceDigitalPIPlanningProcess: 'The ePSSR Support Team will review enhancement requests as part of the Surface Digital PI Planning Process',
  PleaseselectRequestAccessunderTypeofRequests: 'Please select <b>Request Access</b> under Type of Requests.',
  SelectAccessRequestTypeAddorRemove: 'Select Access Request Type <b>(Add or Remove)</b>',
  SpecifyRoleandAccesstheUserneeds: 'Specify Role and Access the User needs.',
  UserAlreadyPartipatedInPssr: 'Participant ["{{newTranslator}}"] has already participated in pssr',
  TranslateActionItem: 'Translate Action Item',
  AbandonedPSSRInterval: 'Stagnant PSSR Interval',
  ShowOnlyDeletedPSSRs: 'Show Only Deleted PSSRs',
  ShowOnlyAbandonedPSSRs: 'Show Only Stagnant PSSRs',
  CloseConfirmation: 'Remember, by closing the PSSR, you confirm that all action items have been closed. Close PSSR?',
  ReimportAllSharedUsers: 'Re-import All Shared Users',
  ImportSharedUsersFailed: 'Import failed',
  ImportSharedUsersIncompleted: 'Import incomplete',
  ImportSharedUsersCompleted: 'Import complete in: {{ minutes }}mins',

  ConfirmReimportAllSharedUsersHeader: 'Re-import All Shared Users',
  ConfirmReimportAllSharedUsersMessage: 'You are about to update all shared users configuration.<br> This operation could take several minutes to complete.',
  'SendaremindertoAssignees': 'Send a reminder to Assignees',
  SendReminderInstructions: 'Send reminder to assignees for all opened AIs.\nWhen PSSR is in Pending Approval status, \nApprovers will receive notification.',
  'Sendamessage': 'Send a message',
  'SendanemailmessagetoallPSSRParticipants': 'Send an email message to all PSSR Participants',
  ImportSharedUserOptionWhitePagesTitle: 'White Pages Integration',
  ImportSharedUserOptionWhitePagesDescription: 'Use this type of shared email if you need to sync with White Pages. The list of users will be updated automatically over time. Some users may be automatically added or removed from this shared email.',
  ImportSharedUserOptionCustomTitle: 'Custom Shared Email',
  ImportSharedUserOptionCustomDescription: 'Manually manage the list of users.',
  ImportActionItemsSuccessfully: 'Imported Action Items successfully',
  ImportActionItemsNone: 'No Action Items to import',
};
