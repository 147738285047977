import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '#services/shared';
import { B2CService } from '#services/api/b2c.service';
import { B2C, B2CResponse } from '#models/b2c';
import { Utility } from '#services/shared/utility';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-b2c',
  templateUrl: './b2c.component.html',
})
export class B2CLoginComponent implements OnInit {
  b2c: B2C;

  constructor(
    private router: Router,
    private authService: AuthService,
    private b2cService: B2CService,

    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.router.navigate(['']);
    }
    this.b2c = new B2C();
  }

  async loginB2C() {
    await this.b2cService
      .login(this.b2c)
      .toPromise()
      .then(
        async (data: B2CResponse) => {
          if (data.error) {

            this.toastrService.showError(data.error_description);
          } else {
            data.username = this.b2c.username;
            this.authService.setB2CToken(data);
            const loggedUser = await this.authService.loadUserInfo();
            if (Utility.isValidObj(loggedUser) && loggedUser.ID > 0) {
              window.location.href = '/';
              return;
            } else {
              this.b2c.password = null;
              this.authService.logoutB2C();
              this.toastrService.showError('You don\'t have access. Ask your BU admin for access.');
            }
          }
        },
        (error) => {
          this.authService.logoutB2C();
          this.toastrService.showError(error);
        }
      );
  }
}
