import { Component, OnInit } from '@angular/core';
import { Equipment } from '#models/equipment';
import { EquipmentService } from '#services/api/equipment.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EquipmentTemplateService } from '../../../services/api/equipment-template.service';

@Component({
  selector: 'app-end-device',
  templateUrl: './end-device.component.html',
  styleUrls: ['./end-device.component.scss'],
  providers: [EquipmentService],
})
export class EndDeviceComponent implements OnInit {
  equipment: Equipment;
  equipmentId: number;
  isTemplate: boolean;
  public buId: number;

  constructor(
    public svcEquipment: EquipmentService,
    private route: ActivatedRoute,
    private equipTempService: EquipmentTemplateService,

    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((p) => {
      (this.equipmentId = +p['itemId']),
        (this.buId = +p['parentId']),
        (this.isTemplate = p['isTemplate'] === 'true');
    });

    this.loadEquipment();
  }

  private async loadEquipment() {

    if (this.isTemplate) {
      this.equipTempService
        .getEquipmentTemplateById(this.equipmentId)

        .subscribe((equipment) => {
          this.equipment = equipment;
        });
    } else {
      this.svcEquipment
        .getEquipmentById(this.equipmentId)

        .subscribe((data: Equipment) => {
          this.equipment = data;
        });
    }
  }

  backEquipmentList() {
    this.router.navigateByUrl('admin/equipment');
  }
}
