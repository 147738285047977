export class ProjectUser {
    Id: number;
    UserID: number;
    ProjectID: number;
    UserName: string;
    UserCAI: string;
    UserEmail: string;
    Role: number;
    RoleName: string;
    CanEdit: boolean;
    SignatureUrl: string;
    SignatureGUID: string;
    SignatureDate: Date;
    SignatureImage: string;
    IsOrgAdmin: boolean;
    IsBusinessUnitAdmin: boolean;
}
