<div class="box-shadow module" [appBUSwitcher]="selectedBU">

  <h2>
    <span *ngIf="endDeviceTypeId > 0; else altPrefix">Edit</span
    ><ng-template #altPrefix>Add</ng-template> Device Type
  </h2>
  <hr />

  <!-- End Device Type -->
  <form (ngSubmit)="onSubmitEndDeviceType()" #endDeviceTypeForm="ngForm">
    <div class="form-horizontal">
      <div class="form-group">
        <label class="control-label col-md-2">Name</label>
        <div class="col-md-6">
          <input
            class="form-control text-box single-line"
            [(ngModel)]="endDeviceType.Name"
            name="ename"
            #ename="ngModel"
            maxlength="150"
            placeholder="Device Type Name (max 150 characters)"
            required
          />
          <div class="row">
            <span
              class="col-sm-8 text-danger field-validation-error"
              *ngIf="!ename?.valid"
            >
              <span>{{ "This field is required" | translate }}.</span>
            </span>
            <span class="col-sm-4 charSpan"
              >{{ endDeviceType.Name?.length }} of 150</span
            >
          </div>
        </div>
        <div class="col-md-2">
          <input
            style="padding-right: 0px; padding-left: 0px; text-align: center"
            type="checkbox"
            name="issernumreq"
            #issernumreq="ngModel"
            [(ngModel)]="endDeviceType.IsSerialNumReq"
            value="endDeviceType.IsSerialNumReq"
          />
          Serial Number Required
        </div>
        <div class="col-md-2">
          <input
            style="padding-right: 0px; padding-left: 0px; text-align: center"
            type="checkbox"
            name="isineam"
            #isineam="ngModel"
            [(ngModel)]="endDeviceType.IsInEAM"
            value="endDeviceType.IsInEAM"
          />
          Add to EAM
        </div>
      </div>

      <div *ngIf="endDeviceType.IsInEAM">
        <div class="form-group">
          <label class="control-label col-md-2">EAM Name</label>
          <div class="col-md-8">
            <input
              class="form-control text-box single-line"
              [(ngModel)]="endDeviceType.EAMName"
              name="eamname"
              #eamname="ngModel"
              maxlength="80"
              placeholder="EAM Name (max 80 characters)"
            />
            <div class="row">
              <span class="col-sm-4 charSpan"
                >{{ endDeviceType.EAMName?.length }} of 80</span
              >
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="control-label col-md-2">EAM Description</label>
          <div class="col-md-8">
            <input
              class="form-control text-box single-line"
              [(ngModel)]="endDeviceType.Description"
              name="description"
              #description="ngModel"
              maxlength="80"
              placeholder="EAM Description (max 80 characters)"
              required
            />
            <div class="row">
              <span
                class="col-sm-8 text-danger field-validation-error"
                *ngIf="!description?.valid"
              >
                <span>{{ "This field is required" | translate }}.</span>
              </span>
              <span class="col-sm-4 charSpan"
                >{{ endDeviceType.Description?.length }} of 80</span
              >
            </div>
          </div>
        </div>

        <div class="form-group">
          <label class="control-label col-md-2">EAM Class</label>
          <div class="col-md-8">
            <input
              class="form-control text-box single-line"
              [(ngModel)]="endDeviceType.EAMClass"
              name="eamclass"
              #eamclass="ngModel"
              maxlength="80"
              placeholder="EAM Class (max 80 characters)"
            />
            <div class="row">
              <span class="col-sm-4 charSpan"
                >{{ endDeviceType.EAMClass?.length }} of 80</span
              >
            </div>
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="col-md-offset-2 col-md-10">
          <button
            type="submit"
            class="btn btn-primary"
            [disabled]="!endDeviceTypeForm.form.valid"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </form>

  <!-- Manufacturer / Model -->
  <div class="table-padding" *ngIf="endDeviceTypeId > 0">
    <div class="form-horizontal component-separator">
      <h3>Manufacturer - Model for {{ endDeviceType.Name }}</h3>
      <div>
        <table class="table table-hover table-responsive component-separator">
          <tr>
            <th scope="col" class="col-xs-5">Manufacturer</th>
            <th scope="col" class="col-xs-5">Model</th>
            <th
              scope="col"
              class="col-xs-2"
              style="text-align: end; padding: 1px"
            >
              <button
                type="button"
                class="btn btn-success"
                (click)="addEditManufModel()"
                style="width: 80px"
              >
                New
              </button>
            </th>
          </tr>
          <tr *ngFor="let manufMod of manufModelList">
            <td class="col-xs-5">{{ manufMod.Manufacturer }}</td>
            <td class="col-xs-5">{{ manufMod.Model }}</td>
            <td class="col-xs-2" style="text-align: end; padding: 1px">
              <a (click)="addEditManufModel(manufMod)" style="cursor: pointer"
                ><strong>Edit</strong></a
              >
              <span>&nbsp;|&nbsp;</span>
              <a
                (click)="showModalEliminateManMod(manufMod)"
                style="cursor: pointer; color: red"
                data-toggle="modal"
                data-target="#modalDelete"
                ><strong>Delete</strong></a
              >
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="component-separator" *ngIf="showManufModPanel">
      <h3>
        <span *ngIf="manufModEdit">Edit</span
        ><span *ngIf="!manufModEdit">New</span> Manufacturer - Model for
        {{ endDeviceType.Name }}
      </h3>
      <form (ngSubmit)="onSubmitManufMod()" #manufModForm="ngForm">
        <div class="form-horizontal">
          <div class="form-group">
            <label class="control-label col-md-2">Manufacturer</label>
            <div class="col-md-8">
              <input
                class="form-control text-box single-line"
                [(ngModel)]="manufModel.Manufacturer"
                name="manufac"
                #manufac="ngModel"
                maxlength="250"
                placeholder="Manufacturer Name (max 250 characters)"
                required
              />
              <div class="row">
                <span
                  class="col-sm-8 text-danger field-validation-error"
                  *ngIf="!manufac.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
                <span class="col-sm-4 charSpan"
                  >{{ manufModel.Manufacturer?.length }} of 250</span
                >
              </div>
            </div>
          </div>

          <div class="form-group">
            <label class="control-label col-md-2">Model</label>
            <div class="col-md-8">
              <input
                class="form-control text-box single-line"
                [(ngModel)]="manufModel.Model"
                name="eModel"
                #eModel="ngModel"
                maxlength="250"
                placeholder="Model (max 250 characters)"
                required
              />
              <div class="row">
                <span
                  class="col-sm-8 text-danger field-validation-error"
                  *ngIf="!eModel.valid"
                >
                  <span>{{ "This field is required" | translate }}.</span>
                </span>
                <span class="col-sm-4 charSpan"
                  >{{ manufModel.Model?.length }} of 250</span
                >
              </div>
            </div>
          </div>

          <div class="row form-group">
            <div class="col-md-offset-2 col-md-10">
              <button
                type="submit"
                class="btn btn-primary"
                [disabled]="!manufModForm.form.valid"
              >
                <span *ngIf="manufModEdit">Save</span>
                <span *ngIf="!manufModEdit">Add</span> Manufacturer - Model
              </button>
              <button
                type="button"
                (click)="changeManufModPanelState()"
                class="col-md-offset-1 btn btn-secondary"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

    <!--Modal to Ask Delete Manufacturer / Model-->
    <div
      class="modal fade"
      id="modalDelete"
      tabindex="-1000"
      role="dialog"
      aria-labelledby="modalSaveChanges"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title" id="exampleModalCenterTitle">
              Delete Manufacturer - Model
            </h4>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <h3>Do you agree to Delete selected Manufacturer - Model&#63;</h3>
            <h4>{{ manufModel?.Manufacturer }} - {{ manufModel?.Model }}</h4>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-danger"
              (click)="deleteManufMod()"
              data-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="table-padding" *ngIf="endDeviceTypeId > 0">
    <br />
    <div class="form-horizontal component-separator">
      <h3>AB Checklist for {{ endDeviceType.Name }}</h3>
    </div>
    <br />
  </div>
  <hr />

  <!-- Back button -->
  <div>
    <button
      type="button"
      class="btn btn-default"
      (click)="router.navigate(['/admin/end-device-type/'])"
    >
      Back to List
    </button>
    &nbsp;
  </div>
</div>
