<form [formGroup]="form" class="form-user form-horizontal form-group" (change)="onChange()">
  <ng-container formArrayName="userList" *ngFor="let group of userListGroup.controls; let i = index;">
    <ng-container [formGroupName]="i">
      <ng-container [ngTemplateOutlet]="userTemplate"
                    [ngTemplateOutletContext]="{ form: group, index: i }">
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!disabled">
    <span class="col-md-2"></span>
    <span class="col-md-8">
      <button class="btn btn-link p-0" [disabled]="form.invalid" (click)="addNewUser()">{{"Add User"|translate}}</button>
    </span>
  </ng-container>
</form>

<ng-template #userTemplate let-form='form' let-index='index'>
  <ng-container [formGroup]="form">
    <div class="autocomplete-input">
      <label class="control-label col-md-2">
        {{ "User" | translate }} {{ index+1 }} 
        <b *ngIf="!disabled" class="error-text">&nbsp;*</b>
      </label>

      <div class="user-container col-md-8 ">
        <p-autoComplete
          class="single-line"
          delay="1000"
          formControlName="selectedValue"
          [field]="'FullName'"
          
          [suggestions]="usersSuggestions"
          (onSelect)="selectUser($event, index)" 
          (completeMethod)="getUsers($event)" 
          (onClear)="clearSelectedUser(index)" 
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }" 
          [minLength]="1"
          [placeholder]="'typeusercainame' | translate">
        </p-autoComplete>

        <span class="error-text" *ngIf="form.get('Email').invalid">          
          {{ "SelectUser" | translate }}
        </span>
      </div>
      
      <button *ngIf="!disabled" class="delete-btn" type="button" (click)="deleteUser(index)" title="{{'Delete'|translate}}">
        <img src="assets/images/cross_color.png" alt="Delete" class="delete-icon" />
      </button>
    </div>
  </ng-container>
</ng-template>