import { Component, OnInit } from '@angular/core';
import { BusinessUnitsService } from '#services/api';
import { BusinessUnit } from '#models/business-unit';
import { User } from '#models/user';
import { UserService } from '#services/api/user.service';
import { ConfirmationService } from 'primeng/api';
import { BusinessUnitUser } from '#models/business-unit-user';
import { Utility } from '#services/shared/utility';
import { UserType } from '#models/enum/user-type';
import { TranslatePipe } from '@ngx-translate/core';
import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-business-unit-user',
  templateUrl: './business-unit-user.component.html',
  providers: [TranslatePipe],
})
export class BusinessUnitUserComponent implements OnInit {
  UserType = UserType;
  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  buUsers: BusinessUnitUser[];
  user: BusinessUnitUser = new BusinessUnitUser();
  selectedUser: User;
  users: User[];

  isSysAdmin = true;

  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private toastrService: ToastrService,
    private translatePipe: TranslatePipe
  ) { }

  async ngOnInit() {
    this.user.Id = null;
    this.user.Type = UserType.Chevron;
    await this.loadBusinessUnits();
    await this.loadBUUsers();
  }

  async loadBusinessUnits() {
    await this.businessUnitsSvc
      .getUserAdminBUs()
      .toPromise()
      .then((data: BusinessUnit[]) => {
        this.lstBusinessUnit = data;
        if (this.lstBusinessUnit.length > 0) {
          this.selectedBU = this.lstBusinessUnit[0];
        }
      });
  }

  async loadBUUsers() {
    if (this.selectedBU) {
      await this.businessUnitsSvc
        .getBusinessUnitUsers(this.selectedBU.Id)
        .toPromise()
        .then((data: BusinessUnitUser[]) => {
          this.buUsers = data;
        });
    } else {
      this.buUsers = [];
    }
  }

  getUsers(event) {
    const query = event.query;
    this.userService.searchUser(query).subscribe((data) => {
      this.users = data;
      this.users.forEach(function (_obj, index) {
        this[index].FullName = Utility.formatUser(this[index]);
      }, this.users);
    });
  }

  async addUser() {
    this.user.ChildUsers = [];

    this.user.BusinessUnitId = Utility.isValidObj(this.selectedBU)
      ? this.selectedBU.Id
      : 0;
    this.user.CAI = null;
    this.user.HasAccess = true;

    if (this.user.Type === UserType.Chevron) {
      this.user.CAI = Utility.isValidObj(this.selectedUser)
        ? this.selectedUser.CAI
        : null;
      this.user.Email = this.getUserEmail(this.selectedUser);
    }

    if (!this.validateUserInput()) {
      await this.businessUnitsSvc
        .addBusinessUnitUser(this.user)

        .toPromise()
        .then(
          (data) => {
            this.buUsers.push(data);
            this.toastrService.showSuccess(this.translatePipe.transform('AddedUserSuccessfulMessage'));
            this.clear();
          },
          (error) => this.toastrService.showWarning(error.error.ExceptionMessage)
        );
    } else {
      this.toastrService.showWarning(this.validateUserInput());
    }
  }

  private getUserEmail(user: User): string {
    return Utility.isValidObj(user) ? user.Email : null;
  }

  removeUserWarning(buUsrId: number) {
    const message = this.translatePipe.transform(
      'Recordwillbepermanentlyremovedcontinue'
    );
    this.confirmationService.confirm({
      message: message,
      header: this.translatePipe.transform('Confirmdelete'),
      accept: () => {
        this.deleteUser(buUsrId);
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  async deleteUser(buUsrId: number) {
    await this.businessUnitsSvc
      .removeBusinessUnitUser(buUsrId)
      .toPromise()
      .then(
        () => {
          const userOrgIndex = this.buUsers.findIndex((x) => x.Id === buUsrId);
          this.buUsers.splice(userOrgIndex, 1);
        },
        (error) => {
          this.toastrService.showWarning(error.error.ExceptionMessage);
        }
      );
  }

  clear() {
    this.selectedUser = null;

    this.user.UserId = null;
    this.user.FirstName = null;
    this.user.MiddleName = null;
    this.user.LastName = null;
    this.user.Email = null;
    this.user.CAI = null;
    this.user.Passcode = null;
    this.user.IsLoginOnly = false;
    this.user.ChildUsers = [];
  }

  validateUserInput() {
    const errors = [];
    if (!Utility.isValidObj(this.selectedBU)) {
      errors.push(this.translatePipe.transform('BusinessUnitisrequired'));
    }
    if (
      this.user.Type === UserType.Chevron &&
      !Utility.isValidString(this.user.Email)
    ) {
      errors.push(this.translatePipe.transform('Userisrequired'));
    }
    if (
      this.user.Type === UserType.External &&
      !Utility.isValidString(this.user.FirstName)
    ) {
      errors.push(this.translatePipe.transform('First Name is required'));
    }

    return errors.join('<br>');
  }
}
