import { Component, OnInit } from '@angular/core';

import { MobileAppVersionService } from '#services/api/mobile-app-version.service';
import { MobileAppVersion } from '#models/mobile-app-version';
import { finalize, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { SelectItem } from 'primeng/api';
import {
  AppVersionStatusDesc,
  AppVersionStatus,
} from '#models/enum/system-version-status';

import { TranslatePipe } from '@ngx-translate/core';

import { ToastrService } from '#services/shared/toastr.service';

@Component({
  selector: 'app-mobile-app-version',
  templateUrl: './mobile-app-version.component.html',
  styleUrls: ['./mobile-app-version.component.scss'],
  providers: [TranslatePipe],
})
export class MobileAppVersionComponent implements OnInit {
  appVersions: Array<MobileAppVersion>;
  listAppVersionStatus: Array<SelectItem>;
  availabilityOptions: Array<SelectItem>;
  loadingCompleted: boolean;
  showDialog: boolean;
  private safeCopy: Array<MobileAppVersion>;
  get isEmpty() {
    return !this.appVersions || this.appVersions.length === 0;
  }

  constructor(
    private toastrService: ToastrService,
    private mobileAppVersionSvc: MobileAppVersionService,

    private translatePipe: TranslatePipe
  ) {}

  ngOnInit() {
    this.loadMobileAppVersions();
    this.loadDropdownOptions();
  }

  addVersion(newVersion: MobileAppVersion) {
    this.appVersions = [...this.appVersions, newVersion];
  }

  toggleDialog(show: boolean) {
    this.showDialog = show;
  }

  /**
   * Saves changes made to mobile app versions
   */
  saveChanges() {
    // todo: only update those who has been changed
    const observables = this.appVersions.map((appVersion) => {
      return this.mobileAppVersionSvc.update(appVersion);
    });
    forkJoin(observables)
      .pipe(
        tap(
          (_) => _,
          (err) => this.handleException(err)
        )
      )
      .subscribe(() => {
        this.toastrService.showSuccess( this.translatePipe.transform('Changes has been saved') );
      });
  }

  /**
   * Discard changes
   */
  discard() {
    // create a new object dettached from in-memory variable
    this.appVersions = JSON.parse(JSON.stringify(this.safeCopy));
  }

  /**
   * Loads list of mobile app versions
   */
  private loadMobileAppVersions() {
    this.loadingCompleted = false;

    const observable = this.mobileAppVersionSvc.getAppVersions().pipe(
      tap(
        (_) => _,
        (err) => this.handleException(err)
      ),
      finalize(() => {
        this.loadingCompleted = true;
      })
    );

    observable.subscribe((mobileAppVersions) => {
      this.appVersions = mobileAppVersions;
      this.safeCopy = JSON.parse(JSON.stringify(mobileAppVersions));
    });
  }

  /**
   * Loads all dropdown items
   */
  private loadDropdownOptions() {
    this.availabilityOptions = [
      { label: 'Yes', value: false },
      { label: 'No', value: true },
    ];

    // mapping enum object to an array
    this.listAppVersionStatus = Object.keys(AppVersionStatus)
      .filter((key) => typeof AppVersionStatus[key] === 'number')
      .map(
        (key) =>
          <SelectItem>{
            value: AppVersionStatus[key],
            label: AppVersionStatusDesc.map.get(AppVersionStatus[key]),
          }
      );
  }

  /**
   * Adds a toast notification with the error details
   * @param err
   */
  private handleException(err: any): void {

    this.toastrService.showSuccess( err.error.message, this.translatePipe.transform('Unable to complete operation'));
  }
}
