import { PSSRFile } from '#models/pssr-file';
import { PSSRService } from '#services/api';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FileResolver implements Resolve<PSSRFile | null> {
  constructor(private pssrService: PSSRService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<PSSRFile | null> {
    return this.pssrService
      .downloadPSSRFileByGuid(
        +route.paramMap.get('pssrId'),
        route.paramMap.get('fileId')
      )
      .pipe(catchError(() => of(null)));
  }
}
