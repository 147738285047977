import { NgModule } from '@angular/core';
import { BusinessUnitsService, FeedbackService } from './';
import { TaskPlanService } from '#services/api/task-plan.service';
import { SharedModule } from '#services/shared/shared.module';
import { UserService } from '#services/api/user.service';
import { PSSRService } from './pssr.service';
import { PSSRImportService } from './pssr-import.service';
import { EquipmentService } from './equipment.service';
import { EndDeviceService } from './end-device.service';
import { EquipmentTemplateService } from './equipment-template.service';
import { EndDeviceTemplateService } from './end-device-template.service';
import { ProjectService } from './project.service';
import { EndDeviceTypeService } from './end-device-type.service';
import { ManufacturerModelService } from './manufacturer-model.service';
import { GroupService } from './groups.service';
import { MobileAppVersionService } from './mobile-app-version.service';
import { B2CService } from './b2c.service';

@NgModule({
    declarations: [
    ],
    imports: [
        SharedModule
    ],
    providers: [
        BusinessUnitsService,
        TaskPlanService,
        FeedbackService,
        UserService,
        PSSRService,
        PSSRImportService,
        EquipmentService,
        EndDeviceService,
        EquipmentTemplateService,
        EndDeviceTemplateService,
        ProjectService,
        EndDeviceTypeService,
        ManufacturerModelService,
        GroupService,
        MobileAppVersionService,
        B2CService
    ]
})

export class ApiModule { }
