import * as Languages from '#services/translate';
import { Language } from '#services/translate/languages';
import { Injectable } from '@angular/core';
import { TranslateLoader } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LanguageLoader extends TranslateLoader {
  getTranslation(lang: Language): Observable<any> {
    const key = lang.toUpperCase().replace('-', '');

    if (!!Languages[key]) {
      return of(Languages[key]);
    }
    return of(Languages.EN);
  }
}
