import {
  Component,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  Validator,
  ControlValueAccessor,
  FormGroup,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { DialogService } from '#services/shared/dialog.service';
import { PSSRResponseService } from '#services/api';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ResponseItem } from '#models/response-item';
import { TranslatePipe } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
@Component({
  selector: 'app-comment-dialog',
  templateUrl: 'comment-dialog.component.html',
  providers: [
    TranslatePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentDialogComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CommentDialogComponent,
      multi: true,
    },
    DialogService,
  ],
})
export class CommentDialogComponent
  implements ControlValueAccessor, Validator, OnDestroy {
  @Input()
  set prompt(value) {
    if (value) {
      this.dialogService.open();
    } else {
      this.dialogService.close();
    }
  }
  get prompt() {
    return this.dialogService.isOpen;
  }

  constructor(
    private fb: FormBuilder,
    private responseSvc: PSSRResponseService,

    private dialogService: DialogService,
    private translatePipe: TranslatePipe,
    private confirmationSvc: ConfirmationService
  ) {
    this.form = this.fb.group({
      Action: null,
      ActionID: null,
      Status: 0,
      StatusName: 'None',
      Priority: 0,
      CreateDate: new Date(),
      ClosedBy: null,
      CreatedBy: null,
      UpdatedBy: null,
      ClosedOn: null,
      CanRejectItem: false,
      CanEditItem: false,
      CanReOpenItem: false,
      IsActionItem: false,
      ResponseID: 0,
      Id: 0,
      GUID: '',
      PSSRID: 0,
      OrganizationId: 0,
      Comment: ['', Validators.required],
      UpdatedDate: new Date(),
    });

    this.form.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((input) => {
        this.onChange(input);
        this.onTouched();
      });
  }

  get value() {
    return this.form.value;
  }

  set value(value) {
    this.form.patchValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get errors() {
    let errors = {};
    for (const key in this.form.controls) {
      if (this.form.controls.hasOwnProperty(key)) {
        if (this.form.controls[key].errors !== null) {
          errors = { ...errors, [key]: this.form.controls[key].errors };
        }
      }
    }
    return errors;
  }
  @Input() canDelete = true;
  disabled = false;
  form: FormGroup;

  private destroyed$ = new Subject<boolean>();

  errorMessage = '';

  @Output() saved = new EventEmitter();

  onChange = (input) => {
    // TODO implement
  }

  onTouched = () => {
    // TODO implement
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  validate() {
    if (this.form.valid) {
      return null;
    }
    return {
      dialog: this.errors,
    };
  }

  writeValue(value) {
    if (!value) {
      this.form.reset();
    } else {
      this.value = value;
    }
  }

  open() {
    this.prompt = true;
  }

  close() {
    this.prompt = false;
    this.errorMessage = '';
  }

  async saveComment() {
    if (this.validate() !== null) {
      this.errorMessage = this.getErrorMessage();
      setTimeout(() => {
        this.errorMessage = '';
      }, 5000);
      return;
    }

    try {

      this.disabled = true;

      const savedItem = await this.responseSvc
        .updateActionItem(this.value)
        .toPromise();
      this.disabled = false;
      this.close();
      this.saved.emit(savedItem);
    } catch ({ error: { ExceptionMessage } }) {
      this.disabled = false;
      this.errorMessage = ExceptionMessage;
    } finally {
      this.disabled = false;

    }
  }

  async deleteComment() {
    const header = this.translatePipe.transform('ConfirmDeleteHeader');
    const labelOk = this.translatePipe.transform('Confirm');
    const labelCancel = this.translatePipe.transform('Cancel');
    const message = this.translatePipe.transform('ConfirmDeleteMessage', {});
    this.confirmationSvc.confirm({
      header: header,
      acceptLabel: labelOk,
      rejectLabel: labelCancel,
      message: message,
      accept: () => {
        this.deleteCommentAccepted();
      },
      reject: () => {
        // TODO implement
      },
    });
  }
  async deleteCommentAccepted() {
    if (this.validate() !== null) {
      this.errorMessage = this.getErrorMessage();
      setTimeout(() => {
        this.errorMessage = '';
      }, 5000);
      return;
    }
    try {

      this.disabled = true;
      let deletedItem = new ResponseItem();
      deletedItem = this.value;
      await this.responseSvc.deleteResponseItem(this.value.Id).toPromise();
      this.disabled = false;
      deletedItem.IsDeleted = true;
      this.saved.emit(deletedItem);
      this.close();
    } catch ({ error: { ExceptionMessage } }) {
      this.disabled = false;
      this.errorMessage = ExceptionMessage;
    }
  }
  getErrorMessage() {
    let msg = '';
    if (this.form.controls['Comment'].invalid) {
      msg += `Comment is Required <br>`;
    }
    return msg;
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
