import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Project } from '../../../models/project';
import { Router } from '@angular/router';

@Component({
    selector: 'app-pssr-project-dashboard',
    templateUrl: './pssr-project-dashboard.component.html',
    styleUrls: ['./pssr-project-dashboard.component.scss']
})
export class PssrProjectDashboardComponent implements OnInit, OnChanges {

    //#region VARIABLES

    // Projects
    @Input() project: Project;
    @Output() outputProjectId = new EventEmitter();

    //  PSSR
    public pssrStatusList: any[];

    //#endregion

    constructor(private router: Router) {
    }

    ngOnInit() {
        this.pssrStatusList = [];
        this.statusListSummary();
    }

    ngOnChanges() {
        // TODO implement
    }

    ProjPSSR(projId) {
        this.outputProjectId.emit(projId);
        this.router.navigateByUrl(`pssr-project-detail/${projId}`);
    }

    statusListSummary() {

        this.project.StatusList.forEach(data => {
            if (!this.pssrStatusList.includes(data)) {
                this.pssrStatusList.push({ name: data.name, value: 1 });
            } else {
                const status = this.project.StatusList.find(x => x.name === data.name);
                const ind = this.project.StatusList.findIndex(status);
                status.value++;
                this.pssrStatusList.splice(ind, 1, status);
            }
        });



    }
}
