
export enum PssrTypeEnum {
  CCC = 1,
  Factory = 2,
  MOC = 3,
  NonMOC = 4,
  ORR = 5,
  PSSR = 6,
  Test = 7
}
