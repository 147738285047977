<div class="box-shadow module">
  <a #msgFocusId href=""></a>



  <div class="row">
    <h2 [innerHTML]="selectedBU?.PssrTitle | translate"></h2>
  </div>

  <!-- filters -->
  <div class="row">
    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ "BusinessUnit" | translate }}</label>
        <p-dropdown
          id="selectBU"
          [appBUSwitcher]="selectedBU"
          [options]="lstBUs"
          [(ngModel)]="selectedBU"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          (onChange)="changeBU()"
          optionLabel="Name"
          appendTo="body"
          (onClick)="loadBUs()"
          placeholder="{{ 'Select Option' | translate }}"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ (selectedBU.OrganizationLabel || 'Organization') | translate }}</label>
        <p-dropdown
          id="selectOrg"
          [options]="lstOrgs"
          [(ngModel)]="filter.Organization"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          (onChange)="changeOrg()"
          optionLabel="Name"
          appendTo="body"
          (onClick)="loadOrganizations()"
          [disabled]="!(selectedBU !== null && selectedBU?.Id &gt; 0)"
          placeholder="{{ 'Select Option' | translate }}"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ (selectedBU.HierarchyLabel1 || 'HierarchyLabel1') | translate }}</label>
        <p-dropdown
          id="selectArea"
          [options]="lstArea"
          [(ngModel)]="filter.Area"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          (onChange)="changeArea()"
          optionLabel="Name"
          appendTo="body"
          (onClick)="loadArea()"
          [disabled]="!(filter.Organization !== null && filter.Organization?.Id  &gt; 0)"
          placeholder="{{ 'Select Option' | translate }}"
        >
        </p-dropdown>
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ (selectedBU.HierarchyLabel2 || 'HierarchyLabel2') | translate }}</label>
        <p-dropdown
          id="selectSubArea"
          [options]="lstSubArea"
          [(ngModel)]="filter.SubArea"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          (onChange)="changeSubArea()"
          optionLabel="Name"
          appendTo="body"
          (onClick)="loadSubArea()"
          [disabled]="!(filter.Area && filter.Area?.Id  &gt; 0)"
          placeholder="{{ 'Select Option' | translate }}"
        >
        </p-dropdown>
      </div>
    </div>

    <div
      *ngIf="selectedBU.ShowHierarchyLabel3"
      class="col-xs-3"
    >
      <div class="form-group">
        <label>{{ (selectedBU.HierarchyLabel3 || 'HierarchyLabel3') | translate }}</label>
        <p-dropdown
          id="selectFacility"
          [options]="lstFacility"
          [(ngModel)]="filter.Facility"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          (onChange)="filterInfo()"
          optionLabel="Name"
          appendTo="body"
          (onClick)="loadFacility()"
          [disabled]="!(filter.Area && filter.Area?.Id  &gt; 0)"
          placeholder="{{ 'Select Option' | translate }}"
        >
        </p-dropdown>
      </div>
    </div>

    <div
      *ngIf="selectedBU.ShowProject"
      class="col-xs-3"
    >
      <div class="form-group">
        <label>{{ selectedBU.EventLabel || "Project" | translate }}</label>
        <ng-container *ngIf="!selectedBU?.ShowProjectInput">
          <p-dropdown
            id="selectProject"
            [options]="lstProject"
            [(ngModel)]="filter.Project"
            [styleClass]="'text-left'"
            [style]="{ width: '100%' }"
            (onChange)="filterInfo()"
            optionLabel="Name"
            appendTo="body"
            (onClick)="loadProject()"
            [disabled]="!(filter.Organization && filter.Organization?.Id  &gt; 0)"
            placeholder="{{ 'Select Option' | translate }}"
          >
          </p-dropdown>
        </ng-container>
        <input
          *ngIf="selectedBU?.ShowProjectInput"
          type="text"
          class="form-control"
          [(ngModel)]="filter.ProjectNumber"
          name="projNum"
          #projNum="ngModel"
          (change)="enableFilter()"
        />
      </div>
    </div>

    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ "Template" | translate }}</label>
        <p-dropdown
          id="selectTaskPlan"
          [options]="lstTaskPlan"
          [(ngModel)]="filter.TaskPlan"
          [styleClass]="'text-left'"
          [style]="{ width: '100%' }"
          (onChange)="filterInfo()"
          optionLabel="Description"
          appendTo="body"
          (onClick)="loadTaskPlan()"
          [disabled]="!(selectedBU !== null && selectedBU?.Id &gt; 0)"
          placeholder="{{ 'Select Option' | translate }}"
        >
        </p-dropdown>
      </div>
    </div>
    <div class="col-xs-3">
      <div class="form-group">
        <label>{{ "Status" | translate }}</label>
        <br />
        <p-multiSelect
          id="selectStatus"
          [options]="pssrStatus"
          [(ngModel)]="filter.StatusList"
          [style]="{ width: '100%', minHeight: '34px' }"
          [disabled]="filter.ShowAbandoned"
          (onChange)="statusSelect($event)"

        >
          <ng-template let-item pTemplate="item">
            {{ item.label | translate }}</ng-template
          ><ng-template let-items pTemplate="selectedItems"
            >
            <span *ngIf="items?.length === 0">{{ "Select option" | translate}}</span>
            <span *ngFor="let selectedItem of items; let i = index"
              ><span *ngIf="i > 0">,&nbsp;</span
              >{{ PSSRStatus[selectedItem] | translate }}</span
            >
          </ng-template></p-multiSelect
        >
      </div>
    </div>

    <div *ngIf="selectedBU.ShowPssrType" class="col-xs-3">
      <div class="form-group">
        <label>{{ "Type" | translate }}</label>
        <br />
        <p-multiSelect
          id="selectType"
          [options]="pssrTypes"
          [(ngModel)]="filter.PssrTypeIds"
          [style]="{ width: '100%', minHeight: '34px' }"
          (onChange)="statusSelect($event)"
        >
          <ng-template let-item pTemplate="item">
            {{ item.label | translate }}</ng-template
          ><ng-template let-items pTemplate="selectedItems"
            > 
            <span *ngIf="items?.length === 0">{{ "Select option" | translate}}</span>
            <span *ngFor="let selectedItem of items; let i = index"
              ><span *ngIf="i > 0">,&nbsp;</span
              >{{ getPssrType(selectedItem) | translate }}</span
            >
          </ng-template></p-multiSelect
        >
      </div>
    </div>
  </div>

  <!-- filter buttons -->
  <div class="row">

   
    <div class="col-xs-2">
      <div class="form-group col-xs-12 margin-top-20 no-padding padding-right-0">
        <label>{{ "Assigned to" | translate }}</label>

        <p-autoComplete
          id="cu"
          [(ngModel)]="selectedUser"
          [suggestions]="suggestedUsers"
          (completeMethod)="getAsigneeAutocomplete($event)"
          (onClear)="onClearAssignee()"
          (onSelect)="onSelectAssignee($event)"
          name="cu"
          #cu="ngModel"
          field="FullName"
          placeholder=""
          delay="1000"
          [style]="{ width: '100%' }"
          [inputStyle]="{ width: '100%' }"
          [minLength]="1"
        ></p-autoComplete>
      </div>
    </div>
    <div class="col-xs-2">
      <div class="form-group col-xs-12 margin-top-20 padding-left-0">
        <label>{{ "Search" | translate }}</label>
        <input
          id="search"
          class="form-control text-box"
          [(ngModel)]="filter.Search"
          name="search"
          [placeholder]="'Title, Id, MOC, WO'"
          maxlength="30"
          (ngModelChange)="enableFilter()"
        />
        </div>
    </div>
  <div class="col-xs-8 margin-top-20 padding-right-0 padding-left-0">
      <div class="row">
        <div class="col-xs-3 margin-top-20">
          <button
            id="createTrainingBtn"
            *ngIf="selectedBU.HasTraining && isAdmin"
            type="button"
            class="btn btn-info whiteSpaceBtn"
            style="width: 100%"
            (click)="confirmTrainingReset()"
          >
            {{ "Create Training" | translate }}
          </button>
        </div>
        <div class="col-xs-3 margin-top-20" *ngIf="!selectedBU?.ShowCCC && !selectedBU?.ShowORR">
          <p-splitButton
            id="newPssr"
            class="btn-success whiteSpaceBtn"
            label="{{ 'New PSSR' | translate }}"
            (onClick)="btnCreatePSSR()"
            [model]="buttonMenu">
          </p-splitButton>
        </div>
        <div class="col-xs-3 margin-top-20" *ngIf="selectedBU?.ShowCCC && !selectedBU?.ShowORR">
          <p-splitButton
          id="newPssr"
          class="btn-success whiteSpaceBtn"
          label="{{ 'NewPSSRCCC' | translate }}"

          (onClick)="btnCreatePSSR()"
          [model]="buttonMenu">
        </p-splitButton>
        </div>
        <div class="col-xs-3 margin-top-20" *ngIf="selectedBU?.ShowORR && !selectedBU?.ShowCCC">
          <p-splitButton
          id="newPssr"
          class="btn-success whiteSpaceBtn"
          label="{{ 'NewPSSRORR' | translate }}"

          (onClick)="btnCreatePSSR()"
          [model]="buttonMenu">
        </p-splitButton>
        </div>
        <div class="col-xs-3 margin-top-20" *ngIf="selectedBU?.ShowCCC && selectedBU?.ShowORR">
          <p-splitButton
          id="newPssr"
          class="btn-success whiteSpaceBtn"
          label="{{ 'NewPSSRCCCORR' | translate }}"

          (onClick)="btnCreatePSSR()"
          [model]="buttonMenu">
        </p-splitButton>
        </div>

        <div class="col-xs-3">
          <br />
          <button
            id="filterBtn"
            [disabled]="disableFilter"
            type="button whiteSpaceBtn"
            class="btn btn-primary"
            style="width: 100%"
            (click)="filterInfo()"
          >
            {{ "Filter" | translate }}
          </button>
        </div>
        <div class="col-xs-3 margin-top-20">
          <button
            id="clearfilter"
            type="button"
            class="btn btn-warning whiteSpaceBtn"
            style="width: 100%"
            (click)="clearfilter()"
          >
            {{ "Clear Filter" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

   
      <div class="row "> <div *ngIf="isAdmin" class=" col-xs-3 padding-checkbox" >
        <input
          id="showDeleteCbx"
          class="col-xs-1 padding-right-0 padding-right-0"
          type="checkbox"
          [(ngModel)]="filter.ShowDeleted"
          [disabled]="filter.ShowAbandoned"
          (click)="enableFilter()"
        />
        <label class="min-padding col-xs-11  padding-right-0 padding-left-0">
          {{ "Show Only Deleted PSSRs" | translate: selectedBU }} &nbsp;</label
        >
      </div></div>
    
    <div class="row">
      <div *ngIf="isAdmin" class=" form-group col-xs-3 padding-checkbox" >
        <input
          id="showAbandonedCbx"
          class="col-xs-1 padding-right-0 padding-left-0"
          type="checkbox"
          [(ngModel)]="filter.ShowAbandoned"
          [disabled]="filter.ShowDeleted"
          (click)="enableFilter()"
        />
        <label class="min-padding col-xs-11 padding-right-0  padding-left-0">
          {{ "Show Only Abandoned PSSRs" | translate: selectedBU }} &nbsp;</label
        >
      </div>
    </div>

  <!-- Pagination Control -->
  <div style="height: 0; margin-bottom: -0.5em;">
    <ng-container [ngTemplateOutlet]="paginationControl"></ng-container>
  </div>

  <!-- PSSR Grid -->
  <div *ngFor="let item of pssrsList | paginate: { id: 'paginator', itemsPerPage: pagination.pageSize, currentPage: pagination.pageNumber, totalItems: pagination.totalCount }">
    <ng-container
      [ngTemplateOutlet]="list"
      [ngTemplateOutletContext]="{ list: item[1], type: item[0] }"
    ></ng-container>
  </div>

  <!-- Pagination Control -->
  <ng-container [ngTemplateOutlet]="paginationControl"></ng-container>

  <ng-template #list let-list="list" let-type="type">
    <h3
      style="
        border-bottom: 1px solid #eee;
        margin-bottom: 5px;
        text-align: left;
      "
    >
      {{ type | translate }}
    </h3>
    <table
      *ngIf="list.length &gt; 0"
      class="table table-hover table-stripped table-condensed"
    >
      <thead>
        <tr class="bg-info">
          <th id="thDescriptionScope" class="col-sm-4">
            {{ "DescriptionScope" | translate }}
          </th>
          <th id="thProgress" class="col-sm-2">{{ "Progress" | translate }}</th>
          <th id="thLocation" class="col-sm-2 text-center">
            {{ "Location" | translate }}
          </th>
          <th id="thDates" class="col-sm-2">{{ "Dates" | translate }}</th>
          <th id="thActions" class="col-sm-1">{{ "Actions" | translate }}</th>
          <th id="thHolder" class="col-sm-1">&nbsp;</th>
        </tr>
      </thead>
      <tbody *ngFor="let p of list">
        <tr>
          <td class="col-sm-4">
            <div class="row padding-left-5">
              {{ p.Name }}
            </div>
            <div *ngIf="p.MOC" class="row padding-left-5">
              <b>{{ "MOC #" | translate }}:</b> {{ p.MOC }}
            </div>
            <div *ngIf="p.WO" class="row padding-left-5">
              <b>{{ "WO" | translate }}:</b> {{ p.WO }}
            </div>
            <div *ngIf="p.ProjectID" class="row padding-left-5">
              <b>{{ selectedBU.EventLabel || "Project" }}:</b> {{ p.Project }}
            </div>
            <div *ngIf="p.Description" class="row padding-left-5 descSize">
              <b>{{ "Description" | translate }}:</b> {{ p.Description }}
            </div>
            <div class="row">
              <div
                class="col-sm-5 text-center {{
                  p.statusClass
                }} padding-left-5 padding-right-5"
                style="border-radius: 1em; color: white; max-width: 250px"
              >
                {{ p.StatusName | translate }}
              </div>
            </div>
          </td>
          <td class="col-sm-2">
            <table>
              <tr>
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "TotalQuestions" | translate }}:</strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.TotalQuestions }}
                </td>
              </tr>
              <tr>
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "OutstandingQuestions" | translate }}:</strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.OutstandingQuestions }}&nbsp;
                </td>
              </tr>
              <tr>
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "CompletedQuestions" | translate }}:</strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.CompletedQuestions }}&nbsp;
                </td>
              </tr>
            </table>
          </td>
          <td class="col-sm-2">
            <div class="row">
              <div class="col-xs-7 text-right text-muted padding-left-0">
                <strong>{{ selectedBU.HierarchyLabel1 | translate }} :</strong>
              </div>
              <div class="col-xs-5 text-left padding-left-0">
                {{ p.Area }}
              </div>
            </div>
            <div class="row">
              <div class="col-xs-7 text-right text-muted padding-left-0">
                <strong>{{ selectedBU.HierarchyLabel2 | translate }} :</strong>
              </div>
              <div class="col-xs-5 text-left padding-left-0">
                {{ p.SubArea }}
              </div>
            </div>
            <div class="row" *ngIf="selectedBU.ShowHierarchyLabel3">
              <div class="col-xs-7 text-right text-muted padding-left-0">
                <strong>{{ selectedBU.HierarchyLabel3 | translate }} :</strong>
              </div>
              <div class="col-xs-5 text-left padding-left-0">
                {{ p.Facility }}
              </div>
            </div>
          </td>
          <td class="col-sm-2">
            <table>
              <tr *ngIf="p.CreateDate">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong
                    >{{
                      "Draft Created
                    On" | translate
                    }}:</strong
                  >
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.CreateDate | date: "shortDate" }}
                </td>
              </tr>
              <tr *ngIf="p.InitiatedOn">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "Initiated On" | translate }}:</strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.InitiatedOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>
              <tr *ngIf="p.StartedOn">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "Started" | translate }}:</strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.StartedOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>
              <tr *ngIf="p.CompletedOn">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong
                    >{{
                      "Walkdown Completed
                    On" | translate
                    }}:</strong
                  >
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.CompletedOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>
              <tr *ngIf="p.PendingApprovalOn">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong
                    >{{
                      "Pending Approval Submitted
                    On"
                        | translate
                    }}:</strong
                  >
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.PendingApprovalOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>
              <tr *ngIf="p.ApprovedOn">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "Approved On" | translate }}:</strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.ApprovedOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>
              <tr *ngIf="p.ClosedOn">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "Closed On" | translate }}: </strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.ClosedOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>

              <tr *ngIf="p.DeletedOn && p.IsDeleted">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "Deleted On" | translate }}: </strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.DeletedOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>
              <tr *ngIf="p.RestoredOn && !p.IsDeleted">
                <td nowrap class="col-xs-5 text-right padding-left-0">
                  <strong>{{ "Restored On" | translate }}: </strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">
                  {{ p.RestoredOn | date: "shortDate" }}&nbsp;
                </td>
              </tr>
            </table>
          </td>
          <td class="col-sm-1">
            <table>
              <tr *ngFor="let q of p.QuestionsFormated">
                <td
                  nowrap
                  class="col-xs-5 text-right text-muted padding-left-0"
                >
                  <strong>{{ q.name }} :</strong>
                </td>
                <td class="col-xs-7 text-left padding-left-0">{{ q.value }}</td>
              </tr>
            </table>
          </td>
          <td class="col-sm-1">
            <div class="row">
              <button
                type="button"
                class="btn btn-primary btn-block whiteSpaceBtn"
                (click)="btnEditPSSR(p.Id)"
              >
                {{ "Open" | translate }}
              </button>
              <button
                *ngIf="p.showViewItems"
                type="button"
                class="btn btn-success btn-block whiteSpaceBtn"
                (click)="btnViewActionItems(p.Name)"
              >
                {{ "View Items" | translate }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </ng-template>

  <ng-template #paginationControl>
    <div class="pagination-container" *ngIf="pagination.totalCount > pagination.pageSize">
      <pagination-controls id="paginator" class="pagination-control" (pageChange)="onPageChange($event)" maxSize="{{pagination.pageSize}}"></pagination-controls>
      <p>{{ pagination.currentRange }}</p>
    </div>
  </ng-template>
</div>

<p-confirmDialog #cd>
  <p-footer>
    <button
      type="button"
      pButton
      icon="fa-check pi pi-check whiteSpaceBtn"
      (click)="cd.accept()"
      style="width: 80px"
    >
      {{ "Yes" | translate }}
    </button>
    <button
      type="button"
      pButton
      icon="fa-close pi pi-times whiteSpaceBtn"
      (click)="cd.reject()"
      style="width: 80px"
    >
      {{ "No" | translate }}
    </button>
  </p-footer>
</p-confirmDialog>
