import { Component, OnInit } from '@angular/core';
import { SubAreaService } from '#services/api/sub-area.service';
import { AreaService } from '#services/api/area.service';
import { Area } from '#models/area';
import { SubArea } from '#models/sub-area';

import { Router, ActivatedRoute } from '@angular/router';
import { BusinessUnit } from '#models/business-unit';
import { BusinessUnitsService } from '#services/api';
import { Utility } from '#services/shared/utility';


@Component({
  selector: 'app-sub-area',
  templateUrl: './sub-area.component.html'
})
export class SubAreaComponent implements OnInit {

  selectedBU: BusinessUnit;
  lstBusinessUnit: BusinessUnit[] = [];
  selectedArea: Area;
  lstAreas: Area[] = [];
  lstSubAreas: SubArea[] = [];
  areaId: number;
  buId: number;

  constructor(
    private businessUnitsSvc: BusinessUnitsService,
    private subAreaSvc: SubAreaService,

    private areaService: AreaService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.selectedArea = new Area();
    this.route.params.subscribe(v => {
      this.buId = +v['buId'];
      this.areaId = +v['areaId'];
    });
    this.selectedArea.Id = this.areaId;
    this.selectedArea.BusinessUnitID = this.buId;
    this.loadAreas();
    this.loadSubAreas();
    this.loadBusinessUnits();
  }

  loadAreas(): void {
    this.areaService.getAllByBusinessUnit(this.buId)
    .subscribe((data: Area[]) => {
      this.lstAreas = data.sort((a, b) => a.Name.localeCompare(b.Name));
      this.selectedArea = this.lstAreas.find(x => x.Id === this.areaId);
      this.buId = this.selectedArea.BusinessUnitID;
    });
  }

  loadSubAreas(): void {

    this.subAreaSvc.getAllByAreaId(this.selectedArea.Id)

    .subscribe((data: SubArea[]) => {
      this.lstSubAreas = [];
      if (Utility.isValidListWithData(data)) {
        this.lstSubAreas = data.sort((a, b) => a.Name.localeCompare(b.Name));
      }
    });
  }
  loadBusinessUnits(): void {
    this.businessUnitsSvc.getUserBUs()
    .subscribe((data: BusinessUnit[]) => {
      this.lstBusinessUnit = data;
      if (this.lstBusinessUnit.length > 0) {
        if (this.buId == null || this.buId == undefined) {
          this.selectedBU = this.lstBusinessUnit[0];
        } else {
          this.selectedBU = this.lstBusinessUnit.find(x => x.Id === this.buId);
        }
        this.loadAreas();
      }
    });
  }
  onRedirectButtonClick(url: string, subAreaId: number): void {
    this.router.navigate([url, {buId: this.selectedArea.BusinessUnitID, areaId: this.selectedArea.Id, subAreaId: subAreaId,
      hierarchyLabel2: this.selectedBU.HierarchyLabel2,
      hierarchyLabel3: this.selectedBU.HierarchyLabel3
    }]);
  }

}
