import { TaskPlanSection } from '#models/task-plan-section';
import { PSSRSectionOrder } from '#models/pssr-section-order';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '#environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PssrSectionOrderService {
  constructor(
    private http: HttpClient
  ) {

  }
  changeOrder(
    newOrder: number,
    section: TaskPlanSection
  ): Observable<Partial<PSSRSectionOrder>> {
    return this.http.put<Partial<PSSRSectionOrder>>(
      `${environment.apiEndpoint}/pssrSectionOrder/switchOrder/${newOrder}`, {
        PSSRID: section.Responses[0].PSSRID,
        SectionDescription: section.Description,
        SectionID: section.ID
      }
    );
  }
  bulkChangeOrder(
    batch: PSSRSectionOrder[] = []
  ) {
    return this.http.put(
      `${environment.apiEndpoint}/pssrSectionOrder/switchOrder`, batch
    );
  }
}
