import { Component, OnInit } from '@angular/core';
import { User } from '#models/user';
import { UserService } from '#services/api/user.service';
import { ToastrService } from '#services/shared/toastr.service';
import { SystemAdmin } from '#models/system-admin';
import { SystemAdminService } from '#services/api/system-admin.service';
import { Utility } from '#services/shared/utility';


@Component({
  selector: 'app-system-admin',
  styleUrls: ['./system-admin.component.scss'],
  templateUrl: './system-admin.component.html',
})
export class SystemAdminComponent implements OnInit {
  selectedUser: User;
  users: User[];

  sysAdminList: SystemAdmin[];



  firstName: string;
  lastName: string;
  email: string;

  constructor(

    private toastrService: ToastrService,
    private userService: UserService,
    public sysAdminService: SystemAdminService
  ) {}

  ngOnInit() {
    this.loadSystemAdmins();
  }

  // Methods

  public loadSystemAdmins(): void {

    this.sysAdminService
      .getAll()

      .subscribe((data: SystemAdmin[]) => {
        this.sysAdminList = data;
      });
  }

  getUsers(event) {
    const query = event.query;
    this.userService.searchUser(query).subscribe((data) => {
      this.users = data;
      this.users.forEach(function (obj, index) {
        this[index].FullName = Utility.formatUser(this[index]);
      }, this.users);
    });
  }

  public addSysAdmin() {
    const sysAdmin = new SystemAdmin();
    sysAdmin.FirstName = this.selectedUser.FirstName;
    sysAdmin.MiddleName = this.selectedUser.MiddleName;
    sysAdmin.LastName = this.selectedUser.LastName;
    sysAdmin.Email = this.selectedUser.Email;
    sysAdmin.CAI = this.selectedUser.CAI;
    sysAdmin.IsDisabled = false;


    this.sysAdminService
      .createSystemAdmin(sysAdmin)

      .subscribe(
        (data) => {
          this.sysAdminList.push(data);
          this.toastrService.showSuccess( 'System Admin Successfully added');
          this.selectedUser = null;
        },
        (error) => {
          this.toastrService.showError( error.error.ExceptionMessage);

        }
      );
  }

  public updateSysAdmin(sysId: number) {
    const sysAdmin = this.sysAdminList.find((x) => x.ID == sysId);
    sysAdmin.IsDisabled = !sysAdmin.IsDisabled;


    this.sysAdminService
      .updateSystemAdmin(sysAdmin)

      .subscribe(
        () => {
          // TODO implement
        },
        (error) => {
          sysAdmin.IsDisabled = !sysAdmin.IsDisabled;
          this.toastrService.showError(  error.error.ExceptionMessage);
        }
      );
  }

  isInvalidString(value: string): boolean {
    return value == undefined || value == null || value.length == 0;
  }
}
