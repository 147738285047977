import { TranslationRequest } from '#models/translation-request';
import { User } from '#models/user';
import { UserService } from '#services/api';
import { Utility } from '#services/shared/utility';
import { Language } from '#services/translate/languages';
import {
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslatePipe } from '@ngx-translate/core';
import { AutoComplete } from 'primeng/autocomplete';

export interface TranslationRequestDialogData {
  availableLanguages?: { value: Language | string; label: string }[];
  translatorEmail?: string;
  pssrId: number;
  organizationId: number;
}

@Component({
  selector: 'app-translation-request-dialog',
  templateUrl: './translation-request-dialog.component.html',
  providers: [TranslatePipe],
  host: {
    class: 'ui-dialog ui-widget ui-widget-content ui-corner-all ui-shadow',
  },
  styles: [
    `
      :host {
        display: block;
        position: relative;
      }
    `,
  ],
})
export class TranslationRequestDialogComponent implements OnInit {
  translationRequestForm: FormGroup;
  participants = [];

  @ViewChild(AutoComplete) lookup: AutoComplete;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: TranslationRequestDialogData = {
      availableLanguages: null,
      translatorEmail: null,
      pssrId: 0,
      organizationId: 0
    },
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<TranslationRequestDialogComponent>,
    private userService: UserService,
    private renderer2: Renderer2,
    private el: ElementRef
  ) {
    const form = this.getForm(this.data);
    this.translationRequestForm = this.formBuilder.group(form);
  }

  getForm({ availableLanguages = null }: TranslationRequestDialogData): {
    [key: string]: any;
  } {
    const form = {
      TranslatorEmail: [
        '',
        Validators.compose([Validators.required, Validators.email]),
      ],

      SelectedParticipant: [null],
    };
    if (availableLanguages) {
      form['Language'] = [
        availableLanguages.length > 0 ? availableLanguages[0].value : '',
        Validators.compose([
          Validators.required,
          this.validateLanguage(availableLanguages.map(({ value }) => value)),
        ]),
      ];
    }
    return form;
  }

  ngOnInit(): void {
    if (this.data.translatorEmail) {
      this.translationRequestForm.patchValue({
        TranslatorEmail: this.data.translatorEmail,
        SelectedParticipant: {
          FullName: this.data.translatorEmail,
        },
      });
    }
  }

  async lookupParticipants({ query }): Promise<void> {
    let data = [];
    this.translationRequestForm.patchValue({
      TranslatorEmail: '',
    });

    try {
      data = await this.userService.searchParticipants(query, this.data.organizationId).toPromise();

      if (data.length > 0) { this.onLookupParticipants(); }
    } catch (e) {
      // TODO implment
    } finally {
      this.participants = data.map((d) => ({
        ...d,
        FullName: Utility.formatUser(d),
      }));
    }
  }

  selectParticipant({ Email }: Partial<User>) {
    this.translationRequestForm.patchValue({
      TranslatorEmail: Email,
    });

    this.onTerminateLookupParticipants();
  }

  requestTranslation({
    Language, // tslint:disable-line
    TranslatorEmail,
  }: Partial<TranslationRequest>): void {
    let output = null;
    if (TranslatorEmail != this.data.translatorEmail) {
      output = {
        Language,
        TranslatorEmail,
        PSSRID: this.data.pssrId,
      };
    }
    this.dialogRef.close(output);
  }

  validateLanguage =
    (validLanguages = ['en', 'ru', 'kk']) =>
      ({ value = '' }): ValidationErrors | null => {
        if (!!value) {
          return validLanguages.includes(value)
            ? null
            : {
              invalidLanguage: {
                requiredLanguages: validLanguages,
                actual: value,
              },
            };
        }
        return null;
      }

  onClearLookup(): void {
    this.participants = [];
    this.translationRequestForm.patchValue({
      TranslatorEmail: '',
    });
    this.onTerminateLookupParticipants();
  }

  onLookupParticipants(): void {
    const el = (this.el.nativeElement as HTMLElement).parentNode.parentNode;
    this.renderer2.addClass(el, 'on-lookup-participants');
  }
  onTerminateLookupParticipants(): void {
    const el = (this.el.nativeElement as HTMLElement).parentNode.parentNode;
    this.renderer2.removeClass(el, 'on-lookup-participants');
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event): void {
    const el = this.lookup.el.nativeElement as HTMLElement;

    if (!el.contains(event.target)) {
      this.onTerminateLookupParticipants();
    }
  }
}
