import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';
import {
  PSSR,
  LoggedUser,
  TaskPlan,
  TaskPlanSection,
  BusinessUnit,
} from '#models/index';
import { PSSRUser } from '#models/pssr-user';
import { UserRole } from '#models/enum/user-role';
import { UserUiRole } from '#models/user-ui-role';
import { PSSRUserList } from '#models/pssr-user-list';
import { SectionUser } from '#models/section/section';
import { User } from '#models/user';
import { UserService } from '#services/api/user.service';

import { PSSRResponseService, TaskPlanService } from '#services/api';
import { Utility } from '#services/shared/utility';
import { UserType } from '#models/enum/user-type';

import { AuthService } from '#services/shared';
import { ToastrService } from '#services/shared/toastr.service';
import { ParticipantType } from '#models/enum/participant-type';

@Component({
  selector: 'app-pssr-edit-qaqc-members',
  templateUrl: './pssr-edit-qaqc-members.component.html',
  styleUrls: ['./pssr-edit-qaqc-members.component.scss'],
})
export class PssrEditQaqcMembersComponent implements OnInit, OnChanges {
  constructor(
    public userService: UserService,
    public pssrResponseService: PSSRResponseService,
    private toastrService: ToastrService,
    private authService: AuthService,
    public taskPlanService: TaskPlanService
  ) { }

  get members() {
    return this.pssr.PSSRUsers.Users;
  }
  @Input() orgId: number;
  @Input() templateId: number;
  @Input() bu: BusinessUnit = new BusinessUnit();

  @Output() addedPssrUsers = new EventEmitter<PSSRUser[]>();
  @Output() addedSectionLeads = new EventEmitter<SectionUser[]>();

  pssrTaskPlan: TaskPlan = new TaskPlan();
  pssrTaskPlanSection: TaskPlanSection[] = [];
  pssr = new PSSR(); // Default
  pssrUsers: PSSRUser[] = [];

  roles: UserUiRole[];

  displayAddModal = false;
  displayEditModal = false;
  displayChev = false;
  displayENonChev = false;
  displayNNonChev = false;
  displayMain = false;
  allowMulti = false;
  disableAdd = true;
  displayNull = true;
  selectedRole: string;
  selectedRoleId: number;
  selectedUser: User;

  possibleSelectedUser: User[] = [];
  possibleParticipantId: User[] = [];
  possibleOtherParticipant: string[] = [];
  maxMessage = '';
  maxMessageWarning = 'Only 5 users can be added at a time';
  maxMessageLimit = '5 user limit reached, please select add';
  participateMessageWarning = 'This user is already a member of this pssr';

  participants: User[];
  participantList: PSSRUser[] = [];

  participantType: ParticipantType;

  ParticipantType = ParticipantType;

  participantId: number;
  otherParticipant: string;
  nonChevronUsers: User[];
  disablePartic: boolean;

  participantToEdit: PSSRUser;
  showFinalRole: boolean;
  currentUser: LoggedUser;

  // Section
  canAddSectionUser: boolean;
  sectionSelectedUser: PSSRUser;
  sectionLeads: SectionUser[] = [];

  displayImage = false;
  imagePhoto: string;

  async ngOnInit() {
    this.currentUser = this.authService.getCurrentUser();
    this.pssr.PSSRUsers = new PSSRUserList();
    this.pssr.PSSRUsers.Users = this.pssrUsers;
    this.participantToEdit = new PSSRUser();
    this.participantId = 0;
    this.participantType = ParticipantType.ChevronOrSharedUser; // chevron
    this.possibleSelectedUser = [];
    this.bu.PssrTitle = Utility.getPssrTitle(this.bu);
    this.setRoles();
    await this.getUsersByOrganization();
    this.getTaskPlan();
    // await this.getSignatures();
  }

  async addSectionUser(sectionId: number) {
    // Validation
    const existingUser = this.sectionLeads.find(
      (u) =>
        u.UserEmail === this.sectionSelectedUser.UserEmail &&
        u.SectionID === sectionId
    );
    if (Utility.isValidObj(existingUser)) {
      const msg =
        this.sectionSelectedUser.UserName +
        ' is already a lead of this section';
      this.sectionSelectedUser = null;
      this.toastrService.showInfo(msg);
      return;
    }
    const secLead = new SectionUser();
    secLead.TaskPlanID = this.templateId;
    secLead.SectionID = sectionId;
    secLead.UserEmail = this.sectionSelectedUser.UserEmail;
    secLead.UserName = this.sectionSelectedUser.UserName;
    this.sectionLeads.push(secLead);
    this.addedSectionLeads.emit(this.sectionLeads);

    const user = new SectionUser();
    user.UserName = this.sectionSelectedUser.UserName;
    user.UserEmail = this.sectionSelectedUser.UserEmail;
    this.pssrTaskPlanSection.find((u) => u.ID === sectionId).Users.push(user);
    this.sectionSelectedUser = null;
  }

  /**
   * Removes a user from section leaders
   * @param sectionId sectionUserId
   */
  async removeSectionUser(section: TaskPlanSection, email: string) {
    const index = section.Users.findIndex((u) => u.UserEmail === email);
    section.Users.splice(index, 1);
    const index2 = this.sectionLeads.findIndex(
      (u) => u.UserEmail === email && u.SectionID === section.ID
    );
    this.sectionLeads.splice(index2, 1);
    this.addedSectionLeads.emit(this.sectionLeads);
  }

  async getTaskPlan() {
    try {
      const data = await this.taskPlanService
        .getTaskPlanById(this.templateId)
        .toPromise();

      this.pssrTaskPlan = data;

      this.pssrTaskPlanSection = data.TaskPlanSections;
      this.pssrTaskPlanSection.forEach((u) => (u.Users = []));

    } catch (e) {
      console.error(e);
    }
  }

  ngOnChanges(changes): void {
    this.getTaskPlan();
  }

  setRoles() {
    this.disablePartic = false; // this.pssr.Status === PSSRStatus.Approved || this.pssr.Status === PSSRStatus.Closed || this.pssr.Status === PSSRStatus.Cancel;
    const disableFinalApr = false; // Utility.isValidObj(this.pssr.PSSRUsers.Users.find(x => x.Role === UserRole.Approver)) || this.disablePartic;
    this.roles = [
      {
        RoleId: UserRole.Lead,
        RoleName: 'Lead',
        RoleLabel: 'PSSR Team Lead',
        Disabled: this.disablePartic,
      },
      {
        RoleId: UserRole.Team,
        RoleName: 'ChangeTeam',
        RoleLabel: 'PSSR Team',
        Disabled: this.disablePartic,
      },
      {
        RoleId: UserRole.OpsRep,
        RoleName: 'OpsRepresentative',
        RoleLabel: 'Operations Representative',
        Disabled: this.disablePartic,
      },
      {
        RoleId: UserRole.Approver,
        RoleName: 'FinalApprover',
        RoleLabel: 'Final Approver',
        Disabled: disableFinalApr,
      },
    ];
  }

  validateRoles() {
    this.showFinalRole = !Utility.isValidObj(
      this.pssr.PSSRUsers.Users.find((x) => x.Role === UserRole.Approver)
    );
  }

  async getSignatures() {
    for (const u of this.pssr.PSSRUsers.Users) {
      u.SignatureDate = Utility.parseOffsetDate(u.SignatureDate);

      if (
        u.SignatureGUID != null &&
        u.SignatureGUID != undefined &&
        u.SignatureGUID != ''
      ) {

        try {

          const data = await this.pssrResponseService
            .getImageByGUID(0, u.SignatureGUID)
            .toPromise();

          u.SignatureImage = data.Photo;

        } catch (e) {
          console.error(e);
        }

      }
    }
  }

  // -- images
  btnExpandImage(image: string) {
    this.displayImage = true;
    this.imagePhoto = 'data:image/png;base64,' + image;
  }

  btnCollapseImage() {
    this.displayImage = false;
    this.imagePhoto = null;
  }

  openAddParticipant(role: UserUiRole) {
    this.maxMessage = '';
    this.possibleSelectedUser = [];
    this.possibleOtherParticipant = [];
    this.possibleParticipantId = [];
    this.displayChev = false;
    this.displayENonChev = false;
    this.allowMulti = false;
    this.displayNNonChev = false;
    if (role.RoleName === 'OpsRepresentative' || 'ChangeTeam') {
      this.allowMulti = true;
      this.displayMain = true;
      this.displayNull = true;
    }
    if (role.RoleName === 'Lead') {
      this.allowMulti = false;
      this.displayMain = false;
      this.displayNull = false;
    }
    if (role.RoleName === 'FinalApprover') {
      this.allowMulti = false;
      this.displayMain = false;
      this.displayNull = false;
      if (!this.bu.AllowMultiApprover) {
        const user = this.pssr.PSSRUsers.Users.find(
          (x) => x.Role == role.RoleId
        );
        if (user != null) {
          this.toastrService.showWarning(role.RoleLabel + ' Role is already added');
        } else {
          this.displayAddModal = true;
          this.selectedRole = role.RoleLabel;
          this.selectedRoleId = role.RoleId;
          this.selectedUser = null;
        }
      } else {
        this.displayAddModal = true;
        this.selectedRole = role.RoleLabel;
        this.selectedRoleId = role.RoleId;
        this.selectedUser = null;
      }
    } else {
      this.displayAddModal = true;
      this.selectedRole = role.RoleLabel;
      this.selectedRoleId = role.RoleId;
      this.selectedUser = null;
    }
    this.validateRoles();
  }

  openEditParticipant(part: PSSRUser) {
    this.validateRoles();
    const copy = JSON.parse(JSON.stringify(part));
    this.participantToEdit = copy;
    this.displayEditModal = true;
  }

  // method

  addParticipant() {
    this.displayAddModal = false;
    this.participantList = [];
    let user: PSSRUser;

    if (this.participantType === ParticipantType.NonChevronUser) {
      for (let i = 0; i < this.possibleParticipantId.length; i++) {
        user = new PSSRUser();
        user.PSSRID = this.pssr.Id;
        user.Role = this.selectedRoleId;
        user.UserID = this.possibleParticipantId[i].ID;
        user.UserName = this.possibleParticipantId[i].FullName;
        this.participantList.push(user);
      }
    } else if (this.participantType === ParticipantType.ChevronOrSharedUser) {
      for (let i = 0; i < this.possibleSelectedUser.length; i++) {
        user = new PSSRUser();
        user.PSSRID = this.pssr.Id;
        user.Role = this.selectedRoleId;
        user.UserEmail = this.possibleSelectedUser[i].Email;
        user.UserName = this.possibleSelectedUser[i].FullName;
        this.participantList.push(user);
      }
    } else {
      for (let i = 0; i < this.possibleOtherParticipant.length; i++) {
        user = new PSSRUser();
        user.PSSRID = this.pssr.Id;
        user.Role = this.selectedRoleId;
        user.UserEmail = this.possibleOtherParticipant[i];
        user.UserName = this.possibleOtherParticipant[i];
        this.participantList.push(user);
      }
    }
    this.pssr.PSSRUsers.Users = [
      ...this.pssr.PSSRUsers.Users,
      ...this.participantList,
    ];
    this.addedPssrUsers.emit(this.pssr.PSSRUsers.Users);
  }

  editParticipant() {
    this.displayEditModal = false;

    const ix = this.pssr.PSSRUsers.Users.findIndex(
      (pu) => pu.UserEmail === this.participantToEdit.UserEmail
    );
    if (ix > -1) {
      this.pssr.PSSRUsers.Users[ix].Role = this.participantToEdit.Role;
      this.pssr.PSSRUsers.Users[ix].RoleName = this.participantToEdit.RoleName;
    }
    this.addedPssrUsers.emit(this.pssr.PSSRUsers.Users);
  }

  public removeParticipant(participant: PSSRUser) {
    this.displayEditModal = false;
    const usrIx = this.pssr.PSSRUsers.Users.findIndex(
      (x) => x.UserEmail === participant.UserEmail
    );
    if (usrIx > -1) {
      this.pssr.PSSRUsers.Users.splice(usrIx, 1);
    }
    this.addedPssrUsers.emit(this.pssr.PSSRUsers.Users);
  }

  // ---------
  async suggestParticipants(event) {
    try {
      const query = event.query;
      this.participants = await this.userService.searchUser(query).toPromise();

      this.participants.forEach(function (_, index) {
        this[index].FullName = Utility.formatUser(this[index]);
      }, this.participants);
    } catch (e) {
      console.error(e);
    }
  }

  addNonChevronUserById(entry: number) {
    if (this.possibleParticipantId.length >= 5) {
      this.maxMessage = this.maxMessageLimit;
      return;
    }

    if (!entry) {
      return;
    }

    let tempUser: User;
    tempUser = this.nonChevronUsers.find(({ ID }) => ID === entry);

    if (this.possibleParticipantId.some((item) => item.ID === entry)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    if (this.pssr.PSSRUsers.Users.some((val) => val.UserID === entry)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    this.possibleParticipantId.push(tempUser);

    if (this.displayMain) {
      this.displayChev = true;
      this.displayNNonChev = true;
      this.maxMessage = this.maxMessageWarning;
    }

    this.disableAdd = false;
  }

  addChevronUser(entry) {
    if (this.possibleSelectedUser.length >= 5) {
      this.maxMessage = this.maxMessageLimit;
    }

    if (this.possibleSelectedUser.some((item) => item.CAI === entry.CAI)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    if (
      this.pssr.PSSRUsers.Users.some((item) => item.UserEmail === entry.Email)
    ) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    this.possibleSelectedUser.push(entry);

    if (this.displayMain) {
      this.displayENonChev = true;
      this.displayNNonChev = true;
      this.maxMessage = this.maxMessageWarning;
    }

    if (this.displayNull) {
      this.selectedUser = null;
    }

    this.disableAdd = false;
  }

  addExternalUser(entry: string): void {
    if (this.possibleOtherParticipant.length >= 5) {
      this.maxMessage = this.maxMessageLimit;
      return;
    }

    if (this.possibleOtherParticipant.some((item) => item === entry)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    if (this.pssr.PSSRUsers.Users.some((item) => item.UserName === entry)) {
      this.maxMessage = this.participateMessageWarning;
      return;
    }

    this.possibleOtherParticipant.push(entry);

    if (this.displayMain) {
      this.displayChev = true;
      this.displayENonChev = true;
      this.maxMessage = this.maxMessageWarning;
    }

    if (this.displayNull) {
      this.otherParticipant = null;
    }

    this.disableAdd = false;
  }

  removePossibleParticipant(entry) {
    switch (this.participantType) {
      case ParticipantType.NonChevronUser: {
        const index: number = this.possibleParticipantId.indexOf(entry);
        if (index !== -1) {
          this.possibleParticipantId = this.possibleParticipantId.filter((_, i) => i !== index);
        }
        if (this.possibleParticipantId.length === 0) {
          this.displayENonChev = false;
          this.displayNNonChev = false;
          this.displayChev = false;
          this.disableAdd = true;

          this.maxMessage = '';

          return;
        }
      } break;
      case ParticipantType.ChevronOrSharedUser:
        {
          const index: number = this.possibleSelectedUser.indexOf(entry);
          if (index !== -1) {
            this.possibleSelectedUser = this.possibleSelectedUser.filter((_, i) => i !== index);
          }

          if (this.possibleSelectedUser.length === 0) {
            this.displayENonChev = false;
            this.displayNNonChev = false;
            this.displayChev = false;
            this.disableAdd = true;

            this.maxMessage = '';

            return;
          }

        } break;
      case ParticipantType.External: {
        const index: number = this.possibleOtherParticipant.indexOf(entry);

        if (index !== -1) {
          this.possibleOtherParticipant = this.possibleOtherParticipant.filter((_, i) => i !== index);
        }

        if (this.possibleOtherParticipant.length === 0) {
          this.displayENonChev = false;
          this.displayNNonChev = false;
          this.displayChev = false;
          this.disableAdd = true;

          this.maxMessage = '';
          return;
        }
      } break;
    }


    this.maxMessage = this.maxMessageWarning;
  }

  async getUsersByOrganization() {
    try {
      const data = await this.userService
        .getUsersByOrg(this.orgId)
        .toPromise();

      const usrs: User[] = data.filter((u) => u.Type !== UserType.Chevron);
      this.nonChevronUsers = usrs.sort((one, two) =>
        one.FirstName.toUpperCase() < two.FirstName.toUpperCase() ? -1 : 1
      );

    } catch (e) {
      console.error(e);
    }
  }
}
