import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  AfterContentChecked,
} from '@angular/core';
import {
  PSSR,
  PSSRResponse,
  TaskPlanSection,
  TaskPlanSubSection,
  Group,
  PSSRFile,
  TaskPlanSectionType,
} from '#models/index';
import {
  PSSRResponseService,
  PSSRService,
  BusinessUnitsService,
} from '#services/api';
import { PSSRStatus } from '#models/pssr-status';
import { SectionType } from '#models/enum/section-type';
import { SectionUser } from '#models/section/section';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { PSSRUser } from '#models/pssr-user';
import { UserRole } from '#models/enum/user-role';
import { Utility } from '#services/shared/utility';
import { CommentDialogComponent } from '../dialogs/comment-dialog/comment-dialog.component';
import { ResponseItem } from '#models/response-item';
import { ActionItemDialogComponent } from '../dialogs/action-item-dialog/action-item-dialog.component';
import { AuthService } from '#services/shared';
import { ActionItemDialogService } from '../dialogs/action-item-dialog/action-item-dialog.service';
import { ResponseValidator } from '#services/shared/response-validator.service';
import { TranslatePipe } from '@ngx-translate/core';
import { SectionInputDialogComponent } from '../dialogs/section-input-dialog/section-input-dialog.component';
import { DialogService } from '#services/shared/dialog.service';
import { ToastrService } from '#services/shared/toastr.service';
import { ResponseStatus } from '#models/enum/response-status';
import { ItemType } from '#models/enum/item-type';
import { ResponseItemStatus } from '#models/response-item-status';
import { ResponseItemPriority } from '#models/enum/response-item-priority';
import { MOCChecklistHelper } from '#services/shared/moc-checklist-helper.service';

@Component({
  selector: 'app-pssr-edit-items-section',
  templateUrl: './pssr-edit-items-section.component.html',
  styleUrls: ['./pssr-edit-items-section.component.scss'],
  providers: [
    TranslatePipe,
    ActionItemDialogService,
    ResponseValidator,
    DialogService,
  ],
})
export class PssrEditItemsSectionComponent implements OnInit, AfterContentChecked {
  get subSections() {
    return this.section.TaskPlanSubSections;
  }
  get hasSubSections() {
    return !!this.subSections && this.subSections.length > 0;
  }
  get isProjectSpecific() {
    return this.section.Type === SectionType.ProjectSpecific;
  }

  get isUserAllowedToEdit() {
    const isEditStatus =
      this.pssr.Status === PSSRStatus.Draft ||
      this.pssr.Status === PSSRStatus.Initiated ||
      this.pssr.Status === PSSRStatus.InProgress;
    return isEditStatus && this.pssr.CurrentUser.CanEdit;
  }

  get canAddQuestion(): boolean {
    if (
      this.section.Type == SectionType.Signature &&
      this.pssr.Status == PSSRStatus.Initiated
    ) {
      return false;
    }
    return this.isUserAllowedToEdit;
  }

  get isUserAllowedToDelete() {
    return (
      this.section.SectionType != TaskPlanSectionType.Mandatory &&
      this.isUserAllowedToEdit
    );
  }

  get isAdminAndCanDeleteSection() {
    if (this.pssr.Status === PSSRStatus.Draft) {
      if (
        this.currentUser.IsOrgAdmin ||
        this.currentUser.IsBusinessUnitAdmin ||
        this.isSystemAdmin
      ) {
        return true;
      }
    }
    return false;
  }

  get showHeaders() {
    return (
      this.section.Type !== SectionType.StandAlone &&
      this.section.Type !== SectionType.Signature
    );
  }
  get showCode() {
    return !this.pssr.HideCode || this.isProjectSpecific;
  }

  get responses() {
    return !this.hasSubSections
      ? this.section.Responses
      : this.section.TaskPlanSubSections.map((t) => t.Responses).reduce(
        (array, prev) => prev.concat(array),
        []
      );
  }

  get isBlankSection() {
    return this.section.Type === SectionType.StandAlone
      ? this.section.StandAloneItems.length === 0
      : this.responses.length === 0;
  }

  constructor(
    private responseSvc: PSSRResponseService,
    private pssrSvc: PSSRService,
    private toastrService: ToastrService,
    private confirmationSvc: ConfirmationService,
    private businessUnitService: BusinessUnitsService,
    private authService: AuthService,
    private actionItemDialogService: ActionItemDialogService,
    private responseValidator: ResponseValidator,
    private translatePipe: TranslatePipe,
    private mocChecklistHelper: MOCChecklistHelper
  ) { }

  get allowMembersToCompletePSSR() {
    return this.businessUnitService.allowMembersToCompletePSSR(
      this.pssr.buObj,
      this.pssr
    );
  }

  get listOfDescriptions() {
    return this.responseSvc.getListOfSections(this.pssr.lstResponse);
  }

  get sectionTitle() {
    if (
      this.section.Description != null &&
      (this.section.Description.includes('Post') ||
        this.section.Description === 'Signatures')
    ) {
      return this.translatePipe.transform(this.section.Description);
    }

    return this.section.Description;
  }

  get actionItemsStats() {
    const actionItems = this._countActionItems(this.section.Responses);
    const standAloneItems = this.section.StandAloneItems;

    return {
      3: this._parseActionItemsStats({
        actionItems,
        standAloneItems,
        priority: 3,
      }),
      4: this._parseActionItemsStats({
        actionItems,
        standAloneItems,
        priority: 4,
      }),
      5: this._parseActionItemsStats({
        actionItems,
        standAloneItems,
        priority: 5,
      }),
    };
  }

  get canTranslate(): boolean {
    return (
      this.pssr.CurrentUser.Role === UserRole.Translator &&
      this.pssr.buObj.CanTranslate &&
      this.pssr.translationRequestList &&
      this.pssr.translationRequestList.some(
        (r) =>
          r.TranslatorEmail === this.pssr.CurrentUser.UserEmail ||
          this.pssr.CurrentUser.ParentUsers.includes(r.TranslatorEmail)
      )
    );
  }

  @Input() pssr: PSSR;
  @Input() section: TaskPlanSection;

  @Input() groups: Group[];
  @Input() pssrFiles: PSSRFile[] = [];

  @ViewChild(SectionInputDialogComponent)
  sectionInputDialog: SectionInputDialogComponent;

  editedSection = new TaskPlanSection();

  @Input() index = 0;
  @Input() maxSections = 1;

  @Output() onRemoveSection = new EventEmitter<TaskPlanSection>();
  @Output() sortChanged = new EventEmitter();
  @Output() updatedSection = new EventEmitter<TaskPlanSection>();

  isSystemAdmin = false;
  newComment: Partial<ResponseItem> = null;
  newActionItem: Partial<ResponseItem> = null;

  responseOptions: SelectItem[] = [
    { label: '', value: '' },
    { label: 'Yes', value: 'Yes' },
    { label: 'No', value: 'No' },
    { label: 'N/A', value: 'N/A' },
  ];

  @Input() members: PSSRUser[] = [];

  SectionType = SectionType;
  TaskPlanSectionType = TaskPlanSectionType;
  ItemType = ItemType;

  canAddSectionUser: boolean;
  newResponse: PSSRResponse;
  selectedUser: PSSRUser;
  selectedSubSection: TaskPlanSubSection;
  disableAddQuestion: boolean;

  currentUser: PSSRUser;
  isSectionLead = false;

  displayClearModal = false;
  selectedResponse: PSSRResponse;

  private _countActionItems(data: PSSRResponse[] = []): ResponseItem[] {
    return data.reduce(
      (a, b) => [...a, ...b.ActionItems.filter((i) => i.IsActionItem)],
      []
    );
  }
  private _parseActionItemsStats({
    actionItems = [],
    standAloneItems = [],
    priority = ResponseItemPriority.P3,
  }: {
    actionItems: ResponseItem[];
    standAloneItems: ResponseItem[];
    priority: number;
  }): string {
    const ais = actionItems.filter((i) => i.Priority === priority);
    const standAlones = standAloneItems.filter((i) => i.Priority === priority);
    const aisClosed = ais.filter((i) => i.Status === ResponseItemStatus.Closed);
    const standAlonesClosed = standAlones.filter((i) => i.Status === ResponseItemStatus.Closed);
    return `${aisClosed.length + standAlonesClosed.length}/${ais.length + standAlones.length
      }`;
  }

  canDeleteResponse(response: PSSRResponse): boolean {
    return this.responseSvc.canDeleteResponse(response, this.pssr);
  }

  ngOnInit() {
    this.currentUser = this.pssr.CurrentUser;
    this.isSystemAdmin = this.authService.isSystemAdmin();

    this.setSectionLead();

    this.disableAddQuestion = false;
    this.setPermissionToAddUsers();
    this.setupNewResponse();

    this.selectedSubSection = this.hasSubSections ? this.subSections[0] : null;
    this.editedSection = { ...this.section };
  }

  ngAfterContentChecked() {
    this.validateResponseStatuses();
  }

  validateResponseStatuses() {
    this.validateItemQuestion();
    this.validateSubSection();
    this.validateSection();
  }

  validateItemQuestion() {
    this.section.Responses.forEach(r => {

      r.ResponseStatus = this.responseValidator.getResponseStatus(r, this.pssr.buObj);
    });
  }

  validateSubSection() {
    this.section.TaskPlanSubSections.forEach((subSection) => {
      // TODO why we have 2 collections? : this.section.Responses and this.section.TaskPlanSubSections[0].Responses
      let subSectionResponseStatus = ResponseStatus.None;

      for (const r of subSection.Responses) {
        // Get response from another(see prev. TODO) colection with updated ResponseStatus fields
        const response = this.section.Responses.find(i => i.Id === r.Id);
        r.ResponseStatus = response?.ResponseStatus;

        // Get worst status from responses
        subSectionResponseStatus = <ResponseStatus>Math.max(subSectionResponseStatus, response?.ResponseStatus);
      }

      subSection.ResponseStatus = subSectionResponseStatus;
    });
  }

  validateSection() {
    const responsesStatus = <ResponseStatus>Math.max(...(this.section.Responses?.map<number>(i => i.ResponseStatus) ?? [ResponseStatus.Answered]));
    const standAlonesStatus = <ResponseStatus>Math.max(...(this.section.StandAloneItems?.map<number>(i => i.ResponseStatus) ?? [ResponseStatus.Answered]));
    const sectionStatus = <ResponseStatus>Math.max(responsesStatus, standAlonesStatus);

    this.section.ResponseStatus = sectionStatus;
  }

  getElementColorByType(status: ResponseStatus) {
    return Utility.getResponseStatusCSSClass(status);
  }

  getSubsectionElementColorByType(subSection: TaskPlanSubSection) {
    const questionsResultSummary = <ResponseStatus>Math.max(...(subSection?.Responses?.map<number>(i => i.ResponseStatus))); // ?? [ResponseStatus.Answered]
    return Utility.getResponseStatusCSSClass(questionsResultSummary);
  }

  getSectionElementColorByType(section: TaskPlanSection) {
    const questionResultSummary = <ResponseStatus>Math.max(...(section.Responses?.map<number>(i => i.ResponseStatus) ?? [ResponseStatus.Answered]));
    return Utility.getResponseStatusCSSClass(questionResultSummary);
  }

  getQuestionElementColorByType(responseStatus: ResponseStatus) {
    return Utility.getResponseStatusCSSClass(responseStatus);
  }

  setSectionLead() {
    this.isSectionLead =
      this.section.Users?.some(
        (sectionUser) => this.responseSvc.isSectionLead({
          sectionDescription: this.section.Description,
          sectionId: this.section.ID,
          businessUnitCode: this.pssr.BusinessUnitCode,
          currentUser: this.pssr.CurrentUser,
          sectionUser
        })
      );
  }

  setResponseCanAnswer(canAnswer: boolean) {
    if (!this.hasSubSections) {
      this.section.Responses = this.section.Responses.map((r) =>
        r.SectionID == this.section.ID ? { ...r, CanAnswer: canAnswer } : r
      );
    } else {
      this.section.TaskPlanSubSections.forEach((ss) => {
        ss.Responses = ss.Responses.map((r) =>
          r.SectionID == this.section.ID ? { ...r, CanAnswer: canAnswer } : r
        );
      });
    }
  }

  /**
   * Adds a user to section leaders
   */
  async addSectionUser() {
    if (!Utility.isValidListWithData(this.section.Users)) {
      this.section.Users = [];
    }
    const existingUser = this.section.Users.find(
      (u) => u.UserID === this.selectedUser.UserID
    );

    if (Utility.isValidObj(existingUser)) {
      const msg = this.translatePipe.transform(
        'SectionLeadWasAlreadyAddedWarningMessage',
        this.selectedUser
      );
      this.selectedUser = null;

      this.toastrService.showInfo(msg);
      return;
    }

    const user = <SectionUser>{
      PSSRID: this.pssr.Id,
      UserID: this.selectedUser.UserID,
      UserEmail: this.selectedUser.UserEmail
    };

    if (this.section.ID && this.section.ID !== 0) {
      user.SectionID = this.section.ID;
    }

    user.SectionDescription = this.section.Description;

    await this.pssrSvc
      .addSectionUser(user)
      .toPromise()
      .then(
        (data: SectionUser) => {
          this.section.Users.push(data);
          this.selectedUser = null;

          const isSectionLead =
            this.responseSvc.isSectionLead({
              sectionDescription: this.section.Description,
              sectionId: this.section.ID,
              businessUnitCode: this.pssr.BusinessUnitCode,
              currentUser: this.currentUser,
              sectionUser: data
            });


          this.setResponseCanAnswer(isSectionLead);
          this.isSectionLead = isSectionLead;
        },
        () => {
          this.toastrService.showWarning(this.translatePipe.transform('SectionLeadAddedErrorMessage'));
        }
      );
  }

  /**
   * Removes a user from section leaders
   * @param sectionId sectionUserId
   */
  async removeSectionUser(sectionId: number) {
    if (sectionId === 0) {
      return;
    }

    await this.pssrSvc
      .removeSectionUser(sectionId)
      .toPromise()
      .then(
        () => {
          const index = this.section.Users.findIndex((u) => u.Id === sectionId);

          if (index === -1) {
            return;
          }

          const removedUser = this.section.Users[index];
          this.section.Users = this.section.Users.filter((_, i) => i !== index);
          if (
            this.responseSvc.isSectionLead({
              businessUnitCode: this.pssr.BusinessUnitCode,
              currentUser: this.currentUser,
              sectionDescription: this.section.Description,
              sectionId: this.section.ID,
              sectionUser: removedUser
            })
          ) {

            this.setResponseCanAnswer(false);
            this.isSectionLead = false;

          }
        },
        () => {
          this.toastrService.showWarning(this.translatePipe.transform('SectionLeadRemovedErrorMessage'));
        }
      );
  }

  /**
   * Adds a response to the section/subsection
   */
  async addResponse() {
    this.disableAddQuestion = true;

    if (this.hasSubSections) {
      const response = this.newResponse;
      if (!Utility.isValidObj(this.selectedSubSection)) {
        this.selectedSubSection = this.subSections.filter(
          (x) => x.Description === ''
        )[0];
      }
      const subSection = this.selectedSubSection;
      response.SubSectionDescription = subSection.Description;
      response.SubSectionSortID = subSection.SortID;
      response.SortID =
        subSection.Responses.length > 0
          ? subSection.Responses[subSection.Responses.length - 1].SortID + 1
          : 1;

      await this.responseSvc
        .create(response)
        .toPromise()
        .then(
          (data: PSSRResponse) => {
            subSection.Responses.push(data);
            this.section.Responses.push(data);
          },
          () => {
            this.toastrService.showWarning(this.translatePipe.transform('AddResponseErrorMessage'));
          }
        );
    } else {
      await this.responseSvc
        .create(this.newResponse)
        .toPromise()
        .then(
          (data: PSSRResponse) => {
            this.section.Responses.push(data);
            if (this.isProjectSpecific && !this.canAddSectionUser) {
              this.setPermissionToAddUsers();
            }
          },
          () => {
            this.toastrService.showWarning(this.translatePipe.transform('AddResponseErrorMessage'));
          }
        );
    }

    this.setupNewResponse();
    this.disableAddQuestion = false;
  }

  /**
   * Removes a response from the section/subsection
   */
  async removeResponseConfirmed(response: PSSRResponse) {
    try {
      await this.responseSvc.delete(response.Id).toPromise();

      if (
        this.isProjectSpecific &&
        Utility.isValidListWithData(this.section.Responses) &&
        this.section.Responses.length == 1
      ) {
        this.canAddSectionUser = false;
      }

      if (!this.hasSubSections) {
        this.section.Responses = this.section.Responses.filter(
          (r) => r.Id !== response.Id
        );
      } else {
        const subSection = this.subSections.find(
          (s) => s.Description === response.SubSectionDescription
        );
        subSection.Responses = subSection.Responses.filter(
          (r) => r.Id !== response.Id
        );
      }
    } catch ({ error: { ExceptionMessage } }) {
      this.toastrService.showError(ExceptionMessage);
    }
  }

  async removeResponse(response: PSSRResponse) {
    const header = this.translatePipe.transform('ConfirmDeleteHeader');
    const labelOk = this.translatePipe.transform('Confirm');
    const labelCancel = this.translatePipe.transform('Cancel');
    const message = this.translatePipe.transform('ConfirmDeleteMessage', {});
    this.confirmationSvc.confirm({
      message: message,
      header: header,
      acceptLabel: labelOk,
      rejectLabel: labelCancel,
      accept: () => {
        this.removeResponseConfirmed(response);
      },
      reject: () => {
        // TODO reject implementation
      },
    });
  }

  /**
   * Removes this section from PSSR
   */
  removeSection() {
    this.confirmationSvc.confirm({
      header: this.translatePipe.transform('ConfirmRemoveSectionTitle'),
      message: this.translatePipe.transform('ConfirmRemoveSectionDescription'),
      accept: () => this.onRemoveSection.emit(this.section),
    });
  }

  /**
   * Sets the permissions to add section leaders
   */
  private setPermissionToAddUsers() {
    const isSectionValid = this.section.Type !== SectionType.Signature;

    const isValidStatus =
      this.pssr.Status === PSSRStatus.Draft ||
      this.pssr.Status === PSSRStatus.Initiated ||
      this.pssr.Status === PSSRStatus.InProgress;

    const isValidStandAloneSection =
      this.section.Type === SectionType.StandAlone &&
      this.pssr.Status === PSSRStatus.Completed;

    this.canAddSectionUser =
      isSectionValid &&
      !this.isBlankSection &&
      (isValidStatus || isValidStandAloneSection) &&
      (this.currentUser.IsBusinessUnitAdmin ||
        this.currentUser.IsOrgAdmin ||
        this.isSystemAdmin ||
        this.pssr.CurrentUser.Role === UserRole.Lead);
  }

  /**
   * Empty the values of the new response object
   */
  private setupNewResponse() {
    this.newResponse = <PSSRResponse>{
      PSSRID: this.pssr.Id,
      CreateDate: new Date(),
      SectionID: this.section.ID,
      ItemDescription: '',
      SectionDescription: this.section.Description,
      SubSectionDescription: '',
      SectionSortID: this.section.SortID,
      SubSectionSortID: 0
    };

    if (this.isProjectSpecific) {
      this.newResponse.SortID =
        this.section.Responses.length > 0
          ? this.section.Responses[this.section.Responses.length - 1].SortID + 1
          : 1;
      this.newResponse.ItemCode =
        (this.section.Responses.length > 9 ? 'PS' : 'PS0') +
        this.newResponse.SortID;
    }
  }

  canRespond(response: PSSRResponse): boolean {
    return this.responseSvc.canRespond(response, this.pssr);
  }

  isActionItemNeeded(resp: PSSRResponse): boolean {
    const validation = this.responseValidator.validate(
      this.pssr.buObj
    )({ value: resp });
    if (
      validation &&
      (validation.ResponseText.message ===
        ResponseValidator.CommentIsNeededError.ResponseText.message ||
        validation.ResponseText.message ===
        ResponseValidator.ActionItemIsNeededError.ResponseText.message)
    ) {
      return true;
    }

    return false;
  }

  getErrorMessage(resp: PSSRResponse): string {
    const validation = this.responseValidator.validate(
      this.pssr.buObj
    )({
      value: resp,
    });

    if (validation === null) {
      return '';
    }

    return `<strong>${this.translatePipe.transform(
      'Note'
    )}:&nbsp;</strong>${this.translatePipe.transform(
      validation.ResponseText.message
    )}`;
  }

  async markAsInProgress() {
    const markAsInProgress = this.pssrSvc.canMarkInProgress(
      this.pssr,
      this.section
    );

    if (markAsInProgress) {
      try {
        await this.pssrSvc
          .changeStatus(this.pssr.Id, PSSRStatus.InProgress)
          .toPromise();

        this.updatedSection.emit(this.section);
      } catch ({ error: { ExceptionMessage } }) {
        this.toastrService.showError(ExceptionMessage);
      }
    }
  }

  actionItemChanged() {
    this.updatedSection.emit(this.section);
  }

  async clearSelection(response: PSSRResponse) {
    try {
      await this.markAsInProgress();

      response.ResponseText = null;

      await this.responseSvc.clearResponse(response).toPromise();

      this.section.Responses = this.section.Responses.map((r) =>
        r.Id == response.Id
          ? { ...r, ResponseText: response.ResponseText, ActionItems: [] }
          : r
      );
      this.section.TaskPlanSubSections.forEach((ss) => {
        ss.Responses = ss.Responses.map((r) =>
          r.Id == response.Id
            ? { ...r, ResponseText: response.ResponseText, ActionItems: [] }
            : r
        );
      });
      this.pssr.lstResponse = this.pssr.lstResponse.map((r) =>
        r.Id == response.Id
          ? { ...r, ResponseText: response.ResponseText, ActionItems: [] }
          : r
      );

      this.pssr = this.responseSvc.validateItemPriority(
        this.pssr,
        this.pssr.lstResponse,
        this.pssr.standAloneItems
      );
    } catch ({ error: { ExceptionMessage } }) {
      this.toastrService.showError(ExceptionMessage);
    }
    this.displayClearModal = false;
  }

  clearOldWay(response: PSSRResponse) {
    response.ResponseText = null;
    this.saveResponse(response);
  }

  async saveResponse(response: PSSRResponse): Promise<void> {
    try {
      await this.markAsInProgress();

      response.ResponseStatus = this.responseValidator.getResponseStatus(response, this.pssr.buObj);

      const data = await this.responseSvc.update(response).toPromise();

      response = { ...response, ...data };

      this.section.Responses = this.section.Responses.map((r) =>
        r.Id == response.Id ? { ...r, ResponseText: response.ResponseText } : r
      );
      this.section.TaskPlanSubSections.forEach((ss) => {
        ss.Responses = ss.Responses.map((r) =>
          r.Id == response.Id
            ? { ...r, ResponseText: response.ResponseText }
            : r
        );
      });

      this.pssr.lstResponse = this.pssr.lstResponse.map((r) =>
        r.Id == response.Id ? { ...r, ResponseText: response.ResponseText } : r
      );

      this.pssr = this.responseSvc.validateItemPriority(
        this.pssr,
        this.pssr.lstResponse,
        this.pssr.standAloneItems
      );
    } catch ({ error: { ExceptionMessage } }) {
      this.toastrService.showError(ExceptionMessage);
    }
  }

  canAddActionItemAndComment(response: PSSRResponse) {
    if (!!response.ItemCode && this.mocChecklistHelper.isMOCChecklistLocked(response.ItemCode)) {
      return false;
    }
    return this.responseSvc.canAddActionItem(response, this.pssr);
  }

  canDisplayQuestionSection(): boolean {
    if (this.section.Responses?.length > 0) {
      const response = this.section.Responses[0];

      if (!!response.ItemCode && this.mocChecklistHelper.isMOCChecklistLocked(response.ItemCode)) {
        return false;
      }
    }
    return true;
  }

  addComment(response: PSSRResponse, commentDialog: CommentDialogComponent) {
    this.newComment = this.actionItemDialogService.setupComment({
      pssrId: this.pssr.Id,
      organizationId: this.pssr.OrganizationID,
      responseId: response.Id,
    });
    commentDialog.open();
  }

  addActionItem(
    response: PSSRResponse,
    actionItemDialog: ActionItemDialogComponent
  ) {
    this.newActionItem = this.actionItemDialogService.setupActionItem({
      pssr: this.pssr,
      responseId: response.Id,
    });

    actionItemDialog.open();
  }

  saveResponseItem(savedItem: ResponseItem): void {
    this.section.Responses.forEach((response) => {
      if (response.Id == savedItem.ResponseID && savedItem.IsDeleted == false) {
        response.ActionItems.push(savedItem);
      } else if (response.Id == savedItem.ResponseID && savedItem.IsDeleted) {
        response.ActionItems = response.ActionItems.filter(
          (i) => i.Id !== savedItem.Id
        );
      }
    });
    this.section.TaskPlanSubSections.forEach((subSection) => {
      subSection.Responses = subSection.Responses.map((r) => {
        const temp = this.section.Responses.find((v) => v.Id == r.Id);
        if (temp) {
          return { ...r, ActionItems: temp.ActionItems };
        }
        return r;
      });
    });

    this.pssr.lstResponse = this.pssr.lstResponse.map((r) => {
      const temp = this.section.Responses.find((v) => v.Id == r.Id);
      if (temp) {
        return { ...r, ActionItems: temp.ActionItems };
      }
      return r;
    });

    this.pssr = this.responseSvc.validateItemPriority(
      this.pssr,
      this.pssr.lstResponse,
      this.pssr.standAloneItems
    );

    this.markAsInProgress();
  }

  async onResponseSigned(signedResponse: PSSRResponse) {
    this.section.Responses = this.section.Responses.map((r) =>
      r.Id == signedResponse.Id ? signedResponse : r
    );

    this.section.TaskPlanSubSections.forEach((subSection) => {
      subSection.Responses = subSection.Responses.map((r) => {
        if (r.Id == signedResponse.Id) {
          return signedResponse;
        }
        return r;
      });
    });

    this.pssr.lstResponse = this.pssr.lstResponse.map((r) => {
      if (r.Id == signedResponse.Id) {
        return signedResponse;
      }
      return r;
    });

    this.pssr = this.responseSvc.validateItemPriority(
      this.pssr,
      this.pssr.lstResponse,
      this.pssr.standAloneItems
    );

    await this.markAsInProgress();
  }

  toggleContent(
    $event: Event,
    sectionDescription: string,
    subSectionDescription: string = ''
  ): void {
    $event.stopPropagation();

    if (sectionDescription !== this.section.Description) {
      return;
    }

    if (subSectionDescription) {
      const ss = this.section.TaskPlanSubSections.findIndex(
        (s) => s.Description === subSectionDescription
      );

      if (ss < 0) {
        return;
      }

      this.section.TaskPlanSubSections[ss].isCollapsed =
        !this.section.TaskPlanSubSections[ss].isCollapsed;
    } else {
      this.section.isCollapsed = !this.section.isCollapsed;
    }
  }

  switchSectionOrder(newOrder) {
    this.sortChanged.emit({
      previousIndex: this.index,
      currentIndex: newOrder,
    });
  }

  editSection() {
    if (
      this.section.SectionType === TaskPlanSectionType.Multiple &&
      (this.pssr.CurrentUser.CanEdit ||
        this.isSystemAdmin ||
        this.currentUser.IsBusinessUnitAdmin ||
        this.currentUser.IsOrgAdmin) &&
      this.pssr.Status <= PSSRStatus.InProgress
    ) {
      this.sectionInputDialog.open();
    }
  }

  async updateSection({ Description }: TaskPlanSection) {
    await this.responseSvc
      .updatePssrSection(this.pssr.Id, {
        newSection: { ...this.section, Description },
        sectionDescription: this.section.Description,
        sectionId: this.section.ID,
      })
      .toPromise();

    this.section.Responses.forEach((r) => {
      r.SectionDescription = Description;
    });

    this.section.TaskPlanSubSections.forEach((ss) => {
      ss.Responses.forEach((r) => {
        r.SectionDescription = Description;
      });
    });

    this.pssr.lstResponse.forEach((r) => {
      if (r.SectionDescription == this.section.Description) {
        r.SectionDescription = Description;
      }
    });

    this.pssr = this.responseSvc.validateItemPriority(
      this.pssr,
      this.pssr.lstResponse,
      this.pssr.standAloneItems
    );

    this.section.Description = Description;
    this.editedSection.Description = Description;

    this.updatedSection.emit(this.section);
  }
}
