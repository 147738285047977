<div class="box-shadow module" style="width:80%; margin:auto; margin-bottom:20px">

    <h2>{{'Attention'|translate}}!</h2>
    <hr>
    <h3>{{confirmDeleteAreaTitle  | translate:hierarchyLabels }}: <span style="background-color: yellow;">{{area.Name}}</span> ?</h3>
    <h4>{{ confirmDeleteAreaDescription | translate:hierarchyLabels }}</h4>
    <h3><a class="btn btn-danger" style="width: 100px;" (click)="deleteArea()">{{'Delete'|translate}}</a></h3>
</div>

<div style="width:80%; margin:auto">
    <a (click)="onRedirectButtonClick(area.BusinessUnitID)" style="cursor: pointer;">{{'Back to Area List'|translate}}</a>
</div>
